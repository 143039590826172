/*
 * menuConstants.js
 *
 * A script that defines:
 * 1. The analytics sidebar items
 * 2. The bam platform dropdown menu items
 * 3. The date range selector group menu items
 *
 */

// Sidebar Menu
export const sidebarMenuItems = [
  {
    key: 'AnalyticsOverview',
    title: 'Analytics Overview',
    icon: 'bar-chart',
  },
  {
    key: 'UserActivity',
    title: 'User Activity',
    icon: 'user',
    overview: 'users',
    overviewInfo:
      'This chart describes the number of unique active users per day over the specified ' +
      'time range.',
  },
  {
    key: 'AssetsDetail',
    title: 'Asset Views',
    icon: 'eye',
    overview: 'assetsViewed',
    overviewInfo:
      'This chart describes the number of asset view events per day over the specified ' +
      'time range.',
  },
  {
    key: 'ShareDetail',
    title: 'Asset Shares',
    icon: 'share-alt',
    overview: 'assetsShared',
    overviewInfo:
      'This chart describes the number of asset share events per day over the specified ' +
      'time range.',
  },
  {
    key: 'CalcViews',
    title: 'Calculator Views',
    icon: 'calculator',
    overview: 'calcsViewed',
    overviewInfo:
      'This chart describes the number of calculator view events per day over the specified ' +
      'time range.',
  },
  {
    key: 'Calculations',
    title: 'Calculations',
    icon: 'calculator',
    overview: 'calculations',
    overviewInfo:
      'This chart describes the number of calculations per day over the specified ' +
      'time range.',
  },
]

// Platform Selector
export const platformMenuItems = [
  {
    key: 'all',
    label: 'All',
  },
  {
    key: 'mst',
    label: 'Mobile',
  },
  {
    key: 'dam',
    label: 'Web',
  },
]

// Date Range Selector
export const dateRangeMenuItems = [
  {
    key: '1d',
    label: 'Today',
    duration: 1,
    unit: 'd',
  },
  {
    key: '7d',
    label: '7d',
    duration: 7,
    unit: 'd',
  },
  {
    key: '30d',
    label: '30d',
    duration: 30,
    unit: 'd',
  },
  {
    key: '3M',
    label: '3m',
    duration: 3,
    unit: 'M',
  },
  {
    key: '1y',
    label: '1y',
    duration: 1,
    unit: 'y',
  },
  {
    key: 'all',
    label: 'All Time',
    duration: null,
    unit: null,
  },
  {
    key: 'custom',
    label: null,
    duration: null,
    unit: null,
  },
]
