import PropTypes from 'prop-types'
import React from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import {
  DND_SLIDE,
  dragCollect,
  dropCollect,
  slideSource,
  slideTarget,
} from '../../../dux/modules/DragDrop'
import Slide from './Slide'

class DraggableSlide extends React.Component {
  saveSlideRef = (ref) => (this.slideRef = ref)

  render() {
    return this.props.connectDropTarget(
      this.props.connectDragSource(
        <div>
          <Slide
            {...this.props}
            saveSlideRef={this.saveSlideRef}
            draggable={true}
          />
        </div>
      )
    )
  }
}

DraggableSlide.propTypes = {
  slide: PropTypes.object,
  connectDragSource: PropTypes.func,
  connectDropTarget: PropTypes.func,
  reorderSlides: PropTypes.func,
  isDragging: PropTypes.bool,
  isActive: PropTypes.bool,
  makeSlideActive: PropTypes.func,
  mainImage: PropTypes.string,
}

export default DropTarget(
  DND_SLIDE,
  slideTarget,
  dropCollect
)(DragSource(DND_SLIDE, slideSource, dragCollect)(DraggableSlide))
