/**
 * CalculatorResults Component
 * The view that slides into place to view the results of a calculation.
 *
 */

import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'
import { numberWithCommas } from '../utils'
import Title from 'antd/lib/typography/Title'

class CalculatorResults extends PureComponent {
  createHeaderSection = (title) => {
    return (
      <div>
        <Title level={4} className="assetdetail__calc-text-secondary">
          {title.toUpperCase()}
        </Title>
        <Title
          level={2}
          className="assetdetail__calc-text-primary"
          style={{ marginTop: 0 }}
        >
          RESULTS
        </Title>
      </div>
    )
  }

  createTableItem = (tableItem) => {
    const superMap = { 2: '²', 3: '³' }
    const { units, unitSuper, label, value, decimalPlaces, type } = tableItem

    const displayValue =
      type === 'string'
        ? value
        : units === '$'
        ? `$${numberWithCommas(value, decimalPlaces)}`
        : units.length > 1 && units[0] === '$'
        ? `$${numberWithCommas(value, decimalPlaces)}${units.substring(1)}`
        : `${numberWithCommas(value, decimalPlaces)} ${units}`

    return (
      <div key={label} className="assetdetail__calc-results-item">
        {label && (
          <Title
            level={4}
            className="assetdetail__calc-text-primary"
            style={{ margin: 0, textAlign: 'left' }}
          >
            {label}
          </Title>
        )}
        <Title
          level={4}
          className="assetdetail__calc-text-primary"
          style={{ margin: 0, marginLeft: 10, textAlign: 'right' }}
        >
          {displayValue + (superMap[unitSuper] || '')}
        </Title>
      </div>
    )
  }

  createTableSection = (tableData) => {
    return (
      <div className="assetdetail__calc-results-container">
        {tableData.map((tableItem) => this.createTableItem(tableItem))}
      </div>
    )
  }

  createResultItem = (resultItem, multiOutputResult) => {
    const { units, label, value, decimalPlaces, type } = resultItem

    const displayValue =
      type === 'string'
        ? value
        : units === '$'
        ? `$${numberWithCommas(value, decimalPlaces)}`
        : units.length > 1 && units[0] === '$'
        ? `$${numberWithCommas(value, decimalPlaces)}${units.substring(1)}`
        : units
        ? `${numberWithCommas(value, decimalPlaces)} ${units}`
        : numberWithCommas(value, decimalPlaces)

    return (
      <div
        key={label || displayValue}
        className="assetdetail__calc-results-item"
        style={{
          display: multiOutputResult ? 'block' : 'flex',
        }}
      >
        {label && (
          <Title
            level={3}
            className="assetdetail__calc-text-primary"
            style={{ margin: 0 }}
          >
            {label}
          </Title>
        )}

        <Title
          level={3}
          className="assetdetail__calc-text-primary"
          style={{ margin: 0, marginLeft: 10 }}
        >
          {displayValue}
        </Title>
      </div>
    )
  }

  createResultSection = (resultData) => {
    const isMultiOutputResult = resultData.some(
      ({ label }) => label === undefined
    )
    return (
      <div className="assetdetail__calc-results-container">
        {resultData.map((resultItem) =>
          this.createResultItem(resultItem, isMultiOutputResult)
        )}
      </div>
    )
  }

  createDisclaimerSection = (disclaimerText) => {
    return (
      <div>
        <p>{disclaimerText}</p>
      </div>
    )
  }
  formatFieldData = (fields) => {
    if (!fields) return null
    const results = this.props.results
    return fields
      .map((field) => {
        return {
          ...field,
          value: results[field.key],
        }
      })
      .filter((field) => results[field.key] !== null)
  }
  render() {
    const { title, disclaimerText, resultsFields, onDismiss } = this.props
    const tableData = this.formatFieldData(resultsFields.secondary)
    const resultData = this.formatFieldData(resultsFields.primary)
    return (
      <div className="assetdetail__calc-results">
        {/* Container */}
        <div>
          {/* Close Button */}
          <Title
            onClick={onDismiss}
            className="assetdetail__calc-results-close assetdetail__calc-text-primary"
          >
            X
          </Title>
          {/* Content */}
          <div className="assetdetail__calc-results-table">
            {/* Title */}
            {this.createHeaderSection(title)}
            {/* Secondary Results Table */}
            {tableData && this.createTableSection(tableData)}
            {/* Primary Result */}
            {resultData && this.createResultSection(resultData)}
            {/* Created By */}
            {/* Disclaimer */}
            {this.createDisclaimerSection(disclaimerText)}
          </div>
        </div>
      </div>
    )
  }
}

CalculatorResults.propTypes = {
  title: PropTypes.string,
  tableData: PropTypes.arrayOf(PropTypes.object),
  resultData: PropTypes.arrayOf(PropTypes.object),
  disclaimerText: PropTypes.string,
}

export default CalculatorResults
