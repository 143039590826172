import React from 'react'
import { List } from 'antd'
import StatusIcon from '../../../StatusIcon'
import Text from 'antd/lib/typography/Text'
import moment from 'moment'
import { getLabel } from '../../../../../dux/modules/utils'
import { Link } from 'react-router-dom'
import Title from 'antd/lib/typography/Title'

const AssetCard = ({
  item,
  allFolders,
  endpoints,
  currentSelectedHotspot,
  setSelectedHotspot,
}) => {
  if (!item) return <></>

  const assetType = getLabel(item)

  const currentSelectedHotspotStyle =
    item.index === currentSelectedHotspot
      ? { backgroundColor: 'rgb(55,55,55)' }
      : {}
  return (
    <List.Item
      style={{
        cursor: 'pointer',
        border: 'none',
        borderTop: '2px solid rgb(45,45,47)',
        ...currentSelectedHotspotStyle,
      }}
      onMouseOver={() => setSelectedHotspot(item.index)}
    >
      <Link to={`/library/asset/${item.id}`}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Title
            level={4}
            style={{
              margin: 0,
              marginLeft: 20,
              paddingRight: 10,
              color: '#EDEDED',
            }}
          >
            {item.index + 1}
          </Title>
          <List.Item.Meta
            avatar={<PreviewImage asset={item} />}
            title={
              <h3 style={{ color: '#EDEDED' }}>
                {item.displayText || item.title}
              </h3>
            }
            description={
              <>
                <StatusIcon {...{ allFolders, endpoints, item: item }} />
                <Text
                  type="secondary"
                  style={{ color: '#B5B5B5', paddingLeft: 10 }}
                >
                  {assetType} &sdot; {moment(item.updated).format('MM/DD/YY')}
                </Text>
              </>
            }
          />
        </div>
      </Link>
    </List.Item>
  )
}

const PreviewImage = ({ asset }) => {
  const imageView = (
    <div
      style={{
        backgroundImage: `url(${asset.preview})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        ...styles.previewSizing,
      }}
    ></div>
  )
  const iconView = (
    <div
      style={{
        backgroundColor: asset.color,
        ...styles.center,
        ...styles.previewSizing,
      }}
    >
      <i
        className={`fa ${asset.icon}`}
        style={{
          color: 'white',
          fontSize: 20,
        }}
      />
    </div>
  )

  if (asset.preview) return imageView

  return iconView
}

export const AssetList = ({
  listData,
  allFolders,
  endpoints,
  currentSelectedHotspot,
  setSelectedHotspot,
}) => (
  <div
    style={{
      height: '100%',
      width: '35%',
      minWidth: 300,
      borderLeft: '1px solid rgb(45,45,47)',
    }}
  >
    <div
      style={{
        margin: '0 auto',
        borderRadius: 3,
        width: '100%',
      }}
    >
      <List
        itemLayout="horizontal"
        dataSource={listData}
        locale={{
          emptyText: (
            <p style={{ color: 'white', marginTop: 14 }}>No related assets</p>
          ),
        }}
        rowKey={(item) => item.id}
        renderItem={(item) => (
          <AssetCard
            {...{
              item,
              allFolders,
              endpoints,
              currentSelectedHotspot,
              setSelectedHotspot,
            }}
            key={item.id}
          />
        )}
      />
    </div>
  </div>
)

const styles = {
  previewSizing: { width: 64, height: 64, borderRadius: 4, marginLeft: 12 },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
