import React, { Component } from 'react'
import * as folderActions from '../../../dux/actions/folder'
import { connect } from 'react-redux'

const styles = {
  form: { display: 'flex', flexDirection: 'column', color: 'black' },
  label: { fontWeight: '1000px', fontSize: '30px' },
  button: { color: 'black', width: 200 },
  disabled: { color: 'red' },
}

class BatchFolders extends Component {
  defaultText = `This utility takes a json array of folder path strings, 
  where each folder name is separated by a slash (/)`

  constructor(props) {
    super(props)
    this.handleFileChange = ({ target: { files } }) => {
      const reader = new FileReader()
      reader.onload = ({ target: { result } }) => {
        const data = JSON.parse(result)
        if (this.validate(data)) {
          this.setState({ data: data })
        } else {
          this.setState({ data: null })
        }
      }
      reader.readAsText(files[0])
    }
  }

  validate(json) {
    // all we need is an array of strings
    if (!Array.isArray(json)) {
      console.error('data is not an array')
      this.setState({ output: 'Data is invalid: not an array' })
      return false
    }

    if (!json) return false

    return true
  }

  submitHandler = (e) => {
    e.preventDefault()

    console.log('invoking addFolders', this.state.data)
    this.props.addFolders(this.state.data)
  }

  render() {
    const isValid = this.state && this.state.data
    const output =
      (this.state && this.state.output) || !isValid ? this.defaultText : ''

    return (
      <>
        <form style={styles.form} onSubmit={this.submitHandler}>
          <input
            type="file"
            onChange={this.handleFileChange}
            name="upload"
            id="upload"
            accept=".json"
          />
          <br />
          {isValid && (
            <button type="submit" style={styles.button} disabled={!isValid}>
              Start
            </button>
          )}
          {output && (
            <div>
              <pre>{output}</pre>
            </div>
          )}
        </form>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  addFolders: (paths) => {
    dispatch(folderActions.addFolders(paths))
  },
})

const mapStateToProps = (state) => ({
  allFolders: state.folders.byId,
})

export default connect(mapStateToProps, mapDispatchToProps)(BatchFolders)
