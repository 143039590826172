import { message } from 'antd'
import md5 from 'md5'
import React from 'react'
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects'
import { getS3Url } from '../modules/utils'
import * as apiActions from './api'
import * as appActions from './appActions'
import * as assetActions from './asset'

export const STRESS_TEST = 'STRESS_TEST'
export const RECONCILE_REFERENCES_REQUEST = 'RECONCILE_REFERENCES_REQUEST'
export const RECONCILE_REFERENCES = 'RECONCILE_REFERENCES'
export const SUBMIT_NEWASSET_REPORT = 'SUBMIT_NEWASSET_REPORT'
export const CREATE_SNAPSHOT = 'CREATE_SNAPSHOT'
export const SUBMIT_USER_AUDIT_REPORT = 'SUBMIT_USER_AUDIT_REPORT'
export const ADMIN_SIGNOUT_USER = 'ADMIN_SIGNOUT_USER'

export function stressTest(index, asset, parent, file, token) {
  return {
    type: STRESS_TEST,
    index,
    asset,
    parent,
    token,
    file,
  }
}

export function* watchStressTest() {
  yield takeEvery(STRESS_TEST, stressTestSaga)
}

export function* stressTestSaga(action) {
  console.log(`stressTesting: ${action.index}`)
  yield put({ type: appActions.INITIATE_ASYNC })

  //Generate a signed URL to put the article content in S3
  try {
    const config = yield select((state) => state.config.appConfig)
    const timestamp = yield call(Date.now)

    const uuid = md5(`${action.asset.title}${timestamp}`)
    const key = `active/stresstest/${uuid}`
    const url = `${config.baseUrl}/${key}`
    action.asset.key = key
    action.asset.url = url
    const body = action.file
    const params = {
      Bucket: config.bucket,
      ContentType: 'image/jpeg',
      Key: key,
    }
    const signedUrl = yield call(getS3Url, params, config)

    // Use the URL to save the item to S3
    // use the version with no auth header
    yield call(apiActions.fetchSaga, {
      url: signedUrl,
      init: {
        method: 'PUT',
        body,
      },
      s3Put: true,
    })

    yield call(assetActions.createAssetSaga, {
      type: assetActions.CREATE_ASSET,
      asset: action.asset,
      parent: action.parent,
      token: action.token,
    })
  } catch (error) {
    console.log(error, error.stack)
  }
}

export const reconcileRelationships = () => ({
  type: RECONCILE_REFERENCES_REQUEST,
})

export function* watchReconcileRelationships() {
  yield takeEvery(RECONCILE_REFERENCES_REQUEST, reconcileRelationshipsSaga)
}

export function* reconcileRelationshipsSaga() {
  try {
    yield put({ type: appActions.INITIATE_ASYNC })
    const config = yield select((state) => state.config.appConfig)
    const user = yield select((state) => state.user)

    /* Initiate State Reconciliation */
    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/state/reports/reconcile`,
      init: {
        method: 'POST',
        body: JSON.stringify({
          userId: user.id,
        }),
      },
    })
    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/state/addaction`,
      init: {
        method: 'POST',
        body: JSON.stringify({
          action: {
            type: RECONCILE_REFERENCES,
            user: user.id,
          },
          events: [],
        }),
      },
    })
    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    console.log(error)
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

export const submitNewAssetReport = (startDate, endDate) => ({
  type: SUBMIT_NEWASSET_REPORT,
  startDate,
  endDate,
})

export function* watchSubmitNewAssetReport() {
  yield takeEvery(SUBMIT_NEWASSET_REPORT, submitNewAssetReportSaga)
}

export function* submitNewAssetReportSaga(action) {
  try {
    const config = yield select((state) => state.config.appConfig)

    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/state/reports/newassets`,
      init: {
        method: 'POST',
        body: JSON.stringify({
          startDate: action.startDate,
          endDate: action.endDate,
        }),
      },
    })
  } catch (error) {
    console.log(error)
  }
}

export const createSnapshot = () => ({
  type: CREATE_SNAPSHOT,
})

export function* watchCreateSnapshot() {
  yield takeEvery(CREATE_SNAPSHOT, createSnapshotSaga)
}

export function* createSnapshotSaga() {
  try {
    const config = yield select((state) => state.config.appConfig)

    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/state/sns/update`,
      init: {
        method: 'GET',
      },
    })
    yield call(message.success, <span>Snapshot Requested</span>, 4)
  } catch (error) {
    yield call(message.error, <span>Snapshot Failed</span>, 4)
  }
}

export const submitUserAuditReport = () => ({
  type: SUBMIT_USER_AUDIT_REPORT,
})

export function* watchSubmitUserAuditReport() {
  yield takeLeading(SUBMIT_USER_AUDIT_REPORT, submitUserAuditReportSaga)
}

export function* submitUserAuditReportSaga() {
  try {
    const config = yield select((state) => state.config.appConfig)
    const user = yield select((state) => state.user)

    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/state/reports/useraudit`,
      init: {
        method: 'POST',
        body: JSON.stringify(user.email),
      },
    })
    yield call(message.success, <span>User Audit Report requested</span>, 4)
  } catch (error) {
    console.log(error)
  }
}

export const signoutUser = (user) => ({
  type: ADMIN_SIGNOUT_USER,
  user,
})

export function* watchSignoutUser() {
  yield takeEvery(ADMIN_SIGNOUT_USER, adminsignoutuserSaga)
}

export function* adminsignoutuserSaga(action) {
  try {
    const config = yield select((state) => state.config.appConfig)
    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/users/signout`,
      init: {
        method: 'POST',
        body: JSON.stringify(action.user),
      },
    })
    yield call(message.success, <span>User Sign Out requested</span>, 4)
  } catch (error) {
    console.log(error)
  }
}

export default all([
  watchStressTest(),
  watchReconcileRelationships(),
  watchSubmitNewAssetReport(),
  watchCreateSnapshot(),
  watchSubmitUserAuditReport(),
  watchSignoutUser(),
])
