import { Input, Select } from 'antd'
import React from 'react'

const Option = Select.Option

class SizeInput extends React.Component {
  static getDerivedStateFromProps(nextProps) {
    // Should be a controlled component.
    if ('value' in nextProps) {
      if (
        nextProps.value.number &&
        nextProps.value.units &&
        ['GB', 'MB'].indexOf(nextProps.value.units) > -1
      )
        return {
          ...(nextProps.value || {}),
        }
    }
    return null
  }

  constructor(props) {
    super(props)

    const value = props.value || {}
    this.state = {
      number: value.number || 0,
      units: value.units || 'MB',
    }
  }

  handleNumberChange = (e) => {
    let number = parseInt(e.target.value || 0, 10)
    if (Number.isNaN(number)) {
      return
    }

    if ('value' in this.props) {
      this.setState({ number }, () => this.triggerChange({ number }))
    }
  }

  handleUnitsChange = (units) => {
    if ('value' in this.props) {
      this.setState({ units }, () => this.triggerChange({ units }))
    }
  }

  triggerChange = (changedValue) => {
    // Should provide an event to pass value to Form.
    const onChange = this.props.onChange
    if (onChange) {
      onChange(Object.assign({}, this.state, changedValue))
    }
  }

  render() {
    const { size } = this.props
    const state = this.state
    return (
      <span>
        <Input
          type="number"
          size={size}
          value={state.number}
          onChange={this.handleNumberChange}
          style={{ width: '65%', marginRight: '3%' }}
        />
        <Select
          value={state.units}
          size={size}
          style={{ width: '32%' }}
          onChange={this.handleUnitsChange}
        >
          <Option value="MB">MB</Option>
          <Option value="GB">GB</Option>
        </Select>
      </span>
    )
  }
}

export default SizeInput
