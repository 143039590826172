import { Form, Input, Modal, Transfer } from 'antd'
import md5 from 'md5'
import PropTypes from 'prop-types'
import React from 'react'

const FormItem = Form.Item

class AddGroupModal extends React.Component {
  state = {
    targetKeys: [],
    selectedKeys: [],
  }

  saveFormRef = (ref) => (this.form = ref)

  handleChange = (nextTargetKeys) => {
    this.setState({ targetKeys: nextTargetKeys })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    })
  }

  onAdd = () => {
    this.form.validateFields((err, values) => {
      if (!err) {
        const id = md5(`${values.groupName}${Date.now()}`)
        const newGroup = {
          id,
          name: values.groupName,
          description: values.description,
          members: this.state.targetKeys,
          system: false,
        }
        this.props.permissionsActions.addGroup(newGroup)
      }
    })
  }
  render() {
    return (
      <Modal
        title="Add Group"
        okText="SAVE"
        onOk={this.onAdd}
        cancelText="Cancel"
        onCancel={this.props.modalActions.closeModal}
        visible={this.props.modal.modalOpen}
        confirmLoading={this.props.appState.asyncPending}
        maskClosable={false}
        width={this.props.appState.windowWidth > 768 ? 700 : 520}
      >
        <AddGroupForm
          ref={this.saveFormRef}
          roles={this.props.modalProps.roles}
          users={Object.keys(this.props.permissions.users)
            .map((u) => this.props.permissions.users[u])
            .sort((a, b) => a.email.localeCompare(b.email))}
          titles={['All Users (non-members)', 'Group Members']}
          targetKeys={this.state.targetKeys}
          selectedKeys={this.state.selectedKeys}
          handleChange={this.handleChange}
          handleSelectChange={this.handleSelectChange}
          windowWidth={this.props.appState.windowWidth}
        />
      </Modal>
    )
  }
}

const AddGroupForm = Form.create()((props) => {
  const { form } = props
  const { getFieldDecorator } = form

  return (
    <Form layout="vertical">
      <FormItem label="Group Name" require={true}>
        {getFieldDecorator('groupName', {
          initialValue: '',
          validateTrigger: 'onBlur',
          rules: [
            {
              required: true,
              message: 'Group Name is required',
            },
          ],
        })(<Input />)}
      </FormItem>
      <FormItem label="Group Description" require={true}>
        {getFieldDecorator('description', {
          initialValue: '',
        })(<Input />)}
      </FormItem>
      <FormItem label="Group Members">
        <Transfer
          dataSource={props.users}
          titles={props.titles}
          targetKeys={props.targetKeys}
          selectedKeys={props.selectedKeys}
          onChange={props.handleChange}
          onSelectChange={props.handleSelectChange}
          render={(item) => <span title={item.email}>{item.email}</span>}
          rowKey={(record) => record.email}
          listStyle={{ width: props.windowWidth > 768 ? 300 : 210 }}
        />
      </FormItem>
    </Form>
  )
})
/*
 *  Props are from the redux store
 *  "actions" are objects created by bindActionCreators, containing all relevant action functions
 *  modalProps is object passed from modal creator
 */
AddGroupModal.propTypes = {
  modal: PropTypes.object,
  modalActions: PropTypes.object,
  modalProps: PropTypes.object,
  appState: PropTypes.shape({
    asyncPending: PropTypes.bool,
  }),
  onSubmit: PropTypes.func,
}

AddGroupModal.defaultProps = {
  modalProps: {},
}

export default AddGroupModal
