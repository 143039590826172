import React from 'react'
import { Smartform } from './Smartform'
import Title from 'antd/lib/typography/Title'

import ProMach_IPAK_RFQ from '../../../../dev/forms/ProMach/IPAK_RFQ.form.json'
import Titan_MK1369 from '../../../../dev/forms/Titan/Titan_MK1369.form.json'
import Titan_MK1371 from '../../../../dev/forms/Titan/Titan_MK1371.form.json'
import Titan_MK1422 from '../../../../dev/forms/Titan/Titan_MK1422.form.json'
import Titan_MK1444 from '../../../../dev/forms/Titan/Titan_MK1444.form.json'
import Titan_MK1500 from '../../../../dev/forms/Titan/Titan_MK1500.form.json'

/**
 * Handy way to inject form data directly for development purposes.
 */
const ASSET_OVERRIDE = {
  'ProMach_IPAK_RFQ.form': ProMach_IPAK_RFQ,
  'Titan_MK1369.form': Titan_MK1369,
  'Titan_MK1371.form': Titan_MK1371, // general order form
  'Titan_MK1422.form': Titan_MK1422,
  'Titan_MK1444.form': Titan_MK1444,
  'Titan_MK1500.form': Titan_MK1500,
  // TODO: production URLs
}

/**
 * @typedef {{ id: string, extension: 'form', filename: string, title: string, url: string }} Asset
 * @param {{ asset: Asset }} props
 */
export function FormPreview(props) {
  const { asset } = props
  // BEGIN TODO: the following is what *should* be happening...the asset should be fetched from
  // its URL.  However, for form assets, the CORS headers are not set, which is preventing
  // the asset from loading.  Until that problem is resolved, we are hardcoding the assets
  // const [form, setForm] = useState()
  // useEffect(() => {
  //   fetch(props.asset.url).then(res => res.json()).then(setForm)
  // }, [props.asset.url])
  const formConfig = ASSET_OVERRIDE[asset.filename]
  // END TODO
  if (!formConfig)
    return (
      <div>
        <Title style={{ color: 'white' }}>Asset Not Found</Title>
        <p>
          Asset <code>{asset.url}</code> not found.
        </p>
      </div>
    )
  return (
    <div style={{ marginTop: '2em' }}>
      <Smartform formConfig={formConfig} />
    </div>
  )
}
