import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import Lightbox from 'react-image-lightbox'

const ImagePreview = (props) => {
  const suffix = props.thumbnail ? `_thumb-${props.thumbnail}` : ''
  return (
    <Fragment>
      <div
        className="assetdetail__preview-image"
        style={{
          backgroundImage: `url(${props.asset.preview}${suffix})`,
          height: props.asset.dimensions
            ? `${props.asset.dimensions.height}px`
            : '100%',
          width: props.asset.dimensions
            ? `${props.asset.dimensions.width}px`
            : '100%',
        }}
        onClick={props.openLightbox}
      >
        {props.children}
      </div>
      {props.showLightbox && (
        <Lightbox
          mainSrc={props.asset.preview}
          onCloseRequest={props.closeLightbox}
        />
      )}
    </Fragment>
  )
}

ImagePreview.propTypes = {
  thumbnail: PropTypes.string,
  asset: PropTypes.object,
  showLightbox: PropTypes.bool,
  openLightbox: PropTypes.func,
  closeLightbox: PropTypes.func,
}

ImagePreview.defaultProps = {
  showLightbox: false,
}
export default ImagePreview
