// BatchAssets.js

import React, { Component } from 'react'
import * as assetActions from '../../../dux/actions/asset'
import { connect } from 'react-redux'

const styles = {
  form: { display: 'flex', flexDirection: 'column', color: 'black' },
  label: { fontWeight: '1000px', fontSize: '30px' },
  button: { color: 'black', width: 200 },
  disabled: { color: 'red' },
}

class BatchAssets extends Component {
  defaultText = `This utility takes a json array of objects with the following keys: 
    filename (must be unique)
    title
    folderId
    size
    key`

  constructor(props) {
    super(props)
    this.handleFileChange = ({ target: { files } }) => {
      const reader = new FileReader()
      reader.onload = ({ target: { result } }) => {
        const data = JSON.parse(result)
        if (this.validate(data)) {
          this.setState({ data: data })
        }
      }
      reader.readAsText(files[0])
    }
  }

  validate(json) {
    // all we need is an array of strings
    try {
      if (!Array.isArray(json)) {
        console.error('data is not an array')
        throw 'Data is invalid: needs to be a JSON array of objects with filename, title, folderId, size, and key'
      }

      json.forEach((obj) => {
        if (!obj.filename) throw 'One or more objects is missing filename'
        if (!obj.title) throw 'One or more objects is missing title'
        if (!obj.folderId) throw 'One or more objects is missing folderId'
        if (!obj.size) throw 'One or more objects is missing size'
        if (!obj.key) throw 'One or more objects is missing key'
      })
    } catch (error) {
      console.log(error)
      this.setState({
        output: error,
      })
      return false
    }

    return true
  }

  submitHandler = (e) => {
    e.preventDefault()

    console.log('invoking create assets', this.state.data)
    this.props.createAssets(this.state.data)
  }

  render() {
    const isValid = this.state && this.state.data
    const output =
      (this.state && this.state.output) || !isValid ? this.defaultText : ''

    return (
      <>
        <form style={styles.form} onSubmit={this.submitHandler}>
          <input
            type="file"
            onChange={this.handleFileChange}
            name="upload"
            id="upload"
            accept=".json"
          />
          <br />
          {isValid && (
            <button type="submit" style={styles.button} disabled={!isValid}>
              Start
            </button>
          )}
          {output && (
            <div>
              <pre>{output}</pre>
            </div>
          )}
        </form>
      </>
    )
  }
}

// What will happen when we call these sagas in a loop like this?

const mapDispatchToProps = (dispatch) => ({
  createAssets: (data) => {
    dispatch(assetActions.batchCreateAssets(data))
  },
})
const mapStateToProps = (state) => ({
  user: state.user,
})
export default connect(mapStateToProps, mapDispatchToProps)(BatchAssets)
