import { Button, Modal } from 'antd'
import React from 'react'

const DownloadModal = (props) => {
  const footer = (
    <div>
      <Button onClick={props.modal.modalProps.originalHandler}>Original</Button>
      <Button onClick={props.modal.modalProps.transcodeHandler}>
        Transcoded
      </Button>
      <Button onClick={props.modalActions.closeModal}>Cancel</Button>
    </div>
  )
  return (
    <Modal
      title={props.modal.modalProps.title}
      visible={props.modal.modalOpen}
      confirmLoading={props.appState.asyncPending}
      maskClosable={false}
      closable={false}
      footer={footer}
    >
      <div className="modal__text">
        This video has been transcoded to MP4 format. You can download either
        the original file or the transcoded version.
      </div>
    </Modal>
  )
}

export default DownloadModal
