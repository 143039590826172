import { Button, Modal } from 'antd'
import React from 'react'
import '../styles/Modal.css'

class UnpublishAssetModal extends React.Component {
  render() {
    const { modalProps, appState, modal, modalActions } = this.props

    const message =
      modalProps.message ||
      (modalProps.assets.length > 1
        ? 'Unpublishing these assets will remove them from the endpoint.  Continue?'
        : 'Unpublishing this asset will remove it from the endpoint.  Continue?')

    return (
      <Modal
        title="Unpublish Asset"
        okText="PUBLISH"
        onOk={this.save}
        cancelText="Cancel"
        footer={[
          <Button key="cancel" onClick={modalActions.closeModal}>
            Cancel
          </Button>,
          <Button
            key="publish"
            type="primary"
            onClick={modalProps.requestFunc}
            loading={appState.asyncPending}
          >
            REQUEST
          </Button>,
          <Button
            key="approve"
            type="primary"
            onClick={() => modalProps.approveFunc()}
            loading={appState.asyncPending}
          >
            REQUEST and APPROVE
          </Button>,
        ]}
        onCancel={modalActions.closeModal}
        visible={modal.modalOpen}
        confirmLoading={appState.asyncPending}
        maskClosable={false}
      >
        <div className="modal__text">{message}</div>
      </Modal>
    )
  }
}

export default UnpublishAssetModal
