import React from 'react'
import { Divider, List } from 'antd'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'
import moment from 'moment'
import { Link } from 'react-router-dom'

import StatusIcon from '../../StatusIcon'
import { getLabel } from '../../../../dux/modules/utils'

const HeaderImage = ({ image }) => {
  if (!image) return null
  return (
    <div
      style={{
        backgroundImage: `url(${image.url}`,
        ...styles.headerImage,
      }}
    ></div>
  )
}

const PreviewImage = ({ asset }) => {
  const imageView = (
    <div
      style={{
        backgroundImage: `url(${asset.preview})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        ...styles.previewSizing,
      }}
    ></div>
  )
  const iconView = (
    <div
      style={{
        backgroundColor: asset.color,
        ...styles.center,
        ...styles.previewSizing,
      }}
    >
      <i
        className={`fa ${asset.icon}`}
        style={{
          color: 'white',
          fontSize: 20,
        }}
      />
    </div>
  )

  if (asset.preview) return imageView

  return iconView
}

const AssetCard = ({ item, allFolders, endpoints }) => {
  if (!item) return <></>

  const assetType = getLabel(item)

  return (
    <List.Item style={{ cursor: 'pointer' }} key={item.id}>
      <Link to={`/library/asset/${item.id}`}>
        <List.Item.Meta
          avatar={<PreviewImage asset={item} />}
          title={
            <h3 style={{ color: '#EDEDED' }}>
              {item.displayText || item.title}
            </h3>
          }
          description={
            <>
              <StatusIcon {...{ allFolders, endpoints, item: item }} />
              <Text
                type="secondary"
                style={{ color: '#B5B5B5', paddingLeft: 10 }}
              >
                {assetType} &sdot; {moment(item.updated).format('MM/DD/YY')}
              </Text>
            </>
          }
        />
      </Link>
    </List.Item>
  )
}

function FeaturePreview({ asset, allAssets, allFolders, endpoints }) {
  if (!asset) return null

  const { feature, description, title } = asset
  const featureImageAsset = allAssets[feature?.image]

  // If there is no Header image, apply some margin to the top of the title
  const titleMarginTop = featureImageAsset ? {} : { marginTop: 25 }
  console.log({
    feature: feature.relatedAssets.map(
      (item) => allAssets[item.assetId || item]
    ),
  })

  // There are two shapes relatedAssets can take, id[] or {assetId: id}[]
  const listData = feature?.relatedAssets
    .filter((asset) => allAssets[asset.assetId || asset])
    .map((asset) => {
      // If it's just an id, return the asset
      if (typeof asset === 'string') return allAssets[asset]
      // Spread in feature-specific info like displayText
      return { ...allAssets[asset.assetId], ...asset, id: asset.assetId }
    })
  return (
    <div
      className="assetdetail__preview"
      style={{ overflow: 'auto', paddingBottom: 25, width: 600 }}
    >
      {/* FEATURE IMAGE */}
      {feature.image && <HeaderImage image={featureImageAsset} />}

      {/* TITLE / DESCRIPTION */}
      <div style={{ ...styles.titleArea, ...titleMarginTop }}>
        <Title style={styles.titleText} level={2}>
          {title}
        </Title>
        {description && (
          <Text
            type="secondary"
            style={{ color: '#B5B5B5', whiteSpace: 'pre-line' }}
          >
            {description}
          </Text>
        )}
      </div>

      {/* RELATED ASSETS LIST */}
      {listData && listData.length ? (
        <>
          <Divider style={styles.maxContentWidth} orientation="left" />
          <div
            style={{
              margin: '0 auto',
              backgroundColor: '#2D2D2F',
              borderRadius: 3,
              borderBottom: '1px solid #EDEDED',
              marginLeft: 50,
              maxWidth: 500,
            }}
          >
            <List
              itemLayout="horizontal"
              dataSource={listData}
              locale={{
                emptyText: (
                  <p style={{ color: 'white', marginTop: 14 }}>
                    No related assets
                  </p>
                ),
              }}
              renderItem={(item) => (
                <AssetCard {...{ item, allFolders, endpoints }} />
              )}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

const styles = {
  previewSizing: { width: 64, height: 64, borderRadius: 4, marginLeft: 12 },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fill: {
    width: '100%',
    height: '100%',
  },
  headerImage: {
    margin: '0 auto',
    marginTop: 25,
    backgroundSize: 'contain',
    backgroundPosition: 'left',
    backgroundRepeat: 'no-repeat',
    minHeight: 100,
    maxHeight: 400,
    height: '40%',
    width: '80%',
  },
  titleArea: {
    width: '80%',
    margin: '0 auto',
    minHeight: 125,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-around',
    justifyContent: 'center',
    maxWidth: 500,
  },
  titleText: {
    color: 'white',
    display: 'flex',
    justifySelf: 'flex-start',
    marginTop: 12,
  },
  articleContent: { margin: 0, marginLeft: 50, maxWidth: 600 },
}

export default FeaturePreview
