import { Checkbox } from 'antd'
import Title from 'antd/lib/typography/Title'
import React from 'react'

function CalculatorCheckbox({ label, defaultValue, onChange, id, dataType }) {
  return (
    <div>
      <Title level={4} className="assetdetail__calc-text-secondary">
        {label}
      </Title>
      <Checkbox
        checked={defaultValue}
        onChange={() => onChange(id, !defaultValue, dataType)}
      ></Checkbox>
    </div>
  )
}

export default CalculatorCheckbox
