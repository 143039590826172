import { Form, Input } from 'antd'
import React from 'react'

const DisplayTextOverride = Form.create()((props) => {
  const { form } = props
  const { getFieldDecorator } = form
  return (
    <Form>
      <div className="builders__padded-div">
        {props.textOverrideDescription}
        <Form.Item label="Display Text Override" style={{ marginBottom: 0 }}>
          {getFieldDecorator('displayText', {
            initialValue: props.value,
            validateTrigger: 'onChange',
            rules: [
              {
                max: props.maxLength,
                message: `Maximum text length is ${props.maxLength}`,
              },
            ],
          })(<Input />)}
        </Form.Item>
      </div>
    </Form>
  )
})

export default DisplayTextOverride
