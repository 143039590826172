import { Button, Input, Menu, Modal, Transfer } from 'antd'
import React from 'react'
import { MAX_GROUP_NAME_LENGTH } from '../../dux/constants/groups'
import EditableChip from '../BAM_UI/EditableChip'
import PermissionsTree from '../permissions/PermissionsTree'
import { getChangedContentRoles } from '../permissions/utils/permissionsUtils'

class EditGroupsModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      groupName:
        this.props.permissions.groups[this.props.modalProps.group].name,
      targetKeys: Object.keys(this.props.permissions.users)
        .filter(
          (u) =>
            this.props.permissions.groups[
              this.props.modalProps.group
            ].members.indexOf(u) > -1
        )
        .sort((a, b) => a.localeCompare(b)),
      selectedKeys: [],
      selectedTab: 'members',
      savedContentRoles: undefined,
      footer: (
        <div>
          <Button onClick={this.props.modalActions.closeModal}>Cancel</Button>
          <Button type="primary" onClick={this.onSave}>
            Save
          </Button>
        </div>
      ),
      description:
        this.props.permissions.groups[this.props.modalProps.group].description,
    }
  }

  saveTreeRef = (ref) => (this.tree = ref)

  handleNameChange = (value) => {
    this.setState({ groupName: value })
  }

  handleMenuClick = (value) => {
    const savedRoles = this.tree && this.tree.getContentRoles()
    if (value.key === 'members') this.setFooter()

    this.setState((prevState) => ({
      selectedTab: value.key,
      savedContentRoles:
        value.key === 'members' ? savedRoles : prevState.savedContentRoles,
    }))
  }

  handleChange = (nextTargetKeys) => {
    this.setState({
      targetKeys: nextTargetKeys.sort((a, b) => a.localeCompare(b)),
    })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    })
  }

  handleDescriptionChange = (e) => {
    e.persist()
    this.setState(() => ({ description: e.target.value }))
  }

  setFooter = (ftr) => {
    const footer = (
      <div className="permissionstree__footer-container">
        <span>{ftr}</span>
        <div>
          <Button onClick={this.props.modalActions.closeModal}>Cancel</Button>
          <Button type="primary" onClick={this.onSave}>
            Save
          </Button>
        </div>
      </div>
    )
    this.setState(() => ({ footer }))
  }

  searchFilter = (inputValue, option) =>
    option.email.toLowerCase().indexOf(inputValue.toLowerCase()) > -1

  onSave = () => {
    if (this.state.groupName) {
      let newGroup = {
        ...this.props.permissions.groups[this.props.modalProps.group],
        description: this.state.description,
        members: this.state.targetKeys,
        name: this.state.groupName,
      }
      this.props.permissionsActions.updateGroup(newGroup)
    }
    const rolesToCheck = this.tree
      ? this.tree.getContentRoles()
      : this.state.savedContentRoles
    if (rolesToCheck) {
      const updates = getChangedContentRoles(
        rolesToCheck,
        this.props.folders.byId
      )
      if (Object.keys(updates).length > 0) {
        this.props.folderActions.bulkContentRoleUpdate(updates)
      }
    }
  }

  permissionsDisabled = () => {
    const { modalProps, user, permissions } = this.props
    return (
      this.isSystemGroup(modalProps.group) ||
      permissions.groups['SYS.contentadmins'].members.indexOf(user.email) < 0
    )
  }

  isSystemGroup = (group) => this.props.permissions.groups[group].system

  render() {
    const { modalProps, appState, modal, permissions, folders, endpoints } =
      this.props
    return (
      <Modal
        title={
          <EditableChip
            displayText={this.state.groupName}
            callback={this.handleNameChange}
            maxLength={MAX_GROUP_NAME_LENGTH}
            disabled={this.isSystemGroup(modalProps.group)}
          />
        }
        footer={this.state.footer}
        visible={modal.modalOpen}
        confirmLoading={appState.asyncPending}
        maskClosable={false}
        closable={false}
        width={1200}
        className="modal__container-nopadding"
      >
        <Menu
          onClick={this.handleMenuClick}
          selectedKeys={[this.state.selectedTab]}
          mode="horizontal"
        >
          <Menu.Item key="members">Members</Menu.Item>
          <Menu.Item key="permissions" disabled={this.permissionsDisabled()}>
            Permissions
          </Menu.Item>
        </Menu>
        {this.state.selectedTab === 'members' ? (
          <div>
            <div className="permissions__input-container">
              <span className="permissions__input-label">Description</span>
              <Input.TextArea
                autosize={{ minRows: 3, maxRows: 8 }}
                defaultValue={this.state.description}
                placeholder="Add group description here"
                disabled={permissions.groups[modalProps.group].system}
                onChange={this.handleDescriptionChange}
              />
            </div>
            <Transfer
              className="permissions__transfer"
              dataSource={Object.keys(permissions.users)
                .map((u) => permissions.users[u])
                .sort((a, b) => a.email.localeCompare(b.email))}
              titles={['All Users', 'Members']}
              targetKeys={this.state.targetKeys}
              selectedKeys={this.state.selectedKeys}
              onChange={this.handleChange}
              onSelectChange={this.handleSelectChange}
              render={(item) => <span title={item.email}>{item.email}</span>}
              listStyle={{ width: 500 }}
              rowKey={(record) => record.email}
              showSearch={true}
              filterOption={this.searchFilter}
            />
          </div>
        ) : (
          <PermissionsTree
            ref={this.saveTreeRef}
            endpoints={endpoints.endpoints}
            roles={Object.keys(permissions.roles)
              .filter((role) => permissions.roles[role].contentRole)
              .sort(
                (role1, role2) =>
                  permissions.roles[role1].sortOrder -
                  permissions.roles[role2].sortOrder
              )
              .map((role) => permissions.roles[role])}
            folders={folders.byId}
            entity={modalProps.group}
            entityType="group"
            savedContentRoles={this.state.savedContentRoles}
            setFooter={this.setFooter}
          />
        )}
      </Modal>
    )
  }
}

export default EditGroupsModal
