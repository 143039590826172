export const moneyRound = (num) => {
  if (!num) {
    const err = `not a number: ${num}`
    console.log(err)
  }
  return Math.round(num * 100) / 100
}

export const decRound = (num, dec = 1) => {
  if (!num) {
    const err = `not a number: ${num}`
    console.log(err)
  }
  if (dec === 0) return 0
  return Math.ceil(num * (100 * dec)) / (100 * dec)
}

export const toPickerObj = (dataList) => {
  return dataList.map((item) => ({
    label: `${item}`,
    value: `${item}`,
    color: `#969696`,
  }))
}
export function mapToObj(map) {
  const obj = {}
  map.forEach((value, key) => {
    if (value instanceof Map) {
      const nestedObj = {}
      value.forEach((val, k) => (nestedObj[k] = val))
      obj[key] = nestedObj
    } else {
      obj[key] = value
    }
  })
  return obj
}

//todo: modify regex to ignore any digit after the decimal point to allow decimal place setting to
// be greater than 3 without adding commas ex. 90.5,043
export const numberWithCommas = (x, decimalPlaceSetting = 2) => {
  let decimalPlaces
  if (decimalPlaceSetting > 3) {
    decimalPlaces = 3
  } else if (decimalPlaceSetting < 0) {
    decimalPlaces = 0
  } else decimalPlaces = decimalPlaceSetting
  return x.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const toDecimalString = (string) => {
  return string != null ? string.replace(/[^0-9.]/g, '') : ''
}

export const indexOfRange = (dataArr, dataVal) => {
  return dataArr.findIndex((dataItem, index, dataArray) => {
    const min = index === 0 ? 0 : dataArray[index - 1]
    const max = dataArray[index]

    return dataVal >= min && dataVal <= max
  })
}

export const valueOfRange = (dataArr, dataVal) => {
  return dataArr.find((dataItem, index, dataArray) => {
    const min = index === 0 ? 0 : dataArray[index - 1]
    const max = dataArray[index]

    return dataVal >= min && dataVal <= max
  })
}

export const defaultDisclaimer = `Due to changing operating and material conditions prevalent in any material processing, the above figures should be considered as theoretical estimates only and are based on assumed input values provided by the customer. Kolberg-Pioneer, Inc., Johnson Crushers International, Inc. and Astec Mobile Screens, Inc.  make no guarantee of actual field results, and therefore, no production performance or warranty applies.`

const error = {
  nil: new Error('#NULL!'),
  div0: new Error('#DIV/0!'),
  value: new Error('#VALUE?'),
  ref: new Error('#REF!'),
  name: new Error('#NAME?'),
  num: new Error('#NUM!'),
  na: new Error('#N/A'),
  error: new Error('#ERROR!'),
  data: new Error('#GETTING_DATA'),
}

const anyIsError = function () {
  var n = arguments.length
  while (n--) {
    if (arguments[n] instanceof Error) {
      return true
    }
  }
  return false
}

const parseNumber = function (string) {
  if (string === undefined || string === '') {
    return error.value
  }
  if (!isNaN(string)) {
    return parseFloat(string)
  }
  return error.value
}

export const CUMIPMT = function (rate, periods, value, start, end, type) {
  // Credits: algorithm inspired by Apache OpenOffice
  // Credits: Hannes Stiebitzhofer for the translations of function and variable names
  // Requires exports.FV() and exports.PMT() from exports.js [http://stoic.com/exports/]

  rate = parseNumber(rate)
  periods = parseNumber(periods)
  value = parseNumber(value)
  if (anyIsError(rate, periods, value)) {
    return error.value
  }

  // Return error if either rate, periods, or value are lower than or equal to zero
  if (rate <= 0 || periods <= 0 || value <= 0) {
    return error.num
  }

  // Return error if start < 1, end < 1, or start > end
  if (start < 1 || end < 1 || start > end) {
    return error.num
  }

  // Return error if type is neither 0 nor 1
  if (type !== 0 && type !== 1) {
    return error.num
  }

  // Compute cumulative interest
  var payment = PMT(rate, periods, value, 0, type)
  var interest = 0

  if (start === 1) {
    if (type === 0) {
      interest = -value
      start++
    }
  }

  for (var i = start; i <= end; i++) {
    if (type === 1) {
      interest += FV(rate, i - 2, payment, value, 1) - payment
    } else {
      interest += FV(rate, i - 1, payment, value, 0)
    }
  }
  interest *= rate

  // Return cumulative interest
  return interest
}

const FV = function (rate, periods, payment, value, type) {
  // Credits: algorithm inspired by Apache OpenOffice

  value = value || 0
  type = type || 0

  rate = parseNumber(rate)
  periods = parseNumber(periods)
  payment = parseNumber(payment)
  value = parseNumber(value)
  type = parseNumber(type)
  if (anyIsError(rate, periods, payment, value, type)) {
    return error.value
  }

  // Return future value
  var result
  if (rate === 0) {
    result = value + payment * periods
  } else {
    var term = Math.pow(1 + rate, periods)
    if (type === 1) {
      result = value * term + (payment * (1 + rate) * (term - 1)) / rate
    } else {
      result = value * term + (payment * (term - 1)) / rate
    }
  }
  return -result
}

const PMT = function (rate, periods, present, future, type) {
  // Credits: algorithm inspired by Apache OpenOffice

  future = future || 0
  type = type || 0

  rate = parseNumber(rate)
  periods = parseNumber(periods)
  present = parseNumber(present)
  future = parseNumber(future)
  type = parseNumber(type)
  if (anyIsError(rate, periods, present, future, type)) {
    return error.value
  }

  // Return payment
  var result
  if (rate === 0) {
    result = (present + future) / periods
  } else {
    var term = Math.pow(1 + rate, periods)
    if (type === 1) {
      result =
        ((future * rate) / (term - 1) + (present * rate) / (1 - 1 / term)) /
        (1 + rate)
    } else {
      result = (future * rate) / (term - 1) + (present * rate) / (1 - 1 / term)
    }
  }
  return -result
}

export default {
  moneyRound,
  decRound,
  toPickerObj,
  toDecimalString,
  indexOfRange,
  valueOfRange,
  defaultDisclaimer,
  anyIsError,
  parseNumber,
  CUMIPMT,
  FV,
  PMT,
}
