import update from 'immutability-helper'
import md5 from 'md5'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import * as folderConstants from '../constants/folder'
import utils from '../modules/DragDropUtils'
import SessionTimeoutError from '../modules/SessionTimeoutError'
import {
  buildPath,
  containsPublishedAsset,
  requiresTraverserRole,
} from '../modules/utils'
import * as apiActions from './api'
import * as appActions from './appActions'
import * as modalActions from './modal'
import { createSnapshot } from './superuser'
import * as userActions from './user'

//Folder action types
export const LOAD_FOLDER_STATE = 'LOAD_FOLDER_STATE'
export const ADD_FOLDER = 'ADD_FOLDER'
export const ADD_FOLDERS = 'ADD_FOLDERS'
export const ADD_FOLDER_SUCCESS = 'ADD_FOLDER_SUCCESS'
export const ADD_FOLDER_FAILURE = 'ADD_FOLDER_FAILURE'
export const RENAME_FOLDER = 'RENAME_FOLDER'
export const RENAME_FOLDER_SUCCESS = 'RENAME_FOLDER_SUCCESS'
export const RENAME_FOLDER_FAILURE = 'RENAME_FOLDER_FAILURE'
export const MOVE_FOLDER = 'MOVE_FOLDER'
export const MOVE_FOLDER_SUCCESS = 'MOVE_FOLDER_SUCCESS'
export const EMPTY_RECYCLE_BIN = 'EMPTY_RECYCLE_BIN'
export const EMPTY_RECYCLE_BIN_SUCCESS = 'EMPTY_RECYCLE_BIN_SUCCESS'
export const DELETE_FOLDER = 'DELETE_FOLDER'
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS'
export const ACTIVE_FOLDER = 'ACTIVE_FOLDER'
export const SET_ACTIVE_FOLDER = 'SET_ACTIVE_FOLDER'
export const SELECT_FOLDER = 'SELECT_FOLDER'
export const CLEAR_SELECTED_FOLDERS = 'CLEAR_SELECTED_FOLDERS'
export const SET_NEW_FOLDERNAME = 'SET_NEW_FOLDERNAME'
export const SET_VIRTUAL_FOLDERS = 'folders/SET_VIRTUAL_FOLDERS'
export const UPDATE_FOLDER_CONTENTROLES = 'UPDATE_FOLDER_CONTENTROLES'
export const UPDATE_FOLDER_CONTENTROLES_SUCCESS =
  'UPDATE_FOLDER_CONTENTROLES_SUCCESS'
export const BULK_CONTENTROLE_UPDATE = 'BULK_CONTENTROLE_UPDATE'
export const BULK_CONTENTROLE_UPDATE_SUCCESS = 'BULK_CONTENTROLE_UPDATE_SUCCESS'
export const REORDER_CHILD_FOLDERS = 'REORDER_CHILD_FOLDERS'

//Action creators
/*
 * addFolder = public facing action creator for addFolders saga
 *   Param: name - the name of the folder to add
 *   Param: parent - the parent folder object
 *   Param: owner - the user ID of the owner
 *   Param: client - the ID of the client
 *   Param: permissions - the contentRoles for the folder
 *   Param: path - an array of this folder's parent folders
 *   Parma: allFolders - folders.byId object
 *   Parma: [folderType] - folder type, as defined in the folder constants
 *   Param: [id] - optional folder ID to use
 */
export function addFolder(
  name,
  parent,
  owner,
  client,
  permissions,
  path,
  allFolders,
  folderType,
  id
) {
  return {
    type: ADD_FOLDER,
    name,
    parent,
    owner,
    client,
    permissions,
    path,
    allFolders,
    folderType,
    id,
  }
}

/*
 * watchAddFolder - watcher function to initiate addFolderSaga
 */
export function* watchAddFolder() {
  yield takeEvery(ADD_FOLDER, addFolderSaga)
}

/*
 *   Function: addFolder
 *   Purpose: Dispatch an action to add a folder (initiated by right click in sidebar)
 */
export function* addFolderSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC })
  try {
    const config = yield select((state) => state.config.appConfig)
    const timestamp = yield call(Date.now)
    action.user = action.owner
    action.folder = {
      id: action.id || md5(`${action.name}${action.user}${timestamp}`),
      name: action.name,
      parentId: action.parent.id,
      childFolders: [],
      assets: [],
      createdDt: timestamp,
      owner: action.owner,
      client: action.client,
      deleted: false,
      type: action.folderType || folderConstants.DEFAULT_FOLDER_TYPE,
      contentRoles: action.permissions,
    }
    let events = []

    /* Check parent folders for traverse access for any non-system groups that have access to
    the new folder */
    for (let folder of action.path) {
      const entities = Object.keys(action.permissions).filter(
        (cr) => !cr.startsWith('SYS.')
      )
      for (let entity of entities) {
        if (
          !(
            action.allFolders[folder].contentRoles[entity] &&
            action.allFolders[folder].contentRoles[entity].roleIds.length > 0
          )
        ) {
          let updatedContentRoles = Object.assign(
            {},
            action.allFolders[folder].contentRoles,
            {
              [entity]: {
                entityId: action.permissions[entity].entityId,
                entityType: action.permissions[entity].entityType,
                roleIds: action.allFolders[folder].contentRoles[entity]
                  ? action.allFolders[folder].contentRoles[
                      entity
                    ].roleIds.concat(['traverser'])
                  : ['traverser'],
              },
            }
          )

          yield call(updateFolderSaga, {
            type: UPDATE_FOLDER_CONTENTROLES,
            folder: action.allFolders[folder],
            path: action.path,
            allFolders: action.allFolders,
            checkTraverseRole: false,
            contentRoles: updatedContentRoles,
          })
        }
      }
    }

    // drop allFolders before saving the cloud action!
    delete action.allFolders

    console.log({ action, events })

    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/state/addaction`,
      init: {
        method: 'POST',
        body: JSON.stringify({ action, events }),
      },
    })

    yield put({ type: ADD_FOLDER_SUCCESS, folder: action.folder })
    const openFolderKeys = yield select(
      (state) => state.appState.openFolderKeys
    )
    if (openFolderKeys.indexOf(action.parent.id) < 0) {
      yield put({ type: appActions.ADD_OPEN_FOLDERKEY, id: action.parent.id })
    }
    yield put({ type: modalActions.CLOSE_MODAL })
  } catch (error) {
    console.error(error)
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: error.message,
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
      yield put({ type: SET_NEW_FOLDERNAME, name: '' })
    }
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

export function addFolders(paths) {
  return {
    type: ADD_FOLDERS,
    paths,
  }
}

export function* watchAddFolders() {
  yield takeEvery(ADD_FOLDERS, addFoldersSaga)
}

export function* addFoldersSaga(action) {
  console.log('addFoldersSaga', action)

  const user = yield select((state) => state.user)
  const config = yield select((state) => state.config.appConfig)

  // keep track of paths and associated ids
  const folderIdsByPath = {}
  const results = {}

  // keep track of number of segments in the path
  let segmentsLength = 1

  // iterate over all paths in action
  for (let i = 0; i < action.paths.length; ++i) {
    try {
      const path = action.paths[i]
      console.log(`creating folder ${i + 1} of ${action.paths.length}: ${path}`)

      // keep track of number of segments in the path-
      // because the paths are sorted by number of segments, this number should only
      // increase.
      // every time the number increases, make sure a call to update is made in order
      // to make sure the a snapshot is produced.
      const pathSegmentsLength = (path.match(/\//g) || []).length + 1
      if (pathSegmentsLength > segmentsLength) {
        segmentsLength = pathSegmentsLength
        const response = yield call(apiActions.secureFetchSaga, {
          url: `${config.baseUrl}/api/state/sns/update`,
          init: {
            method: 'GET',
          },
        })
        console.log(
          'asked for state update because of path segments increase',
          response
        )
      }
      // for each path, use findOrCreateFolderWithPath to determine whether the folder
      // already exists and if not create one
      const result = yield call(
        findOrCreateFolderWithPath,
        path,
        user,
        results,
        folderIdsByPath
      )
      // if one folder fails, abort saga altogether
      if (result === -1) {
        console.log('folder could not be created; aborting', {
          path,
        })
        break
      }
      // every 15 folders processed, trigger a state snapshot
      if (i > 0 && i % 15 === 0) {
        const response = yield call(apiActions.secureFetchSaga, {
          url: `${config.baseUrl}/api/state/sns/update`,
          init: {
            method: 'GET',
          },
        })
        console.log('asked for state update', response)
      }
    } catch (error) {
      // if one folder fails, abort saga altogether
      console.log('addFoldersSaga folder creation error: ' + action.paths[i])
      break
    }
  }

  // one final state snapshot
  const response = yield call(apiActions.secureFetchSaga, {
    url: `${config.baseUrl}/api/state/sns/update`,
    init: {
      method: 'GET',
    },
  })
  console.log('asked for state update', response)

  const createdCount = results['CREATED_COUNT'] || 0
  delete results['CREATED_COUNT']
  console.log({ results, createdCount })
}

const corePermissions = {
  'SYS.traversers': {
    entityType: 'group',
    entityId: 'SYS.traversers',
    roleIds: ['traverser'],
  },
  'SYS.viewers': {
    entityType: 'group',
    entityId: 'SYS.viewers',
    roleIds: ['viewer'],
  },
  'SYS.editors': {
    entityType: 'group',
    entityId: 'SYS.editors',
    roleIds: ['editor'],
  },
  'SYS.creators': {
    entityType: 'group',
    entityId: 'SYS.creators',
    roleIds: ['creator'],
  },
  'SYS.approvers': {
    entityType: 'group',
    entityId: 'SYS.approvers',
    roleIds: ['approver'],
  },
  'SYS.publishers': {
    entityType: 'group',
    entityId: 'SYS.publishers',
    roleIds: ['publisher'],
  },
  'SYS.contentadmins': {
    entityType: 'group',
    entityId: 'SYS.contentadmins',
    roleIds: ['contentadmin'],
  },
}

function folderIdWithNameAndParent(
  name,
  parentId,
  allFolders,
  folderIdsByPath
) {
  console.log('folderIdWithNameAndParent', { name, parentId })

  console.log({ allFolders })
  // find a folder in the redux state, based on path

  let parentFolder = allFolders[parentId]
  console.log({ parentFolder })
  if (!parentFolder) {
    console.log('parent folder not found in allFolders')
    // parent folder does not exist - it may have just been created
    const parentFolderPath = Object.keys(folderIdsByPath).find(
      (key) => folderIdsByPath[key] === parentId
    )
    if (parentFolderPath) {
      console.log(
        `parent folder ${parentFolderPath} found in local state: ${parentId}`
      )
      const folderIdByPath = folderIdsByPath[`${parentFolderPath}/${name}`]
      if (!folderIdByPath) {
        console.log('folder not found in parent folder')
      }
      return folderIdByPath
    }
    // if no folder is found, return null
    console.error(`no folder found for id: ${parentId}`)
    return null
  }

  // sometimes a folder does not have a childFolders prop- why? perhaps because
  // that prop is not always created when the folder is?
  if (!parentFolder.childFolders) return null

  // find a folder with a matching parent and name
  try {
    const childFolderId = parentFolder.childFolders.find(
      (childId) => allFolders[childId].name === name
    )
    if (!childFolderId) return null
    return childFolderId
  } catch {
    console.log('error fetching child folder', { parentFolder })
    return null
  }
}

function folderIdForPath(folderPath, allFolders, folderIdsByPath) {
  console.log('folderIdForPath', { folderPath })

  // if the folder was just created, it will be in the folderIdsByPath object
  if (folderIdsByPath && folderIdsByPath[folderPath]) {
    console.log('folder id for path found in folderIdsByPath cache', {
      folderPath,
      folderId: folderIdsByPath[folderPath],
    })
    return folderIdsByPath[folderPath]
  }

  // otherwise, we need to look in the redux state-
  // we'll need to find the id of each folder in the path

  const folderPathSegments = folderPath.split('/')

  // reduce the array of folder names to an array of ids
  const idPath = folderPathSegments.reduce((prev, curr) => {
    // if we've alreay failed, fail out with a null
    if (prev == null) return null

    // the first segment is a root folder id instead of a name-
    // just push it into the array
    if (prev.length === 0) {
      prev.push(curr)
      return prev
    }

    // for the other segments, find the folder with the same name and parent
    const nextId = folderIdWithNameAndParent(
      curr, // name
      prev.length > 0 ? prev[prev.length - 1] : null, // parentId
      allFolders,
      folderIdsByPath
    )

    // if the segment does not correspond to an existing folder id,
    // return null to fail out of the idPath generation -
    // there is no folder id for folderPath
    if (nextId == null) {
      return null
    }

    // otherwise, push the folder id into the idPath array
    prev.push(nextId)
    return prev
  }, [])

  //console.log({ idPath })

  if (!idPath || idPath.length === 0) {
    console.log('no idPath for folderPath', { folderPath })
    return null
  }
  const folderId = idPath[idPath.length - 1] // folderId is last id in path
  return folderId
}

function* findOrCreateFolderWithPath(
  folderPath,
  user,
  results,
  folderIdsByPath
) {
  console.log('findOrCreateFolderWithPath', folderPath)

  // fetch all folders currently in state
  const allFolders = yield select((state) => state.folders.byId)

  // if the folder already exists, just return the id
  let folderId = folderIdForPath(folderPath, allFolders, folderIdsByPath)
  if (folderId) {
    results[folderPath] = folderId
    folderIdsByPath[folderPath] = folderId
    console.log('found folder: ' + folderPath, folderId)
    return yield folderId
  }

  // else, find and/or create each parent folder in the path
  const folderPathSegments = folderPath.split('/')
  const folderName = folderPathSegments.pop()
  const idPath = []
  //console.log({ folderPathSegments })
  if (folderPathSegments.length) {
    // iterate over the segments
    let i = 1
    for (; i <= folderPathSegments.length; ++i) {
      const subPath = folderPathSegments.slice(0, i).join('/')
      //console.log({ subPath })
      const newFolderId = yield call(
        findOrCreateFolderWithPath,
        subPath,
        user,
        results,
        folderIdsByPath
      )
      if (!newFolderId) {
        console.log(
          'folder for subpath could not be found or created, aborting',
          { subPath }
        )
        return yield -1
      }
      idPath.push(newFolderId)
    }
  }

  folderId = md5(`${folderName}${user.id}${Date.now()}`)
  const parentId = idPath[idPath.length - 1]
  if (!parentId || parentId.length === 0) {
    console.log('no parent folder found, aborting')
    return yield -1
  }

  // creation action
  try {
    const config = yield select((state) => state.config.appConfig)
    const timestamp = yield call(Date.now)
    const action = {
      type: ADD_FOLDER,
      name: folderName,
      parent: { id: parentId },
      owner: user.id,
      user: user.id,
      client: user.client,
      permissions: corePermissions,
      path: idPath,
      folderType: folderConstants.DEFAULT_FOLDER_TYPE,
      id: folderId,
      folder: {
        id: folderId,
        name: folderName,
        parentId: parentId,
        childFolders: [],
        assets: [],
        createdDt: timestamp,
        owner: user.id,
        client: user.client,
        deleted: false,
        type: folderConstants.DEFAULT_FOLDER_TYPE,
        contentRoles: corePermissions,
      },
    }

    // push ADD_FOLDER (ADD_FOLDER_SUCCESS) action to state actions
    const response = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/state/addaction`,
      init: {
        method: 'POST',
        body: JSON.stringify({ action, events: [] }),
      },
    })

    // log folder id to results and folderIdsByPath
    console.log('add folder success: ' + folderPath, response)
    results[folderPath] = folderId
    results['CREATED_COUNT'] = (results['CREATED_COUNT'] || 0) + 1
    folderIdsByPath[folderPath] = folderId

    // success action to update local redux state
    yield put({ type: ADD_FOLDER_SUCCESS, folder: action.folder })
  } catch (error) {
    console.log('add folder error: ' + folderPath, error)
    results[folderPath] = 'failure'
    return yield -1
  }

  return yield results[folderPath]
}

/*
 *   Function: renameFolder
 *   Purpose: Dispatch an action to change the name of a folder
 *   Param: newName - the replacement name
 *   Param: id - the ID of the target folder
 *   Param: [showSpinner] - flag indicating if the async spinner should be displayed
 */
export function renameFolder(newName, id, showSpinner = true) {
  return {
    type: RENAME_FOLDER,
    newName,
    id,
    showSpinner,
  }
}

export function* watchRenameFolder() {
  yield takeEvery(RENAME_FOLDER, renameFolderSaga)
}

export function* renameFolderSaga(action) {
  const { showSpinner } = action
  try {
    const allFolders = yield select((state) => state.folders.byId)
    if (!action.newName) {
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: { type: 'ERROR', msg: 'Empty folder name is not allowed' },
      })
      return yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
    if (allFolders[action.id].name === action.newName) {
      return
    }
    const config = yield select((state) => state.config.appConfig)

    if (action.showSpinner) {
      yield put({ type: appActions.INITIATE_ASYNC, showSpinner: !!showSpinner })
    }

    // Check the user session
    const user = yield select((state) => state.user)

    action.user = user.id
    action.client = user.client

    let events = []
    const result = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/state/addaction`,
      init: {
        method: 'POST',
        body: JSON.stringify({ action, events }),
      },
    })

    if (result.statusCode && result.statusCode !== 200) {
      throw new Error(result.message)
    } else {
      yield put({
        type: RENAME_FOLDER_SUCCESS,
        id: action.id,
        newName: action.newName,
      })
    }
  } catch (err) {
    // This occurs when Session times out and user chooses to quit
    if (err instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: RENAME_FOLDER_FAILURE, id: action.id })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: 'Unable to rename folder. Please try your request again',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  } finally {
    if (action.showSpinner) {
      yield put({ type: appActions.COMPLETE_ASYNC })
    }
  }
}

/*
 * dropFolders - public facing action creator for moving folders
 *  triggered by a drag-n-drop operation
 */
export function dropFolder(dropId, dragId, parent, dragData) {
  return {
    type: MOVE_FOLDER,
    dropId,
    dragId,
    parent,
    dragData,
  }
}

/*
 * watchDropFolders - watcher to initiate the dropFoldersSaga
 */
export function* watchDropFolder() {
  yield takeEvery(MOVE_FOLDER, dropFolderSaga)
}

/*
 * dropFoldersSaga - handle moving folders after a drag-n-drop operation
 * - update parent ID of folder
 * - update childFolders of old parent
 * - update childFolders of new parent
 */
export function* dropFolderSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
  // Check the user session
  const user = yield select((state) => state.user)

  // insert the user ID and client to the action
  action.user = user.id
  action.client = user.client
  try {
    const config = yield select((state) => state.config.appConfig)
    //refresh the state
    const result = yield call(appActions.refreshStateSaga)
    const allFolders = result.folderState.byId
    const path = yield call(buildPath, action.dropId, allFolders)

    //check to see if the drag/drop operation is still valid
    // 1. If the parent ID has changed on state refresh (i.e. someone else has moved the folder)
    // 2. If it's moving to the recycle bin and someone has published one of the assets.
    let msg = ''
    let valid = true
    // ['_mstlibrary'] !== ['_mstlibrary']
    // ['_mstlibrary'] != ['_mstlibrary']
    if (allFolders[action.dragId].parentId !== action.parent) {
      console.log({
        parentId: allFolders[action.dragId].parentId,
        parent: action.parent,
      })
      valid = false
      msg = `The requested folder move is invalid.
            The folder was probably moved or deleted by another user.
            The application has been refreshed - check the folder tree and try again.`
    } else if (
      path.indexOf(result.folderState.recycleBin) > -1 &&
      containsPublishedAsset(
        action.dragId,
        allFolders,
        result.assetState.byId,
        result.assetState.relationships.assets
      )
    ) {
      valid = false
      msg =
        'This folder - or one of its subfolders - contains a published or pending asset.  All ' +
        'assets must be unpublished before deleting'
    }

    if (valid) {
      let newIndex = -1
      if (action.dragData.above)
        newIndex = utils.getNodeIndex(allFolders, action.dragData.above)
      else if (action.dragData.below)
        newIndex = utils.getNodeIndex(allFolders, action.dragData.below) + 1

      action.newIndex = newIndex

      // Reorder folders only
      if (action.dragData && action.parent === action.dropId) {
        const childIndex = utils.getNodeIndex(allFolders, action.dragId)

        yield put({
          type: REORDER_CHILD_FOLDERS,
          parent: action.parent,
          child: action.dragId,
          newIndex,
          childIndex,
        })
        yield call(apiActions.secureFetchSaga, {
          url: `${config.baseUrl}/api/state/addaction`,
          init: {
            method: 'POST',
            body: JSON.stringify({
              action: {
                type: REORDER_CHILD_FOLDERS,
                parent: action.parent,
                child: action.dragId,
                newIndex,
                childIndex,
              },
              events: [],
            }),
          },
        })
        yield put({ type: appActions.COMPLETE_ASYNC })
      } else {
        // Now check the old parent path to see if any traverser roles can be dropped
        // However, we'll exclude nodes that are common to the new path
        let parentPath = yield call(buildPath, action.parent, allFolders)
        parentPath = parentPath.filter((node) => !path.includes(node))
        for (let folder of parentPath) {
          let entities = Object.keys(allFolders[folder].contentRoles)
          let needsUpdate = false
          let originalContentRoles = allFolders[folder].contentRoles
          for (let entity of entities) {
            if (
              !entity.startsWith('SYS') &&
              allFolders[folder].contentRoles[entity].roleIds.length === 1 &&
              allFolders[folder].contentRoles[entity].roleIds[0] === 'traverser'
            ) {
              let test = requiresTraverserRole(
                folder,
                entity,
                allFolders,
                action.dragId
              )
              if (!test) {
                needsUpdate = true
                originalContentRoles = update(originalContentRoles, {
                  $unset: [entity],
                })
              }
            }
          }
          if (needsUpdate) {
            yield call(updateFolderSaga, {
              type: UPDATE_FOLDER_CONTENTROLES,
              folder: allFolders[folder],
              path: parentPath,
              allFolders,
              checkTraverseRole: false,
              contentRoles: originalContentRoles,
              setAsyncPending: false,
            })
          }
        }

        // If the drag source folder has any contentRole entities that the drop target folder
        // does not have, we need to add the traverse role to parent tree for that entity
        let newContentRoles = Object.keys(
          allFolders[action.dragId].contentRoles
        )
          .filter((entity) => !allFolders[action.dropId].contentRoles[entity])
          .reduce((result, entity) => {
            result[entity] = {
              ...allFolders[action.dragId].contentRoles[entity],
            }
            result[entity].roleIds = ['traverser']
            return result
          }, {})
        yield call(updateFolderSaga, {
          type: UPDATE_FOLDER_CONTENTROLES,
          folder: allFolders[action.dropId],
          path,
          allFolders,
          checkTraverseRole: true,
          contentRoles: Object.assign(
            {},
            allFolders[action.dropId].contentRoles,
            newContentRoles
          ),
        })
        // save the action in the cloud history
        let events = []
        yield call(apiActions.secureFetchSaga, {
          url: `${config.baseUrl}/api/state/addaction`,
          init: {
            method: 'POST',
            body: JSON.stringify({
              action: {
                type: MOVE_FOLDER,
                dragId: action.dragId,
                dropId: action.dropId,
                path,
                newIndex,
              },
              events,
            }),
          },
        })

        yield put({
          type: MOVE_FOLDER_SUCCESS,
          dragId: action.dragId,
          dropId: action.dropId,
          path,
          newIndex,
        })
        yield put({ type: modalActions.CLOSE_MODAL })
        yield put({ type: appActions.COMPLETE_ASYNC })
      }
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: msg || 'The folder move is invalid',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  } catch (error) {
    console.log(error)
    yield put({ type: appActions.COMPLETE_ASYNC })

    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: { type: 'ERROR', msg: 'Could not move folder to target' },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}
/*
 * Function setNewFolderName: dispatched when user is adding a new
 *  folder.
 */
export function setNewFolderName(name) {
  return {
    type: SET_NEW_FOLDERNAME,
    name,
  }
}

export const activeFolder = (id, scroll = true) => ({
  type: ACTIVE_FOLDER,
  id,
  scroll,
})

export function* watchActiveFolder() {
  yield takeEvery(ACTIVE_FOLDER, activeFolderSaga)
}

/*
 *  activeFolderSaga - organize actions when user changes folder
 * 1. scroll to top
 * 2. clear selected assets - can't select across folders
 * 3. set the active folder for display (possibly null)
 * 4. If folder is not null, sort assets per redux state
 */
export function* activeFolderSaga(action) {
  const activeFolder = yield select((state) => state.folders.activeFolder)
  if (activeFolder !== action.id) {
    yield put({ type: appActions.SCROLL_TO_TOP, element: 'asset_container' })
    yield put({ type: appActions.CLEAR_SELECTED_ASSETS })
    yield put({ type: SET_ACTIVE_FOLDER, id: action.id })
    if (action.id && action.scroll)
      yield put({ type: appActions.APPLY_LIBRARY_SORT })
  }
}

export function selectFolder(id) {
  return {
    type: SELECT_FOLDER,
    payload: id,
  }
}

export function clearSelectedFolders() {
  return {
    type: CLEAR_SELECTED_FOLDERS,
  }
}

export function emptyRecycleBin(folder, parentId) {
  return {
    type: EMPTY_RECYCLE_BIN,
    folder,
    parentId,
  }
}

export function* watchEmptyRecycleBin() {
  yield takeEvery(EMPTY_RECYCLE_BIN, emptyRecycleBinSaga)
}

export function* emptyRecycleBinSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
  try {
    const config = yield select((state) => state.config.appConfig)
    const user = yield select((state) => state.user)
    const items = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/state/reports/recycle`,
      init: {
        method: 'POST',
        body: JSON.stringify(user.email),
      },
    })

    action.itemsToDelete = items
    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/state/addaction`,
      init: {
        method: 'POST',
        body: JSON.stringify({ action, events: [] }),
      },
    })
    yield all([
      put({
        type: EMPTY_RECYCLE_BIN_SUCCESS,
        folder: action.folder,
        parentId: action.parentId,
        itemsToDelete: items,
      }),
      put(createSnapshot()),
    ])
    yield put({ type: appActions.COMPLETE_ASYNC, showSpinner: false })
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      console.error('Error emptying recycle bin', error)

      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            error.clientMessage ||
            'An error occurred while emptying recycle bin',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}

export function deleteChildFolder(child, parent) {
  return {
    type: DELETE_FOLDER_SUCCESS,
    child,
    parent,
  }
}

export const updateFolder = ({
  folder,
  path,
  allFolders,
  checkTraverseRole = true,
  contentRoles,
  applyToSubfolders = false,
  setAsyncPending = true,
} = {}) => ({
  type: UPDATE_FOLDER_CONTENTROLES,
  folder,
  path,
  allFolders,
  checkTraverseRole,
  contentRoles,
  applyToSubfolders,
  setAsyncPending,
})

export function* watchUpdateFolder() {
  yield takeEvery(UPDATE_FOLDER_CONTENTROLES, updateFolderSaga)
}

export function* updateFolderSaga(action) {
  if (action.setAsyncPending) yield put({ type: appActions.INITIATE_ASYNC })
  try {
    const config = yield select((state) => state.config.appConfig)
    const user = yield select((state) => state.user)
    // insert the user ID and client to the action
    action.user = user.id
    action.client = user.client

    let events = []
    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/state/addaction`,
      init: {
        method: 'POST',
        body: JSON.stringify({
          action: Object.assign({}, action, { allFolders: undefined }),
          events,
        }),
      },
    })

    if (action.checkTraverseRole) {
      for (let folder of action.path) {
        //console.log(`checking ${action.allFolders[folder].name}`)
        let updatedContentRoles = Object.assign(
          {},
          action.allFolders[folder].contentRoles
        )
        let needsUpdate = false
        /* Check that existing traverse permissions are still required after this change */
        /* Check parent traverser roles that do not exist in updated content roles */
        const traverserEntities = Object.keys(
          action.allFolders[folder].contentRoles
        ).filter((entity) => {
          return (
            entity !== 'SYS.traversers' &&
            action.allFolders[folder].contentRoles[entity].roleIds.length ===
              1 &&
            action.allFolders[folder].contentRoles[entity].roleIds[0] ===
              'traverser' &&
            !action.contentRoles[entity]
          )
        })
        for (let traverserEntity of traverserEntities) {
          if (
            !requiresTraverserRole(
              folder,
              traverserEntity,
              action.allFolders,
              action.folder.id
            )
          ) {
            //console.log(`removing ${traverserEntity} from ${action.allFolders[folder].name}`)
            updatedContentRoles = update(updatedContentRoles, {
              $unset: [traverserEntity],
            })
            needsUpdate = true
          }
        }
        /* Check parent folders for traverse access for any new non-system groups that have
           access to the new folder */
        const newEntities = Object.keys(action.contentRoles).filter(
          (cr) => !cr.startsWith('SYS.')
        )
        for (let entity of newEntities) {
          if (
            !(
              action.allFolders[folder].contentRoles[entity] &&
              action.allFolders[folder].contentRoles[entity].roleIds.length > 0
            )
          ) {
            //console.log(`adding ${entity} to ${action.allFolders[folder].name}`)
            updatedContentRoles = update(updatedContentRoles, {
              [entity]: {
                $set: {
                  entityId: action.contentRoles[entity].entityId,
                  entityType: action.contentRoles[entity].entityType,
                  roleIds: action.allFolders[folder].contentRoles[entity]
                    ? action.allFolders[folder].contentRoles[
                        entity
                      ].roleIds.concat(['traverser'])
                    : ['traverser'],
                },
              },
            })
            needsUpdate = true
          }
        }
        if (needsUpdate) {
          //console.log(`updating  ${action.allFolders[folder].name} with these content roles:
          // ${updatedContentRoles}`)
          yield call(updateFolderSaga, {
            type: UPDATE_FOLDER_CONTENTROLES,
            folder: action.allFolders[folder],
            path: action.path.filter((f) => f !== folder),
            allFolders: action.allFolders,
            checkTraverseRole: false,
            contentRoles: updatedContentRoles,
          })
        }
      }
    }

    yield put({
      type: UPDATE_FOLDER_CONTENTROLES_SUCCESS,
      folder: action.folder,
      contentRoles: action.contentRoles,
    })
    yield put({ type: modalActions.CLOSE_MODAL })
    if (action.setAsyncPending) yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      console.log(error)
      if (action.setAsyncPending) yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            error.clientMessage ||
            'An error occurred while updating the folder',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}

export const bulkContentRoleUpdate = (contentRoles) => ({
  type: BULK_CONTENTROLE_UPDATE,
  contentRoles,
})

export function* watchBulkContentRoleUpdate() {
  yield takeEvery(BULK_CONTENTROLE_UPDATE, bulkContentRoleUpdateSaga)
}

export function* bulkContentRoleUpdateSaga(action) {
  try {
    yield put({ type: appActions.INITIATE_ASYNC })
    const config = yield select((state) => state.config.appConfig)
    const user = yield select((state) => state.user)
    // insert the user ID and client to the action
    action.user = user.id
    action.client = user.client

    let events = []
    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/state/addaction`,
      init: {
        method: 'POST',
        body: JSON.stringify({
          action,
          events,
        }),
      },
    })
    yield put({
      type: BULK_CONTENTROLE_UPDATE_SUCCESS,
      contentRoles: action.contentRoles,
    })
  } catch (error) {
    console.log(error)
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

export const reorderChildFolders = (parent, child, newIndex) => ({
  type: REORDER_CHILD_FOLDERS,
  parent,
  child,
  newIndex,
})

export default all([
  watchAddFolder(),
  watchRenameFolder(),
  watchDropFolder(),
  watchActiveFolder(),
  watchEmptyRecycleBin(),
  watchUpdateFolder(),
  watchBulkContentRoleUpdate(),
  watchAddFolders(),
])
