import PropTypes from 'prop-types'
import React from 'react'
import { DropTarget } from 'react-dnd'
import { PRODUCT__MAX_HOTSPOTS } from '../../../dux/constants/builders'
import {
  DND_HOTSPOT,
  dropCollect,
  hotspotTarget,
} from '../../../dux/modules/DragDrop'
import Hotspot from './Hotspot'

class ProductSimulator extends React.Component {
  render() {
    const {
      connectDropTarget,
      imageWidth,
      imageHeight,
      slideImageUrl,
      activeSlide,
      activeHotspot,
      config,
      center,
      builderType,
      componentMouseEnter,
      componentMouseLeave,
      handleSelectHotspot,
      builderActions,
    } = this.props
    const cursor = !activeSlide.mainImage
      ? 'not-allowed'
      : activeSlide.hotspots.length >= PRODUCT__MAX_HOTSPOTS
      ? 'default'
      : 'crosshair'
    return connectDropTarget(
      <div
        id="product-view"
        className="simulator__view"
        style={{
          width: imageWidth,
          height: imageHeight,
          backgroundImage: `url(${slideImageUrl})`,
          cursor,
        }}
        onClick={this.props.saveClickPosition}
      >
        {activeSlide.hotspots &&
          activeSlide.hotspots.map((spot, index) => {
            return (
              <Hotspot
                key={index}
                number={index + 1}
                active={spot.id === activeHotspot}
                hotspot={spot}
                config={config}
                parentId={activeSlide.id}
                center={center}
                builderType={builderType}
                builderActions={builderActions}
                handleMouseEnter={componentMouseEnter}
                handleMouseLeave={componentMouseLeave}
                handleSelectHotspot={handleSelectHotspot}
              />
            )
          })}
      </div>
    )
  }
}

ProductSimulator.propTypes = {
  connectDropTarget: PropTypes.func,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  slideImageUrl: PropTypes.string,
  activeSlide: PropTypes.object,
  activeHotspot: PropTypes.string,
  hotspot: PropTypes.object,
  center: PropTypes.object,
  builderType: PropTypes.string,
  builderActions: PropTypes.object,
  componentMouseEnter: PropTypes.func,
  componentMouseLeave: PropTypes.func,
  handleSelectHotspot: PropTypes.func,
  openSelectAssetModal: PropTypes.func,
  config: PropTypes.object,
}

export default DropTarget(
  DND_HOTSPOT,
  hotspotTarget,
  dropCollect
)(ProductSimulator)
