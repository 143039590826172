import { Input } from 'antd'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

const EditableAttribute = (props) => {
  let editComponent
  switch (props.type) {
    case 'text':
      editComponent = (
        <Input
          key={props.id}
          id={props.id}
          value={props.value}
          onChange={props.changeHandler}
          className={props.cssValueClass}
        />
      )
      break
    case 'textarea':
      editComponent = (
        <Input.TextArea
          key={props.id}
          autosize={{ minRows: 3, maxRows: 20 }}
          id={props.id}
          value={props.value}
          onChange={props.changeHandler}
          className={props.cssValueClass}
        />
      )
      break
    default:
      editComponent = null
  }
  return (
    <Fragment>
      <div className={props.cssLabelClass}>{props.label}</div>
      <div>{editComponent}</div>
    </Fragment>
  )
}

EditableAttribute.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  cssLabelClass: PropTypes.string,
  cssValueClass: PropTypes.string,
  changeHandler: PropTypes.func,
}

export default EditableAttribute
