import { Icon, Input } from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { copySpanToClipboard } from '../../dux/modules/utils'

const CopyButton = (props) => {
  const { isCopied, setIsCopied } = props

  const handleCopyClick = () => {
    setIsCopied(true)
  }

  const copyTextComponent = isCopied ? (
    <span className="span__clickable-success" style={styles.copyText}>
      Copied
    </span>
  ) : (
    <span className="span__clickable" style={styles.copyText}>
      Copy
    </span>
  )

  const iconComponent = isCopied ? (
    <Icon
      type="check-circle"
      className="bam__invitation-link-success"
      style={styles.copyIcon}
    />
  ) : (
    <Icon
      type="copy"
      className="bam__invitation-link-icon"
      style={styles.copyIcon}
    />
  )

  return (
    <div style={styles.copyButtonContainer} onClick={handleCopyClick}>
      {iconComponent}
      {copyTextComponent}
    </div>
  )
}

class PublicURLForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isCopied: false,
    }
  }

  setIsCopied = (status) => {
    copySpanToClipboard('urlTextArea')
    this.setState({ isCopied: !!status })
  }

  render() {
    const { url } = this.props
    const { isCopied } = this.state

    return (
      <div style={styles.container}>
        <Input.TextArea
          id={'urlTextArea'}
          value={url}
          disabled
          autosize={{ maxRows: 2 }}
        />
        <CopyButton
          url={url}
          isCopied={isCopied}
          setIsCopied={this.setIsCopied}
        />
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '16px',
  },
  copyButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  copyIcon: {
    fontSize: '18px',
    fontWeight: '500',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  copyText: {
    textDecoration: 'none',
  },
  urlText: {
    paddingRight: 2,
  },
}

CopyButton.propTypes = {
  url: PropTypes.string,
  isCopied: PropTypes.bool,
  setIsCopied: PropTypes.func,
}

PublicURLForm.propTypes = {
  url: PropTypes.string,
}

export default PublicURLForm
