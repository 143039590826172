import { NAVIGATOR__SLIDE_ICON } from '../../../../dux/constants/builders'
import { FOLDER__ICON } from '../../../../dux/constants/folder'

export const getIconForZoneTarget = (targetType, targetId, allAssets) => {
  switch (targetType) {
    case 'asset':
      return allAssets[targetId].icon
    case 'slide':
      return NAVIGATOR__SLIDE_ICON
    case 'folder':
      return FOLDER__ICON
    default:
      return NAVIGATOR__SLIDE_ICON
  }
}

export const getDisplayTextForZoneTarget = (
  targetType,
  targetId,
  allAssets,
  allFolders,
  slides
) => {
  if (!targetId) return 'Unknown'
  let index
  switch (targetType) {
    case 'asset':
      return (
        (allAssets[targetId] &&
          (allAssets[targetId].title || allAssets[targetId].filename)) ||
        'Unknown'
      )
    case 'folder':
      return (allFolders[targetId] && allFolders[targetId].name) || 'Unknown'
    case 'slide':
      index = slides.findIndex((slide) => slide.id === targetId)
      return (
        (index >= 0 && (slides[index].displayText || `Slide ${index + 1}`)) ||
        'Unknown'
      )
    default:
      return 'No Target Assigned'
  }
}
