import { Button } from 'antd'
import React from 'react'
import { shortenFolderName } from '../../dux/modules/utils'

const MSTFeaturedAssetSettingsView = (props) => (
  <div className="settings__form-container">
    <div className="builders__form-label">Featured Assets</div>
    <div className="builders__padded-div">
      Assets in this section will be displayed in the rotating carousel on the
      home page of the Mobile Sales Tool. Use this space to display assets and
      builders you want users to see first. This can be new promotions,
      important information, frequently-used content, or other items you want to
      highlight. Remember, this will be the first thing users see after they log
      in.
    </div>
    <div className="builders__form-label">Featured Asset Carousel</div>
    <div className="builders__padded-div">
      Click the Add Asset button and select an asset from your library to
      feature on the MST front page. Remember, only assets published to the MST
      endpoint are available.
    </div>
    <div className="builders__form-label">Permissions</div>
    <div className="builders__padded-div">
      All Featured Assets should have permissions set to allow all MST users to
      view them. If permissions are not set correctly, the Featured Asset
      Carousel may show default assets or important information may not be
      visible to your MST users.
    </div>
    <div className="settings__featured-items-container">
      {props.carousel.map((featured, i) => {
        const preview = props.getPreview(featured.asset)
        return preview ? (
          <div
            key={`${featured.asset}${i}`}
            className="settings__featured-items-group"
          >
            <div
              id={`carousel${i}`}
              className="settings__featured-item"
              ref={(ref) => props.saveRBI(ref, `carousel${i}`)}
            >
              <img
                className="settings__featured-item-preview"
                src={preview}
                alt="featured asset"
                style={{ display: 'none' }}
              />
              <div className="settings__featured-item-action">
                <div className="settings__featured-item-action-icon">
                  <i
                    id={featured.asset}
                    className="fa fa-trash settings__featured-item-icon"
                    onClick={() => props.removeItem(featured)}
                  />
                  <i
                    className="fa fa-edit settings__featured-item-icon"
                    onClick={() => props.openSelectAssetModal(featured)}
                  />
                </div>
              </div>
            </div>
            <div className="settings__featured-item-text">
              <div className="settings__featured-item-text-line1">
                {shortenFolderName(
                  featured.displayText ||
                    props.allAssets[featured.asset].filename
                )}
              </div>
              <div className="settings__featured-item-text-line2">
                <i className={`fa ${props.allAssets[featured.asset].icon}`} />
                &nbsp;
                {props.allAssets[featured.asset].extension &&
                  props.allAssets[featured.asset].extension.toUpperCase()}
              </div>
            </div>
          </div>
        ) : (
          <div
            key={`${featured.asset}${i}`}
            className="settings__featured-items-group"
          >
            <div
              className="settings__featured-item"
              style={{
                backgroundColor: props.allAssets[featured.asset].color,
                color: 'rgba(255, 255, 255, 0.3)',
              }}
            >
              <i
                className={`fa ${
                  props.allAssets[featured.asset].icon
                } settings__featured-item-large-icon`}
              />
              <div className="settings__featured-item-action">
                <div className="settings__featured-item-action-icon">
                  <i
                    id={featured.asset}
                    className="fa fa-trash settings__featured-item-icon"
                    onClick={() => props.removeItem(featured)}
                  />
                  <i
                    className="fa fa-edit settings__featured-item-icon"
                    onClick={() => props.openSelectAssetModal(featured)}
                  />
                </div>
              </div>
            </div>

            <div className="settings__featured-item-text">
              <div className="settings__featured-item-text-line1">
                {shortenFolderName(
                  featured.displayText ||
                    props.allAssets[featured.asset].filename
                )}
              </div>
              <div className="settings__featured-item-text-line2">
                <i className={`fa ${props.allAssets[featured.asset].icon}`} />
                &nbsp;
                {props.allAssets[featured.asset].extension &&
                  props.allAssets[featured.asset].extension.toUpperCase()}
              </div>
            </div>
          </div>
        )
      })}
      {
        /* If there are less than 6 assets in carousel, allow adding another */
        props.carousel.length < props.maxSize ? (
          <div className="settings__featured-items-group">
            <div className="settings__featured-item-blank">
              <span className="settings__featured-item-blank-text">
                There is nothing here. Yet.
              </span>
              <div
                className="settings__featured-item-action-blank"
                onClick={() => props.openSelectAssetModal()}
              >
                <i className="fa fa-plus-circle" />
                &nbsp; Asset
              </div>
            </div>
          </div>
        ) : null
      }
    </div>
    <div className="builders__button-container">
      <span className="builders__button">
        <Button onClick={props.onCancel}>Cancel</Button>
      </span>
      <span className="builders__button">
        <Button type="primary" onClick={props.onSave} loading={props.loading}>
          Save
        </Button>
      </span>
      <div className="builders__fixed-div">
        {props.message && (
          <div>
            {props.messageType === 'SUCCESS' ? (
              <i className="fa fa-check-circle assettable__status-green" />
            ) : (
              <i className="fa fa-exclamation-circle assettable__status-red" />
            )}
            &nbsp; {props.message}
          </div>
        )}
      </div>
    </div>
  </div>
)

export default MSTFeaturedAssetSettingsView
