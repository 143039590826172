import { Button } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

const Maintenance = (props) => (
  <div>
    <h3>
      Maintenance mode is{' '}
      <span style={{ color: 'red' }}>
        {(props.maintenance && props.maintenance) === 1
          ? 'enabled'
          : 'disabled'}
      </span>
    </h3>
    <Button onClick={props.onClickOn} style={{ margin: '0 10px' }}>
      Maintenance On
    </Button>
    <Button onClick={props.onClickOff} style={{ margin: '0 10px' }}>
      Maintenance Off
    </Button>
    <Button onClick={props.onClickSnapshot} style={{ margin: '0 10px' }}>
      Create State Snapshot
    </Button>
  </div>
)

Maintenance.propTypes = {
  onClickOn: PropTypes.func.isRequired,
  onClickOff: PropTypes.func.isRequired,
  maintenance: PropTypes.number.isRequired,
  onClickSnapshot: PropTypes.func.isRequired,
}

export default Maintenance
