import { Icon, Popover, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { copySpanToClipboard } from '../../../dux/modules/utils'

const CopyURLAction = (props) => {
  const { asset, setClipboardContent } = props

  return (
    <div>
      <Tooltip title="Public URL">
        <Popover placement={'bottom'} content={<CopyOptions {...props} />}>
          <i
            className={'fa fa-link'}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              copySpanToClipboard(`copy-span-${asset.id}`)
              setClipboardContent(asset.publicURL)
              return false
            }}
          />
        </Popover>
      </Tooltip>
    </div>
  )
}

const CopyOptions = (props) => {
  const { asset, clipboardContent, setClipboardContent } = props

  const isCopied = clipboardContent === asset.publicURL

  const copyTextComponent = isCopied ? (
    <span className="span__clickable-success" style={styles.copyText}>
      Copied
    </span>
  ) : (
    <span className="span__clickable" style={styles.copyText}>
      Copy
    </span>
  )

  const iconComponent = isCopied ? (
    <Icon
      type="check-circle"
      className="bam__invitation-link-success"
      style={styles.copyIcon}
    />
  ) : (
    <Icon
      type="copy"
      className="bam__invitation-link-icon"
      style={styles.copyIcon}
    />
  )

  return (
    <div style={styles.copyOptionsContainer}>
      <span
        id={`copy-span-${asset.id}`}
        style={styles.urlText}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {asset.publicURL}
      </span>

      <span
        onClick={(e) => {
          e.stopPropagation()
          copySpanToClipboard(`copy-span-${asset.id}`)
          setClipboardContent(asset.publicURL)
        }}
      >
        {iconComponent}
        {copyTextComponent}
      </span>
    </div>
  )
}

const styles = {
  copyOptionsContainer: {},
  copyIcon: {
    fontSize: '18px',
    fontWeight: '500',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  copyText: {
    textDecoration: 'none',
  },
  urlText: {
    paddingRight: 2,
  },
}

CopyOptions.propTypes = {
  asset: PropTypes.object,
  clipboardContent: PropTypes.string,
  setClipboardContent: PropTypes.func,
}

export default CopyURLAction
