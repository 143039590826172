import { Collapse, Icon, Layout, Modal, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActions from '../../../dux/actions/appActions'
import * as assetActions from '../../../dux/actions/asset'
import * as builderActions from '../../../dux/actions/builders'
import * as endpointActions from '../../../dux/actions/endpoint'
import * as folderActions from '../../../dux/actions/folder'
import * as modalActions from '../../../dux/actions/modal'
import { DEFAULT_SIDEBAR_WIDTH } from '../../../dux/constants/app'
import {
  TRANSCODING_STATUS_COMPLETE,
  TRANSCODING_STATUS_HIDDEN,
  UGC_REJECTED_STATUS,
} from '../../../dux/constants/assets'
import {
  BUILDER_TYPES,
  COMPARISON__BUILDER_TYPE,
} from '../../../dux/constants/builders'
import { UGC_FOLDER } from '../../../dux/constants/folder'
import {
  assetIsPublished,
  assetName,
  buildPath,
  compareFlatArrays,
  downloadAsset,
  findLibraryFolder,
  getEndpointOfNode,
  isContentAdmin,
  validatePrivilege,
} from '../../../dux/modules/utils'
import EditableAttribute from '../../BAM_UI/EditableAttribute'
import TwoButtonContainer from '../../BAM_UI/TwoButtonContainer'
import LoadingModal from '../../modals/LoadingModal'
import ActionIcon from '../ActionIcon'
import { GLOBAL_ACTIONS } from '../CardConstants'
import PublicURLForm from '../PublicURLForm'
import ReviewAssetForm from '../ReviewAssetForm'
import AssetDetailPanel from './AssetDetailPanel'
import AssetLocationPanel from './AssetLocationPanel'
import AssetRelationshipsPanel from './AssetRelationshipsPanel'

const { Content } = Layout
const { Panel } = Collapse

class AssetDetailSidebar extends React.Component {
  state = {
    activeKey: ['metadata'],
    title: this.props.allAssets[this.props.match.params.id].title,
    description: this.props.allAssets[this.props.match.params.id].description,
    filename: this.props.allAssets[this.props.match.params.id].filename,
  }

  onPanelChange = (key) => this.setState({ activeKey: key })

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.setState({ activeKey: ['metadata'] })
      if (this.props.editModeAsset) this.cancelAssetEdit()
    } else if (
      this.props.match.params.id === this.props.editModeAsset &&
      this.state.filename !==
        this.props.allAssets[this.props.match.params.id].filename
    )
      this.setState({
        filename: this.props.allAssets[this.props.match.params.id].filename,
      })
  }

  componentDidMount() {
    if (this.props.sidebarWidth !== DEFAULT_SIDEBAR_WIDTH)
      this.props.appActions.toggleSidebar(DEFAULT_SIDEBAR_WIDTH)
  }

  componentWillUnmount() {
    this.cancelAssetEdit()
  }
  /*
   * CROP action only applyes to images
   * SHARE action only applies to "assets" (i.e. non-builders)
   * DOWNLOAD action excludes builders, except comparisons
   */
  globalActionFilter = ({ action }, asset) => {
    const { allAssets, match, activeFolder } = this.props
    const assetIsBuilderType = BUILDER_TYPES.includes(asset.asset_type)
    const assetHasPublicURL = !!allAssets[match.params.id].publicURL

    if (action === 'edit' && assetIsBuilderType) return false

    if (action === 'editbuilder' && !assetIsBuilderType) return false

    if (action === 'crop' && !asset.file_type.startsWith('image')) return false

    if (action === 'share' && asset.asset_type !== 'asset') return false

    if (
      action === 'download' &&
      assetIsBuilderType &&
      asset.asset_type !== COMPARISON__BUILDER_TYPE
    )
      return false

    // Disable share and download for custom assets
    if (
      action === 'share' &&
      (asset.extension === 'calc' ||
        asset.extension === 'form' ||
        asset.extension === '360')
    )
      return false

    // disable download for calcs and forms
    if (
      action === 'download' &&
      (asset.extension === 'calc' || asset.extension === 'form')
    )
      return false

    if (action === 'move' && activeFolder !== UGC_FOLDER) return false

    if (action === 'delete' && activeFolder !== UGC_FOLDER) return false

    if (action === 'copyURL' && !assetHasPublicURL) return false

    const isUGCAction = ['move', 'delete'].includes(action)
    if (activeFolder === UGC_FOLDER && !isUGCAction) return false

    return true
  }

  folderActionFilter = (action, asset, folder) => {
    const { allFolders, endpoints } = this.props
    const currentEndpoint = getEndpointOfNode(
      allFolders[folder],
      allFolders,
      endpoints.endpoints
    )
    if (this.props.activeFolder === UGC_FOLDER && action.action !== 'delete')
      return false
    if (
      action.action === 'clone' &&
      (currentEndpoint.id !== 'LIB' ||
        BUILDER_TYPES.indexOf(asset.asset_type) > -1)
    )
      return false
    if (action.action === 'delete' && currentEndpoint.allowPublishing)
      return false
    if (
      !currentEndpoint.allowPublishing &&
      (action.action === 'review' ||
        action.action === 'unpublish_review' ||
        action.action === 'unpublish')
    )
      return false
    if (
      action.action === 'review' &&
      endpoints.folderAssetStatus[folder][asset.id].status !==
        'PENDING_APPROVAL'
    )
      return false
    if (
      action.action === 'unpublish_review' &&
      endpoints.folderAssetStatus[folder][asset.id].status !== 'PENDING_REMOVAL'
    )
      return false
    if (
      action.action === 'unpublish' &&
      endpoints.folderAssetStatus[folder][asset.id].status !== 'APPROVED'
    )
      return false
    return true
  }

  goBack = () => {
    this.props.appActions.setBackButtonFlag()
    this.props.history.goBack()
  }

  deleteAndGoBack = (asset, folder) => {
    this.props.assetActions.dropAsset(this.props.recycleBin, [asset.id], folder)
    this.goBack()
  }

  cloneAndGoBack = (asset, folder) => {
    this.props.assetActions.cloneAsset(this.props.allAssets[asset.id], folder)
    this.goBack()
  }

  editChangeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }

  saveAssetEdit = () => {
    // Get pending tag changes, handle updates in callback
    this.tagsRef.handleInputConfirm(null, () => {
      const newAsset = {
        ...this.props.allAssets[this.props.match.params.id],
        title: this.state.title,
        description: this.state.description,
        filename: this.state.filename,
        tags: this.tagsRef.getTags(),
      }
      let updates = {}
      Object.keys(newAsset).forEach((key) => {
        if (Array.isArray(key)) {
          if (
            !compareFlatArrays(newAsset[key], this.props.originalAssetData[key])
          )
            updates[key] = newAsset[key]
        } else if (newAsset[key] !== this.props.originalAssetData[key])
          updates[key] = newAsset[key]
      })
      // Only update if something has changed
      if (Object.keys(updates).length > 0)
        this.props.assetActions.saveAssetEdits(
          newAsset,
          updates,
          false,
          true,
          this.props.originalAssetData.ETag
        )
      else this.props.appActions.setEditModeAsset(null)
    })
  }

  cancelAssetEdit = () => {
    this.setState({
      title: this.props.allAssets[this.props.match.params.id].title,
      description: this.props.allAssets[this.props.match.params.id].description,
      filename: this.props.allAssets[this.props.match.params.id].filename,
    })
    if (this.props.originalAssetData)
      this.props.assetActions.cancelReplaceAsset()
  }

  getCardAction = (action, folder) => {
    const asset = this.props.allAssets[this.props.match.params.id]
    let func = null
    switch (action) {
      case 'editbuilder':
      case 'edit':
        func = () => {
          if (
            assetIsPublished(
              asset.id,
              this.props.relationships.assets,
              this.props.allAssets
            )
          )
            return Modal.confirm({
              title: 'Published Asset - Continue?',
              onOk: () => {
                if (BUILDER_TYPES.indexOf(asset.asset_type) > -1) {
                  this.props.history.push(
                    `/builders/${asset.asset_type}/${asset.id}`
                  )
                  this.props.appActions.setMenuItem('builders')
                } else {
                  this.setState((prevState) => {
                    if (prevState.activeKey.includes('metadata'))
                      return prevState
                    return { activeKey: ['metadata'] }
                  })
                  this.props.appActions.scrollToTop(
                    'assetdetail__collapse-container'
                  )
                  this.props.appActions.setEditModeAsset(asset.id)
                }

                Promise.resolve('')
              },
              content: asset.publishedTo.map((folderid) => {
                return (
                  <div key={folderid}>
                    {this.props.allFolders[folderid].name} (
                    {
                      this.props.endpoints.folderAssetStatus[folderid][asset.id]
                        .status
                    }
                    )
                  </div>
                )
              }),
            })
          if (BUILDER_TYPES.indexOf(asset.asset_type) > -1) {
            this.props.history.push(`/builders/${asset.asset_type}/${asset.id}`)
            return this.props.appActions.setMenuItem('builders')
            //this.props.builderActions.editBuilder(asset.asset_type, folder, asset)
          }

          this.props.appActions.scrollToTop('assetdetail__collapse-container')
          this.setState((prevState) => {
            if (prevState.activeKey.includes('metadata')) return prevState
            return { activeKey: prevState.activeKey.concat('metadata') }
          })
          return this.props.appActions.setEditModeAsset(asset.id)
        }
        break
      case 'clone':
        func = () => this.cloneAndGoBack(asset, folder)
        break
      case 'download':
        func = () => {
          if (
            asset.file_type.startsWith('video') &&
            asset.videoSrc &&
            (asset.videoSrc.status === TRANSCODING_STATUS_COMPLETE ||
              asset.videoSrc.status === TRANSCODING_STATUS_HIDDEN)
          ) {
            this.props.modalActions.openModal('DownloadModal', {
              asset,
              title: 'Download Video Asset',
              originalHandler: () => {
                downloadAsset(asset)
                this.props.modalActions.closeModal()
              },
              transcodeHandler: () => {
                downloadAsset(asset, true)
                this.props.modalActions.closeModal()
              },
            })
          } else {
            downloadAsset(asset)
          }
        }
        break
      case 'share':
        func = () =>
          this.props.modalActions.openModal('ShareAssetModal', {
            assets: [asset],
            user: this.props.userId,
            title: 'Share Asset',
          })
        break
      case 'move':
        func = () =>
          this.props.modalActions.openModal('ReviewUGCModal', {
            assets: [asset.id],
            treeRoot: getEndpointOfNode(
              this.props.allFolders[this.props.activeFolder],
              this.props.allFolders,
              this.props.endpoints.endpoints
            ).rootFolders,
          })
        break
      case 'delete':
        func = () => {
          if (folder === UGC_FOLDER) {
            return this.props.assetActions.reviewUgcAssets(
              [asset.id],
              UGC_REJECTED_STATUS,
              this.props.recycleBin,
              this.props.history
            )
          }

          if (
            assetIsPublished(
              asset.id,
              this.props.relationships.assets,
              this.props.allAssets
            )
          )
            return Modal.confirm({
              title: 'Published Asset - Continue?',
              onOk: () => this.deleteAndGoBack(asset, folder),
              content: asset.publishedTo.map((folderid) => {
                return (
                  <div key={folderid}>
                    {this.props.allFolders[folderid].name} (
                    {
                      this.props.endpoints.folderAssetStatus[folderid][asset.id]
                        .status
                    }
                    )
                  </div>
                )
              }),
            })

          return this.deleteAndGoBack(asset, folder)
        }
        break
      case 'publish':
        func = () =>
          this.props.modalActions.openModal('PublishAssetModal', {
            assets: [asset.id],
            parent: folder,
          })
        break
      case 'crop':
        func = () => {
          if (
            assetIsPublished(
              asset.id,
              this.props.relationships.assets,
              this.props.allAssets
            )
          )
            return Modal.confirm({
              title: 'Published Asset - Continue?',
              onOk: () =>
                this.props.history.push(`/library/asset/crop/${asset.id}`),
              content: asset.publishedTo.map((folderid) => {
                return (
                  <div key={folderid}>
                    {this.props.allFolders[folderid].name} (
                    {
                      this.props.endpoints.folderAssetStatus[folderid][asset.id]
                        .status
                    }
                    )
                  </div>
                )
              }),
            })
          else return this.props.history.push(`/library/asset/crop/${asset.id}`)
        }
        break
      case 'review':
        func = () =>
          (this.reviewForm = Modal.info({
            title: (
              <span>
                Review Assets for Publishing &nbsp;
                <Tooltip
                  title={
                    'Publishing an asset requires approval.  A published asset will be visible ' +
                    'to consumers of this endpoint'
                  }
                >
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            ),
            okText: 'Cancel',
            onOk: () => Promise.resolve({}),
            okType: 'default',
            maskClosable: false,
            content: (
              <ReviewAssetForm
                ref={this.saveFormRef}
                assets={[asset.id]}
                reviewFunc={this.props.endpointActions.reviewAsset}
                statusType={'PENDING_APPROVAL'}
                activeFolder={folder}
                allAssets={this.props.allAssets}
                closeForm={this.closeReviewForm}
              />
            ),
          }))

        break
      case 'unpublish':
        func = () => {
          const unpublish = validatePrivilege(
            'unpublishasset',
            this.props.privileges,
            this.props.email,
            this.props.groups,
            folder,
            this.props.allFolders
          )
          const approve = validatePrivilege(
            'approveasset',
            this.props.privileges,
            this.props.email,
            this.props.groups,
            folder,
            this.props.allFolders
          )
          const allowSingleAction = unpublish && approve
          const approveFunc = () =>
            this.props.endpointActions.unpublishAndApprove([asset.id], folder)
          this.props.endpointActions.unpublish(
            [asset.id],
            folder,
            () => this.props.endpointActions.unpublishAsset([asset.id], folder),
            allowSingleAction ? approveFunc : null
          )
        }
        break

      case 'unpublish_review':
        func = () =>
          (this.reviewForm = Modal.info({
            title: (
              <span>
                Review Assets for Removal &nbsp;
                <Tooltip
                  title={
                    'Removing a published asset requires approval.  Once an asset is removed, it ' +
                    ' is no longer visible to consumers of this endpoint'
                  }
                >
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            ),
            okText: 'Cancel',
            onOk: () => Promise.resolve({}),
            okType: 'default',
            maskClosable: false,
            content: (
              <ReviewAssetForm
                ref={this.saveFormRef}
                assets={[asset.id]}
                reviewFunc={this.props.endpointActions.reviewUnpublishAsset}
                statusType={'PENDING_REMOVAL'}
                activeFolder={folder}
                allAssets={this.props.allAssets}
                closeForm={this.closeReviewForm}
              />
            ),
          }))
        break

      case 'copyURL':
        func = () =>
          Modal.info({
            title: <span>Public URL</span>,
            icon: (
              <Tooltip
                title={
                  'A Public URL is a url that points to a public copy of the media file for this ' +
                  "asset. It's produced by publishing the asset to a web endpoint, and revoked " +
                  "by unpublishing the asset from any web endpoint where it's published."
                }
              >
                <Icon type="question-circle-o" />
              </Tooltip>
            ),
            okText: 'Okay',
            onOK: () => Promise.resolve({}),
            okType: 'default',
            maskClosable: false,
            content: <PublicURLForm url={asset.publicURL} />,
          })
        break

      default:
        func = null
    }
    return func
  }

  saveFormRef = (ref) => {
    this.reviewAssetForm = ref
  }

  saveTagsRef = (ref) => (this.tagsRef = ref)

  closeReviewForm = () => {
    this.reviewForm.destroy()
  }

  getFilteredFolders = () => {
    return Object.keys(this.props.allFolders)
      .filter(
        (folder) =>
          folder !== '_search' &&
          this.props.allFolders[folder].assets.indexOf(
            this.props.match.params.id
          ) > -1
      )
      .map((folder) => this.props.allFolders[folder])
      .sort((a, b) => {
        // Sort by endpoint sortOrder, then by folder name
        const ep1 = getEndpointOfNode(
          a,
          this.props.allFolders,
          this.props.endpoints.endpoints
        )
        const ep2 = getEndpointOfNode(
          b,
          this.props.allFolders,
          this.props.endpoints.endpoints
        )
        if (ep1.sortOrder < ep2.sortOrder) return -1
        if (ep1.sortOrder > ep2.sortOrder) return 1
        let pathA = buildPath(a.id, this.props.allFolders)
          .reverse()
          .map((id) => this.props.allFolders[id].name)
          .join(' ')
        let pathB = buildPath(b.id, this.props.allFolders)
          .reverse()
          .map((id) => this.props.allFolders[id].name)
          .join(' ')
        return pathA.localeCompare(pathB)
      })
  }

  navigateToFolder = (id) => {
    const {
      history,
      activeEndpoint,
      folderActions,
      appActions,
      allFolders,
      endpoints,
    } = this.props
    const ep = getEndpointOfNode(
      allFolders[id],
      allFolders,
      endpoints.endpoints
    )

    // BAM-2502 ep may not exist due to being filtered out from privileges
    if (!ep.id) {
      return
    }

    if (ep.id !== activeEndpoint) {
      appActions.setOpenEndpointKeys([ep.id])
      appActions.setActiveEndpoint(ep.id)
    }
    const path = buildPath(id, allFolders)
    path.forEach((node) => appActions.addOpenFolderKey(node))
    folderActions.activeFolder(id)
    history.push(`/library/${id}`)
  }

  hideTranscodedVideo = () =>
    this.props.assetActions.saveAssetEdits(
      this.props.allAssets[this.props.match.params.id],
      {
        videoSrc: {
          ...this.props.allAssets[this.props.match.params.id].videoSrc,
          status: TRANSCODING_STATUS_HIDDEN,
          message: 'Transcoded video is hidden.',
        },
      }
    )
  showTranscodedVideo = () =>
    this.props.assetActions.saveAssetEdits(
      this.props.allAssets[this.props.match.params.id],
      {
        videoSrc: {
          ...this.props.allAssets[this.props.match.params.id].videoSrc,
          status: TRANSCODING_STATUS_COMPLETE,
          message: 'Transcoding complete.  Showing mp4 version of video.',
        },
      }
    )

  showReplaceAction = () => {
    const {
      privileges,
      email,
      groups,
      activeFolder,
      allFolders,
      endpoints,
      match,
      allAssets,
      isSuperUser,
    } = this.props
    const assetId = match.params.id
    const asset = allAssets[assetId]
    /* Don't replace UGC Content */
    const activeFolderObject = allFolders[activeFolder]
    const folderIsInUGC =
      activeFolderObject && activeFolderObject.parentId === UGC_FOLDER
    const isUGC = activeFolder === UGC_FOLDER || folderIsInUGC

    if (isUGC) return false

    /* Check if user has editmetadata privileges before allowing them to replace*/
    const folder =
      activeFolder === '_search'
        ? findLibraryFolder(match.params.id, allFolders, endpoints.endpoints)
        : activeFolder

    const hasValidFolderPermissions = validatePrivilege(
      'editmetadata',
      privileges,
      email,
      groups,
      folder,
      allFolders
    )
    /* 
    Check to make sure this is an asset that we can replace, 
    (No builders, calculators allowed with superuser access only ) 
    */
    if (asset.extension === 'calc' || asset.extension === 'form') {
      return hasValidFolderPermissions && isSuperUser
    }
    const isBuilder = BUILDER_TYPES.includes(asset.asset_type)

    return hasValidFolderPermissions && !isBuilder
  }

  render() {
    const asset = this.props.match.params.id
    const {
      allFolders,
      allAssets,
      email,
      privileges,
      groups,
      activeFolder,
      recycleBin,
      backButtonFlag,
      relationships,
      endpoints,
      originalAssetData,
      modalActions,
      appActions,
      assetActions,
      carousel,
      customAssets,
      editModeAsset,
    } = this.props
    const editable = this.props.match.params.id === editModeAsset
    const folderList = Object.keys(allFolders).filter(
      (folder) => allFolders[folder].assets.indexOf(asset) > -1
    )
    const isCropping = this.props.match.path.startsWith('/library/asset/crop')
    const isInRecycleBin =
      activeFolder &&
      allAssets[asset] &&
      buildPath(activeFolder, allFolders).indexOf(recycleBin) > -1
    const isBuilderType = BUILDER_TYPES.includes(allAssets[asset].asset_type)

    const actionDisabled =
      isCropping ||
      isInRecycleBin ||
      (isBuilderType && !isContentAdmin(email, groups))

    const shouldShowReplaceAction = this.showReplaceAction()
    const uploadActionProps = {
      originalAssetData,
      relationships,
      allAssets,
      allFolders,
      endpoints,
      appActions,
      modalActions,
      assetActions,
      asset: allAssets[this.props.match.params.id],
    }
    return allAssets[asset] ? (
      <Content className="assetdetail__sidebar">
        <div className="assetdetail__sidebar-header">
          {}
          {editable ? (
            <div className="assetdetail__panel-content">
              <EditableAttribute
                type="text"
                id="title"
                label="Title"
                cssLabelClass="assetdetail__panel-content-title"
                value={this.state.title}
                cssValueClass="assetdetail__panel-content-value-editable assetdetail__no-margin"
                isEditing={editable}
                changeHandler={this.editChangeHandler}
              />
            </div>
          ) : (
            (backButtonFlag || activeFolder) && (
              <Fragment>
                <i
                  className="fa fa-long-arrow-left assetdetail__sidebar-header-icon"
                  onClick={this.goBack}
                />
                {assetName(allAssets[this.props.match.params.id])}
              </Fragment>
            )
          )}
        </div>
        <div
          className="assetdetail__collapse-container"
          id="assetdetail__collapse-container"
        >
          <Collapse
            className="assetdetail"
            activeKey={this.state.activeKey}
            onChange={this.onPanelChange}
          >
            <Panel
              className="assetdetail__panel"
              key="metadata"
              header={
                <div
                  className={`assetdetail__panel-header${
                    this.state.activeKey.includes('metadata') ? '-noborder' : ''
                  }`}
                >
                  <div
                    className={
                      this.state.activeKey.includes('metadata')
                        ? 'assetdetail__panel-header-active'
                        : ''
                    }
                  >
                    ASSET DETAILS
                  </div>
                </div>
              }
            >
              <AssetDetailPanel
                asset={allAssets[this.props.match.params.id]}
                isEditing={editable}
                editChangeHandler={this.editChangeHandler}
                editState={this.state}
                saveTagsRef={this.saveTagsRef}
                showTranscodedVideo={this.showTranscodedVideo}
                hideTranscodedVideo={this.hideTranscodedVideo}
                allUsers={this.props.allUsers}
                uploadActionProps={uploadActionProps}
                shouldShowReplaceAction={shouldShowReplaceAction}
              />
            </Panel>
            <Panel
              className="assetdetail__panel"
              key="locations"
              header={
                <div
                  className={`assetdetail__panel-header${
                    this.state.activeKey.includes('locations')
                      ? '-noborder'
                      : ''
                  }`}
                >
                  <div
                    className={
                      this.state.activeKey.includes('locations')
                        ? 'assetdetail__panel-header-active'
                        : ''
                    }
                  >
                    LOCATIONS AND STATUS
                  </div>
                </div>
              }
            >
              <AssetLocationPanel
                folderList={this.getFilteredFolders()}
                allFolders={allFolders}
                relationships={relationships}
                asset={allAssets[asset]}
                allAssets={allAssets}
                endpoints={endpoints}
                actionFilter={this.folderActionFilter}
                email={email}
                privileges={privileges}
                groups={groups}
                handler={this.getCardAction}
                actionDisabled={actionDisabled}
                carousel={carousel}
                customAssets={customAssets}
                navigateToFolder={this.navigateToFolder}
              />
            </Panel>
            <Panel
              className="assetdetail__panel"
              key="relationships"
              header={
                <div
                  className={`assetdetail__panel-header${
                    this.state.activeKey.includes('relationships')
                      ? '-noborder'
                      : ''
                  }`}
                >
                  <div
                    className={
                      this.state.activeKey.includes('relationships')
                        ? 'assetdetail__panel-header-active'
                        : ''
                    }
                  >
                    ASSET RELATIONSHIPS
                  </div>
                </div>
              }
            >
              <AssetRelationshipsPanel
                asset={allAssets[asset]}
                allAssets={allAssets}
                relationships={relationships}
                allFolders={allFolders}
                navigateToFolder={this.navigateToFolder}
              />
            </Panel>
            <Border />
          </Collapse>
        </div>
        <div className="assetdetail__footer">
          {editable ? (
            <TwoButtonContainer
              primaryButtonText="Save"
              secondaryButtonText="Cancel"
              primaryButtonAction={this.saveAssetEdit}
              secondaryButtonAction={this.cancelAssetEdit}
            />
          ) : (
            <span className="assetdetail__footer-actions">
              {GLOBAL_ACTIONS.filter((action) =>
                this.globalActionFilter(action, allAssets[asset])
              ).map((action) => {
                return (
                  <ActionIcon
                    key={action.action}
                    action={action}
                    folder={folderList}
                    allFolders={allFolders}
                    email={email}
                    privileges={privileges}
                    groups={groups}
                    disabled={actionDisabled}
                    useText={this.props.activeFolder === UGC_FOLDER}
                    handler={this.getCardAction(action.action, activeFolder)}
                  />
                )
              })}
            </span>
          )}
        </div>
      </Content>
    ) : (
      <LoadingModal />
    )
  }
}

// Throwaway component to get a border into a collapse body
// because AntD expects a Panel, and React complains if you just put a div...
const Border = () => <div className="assetdetail__panel-last"></div>

AssetDetailSidebar.propTypes = {
  match: PropTypes.object,
  relationships: PropTypes.object,
  allAssets: PropTypes.object,
  allFolders: PropTypes.object,
  endpoints: PropTypes.object,
  recycleBin: PropTypes.string,
  activeFolder: PropTypes.string,
  activeEndpoint: PropTypes.string,
  sidebarWidth: PropTypes.number,
  userId: PropTypes.string,
  email: PropTypes.string,
  privileges: PropTypes.object,
  groups: PropTypes.object,
  history: PropTypes.object,
  assetActions: PropTypes.object,
  modalActions: PropTypes.object,
  appActions: PropTypes.object,
  endpointActions: PropTypes.object,
  builderActions: PropTypes.object,
  folderActions: PropTypes.object,
  backButtonFlag: PropTypes.bool,
  carousel: PropTypes.array,
  customAssets: PropTypes.array,
  editModeAsset: PropTypes.string,
  originalAssetData: PropTypes.object,
  allUsers: PropTypes.object,
}

const mapStateToProps = (state) => ({
  allAssets: state.assets.byId,
  originalAssetData: state.assets.originalAssetData,
  relationships: state.assets.relationships,
  recycleBin: state.folders.recycleBin,
  allFolders: state.folders.byId,
  activeFolder: state.folders.activeFolder,
  activeEndpoint: state.appState.activeEndpoint,
  sidebarWidth: state.appState.sidebarWidth,
  editModeAsset: state.appState.editModeAsset,
  endpoints: state.endpoints,
  userId: state.user.id,
  email: state.user.email,
  privileges: state.permissions.privileges,
  groups: state.permissions.groups,
  backButtonFlag: state.appState.backButtonFlag,
  carousel: state.settings.featured.carousel,
  customAssets: state.settings.menu.custom,
  allUsers: state.permissions.users,
  isSuperUser: state.user.level === 'Superuser',
})

const mapDispatchToProps = (dispatch) => ({
  modalActions: bindActionCreators(modalActions, dispatch),
  appActions: bindActionCreators(appActions, dispatch),
  assetActions: bindActionCreators(assetActions, dispatch),
  endpointActions: bindActionCreators(endpointActions, dispatch),
  builderActions: bindActionCreators(builderActions, dispatch),
  folderActions: bindActionCreators(folderActions, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(AssetDetailSidebar)
