import { Button, Divider, Modal } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as modalActions from '../../dux/actions/modal'
import * as actions from '../../dux/actions/permissions'
import { buildPath } from '../../dux/modules/utils'

const GroupTableActions = (props) => (
  <span>
    <Button
      disabled={!props.isAdmin}
      onClick={() =>
        props.modalActions.openModal('EditGroupsModal', {
          group: props.group.id,
          users: props.users,
        })
      }
      icon="edit"
      style={{ margin: '0 5px' }}
    />
    <Button
      disabled={props.group.system || !props.isAdmin}
      icon="delete"
      style={{ margin: '0 5px' }}
      onClick={() => {
        return props.isAdmin
          ? Modal.confirm({
              title: 'Confirm Group delete',
              onOk: () => {
                props.actions.deleteGroup(props.group.id)
                Promise.resolve('')
              },
              content: (
                <div>
                  This action cannot be undone.{' '}
                  {props.groups[props.group.id].members.length > 0
                    ? `This groups has ${
                        props.groups[props.group.id].members.length
                      } member${
                        props.groups[props.group.id].members.length > 1
                          ? 's'
                          : ''
                      }.  `
                    : ''}
                  The group will be removed from all folders where permissions
                  are granted.
                  {props.folderPrivs.length > 0 ? (
                    <div>
                      <Divider />
                      This group is assigned privileges in folders: <br />
                      {props.folderPrivs.map((folder) => {
                        const path = buildPath(folder, props.allFolders)
                        return (
                          <div key={folder}>
                            {path
                              .map((node) => props.allFolders[node].name)
                              .reverse()
                              .join(' > ')}
                          </div>
                        )
                      })}
                    </div>
                  ) : null}
                </div>
              ),
            })
          : null
      }}
    />
  </span>
)

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  modalActions: bindActionCreators(modalActions, dispatch),
})

export default connect(null, mapDispatchToProps)(GroupTableActions)
