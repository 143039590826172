import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActions from '../../dux/actions/appActions'
import * as modalActions from '../../dux/actions/modal'
import * as actions from '../../dux/actions/settings'
import { BUILDER_TYPES } from '../../dux/constants/builders'
import { CAROUSEL_SIZE } from '../../dux/constants/featured'
import ResponsiveBackgroundImage from '../../dux/modules/ResponsiveBackgroundImage'
import { getEndpointById } from '../../dux/modules/utils'
import LoadingModal from '../modals/LoadingModal'

import MSTFeaturedAssetsSettingsView from './MSTFeaturedAssetsSettingsView'

class MSTFeaturedAssetSettings extends React.Component {
  state = {
    originalCarousel: null,
  }

  /* ResponsiveBackgroundImages */
  rbis = {}

  saveRBI = (ref, id) =>
    (this.rbis[id] = new ResponsiveBackgroundImage(
      document.getElementById(id),
      () => 100
    ))

  componentDidMount() {
    this.props.actions.getSetting('featured')
    this.props.appActions.scrollToTop('main-content')
  }

  onSave = () => {
    this.setState({
      message: 'Featured Asset changes saved',
      messageType: 'SUCCESS',
    })
    this.props.actions.saveSettings('featured', {
      carousel: this.props.settings.featured.carousel,
    })
  }

  onCancel = () => {
    if (this.state.originalCarousel) {
      this.props.actions.resetCarousel(this.state.originalCarousel)
    }
    this.setState({
      message: 'Featured Asset changes canceled',
      messageType: 'ERROR',
    })
  }

  removeItem = (asset) => {
    if (!this.state.originalCarousel) {
      this.setState({
        originalCarousel: this.props.settings.featured.carousel.map((fa) => ({
          ...fa,
        })),
        message: null,
        messageType: null,
      })
    }
    this.props.actions.removeAssetFromCarousel(asset)
  }

  addAssetToCarousel = (asset, folder, displayText) => {
    if (!this.state.originalCarousel) {
      this.setState({
        originalCarousel: this.props.settings.featured.carousel.map((fa) => ({
          ...fa,
        })),
        message: null,
        messageType: null,
      })
    } else {
      this.setState({ message: null, messageType: null })
    }
    this.props.actions.addAssetToCarousel(asset, folder, displayText)
  }

  replaceCarouselAsset = (asset, folder, displayText, originalAsset) => {
    if (!this.state.originalCarousel) {
      this.setState({
        originalCarousel: this.props.settings.featured.carousel.map((fa) => ({
          ...fa,
        })),
        message: null,
        messageType: null,
      })
    } else {
      this.setState({ message: null, messageType: null })
    }
    this.props.actions.replaceCarouselAsset(
      asset,
      folder,
      displayText,
      originalAsset
    )
  }

  openSelectAssetModal = (selectedAsset) => {
    let modalProps = {
      treeRoot: getEndpointById('MST', this.props.endpoints).rootFolders,
      filterType: 'published',
      saveCallback: this.addAssetToCarousel,
      modalTitle: 'Assets - Published to Mobile Sales Tool',
      showTextOverride: true,
      carousel: this.props.settings.featured.carousel,
      textOverrideDescription:
        'By default the asset title will be displayed in the carousel.  However, you may ' +
        'customize the text below if necessary.',
    }
    if (selectedAsset) {
      modalProps.selectedAsset = selectedAsset.asset
      modalProps.selectedFolder = selectedAsset.folder
      modalProps.selectedDisplayText = selectedAsset.displayText
      modalProps.saveCallback = (asset, folder, displayText) =>
        this.replaceCarouselAsset(asset, folder, displayText, selectedAsset)
    }
    this.props.modalActions.openModal('SelectAssetModal', modalProps)
  }

  getPreview = (assetId) => {
    const { allAssets } = this.props
    if (BUILDER_TYPES.find((type) => type === allAssets[assetId].asset_type)) {
      const image = allAssets[assetId][allAssets[assetId].asset_type].image
      return image ? allAssets[image].preview : null
    }

    return allAssets[assetId].preview
  }

  render() {
    return (
      <div className="settings__container">
        {this.props.appState.asyncPending ? <LoadingModal /> : null}
        <MSTFeaturedAssetsSettingsView
          carousel={this.props.settings.featured.carousel}
          allAssets={this.props.allAssets}
          loading={this.props.appState.asyncPending}
          removeItem={this.removeItem}
          onSave={this.onSave}
          onCancel={this.onCancel}
          saveRBI={this.saveRBI}
          maxSize={CAROUSEL_SIZE}
          modalActions={this.props.modalActions}
          openSelectAssetModal={this.openSelectAssetModal}
          message={this.state.message}
          messageType={this.state.messageType}
          getPreview={this.getPreview}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  settings: state.settings,
  appState: state.appState,
  allAssets: state.assets.byId,
  endpoints: state.endpoints.endpoints,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  appActions: bindActionCreators(appActions, dispatch),
  modalActions: bindActionCreators(modalActions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MSTFeaturedAssetSettings)
