import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import * as constants from '../constants/branding'
import * as folderConstants from '../constants/folder'
import SessionTimeoutError from '../modules/SessionTimeoutError'
import * as apiActions from './api'
import * as appActions from './appActions'
import * as folderActions from './folder'
import * as userActions from './user'

export const ADD_ASSET_TO_CAROUSEL = 'ADD_ASSET_TO_CAROUSEL'
export const CANCEL_RESOURCES_UPDATE = 'CANCEL_RESOURCES_UPDATE'
export const GET_SETTING = 'GET_SETTING'
export const REFRESH_SETTINGS = 'REFRESH_SETTINGS'
export const REFRESH_SETTINGS_SUCCESS = 'REFRESH_SETTINGS_SUCCESS'
export const REMOVE_ASSET_FROM_CAROUSEL = 'REMOVE_ASSET_FROM_CAROUSEL'
export const REMOVE_CUSTOM_MENU_RESOURCE = 'REMOVE_CUSTOM_MENU_RESOURCE'
export const REMOVE_CUSTOM_RESOURCE = 'REMOVE_CUSTOM_RESOURCE'
export const REPLACE_CAROUSEL_ASSET = 'REPLACE_CAROUSEL_ASSET'
export const RESET_CAROUSEL = 'RESET_CAROUSEL'
export const RESET_MENU_SETTINGS = 'RESET_MENU_SETTINGS'
export const SAVE_CUSTOM_MENU_RESOURCE = 'SAVE_CUSTOM_MENU_RESOURCE'
export const SAVE_SETTINGS = 'SAVE_SETTINGS'
export const SAVE_SETTINGS_SUCCESS = 'SAVE_SETTINGS_SUCCESS'
export const SET_BRANDING_MESSAGE = 'SET_BRANDING_MESSAGE'
export const SET_CUSTOM_RESOURCE = 'SET_CUSTOM_RESOURCE'
export const SET_SETTINGS_ACTIVE_KEY = 'SET_SETTINGS_ACTIVE_KEY'
export const SET_SETTINGS_ACTIVE_ITEM = 'SET_SETTINGS_ACTIVE_ITEM'
export const SET_SETTINGS_OPEN_KEYS = 'SET_SETTINGS_OPEN_KEYS'
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'
export const VALIDATE_BRANDING_FILE = 'VALIDATE_BRANDING_FILE'

export const setSettingsOpenKeys = (keys) => ({
  type: SET_SETTINGS_OPEN_KEYS,
  keys,
})

export const setSettingsActiveKey = (key) => ({
  type: SET_SETTINGS_ACTIVE_KEY,
  key,
})

export const setSettingsActiveItem = (item) => ({
  type: SET_SETTINGS_ACTIVE_ITEM,
  item,
})

export const setBrandingMessage = (msg, msgType) => ({
  type: SET_BRANDING_MESSAGE,
  msg,
  msgType,
})

export const addAssetToCarousel = (asset, folder, displayText) => ({
  type: ADD_ASSET_TO_CAROUSEL,
  asset,
  folder,
  displayText,
})

export const resetCarousel = (carousel) => ({
  type: RESET_CAROUSEL,
  carousel,
})

export const removeAssetFromCarousel = (asset) => ({
  type: REMOVE_ASSET_FROM_CAROUSEL,
  asset,
})

export const replaceCarouselAsset = (
  asset,
  folder,
  displayText,
  originalAsset
) => ({
  type: REPLACE_CAROUSEL_ASSET,
  asset,
  folder,
  displayText,
  originalAsset,
})

export const saveCustomMenuResource = (resource) => ({
  type: SAVE_CUSTOM_MENU_RESOURCE,
  resource,
})

export const removeCustomMenuResource = (resource) => ({
  type: REMOVE_CUSTOM_MENU_RESOURCE,
  resource,
})

export const resetMenuSettings = (originalResources) => ({
  type: RESET_MENU_SETTINGS,
  originalResources,
})

export const saveCustomResource = (resource) => ({
  type: SET_CUSTOM_RESOURCE,
  resource,
})

export const removeCustomResource = (resourceId) => ({
  type: REMOVE_CUSTOM_RESOURCE,
  resourceId,
})

export const cancelResourcesUpdate = (originalResources) => ({
  type: CANCEL_RESOURCES_UPDATE,
  originalResources,
})

export const getSetting = (setting) => ({
  type: GET_SETTING,
  setting,
})

export function* watchGetSetting() {
  yield takeEvery(GET_SETTING, getSettingSaga)
}

export function* getSettingSaga(action) {
  try {
    yield put({ type: appActions.ADD_PENDING_ACTION, action: GET_SETTING })
    yield put({ type: appActions.INITIATE_ASYNC })
    const config = yield select((state) => state.config.appConfig)
    const result = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/settings/v2/${action.setting}`,
      init: {
        method: 'GET',
      },
    })
    yield put({ type: UPDATE_SETTINGS, result })
    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    console.log(error)
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
    }
  } finally {
    yield put({ type: appActions.REMOVE_PENDING_ACTION, action: GET_SETTING })
  }
}

export const refreshSettings = () => ({
  type: REFRESH_SETTINGS,
})

export function* watchRefreshSettings() {
  yield takeEvery(REFRESH_SETTINGS, refreshSettingsSaga)
}

export function* refreshSettingsSaga() {
  try {
    yield put({ type: appActions.INITIATE_ASYNC })
    const config = yield select((state) => state.config.appConfig)
    const settings = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/settings/v2/all`,
      init: {
        method: 'GET',
      },
    })
    const keys = Object.keys(settings)
    for (let key of keys) {
      yield put({ type: UPDATE_SETTINGS, result: settings[key] })
    }

    yield put({ type: REFRESH_SETTINGS_SUCCESS })
    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      console.log(error)
      yield put({ type: appActions.COMPLETE_ASYNC })
    }
  }
}

export const saveSettings = (setting, value) => ({
  type: SAVE_SETTINGS,
  setting,
  value,
})

export function* watchSaveSettings() {
  yield takeEvery(SAVE_SETTINGS, saveSettingsSaga)
}

export function* saveSettingsSaga(action) {
  try {
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
    const config = yield select((state) => state.config.appConfig)
    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/settings/update`,
      init: {
        method: 'POST',
        body: JSON.stringify({
          [action.setting]: action.value,
        }),
      },
    })
    yield put({
      type: SAVE_SETTINGS_SUCCESS,
      setting: action.setting,
      value: action.value,
    })
    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    console.log(error)
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
    }
  }
}

export const validateFile = (file, brandingType, callback) => ({
  type: VALIDATE_BRANDING_FILE,
  file,
  brandingType,
  callback,
})

export function* watchValidateFile() {
  yield takeEvery(VALIDATE_BRANDING_FILE, validateFileSaga)
}

export function* validateFileSaga(action) {
  try {
    yield put({ type: appActions.INITIATE_ASYNC })
    const config = yield select((state) => state.config.appConfig)
    const dimensions = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/imgx/size`,
      init: {
        method: 'POST',
        body: JSON.stringify(action.file.replace(`${config.baseUrl}/`, '')),
      },
    })

    if (dimensions.error) {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield call(action.callback, `Invalid file: ${dimensions.error}`)
    } else {
      const expectedDimensions = yield call(
        getExpectedDimensions,
        action.brandingType
      )
      if (
        dimensions.width !== expectedDimensions.widthh ||
        dimensions.height !== expectedDimensions.height
      ) {
        yield call(
          action.callback,
          null,
          `Recommended dimensions: ${expectedDimensions.width} x ${expectedDimensions.height}, ` +
            `Actual dimensions: ${dimensions.width} x ${dimensions.height}`
        )
      }
      yield put({ type: appActions.COMPLETE_ASYNC })
    }
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      console.log(error)
      yield put({ type: appActions.COMPLETE_ASYNC })
    }
  }
}

const getExpectedDimensions = (type) => {
  switch (type) {
    case 'logo':
      return {
        width: constants.LOGO_WIDTH,
        height: constants.LOGO_HEIGHT,
      }

    case 'splashScreen':
      return {
        width: constants.SPLASH_WIDTH,
        height: constants.SPLASH_HEIGHT,
      }

    case 'brandProfile': {
      return {
        width: constants.BRAND_PROFILE_WIDTH,
        height: constants.BRAND_PROFILE_HEIGHT,
      }
    }
    case 'webendpoint404': {
      return {
        width: constants.WEB_ENDPOINT_404_WIDTH,
        height: constants.WEB_ENDPOINT_404_HEIGHT,
      }
    }
    default:
      return {
        width: 0,
        height: 0,
      }
  }
}

export function* watchVirtualizeUploadGroups() {
  // folder state is overwritten during refresh
  yield takeLatest(appActions.REFRESH_STATE, virtualizeUploadGroups)
  // when settings are loaded, refresh virtualization
  yield takeLatest(REFRESH_SETTINGS_SUCCESS, virtualizeUploadGroups)
  // virtualize when uploads setting is changed
  yield takeLatest(SAVE_SETTINGS_SUCCESS, virtualizeUploadGroups)
}

function* virtualizeUploadGroups(action) {
  if (action.type === SAVE_SETTINGS_SUCCESS && action.setting !== 'uploads') {
    return
  }

  const uploads = yield select((state) => state.settings.uploads)
  const assets = yield select((state) => state.assets.byId)
  const folders = yield select((state) => state.folders.byId)
  const ugc = folders[folderConstants.UGC_FOLDER]

  try {
    const virtualFolders = uploads.groups.map((group) => {
      const vfId = `${folderConstants.UGC_FOLDER_TYPE}.${group}`

      return {
        ...ugc,
        id: vfId,
        assets: ((folders[vfId] && folders[vfId].assets) || []).concat(
          ugc.assets.filter(
            (assetId) =>
              assets[assetId] &&
              !assets[assetId].deleted &&
              assets[assetId].description &&
              assets[assetId].description
                .toLowerCase()
                .startsWith(group.toLowerCase())
          )
        ),
        childFolders: [],
        name: group,
        parentId: folderConstants.UGC_FOLDER,
        type: folderConstants.VIRTUAL_FOLDER_TYPE,
        virtual: true,
      }
    })

    yield put({
      type: folderActions.SET_VIRTUAL_FOLDERS,
      virtualFolders: virtualFolders || [],
    })
  } catch (err) {
    console.error(err)
  }
}

export default all([
  watchGetSetting(),
  watchRefreshSettings(),
  watchSaveSettings(),
  watchValidateFile(),
  watchVirtualizeUploadGroups(),
])
