import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as appActions from '../dux/actions/appActions'
import * as searchActions from '../dux/actions/search'
import * as userActions from '../dux/actions/user'
import { isAdmin, isContentAdmin } from '../dux/modules/utils'
import BamHeader from './BamHeader'

class BamHeaderContainer extends React.Component {
  handleClick = (e) => {
    this.props.appActions.setMenuItem(e.key)
  }

  onChangeSearch = (e) => {
    this.props.searchActions.setQuery(e.target.value)
  }

  onSubmitSearch = () => {
    // Block search if other action is pending (like existing search)
    if (!this.props.loading) {
      // First scrub the search input, remove any escape slashes
      const query = this.props.searchQuery.replace(/\\/gi, ' ')

      this.props.history.push('/library/_search')
      this.props.searchActions.submitSearch(query)
    }
  }

  isDisabled = () => {
    let disabled = false
    if (this.props.menuItem !== 'library') disabled = true

    return disabled
  }

  getSettingsLink = () => {
    const { settingsActiveKey, settingsActiveItem, settingsMenu } =
      this.props.settings
    return settingsMenu
      .filter((key) => key.id === settingsActiveKey)[0]
      .items.filter((item) => item.id === settingsActiveItem)[0].route
  }

  render() {
    return (
      <BamHeader
        isAdmin={isAdmin(this.props.user.email, this.props.groups)}
        isContentAdmin={isContentAdmin(
          this.props.user.email,
          this.props.groups
        )}
        mask={this.props.config.mask}
        handleClick={this.handleClick}
        onChangeSearch={this.onChangeSearch}
        onSubmitSearch={this.onSubmitSearch}
        selectedKeys={[this.props.menuItem]}
        activeFolder={this.props.activeFolder}
        searchQuery={this.props.searchQuery}
        settingsLink={this.getSettingsLink()}
        isDisabled={this.isDisabled}
        logoWidth={this.props.logoWidth}
        modules={this.props.settings.modules}
      />
    )
  }
}

BamHeaderContainer.propTypes = {
  activeFolder: PropTypes.string,
  user: PropTypes.object,
  loading: PropTypes.bool,
  menuItem: PropTypes.string,
  searchQuery: PropTypes.string,
  groups: PropTypes.object,
  history: PropTypes.object,
  userActions: PropTypes.object,
  appActions: PropTypes.object,
  searchActions: PropTypes.object,
  logoWidth: PropTypes.number,
}

const mapStateToProps = (state) => {
  return {
    activeFolder: state.folders.activeFolder,
    user: state.user,
    loading: state.appState.asyncPending,
    menuItem: state.appState.menuItem,
    searchQuery: state.search.searchQuery,
    groups: state.permissions.groups,
    settings: state.settings,
    config: state.config.appConfig,
  }
}

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(userActions, dispatch),
  appActions: bindActionCreators(appActions, dispatch),
  searchActions: bindActionCreators(searchActions, dispatch),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BamHeaderContainer)
)
