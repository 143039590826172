import { message, Modal } from 'antd'
import { omit } from 'lodash'
import md5 from 'md5'
import React from 'react'
import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects'
import * as analyticsConstants from '../constants/analytics'
import * as endpointConstants from '../constants/endpoints'
import * as settingsConstants from '../constants/settings'
import SessionTimeoutError from '../modules/SessionTimeoutError'
import {
  assetName,
  containsPublishedAsset,
  createFolderToAssetsCollection,
  displayToast,
  getEndpointOfNode,
  recordAnalyticsEvent,
} from '../modules/utils'
import * as apiActions from './api'
import * as appActions from './appActions'
import * as assetActions from './asset'
import * as folderActions from './folder'
import * as modalActions from './modal'
import * as notificationActions from './notifications'
import * as settingsActions from './settings'
import * as userActions from './user'
import helpers from './utils/helpers'

export const PENDING_APPROVAL = 'PENDING_APPROVAL'
export const APPROVED = 'APPROVED'
export const REJECTED = 'REJECTED'
export const PENDING_REMOVAL = 'PENDING_REMOVAL'
export const UNPUBLISHED = 'UNPUBLISHED'
export const ASSET_STATUS_STATES = [
  UNPUBLISHED,
  PENDING_APPROVAL,
  APPROVED,
  REJECTED,
  PENDING_REMOVAL,
]

export const ADD_ENDPOINT = 'ADD_ENDPOINT'
export const ADD_ENDPOINT_SUCCESS = 'ADD_ENDPOINT_SUCCESS'
export const DELETE_ENDPOINT = 'DELETE_ENDPOINT'
export const DELETE_ENDPOINT_SUCCESS = 'DELETE_ENDPOINT_SUCCESS'
export const LOAD_ENDPOINT_STATE = 'LOAD_ENDPOINT_STATE'
export const PUBLISH_ASSET = 'PUBLISH_ASSET'
export const PUBLISH_ASSET_SUCCESS = 'PUBLISH_ASSET_SUCCESS'
export const PUBLISH_AND_APPROVE_ASSET = 'PUBLISH_AND_APPROVE_ASSET'
export const PUBLISH_AND_APPROVE_ASSET_SUCCESS =
  'PUBLISH_AND_APPROVE_ASSET_SUCCESS'
export const BATCH_PUBLISH = 'BATCH_PUBLISH'
export const RENAME_ENDPOINT = 'RENAME_ENDPOINT'
export const RENAME_ENDPOINT_SUCCESS = 'RENAME_ENDPOINT_SUCCESS'
export const REVIEW_ASSET = 'REVIEW_ASSET'
export const REVIEW_ASSET_SUCCESS = 'REVIEW_ASSET_SUCCESS'
export const UNPUBLISH_ASSET = 'UNPUBLISH_ASSET'
export const UNPUBLISH_ASSET_SUCCESS = 'UNPUBLISH_ASSET_SUCCESS'
export const UNPUBLISH_AND_APPROVE_ASSET = 'UNPUBLISH_AND_APPROVE_ASSET'
export const UNPUBLISH_AND_APPROVE_ASSET_SUCCESS =
  'UNPUBLISH_AND_APPROVE_ASSET_SUCCESS'
export const REVIEW_UNPUBLISH_ASSET = 'REVIEW_UNPUBLISH_ASSET'
export const REVIEW_UNPUBLISH_ASSET_SUCCESS = 'REVIEW_UNPUBLISH_ASSET_SUCCESS'
export const UNPUBLISH = 'UNPUBLISH'
export const UPUB_WEB_ENDPOINT_ASSET = 'UPUB_WEB_ENDPOINT_ASSET'

/**
 * Action creator for addEndpoint saga
 * @param {String} client client id
 * @param {String} name endpoint name
 * @param {Object} options endpoint options, such as description, badges, etc
 */
export const addEndpoint = (client, name, options) => ({
  type: ADD_ENDPOINT,
  client,
  name,
  options,
})

export function* watchAddEndpoint() {
  yield takeLeading(ADD_ENDPOINT, addEndpointSaga)
}

export function* addEndpointSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC })

  try {
    const config = yield select((state) => state.config.appConfig)
    const { endpoints } = yield select((state) => state.endpoints)
    const highestSortOrder = endpoints.reduce(
      (x, y) => Math.max(x.sortOrder || 0, y.sortOrder || 0),
      0
    )
    const events = []

    action.endpoint = {
      actionIcon: '',
      allowPublishing: false,
      badges: [],
      description: '',
      client: action.client,
      icon: '',
      id: `WEB-${md5(`${action.name}${Date.now()}`)}`,
      name: action.name,
      privilege: '',
      rootFolders: [],
      views: [],
      sortOrder: highestSortOrder + 1,
      ...action.options,
    }

    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/state/addaction`,
      init: {
        method: 'POST',
        body: JSON.stringify({ action, events }),
      },
    })

    yield put({ type: ADD_ENDPOINT_SUCCESS, endpoint: action.endpoint })
  } catch (err) {
    console.error(err)
    if (err instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    }
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

export const deleteEndpoint = (endpointId, confirm = true) => ({
  type: DELETE_ENDPOINT,
  endpointId,
  confirm,
})

export function* watchDeleteEndpoint() {
  yield takeLeading(DELETE_ENDPOINT, deleteEndpointSaga)
}

export function* deleteEndpointSaga(action) {
  if (action.confirm) {
    yield call(
      () =>
        new Promise((resolve, reject) => {
          Modal.confirm({
            cancelText: 'No',
            content:
              'Deleting an endpoint cannot be undone. Do you wish to delete this endpoint?',
            okText: 'Yes',
            okType: 'danger',
            onCancel: () => reject(),
            onOk: () => resolve(),
            title: 'Delete endpoint',
          })
        })
    )
  }

  try {
    yield put({ type: appActions.INITIATE_ASYNC })

    const {
      assetState: { byId: allAssets, relationships },
      folderState: { byId: allFolders },
      endpointState: { endpoints },
    } = yield call(appActions.refreshStateSaga)

    const endpoint = endpoints.find((e) => e.id === action.endpointId)

    if (
      endpoint.rootFolders.some((folder) =>
        containsPublishedAsset(
          folder,
          allFolders,
          allAssets,
          relationships.assets
        )
      )
    ) {
      yield call(Modal.warning, {
        content:
          'This folder - or one of its subfolders - contains a published or pending asset. ' +
          'All assets must be unpublished before deleting',
        title: 'Unable to delete',
      })
    } else {
      const { recycleBin } = yield select((state) => state.folders)
      const dragData = { over: null, above: null, below: null }
      const dropActions = []

      for (const rootFolder of endpoint.rootFolders) {
        dropActions.push(
          call(folderActions.dropFolderSaga, {
            type: folderActions.MOVE_FOLDER,
            dropId: recycleBin,
            dragId: rootFolder,
            parent: undefined,
            dragData,
          })
        )
      }

      yield all(dropActions)

      if (endpoint.type === endpointConstants.WEB_ENDPOINT_TYPE) {
        const currentWebEndpointSettings = yield select(
          (state) =>
            state.settings[settingsConstants.SETTINGS_KEY_WEB_ENDPOINTS]
        )

        yield call(settingsActions.saveSettingsSaga, {
          type: settingsActions.SAVE_SETTINGS,
          setting: settingsConstants.SETTINGS_KEY_WEB_ENDPOINTS,
          value: {
            image404sByEndpointId: omit(
              currentWebEndpointSettings.image404sByEndpointId,
              action.endpointId
            ),
          },
        })
      }

      const config = yield select((state) => state.config.appConfig)
      const events = []

      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/state/addaction`,
        init: {
          method: 'POST',
          body: JSON.stringify({ action, events }),
        },
      })

      yield put({
        type: DELETE_ENDPOINT_SUCCESS,
        endpointId: action.endpointId,
      })
    }
  } catch (err) {
    console.error(err)
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

/*
 * Public facing action creator for publishing assets
 * @param 'type' - constant action type
 * @param ['ids'] - array of asset IDs that was published
 * @param 'parent' - string ID of the parent folder that assets were published to
 * @param notifyOptions - push notification options
 */
export const publishAsset = (assets, parent, notifyOptions) => ({
  type: PUBLISH_ASSET,
  assets,
  parent,
  notifyOptions,
})

export function* watchPublishAsset() {
  yield takeEvery(PUBLISH_ASSET, publishAssetSaga)
}

/*
 * Saga for managing the asset publishing actions
 *
 *  Assigns user details to the incoming action
 *  Creates events to be logged in the event log
 *  Calls the addaction api to update cloudstate and log events
 */
export function* publishAssetSaga(action) {
  const {
    assets, // list of asset objects
    parent, // folder id string,
    notifyOptions: { pushNotification = false, pushNotificationMessage },
    type,
  } = action

  yield put({ type: appActions.INITIATE_ASYNC })

  try {
    const config = yield select((state) => state.config.appConfig)
    const data = yield call(appActions.refreshStateSaga)

    /* Check that folder exists, is not in the recycleBin,
     *  and does not currently have a copy of this asset
     */
    if (helpers.validatePublishRequest(action, data)) {
      const timestamp = yield call(Date.now)
      action.timestamp = timestamp
      const user = yield select((state) => state.user)
      action.user = user.id
      action.client = user.client

      let events = []
      assets.forEach((asset) => {
        events.push({
          target_id: asset,
          timestamp,
          event_type: type,
          user: user.id,
          attributes: [
            {
              name: 'folder',
              old_value: null,
              new_value: parent,
            },
            {
              name: 'status',
              old_value: null,
              new_value: PENDING_APPROVAL,
            },
          ],
        })
      })
      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/state/addaction`,
        init: {
          method: 'POST',
          body: JSON.stringify({ action, events }),
        },
      })

      if (pushNotification) {
        const { assetState, folderState } = data

        yield call(apiActions.secureFetchSaga, {
          url: `${config.baseUrl}/api/notifications/push`,
          init: {
            method: 'POST',
            body: JSON.stringify({
              assets: assets.map((asset) => ({
                id: asset,
                name:
                  assetState.byId[asset].title ||
                  assetState.byId[asset].filename,
              })),
              destination: { id: parent, name: folderState.byId[parent].name },
              message: pushNotificationMessage,
              pendingReview: true,
              senderId: user.id,
            }),
          },
        })
      }

      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({ type: modalActions.CLOSE_MODAL })
      yield put({
        type: PUBLISH_ASSET_SUCCESS,
        assets,
        parent,
        user: user.id,
        timestamp,
      })
      if (assets.length > 1) {
        yield call(
          message.success,
          `Your request to publish ${assets.length} assets was submitted for review`,
          4
        )
      } else {
        const filename = yield select(
          (state) => state.assets.byId[assets[0]].filename
        )
        yield call(
          message.success,
          `Your request to publish ${filename} was submitted for review`,
          4
        )
      }
      yield put({ type: appActions.CLEAR_SELECTED_ASSETS })
      for (let asset of assets) {
        yield fork(recordAnalyticsEvent, PUBLISH_ASSET, {
          asset: data.assetState.byId[asset],
        })
      }
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: 'Asset could not be published.  The application is refreshed...please try again',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
      yield put({ type: appActions.CLEAR_SELECTED_ASSETS })
    }
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: error.clientMessage || 'Failed to publish asset',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
      console.log(error)
    }
  }
}

export const publishAndApproveAsset = (assets, parent, notifyOptions) => ({
  type: PUBLISH_AND_APPROVE_ASSET,
  assets,
  parent,
  notifyOptions,
})

export function* watchPublishAndApproveAsset() {
  yield takeEvery(PUBLISH_AND_APPROVE_ASSET, publishAndApproveSaga)
}

export function* publishAndApproveSaga(action) {
  const {
    assets, // list of asset objects
    parent, // folder id string,
    comments,
    notifyOptions: { pushNotification = false, pushNotificationMessage },
    type,
  } = action

  console.log('publishAndApproveSaga', { action })

  const ignoreUI = action.ignoreUI
  delete action.ignoreUI

  if (!ignoreUI)
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })

  try {
    const config = yield action.config ||
      select((state) => state.config.appConfig)
    delete action.config
    const data = yield action.data || call(appActions.refreshStateSaga)
    delete action.data
    const user = yield action.user || select((state) => state.user)
    delete action.user
    const allAssets = yield select((state) => state.assets.byId)

    // Check that folder exists, is not in the recycleBin, and does not currently have a copy of
    // this asset
    if (helpers.validatePublishRequest(action, data)) {
      const timestamp = yield call(Date.now)
      action.timestamp = timestamp
      action.statusType = APPROVED
      action.user = user.id
      action.client = user.client

      // ** Get Public Link ** //
      const endpoints = yield select((state) => state.endpoints.endpoints)
      const allFolders = yield select((state) => state.folders.byId)
      const parentFolder = allFolders[parent]
      /*
       *  If the folder that the asset will be published to is a web endpoint,
       *  then we need to call the saga that creates the public url and adds it
       *  to the asset meta data.
       */
      const endpoint = getEndpointOfNode(parentFolder, allFolders, endpoints)
      const isWebEndpoint = endpoint.type === 'web'
      if (isWebEndpoint) {
        yield call(managePublicLinksForAssets, { assets })
        yield put(
          uPubWebEndpointAssets({
            fiInitialParent: parent,
            ailTargets: assets,
            uPubType: 'PUBLISH',
          })
        )
        return
      }
      // ** END Get Public Link ** //

      let events = []
      assets.forEach((asset) => {
        events.push(
          {
            target_id: asset,
            timestamp,
            event_type: type,
            user: user.id,
            attributes: [
              {
                name: 'folder',
                old_value: null,
                new_value: parent,
              },
              {
                name: 'status',
                old_value: null,
                new_value: PENDING_APPROVAL,
              },
            ],
          },
          {
            target_id: asset,
            timestamp: timestamp + 1,
            event_type: type,
            user: user.id,
            attributes: [
              {
                name: 'status',
                old_value: PENDING_APPROVAL,
                new_value: APPROVED,
              },
              {
                name: 'folder',
                old_value: parent,
                new_value: parent,
              },
              {
                name: 'reviewer',
                old_value: null,
                new_value: user.id,
              },
              {
                name: 'comments',
                old_value: null,
                new_value: comments,
              },
            ],
          }
        )
      })
      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/state/addaction`,
        init: {
          method: 'POST',
          body: JSON.stringify({ action, events }),
        },
      })

      // publish a message to update state
      if (!ignoreUI)
        yield call(apiActions.secureFetchSaga, {
          url: `${config.baseUrl}/api/state/sns/update`,
          init: {
            method: 'GET',
          },
        })

      if (pushNotification) {
        const { assetState, folderState } = data

        yield call(apiActions.secureFetchSaga, {
          url: `${config.baseUrl}/api/notifications/push`,
          init: {
            method: 'POST',
            body: JSON.stringify({
              assets: assets.map((asset) => ({
                id: asset,
                name:
                  assetState.byId[asset].title ||
                  assetState.byId[asset].filename,
              })),
              destination: { id: parent, name: folderState.byId[parent].name },
              message: pushNotificationMessage,
              pendingReview: false,
              senderId: user.id,
            }),
          },
        })
      }

      if (!ignoreUI) yield put({ type: appActions.COMPLETE_ASYNC })
      if (!ignoreUI) yield put({ type: modalActions.CLOSE_MODAL })
      yield put({
        type: PUBLISH_AND_APPROVE_ASSET_SUCCESS,
        assets,
        parent,
        user: user.id,
        timestamp,
        statusType: action.statusType,
      })

      if (assets.length > 1) {
        if (!ignoreUI)
          yield call(
            message.success,
            `${assets.length} assets are now live within ${allFolders[parent].name}`,
            4
          )
      } else {
        if (!ignoreUI)
          yield call(
            message.success,
            <span>
              {assetName(allAssets[assets[0]])} is now live within{' '}
              {allFolders[parent].name}
            </span>,
            4
          )
      }

      if (!ignoreUI) yield put({ type: appActions.CLEAR_SELECTED_ASSETS })
      for (let asset of action.assets) {
        yield fork(recordAnalyticsEvent, PUBLISH_ASSET, {
          asset: data.assetState.byId[asset],
        })
        yield fork(
          recordAnalyticsEvent,
          analyticsConstants.PUBLISH_ASSET_APPROVE,
          {
            asset: data.assetState.byId[asset],
          }
        )
      }
    } else {
      if (!ignoreUI) yield put({ type: appActions.COMPLETE_ASYNC })
      if (!ignoreUI)
        yield put({
          type: modalActions.SET_MESSAGE,
          msg: {
            type: 'ERROR',
            msg: 'Asset could not be published.  The application is refreshed...please try again',
          },
        })
      if (!ignoreUI)
        yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
      yield put({ type: appActions.CLEAR_SELECTED_ASSETS })

      return yield false
    }

    return yield true
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      if (!ignoreUI) yield put({ type: appActions.COMPLETE_ASYNC })
      if (!ignoreUI)
        yield put({
          type: modalActions.SET_MESSAGE,
          msg: {
            type: 'ERROR',
            msg: error.clientMessage || 'Failed to publish asset',
          },
        })
      if (!ignoreUI)
        yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
      console.log(error)
    }

    return yield false
  }
}

export const batchPublish = (publishes) => ({
  type: BATCH_PUBLISH,
  publishes,
})

export function* watchBatchPublish() {
  yield takeEvery(BATCH_PUBLISH, batchPublishSaga)
}

export function* batchPublishSaga(action) {
  const config = yield select((state) => state.config.appConfig)
  const user = yield select((state) => state.user)
  const data = yield call(appActions.refreshStateSaga)

  const publishes = action.publishes

  for (let i = 0; i < publishes.length; ++i) {
    console.log(`publish: ${i + 1} of ${publishes.length}`, publishes[i])
    const publishAction = publishes[i]
    try {
      const { assetId, targetId } = publishAction
      const publishAndApproveAction = {
        type: PUBLISH_AND_APPROVE_ASSET,
        config,
        user,
        data,
        parent: targetId,
        assets: [assetId],
        notifyOptions: { pushNotification: false },
        ignoreUI: true,
      }
      const result = yield call(publishAndApproveSaga, publishAndApproveAction)
      if (!result) throw 'publish failure'
      console.log('publish success', { publishAction })

      if (i % 15 === 0) {
        yield call(apiActions.secureFetchSaga, {
          url: `${config.baseUrl}/api/state/sns/update`,
          init: {
            method: 'GET',
          },
        })
      }
    } catch (error) {
      console.log('publish failure', { publishAction, error })
    }
  }
}

export const renameEndpoint = (newName, id, showSpinner = true) => ({
  type: RENAME_ENDPOINT,
  newName,
  id,
  showSpinner,
})

export function* watchRenameEndpoint() {
  yield takeLeading(RENAME_ENDPOINT, renameEndpointSaga)
}

export function* renameEndpointSaga(action) {
  try {
    const { endpoints } = yield select((state) => state.endpoints)
    const endpoint = endpoints.find((e) => e.id === action.id)

    if (!action.newName) {
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: { type: 'ERROR', msg: 'Empty endpoint name is not allowed' },
      })
      return yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }

    if (endpoint.name === action.newName) {
      return
    }

    if (action.showSpinner) {
      yield put({ type: appActions.INITIATE_ASYNC })
    }

    const config = yield select((state) => state.config.appConfig)
    const user = yield select((state) => state.user)

    action.user = user.id
    action.client = user.client

    let events = []
    const result = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/state/addaction`,
      init: {
        method: 'POST',
        body: JSON.stringify({ action, events }),
      },
    })

    if (result.statusCode && result.statusCode !== 200) {
      throw new Error(result.message)
    } else {
      yield put({
        type: RENAME_ENDPOINT_SUCCESS,
        id: action.id,
        newName: action.newName,
      })
    }
  } catch (err) {
    if (err instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: 'Unable to rename endpoint. Please try your request again',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  } finally {
    if (action.showSpinner) {
      yield put({ type: appActions.COMPLETE_ASYNC })
    }
  }
}

// okFunc and approveFunc is passed from the connected component because
// it already has dispatch bound.  Importing dispatch caused
// unit test issues.
export const unpublish = (assets, folder, okFunc, approveFunc) => ({
  type: UNPUBLISH,
  assets,
  folder,
  okFunc,
  approveFunc,
})

export function* watchUnpublish() {
  yield takeEvery(UNPUBLISH, unpublishSaga)
}

export function* unpublishSaga(action) {
  const { folder, assets, okFunc, approveFunc } = action

  yield call(settingsActions.refreshSettingsSaga)

  const folders = yield select((state) => state.folders.byId)
  const folderType = folders[folder] && folders[folder].type
  const inWebEndpoint = folderType === 'webfolder'

  const baseMessage = 'This action requires approval.  Continue?'
  let fullMessage
  if (inWebEndpoint) {
    fullMessage =
      assets.length > 1
        ? `Before you unpublish these assets, make sure they are not currently live on any ` +
          `of your public websites. Unpublishing from the DAM will deactivate their public urls ` +
          `and remove them from anywhere they're being used.  ${baseMessage}`
        : `Before you unpublish an asset, make sure it is not currently live on any of your ` +
          `public websites. Unpublishing from the DAM will deactivate its public url and remove ` +
          `it from anywhere it’s being used.  ${baseMessage}`
  } else {
    fullMessage =
      assets.length > 1
        ? `Unpublishing these assets will remove them from the endpoint.  ${baseMessage}`
        : `Unpublishing this asset will remove it from the endpoint.  ${baseMessage}`
  }

  const carousel = yield select((state) => state.settings.featured.carousel)
  const customAssets = yield select((state) =>
    state.settings.menu.custom.filter(
      (resource) => resource.resourceType === 'asset'
    )
  )

  let featured = false

  let message = ''
  action.assets.forEach((asset) => {
    if (
      carousel.filter((fa) => fa.asset === asset && fa.folder === folder)
        .length > 0
    ) {
      featured = true
      message =
        'A selected asset is published in the Featured Assets carousel.  It may not be ' +
        'unpublished until it is removed from the carousel.'
    }

    if (!featured && customAssets.some((val) => val.resourceValue === asset)) {
      featured = true
      message =
        'A selected asset is published as a Custom Menu Asset.  It may not be unpublished until ' +
        'it is removed from the Menu.'
    }
  })

  if (featured) {
    yield call(Modal.confirm, {
      title: 'Unpublish Asset',
      onOk: () => {
        Promise.resolve({})
      },
      content: message,
    })
  } else {
    if (approveFunc) {
      yield put({
        type: modalActions.OPEN_MODAL,
        modal: 'UnpublishAssetModal',
        props: {
          canApprove: true,
          requestFunc: okFunc,
          approveFunc: approveFunc,
          assets: assets,
          message: fullMessage,
        },
      })
    } else {
      yield call(Modal.confirm, {
        title: 'Unpublish Asset',
        onOk: () => {
          okFunc()
          Promise.resolve({})
        },
        content: fullMessage,
      })
    }
  }
}

export const unpublishAsset = (assets, parent) => ({
  type: UNPUBLISH_ASSET,
  assets,
  parent,
})

export function* watchUnpublishAsset() {
  yield takeEvery(UNPUBLISH_ASSET, unpublishAssetSaga)
}

export function* unpublishAssetSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
  try {
    const config = yield select((state) => state.config.appConfig)
    const data = yield call(appActions.refreshStateSaga)
    if (helpers.validateUnpublishRequest(action, data)) {
      const timestamp = yield call(Date.now)
      const user = yield select((state) => state.user)
      action.user = user.id
      action.client = user.client
      action.statusType = PENDING_REMOVAL
      action.timestamp = timestamp
      // Build events for event log
      let events = []
      for (let asset of action.assets) {
        events.push({
          target_id: asset,
          event_type: action.type,
          timestamp,
          user: action.user,
          attributes: [
            {
              name: 'status',
              old_value: APPROVED,
              new_value: PENDING_REMOVAL,
            },
            {
              name: 'folder',
              old_value: action.parent,
              new_value: action.parent,
            },
            {
              name: 'requester',
              old_value: null,
              new_value: action.user,
            },
          ],
        })
      }
      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/state/addaction`,
        init: {
          method: 'POST',
          body: JSON.stringify({ action, events }),
        },
      })

      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({ type: modalActions.CLOSE_MODAL })
      yield put({
        type: UNPUBLISH_ASSET_SUCCESS,
        assets: action.assets,
        parent: action.parent,
        statusType: PENDING_REMOVAL,
        user: action.user,
        client: action.client,
        timestamp,
      })
      if (action.assets.length > 1) {
        yield call(
          message.success,
          `Your request to remove ${action.assets.length} assets was submitted.`,
          4
        )
      } else {
        const assets = yield select((state) => state.assets.byId)
        yield call(
          message.success,
          `Your request to remove ${
            assets[action.assets[0]].title || assets[action.assets[0]].filename
          } was submitted.`,
          4
        )
      }
      yield put({ type: appActions.CLEAR_SELECTED_ASSETS })
      for (let asset of action.assets) {
        yield fork(recordAnalyticsEvent, UNPUBLISH_ASSET, {
          asset: data.assetState.byId[asset],
        })
      }
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: 'Asset could not be unpublished.  The application is refreshed...please try again',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
      yield put({ type: appActions.CLEAR_SELECTED_ASSETS })
    }
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: error.clientMessage || 'Failed to unpublish asset',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
      console.log(error)
    }
  }
}

export const unpublishAndApprove = (assets, parent) => ({
  type: UNPUBLISH_AND_APPROVE_ASSET,
  assets,
  parent,
})

export function* watchUnpublishAndApprove() {
  yield takeEvery(UNPUBLISH_AND_APPROVE_ASSET, unpublishAndApproveSaga)
}

export function* unpublishAndApproveSaga(action) {
  const {
    assets, // list of asset objects
    parent, // folder id string,
    type,
    comments,
  } = action

  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })

  try {
    const config = yield select((state) => state.config.appConfig)
    const data = yield call(appActions.refreshStateSaga)
    const allAssets = yield select((state) => state.assets.byId)
    const endpoints = yield select((state) => state.endpoints.endpoints)
    const allFolders = yield select((state) => state.folders.byId)
    const parentFolder = allFolders[parent]
    const endpoint = getEndpointOfNode(parentFolder, allFolders, endpoints)
    const isWebEndpoint = endpoint.type === 'web'

    if (
      helpers.validateUnpublishRequest(action, data) ||
      (isWebEndpoint &&
        helpers.validateUnpublishWebEndpointRequest(action, data))
    ) {
      const timestamp = yield call(Date.now)
      const user = yield select((state) => state.user)
      action.user = user.id
      action.client = user.client
      action.timestamp = timestamp
      action.statusType = UNPUBLISHED

      // ** Public Link ** //
      if (isWebEndpoint) {
        yield call(managePublicLinksForAssets, { assets, shouldRevoke: true })
        yield put(
          uPubWebEndpointAssets({ ailTargets: assets, uPubType: 'UNPUBLISH' })
        )
        return
      }
      // ** END Public Link ** //

      // ** CREATE events for the events table ** //
      let events = []
      for (let asset of assets) {
        events.push(
          {
            target_id: asset,
            event_type: type,
            timestamp,
            user: user.id,
            attributes: [
              {
                name: 'status',
                old_value: APPROVED,
                new_value: PENDING_REMOVAL,
              },
              {
                name: 'folder',
                old_value: parent,
                new_value: parent,
              },
              {
                name: 'requester',
                old_value: null,
                new_value: user.id,
              },
            ],
          },
          {
            target_id: asset,
            event_type: type,
            timestamp: timestamp + 1,
            user: user.id,
            attributes: [
              {
                name: 'status',
                old_value: PENDING_REMOVAL,
                new_value: action.statusType,
              },
              {
                name: 'folder',
                old_value: parent,
                new_value: parent,
              },
              {
                name: 'reviewer',
                old_value: null,
                new_value: user.id,
              },
              {
                name: 'comments',
                old_value: null,
                new_value: comments,
              },
            ],
          }
        )
      }
      // ** END CREATE  ** //

      // ** ADD Actions/Events to DAM ** //
      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/state/addaction`,
        init: {
          method: 'POST',
          body: JSON.stringify({ action, events }),
        },
      })
      // ** END ADD ** //

      // ** CALL to force update the DAM state ** //
      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/state/sns/update`,
        init: {
          method: 'GET',
        },
      })
      // ** END CALL ** //

      // ** UPDATE the local Redux store ** //
      yield put({
        type: UNPUBLISH_AND_APPROVE_ASSET_SUCCESS,
        assets: assets,
        parent: parent,
        comments: comments,
        statusType: action.statusType,
        user: user.id,
        timestamp,
      })
      // ** End UPDATE ** //

      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({ type: appActions.CLEAR_SELECTED_ASSETS })
      yield put({ type: modalActions.CLOSE_MODAL })

      // ** DISPLAY UI notification messages ** //
      let msg
      if (assets.length > 1) {
        msg = `${assets.length} assets have been removed from ${allFolders[parent].name}`
        message.success(msg, 4)
      } else {
        msg = (
          <span>
            {assetName(allAssets[assets[0]])} has been removed from{' '}
            {allFolders[parent].name}
          </span>
        )
        message.success(msg, 4)
      }
      // ** END DISPLAY ** //

      // ** LOG Analytics ** //
      for (let asset of assets) {
        yield fork(recordAnalyticsEvent, UNPUBLISH_ASSET, {
          asset: data.assetState.byId[asset],
        })
        yield fork(
          recordAnalyticsEvent,
          analyticsConstants.UNPUBLISH_ASSET_APPROVE,
          {
            asset: data.assetState.byId[asset],
          }
        )
      }
      // ** END LOG Analytics ** //
    } else {
      // validateUnpublishRequest has returned FALSEY
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            'Asset could not be unpublished.  The application is refreshed...please check ' +
            'status and try again',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
      yield put({ type: appActions.CLEAR_SELECTED_ASSETS })
    }
  } catch (error) {
    console.log(error)
    // Session Timeout and user chooses to QUIT
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      // Something else has thrown inside the saga
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: error.clientMessage || 'Asset review failed',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}

export function* watchPublishAssetSuccess() {
  yield takeEvery(
    [PUBLISH_ASSET_SUCCESS, UNPUBLISH_ASSET_SUCCESS],
    notificationActions.sendPublishNotificationSaga
  )
}

export const reviewAsset = (assets, parent, comments, statusType) => ({
  type: REVIEW_ASSET,
  assets,
  parent,
  comments,
  statusType,
})

export function* watchReviewAsset() {
  yield takeEvery(REVIEW_ASSET, reviewAssetSaga)
}

export function* reviewAssetSaga(action) {
  const {
    assets, // list of asset objects
    parent, // folder id string,
    statusType,
    type,
    comments,
  } = action

  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })

  try {
    const config = yield select((state) => state.config.appConfig)
    const allAssets = yield select((state) => state.assets.byId)
    const data = yield call(appActions.refreshStateSaga)

    if (helpers.validateReviewPublishRequest(action, data)) {
      const timestamp = yield call(Date.now)
      const user = yield select((state) => state.user)
      action.user = user.id
      action.client = user.client
      action.timestamp = timestamp
      const allFolders = yield select((state) => state.folders.byId)

      // ** Get Public Link ** //
      if (statusType === APPROVED) {
        const endpoints = yield select((state) => state.endpoints.endpoints)
        const parentFolder = allFolders[parent]
        /*
         *  If the folder that the asset will be published to is a web endpoint,
         *  then we need to call the saga that creates the public url and adds it
         *  to the asset meta data.
         */
        const endpoint = getEndpointOfNode(parentFolder, allFolders, endpoints)
        const isWebEndpoint = endpoint.type === 'web'
        if (isWebEndpoint) {
          yield call(managePublicLinksForAssets, { assets })
          yield put(
            uPubWebEndpointAssets({
              fiInitialParent: parent,
              ailTargets: assets,
              uPubType: 'PUBLISH',
            })
          )
          return
        }
      }
      // ** END Get Public Link ** //

      let events = []
      for (let asset of assets) {
        events.push({
          target_id: asset,
          event_type: type,
          timestamp,
          user: user.id,
          attributes: [
            {
              name: 'status',
              old_value: PENDING_APPROVAL,
              new_value: statusType,
            },
            {
              name: 'folder',
              old_value: parent,
              new_value: parent,
            },
            {
              name: 'reviewer',
              old_value: null,
              new_value: user.id,
            },
            {
              name: 'comments',
              old_value: null,
              new_value: comments,
            },
          ],
        })
      }
      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/state/addaction`,
        init: {
          method: 'POST',
          body: JSON.stringify({ action, events }),
        },
      })

      // publish a message to update state
      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/state/sns/update`,
        init: {
          method: 'GET',
        },
      })

      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/notifications/pushPending`,
        init: {
          method: 'POST',
          body: JSON.stringify({ assets, destination: { id: parent } }),
        },
      })

      yield put({
        type: REVIEW_ASSET_SUCCESS,
        assets: assets,
        parent: parent,
        comments: comments,
        statusType: statusType,
        user: user.id,
        timestamp,
      })
      yield put({ type: appActions.CLEAR_SELECTED_ASSETS })
      yield put({ type: appActions.COMPLETE_ASYNC })

      let msg
      if (assets.length > 1) {
        if (statusType === APPROVED) {
          msg = `${assets.length} assets are now live within ${allFolders[parent].name}`
        } else {
          msg =
            `${assets.length} assets were rejected from being published within ` +
            `${allFolders[parent].name}`
        }
        yield call(message.success, msg, 4)
      } else {
        if (statusType === APPROVED) {
          msg = (
            <span>
              {assetName(allAssets[assets[0]])} is now live within{' '}
              {allFolders[parent].name}
            </span>
          )
        } else {
          msg = (
            <span>
              You rejected {assetName(allAssets[assets[0]])} from{' '}
              {allFolders[parent].name}
            </span>
          )
        }
        yield call(message.success, msg, 4)
      }
      for (let asset of assets) {
        yield fork(
          recordAnalyticsEvent,
          statusType === APPROVED
            ? analyticsConstants.PUBLISH_ASSET_APPROVE
            : analyticsConstants.PUBLISH_ASSET_REJECT,
          { asset: data.assetState.byId[asset] }
        )
      }
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            'Asset is not PENDING_APPROVAL.  The application is refreshed...please check ' +
            'status and try again',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
      yield put({ type: appActions.CLEAR_SELECTED_ASSETS })
    }
  } catch (error) {
    console.log(error)
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
    }
  }
}

export const reviewUnpublishAsset = (assets, parent, comments, statusType) => ({
  type: REVIEW_UNPUBLISH_ASSET,
  assets,
  parent,
  comments,
  statusType,
})

export function* watchReviewUnpublishAsset() {
  yield takeEvery(REVIEW_UNPUBLISH_ASSET, reviewUnpublishAssetSaga)
}

export function* reviewUnpublishAssetSaga(action) {
  const {
    assets, // list of asset objects
    parent, // folder id string,
    statusType,
    comments,
    type,
  } = action

  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })

  try {
    const config = yield select((state) => state.config.appConfig)
    const allAssets = yield select((state) => state.assets.byId)
    const data = yield call(appActions.refreshStateSaga)
    const endpoints = yield select((state) => state.endpoints.endpoints)
    const allFolders = yield select((state) => state.folders.byId)
    const parentFolder = allFolders[parent]
    const endpoint = getEndpointOfNode(parentFolder, allFolders, endpoints)
    const isWebEndpoint = endpoint.type === 'web'

    if (
      helpers.validateReviewUnpublishRequest(action, data) ||
      (isWebEndpoint &&
        helpers.validateUnpublishWebEndpointRequest(action, data))
    ) {
      const timestamp = yield call(Date.now)
      const user = yield select((state) => state.user)
      action.user = user.id
      action.client = user.client
      action.timestamp = timestamp

      // ** Revoke Public Link ** //
      if (statusType === UNPUBLISHED && isWebEndpoint) {
        yield call(managePublicLinksForAssets, { assets, shouldRevoke: true })
        yield put(
          uPubWebEndpointAssets({ ailTargets: assets, uPubType: 'UNPUBLISH' })
        )
        return
      }
      // ** END Revoke Public Link ** //

      // ** CREATE events for the events table ** //
      let events = []
      for (let asset of assets) {
        events.push({
          target_id: asset,
          event_type: type,
          timestamp,
          user: user.id,
          attributes: [
            {
              name: 'status',
              old_value: APPROVED,
              new_value: statusType,
            },
            {
              name: 'folder',
              old_value: parent,
              new_value: parent,
            },
            {
              name: 'reviewer',
              old_value: null,
              new_value: user.id,
            },
            {
              name: 'comments',
              old_value: null,
              new_value: comments,
            },
          ],
        })
      }
      // ** END CREATE  ** //

      // ** ADD Actions/Events to DAM ** //
      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/state/addaction`,
        init: {
          method: 'POST',
          body: JSON.stringify({ action, events }),
        },
      })
      // ** END ADD ** //

      // ** CALL to force update the DAM state ** //
      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/state/sns/update`,
        init: {
          method: 'GET',
        },
      })
      // ** END CALL ** //

      // ** UPDATE the local Redux store ** //
      yield put({
        type: REVIEW_UNPUBLISH_ASSET_SUCCESS,
        assets: assets,
        parent: parent,
        comments: comments,
        statusType: statusType,
        user: user.id,
        timestamp,
      })
      // ** End UPDATE ** //

      yield put({ type: appActions.COMPLETE_ASYNC })

      // ** DISPLAY UI notification messages ** //
      let msg
      if (assets.length > 1) {
        if (statusType === UNPUBLISHED) {
          msg = `${assets.length} assets have been removed from ${allFolders[parent].name}`
        } else {
          msg = `${assets.length} are still published within ${allFolders[parent].name}`
        }
        message.success(msg, 4)
      } else {
        if (statusType === UNPUBLISHED) {
          msg = (
            <span>
              {assetName(allAssets[assets[0]])} has been removed from{' '}
              {allFolders[parent].name}
            </span>
          )
        } else {
          msg = (
            <span>
              {assetName(allAssets[assets[0]])} is still published within{' '}
              {allFolders[parent].name}
            </span>
          )
        }
        message.success(msg, 4)
      }
      // ** END DISPLAY ** //

      yield put({ type: appActions.CLEAR_SELECTED_ASSETS })

      // ** LOG Analytics ** //
      for (let asset of assets) {
        yield fork(
          recordAnalyticsEvent,
          statusType === UNPUBLISHED
            ? analyticsConstants.UNPUBLISH_ASSET_APPROVE
            : analyticsConstants.UNPUBLISH_ASSET_REJECT,
          { asset: data.assetState.byId[asset] }
        )
      }
      // ** END LOG Analytics ** //
    } else {
      // validateReviewUnpublishRequest has returned FALSEY
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            'Asset is not PENDING_REMOVAL.  The application is refreshed...please check ' +
            'status and try again',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
      yield put({ type: appActions.CLEAR_SELECTED_ASSETS })
    }
  } catch (error) {
    console.log(error)
    // Session Timeout and user chooses to QUIT
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      // Something else has thrown inside the saga
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: error.clientMessage || 'Asset review failed',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}

/*
 * Generates public URLS for given list of assets and updates
 * the assets' meta data with the publicURLs.
 * or
 * Revokes public URLS for given list of assets and updates
 * the assets' meta data with null publicURLs.
 *
 * @param assets {[string]}  -   A list of asset ids
 * @param shouldRevoke {boolean}   -   Produce or Revoke the publicURLs.
 */
export function* managePublicLinksForAssets({ assets, shouldRevoke }) {
  if (!Array.isArray(assets) || !assets.length) return

  const user = yield select((state) => state.user)
  const allAssets = yield select((state) => state.assets.byId)

  for (let asset of assets) {
    yield call(assetActions.publicLinkSaga, {
      asset: allAssets[asset],
      user: user.id,
      revoke: !!shouldRevoke,
    })
  }
}

// UNPUBLISH WEB ENDPOINT ASSET
/*
 * @param    ailTargets  {[string]}    - List of asset ids that are to be published/unpublished
 * @param    uPubType    {string}      - Specify on of "PUBLISH" or "UNPUBLISH"
 */
export function uPubWebEndpointAssets({
  fiInitialParent,
  ailTargets,
  uPubType,
}) {
  return {
    type: UPUB_WEB_ENDPOINT_ASSET,
    fiInitialParent,
    ailTargets,
    uPubType,
  }
}

export function* watchUPubWebEndpointAssets() {
  yield takeEvery(UPUB_WEB_ENDPOINT_ASSET, uPubWebEndpointAssetsSaga)
}

export function* uPubWebEndpointAssetsSaga(action) {
  const { fiInitialParent, ailTargets, uPubType } = action
  const validPubTypes = ['PUBLISH', 'UNPUBLISH']

  // SHORT CIRCUIT ON BAD INPUT
  if (
    !Array.isArray(ailTargets) ||
    !ailTargets.length ||
    !validPubTypes.includes(uPubType)
  )
    return

  const messageType =
    uPubType === 'PUBLISH'
      ? 'PUBLISH_WEBENDPOINT_ASSET'
      : 'UNPUBLISH_WEBENDPOINT_ASSET' // Declared out here to make available in both try and catch

  try {
    // SHOW SPINNER
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
    // CONFIG FOR API CALLS
    const config = yield select((state) => state.config.appConfig)
    // STATE DATA TO CREATE REDUCER PARAMETERS
    const timestamp = yield call(Date.now)
    const user = yield select((state) => state.user)
    const adictAll = yield select((state) => state.assets.byId)
    const fdictAll = yield select((state) => state.folders.byId)
    const eolAll = yield select((state) => state.endpoints.endpoints)
    const fasdictAll = yield select(
      (state) => state.endpoints.folderAssetStatus
    )
    // TARGET THE WEB ENDPOINTS ONLY
    const eilTargets = eolAll
      .filter((eol) => eol.type === 'web')
      .map((eol) => eol.id)
    // EXTRACT AND MASSAGE TARGET FOLDER/ASSET DATA
    const validAssetStatus =
      uPubType === 'PUBLISH'
        ? ['PENDING_APPROVAL']
        : ['PENDING_APPROVAL', 'PENDING_REMOVAL', 'APPROVED']
    const folderAssetsCollection = createFolderToAssetsCollection({
      validAssetStatus,
      fasdictAll,
      ailTargets,
      eilTargets,
      adictAll,
      fdictAll,
      eolAll,
    })

    // ** CREATE the redux action parameters ** //
    const actionType =
      uPubType === 'PUBLISH'
        ? PUBLISH_AND_APPROVE_ASSET
        : UNPUBLISH_AND_APPROVE_ASSET
    const statusType = uPubType === 'PUBLISH' ? APPROVED : UNPUBLISHED
    // ** END CREATE ** //

    // ** CREATE local and cloud actions ** //
    let cloudActions = []
    let localActions = []

    // If this is a publish, then the folderAssetsCollection will not contain an
    // entry for the {parent, assets} that this action was generated for. Add it here.
    if (uPubType === 'PUBLISH') {
      const reduxAction = {
        type: actionType,
        statusType,
        assets: ailTargets,
        parent: fiInitialParent,
        comments: undefined,
        client: user.client,
        user: user.id,
        timestamp,
      }

      // LOCAL QUEUE
      localActions.push(put({ ...reduxAction, type: `${actionType}_SUCCESS` }))

      // CLOUD QUEUE
      cloudActions.push(
        call(apiActions.secureFetchSaga, {
          url: `${config.baseUrl}/api/state/addaction`,
          init: {
            method: 'POST',
            body: JSON.stringify({
              action: reduxAction,
              events: [],
            }),
          },
        })
      )
    }

    for (let folderAssets of folderAssetsCollection) {
      // Set the redux action options
      const reduxAction = {
        type: actionType,
        statusType,
        assets: folderAssets.assets,
        parent: folderAssets.parent,
        comments: undefined,
        client: user.client,
        user: user.id,
        timestamp,
      }

      // LOCAL QUEUE
      localActions.push(put({ ...reduxAction, type: `${actionType}_SUCCESS` }))

      // CLOUD QUEUE
      cloudActions.push(
        call(apiActions.secureFetchSaga, {
          url: `${config.baseUrl}/api/state/addaction`,
          init: {
            method: 'POST',
            body: JSON.stringify({
              action: reduxAction,
              events: [],
            }),
          },
        })
      )
    }
    // ** END CREATE ** //

    // ** FLUSH actions ** //
    // CLOUD
    yield all(cloudActions)
    // LOCAL
    yield all(localActions)
    // ** END FLUSH ** //

    // ** CALL to force update the DAM state ** //
    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/state/sns/update`,
      init: {
        method: 'GET',
      },
    })
    // ** END CALL ** //

    // REMOVE SPINNER
    yield put({ type: appActions.COMPLETE_ASYNC })
    // CLEAR ASSET CHECK BOXES
    yield put({ type: appActions.CLEAR_SELECTED_ASSETS })

    // ** DISPLAY UI notification messages ** //
    yield call(displayToast, {
      type: messageType,
      success: true,
      params: {
        assets: ailTargets,
        allAssets: adictAll,
        time: 4,
      },
    })
    // ** END DISPLAY ** //

    // ** LOG Analytics ** //
    let analyticsActions = []
    for (let aiUnpublish of ailTargets) {
      analyticsActions.push(
        fork(recordAnalyticsEvent, analyticsConstants.UNPUBLISH_ASSET_APPROVE, {
          asset: adictAll[aiUnpublish],
        })
      )
    }
    yield all(analyticsActions)
    // ** END LOG Analytics ** //
  } catch (error) {
    console.log(error)
    yield put({ type: appActions.COMPLETE_ASYNC })
    // ** DISPLAY UI notification messages ** //
    yield call(displayToast, {
      type: messageType,
      success: false,
      params: {
        assets: ailTargets,
        time: 4,
      },
    })
    // ** END DISPLAY ** //
  }
}
// END UNPUBLISH WEB ENDPOINT ASSET

export function* watchReviewAssetSuccess() {
  yield takeEvery(
    [REVIEW_ASSET_SUCCESS, REVIEW_UNPUBLISH_ASSET_SUCCESS],
    notificationActions.sendReviewNotificationSaga
  )
}

export const loadEndpointState = (endpoints, home) => ({
  type: LOAD_ENDPOINT_STATE,
  payload: endpoints,
  home,
})

export default all([
  watchAddEndpoint(),
  watchDeleteEndpoint(),
  watchPublishAsset(),
  watchPublishAssetSuccess(),
  watchPublishAndApproveAsset(),
  watchRenameEndpoint(),
  watchReviewAsset(),
  watchReviewAssetSuccess(),
  watchUnpublishAsset(),
  watchUnpublishAndApprove(),
  watchReviewUnpublishAsset(),
  watchUnpublish(),
  watchUPubWebEndpointAssets(),
  watchBatchPublish(),
])
