import { Form, Input } from 'antd'
import moment from 'moment'
import React from 'react'
import EditableTagGroup from '../common/EditableTagGroup'

const FormItem = Form.Item

class BulkEditForm extends React.PureComponent {
  render() {
    const { form } = this.props
    const { getFieldDecorator } = form
    const generateHelpText = (name) => {
      const error = form.getFieldError(name)
      if (error) return error
      let val = form.getFieldValue(name)
      if (val) {
        val = val.replace(
          '%F',
          form.getFieldValue('filename') || this.props.assets[0].filename
        )
        val = val.replace('%I', 1)
        val = val.replace('%D', `${moment().format('YYYYMMDD')}`)
        return `Ex: ${val}`
      }
      return null
    }
    return (
      <Form layout="vertical">
        <div className="modal__bulkedit-instructions">
          You can use the following template variables to create unique
          identifiers:
          <ul>
            <li> %F - filename of the file</li>
            <li> %I - unique number for each file in this batch</li>
            <li> %D - current date in YYYYMMDD format</li>
          </ul>
          <p>
            Try typing variables into the title or description fields to see
            example output.
          </p>
          <p>Note: Template variables will not be applied to Tags</p>
        </div>
        {this.props.single && (
          <FormItem label="Filename" help={generateHelpText('filename')}>
            {getFieldDecorator('filename', {
              initialValue: this.props.single
                ? this.props.assets[0].filename
                : '',
              validateTrigger: 'onChange',
              rules: [
                {
                  required: true,
                  message: 'Filename is required',
                },
              ],
            })(<Input />)}
          </FormItem>
        )}
        <FormItem label="Title" help={generateHelpText('title')}>
          {getFieldDecorator('title', {
            initialValue: this.props.single ? this.props.assets[0].title : '',
          })(<Input />)}
        </FormItem>
        <FormItem label="Description" help={generateHelpText('description')}>
          {getFieldDecorator('description', {
            initialValue: this.props.single
              ? this.props.assets[0].description
              : '',
          })(<Input.TextArea rows={3} />)}
        </FormItem>
        <FormItem label="Tags">
          <EditableTagGroup
            ref={this.props.saveTagsRef}
            defaultTags={this.props.single ? this.props.assets[0].tags : []}
          />
        </FormItem>
      </Form>
    )
  }
}

BulkEditForm.defaultProps = {
  single: false,
}

export default Form.create()(BulkEditForm)
