import { notification } from 'antd'
import dayjs from 'dayjs'
import Timezone from 'dayjs/plugin/timezone'
import UTC from 'dayjs/plugin/utc'
import moment from 'moment'
import React from 'react'
import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import * as apiActions from './api'
import * as appActions from './appActions'
import { reactNodeToText, recordAnalyticsEvent } from '../modules/utils'
import {
  getActiveAnalyticsDuration,
  getActiveAnalyticsView,
  getDataForView,
  getLastAnalyticsDuration,
  getReportReceivedDate,
} from '../reducers/analytics'
import mockAnalyticsData from '../../testdata/analytics'
import { CALCULATE, LAUNCH_CALCULATOR } from '../constants/analytics'

dayjs.extend(UTC)
dayjs.extend(Timezone)

export const REFRESH_ANALYTICS_DATA = 'REFRESH_ANALYTICS_DATA'
export const SET_ANALYTICS_DATA = 'SET_ANALYTICS_DATA'
export const SET_ANALYTICS_LOADING_STATUS = 'SET_ANALYTICS_LOADING_STATUS'
export const GET_ANALYTICS_SHARE_DATA = 'GET_ANALYTICS_SHARE_DATA'
export const SET_ANALYTICS_SHARE_DATA = 'SET_ANALYTICS_SHARE_DATA'
export const EXPORT_EVENTS_TABLE = 'EXPORT_EVENTS_TABLE'
export const EXPORT_CALCULATIONS = 'EXPORT_CALCULATIONS'

/* Format the data received from the analytics api call.
 *  @param   analyticsView   {string}    The name of the analytics view. i.e. "AnalyticsOverview"
 *  @param   analyticsData   {string}    The unformatted data object
 */
function formatAnalyticsDataForView(analyticsView, analyticsData) {
  /* Currently, this function just pads the data set with zero values if a date
   *  does not exist in the data set.
   *  We bypass it here for AssetDetail and ShareDetail.
   */
  if (analyticsView === 'AssetsDetail' || analyticsView === 'ShareDetail')
    return { data: analyticsData }

  // Pad Empty dates with Empty data objects
  const dateValues = Object.keys(analyticsData)

  for (let idx = 0; idx < dateValues.length; idx++) {
    if (idx === dateValues.length - 1) continue

    const startDate = moment(dateValues[idx])
    const endDate = moment(dateValues[idx + 1])
    const daysBetweenDates = endDate.diff(startDate, 'days')

    for (let dayIdx = 1; dayIdx < daysBetweenDates; dayIdx++) {
      startDate.add(1, 'days')
      analyticsData[startDate.format('YYYY-MM-DD')] = {}
    }
  }

  // Sort by date
  const sortedAnalyticsData = {}
  Object.keys(analyticsData)
    .sort()
    .forEach(function (key) {
      sortedAnalyticsData[key] = analyticsData[key]
    })

  // Should be in format expected by UI code.
  return { data: sortedAnalyticsData }
}

/* Set Analytics Data in redux for the given analytics view.
 *  @param   analyticsView   {string}    The name of the analytics view. i.e. "AnalyticsOverview"
 *  @param   analyticsData   {string}    The formatted data object
 */
export function setAnalyticsDataForView(analyticsReport) {
  return {
    type: SET_ANALYTICS_DATA,
    analyticsReport,
  }
}

/* Refresh Analytics Data for the given analytics view.
 *  @param   analyticsView   {string}    The name of the analytics view. i.e. "AnalyticsOverview"
 */
export function refreshAnalyticsDataForView(analyticsView) {
  return {
    type: REFRESH_ANALYTICS_DATA,
    analyticsView,
  }
}

/**
 *
 * @param {number|undefined} dateReceived - timestamp when the report was received in milliseconds
 * @returns {boolean} - true if the report is less than an hour old, false otherwise
 */
function dataIsCurrent(dateReceived) {
  if (!dateReceived) return false
  const now = new Date()
  const lastFetched = new Date(dateReceived)
  const diff = now.getTime() - lastFetched.getTime()
  const diffHours = diff / (1000 * 60 * 60)
  return diffHours < 1
}

export function* refreshAnalyticsDataForViewSaga() {
  try {
    // first, check to see if the data has been grabbed in the last hour

    const reportReceivedDate = yield select(getReportReceivedDate)

    if (dataIsCurrent(reportReceivedDate)) {
      return
    }

    // yield put({ type: SET_ANALYTICS_LOADING_STATUS, loading: true })
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })

    const config = yield select((state) => state.config.appConfig)
    const { results } = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/analytics/report`,
      init: {
        method: 'GET',
      },
    })

    const formattedResults = Object.keys(results).reduce(
      (acc, curr) => {
        acc[curr + 'Data'] = {
          ...formatAnalyticsDataForView(curr, results[curr]),
        }
        return acc
      },
      { reportReceived: Date.now() }
    )

    yield put(setAnalyticsDataForView(formattedResults))
  } catch (err) {
    console.error(err)
    notification.open({
      message: 'Analytics error',
      description:
        'There was an unexpected error retrieving analytics data. Please contact your ' +
        'administrator for assistance.',
    })
  } finally {
    // yield put({ type: SET_ANALYTICS_LOADING_STATUS, loading: false })
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

export function setAnalyticsShareData(shareDataList) {
  return {
    type: SET_ANALYTICS_SHARE_DATA,
    shareDataList,
  }
}

export function getAnalyticsShareData(shareIds) {
  return {
    type: GET_ANALYTICS_SHARE_DATA,
    shareIds,
  }
}

export function* getAnalyticsShareDataSaga(action) {
  const { shareIds } = action

  if (!Array.isArray(shareIds) || !shareIds.length) return

  try {
    // Async Action Spinner
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })

    // Config for the API call
    const config = yield select((state) => state.config.appConfig)

    // Fetch
    const fetchURL = `${config.baseUrl}/api/assets/sharedByIds`
    const result = yield call(apiActions.secureFetchSaga, {
      url: fetchURL,
      init: {
        method: 'POST',
        body: JSON.stringify({ shareIds }),
      },
    })

    // Set
    yield put(setAnalyticsShareData(result))
  } catch (err) {
    // Error
    console.log(err)
  } finally {
    // Hide Spinner
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

/**
 * @param {Object} tableData the source table data to export
 * @param {moment.Moment[]} dateRange pair of moments that represent the selected date range.
 * This can be a single value if a date has been selected.
 * @param {"shares"|"users"|"views"} view the view being exported
 */
export function exportEventsTable(data) {
  return {
    type: EXPORT_EVENTS_TABLE,
    data,
  }
}

function exportCSV(headers, subHeaders, dataToExport, filename) {
  console.log({ headers, subHeaders, dataToExport })
  // write data - inspired by https://stackoverflow.com/a/24922761
  const headersStr = headers ? `${headers.join(',')}\n` : ''
  const subHeadersStr = subHeaders ? `${subHeaders.join(',')}\n` : ''
  const encodedData = `${headersStr}${subHeadersStr}${dataToExport
    .map((item) =>
      Object.values(item)
        .map((val) => {
          if (typeof val === 'string') {
            val = val.replace(/"/g, '""')

            if (/([",\n])/g.test(val)) {
              val = `"${val}"`
            }
          }

          return val
        })
        .join(',')
    )
    .join('\n')}`

  const blob = new Blob(['\ufeff' + encodedData], {
    type: 'text/csv;charset=utf-8;',
  })

  // write to file
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)

    if (link.download !== 'undefined') {
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      window.open(url)
    }
  }
}

export function* exportEventsTableSaga(action) {
  const columnsToExport = {
    userDays: ['email', 'name', 'date'],
    assetViews: ['title', 'filename', 'file_type', 'email', 'name', 'date'],
    assetShares: [
      'title',
      'filename',
      'file_type',
      'email',
      'name',
      'date',
      'expires',
      'firstView',
      'totalViews',
    ],
    calcViews: ['title', 'email', 'name', 'date'],
    calculations: ['title', 'email', 'name', 'date'],
  }
  const headersToExport = {
    userDays: ['User Email', 'User Name', 'Date'],
    assetViews: [
      'Asset Title',
      'Filename',
      'File Type',
      'User Email',
      'User Name',
      'Date',
    ],
    assetShares: [
      'Asset Title',
      'Filename',
      'File Type',
      'User Email',
      'User Name',
      'Date',
      'Expires',
      'First View',
      'Total Views',
    ],
    calcViews: ['Calculator Title', 'User Email', 'User Name', 'Date'],
    calculations: ['Calculator Title', 'User Email', 'User Name', 'Date'],
  }
  const exportTitles = {
    userDays: 'User_Days',
    assetViews: 'Asset_Views',
    assetShares: 'Asset_Shares',
    calcViews: 'Calculator_Views',
    calculations: 'Calculations',
  }
  const { data } = action
  const { view, dateRange, selectedAsset, selectedUser, events, bamPlatform } =
    data
  const columns = columnsToExport[view]
  const headers = headersToExport[view]
  const exportTitle = exportTitles[view]

  if (!columns) {
    console.warn(`Unsupported view ${view}`)
    return
  }

  const addShareData = (item, shareData) => {
    if (shareData) {
      const itemShare = shareData.find(
        (shareDataItem) => shareDataItem.ID === item.shareId
      )
      if (itemShare) {
        item.expires = !itemShare.expires
          ? '-'
          : moment(itemShare.expires * 1000).format('YYYY-MM-DD')
        item.firstView = !itemShare.firstView
          ? '-'
          : moment(itemShare.firstView * 1000).format('YYYY-MM-DD')
        item.totalViews = !itemShare.totalViews ? '-' : itemShare.totalViews
      }
    }
    return item
  }

  try {
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })

    let shareData
    if (view === 'assetShares') {
      const shareIds = events.map((ev) => ev.shareId).filter((item) => !!item)
      const shareIdsBody = JSON.stringify({ shareIds })

      // Config for the API call
      const config = yield select((state) => state.config.appConfig)

      // Fetch
      const fetchURL = `${config.baseUrl}/api/assets/sharedByIds`
      const result = yield call(apiActions.secureFetchSaga, {
        url: fetchURL,
        init: {
          method: 'POST',
          body: shareIdsBody,
        },
      })
      shareData = result
    }

    let dataToExport = events
      .map((item) => addShareData(item, shareData))
      .map((item) =>
        columns.reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: React.isValidElement(item[curr])
              ? reactNodeToText(item[curr])
              : item[curr],
          }),
          {}
        )
      )

    // filename
    const platform =
      bamPlatform === 'all' ? '' : `-${bamPlatform.toUpperCase()}`
    const assetTxt = !selectedAsset
      ? ''
      : `-${selectedAsset.replaceAll(/\.(.*)/gi, '').replaceAll(/ /gi, '_')}`
    const userTxt = !selectedUser
      ? ''
      : `-${selectedUser.name.replaceAll(/\s+/gi, '_')}`
    const dateFormat = 'YYYYMMDD'
    const dateTxt = Array.isArray(dateRange)
      ? `${dateRange[0].format(dateFormat)}-${dateRange[1].format(dateFormat)}`
      : moment(dateRange).format(dateFormat)
    const filename = `BAM${platform}-${exportTitle}${assetTxt}${userTxt}-${dateTxt}.csv`

    exportCSV(headers, null, dataToExport, filename)
  } catch (err) {
    console.error(err)
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

export function exportCalculations(data) {
  return {
    type: EXPORT_CALCULATIONS,
    data,
  }
}

export function* exportCalculationsSaga(action) {
  const { data } = action
  const { dateRange, selectedUser, events, bamPlatform, calculatorTitle } = data

  console.log({ dateRange, selectedUser, events, bamPlatform, calculatorTitle })

  const inputs = []
  const inputKeys = []
  const outputs = []
  const outputKeys = []

  for (let event of events.toReversed()) {
    const eventKeys = Object.keys(event).sort()
    for (let key of eventKeys) {
      if (key.startsWith('in|')) {
        const tokens = key.split('|')
        const inputName = tokens[2]
        if (!inputs.includes(inputName)) {
          inputs.push(inputName)
          inputKeys.push(key)
        }
      } else if (key.startsWith('out|')) {
        const tokens = key.split('|')
        const outputName = tokens[2]
        if (!outputs.includes(outputName)) {
          outputs.push(outputName)
          outputKeys.push(key)
        }
      }
    }
  }

  const headers = ['Date', 'User Name', 'User Email']
  const subHeaders = ['', '', '']
  if (inputs.length) {
    inputs.forEach((inputName) => {
      headers.push('')
      subHeaders.push(inputName)
    })
    headers[3] = 'Inputs'
  }
  if (outputs.length) {
    outputs.forEach((outputName) => {
      headers.push('')
      subHeaders.push(outputName)
    })
    headers[3 + inputs.length] = 'Outputs'
  }

  try {
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })

    // gather inputs and output columns
    // get pretty names for inputs and outputs
    let dataToExport = events.map((event) => {
      return {
        date: event.date,
        user: event.user,
        email: event.email,
        ...inputs.reduce((prev, curr) => {
          const keyForCurrentEvent = Object.keys(event).find((key) =>
            key.endsWith(`|${curr}`)
          )
          prev[curr] = event[keyForCurrentEvent] || '-'
          return prev
        }, {}),
        ...outputs.reduce((prev, curr) => {
          const keyForCurrentEvent = Object.keys(event).find((key) =>
            key.endsWith(`|${curr}`)
          )
          prev[curr] = event[keyForCurrentEvent] || '-'
          return prev
        }, {}),
      }
    })

    // filename
    const platform =
      bamPlatform === 'all' ? '' : `-${bamPlatform.toUpperCase()}`
    const calcTxt = !calculatorTitle
      ? ''
      : `-${calculatorTitle.replaceAll(/\.(.*)/gi, '').replaceAll(/ /gi, '_')}`
    const userTxt = !selectedUser
      ? ''
      : `-${selectedUser.name.replaceAll(/\s+/gi, '_')}`
    const dateFormat = 'YYYYMMDD'
    const dateTxt = Array.isArray(dateRange)
      ? `${dateRange[0].format(dateFormat)}-${dateRange[1].format(dateFormat)}`
      : moment(dateRange).format(dateFormat)
    const filename = `BAM${platform}-${'Calculations'}${calcTxt}${userTxt}-${dateTxt}.csv`

    exportCSV(headers, subHeaders, dataToExport, filename)
  } catch (err) {
    console.error(err)
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

function* watchRefreshAnalytics() {
  yield all([
    takeLatest(REFRESH_ANALYTICS_DATA, refreshAnalyticsDataForViewSaga),
    takeLatest(
      appActions.SET_ACTIVE_ANALYTICS_DURATION,
      refreshAnalyticsDataForViewSaga
    ),
  ])
}

function* calculatorTask(action) {
  try {
    yield fork(recordAnalyticsEvent, action.type, action.data)
  } catch (error) {
    console.log(error)
  }
}

// Main Export
export default function* analyticsSagas() {
  yield all([
    fork(watchRefreshAnalytics),
    takeEvery(GET_ANALYTICS_SHARE_DATA, getAnalyticsShareDataSaga),
    takeLatest(EXPORT_EVENTS_TABLE, exportEventsTableSaga),
    takeLatest(EXPORT_CALCULATIONS, exportCalculationsSaga),
    takeEvery(CALCULATE, calculatorTask),
    takeEvery(LAUNCH_CALCULATOR, calculatorTask),
  ])
}
