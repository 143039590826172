import update from 'immutability-helper'
import * as appActions from '../actions/appActions'
import * as assetActions from '../actions/asset'
import {
  APPROVED,
  PENDING_APPROVAL,
  PENDING_REMOVAL,
} from '../actions/endpoint'
import { CLOSE_MODAL } from '../actions/modal'
import * as folderActions from '../actions/folder'
import * as userActions from '../actions/user'
import { DEFAULT_SIDEBAR_WIDTH } from '../constants/app'

const ASCENDING = 1
const INITIAL_STATE = {
  windowWidth: 1200,
  cardWidth: 300,
  cardSizeDetected: false,
  activeView: null,
  activeAsset: null,
  maxAssetsToDisplay: 24,
  scrollPosition: 0,
  tokenExpiration: Date.now(),
  initializing: true,
  menuItem: 'library',
  maxSidebarWidth: 305,
  sidebarWidth: DEFAULT_SIDEBAR_WIDTH,
  sidebarCollapsed: false,
  selectedAssets: [],
  selectedFolders: [],
  renamingFolder: '',
  openFolderKeys: [],
  openEndpointKeys: ['LIB'],
  activeEndpoint: 'LIB',
  sharePlatform: '',
  registrationError: false,
  registrationErrorMsg: '',
  forgotPasswordMessage: '',
  showForgotPasswordMessage: false,
  resetPasswordComplete: false,
  resetPasswordError: '',
  loggingOut: false,
  passwordChanging: false,
  passwordChangeMessage: '',
  passwordChangeMessageClass: '',
  showProfileUploader: false,
  asyncPending: false,
  showSpinner: false,
  pendingActionQueue: [],
  startupRoute: '/',
  sortFields: {
    title: {
      value: 'title',
      displayValue: 'Title',
      type: 'text',
      defaultValue: '',
    },
    size: {
      value: 'size',
      displayValue: 'Size',
      type: 'number',
      defaultValue: 0,
    },
    updated: {
      value: 'updated',
      displayValue: 'Date Updated',
      type: 'number',
      defaultValue: 0,
    },
    description: {
      value: 'description',
      displayValue: 'Description',
      type: 'text',
      defaultValue: '',
    },
  },
  sortKey: {
    value: 'title',
    displayValue: 'Title',
    type: 'text',
    defaultValue: '',
  },
  filterOptions: {
    ALL: {
      value: 'ALL',
      displayValue: 'All',
    },
    PENDING_APPROVAL: {
      value: PENDING_APPROVAL,
      displayValue: 'Pending Approval',
    },
    PENDING_REMOVAL: {
      value: PENDING_REMOVAL,
      displayValue: 'Pending Removal',
    },
    APPROVED: {
      value: APPROVED,
      displayValue: 'Approved',
    },
  },
  selectedFilter: 'ALL',
  sortDirection: ASCENDING,
  uploadContinue: false,
  backButtonFlag: false,
  maintenance: {
    value: 'off',
  },
  editModeAsset: '',
  sharedAssetLinks: null,
  versionMismatch: false,
  timer: 0,
  folderDragData: {
    over: null,
    above: null,
    below: null,
  },
  clipboardContent: null,
}

export default function appStateReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case appActions.INITIATE_ASYNC: {
      return {
        ...state,
        asyncPending: true,
        showSpinner: action.showSpinner,
      }
    }

    case appActions.COMPLETE_ASYNC: {
      return {
        ...state,
        asyncPending: false,
        showSpinner: false,
      }
    }

    case appActions.SET_WINDOW_SIZE: {
      return {
        ...state,
        windowWidth: action.size.width,
        windowHeight: action.size.height,
      }
    }

    case appActions.SET_CARDWIDTH: {
      return {
        ...state,
        cardWidth: action.cardWidth,
        cardSizeDetected: true,
      }
    }

    case appActions.TOGGLE_SIDEBAR: {
      return {
        ...state,
        sidebarWidth: action.width,
      }
    }

    case userActions.LOGIN_USER_SUCCESS: {
      return {
        ...state,
        registrationError: false,
        registrationErrorMsg: '',
      }
    }
    /*
     *   REGISTER_USER_SUCCESS - set appstate to reflect registration call completed
     */
    case userActions.REGISTER_USER_SUCCESS: {
      return Object.assign({}, state, {
        asyncPending: false,
        registrationError: false,
        registrationErrorMsg: '',
      })
    }

    /*
     *   REGISTER_USER_FAILURE - set appstate to reflect registration call completed
     */
    case userActions.REGISTER_USER_FAILURE: {
      return Object.assign({}, state, {
        asyncPending: false,
        registrationError: true,
        registrationErrorMsg: action.error.message,
      })
    }

    /*
     * FORGOT_PASSWORD_REQUEST - set flag to tell client forgot password flow is in progress
     */
    case userActions.FORGOT_PASSWORD_REQUEST: {
      return Object.assign({}, state, {
        asyncPending: true,
        forgotPasswordMessage: '',
        showForgotPasswordMessage: false,
      })
    }

    /*
     * FORGOT_PASSWORD_SUCCESS - set flag to tell client forgot password flow is completed
     */
    case userActions.FORGOT_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        asyncPending: false,
        forgotPasswordMessage:
          'An email was sent to your account.  Click the link to reset your password',
        showForgotPasswordMessage: true,
      })
    }

    /*
     * FORGOT_PASSWORD_FAILURE - set flag to tell client forgot password flow is completed
     */
    case userActions.FORGOT_PASSWORD_FAILURE: {
      return Object.assign({}, state, {
        asyncPending: false,
        forgotPasswordMessage: '',
        showForgotPasswordMessage: false,
      })
    }

    case userActions.CLEAR_FORGOT_PASSWORD_MESSAGE: {
      return Object.assign({}, state, {
        forgotPasswordMessage: '',
        showForgotPasswordMessage: false,
      })
    }

    /*
     * RESET_PASSWORD_SUCCESS - reset password process
     */
    case userActions.RESET_PASSWORD_REQUEST: {
      return Object.assign({}, state, {
        resetPasswordError: '',
        resetPasswordComplete: true,
        asyncPending: true,
      })
    }

    /*
     * RESET_PASSWORD_SUCCESS - reset password process
     */
    case userActions.RESET_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        resetPasswordComplete: true,
        asyncPending: false,
      })
    }

    /*
     * RESET_PASSWORD_FAILURE - reset password process
     */
    case userActions.RESET_PASSWORD_FAILURE: {
      return Object.assign({}, state, {
        resetPasswordComplete: false,
        resetPasswordError: action.error.message,
        asyncPending: false,
      })
    }

    /*
     * CHANGE_PASSWORD_REQUEST - update client state to reflect PW change in progress
     */
    case userActions.CHANGE_PASSWORD_REQUEST: {
      return Object.assign({}, state, {
        passwordChanging: true,
        passwordChangeMessage: '',
        passwordChangeMessageClass: '',
      })
    }

    /*
     * CHANGE_PASSWORD_SUCCESS - update client state to reflect PW change complete
     */
    case userActions.CHANGE_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        passwordChanging: false,
        passwordChangeMessage: action.msg,
        passwordChangeMessageClass: 'success',
      })
    }

    /*
     * CHANGE_PASSWORD_FAILURE - update client state to reflect PW change failed
     */
    case userActions.CHANGE_PASSWORD_FAILURE: {
      return Object.assign({}, state, {
        passwordChanging: false,
        passwordChangeMessage: action.msg,
        passwordChangeMessageClass: 'error',
      })
    }

    /*
     * SHOW_PROFILE_UPLOADER - display the upload component on the edit asset modal
     */
    case appActions.SHOW_PROFILE_UPLOADER: {
      return {
        ...state,
        showProfileUploader: action.show,
      }
    }
    case assetActions.CREATE_USERASSET_SUCCESS: {
      return {
        ...state,
        showProfileUploader: false,
      }
    }

    case userActions.UPDATE_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        showProfileUploader: false,
      }
    }

    /*
     *  SELECT_ASSET: Add an asset ID to the array of select assets.
     *    If the asset already exists in the array, remove the ID
     *    Uses update from immutability-helper to keep state immutable.
     *  Params: payload - the ID to be added or removed
     */
    case appActions.SELECT_ASSET: {
      let index = state.selectedAssets.indexOf(action.payload)
      if (index >= 0) {
        return update(state, { selectedAssets: { $splice: [[index, 1]] } })
      } else
        return update(state, { selectedAssets: { $push: [action.payload] } })
    }

    case appActions.SELECT_ASSETS: {
      return {
        ...state,
        selectedAssets: action.ids,
      }
    }
    /*
     *  CLEAR_SELECTED_ASSETS: Clear the array of selected Assets.  May be
     *  used for example after a folder change or drag and drop operation,.
     */
    case appActions.CLEAR_SELECTED_ASSETS: {
      return Object.assign({}, state, { selectedAssets: [] })
    }

    /*
     *  SELECT_FOLDER: Add a folder ID to the array of select folders.
     *    If the folder already exists in the array, remove the ID
     *    Uses update from immutability-helper to keep state immutable.
     *  Params: payload - the ID to be added or removed
     */
    case folderActions.SELECT_FOLDER: {
      let index = state.selectedFolders.indexOf(action.payload)
      if (index >= 0) {
        return update(state, { selectedFolders: { $splice: [[index, 1]] } })
      } else
        return update(state, { selectedFolders: { $push: [action.payload] } })
    }

    /*
     *  CLEAR_SELECTED_FOLDERS: Clear the array of selected folders.  May be
     *  used for example after a drag and drop operation.
     */
    case folderActions.CLEAR_SELECTED_FOLDERS: {
      return Object.assign({}, state, { selectedFolders: [] })
    }

    /*
     * sET_TOKEN_EXPIRATION: update the timestamp when cognito token will expire
     */
    case appActions.SET_TOKEN_EXPIRATION: {
      return {
        ...state,
        tokenExpiration: action.time,
      }
    }

    case appActions.SET_MENU_ITEM: {
      return {
        ...state,
        menuItem: action.item,
      }
    }

    case appActions.INITIALIZATION_COMPLETE: {
      return {
        ...state,
        initializing: false,
      }
    }

    /*
     *  SET_SCROLL_POSITION: this action tracks the scroll position of the layout
     *  component, and enables infinite scrolling.  Assets are displayed in groups
     *  of 24 (easily divisible by 2, 3 or 4).  If the "autoscroll" footer appears
     *  in the viewport, showMore will be true, and the maxAssetsToDisplay will update.
     *  The new maxAssetsToDisplay takes into account assets added through cloning to
     *  ensure that the new max is a multiple of 24.
     */
    case appActions.SET_SCROLL_POSITION: {
      return {
        ...state,
        scrollPosition: action.pos,
        maxAssetsToDisplay:
          state.maxAssetsToDisplay -
          (state.maxAssetsToDisplay % INITIAL_STATE.maxAssetsToDisplay) *
            action.showMore +
          INITIAL_STATE.maxAssetsToDisplay * action.showMore,
      }
    }

    case appActions.APPLY_LIBRARY_SORT_SUCCESS: {
      return {
        ...state,
        sortKey: state.sortFields[action.newSort],
      }
    }

    case appActions.TOGGLE_LIB_SORT_DIR: {
      return {
        ...state,
        sortDirection: state.sortDirection * -1,
      }
    }

    case appActions.SCROLL_TO_TOP: {
      const element = document.getElementById(action.element)
      if (element) element.scrollTop = 0
      return state
    }

    /*
     * ACTIVE_FOLDER: when active folder changes, we should reset the maxAssetsToDisplay
     */
    case folderActions.SET_ACTIVE_FOLDER: {
      return {
        ...state,
        activeView: null,
        maxAssetsToDisplay: INITIAL_STATE.maxAssetsToDisplay,
      }
    }

    case assetActions.CLONE_ASSET_SUCCESS: {
      return {
        ...state,
        maxAssetsToDisplay: state.maxAssetsToDisplay + 1,
      }
    }

    case appActions.RENAMING_FOLDER: {
      return {
        ...state,
        renamingFolder: action.id,
      }
    }

    case appActions.ADD_OPEN_FOLDERKEY: {
      if (state.openFolderKeys.indexOf(action.id) < 0)
        return update(state, {
          openFolderKeys: {
            $push: [action.id],
          },
        })
      return state
    }

    case appActions.REMOVE_OPEN_FOLDERKEY: {
      return update(state, {
        openFolderKeys: {
          $splice: [[state.openFolderKeys.indexOf(action.id), 1]],
        },
      })
    }

    case appActions.SET_OPEN_FOLDER_KEYS:
      return {
        ...state,
        openFolderKeys: action.keyArray,
      }

    /*
     *  when a folder is dropped, add its parents to the open folder keys if necessary
     */
    case folderActions.MOVE_FOLDER_SUCCESS: {
      return update(state, {
        openFolderKeys: {
          $push: action.path.filter(
            (node) => state.openFolderKeys.indexOf(node) < 0
          ),
        },
      })
    }

    case appActions.ADD_OPEN_ENDPOINTKEY: {
      return update(state, {
        openEndpointKeys: {
          $push: [action.id],
        },
      })
    }

    case appActions.REMOVE_OPEN_ENDPOINTKEY: {
      return update(state, {
        openEndpointKeys: {
          $splice: [[state.openEndpointKeys.indexOf(action.id), 1]],
        },
      })
    }

    case appActions.SET_OPEN_ENDPOINTKEYS: {
      return {
        ...state,
        openEndpointKeys: action.ids,
      }
    }

    case appActions.SET_ACTIVE_ENDPOINT: {
      return {
        ...state,
        activeEndpoint: action.id,
      }
    }

    case appActions.SET_STARTUP_ROUTE:
      return {
        ...state,
        startupRoute: action.path,
      }

    case appActions.SET_ACTIVE_VIEW:
      if (state.openEndpointKeys.indexOf(action.endpoint) > -1) {
        return update(state, {
          activeView: {
            $set: action.view,
          },
          activeEndpoint: {
            $set: action.endpoint,
          },
          menuItem: {
            $set: 'library',
          },
          openEndpointKeys: {
            $splice: [
              [
                state.openEndpointKeys.indexOf(action.endpoint),
                1,
                action.endpoint,
              ],
            ],
          },
        })
      }
      return update(state, {
        activeView: {
          $set: action.view,
        },
        activeEndpoint: {
          $set: action.endpoint,
        },
        menuItem: {
          $set: 'library',
        },
        openEndpointKeys: {
          $push: [action.endpoint],
        },
      })

    case appActions.SET_ACTIVE_ASSET:
      return {
        ...state,
        activeAsset: action.id,
      }

    case appActions.ENABLE_UPLOAD_CONTINUE:
      return {
        ...state,
        uploadContinue: action.enabled,
      }

    case appActions.SET_SUCCESSFUL_UPLOADS:
      return {
        ...state,
        successfulUploads: action.uploads,
        uploadType: action.assetType,
      }

    case appActions.SET_BACK_BUTTON_FLAG:
      return {
        ...state,
        backButtonFlag: action.flag,
      }

    case appActions.CLEAR_RESET_PASSWORD_COMPLETE:
      return {
        ...state,
        resetPasswordComplete: false,
      }

    case appActions.APPLY_STATUS_FILTER: {
      return {
        ...state,
        selectedFilter: action.filter,
        selectedAssets: [],
      }
    }

    case appActions.CLEAR_STATUS_FILTER: {
      return {
        ...state,
        selectedFilter: 'ALL',
        selectedAssets: [],
      }
    }

    case appActions.SET_MAX_SIDEBAR_WIDTH: {
      return {
        ...state,
        maxSidebarWidth: action.width,
      }
    }

    // Don't overwrite prevPath (prevents setting path to /maintenance when new version is deployed
    // and multiple secureFetchApi calls are made)
    case appActions.SET_MAINTENANCE_MODE:
      if (state.maintenance?.prevPath)
        action.maintenance.prevPath = state.maintenance.prevPath
      return {
        ...state,
        maintenance: action.maintenance,
      }

    case appActions.SET_EDITMODE_ASSET:
      return {
        ...state,
        editModeAsset: action.assetId,
      }

    case assetActions.CANCEL_REPLACE_ASSET:
      return {
        ...state,
        editModeAsset: null,
      }
    case appActions.REFRESH_STATE:
      return {
        ...state,
        maintenance: action.maintenance,
      }

    case appActions.ADD_PENDING_ACTION:
      return update(state, {
        pendingActionQueue: {
          $apply: (paq) =>
            paq.indexOf(action.action) > -1
              ? paq
              : update(paq, { $push: [action.action] }),
        },
      })

    case appActions.REMOVE_PENDING_ACTION:
      return update(state, {
        pendingActionQueue: {
          $apply: (paq) => paq.filter((item) => item !== action.action),
        },
      })
    case assetActions.GET_SECURE_LINKS_SUCCESS:
      return {
        ...state,
        sharedAssetLinks: {
          duration: action.duration,
          links: action.links,
        },
      }
    case assetActions.CLEAR_SECURE_LINKS:
      return {
        ...state,
        sharedAssetLinks: null,
      }

    case appActions.SET_BAM_VERSION:
      return {
        ...state,
        version: action.version,
      }

    case appActions.SET_VERSION_MISMATCH:
      return {
        ...state,
        versionMismatch: action.mismatch,
      }

    case appActions.SET_COUNTDOWN_TIMER:
      return {
        ...state,
        timer: action.value,
      }

    case appActions.DECREMENT_COUNTDOWN_TIMER:
      return {
        ...state,
        timer: state.timer - action.value,
      }

    case appActions.SET_FOLDER_DRAG_CLEANUP:
      return {
        ...state,
        folderDragCleanup: action.cleanup,
        folderDragData: {
          over: null,
          above: null,
          below: null,
        },
      }

    case appActions.SET_FOLDER_DRAG_DATA:
      return {
        ...state,
        folderDragData: {
          ...action,
        },
      }

    case appActions.SET_CLIPBOARD_CONTENT:
      return {
        ...state,
        clipboardContent: action.text,
      }

    case appActions.SET_ACTIVE_ANALYTICS_VIEW:
      return {
        ...state,
        activeAnalyticsView: action.analyticsViewKey,
      }
    case appActions.SET_ACTIVE_ANALYTICS_PLATFORM:
      return {
        ...state,
        activeAnalyticsPlatform: action.platform,
      }
    case appActions.SET_ACTIVE_ANALYTICS_DURATION:
      return {
        ...state,
        activeAnalyticsDuration: action.duration,
        lastAnalyticsDuration: state.activeAnalyticsDuration,
      }
    case appActions.SET_ANALYTICS_CUSTOM_DATE_RANGE:
      return {
        ...state,
        analyticsCustomDateRange: [action.dateStart, action.dateEnd],
      }
    case appActions.SET_BULK_DOWNLOAD_LINK:
      return {
        ...state,
        bulkDownloadLink: action.link,
      }
    case CLOSE_MODAL:
      return {
        ...state,
        bulkDownloadLink: undefined,
      }
    default:
      return state
  }
}

export const getRefreshedState = (state) => ({
  folderState: state.folders,
  assetState: state.assets,
  endpointState: state.endpoints,
  maintenance: state.appState.maintenance,
})
