/* REACT */
import React, { Component } from 'react'
import { connect } from 'react-redux'
/* ANTD */
import { Button, Typography } from 'antd'
import Title from 'antd/lib/typography/Title'
/* COMPONENTS */
import CalculatorInput from './Components/CalculatorInput'
import CalculatorPicker from './Components/CalculatorPicker'
import CalculatorResults from './Components/CalculatorResults'
import CalculatorCheckbox from './Components/CalculatorCheckbox'
import LoadingError from './Components/LoadingError'
/* DUX */
import {
  CALCULATE,
  LAUNCH_CALCULATOR,
} from '../../../../dux/constants/analytics'
/* UTILS */
import { without } from 'lodash'
import { formatStateFromJSON, getCalculatorConfig } from './calculatorUtils'
import utils, { toPickerObj } from './utils'
import UploadAction from '../../UploadAction'

/* 
TODO: Add support for 'replace asset'
*/

const mapDispatchToProps = (dispatch) => ({
  calculateAnalytics: (data) => dispatch({ type: CALCULATE, data }),
  launchCalculatorAnalytics: (data) =>
    dispatch({ type: LAUNCH_CALCULATOR, data }),
})

export class CalculatorPreview extends Component {
  componentDidMount() {
    const { url, preview: diagramUrl } = this.props.asset
    getCalculatorConfig(url)
      .then((calcJSON) => {
        const diagram = {
          source: diagramUrl !== '' ? diagramUrl : null,
          aspectRatio: calcJSON.diagram?.aspectRatio || 9 / 16,
        }
        const initialState = formatStateFromJSON({ ...calcJSON, diagram })
        const { calculatorId, navTitle } = initialState.calcConfig

        this.setState(
          () => ({ ...initialState, loadingError: false }),
          () =>
            this.props.launchCalculatorAnalytics({
              calculatorId,
              title: navTitle,
            })
        )
      })
      .catch((e) =>
        this.setState(
          () => ({ loadingError: true }),
          () => console.error(e)
        )
      )
  }
  setError = (id) => {
    const errorExists = this.state.errorInputs.includes(id)
    if (errorExists === false) {
      this.setState((prev) => ({
        ...prev,
        errorInputs: [...prev.errorInputs, id],
      }))
    }
  }
  getDataForAnalytics = ({
    inputValues,
    inputFields,
    results,
    resultsFields,
  }) => {
    // map inputs into an object using the labels as keys, for analytics
    const inputs = {}

    inputFields.forEach((section, index) => {
      const inputsSection = {}
      const upperCaseSectionName = section.sectionName.toUpperCase()
      const sectionName = upperCaseSectionName || `Section ${index + 1}`
      inputs[sectionName] = inputsSection
      section.sectionData.forEach((field) => {
        const labelKey = `${field.label || field.key}${
          field.units ? ` (${field.units})` : ''
        }`
        inputsSection[labelKey] = inputValues[field.id]
      })
    })

    // map results into an object using the labels as keys, for analytics
    const outputs = {}

    // add secondary results
    if (resultsFields.secondary) {
      outputs.secondary = {}
      resultsFields.secondary.forEach((field) => {
        const labelKey = `${field.label || field.key}${
          field.units ? ` (${field.units})` : ''
        }`
        outputs.secondary[labelKey] =
          typeof results[field.key] === 'number'
            ? field.decimalPlaces
              ? results[field.key].toFixed(field.decimalPlaces)
              : results[field.key].toFixed(2)
            : results[field.key]
      })
    }

    // add primary results
    if (resultsFields.primary) {
      outputs.primary = {}
      resultsFields.primary.forEach((field) => {
        const labelKey = `${field.label || field.key}${
          field.units ? ` (${field.units})` : ''
        }`
        outputs.primary[labelKey] =
          typeof results[field.key] === 'number'
            ? field.decimalPlaces
              ? results[field.key].toFixed(field.decimalPlaces)
              : results[field.key].toFixed(2)
            : results[field.key]
      })
    }

    return { inputs, outputs }
  }

  clearError = (id) => {
    const errorExists = this.state.errorInputs.includes(id)
    if (errorExists === true) {
      this.setState((prev) => ({
        ...prev,
        errorInputs: [...without(prev.errorInputs, id)],
      }))
    }
  }

  setInputState = (id, value, dataType) => {
    let parsedInputState
    if (dataType === 'number') {
      parsedInputState = value !== '' ? parseFloat(value) : null
    } else {
      parsedInputState = value
    }

    //input side effects

    this.setState((prev) => {
      const state = { ...prev }
      const {
        calcConfig: { inputFields },
      } = state
      let inputMayHaveDependencies = false
      const updatedInputs = inputFields.map(({ sectionName, sectionData }) => ({
        sectionName,
        sectionData: sectionData.map((input) => {
          // if we've hit the input being updated,
          // start looking for side effects with the next input

          if (input.id === id) {
            inputMayHaveDependencies = true
            return input
          }

          if (!inputMayHaveDependencies) {
            return input
          }

          if (input.dependencies === undefined) {
            return input
          }
          //key into dependency to grab sideEffects that need to be applied and
          //collect them in sideEffects object
          const sideEffects = Object.keys(input.dependencies).reduce(
            (sideEffectsAcc, dependencyId) => {
              const currentStateForIteration =
                id === dependencyId
                  ? parsedInputState
                  : state.inputValues[dependencyId]
              const sfx =
                input.dependencies[dependencyId][currentStateForIteration]
              return { ...sideEffectsAcc, ...sfx }
            },
            {}
          )

          // apply state-setting sideEffects
          if (sideEffects.options) {
            state.inputValues[input.id] = sideEffects.options[0]
          }
          if (sideEffects.value !== undefined) {
            state.inputValues[input.id] = sideEffects.value
          }
          if (sideEffects.reset === true) {
            state.inputValues[input.id] = state.defaultValues[input.id]
          }
          // spread config side effects over the input
          const cfg = { ...input, ...sideEffects }
          return cfg
        }),
      }))
      return {
        ...prev,
        calcConfig: { ...prev.calcConfig, inputFields: updatedInputs },
        inputValues: {
          ...prev.inputValues,
          ...state.inputValues,
          [id]: parsedInputState,
        },
      }
    })
  }
  createCalcSection = (section) => {
    const sectionLabel = section.sectionName.toUpperCase()
    return (
      <div key={JSON.stringify(section)} style={{ marginBottom: 50 }}>
        <Title level={3} className="assetdetail__calc-text-primary">
          {sectionLabel}
        </Title>
        {section.sectionData.map((dataField) => this.renderInput(dataField))}
      </div>
    )
  }
  renderInput = (data) => {
    const {
      id,
      type,
      units,
      label,
      options,
      minimum,
      maximum,
      dataType,
      required,
      decimalPlaces,
      hidden,
    } = data
    const inputValue = this.state.inputValues[id]
    const inputValueString = `${this.state.inputValues[id]}`
    // render nothing if the input should be hidden
    if (hidden) return null
    switch (type) {
      case 'input':
        return (
          <CalculatorInput
            key={id}
            defaultValue={inputValueString}
            decimalPlaces={decimalPlaces}
            required={required}
            setError={this.setError}
            clearError={this.clearError}
            setInputState={this.setInputState}
            {...{ label, units, minimum, maximum, dataType, id }}
          />
        )

      case 'picker':
        return (
          <CalculatorPicker
            key={id}
            value={inputValueString}
            options={toPickerObj(options)}
            onChange={this.setInputState}
            {...{ id, label, units, dataType }}
          />
        )
      case 'checkbox':
        return (
          <CalculatorCheckbox
            key={id}
            defaultValue={inputValue}
            onChange={this.setInputState}
            {...{ id, units, label, dataType }}
          />
        )
    }
  }
  toggleShowCalc = () => {
    const {
      showResults,
      calculate,
      inputValues,
      calcConfig: {
        lookupData,
        inputFields,
        resultsFields,
        calculatorId,
        navTitle,
      },
    } = this.state

    if (showResults) {
      this.setState({ showResults: false })
      return
    }
    // If toggling from no show to yes show, then calculate
    const results = calculate(inputValues, lookupData, utils)
    this.setState((state) => ({ ...state, results, showResults: true }))
    const { inputs, outputs } = this.getDataForAnalytics({
      inputValues,
      inputFields,
      resultsFields,
      results,
    })

    this.props.calculateAnalytics({
      calculatorId,
      title: navTitle,
      in: inputs,
      out: outputs,
    })
  }

  render() {
    if (this.state === null) return <div>loading..</div>
    if (this.state.loadingError) return <LoadingError />
    const { showResults, errorInputs, calcConfig, results } = this.state
    const {
      diagram,
      inputFields,
      disclaimer,
      navTitle,
      resultsFields,
      resultsTitle,
      intro,
    } = calcConfig
    const hasError = !!errorInputs.length
    return (
      <div className="assetdetail__calc-preview">
        {!showResults ? (
          <div className="assetdetail__calc-inputs">
            <Title level={1} className="assetdetail__calc-text-primary">
              {navTitle}
            </Title>
            {intro && (
              <Typography className="assetdetail__calc-text-primary">
                {intro}
              </Typography>
            )}
            <div style={{ marginBottom: 50 }}>
              {inputFields.map(this.createCalcSection)}

              <Button
                onClick={this.toggleShowCalc}
                disabled={hasError}
                block
                type="primary"
                size="large"
              >
                CALCULATE
              </Button>
            </div>

            {diagram.source && (
              <div className="assetdetail__calc-diagram-container">
                <img
                  className="assetdetail__calc-diagram-image"
                  src={diagram.source}
                  alt="diagram"
                />
              </div>
            )}
          </div>
        ) : (
          <CalculatorResults
            results={results}
            onDismiss={this.toggleShowCalc}
            title={resultsTitle || navTitle}
            calcConfig={calcConfig}
            disclaimerText={disclaimer || ''}
            resultsFields={resultsFields}
          />
        )}
        {this.props.isSuperUser ? (
          <UploadAction {...this.props} freePosition />
        ) : null}
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(CalculatorPreview)
