import { Breadcrumb, Button, Checkbox, Select, Tooltip } from 'antd'
import React from 'react'
import { buildPath } from '../../dux/modules/utils'
import TwoButtonContainer from '../BAM_UI/TwoButtonContainer'

const Option = Select.Option

export const generateColumns = (
  roles,
  sysGroupStatus,
  toggleSysGroups,
  selectAllEntities
) => {
  const columns = [
    {
      title: <Checkbox onChange={selectAllEntities} />,
      dataIndex: 'selector',
      className: 'modal__permissions-col-hdr',
      key: 'selector',
    },
    {
      title: (
        <span>
          <div>User/Group</div>
          <div className="permissions__linkspan" onClick={toggleSysGroups}>
            {sysGroupStatus === 'show'
              ? 'Hide System Groups'
              : 'Show System Groups'}
          </div>
        </span>
      ),
      dataIndex: 'name',
      className: 'modal__permissions-col-hdr',
      key: 'name',
    },
  ]
  return columns.concat(
    roles
      .filter((role) => role.contentRole)
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .filter((role) => role.id !== 'webcontentuser')
      .map((role) => {
        return {
          title: <Tooltip title={role.description}>{role.displayName}</Tooltip>,
          dataIndex: role.id,
          className: 'modal__permissions-col-hdr',
          key: role.id,
          width: 75,
          render: (text, record) => (
            <Checkbox
              checked={record[role.id]}
              disabled={
                record.key.startsWith('SYS.') ||
                role.id === 'traverser' ||
                role.id === 'webcontentuser'
              }
              onChange={(e) => record.handleChange(e, record, role.id)}
            />
          ),
        }
      })
  )
}

const sortEntityList = (a, b, groups, users) => {
  if (!groups[a] && groups[b]) return -1
  if (!groups[b] && groups[a]) return 1
  if (!users[a] && users[b]) return -1
  if (!users[b] && users[a]) return 1
  if (!groups[a] && !groups[b])
    return users[a].name.localeCompare(users[b].name)
  if (groups[a].system && !groups[b].system) return 1
  if (!groups[a].system && groups[b].system) return -1
  return groups[a].name.localeCompare(groups[b].name)
}

export const formatDataSource = (
  contentRoles,
  groups,
  users,
  hideSysGroups,
  handleChange,
  selectedEntities,
  onEntityClick
) => {
  let datasource = []
  Object.keys(contentRoles)
    .filter((entity) => !(hideSysGroups && entity.startsWith('SYS.')))
    .sort((a, b) => sortEntityList(a, b, groups, users))
    .forEach((entity) => {
      datasource.push({
        key: entity,
        selector: (
          <Checkbox
            checked={selectedEntities.indexOf(entity) > -1}
            disabled={entity.startsWith('SYS.')}
            onChange={() => onEntityClick(entity)}
          />
        ),
        name: getEntityName(entity, contentRoles, groups),
        mobileuser:
          contentRoles[entity].roleIds.findIndex(
            (role) => role === 'mobileuser'
          ) > -1,
        viewer:
          contentRoles[entity].roleIds.findIndex((role) => role === 'viewer') >
          -1,
        creator:
          contentRoles[entity].roleIds.findIndex((role) => role === 'creator') >
          -1,
        editor:
          contentRoles[entity].roleIds.findIndex((role) => role === 'editor') >
          -1,
        publisher:
          contentRoles[entity].roleIds.findIndex(
            (role) => role === 'publisher'
          ) > -1,
        approver:
          contentRoles[entity].roleIds.findIndex(
            (role) => role === 'approver'
          ) > -1,
        contentadmin:
          contentRoles[entity].roleIds.findIndex(
            (role) => role === 'contentadmin'
          ) > -1,
        traverser:
          contentRoles[entity].roleIds.findIndex(
            (role) => role === 'traverser'
          ) > -1,
        handleChange,
      })
    })
  return datasource
}

export const getEntityName = (entity, contentRoles, groups) => {
  let name = ''
  if (contentRoles[entity].entityType === 'group') {
    name = groups[entity].name
  } else {
    name = entity
  }
  return name
}

// This function return an array filter that filters an object for keys that match role ids
export const validRoles = (roles) => {
  return (item) => roles.some((role) => role.id === item)
}

export const getFooter = (
  saveFunc,
  cancelFunc,
  removeFunc,
  selectedEntities,
  primaryDisabled
) => {
  return (
    <span className="modal__flex">
      <span>
        <Button onClick={removeFunc} disabled={selectedEntities.length < 1}>
          Remove Selected
        </Button>
      </span>
      <span>
        <TwoButtonContainer
          primaryButtonText="OK"
          primaryButtonAction={saveFunc}
          primaryButtonDisabled={primaryDisabled}
          secondaryButtonText="Cancel"
          secondaryButtonAction={cancelFunc}
        />
      </span>
    </span>
  )
}

/*
 * Build modal title with path of folder being edited
 * options arg looks like: {
 *   groups,
 *   users,
 *   activeFolder,
 *   allFolders,
 *   newFolder,
 *   newFolderName,
 *   groupSelect,
 *   userSelect,
 *   contentRoles,
 *   addUser,
 *   addGroup,
 * }
 */
export const getModalTitle = (options) => {
  const {
    groups,
    users,
    activeFolder,
    allFolders,
    newFolder,
    newFolderName,
    groupSelect,
    userSelect,
    contentRoles,
    addUser,
    addGroup,
  } = options
  return (
    <span className="modal__flex">
      <span>
        <span> Set Folder Permissions: &nbsp;</span>
        <span>
          <Breadcrumb separator=">">
            {buildPath(activeFolder, allFolders)
              .reverse()
              .map((crumb) => (
                <Breadcrumb.Item
                  key={crumb}
                >{` ${allFolders[crumb].name} `}</Breadcrumb.Item>
              ))
              .concat(
                newFolder
                  ? [
                      <Breadcrumb.Item key="new">{` ${newFolderName} `}</Breadcrumb.Item>,
                    ]
                  : null
              )}
          </Breadcrumb>
        </span>
      </span>
      <span>
        <Select
          id="groupselect"
          key={'group'}
          showSearch
          defaultActiveFirstOption={false}
          style={{ width: 175, margin: '10px 0 10px 5px' }}
          placeholder="Add a Group"
          filterOption
          optionFilterProp="name"
          onChange={addGroup}
          value={groupSelect}
          notFoundContent="No groups available to add"
        >
          {Object.keys(groups)
            .sort((a, b) => groups[a].name.localeCompare(groups[b].name))
            .filter((grp) => !contentRoles[grp] && !groups[grp].system)
            .map((grp) => {
              return (
                <Option key={grp} name={groups[grp].name}>
                  {groups[grp].name}
                </Option>
              )
            })}
        </Select>
        <Select
          id="userselect"
          key={'user'}
          showSearch
          defaultActiveFirstOption={false}
          style={{ width: 175, margin: '10px 0 10px 5px' }}
          placeholder="Add a User"
          filterOption
          optionFilterProp="email"
          onChange={addUser}
          value={userSelect}
          notFoundContent="No users available to add"
        >
          {Object.keys(users)
            .sort((a, b) => users[a].email.localeCompare(users[b].email))
            .filter((u) => !contentRoles[u])
            .map((u) => {
              return (
                <Option key={u} email={users[u].email}>
                  {users[u].email}
                </Option>
              )
            })}
        </Select>
      </span>
    </span>
  )
}
