import { Checkbox, Col } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { DragSource } from 'react-dnd'
import { Link, withRouter } from 'react-router-dom'
import { BUILDER_TYPES } from '../../dux/constants/builders'
import { assetSource, DND_ASSET, dragCollect } from '../../dux/modules/DragDrop'
import ResponsiveBackgroundImage from '../../dux/modules/ResponsiveBackgroundImage'
import {
  getEndpointOfNode,
  getOriginalAssetPreview,
} from '../../dux/modules/utils'
import CopyURLAction from './previews/CopyURLAction'
import ReplacePreviewAction from './previews/ReplacePreviewAction'
import '../styles/Library.css'
import StatusIcon from './StatusIcon'

class AssetCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showReplaceAction: false,
      showPublicURLAction: false,
      imageSize: {},
      size: 'small',
      style: {
        selected: {
          margin: '16px 8px',
          boxShadow: '#50abf1 0 6px 0 0',
        },
        unselected: {
          margin: '16px 8px',
        },
      },
    }
    // ResponsiveBackgroundImage - sets background-image CSS based on changes to hidden image tag
    this.rbi = null

    this.getHeight = () => Math.round(props.cardWidth / (16 / 9))
  }

  componentDidMount() {
    const { item } = this.props

    const preview = this.getPreview(item)
    if (preview) {
      this.rbi = new ResponsiveBackgroundImage(
        document.getElementById(`${item.id}`),
        this.getHeight
      )
    }
  }

  componentDidUpdate(prevProps) {
    const { item } = this.props

    if (item.preview && prevProps.item.preview !== item.preview) {
      this.rbi = new ResponsiveBackgroundImage(
        document.getElementById(`${item.id}`),
        this.getHeight
      )
    }
  }

  getPreview = (asset) => {
    const { allAssets } = this.props

    if (BUILDER_TYPES.indexOf(asset.asset_type) > -1)
      if (allAssets[asset[asset.asset_type].image])
        return allAssets[asset[asset.asset_type].image].preview

    return asset.preview
  }

  onMouseOver = () => {
    const { item } = this.props

    const isReplaceable =
      item.icon !== 'fa-file-image-o' &&
      !BUILDER_TYPES.includes(item.asset_type)
    const isPublicURLable = !BUILDER_TYPES.includes(item.asset_type)

    isReplaceable && this.setState({ showReplaceAction: true })
    isPublicURLable && this.setState({ showPublicURLAction: true })
  }

  onMouseOut = () => {
    const { appActions } = this.props
    // Remove after a 200 ms delay to avoid seeing the antd popover
    // We want that popover to disappear first.
    // Clearing the clipboard content will remove the copied text
    // from the redux store. It should still be copied to the actual
    // clipboard.
    setTimeout(
      () =>
        this.setState(
          {
            showReplaceAction: false,
            showPublicURLAction: false,
          },
          () => appActions.setClipboardContent(null)
        ),
      200
    )
  }

  replacePreviewCB = (succeeded, failed, files) => {
    const { item, config, assetActions, modalActions } = this.props

    if (succeeded.length > 0) {
      const updates = {
        preview: `${config.baseUrl}/${files[0].key}`,
      }
      assetActions.saveAssetEdits(item, updates, true, false, null)
      modalActions.closeModal()
    }
  }

  handlePreviewClick = (e, asset, type) => {
    const { config, assetActions, modalActions } = this.props

    e.stopPropagation()

    switch (type) {
      case 'custom':
        return modalActions.openModal('UploadModal', {
          multiple: false,
          type: 'support',
          keyFunc: () => `${asset.key}.customPreview.${Date.now()}`,
          callback: this.replacePreviewCB,
          imageOnly: true,
          title: 'Upload Custom Thumbnail',
        })

      case 'icon':
        if (asset.preview !== null)
          return assetActions.saveAssetEdits(
            asset,
            {
              preview: null,
            },
            true,
            false,
            null
          )
        break

      case 'original':
        if (
          asset.preview !==
          `${config.baseUrl}/${getOriginalAssetPreview(asset)}`
        )
          return assetActions.saveAssetEdits(
            asset,
            {
              preview: `${config.baseUrl}/${getOriginalAssetPreview(asset)}`,
            },
            true,
            false,
            null
          )
        break

      default:
        return
    }
  }

  render() {
    const {
      item,
      style,
      appState,
      selected,
      cardWidth,
      endpoints,
      appActions,
      isDragging,
      allFolders,
      activeFolder,
      onSelectAsset,
      selectedAssets,
      connectDragSource,
      folderAssetStatus,
    } = this.props
    const { showReplaceAction, showPublicURLAction } = this.state

    const previewImage = this.getPreview(item)
    const hasPreviewImage = !!previewImage
    const hasPublicURL = showPublicURLAction && !!item.publicURL

    return (
      <Col xs={12} md={8} xl={6} xxl={4} style={style}>
        {connectDragSource(
          <div className={isDragging ? 'assetcard__dragging' : 'assetcard'}>
            {hasPreviewImage ? (
              <div
                onMouseEnter={this.onMouseOver}
                onMouseLeave={this.onMouseOut}
              >
                <Link to={`/library/asset/${item.id}`}>
                  <CoverImage
                    alt={item.filename}
                    color={item.color || '#e5e5e5'}
                    asset={item}
                    source={previewImage}
                    height={Math.round(cardWidth / (16 / 9))}
                    onSelect={onSelectAsset}
                    selected={selected}
                    showReplaceAction={showReplaceAction}
                    showPublicURLAction={hasPublicURL}
                    handlePreviewClick={this.handlePreviewClick}
                    clipboardContent={appState.clipboardContent}
                    setClipboardContent={appActions.setClipboardContent}
                    disableSelect={
                      getEndpointOfNode(
                        allFolders[activeFolder],
                        allFolders,
                        endpoints.endpoints
                      ).id === 'LIB'
                        ? false
                        : selectedAssets.length > 0 &&
                          folderAssetStatus[activeFolder][item.id].status !==
                            folderAssetStatus[activeFolder][selectedAssets[0]]
                              .status
                    }
                  />
                </Link>
              </div>
            ) : (
              <div
                onMouseEnter={this.onMouseOver}
                onMouseLeave={this.onMouseOut}
              >
                <Link to={`/library/asset/${item.id}`}>
                  <CoverImageIcon
                    asset={item}
                    height={Math.round(cardWidth / (16 / 9))}
                    onSelect={onSelectAsset}
                    selected={selected}
                    showReplaceAction={showReplaceAction}
                    showPublicURLAction={hasPublicURL}
                    handlePreviewClick={this.handlePreviewClick}
                    clipboardContent={appState.clipboardContent}
                    setClipboardContent={appActions.setClipboardContent}
                    disableSelect={
                      getEndpointOfNode(
                        allFolders[activeFolder],
                        allFolders,
                        endpoints.endpoints
                      ).id === 'LIB'
                        ? false
                        : selectedAssets.length > 0 &&
                          folderAssetStatus[activeFolder][item.id].status !==
                            folderAssetStatus[activeFolder][selectedAssets[0]]
                              .status
                    }
                  />
                </Link>
              </div>
            )}
            <Link to={`/library/asset/${item.id}`}>
              <div className="assetcard__meta">
                <div className="assetcard__title">
                  {item.title || <i>Untitled</i>}
                </div>
                <div className="assetcard__description">
                  {item.description || <i>No description</i>}
                </div>
                <div className="assetcard__data">
                  <span>
                    <i className={`fa ${item.icon}`} />
                    &nbsp;
                    <span className="assetcard__data-item">
                      {item.extension && item.extension.toUpperCase()}
                    </span>
                  </span>
                  <span className="assetcard__data-item2">
                    {moment(item.updated).format('MMM D, YYYY')}
                    <StatusIcon {...this.props} />
                  </span>
                </div>
              </div>
            </Link>
          </div>
        )}
      </Col>
    )
  }
}

const CoverImage = (props) => {
  return (
    <div className="bam-coverimage">
      <div
        id={props.asset.id}
        className="bam-coverimage__wrapper"
        style={{
          height: props.height,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          cursor: 'pointer',
          backgroundColor: props.color,
          color: 'rgba(255, 255, 255, 0.3)',
        }}
      >
        <img
          style={{ display: 'none' }}
          src={props.source}
          srcSet={
            `${props.source}_thumb-200x 200w, ${props.source}_thumb-250x 250w, ` +
            `${props.source}_thumb-375x 375w, ${props.source}_thumb-500x 500w,`
          }
          sizes={
            '(max-width: 767px) 200px, (max-width: 1199px) 250px, (max-width: 2099px) ' +
            '375px, 500px'
          }
          alt="asset card cover"
        />
        <span
          className="bam-coverimage__cb"
          onClick={(e) => {
            e.stopPropagation()
            if (!props.disableSelect) props.onSelect(props.asset.id)
          }}
        >
          <Checkbox disabled={props.disableSelect} checked={props.selected} />
        </span>
        <span className={'bam-coverimage__action-buttons'}>
          <span
            className={`bam-coverimage__action${
              props.showReplaceAction ? '' : '-hidden'
            }`}
          >
            <ReplacePreviewAction {...props} />
          </span>
          <span
            className={`bam-coverimage__action${
              props.showPublicURLAction ? '' : '-hidden'
            }`}
          >
            <CopyURLAction {...props} />
          </span>
        </span>
      </div>
    </div>
  )
}

const CoverImageIcon = (props) => {
  return (
    <div
      className="bam-coverimage-icon"
      style={{ height: props.height, backgroundColor: props.asset.color }}
    >
      <div className="bam-coverimage-icon-content">
        <i className={`fa ${props.asset.icon} bam-coverimage-icon-detail`} />
        <div className="bam-coverimage-icon-text">{props.asset.filename}</div>
      </div>
      <span
        className="bam-coverimage__cb"
        onClick={(e) => {
          e.stopPropagation()
          if (!props.disableSelect) props.onSelect(props.asset.id)
        }}
      >
        <Checkbox disabled={props.disableSelect} checked={props.selected} />
      </span>
      <span className={'bam-coverimage__action-buttons'}>
        <span
          className={`bam-coverimage__action${
            props.showReplaceAction ? '' : '-hidden'
          }`}
        >
          <ReplacePreviewAction {...props} />
        </span>
        <span
          className={`bam-coverimage__action${
            props.showPublicURLAction ? '' : '-hidden'
          }`}
        >
          <CopyURLAction {...props} />
        </span>
      </span>
    </div>
  )
}

AssetCard.propTypes = {
  item: PropTypes.object,
  style: PropTypes.object,
  config: PropTypes.object,
  appState: PropTypes.object,
  allAssets: PropTypes.object,
  selected: PropTypes.bool,
  cardWidth: PropTypes.number,
  endpoints: PropTypes.object,
  appActions: PropTypes.object,
  isDragging: PropTypes.bool,
  allFolders: PropTypes.object,
  activeFolder: PropTypes.string,
  onSelectAsset: PropTypes.func,
  assetActions: PropTypes.object,
  modalActions: PropTypes.object,
  selectedAssets: PropTypes.arrayOf(PropTypes.string),
}

export default DragSource(
  DND_ASSET,
  assetSource,
  dragCollect
)(withRouter(AssetCard))
