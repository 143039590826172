import { Col } from 'antd'
import React from 'react'
import Lightbox from 'react-image-lightbox'
import '../styles/Library.css'

class DummyCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      imageSize: {},
      size: 'small',
      showMoreActions: false,
      showLightbox: false,
      style: {
        selected: {
          margin: '16px',
          boxShadow: '#50abf1 0 6px 0 0',
        },
        unselected: {
          margin: '16px',
        },
      },
    }

    this.showLightbox = () => {
      this.setState({ showLightbox: true })
    }
  }

  render() {
    return (
      <Col xs={12} md={8} xl={6} xxl={4} style={this.props.style}>
        <CoverImageIcon
          color={this.props.item.color || '#e5e5e5'}
          asset={this.props.item}
          height={Math.round(this.props.cardWidth / (16 / 9))}
          showLightbox={this.showLightbox}
        />
        <div className="assetcard__meta">
          <div className="assetcard__title">
            {this.props.item.title || <i>Untitled</i>}
          </div>
          <div className="assetcard__description">
            {this.props.item.description || <i>No description</i>}
          </div>
          <div className="assetcard__data">
            <span>
              <i className={'fa fa-question-circle'} />
              &nbsp;
              <span className="assetcard__data-item">UNKNOWN</span>
            </span>
            <span className="assetcard__data-item2"></span>
          </div>
        </div>
        {this.state.showLightbox && (
          <Lightbox
            mainSrc={this.props.item.preview}
            onCloseRequest={() => this.setState({ showLightbox: false })}
          />
        )}
      </Col>
    )
  }
}

const CoverImageIcon = (props) => {
  return (
    <div
      className="bam-coverimage-icon"
      style={{ height: props.height, backgroundColor: props.asset.color }}
    >
      <div className="bam-coverimage-icon-content">
        <i className={`fa ${props.asset.icon} bam-coverimage-icon-detail`} />
        <div className="bam-coverimage-icon-text">{props.asset.filename}</div>
      </div>
    </div>
  )
}

export default DummyCard
