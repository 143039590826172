import { get } from 'lodash'
import md5 from 'md5'
import { all, call, put, select, takeLeading } from 'redux-saga/effects'
import * as endpointConstants from '../constants/endpoints'
import * as folderConstants from '../constants/folder'
import * as settingsConstants from '../constants/settings'
import * as webEndpointConstants from '../constants/webEndpoints'
import SessionTimeoutError from '../modules/SessionTimeoutError'
import { generateDefaultContentRoles } from '../modules/utils'
import * as apiActions from './api'
import * as appActions from './appActions'
import * as endpointActions from './endpoint'
import * as folderActions from './folder'
import * as modalActions from './modal'
import * as settingsActions from './settings'
import * as userActions from './user'

export const SAVE_WEB_ENDPOINT = 'SAVE_WEB_ENDPOINT'
export const SET_SELECTED_WEB_ENDPOINT_ID = 'SET_SELECTED_WEB_ENDPOINT_ID'

export const saveWebEndpoint = (values) => ({
  type: SAVE_WEB_ENDPOINT,
  values,
})

export function* watchSaveWebEndpoint() {
  yield takeLeading(SAVE_WEB_ENDPOINT, saveWebEndpointSaga)
}

export function* saveWebEndpointSaga(action) {
  try {
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })

    const { values } = action

    const selectedEndpointId = yield select(
      (state) => state.settings.webendpoints.selectedEndpointId
    )
    const isNew =
      !selectedEndpointId ||
      selectedEndpointId === webEndpointConstants.DEFAULT_ID
    const endpointId = isNew
      ? `WEB-${md5(`${values.endpointTitle}${Date.now()}`)}`
      : selectedEndpointId

    const { byId: foldersById } = yield select((state) => state.folders)

    if (isNew) {
      const { client, id: userId } = yield select((state) => state.user)
      const timestamp = yield call(Date.now)
      const rootFolderId = yield call(md5, `${userId}${timestamp}`)

      // TODO calling the saga directly to make it sequential (same with folder below)
      // TODO need to look into a better way, maybe an action creator that returns the saga?
      yield call(endpointActions.addEndpointSaga, {
        type: endpointActions.ADD_ENDPOINT,
        client,
        name: values.endpointTitle,
        options: {
          actionIcon: 'folder-add',
          allowPublishing: true,
          badges: [
            endpointActions.PENDING_APPROVAL,
            endpointActions.PENDING_REMOVAL,
          ],
          description:
            'Assets in this endpoint are available to public websites',
          icon: 'fa-globe',
          id: endpointId,
          privilege: 'addfolder',
          rootFolders: [rootFolderId],
          type: endpointConstants.WEB_ENDPOINT_TYPE,
        },
      })

      const groups = yield select((state) => state.permissions.groups)
      const contentRoles = yield call(
        generateDefaultContentRoles,
        groups,
        endpointConstants.WEB_ENDPOINT_TYPE
      )

      yield call(folderActions.addFolderSaga, {
        type: folderActions.ADD_FOLDER,
        name: values.rootFolder,
        parent: {},
        owner: userId,
        client,
        permissions: contentRoles,
        path: [],
        allFolders: foldersById,
        folderType: folderConstants.WEB_FOLDER_TYPE,
        id: rootFolderId,
      })
    } else {
      const { endpoints } = yield select((state) => state.endpoints)
      const endpoint = endpoints.find((e) => e.id === endpointId)

      if (endpoint.name !== values.endpointTitle) {
        yield put(
          endpointActions.renameEndpoint(
            values.endpointTitle,
            endpointId,
            false
          )
        )
      }

      const rootFolder = foldersById[endpoint.rootFolders[0]]

      if (rootFolder.name !== values.rootFolder) {
        yield put(
          folderActions.renameFolder(values.rootFolder, rootFolder.id, false)
        )
      }
    }

    const currentSettings = yield select(
      (state) => state.settings[settingsConstants.SETTINGS_KEY_WEB_ENDPOINTS]
    )

    /* NOTE: The image404sByEndpointId value was set up to store a unique file path for each
     *  web endpoint. An asset can be published to multiple web endpoints, and we currently don't
     *  have a way to determine which 404 image to show based on which web endpoint it was published
     *  to. So we now assume only one custom 404 image per bam instance, but we keep the data
     *  structure the same. Which means all values in the image404sByEndpoint id object should be
     *  identical.
     */
    const currentImage404sByEndpointId = get(
      currentSettings,
      'image404sByEndpointId',
      {}
    )
    let identicalImage404sByEndpointId = {}
    for (let webEp of Object.keys(currentImage404sByEndpointId)) {
      identicalImage404sByEndpointId[webEp] = values.image404sByEndpointId
    }

    yield call(settingsActions.saveSettingsSaga, {
      setting: settingsConstants.SETTINGS_KEY_WEB_ENDPOINTS,
      value: {
        image404sByEndpointId: {
          ...identicalImage404sByEndpointId,
          [endpointId]: values.image404sByEndpointId,
        },
      },
    })
    // END NOTE

    /* POST the updated 404 image. Necessary so that cloudfront knows
     *  what to resolve when an unknown url is requested from the public
     *  url bucket.
     */
    const config = yield select((state) => state.config.appConfig)

    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/settings/setPublic404`,
      init: {
        method: 'POST',
        body: JSON.stringify({
          image404Path: `/${values.image404sByEndpointId}`,
        }),
      },
    })
    // End POST

    yield put(setSelectedWebEndpointId(endpointId))
  } catch (error) {
    console.log(error)
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    }
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

export const setSelectedWebEndpointId = (endpointId) => ({
  type: SET_SELECTED_WEB_ENDPOINT_ID,
  endpointId,
})

export function* watchSetSelectedWebEndpoint() {
  yield takeLeading(SET_SELECTED_WEB_ENDPOINT_ID, setSelectedWebEndpointIdSaga)
}

export function* setSelectedWebEndpointIdSaga(action) {
  const { endpointId } = action

  try {
    const currentSettings = yield select(
      (state) => state.settings[settingsConstants.SETTINGS_KEY_WEB_ENDPOINTS]
    )
    const newSettings = {
      image404sByEndpointId: currentSettings.image404sByEndpointId,
      selectedEndpointId: endpointId,
    }

    if (endpointId === webEndpointConstants.DEFAULT_ID) {
      newSettings.endpointTitle = ''
      newSettings.rootFolder = ''
    } else {
      const { endpoints } = yield select((state) => state.endpoints)
      const selectedEndpoint = endpoints.find((e) => e.id === endpointId)
      const { byId: foldersById } = yield select((state) => state.folders)

      newSettings.endpointTitle = selectedEndpoint.name
      newSettings.rootFolder = foldersById[selectedEndpoint.rootFolders[0]].name
    }

    yield put({
      type: settingsActions.UPDATE_SETTINGS,
      result: {
        setting: 'webendpoints',
        value: newSettings,
      },
    })
  } catch (err) {
    console.error(err)
    yield put({
      type: modalActions.SET_MESSAGE,
      msg: {
        type: 'ERROR',
        msg: 'Failed to select web endpoint',
      },
    })
    yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
  }
}

export default all([watchSaveWebEndpoint(), watchSetSelectedWebEndpoint()])
