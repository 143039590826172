import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'

import * as Modals from '.'
import * as appActions from '../../dux/actions/appActions'
import * as assetActions from '../../dux/actions/asset'
import * as builderActions from '../../dux/actions/builders'
import * as endpointActions from '../../dux/actions/endpoint'
import * as folderActions from '../../dux/actions/folder'
import * as invitationActions from '../../dux/actions/invitation'
import * as modalActions from '../../dux/actions/modal'
import * as permissionsActions from '../../dux/actions/permissions'
import * as settingsActions from '../../dux/actions/settings'
import * as userActions from '../../dux/actions/user'

class ModalContainer extends React.Component {
  render() {
    if (this.props.modal.modalOpen) {
      const BamModal = Modals[this.props.modal.modalComponent]
      return (
        <div>
          <BamModal {...this.props} modalProps={this.props.modal.modalProps} />
        </div>
      )
    }
    return null
  }
}

ModalContainer.propTypes = {
  modal: PropTypes.object,
  folders: PropTypes.object,
  assets: PropTypes.object,
  modalActions: PropTypes.object,
  appActions: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    folders: state.folders,
    assets: state.assets,
    user: state.user,
    appState: state.appState,
    builders: state.builders,
    endpoints: state.endpoints,
    invitations: state.invitations,
    permissions: state.permissions,
    config: state.config.appConfig,
    settings: state.settings,
  }
}

const mapDispatchToProps = (dispatch) => ({
  modalActions: bindActionCreators(modalActions, dispatch),
  folderActions: bindActionCreators(folderActions, dispatch),
  assetActions: bindActionCreators(assetActions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
  builderActions: bindActionCreators(builderActions, dispatch),
  appActions: bindActionCreators(appActions, dispatch),
  endpointActions: bindActionCreators(endpointActions, dispatch),
  invitationActions: bindActionCreators(invitationActions, dispatch),
  permissionsActions: bindActionCreators(permissionsActions, dispatch),
  settingsActions: bindActionCreators(settingsActions, dispatch),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalContainer)
)
