import { get, find, maxBy, minBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Hint,
  LineMarkSeries,
  makeWidthFlexible,
  MarkSeries,
  XAxis,
  XYPlot,
  YAxis,
} from 'react-vis'
import 'react-vis/dist/style.css'
import { colors } from '../../../../styleConstants'

const DateDisplayFormat = 'MM/DD/YYYY'

const Tooltip = ({ containerStyles, xyObj }) => (
  <div style={{ ...styles.tooltipContainer, ...containerStyles }}>
    <div>
      <span style={{ ...styles.tooltipText, ...styles.bold }}>
        {moment(xyObj.x).format(DateDisplayFormat)}
      </span>
    </div>
    <div>
      <span style={{ ...styles.tooltipText, ...styles.bold }}>
        Total events: {xyObj.y}
      </span>
    </div>
  </div>
)

// ****************
// React Components
// ****************
class AnalyticsAssetGraph extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tooltipValue: null,
    }
  }

  setTooltipValue = (tooltipValue) => {
    this.setState({ tooltipValue })
  }

  forgetTooltipValue = () => {
    this.setState({ tooltipValue: null })
  }

  handleSelectPoint = () => {
    const { tooltipValue } = this.state
    const { onSelectPoint } = this.props

    onSelectPoint(tooltipValue && tooltipValue.x)
  }

  renderPlot = () => {
    const {
      graphData,
      selectedPoint: selectedDate,
      width,
      xTitle,
      yTitle,
    } = this.props
    const { tooltipValue } = this.state

    const selectedPoint = find(graphData, { x: selectedDate })

    const xLower = get(graphData[0], 'x', null)
    const xUpper = get(graphData[graphData.length - 1], 'x', null)
    const xTickValues = [xLower, xUpper]

    const yLower = get(minBy(graphData, 'y'), 'y')
    const yUpper = get(maxBy(graphData, 'y'), 'y')
    const yTickValues = [yLower, yUpper]

    if (graphData.length > 5) {
      xTickValues.splice(
        1,
        0,
        get(graphData[Math.round(graphData.length / 2)], 'x')
      )

      const yMid = (yUpper - yLower) / 2
      yTickValues.splice(1, 0, Math.round(yMid / 5) * 5)
    }

    return (
      <XYPlot
        height={400}
        margin={{ left: 70, right: 40, top: 20, bottom: 40 }}
        onClick={this.handleSelectPoint}
        onMouseLeave={this.forgetTooltipValue}
        width={width}
        xType="ordinal"
      >
        <XAxis
          style={{ text: styles.axisLabelText }}
          tickFormat={(val) => moment(val).format(DateDisplayFormat)}
          tickValues={xTickValues}
          title={xTitle}
        />
        <YAxis
          style={{ text: styles.axisLabelText }}
          tickFormat={(val) => parseInt(val, 10)}
          tickValues={yTickValues}
          title={yTitle}
        />
        <LineMarkSeries
          data={graphData}
          lineStyle={styles.lineGraph}
          markStyle={styles.markGraph}
          onNearestX={this.setTooltipValue}
        />

        {selectedPoint &&
          (() => [
            <MarkSeries
              data={[selectedPoint]}
              key="selectedMark"
              style={{
                ...styles.markGraph,
                fill: colors.blue00,
                pointerEvents: 'none',
              }}
            />,
            <Hint
              align={{ horizontal: 'auto', vertical: 'top' }}
              key="selectedMarkTip"
              value={selectedPoint}
            >
              <Tooltip
                containerStyles={styles.selectedTooltipContainer}
                xyObj={selectedPoint}
              />
            </Hint>,
          ])()}

        {tooltipValue && (
          <Hint
            value={tooltipValue}
            align={{ horizontal: 'auto', vertical: 'top' }}
          >
            <Tooltip xyObj={tooltipValue} />
          </Hint>
        )}
      </XYPlot>
    )
  }

  render() {
    const { graphData, graphTitle } = this.props
    const hasData = graphData && graphData.length > 0

    return (
      <div style={styles.container}>
        <div style={styles.headerContainer}>
          <span style={styles.titleText}>{graphTitle}</span>

          {!hasData && (
            <span style={styles.titleText}>
              No data available for the selected timeframe
            </span>
          )}
        </div>

        {hasData && this.renderPlot()}
      </div>
    )
  }
}

// ******
// Styles
// ******
const styles = {
  container: {
    borderRadius: '7px',
    backgroundColor: colors.white00,
    cursor: 'pointer',
    boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.07)',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '22px 40px',
  },
  titleText: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    letterSpacing: '.94px',
    fontWeight: 'bold',
    color: colors.grey01,
  },
  lineGraph: {
    opacity: 1,
    stroke: colors.blue02,
  },
  markGraph: {
    fill: colors.blue01,
    opacity: 1,
    stroke: colors.blue02,
  },
  selectedTooltipContainer: {
    opacity: 1,
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '3px',
    padding: '5px 5px',
    backgroundColor: colors.blue00,
  },
  tooltipText: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '1.21',
    color: colors.white00,
  },
  bold: {
    fontWeight: 'bold',
  },
  axisLabelText: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '.94px',
    fontWeight: 'bold',
    fill: colors.grey00,
  },
}

// *********
// PropTypes
// *********
Tooltip.propTypes = {
  xyObj: PropTypes.object,
}

AnalyticsAssetGraph.propTypes = {
  xTitle: PropTypes.string,
  yTitle: PropTypes.string,
  width: PropTypes.number,
  graphTitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onSelectPoint: PropTypes.func,
  graphData: PropTypes.arrayOf(PropTypes.object),
}

export default makeWidthFlexible(AnalyticsAssetGraph)
