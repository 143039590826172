import React from 'react'
import { styles } from './styles'
import Title from 'antd/lib/typography/Title'

const PdfLoadingError = () => (
  <div
    style={{
      flexDirection: 'column',
      ...styles.center,
      ...styles.fill,
    }}
  >
    <Title level={2} style={{ color: 'white', padding: '0 80px' }}>
      Sorry, this article couldn&apos;t be displayed.
    </Title>
    <Title level={4} style={{ color: 'lightgray', padding: '0 80px' }}>
      If this problem persists, use the help tab in the top right to make sure
      we are aware of it.
    </Title>
  </div>
)

const PdfView = ({ asset }) => (
  <div className="assetdetail__preview" style={{ ...styles.fill }}>
    <object
      key={asset.id}
      data={asset.url + '?#toolbar=0&navpanes=0&scrollbar=0'}
      type="application/pdf"
      style={{
        ...styles.fill,
        ...styles.center,
      }}
    >
      <PdfLoadingError />
    </object>
  </div>
)

export default PdfView
