import { Select } from 'antd'
import React from 'react'

const SortControl = (props) => {
  const Option = Select.Option
  const sortClass =
    props.sortKey.type === 'text' ? 'fa-sort-alpha' : 'fa-sort-numeric'
  const sortDir = props.sortDirection > 0 ? '-asc' : '-desc'

  return (
    <div className="library__controls library__controls-set">
      <Select
        defaultValue={props.sortKey.value}
        onChange={props.onSortChange}
        dropdownMatchSelectWidth={false}
      >
        {Object.keys(props.options).map((opt) => (
          <Option
            key={props.options[opt].value}
            value={props.options[opt].value}
          >
            {props.options[opt].displayValue}
          </Option>
        ))}
      </Select>
      &nbsp;
      <i
        className={`fa fa-2x ${sortClass}${sortDir}`}
        onClick={() => {
          props.toggleSortDir()
          props.onSortChange(props.sortKey.value)
        }}
      />
    </div>
  )
}

export default SortControl
