import React from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import {
  DND_ASSET,
  dragCollect,
  dropCollect,
  folderSource,
  folderTarget,
} from '../../dux/modules/DragDrop'

class DraggableNode extends React.Component {
  componentDidMount() {
    const { connectDragPreview } = this.props
    const img = new Image()
    img.src = '/folder_preview.png'
    img.onload = () => connectDragPreview(img)
  }
  saveNodeRef = (ref) => (this.node = ref)

  render() {
    const {
      node,
      canDrop,
      children,
      folderDragData,
      connectDragSource,
      connectDropTarget,
    } = this.props

    let cssSuffix =
      folderDragData.over === node.id && canDrop
        ? '-over'
        : folderDragData.above === node.id && canDrop
        ? '-near'
        : folderDragData.below === node.id && canDrop
        ? '-near'
        : ''

    return connectDragSource(
      connectDropTarget(
        <div
          className={`draggable-node${cssSuffix}`}
          style={{
            borderTop:
              folderDragData.above === node.id ? '4px solid #1890ff' : null,
            borderBottom:
              folderDragData.below === node.id ? '4px solid #1890ff' : null,
          }}
        >
          {React.cloneElement(children, { saveNodeRef: this.saveNodeRef })}
        </div>
      )
    )
  }
}

export default DropTarget(
  (props) => [`DND_FOLDER_${props.endpoint}`, DND_ASSET],
  folderTarget,
  dropCollect
)(
  DragSource(
    (props) => `DND_FOLDER_${props.endpoint}`,
    folderSource,
    dragCollect
  )(DraggableNode)
)
