import { all } from 'redux-saga/effects'
import analyticsSagas from './analytics'
import apiSagas from './api'
import appSagas from './appActions'
import assetSagas from './asset'
import buildersSagas from './builders'
import endpointSagas from './endpoint'
import folderSagas from './folder'
import invitationSagas from './invitation'
import modalSagas, * as modalActions from './modal'
import notificationSagas from './notifications'
import permissionsSagas from './permissions'
import searchSagas from './search'
import settingsSagas from './settings'
import superuserSagas from './superuser'
import userSagas from './user'
import webEndpointSagas from './webEndpoints'

export default function* rootSaga() {
  try {
    yield all([
      apiSagas,
      appSagas,
      userSagas,
      assetSagas,
      modalSagas,
      folderSagas,
      searchSagas,
      buildersSagas,
      endpointSagas,
      settingsSagas,
      superuserSagas,
      analyticsSagas(), // This is a slightly different way of instantiating the saga.
      invitationSagas,
      permissionsSagas,
      webEndpointSagas,
      notificationSagas,
    ])
  } catch (error) {
    modalActions.setMessage({
      type: 'ERROR',
      msg:
        'A problem occurred that caused BAM! to terminate. ' +
        'Please refresh your browser to restart',
    })
    modalActions.openModal('MessageModal')
  }
}
