import { Modal } from 'antd'
import React from 'react'

const ConfirmModal = (props) => {
  const {
    appState: { asyncPending },
    modal: { modalMessage, modalOpen },
    modalActions,
    modalProps: { onCancel, onOk } = {},
  } = props

  return (
    <Modal
      confirmLoading={asyncPending}
      maskClosable={false}
      onCancel={onCancel(props) || modalActions.closeModal}
      onOk={onOk(props) || modalActions.closeModal}
      title={modalMessage.title}
      visible={modalOpen}
      width={500}
    >
      {modalMessage.msg}
    </Modal>
  )
}

export default ConfirmModal
