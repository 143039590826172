import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActions from '../../dux/actions/appActions'
import * as actions from '../../dux/actions/notifications'
import { DEFAULT_SIDEBAR_WIDTH } from '../../dux/constants/app'
import Inbox from './Inbox'

class NotificationsContainer extends React.Component {
  state = {
    selectedFolder: 'messages',
    selectedMessages: this.props.notifications.messages.filter(
      (msg) => !msg.deleted
    ),
    activeMessage: 0,
  }

  componentWillMount() {
    this.props.actions.getNotifications(this.props.email)
  }

  componentDidMount() {
    this.props.appActions.toggleSidebar(DEFAULT_SIDEBAR_WIDTH)
    if (this.props.startupRoute) {
      this.props.appActions.setActiveView('inbox', 'HOME')
      this.props.appActions.setStartupRoute(null)
    }
  }

  componentWillUpdate(nextProps) {
    if (
      this.props.notifications.messages.length === 0 &&
      nextProps.notifications.messages.length > 0
    ) {
      this.setState({
        selectedMessages: nextProps.notifications.messages.filter(
          (msg) => !msg.deleted
        ),
      })
    }
  }

  onFolderSelect = (e) => {
    if (e.currentTarget.id !== this.state.selectedFolder)
      this.setState({ selectedFolder: e.currentTarget.id, activeMessage: 0 })
  }

  getSelectedMessages = () => {
    let selected = this.props.notifications.messages.sort(
      (a, b) => b.timestamp - a.timestamp
    )
    switch (this.state.selectedFolder) {
      case 'messages':
        selected = this.props.notifications.messages.filter(
          (msg) => !msg.deleted
        )
        break
      case 'unread':
        selected = this.props.notifications.messages.filter(
          (msg) => !msg.deleted && !msg.viewed
        )
        break
      case 'deleted':
        selected = this.props.notifications.messages.filter(
          (msg) => msg.deleted
        )
        break
      default:
        selected = []
    }

    return selected
  }

  onMessageSelect = (e) => {
    let msgs = this.getSelectedMessages()
    if (!msgs[e.currentTarget.id].viewed)
      this.props.actions.setMessageRead(
        this.props.email,
        msgs[e.currentTarget.id].id
      )

    this.setState({ activeMessage: parseInt(e.currentTarget.id, 10) })
  }

  onMarkUnread = (e) => {
    this.props.actions.setMessageUnread(this.props.email, e.currentTarget.id)
  }

  onMarkDeleted = (e) => {
    this.props.actions.setMessageDeleted(this.props.email, e.currentTarget.id)
  }
  onRefreshInbox = () => {
    this.props.actions.getNotifications(this.props.email, false)
  }

  render() {
    return (
      <Inbox
        allMessages={
          this.props.notifications.messages.filter((msg) => !msg.deleted).length
        }
        unreadMessages={
          this.props.notifications.messages.filter(
            (msg) => !msg.deleted && !msg.viewed
          ).length
        }
        deletedMessages={
          this.props.notifications.messages.filter((msg) => msg.deleted).length
        }
        selectedMessages={this.getSelectedMessages()}
        selectedFolder={this.state.selectedFolder}
        onFolderSelect={this.onFolderSelect}
        activeMessage={this.state.activeMessage}
        onMessageSelect={this.onMessageSelect}
        onRefreshInbox={this.onRefreshInbox}
        onMarkUnread={this.onMarkUnread}
        onMarkDeleted={this.onMarkDeleted}
        loading={this.props.asyncPending}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  startupRoute: state.appState.startupRoute,
  asyncPending: state.appState.asyncPending,
  notifications: state.notifications,
  email: state.user.email,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  appActions: bindActionCreators(appActions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsContainer)
