import { Icon, Tooltip } from 'antd'
import { round } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { Sparklines, SparklinesCurve } from 'react-sparklines'
import { colors } from '../../../../styleConstants'

const momentToDateString = (momentObj) => {
  return moment(momentObj).format('MMM-D')
}

const compactNumber = (num) => {
  const numAsInt = parseInt(num, 10)

  if (numAsInt >= 1000000000) return `${round(numAsInt / 1000000000, 1)}B`
  if (numAsInt >= 1000000) return `${round(numAsInt / 1000000, 1)}M`
  if (numAsInt >= 1000) return `${round(numAsInt / 1000, 1)}K`

  return num
}

// ****************
// React Components
// ****************
const AnalyticsSparklineChart = ({
  title,
  info,
  dateRange,
  chartData,
  onClick,
}) => {
  const { total, dataList } = chartData || {}
  const [dateStart, dateEnd] = dateRange
  const dateStartString = momentToDateString(dateStart)
  const dateEndString = momentToDateString(dateEnd)

  return (
    <div style={styles.container} onClick={onClick}>
      {/* Info Tooltip */}
      <div style={styles.headerContainer}>
        <Tooltip title={info}>
          <Icon type="info-circle" style={styles.infoIcon} />
        </Tooltip>
      </div>
      {/* Chart Title */}
      <div style={styles.subheaderContainer}>
        <span style={styles.titleText}>{title.toUpperCase()}</span>
      </div>
      {/* Content */}
      <div style={styles.contentContainer}>
        {/* Text Info */}
        <div style={styles.leftContainer}>
          {total ? (
            <span style={styles.totalText}>{compactNumber(total)}</span>
          ) : (
            <span style={styles.noDataText}>{'No Data'}</span>
          )}
        </div>
        {/* Sparkline Plot */}
        <div style={styles.rightContainer}>
          <Sparklines
            data={dataList}
            height={styles.sparklineContainer.height}
            min={0}
          >
            <SparklinesCurve
              style={styles.sparkline}
              color={styles.sparkline.color}
            />
          </Sparklines>
          {/* Date Range */}
          <span style={styles.dateText}>
            {dateStartString} - {dateEndString}
          </span>
        </div>
      </div>
    </div>
  )
}

// ******
// Styles
// ******
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    height: '170px',
    width: '335px',
    borderRadius: '7px',
    margin: '10px 20px',
    boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.07)',
    backgroundColor: 'white',
    cursor: 'pointer',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    padding: '12px 12px 0px 27px',
  },
  subheaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '0px 27px 0px 27px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
    padding: '0px 27px',
    flexGrow: 1,
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexGrow: 1,
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '140px',
    paddingBottom: '5px',
  },
  titleText: {
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 'bold',
    letterSpacing: '0.94px',
    color: colors.grey02,
  },
  totalText: {
    fontFamily: 'Roboto',
    fontSize: '44px',
    fontWeight: 'bold',
    color: colors.grey03,
    paddingTop: '0px',
  },
  noDataText: {
    fontFamily: 'Roboto',
    fontSize: '30px',
    fontWeight: 'bold',
    color: colors.grey03,
    paddingTop: '0px',
  },
  dateText: {
    display: 'flex',
    alignSelf: 'center',
    fontSize: '11px',
    fontWeight: 'bold',
    color: colors.grey04,
  },
  sparklineContainer: {
    height: 150,
  },
  sparkline: {
    color: colors.green00,
    strokeWidth: 4,
  },
  infoIcon: {
    fontSize: '20px',
    color: colors.grey03,
  },
}

// *********
// PropTypes
// *********
AnalyticsSparklineChart.propTypes = {
  title: PropTypes.string,
  info: PropTypes.string,
  dateRange: PropTypes.arrayOf(PropTypes.object),
  chartData: PropTypes.object,
  onClick: PropTypes.func,
}

export default AnalyticsSparklineChart
