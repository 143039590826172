import { Button } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { DEFAULT_MAINTENANCE_MESSAGE } from '../../dux/constants/app'
import ModalContainer from '../modals/ModalContainer'
import '../styles/maintenance.css'

const MaintenanceView = (props) => (
  <div className="maintenance__container">
    <div>
      <div className="maintenance__header">
        {props.maintenance.state === 1 ? (
          <span>
            <div>{props.maintenance.title || "We'll be right back ..."}</div>
          </span>
        ) : (
          <span>
            <div>{props.maintenance.title || "And we're back!"}</div>
          </span>
        )}
      </div>
      <div className="maintenance__message">
        {props.maintenance.message || DEFAULT_MAINTENANCE_MESSAGE}
      </div>
      {props.maintenance.state === 0 ? (
        <Button
          className="maintenance__button"
          type="primary"
          href={props.maintenance.prevPath || '/library'}
        >
          Refresh
        </Button>
      ) : null}
    </div>
    <div>
      <img
        className="maintenance__image"
        src={'https://dev.bam.zone/defaults/maintenance_img.png'}
        alt="maintenance"
      />
    </div>
    <ModalContainer />
  </div>
)

MaintenanceView.propTypes = {
  maintenance: PropTypes.object,
}

export default MaintenanceView
