import { Button, Icon, Table, Checkbox } from 'antd'
import { startCase } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { reactNodeToText } from '../../../../../../../dux/modules/utils'
import { colors } from '../../../../styleConstants'

const columnSorters = {
  daysActive: (a, b) => a.daysActive - b.daysActive,
  email: (a, b) =>
    reactNodeToText(a.email).localeCompare(reactNodeToText(b.email)),
  name: (a, b) =>
    reactNodeToText(a.name).localeCompare(reactNodeToText(b.name)),
}

/*
 * Generates the table columns collection from the first element of the
 * table data collection. Keys of that first element will be the table
 * header columns.
 */
const tableColumns = (tableData) => {
  if (!Array.isArray(tableData) || !tableData.length) return null

  let tableColumns = []
  for (let keyName of Object.keys(tableData[0])) {
    if (['key', 'userId'].includes(keyName)) continue

    tableColumns.push({
      dataIndex: keyName,
      sorter: columnSorters[keyName],
      title: startCase(keyName),
    })
  }

  return tableColumns.sort(({ dataIndex: a }, { dataIndex: b }) => {
    const columnOrder = ['name', 'email', 'daysActive']
    const aIdx = columnOrder.indexOf(a)
    const bIdx = columnOrder.indexOf(b)

    if (aIdx === bIdx) {
      return a.localeCompare(b)
    }

    return aIdx - bIdx
  })
}

// ****************
// React Components
// ****************
const AnalyticsUserTable = ({
  tableData,
  activeDate,
  onExportClick,
  onUserSelected,
  selectedUser,
  setShowInactive,
}) => {
  const titleTxt = activeDate
    ? moment(activeDate).format('MMM D, YYYY')
    : 'Activity by User'

  return (
    <div style={styles.container}>
      <div style={styles.headerContainer}>
        <span style={styles.headerText}>{titleTxt}</span>
        {activeDate ? (
          <span />
        ) : (
          <Checkbox onChange={setShowInactive}>Show Inactive Users</Checkbox>
        )}
      </div>
      <Table
        bordered={false}
        columns={tableColumns(tableData)}
        dataSource={tableData}
        footer={() => (
          <div style={styles.footerButtons}>
            <Button onClick={onExportClick} type="ghost">
              <Icon type="download-o" />
              Detailed Report
            </Button>
          </div>
        )}
        onRow={(record) => ({
          onClick: () => {
            if (record.daysActive === 0) return
            onUserSelected(record.key)
          },
        })}
        pagination={false}
        rowClassName={(record) =>
          selectedUser && record.key === selectedUser.id ? 'selected-row' : ''
        }
      />
    </div>
  )
}

// ******
// Styles
// ******
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    marginTop: '20px',
    padding: '20px',
    borderRadius: '7px',
    boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.07)',
    backgroundColor: colors.white00,
  },
  headerContainer: {
    padding: '10px 17px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerText: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    letterSpacing: '.94px',
    fontWeight: 'bold',
    color: colors.grey01,
  },
  footerButtons: {
    alignItems: 'center',
    display: 'flex',
  },
}

// *********
// PropTypes
// *********
AnalyticsUserTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object),
  activeDate: PropTypes.number,
  onClearSelection: PropTypes.func,
}

export default AnalyticsUserTable
