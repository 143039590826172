import { Col, Form, Icon, Input, Modal, Row } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import FileUploader from '../library/FileUploader'
import '../styles/Modal.css'
import '../styles/UserProfile.css'

class EditProfileModal extends React.Component {
  state = {
    showPassword: false,
  }

  componentDidMount() {
    this.props.userActions.setProfilePreview(this.props.user.profileUrl)
  }

  togglePassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }))
  }

  toggleUploader = () => {
    this.props.appActions.showProfileUploader(
      !this.props.appState.showProfileUploader
    )
  }

  update = () => {
    this.form.validateFields((err, values) => {
      if (!err) {
        const { user, userActions } = this.props

        if (user.name !== values.name) {
          userActions.updateUserProfile(user.email, {
            name: values.name,
            profileUrl: user.profilePreview,
          })
        }

        if (values.password) {
          this.props.userActions.changePassword(
            this.props.user.email,
            values.oldpassword,
            values.password
          )
        }
      }
    })
  }

  saveFormRef = (ref) => (this.form = ref)
  render() {
    return (
      <Modal
        title="Edit Profile"
        okText="SAVE"
        onOk={() => this.update()}
        cancelText="Cancel"
        onCancel={this.props.modalActions.closeModal}
        visible={this.props.modal.modalOpen}
        confirmLoading={this.props.appState.asyncPending}
        maskClosable={false}
      >
        <div className="modal__body">
          <Row>
            <Col xs={24}>
              <div className="editprofile__container">
                <img
                  className="profile__avatar"
                  src={`${this.props.user.profilePreview}_thumb-240x`}
                  alt="avatar"
                />
                {this.props.appState.showProfileUploader ? (
                  <div>
                    <div className="editprofile__text">
                      Make sure your photo is at least 240px
                    </div>
                    <div className="editprofile__uploader-container">
                      <FileUploader {...this.props} />
                    </div>
                    <div className="modal__link" onClick={this.toggleUploader}>
                      Hide
                    </div>
                  </div>
                ) : (
                  <div className="modal__link" onClick={this.toggleUploader}>
                    Change
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <EditProfileForm
            name={this.props.user.name}
            email={this.props.user.email}
            ref={this.saveFormRef}
            showPassword={this.state.showPassword}
            togglePassword={this.togglePassword}
          />
        </div>
      </Modal>
    )
  }
}

const EditProfileForm = Form.create()((props) => {
  const { form } = props
  const { getFieldDecorator } = form
  const clearError = (e) => {
    if (props.form.getFieldError(e.target.id))
      props.form.setFields({ [e.target.id]: { errors: '' } })
  }

  return (
    <Form layout="vertical">
      <Form.Item label="Name" require={true}>
        {getFieldDecorator('name', {
          initialValue: props.name,
          rules: [
            {
              required: true,
              message: 'Name is required',
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Email">
        {getFieldDecorator('title', {
          initialValue: props.email,
          rules: [
            {
              required: true,
              message: 'Email is required',
            },
          ],
        })(<Input disabled />)}
      </Form.Item>
      {props.showPassword ? (
        <div>
          <Form.Item label="Old Password">
            {getFieldDecorator('oldpassword', {
              initialValue: '',
              onChange: clearError,
              rules: [
                {},
                {
                  validator(rule, value, callback) {
                    if (
                      props.form.getFieldValue('password') &&
                      value.length < 8
                    ) {
                      return callback(new Error('Old Password is required'))
                    } else return callback()
                  },
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="password"
                placeholder="Password"
              />
            )}
          </Form.Item>
          <Form.Item label="New Password">
            {getFieldDecorator('password', {
              validateTrigger: 'onBlur',
              onChange: clearError,
              rules: [
                {},
                {
                  validator(rule, value, callback) {
                    if (value && value.length < 8) {
                      return callback(
                        new Error('Password must be at least 8 characters')
                      )
                    } else return callback()
                  },
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="password"
                placeholder="New Password"
              />
            )}
          </Form.Item>
          <Form.Item label="Confirm Password">
            {getFieldDecorator('confirmpassword', {
              validateTrigger: 'onBlur',
              onChange: clearError,
              rules: [
                {},
                {
                  validator(rule, value, callback) {
                    if (
                      props.form.getFieldValue('password') &&
                      (!value ||
                        value.length < 8 ||
                        value !== props.form.getFieldValue('password'))
                    )
                      return callback(new Error('Passwords must match'))
                    else return callback()
                  },
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="password"
                placeholder="Confirm Password"
              />
            )}
          </Form.Item>
        </div>
      ) : (
        <div className="modal__link" onClick={props.togglePassword}>
          Change Password
        </div>
      )}
    </Form>
  )
})

/*
 * 'Action' props are redux action, namespaced by bindActionCreators
 * user/modal are redux state objects
 */
EditProfileModal.propTypes = {
  user: PropTypes.object,
  userActions: PropTypes.object,
  modal: PropTypes.object,
  modalActions: PropTypes.object,
}

export default EditProfileModal
