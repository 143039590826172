import React from 'react'
import TreeNode from '../../../sidebar/TreeNode'
import '../../../styles/Builders.css'

const PickerView = (props) => {
  const {
    folders,
    allAssets,
    appState,
    showAssets,
    nodeLink,
    nodeIsActive,
    nodeIsOpen,
    nodeIsDisabled,
    onNodeClick,
    treeRoot,
    activeFolder,
    toggleFolder,
    setActiveFolder,
    setActiveAsset,
    openFolderKeys,
    removeOpenFolderKey,
    style,
    assetFilter,
    displayMode,
  } = props
  return (
    <div className="builders__sider-folder-picker" style={style}>
      <div className="builders__sider-table">
        {treeRoot
          .filter((folder) => {
            return (
              folders.byId[folder].type !== 'recycle' &&
              folders.byId[folder].type !== 'virtual' &&
              folders.byId[folder].type !== 'ugc'
            )
          })
          .map((folderid) => {
            return (
              <TreeNode
                key={folderid}
                activeFolder={activeFolder}
                openFolderKeys={openFolderKeys}
                removeOpenFolderKey={removeOpenFolderKey}
                node={folders.byId[folderid]}
                nodeLink={nodeLink}
                isFolder
                nodeIsActive={nodeIsActive}
                nodeIsOpen={nodeIsOpen}
                isRoot={folders.byId[folderid].parentId ? true : false}
                nodeIsDraggable={() => false}
                rightClickDisabled={true}
                onToggle={toggleFolder}
                onNodeClick={onNodeClick}
                onAssetClick={setActiveAsset}
                nodeIsDisabled={nodeIsDisabled}
                setActiveFolder={setActiveFolder}
                menuItem={appState.menuItem}
                showAssets={showAssets}
                badges={[]}
                getBadgeCount={null}
                allAssets={allAssets}
                assetFilter={assetFilter}
                folders={folders}
                allowPublishing={() => false}
                getPendingCount={() => 0}
                displayMode={displayMode}
                style={{
                  treenodeText: {
                    color: 'rgba(0,0,0,.65)',
                  },
                  treenodeIcon: {
                    color: 'rgba(0,0,0,.65)',
                  },
                }}
              />
            )
          })}
      </div>
    </div>
  )
}

export default PickerView
