import { Badge, Tooltip } from 'antd'
import React from 'react'
import {
  RECYCLE_BIN_FOLDER_TYPE,
  RESOURCES_FOLDER_TYPE,
  UGC_FOLDER_TYPE,
  VIRTUAL_FOLDER_TYPE,
} from '../../dux/constants/folder'
import { BADGES } from '../../dux/constants/sidebar'
import { getChildren, isChild } from '../../dux/modules/utils'

const Node = (props) => {
  let icon
  if (props.node.type === RECYCLE_BIN_FOLDER_TYPE) {
    icon = <i className="fa fa-fw fa-trash" />
  } else if (props.node.type === 'explorer') {
    icon = <i className="fa fa-fw fa-cube" />
  } else if (
    props.node.type === UGC_FOLDER_TYPE ||
    props.node.type === RESOURCES_FOLDER_TYPE
  ) {
    icon = <i className="fa fa-fw fa-archive" />
  } else if (props.node.id === '_search') {
    icon = <i className="fa fa-fw fa-search" />
  } else if (props.node.type === VIRTUAL_FOLDER_TYPE) {
    icon = <i className="fa fa-fw fa-bookmark" />
  } else {
    icon = props.open ? (
      <i className="fa fa-fw fa-folder-open" />
    ) : (
      <i className="fa fa-fw fa-folder" />
    )
  }

  let cssModifier = ''
  if (props.nodeIsActive(props.node.id)) cssModifier = '-active'
  else if (props.nodeIsDisabled(props.node.id)) cssModifier = '-disabled'
  else if (props.activeKey !== props.endpointId) cssModifier = '-inactive'

  return (
    <div
      className={`node${cssModifier}`}
      ref={props.saveNodeRef}
      onClick={(e) => {
        const tempPreserveStatusFilter = e.preserveStatusFilter
        if (e.preserveStatusFilter) {
          e.preserveStatusFilter = false
        }
        if (!props.nodeIsDisabled(props.node.id)) {
          if (
            !props.nodeIsActive(props.node.id) &&
            !props.nodeIsOpen(props.node.id)
          )
            props.toggle(props.node.id)
          else if (props.nodeIsActive(props.node.id))
            props.toggle(props.node.id)
          props.onNodeClick(
            props.node.id,
            props.endpointId,
            tempPreserveStatusFilter
          )
        } else {
          props.toggle(props.node.id)
        }
      }}
    >
      <span
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (!props.nodeIsDisabled(props.node.id)) {
            if (!props.activeFolder) {
              props.onNodeClick(props.node.id, props.endpointId)
              if (!props.nodeIsOpen(props.node.id)) props.toggle(props.node.id)
            } else if (
              isChild(props.activeFolder, props.node.id, props.folders.byId)
            ) {
              props.onNodeClick(props.node.id, props.endpointId)
              props.toggle(props.node.id)
              getChildren(props.node.id, props.folders.byId)
                .filter((child) => props.openFolderKeys.indexOf(child) > -1)
                .forEach((child) => props.removeOpenFolderKey(child))
            } else {
              props.toggle(props.node.id)
            }
          } else {
            props.toggle(props.node.id)
          }
        }}
        className={`treenode-toggle${cssModifier}`}
      >
        {icon}
      </span>
      <span className={`treenode-text${cssModifier}`}>
        {props.renaming ? (
          <input
            defaultValue={props.node.name}
            onBlur={(e) => props.handleRename(e, props.node.id)}
            onKeyUp={(e) => props.handleRename(e, props.node.id)}
            style={{ width: 100, color: 'rgba(0, 0, 0, 0.85)' }}
            autoFocus
          />
        ) : (
          props.node.name
        )}
      </span>
      {
        <span className={`treenode-badge-container${cssModifier}`}>
          {props.badges.map((badge) => {
            return (
              <Tooltip
                key={badge}
                placement={BADGES[badge].placement}
                title={`${props.getBadgeCount(props.node.id, badge)} ${
                  BADGES[badge].tooltip
                }`}
              >
                <Badge
                  count={props.getBadgeCount(props.node.id, badge)}
                  title={null}
                  style={{
                    boxShadow: 'none',
                    minWidth: 26,
                    backgroundColor: BADGES[badge].bgColor,
                    cursor: 'pointer',
                  }}
                  offset={[0, -4]}
                  onClick={(e) => props.onBadgeClick(e, props.node.id, badge)}
                />
              </Tooltip>
            )
          })}
        </span>
      }
    </div>
  )
}

Node.defaultProps = {
  saveNodeRef: () => {},
}
export default Node
