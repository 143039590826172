import { get, pick } from 'lodash'
import { Tag } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAnalyticsShareData } from '../../../../../../dux/actions/analytics'
import * as helpers from '../../../helpers'
import AnalyticsAssetGraph from '../Plots/AnalyticsAssetGraph'
import AnalyticsAssetTable from '../Plots/AnalyticsAssetTable'

const dateStr = (momentObj) => {
  return momentObj.format('YYYYMMDD')
}

// ****************
// React Components
// ****************
class ShareDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedAssetRecord: null,
      selectedDate: null,
      showInactive: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { dateRange } = this.props

    if (
      dateStr(dateRange[0]) !== dateStr(prevProps.dateRange[0]) ||
      dateStr(dateRange[1]) !== dateStr(prevProps.dateRange[1])
    ) {
      this.clearSelectedDate()
      this.setState({ selectedAssetRecord: null })
    }
  }

  clearSelectedDate = () => {
    this.setSelectedDate(null)
  }

  getTableData = () => {
    const {
      activeAnalyticsPlatform,
      allAssets,
      allData,
      allUsers,
      dateRange,
      shareData,
    } = this.props
    const { selectedAssetRecord, selectedDate, showInactive } = this.state
    const selectedAssetShareData = Object.values(
      pick(
        shareData,
        get(selectedAssetRecord, 'sharesList', []).map((e) => e.eventId)
      )
    )

    return helpers.formatDataForAssetTable({
      allAssets,
      allUsers,
      bamPlatform: activeAnalyticsPlatform,
      dataObj: allData,
      dateRange: dateRange,
      eventType: 'shares',
      selectedAsset: get(selectedAssetRecord, 'assetId'),
      selectedAssetShareData,
      selectedDate,
      showInactive,
    })
  }

  onExport = () => {
    // fetch data for export
    const {
      allData,
      allUsers,
      allAssets,
      dateRange,
      activeAnalyticsPlatform,
      shareData,
    } = this.props
    const { selectedAssetRecord, selectedDate } = this.state

    this.props.exportTable(
      helpers.gatherAssetDataForExport({
        dataObj: allData,
        eventType: 'assetShares',
        allAssets,
        allUsers,
        shareData,
        bamPlatform: activeAnalyticsPlatform,
        dateRange: selectedDate || dateRange,
        selectedAsset: get(selectedAssetRecord, 'assetId'),
      })
    )
  }

  onRowClick = (record) => {
    if (record.shares === 0) return

    const shareEvents = get(record, 'sharesList', []).map((e) => e.eventId)

    this.props.getAnalyticsShareData(shareEvents)
    this.setState({ selectedAssetRecord: record })
  }

  clearAssetFilter = () => {
    this.setState({ selectedAssetRecord: null })
  }

  setSelectedDate = (date) => {
    if (this.state.selectedDate === date) {
      this.setState({ selectedDate: null })
    } else {
      this.setState({ selectedDate: date })
    }
  }

  setShowInactive = ({ target }) => {
    this.setState({ showInactive: target.checked })
  }

  render() {
    const { activeAnalyticsPlatform, allData, allAssets, dateRange } =
      this.props

    if (!allData) {
      return <div style={styles.container} />
    }

    const { selectedAssetRecord, selectedDate } = this.state
    const selectedAsset = get(selectedAssetRecord, 'assetId')
    const validAssetIds = Object.keys(allAssets)
    const barGraphData = helpers.formatDataForBarGraph({
      bamPlatform: activeAnalyticsPlatform,
      dataObj: allData,
      dateRange: dateRange,
      selectedAsset,
      validAssetIds: validAssetIds,
    })
    const assetTableData = this.getTableData()
    const assetName = selectedAsset
      ? allAssets[selectedAsset].title || allAssets[selectedAsset].filename
      : ''
    const dateTxt = moment(selectedDate).format('MMM D, YYYY')
    const titleTxt = selectedAsset ? 'Shares by User' : 'Shares by Asset'
    const dateTag = !selectedDate ? null : (
      <Tag
        key={dateTxt}
        closable={true}
        style={styles.tagStyle}
        onClose={() => this.clearSelectedDate()}
      >
        {dateTxt}
      </Tag>
    )
    const assetTag = !selectedAsset ? null : (
      <Tag
        key={assetName}
        closable={true}
        style={styles.tagStyle}
        onClose={this.clearAssetFilter}
      >
        {assetName}
      </Tag>
    )

    return (
      <div style={styles.container}>
        <div style={styles.filterTags}>
          {dateTag}
          {assetTag}
        </div>
        <AnalyticsAssetGraph
          graphData={barGraphData}
          graphTitle={'Shares by Day'}
          onSelectPoint={this.setSelectedDate}
          selectedPoint={selectedDate}
          xTitle={'Asset'}
          yTitle={'Number of Shares'}
        />
        <AnalyticsAssetTable
          onClearSelection={this.clearSelectedDate}
          onExportClick={this.onExport}
          onRowClick={this.onRowClick}
          selectedDate={selectedDate}
          selectedAsset={selectedAsset}
          setShowInactive={this.setShowInactive}
          titleTxt={titleTxt}
          tableData={assetTableData}
        />
      </div>
    )
  }
}

// ******
// Styles
// ******
const styles = {
  container: {
    position: 'relative',
  },
  tagStyle: {
    marginBottom: 10,
    cursor: 'auto',
    color: '#1890ff',
    fontWeight: 'bold',
  },
  filterTags: { marginTop: -10 },
}

// *********
// PropTypes
// *********
ShareDetail.propTypes = {
  activeAnalyticsPlatform: PropTypes.string,
  allData: PropTypes.object,
  allUsers: PropTypes.object,
  allAssets: PropTypes.object,
  dateRange: PropTypes.arrayOf(PropTypes.object),
  getAnalyticsShareData: PropTypes.func,
  shareData: PropTypes.object,
}

const mapState = (state) => ({
  shareData: state.analytics.shareDataByShareId,
})

const mapDispatch = (dispatch) => ({
  getAnalyticsShareData: (shareIds) =>
    dispatch(getAnalyticsShareData(shareIds)),
})

export default connect(mapState, mapDispatch)(ShareDetail)
