import * as modalActions from './modal'
import * as searchActions from './search'

export const findMissingMetadata = () => ({
  type: searchActions.SUBMIT_SEARCH,
  query: 'SYS.missingtags SYS.missingdescription SYS.missingtitle',
  page: 0,
})

export const configUserEndpoint = () => ({
  type: modalActions.OPEN_MODAL,
  modal: 'ConfigUserEndpointModal',
})
