import { Input } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

class EditableChip extends React.Component {
  state = {
    editing: false,
    inputValue: this.props.displayText,
  }

  toggleEdit = () =>
    this.setState((prevState) => ({ editing: !prevState.editing }))

  onChange = (event) => {
    event.persist()
    this.setState(() => ({
      inputValue: event.target.value,
      errorMessage: null,
    }))
  }

  saveValue = () => {
    if (this.state.inputValue.length === 0)
      return this.setState(() => ({ errorMessage: 'Input must have a value' }))

    if (this.state.inputValue.length > this.props.maxLength)
      return this.setState(() => ({
        errorMessage: `Input must be less than ${this.props.maxLength} characters`,
      }))

    this.props.callback(this.state.inputValue)
    return this.toggleEdit()
  }

  render() {
    return this.state.editing ? (
      <span>
        <Input
          value={this.state.inputValue}
          onChange={this.onChange}
          className="modal__titlechip-editing"
          onPressEnter={this.saveValue}
          onBlur={this.saveValue}
          autoFocus
        />
        <span className="modal__titlechip-error">
          {this.state.errorMessage}
        </span>
      </span>
    ) : (
      <span
        className={
          this.state.editing
            ? 'modal__titlechip-editing'
            : 'modal__titlechip__active'
        }
      >
        {this.props.displayText}{' '}
        {!this.props.disabled ? (
          <i className="fa fa-pencil" onClick={this.toggleEdit} />
        ) : null}
      </span>
    )
  }
}

EditableChip.propTypes = {
  displayText: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  maxLength: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
}

export default EditableChip
