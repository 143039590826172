import React from 'react'
import { assetIsPublished } from '../../dux/modules/utils'
import { Icon, Modal, Tooltip } from 'antd'
import Replace from '../BAM_UI/icons/Replace'

const UploadAction = (props) => (
  <div
    className="assetdetail__preview-action"
    onClick={(e) => {
      e.stopPropagation()
      if (
        assetIsPublished(
          props.asset.id,
          props.relationships.assets,
          props.allAssets
        )
      )
        return Modal.confirm({
          title: 'Published Asset - Continue?',
          onOk: () => {
            if (!props.originalAssetData || !props.originalAssetData.id)
              props.appActions.setEditModeAsset(props.asset.id)

            props.modalActions.openModal('UploadModal', {
              title: 'Replace Assets',
              target: 'active',
              multiple: false,
              callback: () => props.assetActions.replaceAsset(props.asset),
            })
            Promise.resolve('')
          },
          content: props.asset.publishedTo.map((folderid) => {
            return (
              <div key={folderid}>
                {props.allFolders[folderid].name} (
                {
                  props.endpoints.folderAssetStatus[folderid][props.asset.id]
                    .status
                }
                )
              </div>
            )
          }),
        })
      if (!props.originalAssetData || !props.originalAssetData.id)
        props.appActions.setEditModeAsset(props.asset.id)

      props.modalActions.openModal('UploadModal', {
        title: 'Replace Assets',
        target: 'active',
        multiple: false,
        callback: () => props.assetActions.replaceAsset(props.asset),
      })
    }}
  >
    <Tooltip title="Replace Asset">
      <Icon component={Replace} />
    </Tooltip>
  </div>
)

export default UploadAction
