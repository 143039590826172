import { Button } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import Picker from '../picker/Picker'
import '../../../styles/Builders.css'

const SaveLocationForm = (props) => {
  const {
    prevAction,
    nextAction,
    nextDisabled,
    actionButtonLabel,
    setSaveFolder,
    targetSaveFolder,
    pickerNode,
    addSaveFolderKey,
    removeSaveFolderKey,
    openSaveFolderKeys,
    onCancel,
  } = props

  // BUILDER SIDER SCREEN 2 : Folder/Preview/Save
  return (
    <div className="builders__sider-form">
      <div className="builders__sider-form-content">
        <div className="builders__sider-header-container">
          <div className="builders__sider-header-icon" onClick={prevAction}>
            <i className="fa fa-long-arrow-left" />
          </div>
          <div className="builders__sider-header-text ">Save To</div>
        </div>
        <div className="builders__sider-form-label">
          Select a folder from the Library
        </div>
        <div>
          <Picker
            setActiveFolder={setSaveFolder}
            activeFolder={targetSaveFolder}
            addOpenFolderKey={addSaveFolderKey}
            activeNode={pickerNode}
            removeOpenFolderKey={removeSaveFolderKey}
            openFolderKeys={openSaveFolderKeys}
            privilegedRole={'createfeature'}
          />
        </div>
      </div>
      <div className="builders__sider-button-container">
        <span className="builders__button">
          <Button onClick={onCancel}>Cancel</Button>
        </span>
        <span className="builders__button">
          <Button type="primary" disabled={nextDisabled} onClick={nextAction}>
            {actionButtonLabel}
          </Button>
        </span>
      </div>
    </div>
  )
}

SaveLocationForm.propTypes = {
  prevAction: PropTypes.func,
  nextAction: PropTypes.func,
  nextDisabled: PropTypes.bool,
  actionButtonLabel: PropTypes.string,
  setSaveFolder: PropTypes.func,
  targetSaveFolder: PropTypes.string,
  pickerNode: PropTypes.string,
  addSaveFolderKey: PropTypes.func,
  removeSaveFolderKey: PropTypes.func,
  openSaveFolderKeys: PropTypes.arrayOf(PropTypes.string),
  onCancel: PropTypes.func,
}

export default SaveLocationForm
