import PropTypes from 'prop-types'
import React, { Component } from 'react'
import moment from 'moment'
import * as helpers from '../../../helpers'
import AnalyticsAssetGraph from '../Plots/AnalyticsAssetGraph'
import AnalyticsAssetTable from '../Plots/AnalyticsAssetTable'
import { Tag } from 'antd'

const dateStr = (momentObj) => {
  return momentObj.format('YYYYMMDD')
}

class Calculations extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedCalculator: null,
      selectedDate: null,
      showInactive: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { dateRange } = this.props

    if (
      dateStr(dateRange[0]) !== dateStr(prevProps.dateRange[0]) ||
      dateStr(dateRange[1]) !== dateStr(prevProps.dateRange[1])
    ) {
      this.clearSelectedDate()
    }
  }

  clearSelectedDate = () => {
    this.setSelectedDate(null)
  }

  getTableData = () => {
    const {
      allData,
      allUsers,
      dateRange,
      activeAnalyticsPlatform,
      allCalculators,
    } = this.props
    const { selectedCalculator, selectedDate, showInactive } = this.state

    const tableData = helpers.formatDataForCalculatorsTable({
      allUsers,
      bamPlatform: activeAnalyticsPlatform,
      dataObj: allData,
      dateRange: dateRange,
      eventType: 'calculations',
      selectedDate,
      showInactive,
      selectedCalculator,
      allCalculators,
    })

    return tableData
  }

  onRowClick = (record) => {
    if (record.views === 0 || !!record.email) return
    this.setState({ selectedCalculator: record.key })
  }

  onExport = () => {
    // fetch data for export
    const {
      allData,
      allUsers,
      dateRange,
      activeAnalyticsPlatform,
      allCalculators,
    } = this.props
    const { selectedCalculator, selectedDate } = this.state

    this.props.exportTable(
      helpers.gatherCalculatorDataForExport({
        dataObj: allData,
        eventType: 'calculations',
        allUsers,
        bamPlatform: activeAnalyticsPlatform,
        dateRange: selectedDate || dateRange,
        allCalculators,
        selectedCalculator,
      })
    )
  }

  onExportCalculations = (calcId) => {
    // fetch data for export
    const {
      allCalculators,
      allData,
      allUsers,
      dateRange,
      activeAnalyticsPlatform,
    } = this.props
    const { selectedCalculator, selectedDate } = this.state

    const calculationsExportData = helpers.gatherCalculationsForExport({
      dataObj: allData,
      eventType: 'calculations',
      allUsers,
      bamPlatform: activeAnalyticsPlatform,
      dateRange: selectedDate || dateRange,
      allCalculators,
      calculator: selectedCalculator || calcId,
    })

    this.props.exportCalculations(calculationsExportData)
  }

  setSelectedDate = (date) => {
    if (this.state.selectedDate === date) {
      this.setState({ selectedDate: null })
    } else {
      this.setState({ selectedDate: date })
    }
  }

  setShowInactive = ({ target }) => {
    this.setState({ showInactive: target.checked })
  }

  clearAssetFilter = () => {
    this.setState({ selectedCalculator: null })
  }

  render() {
    const { allData, dateRange, activeAnalyticsPlatform, allCalculators } =
      this.props

    if (!allData) {
      return <div style={styles.container} />
    }

    const { selectedCalculator, selectedDate } = this.state
    const selectedCalculatorInfo = allCalculators[selectedCalculator]
    const barGraphData = helpers.formatDataForBarGraph({
      bamPlatform: activeAnalyticsPlatform,
      dataObj: allData,
      dateRange,
      selectedCalculator,
      allCalculators,
    })
    const calcTableData = this.getTableData()

    const dateTxt = moment(selectedDate).format('MMM D, YYYY')
    const dateTag = !selectedDate ? null : (
      <Tag
        key={dateTxt}
        closable={true}
        style={styles.tagStyle}
        onClose={() => this.clearSelectedDate()}
      >
        {dateTxt}
      </Tag>
    )
    const calcTag = !selectedCalculator ? null : (
      <Tag
        key={selectedCalculator}
        closable={true}
        style={styles.tagStyle}
        onClose={this.clearAssetFilter}
      >
        {(selectedCalculatorInfo && selectedCalculatorInfo.title) ||
          selectedCalculator}
      </Tag>
    )

    return (
      <div style={styles.container}>
        <div style={styles.filterTags}>
          {dateTag}
          {calcTag}
        </div>
        <AnalyticsAssetGraph
          graphData={barGraphData}
          graphTitle={'Calculations by Day'}
          onSelectPoint={this.setSelectedDate}
          selectedPoint={selectedDate}
          xTitle={'Time'}
          yTitle={'Number of Views'}
        />
        <AnalyticsAssetTable
          onClearSelection={this.clearSelectedDate}
          onExportClick={this.onExport}
          onRowClick={this.onRowClick}
          showInactive={this.state.showInactive}
          selectedCalculator={selectedCalculator}
          selectedDate={selectedDate}
          titleTxt={
            selectedCalculator
              ? 'Calculations by User'
              : 'Calculations by Calculator'
          }
          customExportButtonTitle={
            (selectedCalculator || calcTableData.length === 1) &&
            'Calculations Report'
          }
          customExport={
            selectedCalculator
              ? this.onExportCalculations
              : () => {
                  console.log('Calculations Report click')
                  this.onExportCalculations(
                    calcTableData &&
                      calcTableData.length === 1 &&
                      calcTableData[0].calcId
                  )
                }
          }
          tableData={calcTableData}
        />
      </div>
    )
  }
}

const styles = {
  container: {
    position: 'relative',
  },
  tagStyle: {
    marginBottom: 10,
    cursor: 'auto',
    color: '#1890ff',
    fontWeight: 'bold',
  },
  filterTags: { marginTop: -10 },
}

Calculations.propTypes = {
  allData: PropTypes.object,
  allUsers: PropTypes.object,
  allAssets: PropTypes.object,
  dateRange: PropTypes.arrayOf(PropTypes.object),
  activeAnalyticsPlatform: PropTypes.string,
}

export default Calculations
