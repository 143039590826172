import { Tooltip } from 'antd'
import { Auth } from 'aws-amplify'
import filesize from 'filesize'
import FineUploaderS3 from 'fine-uploader-wrappers/s3'
import PropTypes from 'prop-types'
import React from 'react'
import CancelButton from 'react-fine-uploader/cancel-button'
import Dropzone from 'react-fine-uploader/dropzone'
import FileInput from 'react-fine-uploader/file-input'
import Filename from 'react-fine-uploader/filename'
import 'react-fine-uploader/gallery/gallery.css'
import ProgressBar from 'react-fine-uploader/progress-bar'
//import PauseResumeButton from 'react-fine-uploader/pause-resume-button'
import RetryButton from 'react-fine-uploader/retry-button'
import Status from 'react-fine-uploader/status'
import '../styles/fineUploaderOverrides.css'

export default class FileUploader extends React.Component {
  constructor(props) {
    super(props)
    //only allow multiple files if it is not a user asset upload
    this.state = {
      showModal: props.showModal || false,
      submittedFiles: props.files || [],
      titleTemplate: '',
      tokenExpired: false,
      token: null,
      message: '',
      description: '',
      tags: '',
      uploadProgress: {},
      totalSize: 0,
      successfulUploads: 0,
    }
    this.getAssetTitle = (template, idx, fileName) => {
      return template.replace('[N]', idx).replace('[F]', fileName)
    }

    // Return an Authorization header with a valid token from state
    this.getCustomHeaders = () => ({
      Authorization: this.state.token,
    })

    // Save token to state because Auth.currentSession is async
    this.saveToken = async () => {
      try {
        const session = await Auth.currentSession()
        this.setState(() => ({
          token: session.idToken.jwtToken,
          tokenExpired: false,
        }))
      } catch (error) {
        this.props.userActions.sessionTimeout(null, null, false, null)
      }
    }

    this.currentProgress = () => {
      return (
        Object.keys(this.state.uploadProgress).reduce((result, key) => {
          result = result + this.state.uploadProgress[key]
          return result
        }, 0) || 0
      )
    }

    this.checkToken = () => {
      return new Promise((resolve, reject) => {
        if (!this.state.tokenExpired) {
          return resolve({})
        }
        Auth.currentSession()
          .then(() => {
            return resolve(true)
          })
          .catch(() => {
            return reject(false)
          })
      })
    }

    this.refreshCredentials = async () => {
      try {
        const session = await Auth.currentSession()
        this.setState({
          tokenExpired: false,
          token: session.idToken.jwtToken,
        })

        return {}
      } catch (err) {
        console.log('refreshCredentials error', err)
        this.props.userActions.sessionTimeout(null, null, false, null)

        throw err
      }
    }

    /* Updates the number of bytes loaded so far */
    this.onProgress = (id, name, chunkBytes) => {
      this.setState((prevState) => {
        const newProgress = {
          ...prevState.uploadProgress,
          [id]: chunkBytes,
        }
        return { uploadProgress: newProgress }
      })
    }

    /* Sets a token expired flag if the error is token related */
    this.onError = (id, name, errorReason) => {
      //console.log(errorReason)
      if (errorReason.includes('invalid extension'))
        return this.setState(() => ({ message: errorReason }))

      let tokenExp = this.state.tokenExpired
      if (
        errorReason === 'token expired' ||
        errorReason === 'Problem signing the chunk!'
      ) {
        tokenExp = true
      }

      this.setState(() => ({
        tokenExpired: tokenExp,
        submittedFiles: this.state.submittedFiles.map((fileobj) =>
          fileobj.id !== id
            ? fileobj
            : {
                ...fileobj,
                errorReason,
              }
        ),
      }))
    }

    /* Adds the size of the file to the submittedFiles state object */
    this.onSubmit = (id, name) => {
      let submittedFiles = [...this.state.submittedFiles]
      let size = 0
      this.state.batch.forEach((file) => {
        if (file.name === name) size = file.size
      })
      submittedFiles.push({
        id,
        filename: name,
        size,
      })
      this.setState({ submittedFiles })
    }

    /* Removes file from submitted files; updates total size */
    this.onCancel = (id) => {
      return this.setState(
        (prevState) => ({
          uploadProgress: { ...prevState.uploadProgress, [id]: 0 },
          submittedFiles: prevState.submittedFiles.filter(
            (fileobj) => fileobj.id !== id
          ),
          totalSize: this.getTotalSize(),
        }),
        () => {
          //Enable continue button if all uploads are done
          const { status } = this.uploaderInstance.qq
          const uploads = this.uploaderInstance.methods
            .getUploads({
              status: [
                status.SUBMITTING,
                status.SUBMITTED,
                status.QUEUED,
                status.UPLOADING,
                status.UPLOAD_FINALIZING,
                status.UPLOAD_RETRYING,
                status.PAUSED,
              ],
            })
            .filter((file) => file.id !== id)
          if (this.state.successfulUploads > 0 && uploads.length === 0)
            this.props.appActions.enableUploadContinue(true)
        }
      )
    }

    this.onStatusChange = (id, oldStatus, newStatus) => {
      return this.setState((prevState) => {
        let newProgress = { ...prevState.uploadProgress }
        if (newStatus === 'upload failed') {
          delete newProgress[id]
        }
        return {
          submittedFiles: prevState.submittedFiles.map((fileobj) =>
            fileobj.id === id ? { ...fileobj, status: newStatus } : fileobj
          ),
          totalSize: this.getTotalSize(), //newSize,
          uploadProgress: newProgress,
        }
      })
    }

    this.onManualRetry = (id) => {
      return this.setState((prevState) => ({
        submittedFiles: prevState.submittedFiles.map((fileobj) => {
          if (fileobj.id === id) fileobj.errorReason = null
          return fileobj
        }),
      }))
    }

    //The batch has been validated.
    //Capture the names and sizes of each file in the batch
    this.validateBatchHandler = (batch) => {
      return new Promise((resolve, reject) => {
        this.refreshCredentials()
          .then(() => {
            const filteredBatch = batch.filter((file) => {
              const dupes = this.state.submittedFiles.some(
                (f) => f.filename === file.name
              )
              return !dupes
            })
            let message = null
            if (filteredBatch.length !== batch.length) {
              message = 'Duplicate files excluded from batch'
            }
            if (filteredBatch.length > 0) {
              if (this.props.appState.uploadContinue) {
                this.props.appActions.enableUploadContinue(false)
              }
              let totalSize = this.getTotalSize()
              this.setState({
                totalSize,
                batch: filteredBatch,
                message,
                inProgress: true,
              })
              return resolve({})
            } else {
              this.setState({ message })
              return resolve(false)
            }
          })
          .catch((err) => {
            console.log('validate batch', err)
            return reject(err)
          })
      })
    }

    this.onUpload = () => {
      return new Promise((resolve, reject) => {
        if (this.state.tokenExpired) {
          this.refreshCredentials()
            .then(() => {
              return resolve({})
            })
            .catch((err) => {
              return reject(err)
            })
        } else {
          return resolve({})
        }
      })
    }
    this.validateFile = (file) => {
      return (
        this.state.submittedFiles.filter((f) => f.filename === file.name)
          .length === 0
      )
    }

    this.getTotalSize = () => {
      const { status } = this.uploaderInstance.qq
      const uploads = this.uploaderInstance.methods.getUploads({
        status: [
          status.SUBMITTING,
          status.SUBMITTED,
          status.QUEUED,
          status.UPLOADING,
          status.UPLOAD_FINALIZING,
          status.UPLOAD_RETRYING,
          status.UPLOAD_SUCCESSFUL,
          status.DELETE_FAILED,
          status.PAUSED,
        ],
      })
      return uploads.reduce((result, file) => {
        result = result + file.size
        return result
      }, 0)
    }
    //Occurs after each file has been uploaded.
    //Capture s3-specific metadata about the newly uploaded file.
    this.uploadCompleteHandler = (id, name, responseJSON) => {
      //TODO - Figure out handling for unsuccessful upload attempts
      if (responseJSON.success) {
        this.setState((prevState) => {
          //do not mutate the files collection!
          return {
            submittedFiles: prevState.submittedFiles.map((fileObj) => {
              return fileObj.filename === name
                ? {
                    id,
                    // These properties are set in edit asset modal
                    title: this.getAssetTitle(
                      this.state.titleTemplate,
                      id,
                      fileObj.name
                    ),
                    description: '', //this.state.description,
                    tags: [], //this.state.tags && this.state.tags.split('/'),
                    // These properties are returned by the uploader
                    filename: fileObj.filename,
                    key: this.uploaderInstance.methods.getKey(id),
                    url: `${
                      this.props.config.baseUrl
                    }/${this.uploaderInstance.methods.getKey(id)}`,
                    size: fileObj.size,
                    asset_type: this.props.modalProps.type,
                    // these properties need to come in redux
                    owner: this.props.user.id,
                    client: this.props.user.client,
                    // lambda needs to get this from S3
                    file_type: '', //fileObj.file_type,
                  }
                : fileObj
            }), //map
          }
        }) //this
      } else {
        this.setState((prevState) => {
          return {
            submittedFiles: prevState.submittedFiles.filter(
              (fileObj) => fileObj.name !== name
            ),
            failedFiles: prevState.submittedFiles.map((fileObj) => {
              return fileObj.name === name
                ? {
                    filename: fileObj.name,
                    id,
                  }
                : fileObj
            }),
          }
        })
      }
    }

    //Once the upload batch is complete, we can show the modal
    this.allUploadsCompleteHandler = (succeeded, failed) => {
      //this.setState({ inProgress: false })
      this.setState(
        (prevState) => ({
          inProgress: false,
          successfulUploads: prevState.successfulUploads + succeeded.length,
        }),
        () => {
          if (this.props.modalProps.type === 'support') {
            this.props.modalProps.callback(succeeded, failed, [
              ...this.state.submittedFiles,
            ])
          } else if (
            this.props.modalProps.type === 'userAsset' &&
            succeeded.length > 0
          ) {
            this.props.assetActions.createUserAsset(
              { ...this.state.submittedFiles[succeeded[0]] },
              this.props.user.email
            )
          } else if (succeeded.length > 0) {
            if (this.state.successfulUploads > 0) {
              // If we've set a URL, the upload was successful
              this.props.appActions.setSuccessfulUploads(
                this.state.submittedFiles.filter((file) => file.url),
                this.props.modalProps.type
              )
              this.props.appActions.enableUploadContinue(true)
            }
          }
        }
      )
      /*
            else if (succeeded.length === 1) {
                //TODO - Figure out handling for unsuccessful upload attempts
                //this.setState({showModal: true})
                this.props.modalActions.openModal('EditAssetModal', {
                    asset: this.state.submittedFiles[0],
                    new: true,
                })
            } else if (succeeded.length > 1) {
                this.props.modalActions.openModal('BulkEditModal', {
                    assets: this.state.submittedFiles,
                    new: true,
                })
            }
            */
    }

    this.defaultKeyFunc = (fileId) =>
      `${this.props.modalProps.target}/${this.uploaderInstance.methods.getUuid(
        fileId
      )}`

    this.uploaderInstance = new FineUploaderS3({
      options: {
        objectProperties: {
          region: 'us-west-2',
          key: this.props.modalProps.keyFunc || this.defaultKeyFunc,
          host: 's3-us-west-2.amazonaws.com',
          bucket: this.props.config.bucket,
        },
        chunking: {
          enabled: true,
        },
        resume: {
          enabled: true,
        },
        retry: {
          enableAuto: false,
          maxAutoAttempts: 1,
        },
        request: {
          endpoint: `${this.props.config.assetUrl}`,
          accessKey: this.props.config.publicUploadKey,
          params: {
            'Content-Disposition': 'inline',
          },
        },
        signature: {
          endpoint: `${this.props.config.baseUrl}/api/upload/signedPolicy`,
          version: 4,
          region: 'us-west-2',
          customHeaders: this.getCustomHeaders,
        },
        validation: {
          stopOnFirstInvalidFile: false,
          allowedExtensions: this.props.imageOnly
            ? [
                'jpg',
                'jpeg',
                'png',
                'gif',
                'webp',
                'tiff',
                'tif',
                'psd',
                'heif',
                'heic',
                'svg',
              ]
            : [],
        },
        uploadSuccess: {
          customHeaders: this.getCustomHeaders,
          endpoint: `${this.props.config.baseUrl}/api/assets/uploadSuccess`,
        },
        callbacks: {
          onError: this.onError,
          onSubmit: this.onSubmit,
          onStatusChange: this.onStatusChange,
          onCancel: this.onCancel,
          onComplete: this.uploadCompleteHandler,
          onAllComplete: this.allUploadsCompleteHandler,
          onValidateBatch: this.validateBatchHandler,
          onValidate: this.validateFile,
          onCredentialsExpired: this.refreshCredentials,
          onUpload: this.checkToken,
          onUploadChunk: this.checkToken,
          onProgress: this.onProgress,
          onManualRetry: this.onManualRetry,
        },
      },
    })
  }

  componentDidMount() {
    this.saveToken()
  }

  componentWillUnmount() {
    this.uploaderInstance.methods.cancelAll()
    this.props.appActions.enableUploadContinue(false)
  }

  render() {
    const totalSize = this.getTotalSize()
    const shouldPreventUpload =
      !this.props.multiple && this.state.submittedFiles.length > 0
    const dropzoneCursorStyle = {
      cursor: shouldPreventUpload ? 'not-allowed' : 'pointer',
    }
    return (
      <div>
        <Dropzone
          className="modal__upload-dropzone"
          uploader={this.uploaderInstance}
          multiple={this.props.modalProps.multiple}
          disabled={shouldPreventUpload}
          style={dropzoneCursorStyle}
        >
          <div className={'modal__upload-dropzone-content'}>
            <div>
              <i className="fa fa-files-o" />
            </div>
            <div>Drag and drop your files here</div>
          </div>
        </Dropzone>
        <FileInput
          className={
            'modal__upload-fileinput' +
            (shouldPreventUpload ? ' modal__upload-disabled' : '')
          }
          title={shouldPreventUpload ? 'Upload limited to one file' : ''}
          multiple={this.props.modalProps.multiple}
          uploader={this.uploaderInstance}
          accept={this.props.imageOnly ? 'image/*' : null}
          disabled={shouldPreventUpload}
        >
          …or click to select a file from your computer
        </FileInput>
        <div className="modal__upload-message">{this.state.message}</div>
        <div className="modal__upload-progresstext-container">
          <div className="modal__upload-progresstext">
            {this.state.submittedFiles.length > 0
              ? this.state.inProgress
                ? `Upload Progress: ${
                    totalSize > 0
                      ? Math.floor(
                          (this.currentProgress() / this.getTotalSize()) * 100
                        )
                      : 0
                  }%`
                : `${this.state.successfulUploads} of ${this.state.submittedFiles.length} succeeded`
              : null}
          </div>
          <div className="modal__upload-progresstext-light">
            {this.state.submittedFiles.length > 0 ? (
              <span>
                {filesize(this.currentProgress())}/
                {filesize(this.getTotalSize())}
              </span>
            ) : null}
          </div>
        </div>
        <ProgressBar
          className="modal__upload-progressbar"
          uploader={this.uploaderInstance}
          hideOnComplete
        />
        <div>
          {this.state.submittedFiles.map((fileobj) => {
            const statusCss =
              fileobj.url || fileobj.status === 'upload successful'
                ? 'modal__upload-success'
                : fileobj.status === 'upload failed'
                ? 'modal__upload-failure'
                : ''
            const progressStatusCss =
              fileobj.url || fileobj.status === 'upload successful'
                ? 'modal__upload-progress-success'
                : fileobj.status === 'upload failed'
                ? 'modal__upload-progress-failure'
                : ''
            return (
              <div className="modal__upload-file" key={fileobj.id}>
                <div className="modal__upload-file-row1">
                  <Filename
                    className="modal__upload-filename"
                    id={fileobj.id}
                    uploader={this.uploaderInstance}
                  />
                  <span className="modal__upload-file-status">
                    <CancelButton
                      className="modal__upload-button"
                      id={fileobj.id}
                      uploader={this.uploaderInstance}
                    >
                      <Tooltip title="Cancel">
                        <i className="fa fa-fw fa-ban modal__upload-icon-button" />
                      </Tooltip>
                    </CancelButton>
                    <RetryButton
                      className="modal__upload-button"
                      id={fileobj.id}
                      uploader={this.uploaderInstance}
                    >
                      <Tooltip title="Retry">
                        <i className="fa fa-fw fa-refresh modal__upload-icon-button" />
                      </Tooltip>
                    </RetryButton>
                    <Status
                      id={fileobj.id}
                      className={statusCss}
                      uploader={this.uploaderInstance}
                      text={{
                        uploading: (
                          <Tooltip title="Uploading">
                            <i
                              className={
                                'fa fa-fw fa-circle-o-notch fa-spin modal__upload-icon-button'
                              }
                            />
                          </Tooltip>
                        ),
                        canceled: (
                          <Tooltip title="Canceled">
                            <i className="fa fa-fw fa-ban modal__upload-icon-button" />
                          </Tooltip>
                        ),
                        deleting: <i className="fa fa-fw" />,
                        paused: (
                          <Tooltip title="Paused">
                            <i className="fa fa-fw fa-circle-o-notch modal__upload-icon-button" />
                          </Tooltip>
                        ),
                        queued: (
                          <Tooltip title="Queued">
                            <i className="fa fa-fw fa-circle-o-notch modal__upload-icon-button" />
                          </Tooltip>
                        ),
                        retrying_upload: (
                          <Tooltip title="Retrying">
                            <i
                              className={
                                'fa fa-fw fa-circle-o-notch fa-spin modal__upload-icon-button'
                              }
                            />
                          </Tooltip>
                        ),
                        submitting: <i className="fa fa-fw" />,
                        upload_successful: (
                          <Tooltip title="Success">
                            <i className="fa fa-check-circle-o modal__upload-icon-button" />
                          </Tooltip>
                        ),
                        upload_failed: (
                          <Tooltip title="Failed">
                            <i className="fa fa-fw fa-times-circle-o modal__upload-icon-button" />
                          </Tooltip>
                        ),
                      }}
                    />
                  </span>
                </div>
                <div className="modal__upload-file-error">
                  {fileobj.errorReason}
                </div>
                <div className="modal__upload-file-row2">
                  <ProgressBar
                    className={`${progressStatusCss} modal__upload-progressbar-individual`}
                    id={fileobj.id}
                    uploader={this.uploaderInstance}
                    hideOnComplete={false}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

FileUploader.propTypes = {
  showModal: PropTypes.bool,
  onAddAssetClick: PropTypes.func,
  success: PropTypes.bool,
  files: PropTypes.array,
  activeFolder: PropTypes.string,
  onGetAssetClick: PropTypes.func,
  onGetFolderClick: PropTypes.func,
  user: PropTypes.object,
  modalProps: PropTypes.object,
  imageOnly: PropTypes.bool,
}

FileUploader.defaultProps = {
  imageOnly: false,
}
