import { Alert, Button, Modal, Progress } from 'antd'
import React from 'react'

const BulkDownloadModal = ({ appState, modal, modalActions, modalProps }) => (
  <Modal
    closable={true}
    confirmLoading={appState.asyncPending}
    footer={[
      <Button key="cancel" onClick={modalActions.closeModal}>
        Cancel
      </Button>,
      <Button
        key="ok"
        disabled={!appState.bulkDownloadLink || appState.asyncPending}
        onClick={() => {
          window.open(appState.bulkDownloadLink)
          modalActions.closeModal()
        }}
      >
        Download
      </Button>,
    ]}
    maskClosable={false}
    onCancel={modalActions.closeModal}
    title={modalProps.title || 'Bulk Download'}
    visible={modal.modalOpen}
  >
    {appState.bulkDownloadLink ? (
      <React.Fragment>
        The selected assets are now available to download.
        {modalProps.warning && (
          <Alert
            message={modalProps.warning}
            showIcon={true}
            style={{ marginTop: '1em' }}
            type="warning"
          />
        )}
      </React.Fragment>
    ) : (
      <React.Fragment>
        Please wait while your assets are bundled and made available for
        download...
        <div>
          <Progress
            percent={100}
            showInfo={false}
            status="active"
            type="line"
          />
        </div>
      </React.Fragment>
    )}
  </Modal>
)

export default BulkDownloadModal
