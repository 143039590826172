import { Auth } from 'aws-amplify'
import { isEqual } from 'lodash'
import md5 from 'md5'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import {
  applyUpdatesToComparisonObject,
  convertComparisonObjectToTable,
  convertTableToTSV,
  prepComparisonDataForTable,
} from '../../components/builders/main/utils'
import {
  COMPARISON__BUILDER_TYPE,
  FEATURE__BUILDER_TYPE,
  NAVIGATOR__BUILDER_TYPE,
  NEWS__BUILDER_TYPE,
  PRODUCT__BUILDER_TYPE,
} from '../constants/builders'
import SessionTimeoutError from '../modules/SessionTimeoutError'
import {
  buildPath,
  compareFlatArrays,
  generateBuilderEvents,
  getBuilderRefs,
  getChangedBuilderData,
  getChangedMetadata,
  getChangedReferences,
  getS3Url,
  parseBuilderAssetData,
  recordAnalyticsEvent,
  validateSysTags,
} from '../modules/utils'
import * as apiActions from './api'
import * as appActions from './appActions'
import * as assetActions from './asset'
import * as folderActions from './folder'
import * as modalActions from './modal'
import * as userActions from './user'

// Types
export const SET_KEY_FOR_BUILDER = 'SET_KEY_FOR_BUILDER'
export const RESET_BUILDER_DATA = 'RESET_BUILDER_DATA'
export const SAVE_DATA_FOR_BUILDER = 'SAVE_DATA_FOR_BUILDER'
export const REORDER_SLIDES = 'REORDER_SLIDES'
export const EDIT_BUILDER = 'EDIT_BUILDER'
export const INITIALIZE_NEWS_BUILDER = 'INITIALIZE_NEWS_BUILDER'
export const INITIALIZE_FEATURE_BUILDER = 'INITIALIZE_FEATURE_BUILDER'
export const INITIALIZE_PRODUCT_BUILDER = 'INITIALIZE_PRODUCT_BUILDER'
export const INITIALIZE_NAVIGATOR_BUILDER = 'INITIALIZE_NAVIGATOR_BUILDER'
export const INITIALIZE_COMPARISON_BUILDER = 'INITIALIZE_COMPARISON_BUILDER'

export const SET_SCREEN_FOR_BUILDER = 'SET_SCREEN_FOR_BUILDER'
export const CREATE_SLIDE_FOR_BUILDER_REQUEST =
  'CREATE_SLIDE_FOR_BUILDER_REQUEST'
export const CREATE_SLIDE_FOR_BUILDER = 'CREATE_SLIDE_FOR_BUILDER'
export const DELETE_SLIDE_FROM_BUILDER = 'DELETE_SLIDE_FROM_BUILDER'
export const REPLACE_SLIDE_FOR_BUILDER_REQUEST =
  'REPLACE_SLIDE_FOR_BUILDER_REQUEST'
export const REPLACE_SLIDE_FOR_BUILDER = 'REPLACE_SLIDE_FOR_BUILDER'
export const MAKE_SLIDE_ACTIVE = 'MAKE_SLIDE_ACTIVE'
export const EDIT_SLIDE_LABEL = 'EDIT_SLIDE_LABEL'
export const SLIDE = 'slide'
export const ASSET = 'asset'

export const ADD_HOTSPOT = 'ADD_HOTSPOT'
export const UPDATE_HOTSPOT = 'UPDATE_HOTSPOT'
export const DELETE_HOTSPOT = 'DELETE_HOTSPOT'
export const REPLACE_HOTSPOT = 'REPLACE_HOTSPOT'

export const ADD_NAVZONE = 'ADD_NAVZONE'
export const CREATE_SLIDE_FOR_ZONE = 'CREATE_SLIDE_FOR_ZONE'
export const SET_TARGET_FOR_ZONE = 'SET_TARGET_FOR_ZONE'
export const DELETE_NAVZONE = 'DELETE_NAVZONE'
export const UPDATE_NAVZONE = 'UPDATE_NAVZONE'

export const SET_IMAGE_FOR_COMPARISON_ITEM = 'SET_IMAGE_FOR_COMPARISON_ITEM'
export const UPDATE_COMPARISON_OBJECT_REQUEST =
  'UPDATE_COMPARISON_OBJECT_REQUEST'
export const UPDATE_COMPARISON_OBJECT = 'UPDATE_COMPARISON_OBJECT'

export const CREATE_BUILDER = 'CREATE_BUILDER'
export const CREATE_BUILDER_SUCCESS = 'CREATE_BUILDER_SUCCESS'
export const UPDATE_BUILDER = 'UPDATE_BUILDER'
export const UPDATE_BUILDER_SUCCESS = 'UPDATE_BUILDER_SUCCESS'
/*
 *  Set a key in the redux store for a particular builder type.
 */
export function setKeyForBuilder(builderType, keyVal) {
  return {
    type: SET_KEY_FOR_BUILDER,
    builderType,
    keyVal,
  }
}

/*
 *  Add a slide object to the slides key for a particular builder type
 */
export function createSlideForBuilder(builderType, asset) {
  return { type: CREATE_SLIDE_FOR_BUILDER_REQUEST, builderType, asset }
}

export function* watchCreateSlideForBuilder() {
  yield takeEvery(CREATE_SLIDE_FOR_BUILDER_REQUEST, createSlideForBuilderSaga)
}

export function* createSlideForBuilderSaga(action) {
  try {
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })

    const config = yield select((state) => state.config.appConfig)

    const allAssets = yield select((state) => state.assets.byId)
    let size
    if (action.asset) {
      size = yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/imgx/size`,
        init: {
          method: 'POST',
          body: JSON.stringify(allAssets[action.asset].key),
        },
      })
    } else {
      size = {
        width: 800,
        height: 600,
      }
    }
    const timestamp = yield call(Date.now)
    const id = yield call(md5, `${action.asset}${timestamp}`)
    const defaultSlide = {
      id,
      mainImage: action.asset,
      size,
    }
    if (action.builderType === PRODUCT__BUILDER_TYPE) defaultSlide.hotspots = []
    else defaultSlide.navzones = []
    yield put({
      type: CREATE_SLIDE_FOR_BUILDER,
      builderType: action.builderType,
      slideObj: defaultSlide,
    })
    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    yield put({ type: appActions.COMPLETE_ASYNC })
    console.log(error)
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    }
  }
}
/*
 * Delete a slide from the slides array (by index) for a particular builder type
 */
export function deleteSlideFromBuilder(builderType, slideId, newActiveSlide) {
  return {
    type: DELETE_SLIDE_FROM_BUILDER,
    builderType,
    slideId,
    newActiveSlide,
  }
}

/*
 * Replace background image on a slide for a particular builder type
 */
export function replaceSlideForBuilder(builderType, slideId, newImage) {
  return {
    type: REPLACE_SLIDE_FOR_BUILDER_REQUEST,
    builderType,
    slideId,
    newImage,
  }
}

export function* watchReplaceSlideForBuilder() {
  yield takeEvery(REPLACE_SLIDE_FOR_BUILDER_REQUEST, replaceSlideForBuilderSaga)
}

export function* replaceSlideForBuilderSaga(action) {
  try {
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })

    const config = yield select((state) => state.config.appConfig)

    const allAssets = yield select((state) => state.assets.byId)

    let size
    if (allAssets[action.newImage].crop) {
      size = {
        width: allAssets[action.newImage].crop.width,
        height: allAssets[action.newImage].crop.height,
      }
    } else {
      size = yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/imgx/size`,
        init: {
          method: 'POST',
          body: JSON.stringify(allAssets[action.newImage].key),
        },
      })
    }

    yield put({
      type: REPLACE_SLIDE_FOR_BUILDER,
      builderType: action.builderType,
      slideId: action.slideId,
      newImage: action.newImage,
      size,
    })
    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    yield put({ type: appActions.COMPLETE_ASYNC })
    console.log(error)
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    }
  }
}
/*
 * Set the slide object for the activeSlide key for a particular builder type
 */
export const makeSlideActiveForBuilder = (builderType, slide) => {
  return { type: MAKE_SLIDE_ACTIVE, builderType, slide }
}

/*
 * Set the label for the slide -> Useful for finding it in navigator editing
 */
export const editSlideLabel = (builderType, slideId, value) => ({
  type: EDIT_SLIDE_LABEL,
  builderType,
  slideId,
  value,
})
/*
 * Set the activeScreen for a particular builder type
 */
export function setScreenForBuilder(builderType, activeScreen) {
  return { type: SET_SCREEN_FOR_BUILDER, builderType, activeScreen }
}

/*
 *  Reset the redux store for a particular builder type.
 */
export function resetBuilderData(builderType) {
  return {
    type: RESET_BUILDER_DATA,
    builderType,
  }
}

/*
 *
 */
export const addHotspotToSlide = (builderType, slide, hotspot) => ({
  type: ADD_HOTSPOT,
  builderType,
  slide,
  hotspot,
})

export const updateHotspot = (
  builderType,
  slideId,
  hotspotId,
  newLocation
) => ({
  type: UPDATE_HOTSPOT,
  builderType,
  slideId,
  hotspotId,
  newLocation,
})

export const deleteHotspot = (builderType, hotspotId, parentId) => ({
  type: DELETE_HOTSPOT,
  builderType,
  hotspotId,
  parentId,
})

export const replaceHotspot = (builderType, activeSlideId, newHotspot) => ({
  type: REPLACE_HOTSPOT,
  builderType,
  activeSlideId,
  newHotspot,
})

export const addNavzone = (builderType, activeSlideId, navzone) => ({
  type: ADD_NAVZONE,
  builderType,
  activeSlideId,
  navzone,
})

export const setTargetForZone = (
  builderType,
  slideId,
  zoneId,
  targetType,
  targetId
) => ({
  type: SET_TARGET_FOR_ZONE,
  builderType,
  slideId,
  zoneId,
  targetType,
  targetId,
})

export const deleteNavzone = (builderType, zoneId, slideId) => ({
  type: DELETE_NAVZONE,
  builderType,
  zoneId,
  slideId,
})

export const updateNavzone = (builderType, slideId, newNavzone) => ({
  type: UPDATE_NAVZONE,
  builderType,
  slideId,
  newNavzone,
})

export const createSlideDestinationForZone = (builderType, builderData) => ({
  type: CREATE_SLIDE_FOR_ZONE,
  builderType,
  builderData,
})
export function* watchCreateSlideForZone() {
  yield takeEvery(CREATE_SLIDE_FOR_ZONE, createSlideForZoneSaga)
}

export function* createSlideForZoneSaga(action) {
  const timestamp = yield call(Date.now)
  const id = yield call(md5, timestamp)
  const newSlide = {
    id,
    mainImage: null,
    size: {
      width: 800,
      height: 600,
    },
    navzones: [],
  }
  yield put({
    type: SET_TARGET_FOR_ZONE,
    builderType: action.builderType,
    slideId: action.builderData.activeSlide.id,
    zoneId: action.builderData.activeNavzone.id,
    targetType: SLIDE,
    targetId: id,
  })
  yield put({
    type: CREATE_SLIDE_FOR_BUILDER,
    builderType: action.builderType,
    slideObj: newSlide,
  })
  yield put({ type: modalActions.CLOSE_MODAL })
}

/*
 *  Push the data from the builders.builderType Object
 *  in the redux store to the DB
 */
export function saveDataForBuilder(builderType, builderData, history) {
  return {
    type: SAVE_DATA_FOR_BUILDER,
    builderType,
    builderData,
    history,
  }
}

/*
 *  Watcher function to initiate the Save Builder Data saga
 */
export function* watchSaveBuilderData() {
  yield takeEvery(SAVE_DATA_FOR_BUILDER, saveBuilderDataSaga)
}

/*
 *  Saga that saves the builder data to the database
 */
export function* saveBuilderDataSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })

  try {
    // Refresh application state
    const data = yield call(appActions.refreshStateSaga)
    const allAssets = data.assetState.byId
    const user = yield select((state) => state.user)
    const config = yield select((state) => state.config.appConfig)

    // TODO: validate whether the state is still valid for this builder asset
    if (data) {
      try {
        yield call([Auth, Auth.currentSession])
      } catch (error) {
        const login = yield call(userActions.sessionTimeoutSaga, {})

        // If the user was unable to log back in...
        if (!login) {
          throw new SessionTimeoutError('Session timeout')
        }
      }
      // BS-1744 make sure there's always an ID in the builder
      if (!action.builderData.id) {
        const timestamp = yield call(Date.now)
        action.builderData.id = yield call(md5, timestamp)
      }

      // For comparison objects, convert the ant table to TSV format
      if (action.builderType === COMPARISON__BUILDER_TYPE) {
        const TSVData = yield call(
          convertTableToTSV,
          action.builderData.comparisonData
        )
        //Generate a signed URL to put the TSV in S3
        const key = `${action.builderData.datafileKey}`
        const body = TSVData
        const params = {
          Bucket: config.bucket,
          ContentType: 'text/tab-separated-values',
          Key: key,
        }
        const signedUrl = yield call(getS3Url, params, config)
        // Use the URL to save the TSV to S3
        // use the version with no auth header
        yield call(apiActions.fetchSaga, {
          url: signedUrl,
          init: {
            method: 'PUT',
            headers: {
              'Content-Disposition': 'attachment',
            },
            body,
          },
          s3Put: true,
        })
      }
      // Get global builder settings
      const builderSettings = yield select((state) => state.settings.builders)
      // Call util function to format the data for the particular builder type
      const assetDataObj = yield call(
        parseBuilderAssetData,
        action.builderType,
        action.builderData,
        allAssets,
        user,
        builderSettings
      )
      // Define some values here for readability
      const { assetData, assetMetaData } = assetDataObj
      const assetFilename = `${assetMetaData.title}.${action.builderType}`

      //Generate a signed URL to put the builder data in S3
      const key = `active/${assetMetaData.id}`
      const body = JSON.stringify(assetData)
      const params = {
        Bucket: config.bucket,
        ContentType: 'application/json',
        Key: key,
      }
      const signedUrl = yield call(getS3Url, params, config)

      // Use the URL to save the builder asset to S3
      // use the version with no auth header
      yield call(apiActions.fetchSaga, {
        url: signedUrl,
        init: {
          method: 'PUT',
          headers: {
            'Content-Disposition': 'attachment',
          },
          body,
        },
        s3Put: true,
      })

      // Create a new action to save builder
      const timestamp = yield call(Date.now)
      const createAction = {
        type: CREATE_BUILDER,
        asset: {
          ...assetMetaData,
          key,
          created: timestamp,
          updated: timestamp,
          deleted: false,
          version: 1,
          publishedTo: [],
          referencedBy: [],
          extension: action.builderType,
          file_type: action.builderType,
          filename: assetFilename,
          [action.builderType]: {
            ...assetData,
          },
        },
        parent: action.builderData.targetSaveFolder,
      }
      // Retrieve metadata of the file
      const metadata = yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/assets/metadata`,
        init: {
          method: 'POST',
          body: JSON.stringify({
            asset: createAction.asset,
            operation: 'create',
          }),
        },
      })
      //Set key & url based on response - ensures no duplicate uploads
      createAction.asset.key = metadata.key
      createAction.asset.url = `${config.baseUrl}/${createAction.asset.key}`
      createAction.asset.ETag = metadata.ETag
      createAction.asset.size = metadata.size

      // This function returns an array of assets whose
      // referencedBy property must be updated
      const refObjects = yield call(
        getBuilderRefs,
        action.builderType,
        createAction.asset,
        allAssets
      )
      createAction.assetRefs = refObjects.relatedAssets
      createAction.folderRefs = refObjects.relatedFolders

      // Call API to store the initial asset state and action
      const url = `${config.baseUrl}/api/assets/create/`
      const event = {
        event_type: 'CREATE_BUILDER',
        target_id: createAction.asset.id,
        user: user.id,
        timestamp,
        attributes: Object.keys(createAction.asset).map((key) => ({
          name: key,
          new_value: createAction.asset[key],
          old_value: null,
        })),
      }
      yield call(apiActions.secureFetchSaga, {
        url,
        init: {
          method: 'POST',
          body: JSON.stringify({
            asset: createAction.asset,
            parent: action.builderData.targetSaveFolder,
            userKey: action.userKey,
            action: createAction,
            event,
          }),
        },
      })
      yield put({
        type: CREATE_BUILDER_SUCCESS,
        asset: createAction.asset,
        parent: action.builderData.targetSaveFolder,
        assetRefs: createAction.assetRefs,
        folderRefs: createAction.folderRefs,
      })
      yield call(folderActions.activeFolderSaga, {
        type: folderActions.ACTIVE_FOLDER,
        id: action.builderData.targetSaveFolder,
        scroll: true,
      })
      yield put({
        type: appActions.SET_OPEN_FOLDER_KEYS,
        keyArray: action.builderData.openSaveFolderKeys,
      })
      yield call(appActions.applyLibrarySortSaga, {
        type: appActions.APPLY_LIBRARY_SORT,
        newSort: 'updated',
        sortDir: -1,
      })
      yield call(
        action.history.push,
        `/library/${action.builderData.targetSaveFolder}`
      )
      yield put({ type: appActions.SET_MENU_ITEM, item: 'library' })
      yield fork(recordAnalyticsEvent, CREATE_BUILDER, {
        asset: createAction.asset,
      })
      yield put({ type: appActions.COMPLETE_ASYNC })
    }
  } catch (error) {
    console.log(error)
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            error.clientMessage || `Unable to save ${action.builderType} item`,
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}

export const updateBuilder = (builderType, builderData, history) => ({
  type: UPDATE_BUILDER,
  builderType,
  builderData,
  history,
})

export function* watchUpdateBuilder() {
  yield takeEvery(UPDATE_BUILDER, updateBuilderSaga)
}

export function* updateBuilderSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
  try {
    const { builderType, builderData } = action
    const config = yield select((state) => state.config.appConfig)
    const allAssets = yield select((state) => state.assets.byId)
    builderData.version = parseInt(allAssets[builderData.id].version, 10)
    const user = yield select((state) => state.user)

    try {
      yield call([Auth, Auth.currentSession])
    } catch (error) {
      const login = yield call(userActions.sessionTimeoutSaga, {})

      // If the user was unable to log back in...
      if (!login) {
        throw new SessionTimeoutError('Session timeout')
      }
    }

    // For comparisons, we need to save the previous file if data is changed
    if (builderType === COMPARISON__BUILDER_TYPE) {
      const equal = yield call(
        isEqual,
        allAssets[builderData.id].comparison.comparisonObject,
        builderData.comparisonObject
      )
      if (!equal) {
        builderData.prevDatafileKey = builderData.datafileKey
        const TSVData = yield call(
          convertTableToTSV,
          action.builderData.comparisonData
        )
        //Generate a signed URL to put the TSV in S3
        const timestamp = yield call(Date.now)
        const id = yield call(md5, `${builderData.id}${timestamp}`)
        const key = `comparisons/${id}`
        builderData.datafileKey = key
        const body = TSVData
        const params = {
          Bucket: config.bucket,
          ContentType: 'text/tab-separated-values',
          Key: key,
        }
        const signedUrl = yield call(getS3Url, params, config)
        // Use the URL to save the TSV to S3
        // use the version with no auth header
        yield call(apiActions.fetchSaga, {
          url: signedUrl,
          init: {
            method: 'PUT',
            headers: {
              'Content-Disposition': 'attachment',
            },
            body,
          },
          s3Put: true,
        })

        yield call(apiActions.secureFetchSaga, {
          url: `${config.baseUrl}/api/assets/version`,
          init: {
            method: 'POST',
            body: JSON.stringify(allAssets[builderData.id]),
          },
        })
        builderData.version = builderData.version + 1
      }
    }
    // Get global builder settings
    const builderSettings = yield select((state) => state.settings.builders)
    // Call util function to format the data for the particular builder type
    const assetDataObj = yield call(
      parseBuilderAssetData,
      builderType,
      builderData,
      allAssets,
      user,
      builderSettings
    )
    // Define some values here for readability
    const { assetData, assetMetaData } = assetDataObj
    const newAssetState = {
      ...assetMetaData,
      version: builderData.version,
      [builderType]: {
        ...assetData,
      },
    }
    // Get the "before" state of the builder asset
    const prevAssetState = allAssets[builderData.id]

    // This function returns an object identifying assets/folders whose
    // referencedBy property must be updated
    const refUpdates = yield call(
      getChangedReferences,
      builderType,
      newAssetState,
      prevAssetState,
      allAssets
    )
    // This function validates missing metadata tags
    const meta = yield call(validateSysTags, {
      title: newAssetState.title,
      description: newAssetState.description,
      tags: [...newAssetState.tags],
    })
    if (!compareFlatArrays(meta.tags, newAssetState.tags))
      newAssetState.tags = meta.tags

    // This function compares title, tags, description for changes
    const metadataUpdates = yield call(
      getChangedMetadata,
      builderType,
      newAssetState,
      prevAssetState,
      allAssets,
      user.id
    )
    // This function compares builder body data for changes
    const bodyUpdates = yield call(
      getChangedBuilderData,
      builderType,
      newAssetState,
      prevAssetState
    )

    // Check if we need to save body of builder asset
    const changes = Object.keys(bodyUpdates[builderType])
    if (changes.length > 0) {
      const meta = yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/assets/replace`,
        init: {
          method: 'POST',
          body: JSON.stringify({
            key: prevAssetState.key,
            newBody: assetData,
            previousEtag: allAssets[builderData.id].ETag,
            assetId: allAssets[builderData.id].id,
          }),
        },
      })
      metadataUpdates.size = meta.size
      metadataUpdates.ETag = meta.ETag
    }

    // This function builds an events object for these changes
    const events = yield call(
      generateBuilderEvents,
      builderData.id,
      builderType,
      refUpdates,
      metadataUpdates,
      bodyUpdates,
      user.id,
      prevAssetState
    )

    action.refUpdates = refUpdates
    action.metadataUpdates = metadataUpdates
    action.bodyUpdates = bodyUpdates

    // Only pass necessary action data to cloud action
    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/state/addaction`,
      init: {
        method: 'POST',
        body: JSON.stringify({
          action: {
            type: action.type,
            builderType: action.builderType,
            builderData: {
              id: action.builderData.id,
            },
            refUpdates,
            metadataUpdates,
            bodyUpdates,
          },
          events,
        }),
      },
    })

    yield put({
      type: UPDATE_BUILDER_SUCCESS,
      builderType: action.builderType,
      builderData: {
        id: action.builderData.id,
      },
      refUpdates,
      metadataUpdates,
      bodyUpdates,
    })

    yield fork(recordAnalyticsEvent, action.type, { asset: newAssetState })

    yield put({
      type: appActions.SET_MENU_ITEM,
      item: 'library',
    })

    yield call(action.history.push, `/library/asset/${action.builderData.id}`)

    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    // Already handled
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      console.log('update builder error', error)
      // Cleanup actions
      yield put({ type: appActions.COMPLETE_ASYNC })

      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            error.clientMessage ||
            'An error occurred while updating this asset',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}

export const reorderSlides = (builderType, source, target, slide) => ({
  type: REORDER_SLIDES,
  builderType,
  source,
  target,
  slide,
})

export const setImageForComparisonItem = (builderType, activeItem, asset) => ({
  type: SET_IMAGE_FOR_COMPARISON_ITEM,
  builderType,
  activeItem,
  asset,
})

export const editBuilder = (
  builderType,
  folder,
  asset,
  path,
  onSelectImage,
  onDeleteImage,
  isRowEditing,
  setEditRow
) => ({
  type: EDIT_BUILDER,
  builderType,
  folder,
  asset,
  path,
  onSelectImage,
  onDeleteImage,
  isRowEditing,
  setEditRow,
})

export function* watchEditBuilder() {
  yield takeEvery(EDIT_BUILDER, editBuilderSaga)
}

export function* editBuilderSaga(action) {
  const allFolders = yield select((state) => state.folders.byId)
  const path = buildPath(action.folder, allFolders)
  let allAssets, tableData, comparisonData
  const builderSettings = yield select((state) => state.settings.builders)

  switch (action.builderType) {
    case NEWS__BUILDER_TYPE:
      return yield put({
        type: INITIALIZE_NEWS_BUILDER,
        builderType: action.builderType,
        asset: action.asset,
        folder: action.folder,
        path: action.path,
      })

    case FEATURE__BUILDER_TYPE:
      return yield put({
        type: INITIALIZE_FEATURE_BUILDER,
        builderType: action.builderType,
        asset: action.asset,
        folder: action.folder,
        path: action.path,
      })

    case PRODUCT__BUILDER_TYPE:
      return yield put({
        type: INITIALIZE_PRODUCT_BUILDER,
        builderType: action.builderType,
        asset: action.asset,
        folder: action.folder,
        path: action.path,
      })

    case NAVIGATOR__BUILDER_TYPE:
      return yield put({
        type: INITIALIZE_NAVIGATOR_BUILDER,
        builderType: action.builderType,
        asset: action.asset,
        folder: action.folder,
        path: action.path,
      })

    case COMPARISON__BUILDER_TYPE:
      allAssets = yield select((state) => state.assets.byId)
      // Special Case for assets that were created before comparisonObject was stored in state
      if (
        !action.asset.comparison.comparisonObject &&
        action.asset.comparison.datafileKey
      ) {
        yield call(assetActions.parseComparisonDataSaga, {
          type: assetActions.PARSE_COMPARISON_DATA_REQUEST,
          datafileKey: action.asset.comparison.datafileKey,
          onSelectImage: action.onSelectImage,
          onDeleteImage: action.onDeleteImage,
          builderData: {
            primaryClassification:
              action.asset.comparison.primaryClassification || null,
          },
          hideMessage: true,
          isRowEditing: action.isRowEditing,
          setEditRow: action.setEditRow,
        })
        return yield put({
          type: SET_KEY_FOR_BUILDER,
          builderType: COMPARISON__BUILDER_TYPE,
          keyVal: {
            datafileKey: action.asset.comparison.datafileKey,
            primaryClassification:
              action.asset.comparison.primaryClassification || null,
            targetSaveFolder: action.folder,
            pickerNode: action.folder,
            openSaveFolderKeys: action.path,
            id: action.asset.id,
            title: action.asset.title,
            leadImage: action.asset.comparison.image,
            description: action.asset.description,
            tags: action.asset.tags,
            activeScreen: 'meta',
            isEdit: true,
            useDefaultDisclaimer: builderSettings.comparisons
              .allowCustomDisclaimer
              ? action.asset.comparison.useDefaultDisclaimer
              : true,
            disclaimerText: builderSettings.comparisons.allowCustomDisclaimer
              ? action.asset.comparison.disclaimerText
              : builderSettings.globalDisclaimer,
          },
        })
        // Normal case for initializing comparison builder
      } else {
        tableData = yield call(
          convertComparisonObjectToTable,
          action.asset.comparison.comparisonObject
        )

        comparisonData = yield call(
          prepComparisonDataForTable,
          tableData.columns,
          tableData.dataSource,
          allAssets,
          action.onSelectImage,
          action.onDeleteImage,
          action.isRowEditing,
          action.setEditRow
        )

        return yield put({
          type: INITIALIZE_COMPARISON_BUILDER,
          builderType: action.builderType,
          datafileKey: action.datafileKey,
          asset: action.asset,
          classifications: tableData.classifications,
          primaryClassification: action.asset.comparison.primaryClassification,
          comparisonObject: action.asset.comparison.comparisonObject,
          comparisonData,
          folder: action.folder,
          useDefaultDisclaimer: builderSettings.comparisons
            .allowCustomDisclaimer
            ? action.asset.comparison.useDefaultDisclaimer
            : true,
          disclaimerText: builderSettings.comparisons.allowCustomDisclaimer
            ? action.asset.comparison.disclaimerText
            : builderSettings.globalDisclaimer,
          path,
        })
      }
    default:
      return null
  }
}

export const updateComparisonObject = (
  original,
  values,
  record,
  onSelectImage,
  onDeleteImage,
  isRowEditing,
  setEditRow
) => ({
  type: UPDATE_COMPARISON_OBJECT_REQUEST,
  original,
  values,
  record,
  onSelectImage,
  onDeleteImage,
  isRowEditing,
  setEditRow,
})

export function* watchUpdateComparisonObject() {
  yield takeEvery(UPDATE_COMPARISON_OBJECT_REQUEST, updateComparisonObjectSaga)
}

export function* updateComparisonObjectSaga(action) {
  const newObject = yield call(
    applyUpdatesToComparisonObject,
    action.original,
    action.values,
    action.record
  )
  const tableData = yield call(convertComparisonObjectToTable, newObject)

  const allAssets = yield select((state) => state.assets.byId)
  const comparisonData = yield call(
    prepComparisonDataForTable,
    tableData.columns,
    tableData.dataSource,
    allAssets,
    action.onSelectImage,
    action.onDeleteImage,
    action.isRowEditing,
    action.setEditRow
  )
  const keyVal = {
    comparisonData,
    comparisonObject: newObject,
    classifications: tableData.classifications,
  }
  const primary = yield select(
    (state) => state.builders.comparison.primaryClassification
  )
  if (
    action.record.attribute === 'classification' &&
    tableData.classifications.indexOf(primary) < 0
  ) {
    keyVal.primaryClassification = ''
  }

  yield put({
    type: SET_KEY_FOR_BUILDER,
    builderType: COMPARISON__BUILDER_TYPE,
    keyVal,
  })
}

export default all([
  watchSaveBuilderData(),
  watchEditBuilder(),
  watchUpdateBuilder(),
  watchCreateSlideForBuilder(),
  watchReplaceSlideForBuilder(),
  watchCreateSlideForZone(),
  watchUpdateComparisonObject(),
])
