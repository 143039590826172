import update from 'immutability-helper'
import * as actions from '../actions/notifications'

const INITIAL_STATE = {
  messages: [],
}
export default function notificationsReducer(state = INITIAL_STATE, action) {
  let index = -1
  let newMessage = {}
  switch (action.type) {
    case actions.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        messages: action.messages,
      }

    case actions.UPDATE_MESSAGE_SUCCESS:
      state.messages.forEach((msg, idx) => {
        if (msg.id === action.id) index = idx
      })
      newMessage = Object.assign({}, state.messages[index], action.update)
      return update(state, {
        messages: {
          $splice: [[index, 1, newMessage]],
        },
      })

    default:
      return state
  }
}
