import PropTypes from 'prop-types'
import React, { Component } from 'react'
import * as helpers from '../../../helpers'
import AnalyticsSparklineChart from '../Plots/AnalyticsSparklineChart'

// ****************
// React Components
// ****************
class AnalyticsOverview extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      allData,
      dateRange,
      activeAnalyticsPlatform,
      setActiveAnalyticsView,
      analyticsViews,
    } = this.props

    return (
      <div style={styles.container}>
        {/*  User Activity */}
        {analyticsViews.map((av) => {
          if (av.overview)
            return (
              <AnalyticsSparklineChart
                title={av.title}
                key={av.key}
                info={av.overviewInfo}
                dateRange={dateRange}
                chartData={helpers.formatDataForSparkChart({
                  dataObj: allData,
                  chartType: av.overview,
                  bamPlatform: activeAnalyticsPlatform,
                  dateRange: dateRange,
                })}
                onClick={() => setActiveAnalyticsView(av.key)}
              />
            )
          return null
        })}
      </div>
    )
  }
}

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
}

AnalyticsOverview.propTypes = {
  allData: PropTypes.object,
  activeAnalyticsPlatform: PropTypes.string,
  activeAnalyticsDuration: PropTypes.string,
  analyticsCustomDateRange: PropTypes.arrayOf(PropTypes.string),
  setActiveAnalyticsView: PropTypes.func,
  dateRange: PropTypes.arrayOf(PropTypes.object),
}

export default AnalyticsOverview
