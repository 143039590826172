const mutations = {
  UpdateMaintenanceMode: `mutation UpdateMaintenanceMode($input: StateSnapshotInput) {
        updateMaintenanceMode(input: $input) {
          state,
          message
        }
    }`,
}

export default mutations
