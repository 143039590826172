import { Button, Checkbox } from 'antd'
import React from 'react'
import AssetPreview from '../../../library/AssetPreview'
import Picker from '../picker/Picker'
import '../../../styles/Builders.css'

const AddSlideForm = (props) => {
  const {
    builderData,
    prevAction,
    setPickerFolder,
    setPickerAsset,
    addSaveFolderKey,
    removeSaveFolderKey,
    builderType,
    imageFilter,
    allAssets,
    onCancel,
    nextAction,
    handlePickerModeChange,
  } = props

  return (
    <div className="builders__sider-form">
      <div className="builders__sider-form-content">
        <div className="builders__sider-header-container">
          <div className="builders__sider-header-icon" onClick={prevAction}>
            <i className="fa fa-long-arrow-left" />
          </div>
          <div className="builders__sider-header-text ">
            {builderData.activeSlide.mainImage
              ? 'Replace Background Image'
              : 'Add Background Image'}
          </div>
        </div>
        <div>
          <div className="builders__sider-form-label">Slides</div>
          <div className="builders__sider-form-text">
            Select a background image from the library for the slide background.
          </div>
          <div className="builders__sider-form-text">
            <Checkbox onChange={handlePickerModeChange} /> Show asset titles
          </div>
        </div>
        <div>
          <Picker
            showAssets={true}
            activeNode={builderData.pickerNode}
            activeFolder={builderData.pickerFolder}
            assetFilter={imageFilter}
            setActiveFolder={setPickerFolder}
            setActiveAsset={setPickerAsset}
            addOpenFolderKey={addSaveFolderKey}
            removeOpenFolderKey={removeSaveFolderKey}
            openFolderKeys={builderData.openSaveFolderKeys}
            privilegedRole={'createproduct'}
            builderType={builderType}
            displayMode={builderData.pickerDisplayMode}
            style={{ height: 300 }}
            filter={imageFilter}
          />
        </div>
        <div className="builders__asset_preview_container">
          <div
            style={{ padding: '8px 0' }}
            className="builders__sider-form-label"
          >
            Preview
          </div>
          {allAssets[builderData.pickerNode] && (
            <AssetPreview
              allAssets={allAssets}
              asset={allAssets[builderData.pickerNode]}
              previewStyle="light"
              thumbnail={'350x'}
            />
          )}
        </div>
      </div>
      <div className="builders__sider-button-container">
        <span className="builders__button">
          <Button onClick={onCancel}>Cancel</Button>
        </span>
        <span className="builders__button">
          <Button
            type="primary"
            onClick={nextAction}
            disabled={!allAssets[builderData.pickerNode]}
          >
            Next
          </Button>
        </span>
      </div>
    </div>
  )
}

export default AddSlideForm
