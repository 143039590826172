import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as userActions from '../../dux/actions/user'
import ResetPasswordForm from './ResetPasswordForm'
import '../styles/ForgotPassword.css'

class ResetPassword extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault()
    this.form.validateFields((err, values) => {
      if (!err) {
        this.props.userActions.resetPassword(
          values.email.toLowerCase(),
          this.props.match.params.code,
          values.password
        )
      }
    })
  }
  saveFormRef = (ref) => (this.form = ref)

  render() {
    if (this.props.appState.resetPasswordComplete) {
      return <Redirect to="/reset-password-success" />
    }
    return (
      <ResetPasswordForm
        handleSubmit={this.handleSubmit}
        saveFormRef={this.saveFormRef}
        email={this.props.user.email}
        asyncPending={this.props.appState.asyncPending}
        errorMsg={this.props.appState.resetPasswordError}
        config={this.props.config}
      />
    )
  }
}

ResetPassword.propTypes = {
  mode: PropTypes.string,
  message: PropTypes.string,
}

const mapStateToProps = (state) => ({
  user: state.user,
  appState: state.appState,
  config: state.config.appConfig,
})

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(userActions, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
