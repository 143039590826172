import { Collapse } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { BUILDER_TYPES } from '../../../dux/constants/builders'
import { assetName } from '../../../dux/modules/utils'

const { Panel } = Collapse
class AssetRelationshipsPanel extends React.PureComponent {
  state = {
    activeKey: ['referencedBy'],
  }

  onPanelChange = (key) => this.setState({ activeKey: key })

  render() {
    return (
      <div>
        <Collapse
          className="assetdetail"
          activeKey={this.state.activeKey}
          onChange={this.onPanelChange}
        >
          <Panel
            key="referencedBy"
            showArrow={false}
            header={
              <div className="assetdetail__sub-panel-header">
                <div
                  className={
                    this.state.activeKey.includes('relationships')
                      ? 'assetdetail__panel-header-active'
                      : ''
                  }
                >
                  <i
                    className={`fa fa-caret-${
                      this.state.activeKey.indexOf('referencedBy') > -1
                        ? 'down'
                        : 'right'
                    }`}
                  />{' '}
                  Appears In
                </div>
              </div>
            }
          >
            {this.props.relationships.assets.referencedBy[
              this.props.asset.id
            ] &&
              this.props.relationships.assets.referencedBy[
                this.props.asset.id
              ].map((ref) => {
                if (this.props.allAssets[ref])
                  return (
                    <div key={ref} className="assetdetail__sub-panel-content">
                      <i className={`fa ${this.props.allAssets[ref].icon}`} />
                      <div>
                        <Link
                          to={`/library/asset/${ref}`}
                          className="assetdetail___relationship-link"
                        >
                          {assetName(this.props.allAssets[ref], 26)}
                        </Link>
                      </div>
                      <div>&nbsp;</div>
                    </div>
                  )
                return null
              })}
          </Panel>
          {BUILDER_TYPES.indexOf(this.props.asset.asset_type) > -1 && (
            <Panel
              key="relatedAssets"
              showArrow={false}
              header={
                <div className="assetdetail__sub-panel-header">
                  <div
                    className={
                      this.state.activeKey.includes('relationships')
                        ? 'assetdetail__panel-header-active'
                        : ''
                    }
                  >
                    <i
                      className={`fa fa-caret-${
                        this.state.activeKey.indexOf('relatedAssets') > -1
                          ? 'down'
                          : 'right'
                      }`}
                    />{' '}
                    Built With
                  </div>
                </div>
              }
            >
              {this.props.relationships.assets.relatedAssets[
                this.props.asset.id
              ] &&
                this.props.relationships.assets.relatedAssets[
                  this.props.asset.id
                ].map((ref) => {
                  if (this.props.allAssets[ref])
                    return (
                      <div key={ref} className="assetdetail__sub-panel-content">
                        <i className={`fa ${this.props.allAssets[ref].icon}`} />
                        <div>
                          <Link
                            to={`/library/asset/${ref}`}
                            className="assetdetail___relationship-link"
                          >
                            {assetName(this.props.allAssets[ref], 26)}
                          </Link>
                        </div>
                        <div>&nbsp;</div>
                      </div>
                    )
                  return null
                })}
              {this.props.relationships.assets.relatedFolders[
                this.props.asset.id
              ] &&
                this.props.relationships.assets.relatedFolders[
                  this.props.asset.id
                ].map((ref) => {
                  if (this.props.allFolders[ref])
                    return (
                      <div key={ref} className="assetdetail__sub-panel-content">
                        <i className="fa fa-folder-o" />
                        <div>
                          <Link
                            to={`/library/${ref}`}
                            className="assetdetail___relationship-link"
                            onClick={() => this.props.navigateToFolder(ref)}
                          >
                            {this.props.allFolders[ref].name}
                          </Link>
                        </div>
                        <div>&nbsp;</div>
                      </div>
                    )
                  return null
                })}
            </Panel>
          )}
        </Collapse>
      </div>
    )
  }
}

export default AssetRelationshipsPanel
