export const VIEW_ASSET_DETAIL = 'VIEW_ASSET_DETAIL'
export const DOWNLOAD_ASSET = 'DOWNLOAD_ASSET'
export const CREATE_ASSET = 'CREATE_ASSET'
export const SHARE_ASSET = 'SHARE_ASSET'
export const EDIT_ASSET = 'EDIT_ASSET'
export const PUBLISH_ASSET_REQUEST = 'PUBLISH_ASSET_REQUEST'
export const PUBLISH_ASSET_APPROVE = 'PUBLISH_ASSET_APPROVE'
export const PUBLISH_ASSET_REJECT = 'PUBLISH_ASSET_REJECT'
export const UNPUBLISH_ASSET_REQUEST = 'UNPUBLISH_ASSET_REQUEST'
export const UNPUBLISH_ASSET_APPROVE = 'UNPUBLISH_ASSET_APPROVE'
export const UNPUBLISH_ASSET_REJECT = 'UNPUBLISH_ASSET_REJECT'
export const REFRESH_SESSION = 'REFRESH_SESSION'
export const GENERIC_EVENT = 'GENERIC_EVENT'
export const CALCULATE = 'CALCULATE'
export const LAUNCH_CALCULATOR = 'VIEW_CALCULATOR'

export const SIDEBAR_BACKGROUND_COLOR = '#545454'
export const SIDEBAR_WIDTH = 320
export const SIDEBAR_COLLAPSED_WIDTH = 80
export const SIDEBAR_COLLAPSE_BREAKPOINT = 'md'
