import { Row } from 'antd'
import React from 'react'
import {
  DEFAULT_SIDEBAR_WIDTH,
  SCROLL_BAR_WIDTH,
} from '../../dux/constants/app'
import { getWidestPanel } from '../../dux/modules/utils'
import SidebarTrigger from '../common/SidebarTrigger'
import AssetCard from './AssetCard'
import DummyCard from './DummyCard'
import Folder404 from './Folder404'
import '../styles/Library.css'

const triggerStyle = {}
const TileView = (props) => {
  return (
    <div>
      {props.controls}
      <div className="library__row" id="asset_container">
        <SidebarTrigger
          className="bam-sider__trigger"
          show={props.appState.maxSidebarWidth > DEFAULT_SIDEBAR_WIDTH}
          onToggle={() => {
            props.appActions.toggleSidebar(
              props.appState.maxSidebarWidth > props.appState.sidebarWidth
                ? props.appState.maxSidebarWidth + SCROLL_BAR_WIDTH
                : DEFAULT_SIDEBAR_WIDTH
            )
            window.setTimeout(
              () => props.appActions.setMaxSidebarWidth(getWidestPanel()),
              225
            )
          }}
          direction={
            props.appState.maxSidebarWidth > props.appState.sidebarWidth
              ? 'right'
              : 'left'
          }
          style={triggerStyle}
        />
        <Row
          type="flex"
          gutter={{ xs: 16, sm: 16, md: 16, lg: 20, xl: 20, xxl: 24 }}
          style={{ padding: '20px 20px 20px 30px' }}
        >
          {props.show404 ? (
            <Folder404 activeFolder={props.activeFolder} />
          ) : (
            props.assets.map((asset, index) => {
              if (index < props.max) {
                if (props.allAssets[asset]) {
                  return (
                    <AssetCard
                      key={asset}
                      id={asset}
                      type={'asset'}
                      item={props.allAssets[asset]}
                      selected={props.selectedAssets.indexOf(asset) > -1}
                      {...props}
                    />
                  )
                }
                return (
                  <DummyCard
                    key={asset}
                    id={asset}
                    type={'asset'}
                    item={{
                      id: asset,
                      filename: null,
                      preview: null,
                      icon: 'fa fa-eye-slash',
                      color: '#545454',
                    }}
                    selected={false}
                    {...props}
                  />
                )
              }
              return null
            })
          )}
        </Row>
        {props.footer}
      </div>
    </div>
  )
}

export default TileView
