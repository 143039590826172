import { Button } from 'antd'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { buildPath, validatePrivilege } from '../../dux/modules/utils'
import { MAX_SHARE_LENGTH } from '../../dux/constants/folder'
import SortControl from '../common/SortControl'
import StatusFilter from '../common/StatusFilter'
import ActionIcon from './ActionIcon'
import '../styles/Library.css'

const LibraryHeader = (props) => {
  const folder = props.allFolders[props.activeFolder]
  const folderHasAssets = folder && folder.assets && folder.assets.length > 0
  const folderIsRecycleBin =
    buildPath(props.activeFolder, props.allFolders).indexOf(props.recycleBin) >=
    0
  const folderIsSearch = props.activeFolder === '_search'

  function renderSearchResults(props) {
    if (
      !folderIsSearch ||
      !props.search ||
      !props.search.searchResults ||
      props.search.searchResults.length === 0
    )
      return <Fragment />

    const resultsLoadedMsg =
      props.search.searchResults.length === 0
        ? '- No Results'
        : `${props.search.searchResults.length > 0 ? 1 : 0} - 
    ${
      props.search.searchResults.length > props.search.max
        ? props.search.max
        : props.search.searchResults.length
    } 
    of ${props.search.searchResults.length} result${
            props.search.searchResults.length !== 1 ? 's' : ''
          }`

    return (
      <span className="searchcontrols__column">
        <span className="searchcontrols__searchterm">
          &quot;{props.search.lastSearch}&quot;&nbsp;
        </span>
        {resultsLoadedMsg}
      </span>
    )
  }

  function renderActionIcon(action) {
    const validatePrivilegeByName = (name) =>
      validatePrivilege(
        name,
        props.privileges,
        props.email,
        props.groups,
        props.activeFolder,
        props.allFolders
      )
    //props to be passed to actionIcon
    let actionDisabled = null

    let actionTooltip = null
    let useAltIcon = false
    let unpublishAndApprove = false
    const tooManyMsg = `This option is only available with ${MAX_SHARE_LENGTH} or fewer assets`

    // Disable delete if one or more selected assets is published
    if (action.privilege === 'deleteasset' && props.publishedAssetSelected) {
      actionDisabled = true
      actionTooltip = 'A selected asset is published. Delete disabled'
    }

    // Disable delete if one or more selected assets are referenced in
    // builder assets
    if (action.privilege === 'deleteasset' && props.referencedAssetSelected) {
      actionDisabled = true
      actionTooltip =
        'A selected asset is referenced by another asset. Delete disabled'
    }
    // change select all action to deselect all if all assets are selected
    const assets = props.allFolders[props.activeFolder].assets
    if (
      action.action === 'selectall' &&
      props.activeFolder &&
      (assets.length === props.selectedAssets.length ||
        (props.selectedFilter !== 'ALL' &&
          assets.filter((asset) => {
            return (
              props.publishedStatus[props.activeFolder] &&
              props.publishedStatus[props.activeFolder][asset] &&
              props.publishedStatus[props.activeFolder][asset].status ===
                props.selectedFilter
            )
          }).length === props.selectedAssets.length))
    ) {
      actionTooltip = 'Deselect All'
      useAltIcon = true
    }
    // enable unpublish only if user has privileges
    if (action.action === 'unpublish') {
      unpublishAndApprove =
        validatePrivilegeByName('unpublishasset') &&
        validatePrivilegeByName('approveasset')
    }
    // disable some options if over the max number
    const numberOfSelectedAssets = props.selectedAssets.length
    const ACTIONS_WITH_MAX_SHARE_LENGTH = [
      'share',
      'download',
      'publish',
      'edit',
    ]
    if (
      ACTIONS_WITH_MAX_SHARE_LENGTH.includes(action.action) &&
      numberOfSelectedAssets > MAX_SHARE_LENGTH
    ) {
      actionDisabled = true
      actionTooltip = tooManyMsg
    }

    const selectedAssetsContainNonAssetTypes = props.selectedAssets.some(
      (assetId) => props.allAssets[assetId].asset_type !== 'asset'
    )
    const selectedAssetsContainCalculators = props.selectedAssets.some(
      (assetId) => props.allAssets[assetId].extension === 'calc'
    )
    // Calculator types currently can not be shared or downloaded,
    // since we haven't assigned them a custom asset_type we need this condition
    if (
      (action.action === 'share' || action.action === 'download') &&
      (selectedAssetsContainNonAssetTypes || selectedAssetsContainCalculators)
    ) {
      actionDisabled = true
      actionTooltip = `Some selected assets cannot be ${
        action.action === 'share' ? 'shared' : 'downloaded'
      }`
    }

    return (
      <ActionIcon
        key={action.action}
        action={action}
        folder={props.activeFolder}
        allFolders={props.allFolders}
        email={props.email}
        privileges={props.privileges}
        groups={props.groups}
        useAltIcon={useAltIcon}
        handler={props.handler({
          action: action.action,
          selectedAssets: props.selectedAssets,
          allAssets: props.allAssets,
          allFolders: props.allFolders,
          activeFolder: props.activeFolder,
          modalActions: props.modalActions,
          endpointActions: props.endpointActions,
          reviewFormRef: props.reviewFormRef,
          saveFormRef: props.saveFormRef,
          closeReviewForm: props.closeReviewForm,
          assetActions: props.assetActions,
          recycleBin: props.recycleBin,
          appActions: props.appActions,
          selectedFilter: props.selectedFilter,
          publishedStatus: props.publishedStatus,
          unpublishAndApprove,
          endpoint: props.endpoint,
          endpoints: props.endpoints,
          history: props.history,
          user: props.user,
        })}
        disabled={actionDisabled}
        tooltip={actionTooltip}
      />
    )
  }

  return (
    <div className="library-header">
      <span className="button__container library-title">
        {props.activeFolder &&
          props.allFolders[props.activeFolder] &&
          (props.allFolders[props.activeFolder].name || 'Library')}
      </span>
      {renderSearchResults(props)}
      <span className="library__controls">
        {
          /* Add Asset Button */
          !folderIsRecycleBin && !props.addButtonDisabled && (
            <Button
              className="button__container-inner"
              onClick={() =>
                props.modalActions.openModal('UploadModal', {
                  multiple: true,
                  type: 'asset',
                  target: 'active',
                  callback: props.appActions.uploadCompleteHandler,
                })
              }
              type="primary"
              disabled={props.addButtonDisabled}
              size="small"
            >
              <i className="fa fa-plus-circle" />
              &nbsp; Upload Asset
            </Button>
          )
        }
        {
          /* Bulk Actions */
          folderHasAssets &&
            props.activeFolder &&
            props.allFolders[props.activeFolder] &&
            !folderIsRecycleBin && (
              <div className="library__controls-set">
                {
                  /* # of assets selected */
                  props.selectedAssets.length > 1 && (
                    <span className="library-header__infotext">
                      <span className="library-header__infotext-hightlight">
                        {props.selectedAssets.length}
                      </span>{' '}
                      assets selected
                    </span>
                  )
                }
                {props.bulkActions
                  .filter(props.bulkActionFilter)
                  .map(renderActionIcon)}
              </div>
            )
        }
        {
          /* Status Filter for Endpoints */
          props.activeFolder &&
            props.allFolders[props.activeFolder] &&
            props.showStatusFilter(props.endpoint) &&
            folderHasAssets && (
              <StatusFilter
                options={props.appState.filterOptions}
                selectedFilter={props.selectedFilter}
                onFilterChange={props.appActions.applyStatusFilter}
              />
            )
        }
        {folderHasAssets && (
          <SortControl
            options={props.appState.sortFields}
            sortKey={props.appState.sortKey}
            sortDirection={props.appState.sortDirection}
            onSortChange={props.onSortChange}
            toggleSortDir={props.onToggleSortDir}
          />
        )}
      </span>
    </div>
  )
}

LibraryHeader.propTypes = {
  appState: PropTypes.object,
  onSortChange: PropTypes.func,
  onToggleSortDir: PropTypes.func,
  selectedAssets: PropTypes.arrayOf(PropTypes.string),
  allAssets: PropTypes.object,
  activeFolder: PropTypes.string,
  allFolders: PropTypes.object,
  recycleBin: PropTypes.string,
  endpoint: PropTypes.string,
  endpoints: PropTypes.arrayOf(PropTypes.object),
  endpointActions: PropTypes.object,
  publishedStatus: PropTypes.object,
  bulkActions: PropTypes.arrayOf(PropTypes.object),
  addButtonDisabled: PropTypes.bool,
  privileges: PropTypes.object,
  groups: PropTypes.object,
  email: PropTypes.string,
  modalActions: PropTypes.object,
  appActions: PropTypes.object,
  assetActions: PropTypes.object,
  reviewFormRef: PropTypes.object,
  saveFormRef: PropTypes.func,
  closeReviewForm: PropTypes.func,
  handler: PropTypes.func,
  publishedAssetSelected: PropTypes.bool,
  referencedAssetSelected: PropTypes.bool,
  showStatusFilter: PropTypes.func,
  selectedFilter: PropTypes.string,
  bulkActionFilter: PropTypes.func,
}

export default LibraryHeader
