import update from 'immutability-helper'
import * as appActions from '../actions/appActions'
import * as assetActions from '../actions/asset.js'
import * as builderActions from '../actions/builders'
import * as endpointActions from '../actions/endpoint'
import * as folderActions from '../actions/folder'

const initialState = {
  byId: {},
  originalAssetData: {},
}

export default function assetReducer(state, action) {
  if (typeof state === 'undefined') return initialState

  let newState
  switch (action.type) {
    /*
     *  LOAD_ASSET_STATE - loads an up-to-date state from the cloud
     */
    case assetActions.LOAD_ASSET_STATE: {
      return {
        ...state,
        byId: action.payload.byId,
        relationships: action.payload.relationships,
        newsById: action.payload.newsById,
      }
    }

    case assetActions.UPDATE_ASSET_SUCCESS: {
      return update(state, {
        byId: {
          [action.asset.id]: {
            $merge: action.updates,
          },
        },
      })
    }

    /*
     * CREATE_ASSET_SUCCESS - Action issued when asset upload completes succesfully
     */
    case assetActions.CREATE_ASSET_SUCCESS: {
      return update(state, {
        byId: {
          [action.asset.id]: {
            $set: action.asset,
          },
        },
      })
    }

    case builderActions.CREATE_BUILDER_SUCCESS: {
      newState = update(state, {
        byId: {
          [action.asset.id]: {
            $set: action.asset,
          },
        },
      })
      action.assetRefs.forEach((ref) => {
        if (newState.byId[ref]) {
          newState = update(newState, {
            relationships: {
              assets: {
                referencedBy: {
                  [ref]: {
                    $set: getReferencedByArray(newState, ref).concat(
                      action.asset.id
                    ),
                  },
                },
                relatedAssets: {
                  [action.asset.id]: {
                    $set: getRelatedAssetArray(
                      newState,
                      action.asset.id
                    ).concat(ref),
                  },
                },
              },
            },
          })
        }
      })
      action.folderRefs.forEach((ref) => {
        newState = update(newState, {
          relationships: {
            assets: {
              relatedFolders: {
                [action.asset.id]: {
                  $set: getRelatedFolderArray(newState, action.asset.id).concat(
                    ref
                  ),
                },
              },
            },
            folders: {
              referencedBy: {
                [ref]: {
                  $set: getFolderReferencedByArray(newState, ref).concat(
                    action.asset.id
                  ),
                },
              },
            },
          },
        })
      })
      return newState
    }

    case assetActions.CLONE_ASSET_SUCCESS: {
      return update(state, {
        byId: {
          [action.clone.id]: {
            $set: action.clone,
          },
        },
      })
    }

    case endpointActions.PUBLISH_ASSET_SUCCESS:
      newState = state
      action.assets.forEach((id) => {
        if (
          newState.byId[id].publishedTo &&
          newState.byId[id].publishedTo.indexOf(action.parent) < 0
        ) {
          newState = update(newState, {
            byId: {
              [id]: {
                publishedTo: {
                  $push: [action.parent],
                },
              },
            },
          })
        }
      })
      return newState

    case endpointActions.PUBLISH_AND_APPROVE_ASSET_SUCCESS:
      newState = state
      action.assets.forEach((id) => {
        if (
          newState.byId[id].publishedTo &&
          newState.byId[id].publishedTo.indexOf(action.parent) < 0
        ) {
          newState = update(newState, {
            byId: {
              [id]: {
                publishedTo: {
                  $push: [action.parent],
                },
              },
            },
          })
        }
      })
      return newState

    case endpointActions.REVIEW_ASSET_SUCCESS: {
      if (action.statusType === endpointActions.REJECTED) {
        newState = state
        action.assets.forEach((asset) => {
          newState = update(newState, {
            byId: {
              [asset]: {
                publishedTo: {
                  $apply: (x) =>
                    x.filter((location) => location !== action.parent),
                },
              },
            },
          })
        })
        return newState
      }

      return state
    }

    case endpointActions.REVIEW_UNPUBLISH_ASSET_SUCCESS: {
      if (action.statusType === endpointActions.UNPUBLISHED) {
        newState = state
        action.assets.forEach((asset) => {
          newState = update(newState, {
            byId: {
              [asset]: {
                publishedTo: {
                  $apply: (x) =>
                    x.filter((location) => location !== action.parent),
                },
              },
            },
          })
        })
        return newState
      }

      return state
    }

    case endpointActions.UNPUBLISH_AND_APPROVE_ASSET_SUCCESS: {
      newState = state
      action.assets.forEach((asset) => {
        newState = update(newState, {
          byId: {
            [asset]: {
              publishedTo: {
                $apply: (x) =>
                  x.filter((location) => location !== action.parent),
              },
            },
          },
        })
      })
      return newState
    }

    case assetActions.GET_ASSET_SUCCESS: {
      return update(state, {
        byId: {
          [action.asset.id]: {
            $set: action.asset,
          },
        },
        relationships: {
          $set: action.relationships,
        },
      })
    }

    case appActions.REFRESH_STATE: {
      return {
        ...state,
        byId: action.assetState.byId,
        relationships: action.assetState.relationships,
      }
    }

    case folderActions.EMPTY_RECYCLE_BIN_SUCCESS: {
      newState = state
      Object.keys(newState.relationships.assets.referencedBy).forEach(
        (asset) => {
          newState = update(newState, {
            relationships: {
              assets: {
                referencedBy: {
                  [asset]: {
                    $apply: (x) =>
                      x.filter(
                        (ref) =>
                          action.itemsToDelete.assetsToDelete.indexOf(ref) < 0
                      ),
                  },
                },
              },
            },
          })
        }
      )

      action.itemsToDelete.assetsToDelete.forEach((deletedAsset) => {
        newState = update(newState, {
          byId: {
            [deletedAsset]: {
              deleted: {
                $set: true,
              },
            },
          },
          relationships: {
            assets: {
              relatedAssets: {
                $unset: [deletedAsset],
              },
            },
          },
        })
      })
      return newState
    }

    case builderActions.UPDATE_BUILDER_SUCCESS:
      newState = state
      action.refUpdates.assetRefUpdates.added.forEach((asset) => {
        // Check to make sure the reference isn't a folder
        if (newState.byId[asset]) {
          newState = update(newState, {
            relationships: {
              assets: {
                referencedBy: {
                  [asset]: {
                    $set: getReferencedByArray(newState, asset).concat(
                      action.builderData.id
                    ),
                  },
                },
                relatedAssets: {
                  [action.builderData.id]: {
                    $set: getRelatedAssetArray(
                      newState,
                      action.builderData.id
                    ).concat(asset),
                  },
                },
              },
            },
          })
        }
      })
      action.refUpdates.folderRefUpdates.added.forEach((folder) => {
        newState = update(newState, {
          relationships: {
            assets: {
              relatedFolders: {
                [action.builderData.id]: {
                  $set: getRelatedFolderArray(
                    newState,
                    action.builderData.id
                  ).concat(folder),
                },
              },
            },
            folders: {
              referencedBy: {
                [folder]: {
                  $set: getFolderReferencedByArray(newState, folder).concat(
                    action.builderData.id
                  ),
                },
              },
            },
          },
        })
      })
      action.refUpdates.assetRefUpdates.removed.forEach((asset) => {
        if (newState.byId[asset]) {
          newState = update(newState, {
            relationships: {
              assets: {
                referencedBy: {
                  [asset]: {
                    $apply: (x) =>
                      x.filter((assetId) => assetId !== action.builderData.id),
                  },
                },
                relatedAssets: {
                  [action.builderData.id]: {
                    $apply: (x) => x.filter((assetId) => assetId !== asset),
                  },
                },
              },
            },
          })
        }
      })
      action.refUpdates.folderRefUpdates.removed.forEach((folder) => {
        newState = update(newState, {
          relationships: {
            assets: {
              relatedFolders: {
                [action.builderData.id]: {
                  $apply: (x) => x.filter((folderId) => folderId !== folder),
                },
              },
            },
            folders: {
              referencedBy: {
                [folder]: {
                  $apply: (x) =>
                    x.filter((assetId) => assetId !== action.builderData.id),
                },
              },
            },
          },
        })
      })
      newState = update(newState, {
        byId: {
          [action.builderData.id]: {
            $merge: action.metadataUpdates,
          },
        },
      })
      newState = update(newState, {
        byId: {
          [action.builderData.id]: {
            [action.builderType]: {
              $merge: action.bodyUpdates[action.builderType],
            },
          },
        },
      })
      return newState

    case appActions.SET_EDITMODE_ASSET: {
      return {
        ...state,
        originalAssetData: state.byId[action.assetId],
      }
    }
    case assetActions.REPLACE_ASSET_SUCCESS: {
      return update(state, {
        byId: {
          [action.originalAsset.id]: {
            $set: action.replacementAsset,
          },
        },
      })
    }

    case assetActions.CANCEL_REPLACE_ASSET: {
      return update(state, {
        byId: {
          [state.originalAssetData.id]: {
            $set: state.originalAssetData,
          },
        },
        originalAssetData: {
          $set: {},
        },
      })
    }

    default:
      //no recognition of this action type
      return state
  }
}

const getReferencedByArray = (state, asset) => {
  return state.relationships.assets.referencedBy[asset]
    ? state.relationships.assets.referencedBy[asset]
    : []
}

const getRelatedAssetArray = (state, builder) => {
  return state.relationships.assets.relatedAssets[builder]
    ? state.relationships.assets.relatedAssets[builder]
    : []
}

const getRelatedFolderArray = (state, builder) => {
  return state.relationships.assets.relatedFolders[builder]
    ? state.relationships.assets.relatedFolders[builder]
    : []
}

const getFolderReferencedByArray = (state, folder) => {
  return state.relationships.folders.referencedBy[folder]
    ? state.relationships.folders.referencedBy[folder]
    : []
}
