import { Button, Form, Input, Tooltip } from 'antd'
import React from 'react'
import { RESOURCE_MAX_CUSTOM_RESOURCES } from '../../dux/constants/resource'

const MSTResourceSettingsView = (props) => {
  const totalResources = props.resources.custom.length
  return (
    <div className="settings__container" id="menuSettings">
      <div className="settings__form-container">
        <div className="builders__form-label">Custom Resources</div>
        <div className="builders__padded-div">
          Click the add URL or add phone button to give your team easy access to
          commonly used resources.
        </div>
        <Button
          className="settings__button-inline"
          onClick={() => props.openCustomResourceModal('url')}
          disabled={totalResources >= RESOURCE_MAX_CUSTOM_RESOURCES}
        >
          <span>
            <i className="fa fa-plus-circle" /> URL
          </span>
        </Button>
        <Button
          className="settings__button-inline"
          onClick={() => props.openCustomResourceModal('email')}
          disabled={totalResources >= RESOURCE_MAX_CUSTOM_RESOURCES}
        >
          <span>
            <i className="fa fa-plus-circle" /> Email
          </span>
        </Button>
        <Button
          className="settings__button-inline"
          onClick={() => props.openCustomResourceModal('phone')}
          disabled={totalResources >= RESOURCE_MAX_CUSTOM_RESOURCES}
        >
          <span>
            <i className="fa fa-plus-circle" /> Phone
          </span>
        </Button>
        {props.resources.custom.map((resource, index) => {
          switch (resource.resourceType) {
            case 'url':
              return (
                <div
                  key={resource.id}
                  className="settings__custom-resource-div"
                >
                  <span className="settings__custom-resource-div-text">
                    <i className="fa fa-desktop settings__custom-resource-div-icon" />
                    &nbsp;
                    <Tooltip title={resource.resourceValue} placement="right">
                      <a
                        href={resource.resourceValue}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {resource.displayText}
                      </a>
                    </Tooltip>
                  </span>
                  <span className="settings__custom-resource-div-action">
                    <div>
                      <i
                        className="fa fa-edit settings__custom-resource-div-action-icon"
                        onClick={() =>
                          props.openCustomResourceModal('url', resource)
                        }
                      />
                      <i
                        className="fa fa-trash settings__custom-resource-div-action-icon"
                        onClick={() => props.removeCustomResource(resource)}
                      />
                    </div>
                  </span>
                </div>
              )

            case 'email':
              return (
                <div
                  key={resource.id}
                  className="settings__custom-resource-div"
                >
                  <span className="settings__custom-resource-div-text">
                    <i className="fa fa-envelope settings__custom-resource-div-icon" />
                    &nbsp;
                    <Tooltip title={resource.resourceValue} placement="right">
                      <a href={`mailto:${resource.resourceValue}`}>
                        {resource.displayText}
                      </a>
                    </Tooltip>
                  </span>
                  <span className="settings__custom-resource-div-action">
                    <div>
                      <i
                        className="fa fa-edit settings__custom-resource-div-action-icon"
                        onClick={() =>
                          props.openCustomResourceModal('email', resource)
                        }
                      />
                      <i
                        className="fa fa-trash settings__custom-resource-div-action-icon"
                        onClick={() => props.removeCustomResource(resource)}
                      />
                    </div>
                  </span>
                </div>
              )

            case 'phone':
              return (
                <div
                  key={resource.id}
                  className="settings__custom-resource-div"
                >
                  <span className="settings__custom-resource-div-text">
                    <span>
                      <i className="fa fa-phone settings__custom-resource-div-icon" />
                    </span>
                    &nbsp;
                    <span>
                      <Tooltip title={resource.resourceValue} placement="right">
                        {resource.displayText}
                      </Tooltip>
                    </span>
                  </span>
                  <span className="settings__custom-resource-div-action">
                    <div>
                      <i
                        className="fa fa-edit settings__custom-resource-div-action-icon"
                        onClick={() =>
                          props.openCustomResourceModal('phone', resource)
                        }
                      />
                      <i
                        className="fa fa-trash settings__custom-resource-div-action-icon"
                        onClick={() => props.removeCustomResource(resource)}
                      />
                    </div>
                  </span>
                </div>
              )

            default:
              return <div key={index}></div>
          }
        })}

        <ResourceSettingsForm
          {...props}
          ref={props.saveFormRef}
          onFieldsChange={props.onFieldsChange}
        />

        <div className="builders__button-container">
          <span className="builders__button">
            <Button onClick={props.onCancel}>Cancel</Button>
          </span>
          <span className="builders__button">
            <Button
              type="primary"
              onClick={props.onSave}
              loading={props.loading}
            >
              Save
            </Button>
          </span>
          <div className="builders__fixed-div">
            {props.message && (
              <div>
                {props.messageType === 'SUCCESS' ? (
                  <i className="fa fa-check-circle assettable__status-green" />
                ) : (
                  <i className="fa fa-exclamation-circle assettable__status-red" />
                )}
                &nbsp; {props.message}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const ResourceSettingsForm = Form.create({
  onFieldsChange: (props) => props.onFieldsChange(),
})((props) => {
  const { form } = props
  const { getFieldDecorator } = form

  return (
    <Form layout="vertical">
      <div className="builders__form-label settings__padded-top">
        Social Media Links
      </div>
      <div className="builders__padded-div">
        Add your social media links to have them appear in your menu. Leaving a
        social media link blank will hide it from the menu.
      </div>
      <Form.Item
        className="settings__form-item"
        label={<span className="builders__form-label">Facebook</span>}
      >
        {getFieldDecorator('facebook', {
          initialValue: props.resources.social.facebook,
          validateFirst: true,
          validateTrigger: 'onBlur',
          rules: [
            {
              type: 'url',
              message:
                'Please enter a valid URL, including protocol (http:// or https://)',
            },
            {
              validator(rule, value, callback) {
                if (
                  value &&
                  !value.toLowerCase().startsWith('http:') &&
                  !value.toLowerCase().startsWith('https:')
                )
                  return callback(
                    new Error('URL must start with http: or https:')
                  )
                else return callback()
              },
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item
        className="settings__form-item"
        label={<span className="builders__form-label">Twitter</span>}
      >
        {getFieldDecorator('twitter', {
          initialValue: props.resources.social.twitter,
          validateFirst: true,
          validateTrigger: 'onBlur',
          rules: [
            {
              type: 'url',
              message:
                'Please enter a valid URL, including protocol (http:// or https://)',
            },
            {
              validator(rule, value, callback) {
                if (
                  value &&
                  !value.toLowerCase().startsWith('http:') &&
                  !value.toLowerCase().startsWith('https:')
                )
                  return callback(
                    new Error('URL must start with http: or https:')
                  )
                else return callback()
              },
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item
        className="settings__form-item"
        label={<span className="builders__form-label">Instagram</span>}
      >
        {getFieldDecorator('instagram', {
          initialValue: props.resources.social.instagram,
          validateFirst: true,
          validateTrigger: 'onBlur',
          rules: [
            {
              type: 'url',
              message:
                'Please enter a valid URL, including protocol (http:// or https://)',
            },
            {
              validator(rule, value, callback) {
                if (
                  value &&
                  !value.toLowerCase().startsWith('http:') &&
                  !value.toLowerCase().startsWith('https:')
                )
                  return callback(
                    new Error('URL must start with http: or https:')
                  )
                else return callback()
              },
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item
        className="settings__form-item"
        label={<span className="builders__form-label">YouTube</span>}
      >
        {getFieldDecorator('youtube', {
          initialValue: props.resources.social.youtube,
          validateFirst: true,
          validateTrigger: 'onBlur',
          rules: [
            {
              type: 'url',
              message:
                'Please enter a valid URL, including protocol (http:// or https://)',
            },
            {
              validator(rule, value, callback) {
                if (
                  value &&
                  !value.toLowerCase().startsWith('http:') &&
                  !value.toLowerCase().startsWith('https:')
                )
                  return callback(
                    new Error('URL must start with http: or https:')
                  )
                else return callback()
              },
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label={<span className="builders__form-label">LinkedIn</span>}>
        {getFieldDecorator('linkedin', {
          initialValue: props.resources.social.linkedin,
          validateFirst: true,
          validateTrigger: 'onBlur',
          rules: [
            {
              type: 'url',
              message:
                'Please enter a valid URL, including protocol (http:// or https://)',
            },
            {
              validator(rule, value, callback) {
                if (
                  value &&
                  !value.toLowerCase().startsWith('http:') &&
                  !value.toLowerCase().startsWith('https:')
                )
                  return callback(
                    new Error('URL must start with http: or https:')
                  )
                else return callback()
              },
            },
          ],
        })(<Input />)}
      </Form.Item>
    </Form>
  )
})

export default MSTResourceSettingsView
