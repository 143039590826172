export const MEGABYTES = 1048576
export const GIGABYTES = 1073741824

export const CP_MAX_SUBJECT_CHARS = 50
export const CP_DEFAULT_SUBJECT = 'Shared Asset from BAM!'
export const CP_MAX_MESSAGE_CHARS = 500
export const CP_DEFAULT_MESSAGE = 'Save your default message here'

export const MIN_SHARE_DAYS = 1
export const MAX_SHARE_DAYS = 365

export const SETTINGS_KEY_WEB_ENDPOINTS = 'webendpoints'
export const MAX_NUM_CHARS_ENDPOINT_TITLE = 30
