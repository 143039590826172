import React from 'react'
import './styles/Splash.css'

class SplashScreen extends React.PureComponent {
  render() {
    return (
      <div className="splash">
        <div className="splash__container">
          <span className="splash__gear1">
            <img
              src={`${this.props.config.baseUrl}/small-gear.png`}
              className="splash__small-gear1"
              alt=""
            />
          </span>
          <span>
            <img
              src={`${this.props.config.baseUrl}/large-gear.png`}
              className="splash__large-gear"
              alt=""
            />
          </span>
          <span className="splash__gear3">
            <img
              src={`${this.props.config.baseUrl}/small-gear.png`}
              className="splash__small-gear1"
              alt=""
            />
          </span>
          <div className="splash__text">Loading...</div>
        </div>
      </div>
    )
  }
}

export default SplashScreen
