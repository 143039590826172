import { Checkbox, Modal } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { buildPath } from '../../dux/modules/utils'
import AssetPreview from '../library/AssetPreview'
import TreeNode from '../sidebar/TreeNode'
import DisplayTextOverride from './DisplayTextOverride'
import '../styles/Modal.css'

class SelectAssetModal extends React.Component {
  state = {
    activeNode:
      this.props.modalProps.selectedAsset || this.props.folders.activeFolder,
    activeFolder:
      this.props.modalProps.selectedFolder || this.props.folders.activeFolder,
    openFolderKeys: this.props.modalProps.selectedFolder
      ? buildPath(this.props.modalProps.selectedFolder, this.props.folders.byId)
      : this.props.appState.openFolderKeys,
    activeAsset: this.props.modalProps.selectedAsset || '',
    textOverride: this.props.modalProps.selectedDisplayText || '',
    displayMode: 'filename',
    previewMenuKey: 'image',
  }

  onPreviewMenuChange = (data) =>
    this.setState(() => ({ previewMenuKey: data.key }))

  saveFormRef = (ref) => (this.form = ref)

  // Scroll Selected Asset into view
  saveContainerRef = (ref) => {
    if (ref) {
      if (this.props.modalProps.selectedAsset) {
        const list = document.getElementsByClassName('node-active')
        if (list.length > 0) {
          this.container = ref
          ref.scrollTop = list[0].offsetTop
        }
      }
    }
  }

  save = () => {
    if (this.form) {
      this.form.validateFields((err, values) => {
        if (!err) {
          // Only allow save if an asset is selected
          if (
            this.state.activeAsset ||
            this.props.modalProps.showAssets === false
          ) {
            if (this.props.modalProps.saveCallback)
              this.props.modalProps.saveCallback(
                this.state.activeAsset,
                this.state.activeFolder,
                values.displayText
              )
            else this.props.appActions.setActiveAsset(this.state.activeAsset)

            this.props.modalActions.closeModal()
          }
        }
      })
    } else {
      // Only allow save if an asset is selected
      if (
        this.state.activeAsset ||
        this.props.modalProps.showAssets === false
      ) {
        if (this.props.modalProps.saveCallback)
          this.props.modalProps.saveCallback(
            this.state.activeAsset,
            this.state.activeFolder,
            null
          )
        else this.props.appActions.setActiveAsset(this.state.activeAsset)

        this.props.modalActions.closeModal()
      }
    }
  }

  cancel = () => {
    if (this.props.modalProps.cancelCallback)
      this.props.modalProps.cancelCallback()
    this.props.modalActions.closeModal()
  }

  nodeIsActive = (id, parentFolder = null) => {
    if (parentFolder) {
      return (
        this.state.activeNode === id && parentFolder === this.state.activeFolder
      )
    } else {
      return this.state.activeNode === id
    }
  }

  nodeIsOpen = (id) => this.state.openFolderKeys.indexOf(id) > -1

  nodeIsDraggable = () => {
    return false
  }

  nodeIsDisabled = (id, parentFolder) => {
    if (this.props.modalProps.carousel) {
      return this.props.modalProps.carousel.find(
        (featuredAsset) =>
          featuredAsset.asset === id && featuredAsset.folder === parentFolder
      )
    }
    return false
  }

  onNodeClick = (id) => {
    let mutations = {
      activeNode: id,
      activeAsset: null,
    }
    if (this.state.activeFolder !== id) {
      mutations.activeFolder = id
    }
    this.setState(mutations)
  }

  onAssetClick = (id, parentFolder) => {
    if (
      this.state.activeAsset !== id ||
      this.state.activeFolder !== parentFolder
    ) {
      this.setState({
        activeNode: id,
        activeAsset: id,
        activeFolder: parentFolder,
        textOverride: this.props.assets.byId[id].title,
      })
    }
  }

  // Filter image files based on mime-type or extension
  imageAssetFilter = (id) => {
    if (!this.props.assets.byId[id]) return false
    return (
      this.props.assets.byId[id].file_type.startsWith('image') ||
      ['jpeg', 'jpg', 'gif', 'png', 'svg'].indexOf(
        this.props.assets.byId[id].extension
      ) > -1
    )
  }

  // Filter PDF files based on mime-type or extension
  pdfAssetFilter = (id) => {
    if (!this.props.assets.byId[id]) return false
    return (
      this.props.assets.byId[id].file_type === 'application/pdf' ||
      this.props.assets.byId[id].extension === 'pdf'
    )
  }

  // Display only assets that are published or pending removal
  publishedAssetFilter = (id, parent) => {
    if (!this.props.assets.byId[id]) return false
    let filter = false
    if (
      this.props.endpoints.folderAssetStatus[parent] &&
      this.props.endpoints.folderAssetStatus[parent][id] &&
      this.props.endpoints.folderAssetStatus[parent][id].status === 'APPROVED'
    ) {
      filter = true
    }
    return filter
  }

  multiFilter = (filterArray) => {
    return (id, parent) => {
      let result = true
      filterArray.forEach((filter) => {
        result = result && filter(id, parent)
        return result
      })
      return result
    }
  }

  // Default asset filter (requires that asset have ID - which means it is not corrupted)
  defaultAssetFilter = (id) => this.props.assets.byId[id]

  toggleFolder = (id) => {
    if (this.state.openFolderKeys.indexOf(id) < 0) {
      this.setState({ openFolderKeys: this.state.openFolderKeys.concat([id]) })
    } else {
      this.setState({
        openFolderKeys: this.state.openFolderKeys.filter((key) => key !== id),
      })
    }
  }

  removeOpenFolderKey = (id) => {
    this.setState((prevState) => {
      return {
        openFolderKeys: prevState.openFolderKeys.filter((key) => key !== id),
      }
    })
  }

  nodeLink = () => this.props.location.pathname

  setActiveFolder = (id) => {
    this.setState({ activeFolder: id, activeAsset: null })
  }

  /*
   *  Returns a filter for the assets to be displayed.
   *  Accepts a filter function, an array or a string for predefined filters:
   *    'image'
   *    'pdf'
   *    'published'
   *  The array may contain a combination of predefined strings and functions
   */
  getFilter = (filterRequest) => {
    if (typeof filterRequest === 'function') return filterRequest

    const combinedFilters = []
    if (Array.isArray(filterRequest)) {
      filterRequest.forEach((request) => {
        combinedFilters.push(this.getFilter(request))
      })

      return this.multiFilter(combinedFilters)
    }

    switch (filterRequest) {
      case 'image':
        return this.imageAssetFilter
      case 'pdf':
        return this.pdfAssetFilter
      case 'published':
        return this.publishedAssetFilter
      default:
        return this.defaultAssetFilter
    }
  }

  // Not needed for Select Asset Modal
  allowPublishing = () => false

  // Not needed for select asset modal
  getBadgeCount = () => 0

  // Toggle the asset display mode between filename and title
  handleDisplayModeChange = () => {
    this.setState((prevState) => ({
      displayMode: prevState.displayMode === 'filename' ? 'title' : 'filename',
    }))
  }

  render() {
    return (
      <Modal
        title={this.props.modalProps.modalTitle || 'Assets'}
        okText="Select"
        onOk={this.save}
        cancelText="Cancel"
        onCancel={this.cancel}
        visible={this.props.modal.modalOpen}
        confirmLoading={this.props.appState.asyncPending}
        maskClosable={false}
        style={{ top: 10 }}
        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <div className="modal__text modal__flex">
          <span>Select an asset from the library: </span>
          {
            // Show the checkbox as long as "showAssets" is not explicitly false
            this.props.modalProps.showAssets !== false && (
              <span>
                {' '}
                <Checkbox onChange={this.handleDisplayModeChange} /> Display
                assets by title
              </span>
            )
          }
        </div>
        <div className="modal-tree-container" ref={this.saveContainerRef}>
          {this.props.modalProps.treeRoot
            .filter((folder) => {
              return (
                this.props.folders.byId[folder].type !== 'recycle' &&
                this.props.folders.byId[folder].type !== 'virtual' &&
                this.props.folders.byId[folder].type !== 'ugc'
              )
            })
            .map((folderid) => {
              return (
                <TreeNode
                  key={folderid}
                  {...this.props}
                  activeFolder={this.state.activeFolder}
                  openFolderKeys={this.state.openFolderKeys}
                  removeOpenFolderKey={this.removeOpenFolderKey}
                  node={this.props.folders.byId[folderid]}
                  nodeLink={this.nodeLink}
                  isFolder
                  nodeIsActive={this.nodeIsActive}
                  nodeIsOpen={this.nodeIsOpen}
                  isRoot={
                    this.props.folders.byId[folderid].parentId ? true : false
                  }
                  nodeIsDraggable={this.nodeIsDraggable}
                  rightClickDisabled={true}
                  onToggle={this.toggleFolder}
                  onNodeClick={this.onNodeClick}
                  nodeIsDisabled={this.nodeIsDisabled}
                  setActiveFolder={this.setActiveFolder}
                  menuItem={this.props.appState.menuItem}
                  showAssets={
                    this.props.modalProps.showAssets === undefined
                      ? true
                      : this.props.modalProps.showAssets
                  }
                  displayMode={this.state.displayMode}
                  allAssets={this.props.assets.byId}
                  onAssetClick={this.onAssetClick}
                  assetFilter={this.getFilter(this.props.modalProps.filterType)}
                  allowPublishing={this.allowPublishing}
                  badges={[]}
                  getBadgeCount={this.getBadgeCount}
                  style={{
                    treenodeText: {
                      color: 'rgba(0,0,0,.65)',
                    },
                    treenodeIcon: {
                      color: 'rgba(0,0,0,.65)',
                    },
                  }}
                />
              )
            })}
        </div>
        {this.props.modalProps.showAssets !== false && this.state.activeAsset && (
          <div className="builders__asset_preview_container modal__label-padded-top">
            <AssetPreview
              asset={this.props.assets.byId[this.state.activeAsset]}
              allAssets={this.props.assets.byId}
              previewStyle="light"
              pdfMenuKey={this.state.previewMenuKey}
              onPdfMenuClick={this.onPreviewMenuChange}
            />
          </div>
        )}
        {this.props.modalProps.showTextOverride && (
          <DisplayTextOverride
            ref={this.saveFormRef}
            textOverrideDescription={
              this.props.modalProps.textOverrideDescription
            }
            value={this.state.textOverride}
            onChange={(e) => this.setState({ textOverride: e.target.value })}
            maxLength={this.props.modalProps.maxDisplayText}
          />
        )}
      </Modal>
    )
  }
}

/*
 *  modal/modalActions and folder/folderActions are the redux state and actions
 */
SelectAssetModal.propTypes = {
  modal: PropTypes.object,
  modalProps: PropTypes.object,
  modalActions: PropTypes.object,
  appState: PropTypes.object,
  folders: PropTypes.object,
  folderActions: PropTypes.object,
  user: PropTypes.object,
}

export default SelectAssetModal
