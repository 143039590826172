const queries = {
  GetMaintenanceSnapshot: `query GetMaintenanceSnapshot {
        getMaintenanceSnapshot {
            state,
            message
        }
    }`,
}

export default queries
