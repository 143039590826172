import { Layout } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import * as settingsConstants from '../dux/constants/settings'
import { isContentAdmin } from '../dux/modules/utils'
import Analytics from './analytics/Analytics'
import BamHeaderContainer from './BamHeaderContainer'
import BuilderRouter from './builders/BuilderRouter'
import FourOhFour from './common/FourOhFour'
import PrivacyPolicy from './common/PrivacyPolicy'
import AssetCrop from './library/AssetCrop'
import Sidebar from './sidebar' // Import this before AssetDetail
import AssetDetail from './library/AssetDetail'
import Home from './library/Home'
import AssetDetailSidebar from './library/sidebar/AssetDetailSidebar'
import LoadingModal from './modals/LoadingModal'
import ModalContainer from './modals/ModalContainer'
import NotificationsContainer from './notifications/NotificationsContainer'
import GroupsContainer from './permissions/GroupsContainer'
import InvitationContainer from './permissions/InvitationContainer'
import RolesContainer from './permissions/RolesContainer'
import Superuser from './permissions/Superuser'
import UsersContainer from './permissions/UsersContainer'
import UserProfile from './profile/UserProfile'
import MSTBrandingSettings from './settings/MSTBrandingSettings'
import MSTFeaturedAssetsSettings from './settings/MSTFeaturedAssetsSettings'
import MSTMenuSettings from './settings/MSTMenuSettings'
import MSTResourceSettings from './settings/MSTResourceSettings'
import SettingsContainer from './settings/SettingsContainer'
import SettingsSidebar from './settings/SettingsSidebar'
import './styles/Layout.css'

class PrivateApp extends React.Component {
  render() {
    const { Header, Sider, Content } = Layout
    const { analytics } = this.props.modules
    if (this.props.maintenance?.state === 1 && !this.props.superuser) {
      return <Redirect to="/maintenance" />
    } else if (this.props.versionMismatch) {
      return <Redirect to="/maintenance" />
    }
    return (
      <Layout className="bam-layout" style={{ height: '100vh' }}>
        <Header className="bam-header">
          <BamHeaderContainer logoWidth={this.props.width} />
        </Header>
        <Layout className="bam-main-layout">
          <Sider className="bam-sider" width={this.props.width} trigger={null}>
            <Switch>
              <Route path="/settings" component={SettingsSidebar} />
              <Route
                path="/library/asset/crop/:id"
                component={AssetDetailSidebar}
              />
              <Route path="/library/asset/:id" component={AssetDetailSidebar} />
              <Route path="*" component={Sidebar} />
            </Switch>
          </Sider>
          <Content className="bam-main-content" id="main-content">
            <Route
              exact
              path="/login"
              render={() => (
                <Redirect to={this.props.startupRoute || '/library'} />
              )}
            />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route
                path="/register"
                render={() => <Redirect to="/library" />}
              />
              <Route
                path="/analytics"
                render={() =>
                  analytics &&
                  !analytics.disabled &&
                  isContentAdmin(this.props.email, this.props.groups) ? (
                    <Analytics />
                  ) : (
                    <Redirect to={'/library'} />
                  )
                }
              />
              <Route
                path="/library/notifications"
                component={NotificationsContainer}
              />
              <Route path="/library/asset/crop/:id" component={AssetCrop} />
              <Route path="/library/asset/:id" component={AssetDetail} />
              <Route path="/library/:folder" component={Home} />
              <Route exact path="/library" component={Home} />
              <Route
                path="/builders"
                render={() =>
                  isContentAdmin(this.props.email, this.props.groups) ? (
                    <BuilderRouter {...this.props} />
                  ) : (
                    <Redirect to={'/library'} />
                  )
                }
              />
              <Route
                path="/settings/permissions/invitations"
                component={InvitationContainer}
              />
              <Route
                path="/settings/permissions/users"
                component={UsersContainer}
              />
              <Route
                path="/settings/permissions/groups"
                component={GroupsContainer}
              />
              <Route
                path="/settings/permissions/roles"
                component={RolesContainer}
              />
              <Route
                path="/settings/mst/branding"
                component={MSTBrandingSettings}
              />
              <Route
                path="/settings/mst/featured"
                component={MSTFeaturedAssetsSettings}
              />
              <Route path="/settings/mst/menu" component={MSTMenuSettings} />
              <Route
                path="/settings/mst/resources"
                component={MSTResourceSettings}
              />
              <Route
                path="/settings/mst/uploads"
                render={() => <SettingsContainer setting="uploads" />}
              />
              <Route
                path="/settings/dam/general"
                render={() => <SettingsContainer setting="general" />}
              />
              <Route
                path="/settings/dam/builders"
                render={() => <SettingsContainer setting="builders" />}
              />
              <Route
                path="/settings/dam/profiles"
                render={() => <SettingsContainer setting="profiles" />}
              />
              <Route
                path="/settings/webendpoints/general"
                render={() => (
                  <SettingsContainer
                    setting={settingsConstants.SETTINGS_KEY_WEB_ENDPOINTS}
                  />
                )}
              />
              <Route
                path="/settings/integrations/salesforce"
                render={() => <SettingsContainer setting="integrations" />}
              />
              <Route path="/profile" component={UserProfile} />
              <Route
                path="/superuser"
                render={() =>
                  this.props.superuser ? (
                    <Superuser client={this.props.client} />
                  ) : (
                    <FourOhFour />
                  )
                }
              />
              <Route
                path="/reset-password"
                render={() => <Redirect to="/library" />}
              />
              <Route path="/privacy" component={PrivacyPolicy} />
              <Route path="*" component={FourOhFour} />
            </Switch>
          </Content>
          <ModalContainer />
          <LoadingModal loading={this.props.pending} />
        </Layout>
      </Layout>
    )
  }
}

PrivateApp.propTypes = {
  superuser: PropTypes.bool,
  startupRoute: PropTypes.string,
  allFolders: PropTypes.object,
  folderActions: PropTypes.object,
  actions: PropTypes.object,
  pending: PropTypes.bool,
  width: PropTypes.number,
  sidebarCollapsed: PropTypes.bool,
  onSidebarToggle: PropTypes.func,
  onLoad: PropTypes.func,
  groups: PropTypes.object,
  email: PropTypes.string,
  location: PropTypes.object,
  maintenance: PropTypes.object,
}

export default withRouter(PrivateApp)
