import { Button, Checkbox, Form, Input, Menu, Modal, Transfer } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { isAdmin } from '../../dux/modules/utils'
import EditableChip from '../BAM_UI/EditableChip'
import PermissionsTree from '../permissions/PermissionsTree'
import { getChangedContentRoles } from '../permissions/utils/permissionsUtils'

const FormItem = Form.Item

class EditUserModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: this.props.modalProps.record.name,
      targetKeys: Object.keys(this.props.permissions.groups)
        .filter(
          (g) =>
            this.props.permissions.groups[g].members.indexOf(
              this.props.modalProps.record.email
            ) > -1
        )
        .map((g) => this.props.permissions.groups[g].id),
      selectedKeys: [],
      selectedTab: 'properties',
      footer: (
        <div>
          <Button onClick={this.props.modalActions.closeModal}>Cancel</Button>
          <Button type="primary" onClick={this.onSave}>
            Save
          </Button>
        </div>
      ),
    }
  }

  saveFormRef = (ref) => (this.form = ref)
  saveTreeRef = (ref) => (this.tree = ref)

  handleChange = (nextTargetKeys) => {
    this.setState({ targetKeys: nextTargetKeys })
  }

  handleNameChange = (val) => this.setState({ username: val })

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    })
  }

  handleMenuClick = (value) => {
    const savedRoles = this.tree && this.tree.getContentRoles()
    const savedFormValues = this.form && this.form.getFieldsValue()
    if (value.key === 'properties') this.setFooter()

    this.setState((prevState) => ({
      selectedTab: value.key,
      savedContentRoles:
        value.key === 'properties' ? savedRoles : prevState.savedContentRoles,
      savedFormValues:
        value.key === 'permissions'
          ? savedFormValues
          : prevState.savedFormValues,
    }))
  }

  searchFilter = (inputValue, option) =>
    option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1

  onSave = () => {
    const values = this.form
      ? this.form.getFieldsValue()
      : this.state.savedFormValues

    this.props.permissionsActions.editUser(
      this.props.modalProps.record,
      this.state.username,
      values.admin,
      this.state.targetKeys,
      this.props.permissions.groups
    )

    const rolesToCheck = this.tree
      ? this.tree.getContentRoles()
      : this.state.savedContentRoles
    if (rolesToCheck) {
      const updates = getChangedContentRoles(
        rolesToCheck,
        this.props.folders.byId
      )
      if (Object.keys(updates).length > 0)
        this.props.folderActions.bulkContentRoleUpdate(updates)
    }
  }

  setFooter = (ftr) => {
    const footer = (
      <div className="permissionstree__footer-container">
        <span>{ftr}</span>
        <div>
          <Button onClick={this.props.modalActions.closeModal}>Cancel</Button>
          <Button type="primary" onClick={this.onSave}>
            Save
          </Button>
        </div>
      </div>
    )
    this.setState(() => ({ footer }))
  }

  permissionsDisabled = () => {
    return (
      this.props.permissions.groups['SYS.contentadmins'].members.indexOf(
        this.props.user.email
      ) < 0
    )
  }

  render() {
    return (
      <Modal
        title={
          <EditableChip
            displayText={this.state.username}
            callback={this.handleNameChange}
            maxLength={100}
          />
        }
        footer={this.state.footer}
        visible={this.props.modal.modalOpen}
        confirmLoading={this.props.appState.asyncPending}
        maskClosable={false}
        closable={false}
        width={1200}
        className="modal__container-nopadding"
      >
        <Menu
          onClick={this.handleMenuClick}
          selectedKeys={[this.state.selectedTab]}
          mode="horizontal"
        >
          <Menu.Item key="properties">Properties</Menu.Item>
          <Menu.Item key="permissions" disabled={this.permissionsDisabled()}>
            Permissions
          </Menu.Item>
        </Menu>
        {this.state.selectedTab === 'properties' ? (
          <EditUserForm
            ref={this.saveFormRef}
            record={this.props.modalProps.record}
            targetKeys={this.state.targetKeys}
            selectedKeys={this.state.selectedKeys}
            handleChange={this.handleChange}
            handleSelectChange={this.handleSelectChange}
            windowWidth={this.props.appState.windowWidth}
            groups={this.props.permissions.groups}
            isAdmin={isAdmin(
              this.props.modalProps.record.email.key ||
                this.props.modalProps.record.email,
              this.props.permissions.groups
            )}
            values={this.state.savedFormValues || {}}
            searchFilter={this.searchFilter}
          />
        ) : (
          <PermissionsTree
            ref={this.saveTreeRef}
            endpoints={this.props.endpoints.endpoints}
            roles={Object.keys(this.props.permissions.roles)
              .filter((role) => this.props.permissions.roles[role].contentRole)
              .sort(
                (role1, role2) =>
                  this.props.permissions.roles[role1].sortOrder -
                  this.props.permissions.roles[role2].sortOrder
              )
              .map((role) => this.props.permissions.roles[role])}
            folders={this.props.folders.byId}
            entity={this.props.modalProps.record.email}
            entityType="user"
            setFooter={this.setFooter}
            savedContentRoles={this.state.savedContentRoles}
          />
        )}
      </Modal>
    )
  }
}

const EditUserForm = Form.create()((props) => {
  const { form } = props
  const { getFieldDecorator } = form

  return (
    <Form
      layout="vertical"
      className="modal__form-container"
      style={{ padding: '0 50px' }}
    >
      <div className="modal__form-item-flex-container">
        <FormItem
          label="User Email"
          required={true}
          className="modal__form-item-compact"
        >
          {getFieldDecorator('email', {
            initialValue:
              props.values.email ||
              props.record.email.key ||
              props.record.email,
          })(<Input disabled />)}
        </FormItem>
        <FormItem
          label="Invite Code"
          required={true}
          className="modal__form-item-compact"
        >
          {getFieldDecorator('invite', {
            initialValue: props.values.invitation || props.record.invitation,
          })(<Input disabled />)}
        </FormItem>
      </div>
      {/* <FormItem label="User name" require={true} className="modal__form-item-compact">
                {getFieldDecorator('name',{
                    initialValue: props.values.name || props.record.name,
                })(<Input />)}
            </FormItem> */}
      <FormItem label="Admin" className="modal__form-item-compact">
        {getFieldDecorator('admin', {
          valuePropName: 'checked',
          initialValue: props.values.isAdmin || props.isAdmin,
        })(<Checkbox />)}
      </FormItem>
      <FormItem label="Groups" className="modal__form-item-compact">
        <Transfer
          dataSource={Object.keys(props.groups)
            .filter((g) => g !== 'SYS.admins' && g !== 'SYS.allusers')
            .map((g) => props.groups[g])
            .sort((a, b) => a.name.localeCompare(b.name))}
          titles={['Available Groups', 'Selected Groups']}
          targetKeys={props.targetKeys}
          selectedKeys={props.selectedKeys}
          onChange={props.handleChange}
          onSelectChange={props.handleSelectChange}
          render={(item) => <span title={item.name}>{item.name}</span>}
          rowKey={(record) => record.id}
          listStyle={{ width: 500, height: 270 }}
          showSearch={true}
          filterOption={props.searchFilter}
        />
      </FormItem>
    </Form>
  )
})
/*
 *  Props are from the redux store
 *  "actions" are objects created by bindActionCreators, containing all relevant action functions
 *  modalProps is object passed from modal creator
 */
EditUserModal.propTypes = {
  modal: PropTypes.object,
  modalActions: PropTypes.object,
  modalProps: PropTypes.object,
  appState: PropTypes.shape({
    asyncPending: PropTypes.bool,
  }),
  onSubmit: PropTypes.func,
}

EditUserModal.defaultProps = {
  modalProps: {},
}

export default EditUserModal
