import { Button, Table } from 'antd'
import React from 'react'
import '../styles/Permissions.css'

const InvitationList = (props) => {
  return (
    <div>
      <Table
        className="permissions__table"
        dataSource={props.dataSource}
        columns={props.columns}
        pagination={{ pageSize: 10 }}
        rowKey={(record) => record.id}
        bordered={true}
        scroll={{ x: 1010, y: props.height - 250 }}
        size={props.tablesize}
      />
      <Button
        onClick={() => props.modalActions.openModal('AddUserModal')}
        icon="user-add"
        style={{ margin: '0 10px' }}
      >
        Add User
      </Button>
    </div>
  )
}

export default InvitationList
