import { Button, Form, Icon, Input } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/App-Public.css'
import ExternalLink from '../common/ExternalLink'
import maskConfig from '../../config/co-brand.config'

const LoginForm = (props) => {
  const mask = props.config.mask
  const registrationLink = maskConfig[mask]?.registrationLink
  const registrationLinkText =
    maskConfig[mask]?.registrationLinkText || 'Create one here'
  return (
    <div>
      <div className="public__phone-icon">
        <img
          className="public__logo"
          src={`${props.config.baseUrl}/BAM-logo-v2.svg`}
          alt="logo"
        />
      </div>
      <div className="bam-login__form-container">
        <div className="bam-login__form-text-top">
          No account?{' '}
          {mask && registrationLink ? (
            <ExternalLink href={registrationLink}>
              {registrationLinkText}
            </ExternalLink>
          ) : (
            <Link to="/register">Create one here</Link>
          )}
        </div>
        <div className="bam-login__form-title">Sign in to BAM!</div>
        <div>
          <WrappedBAMLoginForm ref={props.saveFormRef} {...props} />
        </div>
      </div>
    </div>
  )
}

export default LoginForm

const FormItem = Form.Item
const BAMLoginForm = (props) => {
  const maskCfg = maskConfig[props.config.mask] || {}
  const { privacyPolicy, termsAndConditions } = maskCfg
  const { getFieldDecorator } = props.form
  const clearError = (e) => {
    if (props.form.getFieldError(e.target.id))
      props.form.setFields({ [e.target.id]: { errors: '' } })
  }
  return (
    <Form
      onSubmit={props.handleSubmit}
      className="login-form"
      layout="vertical"
      hideRequiredMark
    >
      <FormItem label={<span className="bam-form__item">Email address</span>}>
        {getFieldDecorator('Email', {
          initialValue: props.email,
          validateTrigger: 'onBlur',
          onChange: clearError,
          rules: [
            {
              type: 'email',
              message: 'The input is not a valid email address',
            },
          ],
        })(
          <Input
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            autoFocus={true}
            placeholder="Email"
          />
        )}
      </FormItem>
      <FormItem
        label={
          <span className="bam-form__item bam-login__form-pw-label">
            <span className="bam-login__form-pw-label-token">Password</span>
            <span className="bam-login__form-pw-label-token">
              <Link to="/forgot-password" onClick={props.clearError}>
                Forgot Password?
              </Link>
            </span>
          </span>
        }
        className="bam-form__item"
      >
        {getFieldDecorator('Password', {
          validateTrigger: 'onBlur',
          onChange: clearError,
          rules: [
            {
              type: 'string',
              min: 8,
              message: 'Password must be at least 8 characters',
            },
          ],
        })(
          <Input
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
          />
        )}
      </FormItem>
      <FormItem className="bam-form__item">
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={props.asyncPending}
        >
          Log in
        </Button>
      </FormItem>
      <div className="bam-login__form-error">{props.errorMsg}</div>
      <div className="bam-login__form-links">
        <ExternalLink
          className="light"
          href={
            privacyPolicy ||
            (props.config.links && props.config.links.privacy) ||
            'https://bamsales.io/privacy-policy/'
          }
        >
          Privacy Policy
        </ExternalLink>

        <ExternalLink
          className="light"
          href={
            termsAndConditions ||
            (props.config.links && props.config.links.terms) ||
            'https://bamsales.io/terms-of-use/'
          }
        >
          Terms of Use
        </ExternalLink>
      </div>
    </Form>
  )
}
const WrappedBAMLoginForm = Form.create()(BAMLoginForm)
