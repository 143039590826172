import { Form, Input, Modal } from 'antd'
import React from 'react'
import '../styles/App-Public.css'

class SessionTimeoutModal extends React.Component {
  save = () => {
    this.form.validateFields((err, values) => {
      if (!err) {
        this.props.userActions.sessionTimeout(
          this.props.user.email,
          values.password,
          false
        )
      }
    })
  }

  getFormRef = (ref) => {
    this.form = ref
  }

  render() {
    return (
      <Modal
        title="Session Expired"
        visible={this.props.modal.modalOpen}
        confirmLoading={this.props.appState.asyncPending}
        maskClosable={false}
        okText="Continue"
        onOk={this.save}
        cancelText="Quit"
        onCancel={() => {
          this.props.userActions.sessionTimeout(null, null, true)
        }}
        closable={false}
      >
        <div className="modal__text">
          Your session has expired. Please enter your password below to
          continue, or choose Quit to log out of BAM!
        </div>
        <SessionTimeoutForm ref={this.getFormRef} onEnter={this.save} />
        <div className="bam-login__form-error">
          {this.props.user.loginError}
        </div>
      </Modal>
    )
  }
}

const SessionTimeoutForm = Form.create()((props) => {
  const { form } = props
  const { getFieldDecorator } = form

  return (
    <Form layout="vertical">
      <Form.Item label="Password">
        {getFieldDecorator('password', {
          initialValue: '',
          validateTrigger: 'onChange',
          rules: [
            {
              required: true,
              message: 'Password is required',
            },
          ],
        })(<Input type="password" onPressEnter={props.onEnter} />)}
      </Form.Item>
    </Form>
  )
})
export default SessionTimeoutModal
