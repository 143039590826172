import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

const BreadcrumbHeader = (props) => (
  <div className="builders__header-container">
    <Link className="builders__header-link" to={props.linkTo}>
      {props.linkText} /
    </Link>
    <h1 className="builders__header-text">
      <i className={`${props.crumbIcon} builders__header-icon`} />
      &nbsp; {props.crumbText}
    </h1>
  </div>
)

BreadcrumbHeader.propTypes = {
  linkTo: PropTypes.string,
  linkText: PropTypes.string,
  crumbText: PropTypes.string,
  crumbIcon: PropTypes.string,
}

export default BreadcrumbHeader
