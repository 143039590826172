import { Form, Input, Table } from 'antd'
import React from 'react'

const FormItem = Form.Item
export const EditableContext = React.createContext()

// eslint-disable-next-line no-unused-vars
const EditableRow = ({ form, index, ...props }) => {
  // workaround to avoid "fixed" column table
  //if (props.children.length > 1)
  return (
    <EditableContext.Provider value={form}>
      <tr {...props} />
    </EditableContext.Provider>
  )
  //return null
}

const EditableFormRow = Form.create()(EditableRow)

class EditableCell extends React.Component {
  render() {
    // eslint-disable-next-line no-unused-vars
    const { editing, dataIndex, record, disabled, ...restProps } = this.props

    return (
      <EditableContext.Consumer>
        {(form) => {
          const { getFieldDecorator } = form
          return (
            <td {...restProps}>
              {editing ? (
                <div className="comparison__table-row-hdr-editing">
                  <FormItem style={{ margin: 0 }}>
                    {getFieldDecorator(dataIndex, {
                      rules: [
                        {
                          required: dataIndex === 'attribute',
                          message: 'Please Input Value',
                        },
                      ],
                      initialValue: record[dataIndex],
                    })(<Input disabled={disabled} />)}
                  </FormItem>
                </div>
              ) : (
                restProps.children
              )}
            </td>
          )
        }}
      </EditableContext.Consumer>
    )
  }
}

class EditableTable extends React.Component {
  render() {
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    }

    return (
      <div className="comparison__table-container">
        <Table
          className="comparison__table"
          components={components}
          bordered
          dataSource={this.props.dataSource}
          columns={this.props.columns}
          rowClassName="editable-row"
          rowKey={(record) => record.key}
          scroll={{
            x: 204 * this.props.columns.length,
            y:
              this.props.windowHeight > 550
                ? this.props.windowHeight - 405
                : 145, // This will show minimum of 2 rows
          }}
          pagination={{
            defaultPageSize: 50,
          }}
        />
      </div>
    )
  }
}
export default EditableTable
