import React from 'react'

const SidebarTrigger = (props) => {
  return (
    <div className="bam-sider__trigger-container">
      <div
        className="bam-sider__trigger"
        onClick={props.onToggle}
        style={{ opacity: props.show ? 1 : 0 }}
      >
        <i className={`fa fa-chevron-${props.direction}`} />
      </div>
    </div>
  )
}

export default SidebarTrigger
