import update from 'immutability-helper'
import * as builderActions from '../actions/builders.js'

const initialState = {
  news: {
    id: null,
    title: null,
    leadImage: null,
    contentType: 'text',
    pdfContent: null,
    textContent: '',
    description: '',
    copyright: `Copyright ${new Date().getFullYear()}. All rights reserved.`,
    tags: [],
    targetSaveFolder: null,
    openSaveFolderKeys: [],
    activeScreen: 'meta',
    isEdit: false,
  },
  feature: {
    id: null,
    title: null,
    description: '',
    leadImage: null,
    relatedAssets: [],
    activeRelatedAsset: null,
    tags: [],
    targetSaveFolder: null,
    openSaveFolderKeys: [],
    activeScreen: 'meta',
    isEdit: false,
  },
  product: {
    id: null,
    title: null,
    description: '',
    leadImage: null,
    tags: [],
    slides: [],
    targetSaveFolder: null,
    pickerFolder: null,
    pickerAsset: null,
    pickerNode: null,
    openSaveFolderKeys: [],
    activeScreen: 'meta',
    activeSlide: null,
    activeHotspot: null,
    isEdit: false,
    simulatorView: 'iPhoneX',
  },
  comparison: {
    id: null,
    title: null,
    description: '',
    leadImage: null,
    datafileKey: null,
    classifications: [],
    comparisonData: {
      columns: [],
      dataSource: [],
    },
    primaryClassification: null,
    tags: [],
    editRow: '',
    targetSaveFolder: null,
    pickerFolder: null,
    pickerAsset: null,
    pickerNode: null,
    openSaveFolderKeys: [],
    activeScreen: 'meta',
    isEdit: false,
  },
  navigator: {
    id: null,
    title: null,
    description: '',
    leadImage: null,
    tags: [],
    slides: [],
    targetSaveFolder: null,
    pickerFolder: null,
    pickerAsset: null,
    pickerNode: null,
    openSaveFolderKeys: [],
    activeScreen: 'meta',
    activeSlide: null,
    activeNavzone: null,
    isEdit: false,
    simulatorView: 'iPhoneX',
  },
  simulatorViews: {
    iPhoneX: {
      id: 'iPhoneX',
      label: 'iPhoneX 19.5:9',
      background: '/195_9_template.png',
      ratio: {
        interior: 18 / 9,
        exterior: 2523 / 1212,
      },
    },
    iPhone: {
      id: 'iPhone',
      label: 'Phone 16:9',
      background: '/16_9_template.png',
      ratio: {
        interior: 16 / 9,
        exterior: 2533 / 1238,
      },
    },
    iPad: {
      id: 'iPad',
      label: 'Tablet 4:3',
      background: '/4_3_template.png',
      ratio: {
        interior: 4 / 3,
        exterior: 5836 / 4472,
      },
    },
    android: {
      id: 'android',
      label: 'Phone 18:9',
      background: '/18_9_template.png',
      ratio: {
        interior: 18 / 9,
        exterior: 2471 / 1174,
      },
    },
  },
}

export default function buildersReducer(state, action) {
  if (typeof state === 'undefined') return initialState

  switch (action.type) {
    case builderActions.SET_KEY_FOR_BUILDER:
      return {
        ...state,
        [action.builderType]: {
          ...state[action.builderType],
          ...action.keyVal,
        },
      }

    case builderActions.RESET_BUILDER_DATA:
      return {
        ...state,
        [action.builderType]: initialState[action.builderType],
      }

    case builderActions.CREATE_SLIDE_FOR_BUILDER:
      return update(state, {
        [action.builderType]: {
          slides: {
            $push: [action.slideObj],
          },
          activeSlide: {
            $set: action.slideObj,
          },
          activeScreen: {
            $set: action.slideObj.mainImage
              ? `${action.builderType}EditSlide`
              : 'addSlide',
          },
          pickerAsset: {
            $set: null,
          },
          pickerNode: {
            $set: state[action.builderType].pickerFolder,
          },
        },
      })

    case builderActions.REPLACE_SLIDE_FOR_BUILDER:
      return update(state, {
        [action.builderType]: {
          slides: {
            $apply: (x) =>
              x.map((slide) =>
                slide.id !== action.slideId
                  ? slide
                  : { ...slide, mainImage: action.newImage, size: action.size }
              ),
          },
          activeSlide: {
            mainImage: {
              $set: action.newImage,
            },
            size: {
              $set: action.size,
            },
          },
          activeScreen: {
            $set: `${action.builderType}EditSlide`,
          },
          pickerAsset: {
            $set: null,
          },
          pickerNode: {
            $set: null,
          },
        },
      })

    case builderActions.DELETE_SLIDE_FROM_BUILDER:
      return update(state, {
        [action.builderType]: {
          slides: {
            $apply: (x) => x.filter((slide) => slide.id !== action.slideId),
          },
          activeSlide: {
            $set: action.newActiveSlide,
          },
          activeScreen: {
            $set: action.newActiveSlide
              ? `${action.builderType}EditSlide`
              : 'meta',
          },
        },
      })

    case builderActions.MAKE_SLIDE_ACTIVE:
      return update(state, {
        [action.builderType]: {
          activeSlide: {
            $set: action.slide,
          },
        },
      })

    case builderActions.EDIT_SLIDE_LABEL:
      return update(state, {
        [action.builderType]: {
          activeSlide: {
            displayText: {
              $set: action.value,
            },
          },
          slides: {
            $apply: (x) =>
              x.map((slide) =>
                slide.id !== action.slideId
                  ? slide
                  : update(slide, { displayText: { $set: action.value } })
              ),
          },
        },
      })
    case builderActions.SET_SCREEN_FOR_BUILDER:
      return update(state, {
        [action.builderType]: {
          activeScreen: {
            $set: action.activeScreen,
          },
        },
      })

    case builderActions.REORDER_SLIDES:
      return update(state, {
        [action.builderType]: {
          slides: {
            $splice: [
              [action.source, 1],
              [action.target, 0, action.slide],
            ],
          },
        },
      })

    case builderActions.INITIALIZE_NEWS_BUILDER:
      return {
        ...state,
        news: {
          targetSaveFolder: action.folder,
          openSaveFolderKeys: action.path,
          id: action.asset.id,
          title: action.asset.title,
          leadImage: action.asset.news.image,
          contentType: action.asset.news.pdfContent ? 'pdf' : 'text',
          pdfContent: action.asset.news.pdfContent,
          textContent: action.asset.news.content,
          description: action.asset.description,
          copyright: action.asset.news.copyright,
          tags: action.asset.tags,
          activeScreen: 'meta',
          isEdit: true,
        },
      }

    case builderActions.INITIALIZE_FEATURE_BUILDER:
      return {
        ...state,
        feature: {
          targetSaveFolder: action.folder,
          openSaveFolderKeys: action.path,
          id: action.asset.id,
          title: action.asset.title,
          description: action.asset.description,
          leadImage: action.asset.feature.image,
          relatedAssets: action.asset.feature.relatedAssets,
          tags: action.asset.tags,
          activeScreen: 'meta',
          isEdit: true,
        },
      }

    case builderActions.INITIALIZE_PRODUCT_BUILDER:
      return {
        ...state,
        product: {
          targetSaveFolder: action.folder,
          pickerNode: action.folder,
          openSaveFolderKeys: action.path,
          id: action.asset.id,
          title: action.asset.title,
          leadImage: action.asset.product.image,
          description: action.asset.description,
          tags: action.asset.tags,
          activeScreen: 'meta',
          slides: action.asset.product.slides,
          activeSlide: null,
          activeHotspot: null,
          simulatorView: 'iPhoneX',
          isEdit: true,
        },
      }

    case builderActions.INITIALIZE_NAVIGATOR_BUILDER:
      return {
        ...state,
        navigator: {
          targetSaveFolder: action.folder,
          pickerNode: action.folder,
          openSaveFolderKeys: action.path,
          id: action.asset.id,
          title: action.asset.title,
          leadImage: action.asset.navigator.image,
          description: action.asset.description,
          tags: action.asset.tags,
          activeScreen: 'meta',
          slides: action.asset.navigator.slides,
          activeSlide: null,
          activeNavzone: null,
          simulatorView: 'iPhoneX',
          isEdit: true,
        },
      }
    case builderActions.INITIALIZE_COMPARISON_BUILDER:
      return {
        ...state,
        comparison: {
          datafileKey: action.asset.comparison.datafileKey,
          primaryClassification:
            action.asset.comparison.primaryClassification || null,
          comparisonData: action.comparisonData,
          classifications: action.classifications,
          comparisonObject: action.asset.comparison.comparisonObject,
          useDefaultDisclaimer: action.asset.comparison.useDefaultDisclaimer,
          disclaimerText: action.asset.comparison.disclaimerText,
          targetSaveFolder: action.folder,
          pickerNode: action.folder,
          openSaveFolderKeys: action.path,
          id: action.asset.id,
          title: action.asset.title,
          leadImage: action.asset.comparison.image,
          description: action.asset.description,
          tags: action.asset.tags,
          activeScreen: 'meta',
          isEdit: true,
          editRow: '',
        },
      }
    case builderActions.ADD_HOTSPOT:
      return update(state, {
        [action.builderType]: {
          slides: {
            $apply: (x) =>
              x.map((slide) =>
                slide.id !== action.slide
                  ? slide
                  : update(slide, {
                      hotspots: {
                        $push: [action.hotspot],
                      },
                    })
              ),
          },
          activeSlide: {
            hotspots: {
              $push: [action.hotspot],
            },
          },
          activeHotspot: {
            $set: action.hotspot,
          },
        },
      })

    case builderActions.UPDATE_HOTSPOT:
      return update(state, {
        [action.builderType]: {
          slides: {
            $apply: (x) =>
              x.map((slide) =>
                slide.id !== action.slideId
                  ? slide
                  : update(slide, {
                      hotspots: {
                        $apply: (y) =>
                          y.map((hotspot) =>
                            hotspot.id !== action.hotspotId
                              ? hotspot
                              : { ...hotspot, location: action.newLocation }
                          ),
                      },
                    })
              ),
          },
          activeSlide: {
            hotspots: {
              $apply: (x) =>
                x.map((hotspot) =>
                  hotspot.id !== action.hotspotId
                    ? hotspot
                    : { ...hotspot, location: action.newLocation }
                ),
            },
          },
        },
      })

    case builderActions.DELETE_HOTSPOT:
      return update(state, {
        [action.builderType]: {
          activeSlide: {
            hotspots: {
              $apply: (x) =>
                x.filter((hotspot) => hotspot.id !== action.hotspotId),
            },
          },
          slides: {
            $apply: (x) =>
              x.map((slide) =>
                slide.id !== action.parentId
                  ? slide
                  : update(slide, {
                      hotspots: {
                        $apply: (y) =>
                          y.filter(
                            (hotspot) => hotspot.id !== action.hotspotId
                          ),
                      },
                    })
              ),
          },
          activeHotspot: {
            $set: null,
          },
        },
      })

    case builderActions.REPLACE_HOTSPOT:
      return update(state, {
        [action.builderType]: {
          activeHotspot: {
            $set: action.newHotspot,
          },
          activeSlide: {
            hotspots: {
              $apply: (y) =>
                y.map((hotspot) =>
                  hotspot.id !== action.newHotspot.id
                    ? hotspot
                    : action.newHotspot
                ),
            },
          },
          slides: {
            $apply: (x) =>
              x.map((slide) =>
                slide.id !== action.activeSlideId
                  ? slide
                  : update(slide, {
                      hotspots: {
                        $apply: (y) =>
                          y.map((hotspot) =>
                            hotspot.id !== action.newHotspot.id
                              ? hotspot
                              : action.newHotspot
                          ),
                      },
                    })
              ),
          },
        },
      })

    case builderActions.ADD_NAVZONE:
      return update(state, {
        [action.builderType]: {
          slides: {
            $apply: (x) =>
              x.map((slide) =>
                slide.id !== action.activeSlideId
                  ? slide
                  : update(slide, {
                      navzones: {
                        $push: [action.navzone],
                      },
                    })
              ),
          },
          activeSlide: {
            navzones: {
              $push: [action.navzone],
            },
          },
          activeNavzone: {
            $set: action.navzone,
          },
        },
      })

    case builderActions.SET_TARGET_FOR_ZONE:
      return update(state, {
        [action.builderType]: {
          slides: {
            $apply: (x) =>
              x.map((slide) =>
                slide.id !== action.slideId
                  ? slide
                  : update(slide, {
                      navzones: {
                        $apply: (y) =>
                          y.map((zone) =>
                            zone.id !== action.zoneId
                              ? zone
                              : update(zone, {
                                  targetId: {
                                    $set: action.targetId,
                                  },
                                  targetType: {
                                    $set: action.targetType,
                                  },
                                })
                          ),
                      },
                    })
              ),
          },
          activeSlide: {
            navzones: {
              $apply: (x) =>
                x.map((zone) =>
                  zone.id !== action.zoneId
                    ? zone
                    : update(zone, {
                        targetId: {
                          $set: action.targetId,
                        },
                        targetType: {
                          $set: action.targetType,
                        },
                      })
                ),
            },
          },
        },
      })

    case builderActions.DELETE_NAVZONE:
      return update(state, {
        [action.builderType]: {
          slides: {
            $apply: (x) =>
              x.map((slide) =>
                slide.id !== action.slideId
                  ? slide
                  : update(slide, {
                      navzones: {
                        $apply: (y) =>
                          y.filter((zone) => zone.id !== action.zoneId),
                      },
                    })
              ),
          },
          activeSlide: {
            navzones: {
              $apply: (x) => x.filter((zone) => zone.id !== action.zoneId),
            },
          },
          activeNavzone: {
            $set: null,
          },
        },
      })

    case builderActions.UPDATE_NAVZONE:
      return update(state, {
        [action.builderType]: {
          slides: {
            $apply: (x) =>
              x.map((slide) =>
                slide.id !== action.slideId
                  ? slide
                  : update(slide, {
                      navzones: {
                        $apply: (y) =>
                          y.map((zone) =>
                            zone.id !== action.newNavzone.id
                              ? zone
                              : action.newNavzone
                          ),
                      },
                    })
              ),
          },
          activeSlide: {
            navzones: {
              $apply: (x) =>
                x.map((zone) =>
                  zone.id !== action.newNavzone.id ? zone : action.newNavzone
                ),
            },
          },
          activeNavzone: {
            $set: action.newNavzone,
          },
        },
      })

    case builderActions.UPDATE_COMPARISON_OBJECT:
      return {
        ...state,
        comparisonObject: action.comparisonObject,
      }

    default:
      return state
  }
}
