import React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as appActions from '../../dux/actions/appActions'
import * as userActions from '../../dux/actions/user'
import '../styles/Login.css'
import '../styles/Register.css'
import '../styles/RegistrationPending.css'

class RegistrationPending extends React.Component {
  componentDidMount() {
    this.props.appActions.setStartupRoute(null)
  }

  render() {
    if (!this.props.user.email) {
      return <Redirect to="/login" />
    }
    return (
      <div className="reg-pending">
        <div className="reg-pending__row">
          <div className="reg-pending__col2">
            <span className="registrationpending__spacer">
              <h3>Registration is pending for {this.props.user.email}</h3>
            </span>
            <p
              onClick={() => {
                this.props.userActions.clearLoginError()
                this.props.appActions.clearLocalStorage({
                  registrationPending: false,
                })
              }}
            >
              To complete your registration, click the link in the email then{' '}
              <Link to="/login" className="reg-pending__link">
                {' '}
                login with your credentials
              </Link>
            </p>
            <p
              onClick={() => {
                this.props.userActions.clearLoginError()
                this.props.appActions.clearLocalStorage({
                  email: '',
                  registrationPending: false,
                })
              }}
            >
              If this is not you,{' '}
              <Link to="/login" className="reg-pending__link">
                click here to login as a different user
              </Link>
            </p>
            <p
              onClick={() => {
                this.props.userActions.resendConfirmEmail(this.props.user.email)
                this.props.appActions.clearLocalStorage({
                  email: '',
                  registrationPending: false,
                })
              }}
            >
              <span className="reg-pending__link">
                Click here to resend verification email
              </span>
              {this.props.appState.asyncPending ? (
                <span>
                  <i className="fa fa-spinner fa-spin fa-fw" /> Resending
                  Confirmation
                </span>
              ) : null}
            </p>
            <p>
              {this.props.user.resendConfirmError
                ? this.props.user.resendConfirmErrorMsg
                : null}
            </p>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  appState: state.appState,
})
const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(userActions, dispatch),
  appActions: bindActionCreators(appActions, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPending)
