import { Select } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

const ViewSelector = (props) => {
  const { views, onViewChange, selectedView, disabled } = props
  const { Option } = Select
  return (
    <div>
      <Select
        onChange={onViewChange}
        defaultValue={selectedView}
        disabled={disabled}
      >
        {Object.keys(views).map((view) => (
          <Option key={views[view].id}>{views[view].label}</Option>
        ))}
      </Select>
    </div>
  )
}

ViewSelector.propTypes = {
  views: PropTypes.object,
  onViewChange: PropTypes.func,
  selectedView: PropTypes.string,
  disabled: PropTypes.bool,
}

export default ViewSelector
