import { Input } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

class NewPassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pwd: '',
      pwdConf: '',
      validationState: null,
      validationMsg: '',
    }

    this.stateIsNew = (state, msg) => {
      return (
        this.state.validationState !== state || this.state.validationMsg !== msg
      )
    }

    //Passwords must match and have a minimum length of 12 characters
    this.validate = () => {
      return this.state.pwd === this.state.pwdConf && this.state.pwd.length >= 8
    }

    this.handleFieldChange = (e, name) => {
      this.setState({ [name]: e.target.value }, () => {
        if (this.validate()) {
          //did the state change?
          if (this.stateIsNew('')) {
            this.props.onValidityChange({
              valid: true,
              password: this.state.pwd,
            })
            this.setState({ validationState: 'success' })
          }
        } else {
          let msg = ''
          if (this.state.pwd !== this.state.pwdConf) {
            msg = 'Passwords do not match. '
          }
          if (this.state.pwd.length < 8) {
            msg = msg + 'Minimum length: 8 characters'
          }
          //the passwords don't validate. If this is a new state, update accordingly
          if (this.stateIsNew('error', msg)) {
            this.props.onValidityChange(false)

            this.setState({
              validationState: 'error',
              validationMsg: msg,
            })
          }
        }
      })
    }
  }

  render() {
    return (
      <div>
        <Input
          type="password"
          value={this.state.pwd}
          onChange={(e) => this.handleFieldChange(e, 'pwd')}
          placeholder="Enter new password"
        />
        <div className="input__spacer" />
        <Input
          type="password"
          value={this.state.pwdConf}
          onChange={(e) => this.handleFieldChange(e, 'pwdConf')}
          placeholder="Re-enter new password"
        />
      </div>
    )
  }
}

NewPassword.propTypes = {
  onValidityChange: PropTypes.func,
  cssClass: PropTypes.string,
}
NewPassword.defaultProps = {
  cssClass: 'textinput',
}

export default NewPassword
