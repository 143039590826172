import PropTypes from 'prop-types'
import React from 'react'

const BuilderPreview = (props) => {
  return (
    <div className="builders__preview-text-container">
      <div className="builders__preview-title">{props.titleText}</div>
      <div className="builders__preview-subtitle">{props.subtitleText}</div>
      <div className="builders__preview-description">
        {props.descriptionText}
      </div>
      <div className="builders__preview-caption">{props.captionText}</div>
    </div>
  )
}

BuilderPreview.propTypes = {
  titleText: PropTypes.string,
  subtitleText: PropTypes.string,
  descriptionText: PropTypes.string,
  captionText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default BuilderPreview
