import React from 'react'
import PropTypes from 'prop-types'

import PdfView from './PdfView'

import { styles } from './styles'
import Title from 'antd/lib/typography/Title'
import { Divider } from 'antd'
import Linkify from 'react-linkify'

/*

  News Preview 
  Styles can be found in styles.js

  TODO:
  Needs support for 'replace asset'

*/

const HeaderImage = ({ news, allAssets }) => {
  const image = allAssets[news.image]
  if (!image) return null
  return (
    <div
      style={{
        backgroundImage: `url(${image.url}`,
        ...styles.headerImage,
      }}
    ></div>
  )
}
const NewsPreview = ({ asset, allAssets }) => {
  const news = asset.news
  const pdfAsset = allAssets[news.pdfContent] || null
  if (pdfAsset) return <PdfView asset={pdfAsset} />

  return (
    <div className="assetdetail__preview" style={{ overflow: 'scroll' }}>
      {news.image && <HeaderImage {...asset} allAssets={allAssets} />}
      <div style={styles.titleArea}>
        <Title style={styles.titleText}>{asset.title}</Title>
        <Divider style={styles.maxContentWidth} orientation="left" />
      </div>

      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="blank" href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )}
      >
        <div style={styles.articleContent}>{news.content}</div>
      </Linkify>
    </div>
  )
}

NewsPreview.propTypes = {
  onPdfMenuClick: PropTypes.func,
  pdfMenuKey: PropTypes.string,
  asset: PropTypes.object,
  allAssets: PropTypes.object,
  previewStyle: PropTypes.string,
}

NewsPreview.defaultProps = {
  previewStyle: 'dark',
}

export default NewsPreview
