import { Form, Input, Modal } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { MENU_ITEM_MAX_CHARS } from '../../dux/constants/menu'

const CustomResourceModal = (props) => {
  return (
    <Modal
      title={props.modalProps.modalTitle}
      onOk={() =>
        props.modalProps.saveCustomResource(props.modalProps.resource)
      }
      onCancel={props.modalActions.closeModal}
      visible={props.modal.modalOpen}
      confirmLoading={props.appState.asyncPending}
      maskClosable={false}
    >
      <div className="modal__text-normal">
        Configure a custom link and set display text - be sure to include the
        URL Protocol (http:// or https://):
      </div>
      <ResourceForm
        ref={props.modalProps.saveFormRef}
        resource={props.modalProps.resource}
      />
    </Modal>
  )
}

const ResourceForm = Form.create()((props) => {
  const { form, resource } = props
  const { getFieldDecorator } = form
  const validators = {
    url: [
      {
        required: true,
        message: 'Value is required',
      },
      {
        type: 'url',
        message: 'Please enter a valid URL',
      },
      {
        validator(rule, value, callback) {
          if (
            value &&
            !value.toLowerCase().startsWith('http:') &&
            !value.toLowerCase().startsWith('https:')
          )
            return callback(new Error('URL must start with http: or https:'))
          else return callback()
        },
      },
    ],
    email: [
      {
        required: true,
        message: 'Value is required',
      },
      {
        type: 'email',
        message: 'Please enter a valid email',
      },
    ],
    phone: [
      {
        required: true,
        message: 'Value is required',
      },
    ],
  }
  return (
    <Form layout="vertical">
      <Form.Item
        className="modal__label-padded-top"
        label={
          <span className="builders__form-label">
            {resource.resourceType.toUpperCase()}
          </span>
        }
      >
        {getFieldDecorator('value', {
          initialValue: props.resource.resourceValue || '',
          validateFirst: true,
          validateTrigger: 'onBlur',
          rules: validators[props.resource.resourceType],
        })(<Input />)}
      </Form.Item>
      <Form.Item
        label={<span className="builders__form-label">Display Text</span>}
      >
        {getFieldDecorator('displayText', {
          initialValue: props.resource.displayText || '',
          validateFirst: true,
          rules: [
            {
              required: true,
              message: 'Display Text is required',
            },
            {
              max: MENU_ITEM_MAX_CHARS,
              message: `Labels are limited to ${MENU_ITEM_MAX_CHARS} characters`,
            },
          ],
        })(<Input />)}
      </Form.Item>
    </Form>
  )
})
/*
 * modal is the redux state for modals
 * modalActions is an object containing the modal actions, namespaced by bindActionCreators
 */
CustomResourceModal.propTypes = {
  modal: PropTypes.object,
  modalActions: PropTypes.object,
}
export default CustomResourceModal
