export const colors = {
  black00: '#000000',
  white00: '#ffffff',
  grey00: '#b5b5b5',
  grey01: '#757575',
  grey03: '#545454',
  grey02: '#8596a7',
  grey04: '#afc2d4',
  blue01: '#cceafe',
  blue00: '#1789ff',
  blue02: '#1789ff27',
  green00: '#75cb1b',
}
