import { Button, Modal } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as modalActions from '../../dux/actions/modal'
import * as permissionsActions from '../../dux/actions/permissions'

const InvTableActions = (props) => (
  <span>
    <Button
      disabled={!props.isAdmin}
      onClick={() =>
        props.modalActions.openModal('EditUserModal', {
          record: props.record,
          isAdmin: props.isAdmin,
        })
      }
      icon="edit"
      style={{ margin: '0 5px' }}
    />
    <Button
      disabled={
        !props.isAdmin ||
        props.loggedInUser === props.record.email ||
        props.loggedInUser === props.record.email.key
      }
      icon="delete"
      style={{ margin: '0 5px' }}
      onClick={() => {
        return props.isAdmin
          ? Modal.confirm({
              title: 'Confirm delete',
              onOk: () => {
                props.actions.deleteUser(props.record)
                Promise.resolve('')
              },
              content: 'This action cannot be undone',
            })
          : null
      }}
    />
  </span>
)

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(permissionsActions, dispatch),
  modalActions: bindActionCreators(modalActions, dispatch),
})

export default connect(null, mapDispatchToProps)(InvTableActions)
