import { Button, Layout } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import {
  BUILDER__SIDER_WIDTH,
  COMPARISON__BUILDER_TYPE,
} from '../../../dux/constants/builders'
import BreadcrumbHeader from '../../common/BreadcrumbHeader'
import BuilderPreview from '../preview/BuilderPreview'
import EditableTable from '../preview/EditableTable'
import BuilderSider from '../sider/BuilderSider'
import BuilderSimulator from '../simulator/BuilderSimulator'
import ViewSelector from '../simulator/ViewSelector'
import SlideDeck from './SlideDeck'
import { builderHeaderData, builderPreviewText } from './utils'
import '../../styles/Builders.css'

const AssetBuilderView = (props) => {
  const {
    builderType,
    builderData,
    allAssets,
    allFolders,
    builderActions,
    modalActions,
    endpoints,
    showFooter,
    confirmDeleteSlide,
    replaceSlide,
    openSelectAssetModal,
    showSimulator,
    showComparisonTable,
    simulatorViews,
    saveSlideDeckRef,
    config,
    downloadTemplate,
    getComparisonData,
    windowHeight,
    isEditing,
    setEditComparisonRow,
    tableRecordSave,
    tableRecordCancel,
    handlePickerModeChange,
    builderSettings,
  } = props
  const { Header, Content, Sider, Footer } = Layout

  return (
    <Layout className="builders__layout">
      <Layout className="builders__content-layout">
        <Header className="builders__header-container">
          <BreadcrumbHeader
            linkTo={builderHeaderData[builderType].linkTo}
            linkText={builderHeaderData[builderType].linkText}
            crumbText={
              builderData.title || builderHeaderData[builderType].crumbText
            }
            crumbIcon={builderHeaderData[builderType].crumbIcon}
          />
          {showSimulator && (
            <ViewSelector
              views={simulatorViews}
              selectedView={builderData.simulatorView}
              onViewChange={(val) =>
                builderActions.setKeyForBuilder(builderType, {
                  simulatorView: val,
                })
              }
              disabled={!showSimulator}
            />
          )}
        </Header>
        <Content className="builders__preview-container" id="sim-frame">
          {showSimulator ? (
            <BuilderSimulator
              ref={props.saveSimulatorRef}
              builderData={builderData}
              activeSlide={builderData.activeSlide}
              builderType={builderType}
              builderActions={builderActions}
              allAssets={allAssets}
              simulatorViews={simulatorViews}
              ratio={simulatorViews[builderData.simulatorView].ratio}
              modalActions={modalActions}
              endpoints={endpoints}
              selectTarget={props.selectTarget}
              openSelectAssetModal={props.openSelectAssetModal}
              componentMouseEnter={props.componentMouseEnter}
              componentMouseLeave={props.componentMouseLeave}
              addNavzone={props.addNavzone}
              openNewZoneModal={props.openNewZoneModal}
              config={config}
            />
          ) : showComparisonTable ? (
            <EditableTable
              columns={builderData.comparisonData.columns}
              dataSource={builderData.comparisonData.dataSource}
              windowHeight={windowHeight}
              isEditing={isEditing}
              setEditComparisonRow={setEditComparisonRow}
              tableRecordSave={tableRecordSave}
              tableRecordCancel={tableRecordCancel}
            />
          ) : (
            <BuilderPreview
              titleText={builderPreviewText[builderType].title}
              subtitleText={builderPreviewText[builderType].subtitle}
              descriptionText={builderPreviewText[builderType].description}
              captionText={
                builderType === COMPARISON__BUILDER_TYPE ? (
                  <Button type="primary" onClick={downloadTemplate}>
                    <i className="fa fa-download" />
                    &nbsp;Comparison Template
                  </Button>
                ) : (
                  builderPreviewText[builderType].caption
                )
              }
            />
          )}
        </Content>
        <Footer className="builders__footer">
          {showFooter && (
            <div className="builders__footer-container">
              <div className="builders__footer-button-container">
                <div className="builders__slidedeck-caption">
                  {props.getSlideIndicatorText()}
                </div>
                <div>
                  <Button
                    className="builders__footer-button"
                    onClick={replaceSlide}
                    disabled={!builderData.activeSlide}
                  >
                    Replace
                  </Button>
                  <Button
                    className="builders__footer-button"
                    onClick={confirmDeleteSlide}
                    disabled={!builderData.activeSlide}
                  >
                    Delete
                  </Button>
                </div>
              </div>
              <SlideDeck
                ref={saveSlideDeckRef}
                reorderSlides={builderActions.reorderSlides}
                slides={builderData.slides}
                activeSlide={
                  builderData.activeSlide && builderData.activeSlide.id
                }
                confirmDeleteSlide={confirmDeleteSlide}
                builderType={builderType}
                builderData={builderData}
                builderActions={builderActions}
                modalActions={modalActions}
                endpoints={endpoints}
                allAssets={allAssets}
                openSelectAssetModal={openSelectAssetModal}
              />
            </div>
          )}
        </Footer>
      </Layout>
      <Sider className="builders__sider" width={BUILDER__SIDER_WIDTH}>
        <BuilderSider
          saveFormRef={props.saveFormRef}
          saveTagsRef={props.saveTagsRef}
          builderType={builderType}
          builderData={builderData}
          onCancel={props.onCancel}
          nextAction={props.nextAction}
          prevAction={props.prevAction}
          nextDisabled={props.nextDisabled}
          editSlideLabel={props.builderActions.editSlideLabel}
          allAssets={allAssets}
          allFolders={allFolders}
          addSaveFolderKey={props.addSaveFolderKey}
          removeSaveFolderKey={props.removeSaveFolderKey}
          actionButtonLabel={props.actionButtonLabel}
          handleFormValuesChange={props.handleFormValuesChange}
          imageFilter={props.imageFilter}
          setPickerFolder={props.setPickerFolder}
          setPickerAsset={props.setPickerAsset}
          componentMouseEnter={props.componentMouseEnter}
          componentMouseLeave={props.componentMouseLeave}
          openSelectAssetModal={props.openSelectAssetModal}
          selectTarget={props.selectTarget}
          removeTarget={props.removeTarget}
          editTarget={props.editTarget}
          endpoints={endpoints}
          downloadTemplate={downloadTemplate}
          modalActions={props.modalActions}
          getComparisonData={getComparisonData}
          handlePickerModeChange={handlePickerModeChange}
          builderSettings={builderSettings}
        />
      </Sider>
    </Layout>
  )
}

AssetBuilderView.propTypes = {
  saveFormRef: PropTypes.func,
  saveTagsRef: PropTypes.func,
  saveSlideDeckRef: PropTypes.func,
  saveSimulatorRef: PropTypes.func,
  builderType: PropTypes.string,
  builderData: PropTypes.object,
  onCancel: PropTypes.func,
  nextAction: PropTypes.func,
  nextDisabled: PropTypes.bool,
  allAssets: PropTypes.object,
  addSaveFolderKey: PropTypes.func,
  removeSaveFolderKey: PropTypes.func,
  actionButtonLabel: PropTypes.string,
  handleFormValuesChange: PropTypes.func,
  imageFilter: PropTypes.func,
  setPickerFolder: PropTypes.func,
  setPickerAsset: PropTypes.func,
  handlePickerModeChange: PropTypes.func,
  openSelectAssetModal: PropTypes.func,
  editTarget: PropTypes.func,
  selectTarget: PropTypes.func,
  removeTarget: PropTypes.func,
  confirmDeleteSlide: PropTypes.func,
  getSlideIndicatorText: PropTypes.func,
  componentMouseEnter: PropTypes.func,
  componentMouseLeave: PropTypes.func,
  endpoints: PropTypes.arrayOf(PropTypes.object),
  builderActions: PropTypes.object,
  modalActions: PropTypes.object,
  replaceSlide: PropTypes.func,
  showFooter: PropTypes.bool,
  showSimulator: PropTypes.bool,
  showComparisonTable: PropTypes.bool,
  simulatorViews: PropTypes.object,
  addNavzone: PropTypes.func,
  openNewZoneModal: PropTypes.func,
  allFolders: PropTypes.object,
  config: PropTypes.object,
  downloadTemplate: PropTypes.func,
  getComparisonData: PropTypes.func,
  windowHeight: PropTypes.number,
  isEditing: PropTypes.func,
  tableRecordSave: PropTypes.func,
  tableRecordCancel: PropTypes.func,
  builderSettings: PropTypes.object,
}

export default AssetBuilderView
