import { Modal, Radio } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import Slide from '../builders/main/Slide'

class AddNavzoneModal extends React.Component {
  state = {
    radioValue: this.props.modalProps.selectedType || 'newSlide',
    showSlides: this.props.modalProps.selectedType === 'slide',
    activeSlide: null,
  }

  handleRadioChange = (e) => {
    this.setState({
      radioValue: e.target.value,
      showSlides: e.target.value === 'slide',
    })
  }

  render() {
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    }
    const { modalProps, modal, appState, modalActions } = this.props

    return (
      <Modal
        title={modalProps.title}
        visible={modal.modalOpen}
        confirmLoading={appState.asyncPending}
        okText="Next"
        onOk={() =>
          modalProps.okCallback(this.state.radioValue, this.state.activeSlide)
        }
        onCancel={modalProps.cancelCallback || modalActions.closeModal}
        maskClosable={false}
      >
        <h3 className="modal__message">
          <div>Select a destination for this Nav Zone:</div>
          <Radio.Group
            value={this.state.radioValue}
            onChange={this.handleRadioChange}
          >
            <Radio style={radioStyle} value="newSlide">
              New Slide
            </Radio>
            <Radio
              style={radioStyle}
              value="slide"
              disabled={modalProps.builderData.slides.length < 2}
            >
              Existing Slide
            </Radio>
            <Radio style={radioStyle} value="addAssetTarget">
              Asset
            </Radio>
            <Radio style={radioStyle} value="addFolderTarget">
              Folder
            </Radio>
          </Radio.Group>
        </h3>
        {this.state.showSlides && (
          <div className="modal__slide-container">
            {modalProps.builderData.slides
              .filter(
                (slide) => slide.id !== modalProps.builderData.activeSlide.id
              )
              .map((slide) => {
                const index = modalProps.builderData.slides.findIndex(
                  (s) => s.id === slide.id
                )
                return (
                  <Slide
                    key={slide.id}
                    slide={slide}
                    isDragging={false}
                    tooltipText={slide.displayText || `Slide ${index + 1}`}
                    isActive={slide.id === this.state.activeSlide}
                    makeSlideActive={() =>
                      this.setState({ activeSlide: slide.id })
                    }
                    mainImage={
                      slide.mainImage &&
                      this.props.assets.byId[slide.mainImage].preview
                    }
                  />
                )
              })}
          </div>
        )}
      </Modal>
    )
  }
}

/*
 * modal is the redux state for modals
 * modalActions is an object containing the modal actions, namespaced by bindActionCreators
 */
AddNavzoneModal.propTypes = {
  modal: PropTypes.object,
  modalActions: PropTypes.object,
}
export default AddNavzoneModal
