import React, { Component } from 'react'
import * as assetActions from '../../../dux/actions/asset'
import { connect } from 'react-redux'

const styles = {
  form: { display: 'flex', flexDirection: 'column', color: 'black' },
  label: { fontWeight: '1000px', fontSize: '30px' },
  button: { color: 'black', width: 200 },
  disabled: { color: 'red' },
}

// | first | last | email | password | invite | groups (names, semicolon-delimited) |
// need one of: Invite or Groups, all others are required
class BatchMove extends Component {
  // Upload Folders

  constructor(props) {
    super(props)
    this.handleFileChange = ({ target: { files } }) => {
      const reader = new FileReader()
      reader.onload = ({ target: { result } }) => {
        const data = JSON.parse(result)
        if (this.validate(data)) {
          this.setState({ data: data })
        }
      }
      reader.readAsText(files[0])
    }
    this.folderIdsByPath = {}
  }

  validate(json) {
    try {
      if (!Array.isArray(json)) {
        console.error('data is not an array')
        throw 'Data is invalid: needs to be a JSON array of objects with assetId, sourceId, targetId'
      }

      json.forEach((obj) => {
        if (!obj.assetId) throw 'One or more objects is missing assetId'
        if (!obj.sourceId) throw 'One or more objects is missing sourceId'
        if (!obj.targetId) throw 'One or more objects is missing targetId'
      })
    } catch (error) {
      console.log(error)
      this.setState({
        output: error,
      })
      return false
    }

    return true
  }

  submitHandler = (e) => {
    e.preventDefault()

    console.log('invoking moveAssets', this.state.data)
    this.props.moveAssets(this.state.data)
  }

  render() {
    const isValid = this.state && this.state.data
    const output =
      (this.state && this.state.output) || !isValid ? this.defaultText : ''

    return (
      <>
        <form style={styles.form} onSubmit={this.submitHandler}>
          <label htmlFor="users" style={styles.label}>
            Batch Move Assets
          </label>
          <input
            type="file"
            onChange={this.handleFileChange}
            name="upload"
            id="upload"
            accept=".json"
          />
          <br />
          <button type="submit" style={styles.button} disabled={!isValid}>
            Start
          </button>
          {output && (
            <div>
              <pre>{output}</pre>
            </div>
          )}
        </form>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  moveAssets: (data) => {
    dispatch(assetActions.moveAssets(data))
  },
})

export default connect(null, mapDispatchToProps)(BatchMove)
