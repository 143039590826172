import { Form, Modal, Transfer } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

const FormItem = Form.Item

class ConfigUserEndpointModal extends React.Component {
  state = {
    targetKeys: Object.keys(this.props.endpoints.allViews).filter(
      (view) => this.props.user.homeEndpoint.views.indexOf(view) > -1
    ),
    selectedKeys: [],
  }

  saveFormRef = (ref) => (this.form = ref)

  handleChange = (nextTargetKeys) => {
    this.setState({ targetKeys: nextTargetKeys })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    })
  }

  onSave = () => {
    this.props.userActions.updateUserProfile({
      ...this.props.user,
      homeEndpoint: {
        ...this.props.user.homeEndpoint,
        views: this.state.targetKeys,
      },
    })
    this.props.endpointActions.loadEndpointState(this.props.endpoints, {
      ...this.props.user.homeEndpoint,
      views: this.state.targetKeys,
    })
  }
  render() {
    return (
      <Modal
        title="Configure Workspace"
        okText="SAVE"
        onOk={this.onSave}
        cancelText="Cancel"
        onCancel={this.props.modalActions.closeModal}
        visible={this.props.modal.modalOpen}
        confirmLoading={this.props.appState.asyncPending}
        maskClosable={false}
      >
        <ConfigUserEndpointForm
          ref={this.saveFormRef}
          myViews={this.props.user.homeEndpoint.views}
          allViews={Object.keys(this.props.endpoints.allViews).map((view) => {
            let xferView = this.props.endpoints.allViews[view]
            xferView.disabled = !xferView.optional
            return xferView
          })}
          titles={['All Views', 'My Views']}
          targetKeys={this.state.targetKeys}
          selectedKeys={this.state.selectedKeys}
          handleChange={this.handleChange}
          handleSelectChange={this.handleSelectChange}
        />
      </Modal>
    )
  }
}

const ConfigUserEndpointForm = Form.create()((props) => {
  return (
    <Form layout="vertical">
      <FormItem label="Customize Views">
        <Transfer
          dataSource={props.allViews}
          titles={props.titles}
          targetKeys={props.targetKeys}
          selectedKeys={props.selectedKeys}
          onChange={props.handleChange}
          onSelectChange={props.handleSelectChange}
          render={(item) => <span>{item.description}</span>}
          rowKey={(record) => record.id}
        />
      </FormItem>
    </Form>
  )
})
/*
 *  Props are from the redux store
 *  "actions" are objects created by bindActionCreators, containing all relevant action functions
 *  modalProps is object passed from modal creator
 */
ConfigUserEndpointModal.propTypes = {
  modal: PropTypes.object,
  modalActions: PropTypes.object,
  modalProps: PropTypes.object,
  appState: PropTypes.shape({
    asyncPending: PropTypes.bool,
  }),
  onSubmit: PropTypes.func,
}

ConfigUserEndpointModal.defaultProps = {
  modalProps: {},
}

export default ConfigUserEndpointModal
