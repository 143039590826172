import update from 'immutability-helper'
import { createSelector } from 'reselect'
import * as appActions from '../actions/appActions'
import * as assetActions from '../actions/asset.js'
import * as builderActions from '../actions/builders'
import * as endpointActions from '../actions/endpoint'
import * as folderActions from '../actions/folder.js'
import * as permissionActions from '../actions/permissions'
import * as searchActions from '../actions/search.js'

const defaultFolders = {
  _root: {
    id: '_root',
    assets: [],
    childFolders: [],
    name: 'Root',
    parentId: null,
    type: 'root',
    contentRoles: {},
  },
  _recycle: {
    id: '_recycle',
    assets: [],
    childFolders: [],
    name: 'Recycle Bin',
    parentId: null,
    type: 'recycle',
    contentRoles: {},
  },
  _search: {
    id: '_search',
    assets: [],
    childFolders: [],
    name: 'Search Results',
    parentId: null,
    type: 'virtual',
    contentRoles: {},
  },
}

const INITIAL_STATE = {
  byId: defaultFolders,
  recycleBin: '_recycle',
  activeFolder: '_root',
  searchFolder: '_search',
  newFolderName: '',
  deleting: false,
}

// TODO: forbid folders with duplicate names?
export default function folderReducer(state = INITIAL_STATE, action) {
  let result
  let newArray
  let newState

  switch (action.type) {
    case folderActions.LOAD_FOLDER_STATE: {
      result = {
        ...state,
        byId: action.payload.byId,
        recycleBin: action.payload.recycleBin,
        activeFolder: action.activeFolder,
      }
      result.byId['_search'] = defaultFolders['_search']
      return result
    }
    /*
     *  ADD_FOLDER_SUCCESS: Triggered by successful API call.  Add folder to redux store
     *    byId object, and to the parent folder's childFolders array.  Clear the folder name
     *  from store.
     *  @Param: name - string
     *  @Param: parent - string ID of the parent folder
     */
    case folderActions.ADD_FOLDER_SUCCESS: {
      result = update(state, {
        byId: {
          [action.folder.id]: { $set: action.folder },
        },
        newFolderName: { $set: '' },
        activeFolder: { $set: action.folder.id },
      })

      if (
        action.folder.parentId &&
        !result.byId[action.folder.parentId].childFolders.includes(
          action.folder.id
        )
      ) {
        result = update(result, {
          byId: {
            [action.folder.parentId]: {
              childFolders: { $push: [action.folder.id] },
            },
          },
        })
      }

      return result
    }

    case folderActions.SET_VIRTUAL_FOLDERS: {
      result = state

      const existingVirtuals = Object.values(state.byId).filter(
        (folder) => folder.virtual
      )

      existingVirtuals.forEach((vf) => {
        result = update(result, {
          byId: {
            $unset: [vf.id],
            [vf.parentId]: {
              // reclaim assets from potentially-deleted virtual folders
              assets: { $push: vf.assets },
              childFolders: {
                $splice: [
                  [result.byId[vf.parentId].childFolders.indexOf(vf.id), 1],
                ],
              },
            },
          },
        })
      })

      action.virtualFolders.forEach((vf) => {
        result = update(result, {
          byId: {
            [vf.id]: { $set: vf },
          },
        })

        if (vf.parentId) {
          if (!result.byId[vf.parentId].childFolders.includes(vf.id)) {
            result = update(result, {
              byId: {
                [vf.parentId]: { childFolders: { $push: [vf.id] } },
              },
            })
          }

          // if a child virtual folder has assets, remove them from parent ownership
          result = update(result, {
            byId: {
              [vf.parentId]: {
                assets: {
                  $apply: (assets) =>
                    assets.filter((assetId) => !vf.assets.includes(assetId)),
                },
              },
            },
          })
        }
      })

      return result
    }

    /*
     *   RENAME_FOLDER_SUCCESS: triggered by right click context menu.  Changes the name of an
     *   existing folder
     *   @param: newName - the replacement name
     *   @param: id - the target folder ID
     */
    case folderActions.RENAME_FOLDER_SUCCESS: {
      return update(state, {
        byId: {
          [action.id]: { name: { $set: action.newName } },
        },
      })
    }

    /*
     *   RENAME_FOLDER_FAILURE: triggered when folder update fails.  Restores the name of an
     *   existing folder
     *   @param: id - the target folder ID
     */
    case folderActions.RENAME_FOLDER_FAILURE: {
      return update(state, {
        byId: {
          [action.id]: { name: { $set: state.byId[action.id].name } },
        },
      })
    }

    /*
     *  SET_ACTIVE_FOLDER: set the activeFolder to the ID of the folder that is
     *  selected by the user.
     *  param: id - string ID of the selected folder
     */
    case folderActions.SET_ACTIVE_FOLDER: {
      if (action.id) {
        localStorage.setItem('activeFolder', action.id)
      } else {
        localStorage.removeItem('activeFolder')
      }
      return update(state, {
        activeFolder: { $set: action.id },
      })
    }

    case appActions.SET_ACTIVE_VIEW: {
      return {
        ...state,
        activeFolder: null,
      }
    }
    /*
     * SET_NEW_FOLDERNAME - Sets the folder name when user is adding a new folder
     */
    case folderActions.SET_NEW_FOLDERNAME: {
      return Object.assign({}, state, { newFolderName: action.name })
    }

    /*
     * CREATE_ASSET_SUCCESS - Action issued when asset upload completes succesfully
     *  processed here to add to parent's asset array
     */
    case assetActions.CREATE_ASSET_SUCCESS: {
      return update(state, {
        byId: {
          [action.parent]: {
            assets: {
              $push: [action.asset.id],
            },
          },
        },
      })
    }

    /*
     * CREATE_ASSET_SUCCESS - Action issued when asset upload completes succesfully
     *  processed here to add to parent's asset array
     */
    case builderActions.CREATE_BUILDER_SUCCESS: {
      return update(state, {
        byId: {
          [action.parent]: {
            assets: {
              $push: [action.asset.id],
            },
          },
        },
      })
    }

    case folderActions.MOVE_FOLDER_SUCCESS: {
      const folder = state.byId[action.dragId]
      const parent = state.byId[folder.parentId]

      result = update(state, {
        byId: {
          [action.dropId]: {
            childFolders: {
              $apply: (childFolders) => {
                if (action.newIndex > -1)
                  if (action.newIndex === 0)
                    childFolders = [action.dragId, ...childFolders]
                  else
                    childFolders = [
                      ...childFolders.slice(0, action.newIndex),
                      action.dragId,
                      ...childFolders.slice(action.newIndex),
                    ]
                else childFolders.push(action.dragId)

                return childFolders
              },
            },
          },
          [action.dragId]: {
            parentId: {
              $set: action.dropId,
            },
          },
        },
      }) //end of update

      if (parent) {
        result = update(result, {
          byId: {
            [parent.id]: {
              childFolders: {
                $splice: [[parent.childFolders.indexOf(folder.id), 1]],
              },
            },
          },
        })
      }

      return result
    }

    /*
     * DROP_ASSET - Moves an asset between folders
     */
    case assetActions.DROP_ASSET_SUCCESS: {
      let result = state
      action.dragId.forEach((id) => {
        result = update(result, {
          byId: {
            [action.dropId]: {
              assets: {
                $push: [id],
              },
            },
            [action.sourceFolder]: {
              assets: {
                $splice: [
                  [result.byId[action.sourceFolder].assets.indexOf(id), 1],
                ],
              },
            },
          },
        })
      })

      return result
    }
    case searchActions.CLEAR_SEARCH_RESULTS: {
      return update(state, {
        byId: {
          _search: {
            assets: {
              $set: [],
            },
          },
        },
      })
    }

    case searchActions.PROCESS_SEARCH_RESULTS: {
      return update(state, {
        byId: {
          _search: {
            assets: {
              $push: action.results,
            },
          },
        },
        activeFolder: { $set: '_search' },
      })
    }

    case searchActions.APPLY_SORT_SUCCESS:
      newArray = state.byId['_search'].assets.slice()
      newArray.sort((a, b) => {
        if (action.sortKey.type === 'number') {
          return (
            ((action.searchResultsById[a][action.sortKey.value] ||
              action.sortKey.defaultValue) -
              (action.searchResultsById[b][action.sortKey.value] ||
                action.sortKey.defaultValue)) *
            action.sortDirection
          )
        }
        return (
          (
            action.searchResultsById[a][action.sortKey.value] ||
            action.sortKey.defaultValue
          )
            .toString()
            .localeCompare(
              (
                action.searchResultsById[b][action.sortKey.value] ||
                action.sortKey.defaultValue
              ).toString(),
              { numeric: true }
            ) * action.sortDirection
        )
      })
      return update(state, {
        byId: {
          _search: {
            assets: {
              $set: newArray,
            },
          },
        },
      })

    case assetActions.CLONE_ASSET_SUCCESS: {
      return update(state, {
        byId: {
          [action.parentId]: {
            assets: {
              $splice: [
                [
                  state.byId[action.parentId].assets.indexOf(
                    action.assetToClone.id
                  ),
                  0,
                  action.clone.id,
                ],
              ],
            },
          },
        },
      })
    }

    case appActions.APPLY_LIBRARY_SORT_SUCCESS: {
      newArray = state.byId[state.activeFolder].assets.slice().sort((a, b) => {
        if (action.sortKey.type === 'number') {
          return (
            (((action.assets[a] && action.assets[a][action.sortKey.value]) ||
              action.sortKey.defaultValue) -
              ((action.assets[b] && action.assets[b][action.sortKey.value]) ||
                action.sortKey.defaultValue)) *
            action.sortDir
          )
        }
        const aSort = (
          (action.assets[a] && action.assets[a][action.sortKey.value]) ||
          action.sortKey.defaultValue
        ).toString()
        const bSort = (
          (action.assets[b] && action.assets[b][action.sortKey.value]) ||
          action.sortKey.defaultValue
        ).toString()
        return aSort.localeCompare(bSort) * action.sortDir
      })
      return update(state, {
        byId: {
          [state.activeFolder]: {
            assets: {
              $set: newArray,
            },
          },
        },
      })
    }

    case folderActions.DELETE_FOLDER_SUCCESS: {
      return update(state, {
        byId: {
          [action.parent]: {
            childFolders: {
              $splice: [
                [
                  state.byId[action.parent].childFolders.indexOf(action.child),
                  1,
                ],
              ],
            },
          },
          [action.child]: {
            deleted: {
              $set: true,
            },
          },
        },
      })
    }

    case endpointActions.PUBLISH_ASSET_SUCCESS: {
      newState = state
      action.assets.forEach((id) => {
        if (
          newState.byId[action.parent].assets &&
          newState.byId[action.parent].assets.indexOf(id) < 0
        ) {
          newState = update(newState, {
            byId: {
              [action.parent]: {
                assets: {
                  $push: [id],
                },
              },
            },
          })
        }
      })

      return newState
    }

    case endpointActions.PUBLISH_AND_APPROVE_ASSET_SUCCESS: {
      newState = state
      action.assets.forEach((id) => {
        if (
          newState.byId[action.parent].assets &&
          newState.byId[action.parent].assets.indexOf(id) < 0
        ) {
          newState = update(newState, {
            byId: {
              [action.parent]: {
                assets: {
                  $push: [id],
                },
              },
            },
          })
        }
      })

      return newState
    }

    case endpointActions.REVIEW_ASSET_SUCCESS: {
      if (action.statusType === endpointActions.REJECTED) {
        newState = state
        action.assets.forEach((asset) => {
          newState = update(newState, {
            byId: {
              [action.parent]: {
                assets: {
                  $apply: (x) => x.filter((a) => a !== asset),
                },
              },
            },
          })
        })
        return newState
      }
      return state
    }

    case endpointActions.REVIEW_UNPUBLISH_ASSET_SUCCESS: {
      if (action.statusType === endpointActions.UNPUBLISHED) {
        newState = state
        action.assets.forEach((asset) => {
          newState = update(newState, {
            byId: {
              [action.parent]: {
                assets: {
                  $apply: (x) => x.filter((a) => a !== asset),
                },
              },
            },
          })
        })
        return newState
      }
      return state
    }

    case endpointActions.UNPUBLISH_AND_APPROVE_ASSET_SUCCESS: {
      newState = state
      action.assets.forEach((asset) => {
        newState = update(newState, {
          byId: {
            [action.parent]: {
              assets: {
                $apply: (x) => x.filter((a) => a !== asset),
              },
            },
          },
        })
      })
      return newState
    }

    case folderActions.UPDATE_FOLDER_CONTENTROLES_SUCCESS: {
      return update(state, {
        byId: {
          [action.folder.id]: {
            contentRoles: {
              $set: action.contentRoles,
            },
          },
        },
      })
    }

    case folderActions.BULK_CONTENTROLE_UPDATE_SUCCESS: {
      newState = state
      Object.keys(action.contentRoles).forEach((folder) => {
        newState = update(newState, {
          byId: {
            [folder]: {
              contentRoles: {
                $set: action.contentRoles[folder],
              },
            },
          },
        })
      })
      return newState
    }

    case appActions.REFRESH_STATE: {
      newState = update(state, {
        byId: {
          $set: action.folderState.byId,
        },
      })
      return update(newState, {
        byId: {
          _search: {
            $set: state.byId['_search'],
          },
        },
      })
    }

    case permissionActions.DELETE_USER_SUCCESS: {
      newState = state
      result = Object.keys(newState.byId).filter(
        (folder) =>
          newState.byId[folder].contentRoles &&
          newState.byId[folder].contentRoles[action.user.email]
      )
      result.forEach((folder) => {
        newState = update(newState, {
          byId: {
            [folder]: {
              contentRoles: {
                $unset: [action.user.email],
              },
            },
          },
        })
      })
      return newState
    }

    case folderActions.EMPTY_RECYCLE_BIN_SUCCESS: {
      newState = update(state, {
        byId: {
          [state.recycleBin]: {
            assets: {
              $set: [],
            },
            childFolders: {
              $set: [],
            },
          },
        },
      })
      action.itemsToDelete.foldersToDelete.forEach((folder) => {
        newState = update(newState, {
          byId: {
            [folder]: {
              deleted: {
                $set: true,
              },
            },
          },
        })
      })
      return newState
    }

    case folderActions.REORDER_CHILD_FOLDERS:
      return update(state, {
        byId: {
          [action.parent]: {
            childFolders: {
              $apply: (childFolders) => {
                childFolders.splice(action.childIndex, 1, 'placeholder')
                childFolders.splice(action.newIndex, 0, action.child)
                childFolders = childFolders.filter(
                  (fldr) => fldr !== 'placeholder'
                )
                return childFolders
              },
            },
          },
        },
      })

    /*--------------------------------------------------------------*/
    default:
      //no recognition of this action type
      return state
  }
}

const getFoldersById = (state) => state.folders.byId

export const getAllFolders = createSelector(
  [getFoldersById],
  (foldersById) => foldersById
)
