import update from 'immutability-helper'
import * as actions from '../actions/invitation.js'

const INITIAL_STATE = {
  invitations: [],
}

export default function invitationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.GET_INVITATIONS_SUCCESS: {
      return {
        ...state,
        invitations: action.invitations,
      }
    }

    case actions.ADD_INVITATION_SUCCESS: {
      return update(state, {
        invitations: {
          $push: [action.invitation],
        },
      })
    }

    case actions.EDIT_INVITATION_SUCCESS: {
      let index = -1
      state.invitations.forEach((inv, i) => {
        if (inv.id === action.invite.id) index = i
      })
      return index >= 0
        ? update(state, {
            invitations: {
              $splice: [[index, 1, action.invite]],
            },
          })
        : state
    }

    case actions.DELETE_INVITATION_SUCCESS: {
      let index = -1
      state.invitations.forEach((inv, i) => {
        if (inv.id === action.id) index = i
      })
      return index >= 0
        ? update(state, {
            invitations: {
              $splice: [[index, 1]],
            },
          })
        : state
    }

    default:
      return state
  }
}
