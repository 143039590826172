import Title from 'antd/lib/typography/Title'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  calculateImageSize,
  calculateNavzoneDimensions,
  calculateNavzonePosition,
} from './utils'
import { Link } from 'react-router-dom'

class NavigatorPreview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSlideIndex: 0,
      navigatorWidth: null,
      navigatorHeight: null,
    }
    this.navigatorRef = React.createRef()
  }

  /* 
  NOTE: 
  This window resize listener code has been reused from the Product Viewer. 
  If we make the upgrade to React 16.8, this should be made into a hook and reused in both places.
  */
  componentDidMount() {
    /* Resize Observer web API https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver */
    if (window.ResizeObserver) {
      this.observer = new ResizeObserver((entries) => {
        // We only want to watch the mainViewer container,
        // grab the first entry (entries is the list of elements being observed)
        const navigator = entries[0]
        // Update state with new width and height
        this.setState({
          navigatorWidth: navigator.contentRect.width,
          navigatorHeight: navigator.contentRect.height,
        })
      })
      console.log({ obs: this.observer, ref: this.navigatorRef })
      if (this.navigatorRef.current) {
        this.observer.observe(this.navigatorRef.current)
      }
    } else {
      console.error('ResizeObserver not supported')
    }
  }
  componentWillUnmount() {
    this.observer?.disconnect && this.observer.disconnect()
  }

  render() {
    const { asset, allAssets } = this.props
    const { currentSlideIndex, navigatorHeight, navigatorWidth } = this.state
    const {
      navigator: { slides },
    } = asset
    const currentSlide = slides[currentSlideIndex]
    const { size, mainImage: mainImageAssetId, navzones } = currentSlide
    const mainImageUrl = allAssets[mainImageAssetId]?.url || null

    const setCurrentSlideIndex = (index) =>
      this.setState({ currentSlideIndex: index })

    return mainImageUrl ? (
      <div
        style={{
          ...styles.navigator,
          backgroundImage: `url(${mainImageUrl})`,
        }}
        ref={this.navigatorRef}
      >
        {navzones.map((navzone) => (
          <Navzone
            {...{
              navZone: navzone,
              size,
              navigatorWidth,
              navigatorHeight,
              slides,
              setCurrentSlideIndex,
            }}
            key={navzone.id}
          />
        ))}
      </div>
    ) : (
      <ErrorLoadingImage />
    )
  }
}

const Navzone = ({
  navZone,
  size,
  navigatorWidth,
  navigatorHeight,
  slides,
  setCurrentSlideIndex,
}) => {
  if (!navZone || !navigatorWidth || !navigatorHeight) return null
  const imageSize = calculateImageSize(size, {
    width: navigatorWidth,
    height: navigatorHeight,
  })
  const { width, height } = calculateNavzoneDimensions(navZone, imageSize)
  const { left, bottom } = calculateNavzonePosition(navZone, imageSize, {
    width: navigatorWidth,
    height: navigatorHeight,
  })

  if (navZone.targetType === 'slide') {
    const onNavzoneClick = () => {
      const targetSlide = slides
        .map((slide, index) => ({ ...slide, index }))
        .find((slide) => slide.id === navZone.targetId)
      // Find the index of the target slide
      // Set the current slide index to the target slide index
      setCurrentSlideIndex(targetSlide.index)
    }
    return (
      <Zone
        clickHandler={onNavzoneClick}
        {...{ left, bottom, width, height }}
      />
    )
  } else if (navZone.targetType === 'asset') {
    return (
      <Link to={`/library/asset/${navZone.targetId}`}>
        <Zone {...{ left, bottom, width, height }} />
      </Link>
    )
  } else if (navZone.targetType === 'folder') {
    return (
      <a href={`/library/${navZone.targetId}`}>
        <Zone {...{ left, bottom, width, height }} />
      </a>
    )
  }
}

class Zone extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hover: false,
    }
  }

  render() {
    const { clickHandler, left, bottom, width, height } = this.props
    const extraStyle = this.state.hover ? styles.navZoneHover : {}
    return (
      <div
        style={{
          ...styles.navZone,
          left,
          bottom,
          width,
          height,
          ...extraStyle,
        }}
        onClick={clickHandler || undefined}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      ></div>
    )
  }
}

const ErrorLoadingImage = () => {
  return (
    <div style={{ width: '50%' }}>
      <Title style={{ color: 'white', textAlign: 'center' }} level={2}>
        Sorry, we couldn&apos;t find the asset you were looking for.
      </Title>
      <br />
      <Title style={{ color: 'lightgray', textAlign: 'center' }} level={3}>
        If you&apos;ve confirmed the asset is in the library, you can contact
        support with the help button in the top right corner.
      </Title>
    </div>
  )
}

const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // This will be the interactive image
  navigator: {
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  // Generic styles for the nav zones
  navZone: {
    position: 'absolute',
    cursor: 'pointer',
  },
  navZoneHover: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: '1px solid #999',
  },
}

NavigatorPreview.propTypes = {
  asset: PropTypes.object,
  type: PropTypes.string,
}

export default NavigatorPreview
