import { Button, Form, Input } from 'antd'
import React from 'react'
import {
  APPROVED,
  PENDING_APPROVAL,
  REJECTED,
  UNPUBLISHED,
} from '../../dux/actions/endpoint'
import '../styles/Modal.css'

const FormItem = Form.Item

class ReviewAssetForm extends React.Component {
  approve = () => {
    this.props.reviewFunc(
      this.props.assets,
      this.props.activeFolder,
      this.form.getFieldValue('comments'),
      this.props.statusType === PENDING_APPROVAL ? APPROVED : UNPUBLISHED
    )
    this.props.closeForm()
  }

  reject = () => {
    this.form.validateFields((err, values) => {
      if (!err) {
        this.props.reviewFunc(
          this.props.assets,
          this.props.activeFolder,
          values.comments,
          this.props.statusType === PENDING_APPROVAL ? REJECTED : APPROVED
        )
        this.props.closeForm()
      }
    })
  }

  saveFormRef = (form) => (this.form = form)

  render() {
    return (
      <WrappedForm
        assets={this.props.assets}
        ref={this.saveFormRef}
        onApprove={this.approve}
        onReject={this.reject}
        statusType={this.props.statusType}
      />
    )
  }
}

const WrappedForm = Form.create()((props) => {
  const { form } = props
  const { getFieldDecorator } = form

  return (
    <Form layout="vertical" hideRequiredMark>
      {props.assets.length > 1 && (
        <div style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
          There are{' '}
          <span className="modal__bulkedit-highlight">
            {props.assets.length}
          </span>{' '}
          files in this batch
        </div>
      )}
      <FormItem label="Your Comments">
        {getFieldDecorator('comments', {
          rules: [
            {
              required: true,
              message: `You must enter a comment to ${
                props.statusType === 'PENDING_APPROVAL' ? 'reject' : 'keep'
              } an asset`,
            },
          ],
        })(<Input.TextArea rows={3} />)}
      </FormItem>
      <FormItem>
        <Button
          type="primary"
          onClick={props.onApprove}
          style={{ marginRight: 10 }}
        >
          {props.statusType === 'PENDING_APPROVAL' ? 'Approve' : 'Remove'}
        </Button>
        <Button
          type={props.statusType === 'PENDING_APPROVAL' ? 'danger' : 'default'}
          onClick={props.onReject}
        >
          {props.statusType === 'PENDING_APPROVAL' ? 'Reject' : 'Keep'}
        </Button>
      </FormItem>
    </Form>
  )
})

export default ReviewAssetForm
