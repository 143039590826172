import {
  APPROVED,
  PENDING_APPROVAL,
  PENDING_REMOVAL,
} from '../../dux/actions/endpoint'
import Import from '../BAM_UI/icons/Import'

export const LIBRARY_BULK_ACTIONS = [
  {
    action: 'edit',
    icon: 'fa fa-pencil',
    altIcon: '',
    tooltipText: 'Edit Asset',
    privilege: 'editmetadata',
    multiSelect: true,
  },
  {
    action: 'delete',
    icon: 'fa fa-trash',
    altIcon: '',
    tooltipText: 'Delete',
    privilege: 'deleteasset',
    multiSelect: true,
  },
  {
    action: 'publish',
    icon: 'fa fa-cloud-upload',
    altIcon: '',
    tooltipText: 'Publish',
    privilege: 'publishasset',
    multiSelect: true,
  },
  {
    action: 'move',
    icon: { component: Import, className: 'library__header-custom-icon' },
    tooltipText: 'Move to Library',
    privilege: 'reviewugc',
    multiSelect: true,
  },
  {
    action: 'share',
    icon: 'fa fa-share-alt',
    altIcon: '',
    tooltipText: 'Share',
    privilege: 'viewfolder',
    multiSelect: true,
  },
  {
    action: 'download',
    icon: 'fa fa-download',
    altIcon: '',
    tooltipText: 'Download',
    privilege: 'viewfolder',
    multiSelect: true,
  },
  {
    action: 'selectall',
    icon: 'fa fa-check-square-o',
    altIcon: 'fa fa-square-o',
    tooltipText: 'Select All',
    privilege: 'viewfolder',
    multiSelect: false,
  },
]
export const ENDPOINT_BULK_ACTIONS = [
  {
    action: 'review',
    icon: 'fa fa-eye',
    altIcon: '',
    tooltipText: 'Review',
    privilege: 'approveasset',
    statusType: PENDING_APPROVAL,
    multiSelect: true,
  },
  {
    action: 'share',
    icon: 'fa fa-share-alt',
    altIcon: '',
    tooltipText: 'Share',
    privilege: 'viewfolder',
    statusType: APPROVED,
    multiSelect: true,
  },
  {
    action: 'unpublish_review',
    icon: 'fa fa-eye',
    altIcon: '',
    tooltipText: 'Unpublish Review',
    privilege: 'approveasset',
    statusType: PENDING_REMOVAL,
    multiSelect: true,
  },
  {
    action: 'unpublish',
    icon: 'fa fa-cloud-download',
    altIcon: '',
    tooltipText: 'Unpublish',
    privilege: 'unpublishasset',
    statusType: APPROVED,
    multiSelect: true,
  },
  {
    action: 'selectall',
    icon: 'fa fa-check-square-o',
    altIcon: 'fa fa-square-o',
    tooltipText: 'Select All',
    privilege: 'viewfolder',
    multiSelect: false,
  },
]
