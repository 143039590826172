import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import SessionTimeoutError from '../modules/SessionTimeoutError'
import { findLibraryFolder } from '../modules/utils'
import * as appActions from './appActions'
import * as folderActions from './folder'
import * as modalActions from './modal'
import * as userActions from './user'

export const SET_QUERY = 'SET_QUERY'
export const SUBMIT_SEARCH = 'SUBMIT_SEARCH'
export const PROCESS_SEARCH_RESULTS = 'PROCESS_SEARCH_RESULTS'
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS'
export const SET_SORT_KEY = 'SET_SORT_KEY'
export const TOGGLE_SORT_DIR = 'TOGGLE_SORT_DIR'
export const APPLY_SORT = 'APPLY_SORT'
export const APPLY_SORT_SUCCESS = 'APPLY_SORT_SUCCESS'
export const SET_LAST_SEARCH = 'SET_LAST_SEARCH'

export function setQuery(query) {
  return {
    type: SET_QUERY,
    query,
  }
}

export function setSortKey(obj) {
  return {
    type: SET_SORT_KEY,
    key: obj,
  }
}

export function toggleSortDir() {
  return {
    type: TOGGLE_SORT_DIR,
  }
}

export function setLastSearch(search) {
  return {
    type: SET_LAST_SEARCH,
    search,
  }
}

export function applySort() {
  return {
    type: APPLY_SORT,
  }
}

export function* watchApplySort() {
  yield takeEvery(APPLY_SORT, applySortSaga)
}

export function* applySortSaga() {
  const state = yield select((state) => state.search)
  yield put({
    type: APPLY_SORT_SUCCESS,
    sortKey: state.sortKey,
    searchResultsById: state.searchResultsById,
    sortDirection: state.sortDirection,
  })
}

export function submitSearch(query, page = 0) {
  return {
    type: SUBMIT_SEARCH,
    query,
    page,
  }
}

export function* watchSubmitSearch() {
  yield takeEvery(SUBMIT_SEARCH, submitSearchSaga)
}

export function* submitSearchSaga(action) {
  try {
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
    //Navigate to library, just in case
    yield put({ type: appActions.SET_MENU_ITEM, item: 'library' })
    // clear selected
    yield put(appActions.clearSelectedAssets())
    // Clear prior results
    if (action.page === 0) {
      yield put({ type: CLEAR_SEARCH_RESULTS })
    }

    const assetsById = yield select((state) => state.assets.byId)
    let matchingAssetIds = []

    if (action.query.length > 0) {
      let searchText = action.query.toLowerCase()
      const quotedTokens = searchText.match(/"(?:\\.|[^\\])*?"/i)
      const validatedQuotedTokens = []

      if (quotedTokens !== null) {
        for (let idx = 0; idx < quotedTokens.length; ++idx) {
          let token = quotedTokens[idx]

          if (token.length > 2) {
            searchText = searchText.replace(token, '')
            validatedQuotedTokens.push(token.substring(1, token.length - 1))
          }
        }
      }

      // collect tokens
      let tokens = searchText.split(/[\s|]/)

      // determine search type
      const andSearch = tokens.includes('and')
      const simpleOrSearch = !andSearch && tokens.includes('or')

      // cleanup tokens
      tokens = tokens.concat(validatedQuotedTokens)
      tokens = tokens.filter(
        (token) => token.length > 0 && token !== 'or' && token !== 'and'
      )

      // fetch assets data and prep results array
      let assets = Object.entries(assetsById)

      if (simpleOrSearch) {
        // MATCH ANY (SIMPLE OR)
        const match = new RegExp(`${tokens.join('|')}`, 'i')

        for (const [assetKey, assetData] of assets) {
          const stringValue = JSON.stringify(assetData)

          if (stringValue.search(match) !== -1) {
            matchingAssetIds.push(assetKey)
          }
        }
      } else {
        // MATCH ALL (AND) / WEIGHTED OR
        for (const token in tokens) {
          const match = new RegExp(tokens[token], 'i')

          for (const [assetKey, assetData] of assets) {
            const stringValue = JSON.stringify(assetData)

            if (stringValue.search(match) !== -1) {
              if (!andSearch && matchingAssetIds.includes(assetKey)) {
                // WEIGHTED OR (default) search
                matchingAssetIds = matchingAssetIds.filter(
                  (item) => item !== assetKey
                )
                matchingAssetIds.unshift(assetKey)
              } else {
                matchingAssetIds.push(assetKey)
              }
            }
          }

          if (andSearch && parseInt(token) !== tokens.length - 1) {
            // AND: filter the searched assets for the next pass
            const matchingAssetIdsCopy = matchingAssetIds.slice()
            assets = assets.filter((a) => matchingAssetIdsCopy.includes(a[0]))
            matchingAssetIds = []
          }
        }
      }
    }

    const folderState = yield select((state) => state.folders)
    const { endpoints } = yield select((state) => state.endpoints)
    const results = matchingAssetIds.filter((assetId) => {
      const libFolder = findLibraryFolder(assetId, folderState.byId, endpoints)

      return assetsById[assetId] && libFolder !== folderState.recycleBin
    })

    yield put({ type: PROCESS_SEARCH_RESULTS, results, assetsById })

    // apply sort to results
    const searchState = yield select((state) => state.search)

    if (searchState.sortKey.value !== '_score') {
      yield put({
        type: APPLY_SORT_SUCCESS,
        sortKey: searchState.sortKey,
        searchResultsById: searchState.searchResultsById,
        sortDirection: searchState.sortDirection,
      })
    }

    yield call(folderActions.activeFolder, '_search')
  } catch (error) {
    console.error('search error', error)
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put(
        modalActions.setMessage({
          type: 'ERROR',
          msg:
            error.clientMessage || 'An error occurred while executing search',
        })
      )
      yield put(modalActions.openModal('MessageModal'))
    }
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

export default all([watchSubmitSearch(), watchApplySort()])
