import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Tag } from 'antd'
import moment from 'moment'
import * as helpers from '../../../helpers'
import AnalyticsTemporalLineGraph from '../Plots/AnalyticsTemporalLineGraph'
import AnalyticsUserTable from '../Plots/AnalyticsUserTable'

const dateStr = (momentObj) => {
  return momentObj.format('YYYYMMDD')
}

// ****************
// React Components
// ****************
class UserActivity extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeDate: null,
      selectedUser: null,
    }
  }

  componentDidUpdate(prevProps) {
    const { dateRange } = this.props

    if (
      dateStr(dateRange[0]) !== dateStr(prevProps.dateRange[0]) ||
      dateStr(dateRange[1]) !== dateStr(prevProps.dateRange[1])
    ) {
      this.clearSelectedDate()
      this.setState({ selectedUser: null })
    }
  }

  clearSelectedDate = () => {
    this.setActiveDate(null)
  }

  clearSelectedUser = () => {
    this.setState({ selectedUser: null })
  }

  onExport = () => {
    // fetch data for export
    const { allData, allUsers, dateRange, activeAnalyticsPlatform } = this.props
    const { selectedDate, selectedUser } = this.state

    this.props.exportTable(
      helpers.gatherUserDataForExport({
        dataObj: allData,
        eventType: 'userDays',
        allUsers,
        selectedUser,
        bamPlatform: activeAnalyticsPlatform,
        dateRange: selectedDate || dateRange,
      })
    )
  }

  onUserSelected = (id) => {
    if (this.state.selectedUser && this.state.selectedUser.id === id) {
      this.setState({ selectedUser: null })
    } else {
      this.setState({
        selectedUser: Object.values(this.props.allUsers).find(
          (u) => u.id === id
        ),
      })
    }
  }

  setActiveDate = (date) => {
    if (this.state.activeDate === date) {
      this.setState({ activeDate: null })
    } else {
      this.setState({ activeDate: date })
    }
  }

  setShowInactive = ({ target }) => {
    this.setState({ showInactive: target.checked })
  }

  render() {
    const { allData, allUsers, dateRange, activeAnalyticsPlatform } = this.props

    if (!allData) {
      return <div style={styles.container} />
    }

    const { activeDate, selectedUser, showInactive } = this.state
    const temporalData = helpers.formatDataForLineGraph({
      dataObj: allData,
      chartType: 'users',
      bamPlatform: activeAnalyticsPlatform,
      dateRange,
      selectedUserFilter: selectedUser && selectedUser.id,
    })

    const graphData = temporalData && temporalData.dataList
    const graphDataYMax = temporalData && temporalData.max

    const tableData = helpers.formatDataForUserTable({
      activeDate,
      allUsers,
      bamPlatform: activeAnalyticsPlatform,
      dataObj: allData,
      dateRange,
      showInactive,
    })

    const dateTxt = moment(activeDate).format('MMM D, YYYY')
    const dateTag = !activeDate ? null : (
      <Tag
        key={dateTxt}
        closable={true}
        style={styles.tagStyle}
        onClose={this.clearSelectedDate}
      >
        {dateTxt}
      </Tag>
    )
    const userTag = !selectedUser ? null : (
      <Tag
        key={selectedUser}
        closable={true}
        style={styles.tagStyle}
        onClose={this.clearSelectedUser}
      >
        {selectedUser.email}
      </Tag>
    )

    return (
      <div style={styles.container}>
        <div style={styles.filterTags}>
          {dateTag}
          {userTag}
        </div>
        <AnalyticsTemporalLineGraph
          dateRange={dateRange}
          graphData={graphData}
          graphTitle={'Activity by Day'}
          onSelectPoint={selectedUser ? () => {} : this.setActiveDate}
          selectedPoint={activeDate}
          selectedUser={selectedUser}
          xTitle={'Date'}
          yTitle={'Active Users'}
          yMax={graphDataYMax}
        />

        <AnalyticsUserTable
          activeDate={activeDate}
          onClearSelection={this.clearSelectedDate}
          onExportClick={this.onExport}
          onUserSelected={activeDate ? () => {} : this.onUserSelected}
          selectedUser={selectedUser}
          setShowInactive={this.setShowInactive}
          tableData={tableData}
        />
      </div>
    )
  }
}

// ******
// Styles
// ******
const styles = {
  container: {
    position: 'relative',
  },
  tagStyle: {
    marginBottom: 10,
    cursor: 'auto',
    color: '#1890ff',
    fontWeight: 'bold',
  },
  filterTags: { marginTop: -10 },
}

// *********
// PropTypes
// *********
UserActivity.propTypes = {
  allData: PropTypes.object,
  allUsers: PropTypes.object,
  activeAnalyticsView: PropTypes.string,
  activeAnalyticsPlatform: PropTypes.string,
  activeAnalyticsDuration: PropTypes.string,
  analyticsCustomDateRange: PropTypes.arrayOf(PropTypes.string),
  setActiveAnalyticsView: PropTypes.func,
  dateRange: PropTypes.arrayOf(PropTypes.object),
}

export default UserActivity
