import { Select } from 'antd'
import React from 'react'

const StatusFilter = (props) => {
  const Option = Select.Option
  const cssClass = props.selectedFilter !== 'ALL' ? '-active' : ''
  return (
    <div className="library__controls library__controls-set">
      <Select
        key={props.selectedFilter}
        value={props.selectedFilter}
        onChange={props.onFilterChange}
        dropdownMatchSelectWidth={false}
      >
        {Object.keys(props.options).map((opt) => (
          <Option
            key={props.options[opt].value}
            value={props.options[opt].value}
          >
            {props.options[opt].displayValue}
          </Option>
        ))}
      </Select>
      &nbsp;
      <i className={`fa fa-2x fa-filter statusfilter${cssClass}`} />
    </div>
  )
}

export default StatusFilter
