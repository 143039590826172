import PropTypes from 'prop-types'
import React from 'react'
import { ContextMenu, MenuItem } from 'react-contextmenu'
import * as folderConstants from '../../dux/constants/folder'
import { validatePrivilege } from '../../dux/modules/utils'

const FolderMenu = (props) => {
  const { id, trigger, allFolders } = props

  const handleItemClick = trigger ? trigger.onItemClick : null

  const folderType = trigger
    ? allFolders[trigger.name].type
    : folderConstants.INVALID_FOLDER_TYPE

  const actions = folderConstants.FOLDER_TYPE_ACTIONS[
    folderType
  ].actions.filter((action) => {
    let allowed = true
    action.exceptions.forEach((exception) => {
      if (allowed)
        allowed = !exception(
          trigger.name,
          allFolders,
          folderConstants,
          folderType
        )
    })
    return allowed
  })

  return (
    <ContextMenu
      id={id}
      hideOnLeave={true}
      className={actions.length === 0 ? 'hide' : ''}
    >
      {actions.map((action) => {
        return (
          <MenuItem
            key={action.type}
            onClick={handleItemClick}
            data={{
              action: action.type,
              folderId: trigger.name,
              relationships: props.relationships,
            }}
            disabled={
              !validatePrivilege(
                folderConstants.FOLDER_ACTIONS[action.type].privilege,
                props.privileges,
                props.email,
                props.groups,
                trigger.name,
                props.allFolders
              )
            }
          >
            {action.type}
          </MenuItem>
        )
      })}
    </ContextMenu>
  )
}

FolderMenu.propTypes = {
  id: PropTypes.string.isRequired,
  trigger: PropTypes.shape({
    name: PropTypes.string,
    onItemClick: PropTypes.func,
  }),
  bin: PropTypes.string,
  allFolders: PropTypes.object,
  privileges: PropTypes.object,
  groups: PropTypes.object,
  email: PropTypes.string,
  relationships: PropTypes.object,
}

export default FolderMenu
