import React from 'react'
import '../styles/LoadingModal.css'

const LoadingModal = (props) => {
  const css = props.loading ? 'show' : 'hide'
  return (
    <div className={`loading_${css}`}>
      <i className={`fa fa-5x fa-spinner fa-spin centered_${css}`} />
    </div>
  )
}

export default LoadingModal
