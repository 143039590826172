import { Button } from 'antd'
import React from 'react'
import AutoScrollFooter from './AutoScrollFooter'

/*
 * An initial search requests 250 results.  They are displayed in batches of 24
 * cards using the AutoScrollFooter.  If the user wants to scroll past more than
 * 250 results, they see a button to request more from the server.
 */
const SearchFooter = (props) => {
  if (props.max < props.folder.assets.length) {
    return (
      <AutoScrollFooter max={props.max} length={props.folder.assets.length} />
    )
  }
  if (props.folder.assets.length < props.search.searchResults.length) {
    return (
      <div className="library-header">
        <span className="library-title">
          <Button
            onClick={() => {
              props.actions.submitSearch(
                props.search.lastSearch,
                props.search.nextPage
              )
            }}
          >
            Get More Results
          </Button>
        </span>
      </div>
    )
  } else {
    return null
  }
}

export default SearchFooter
