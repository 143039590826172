import { testCalculateFunction, testConfig } from './testingData'
import utils from './utils'

function validateCalcFunction(calculateFn, ...sampleArgs) {
  try {
    if (typeof calculateFn !== 'function')
      throw new Error(
        `Expected calculate to be type: Function, Got: ${typeof calculateFn}`
      )
    return calculateFn(...sampleArgs)
  } catch (error) {
    console.error(error)
    throw error
  }
}
const initialInputValues = (inputFields) => {
  const initialInputState = {}
  inputFields.forEach(({ sectionData }) =>
    sectionData.forEach((field) => {
      const { id, defaultValue } = field
      initialInputState[id] = defaultValue
    })
  )
  return initialInputState
}
export const formatStateFromJSON = ({ diagram, ...calcJSON }) => {
  const inputValues = initialInputValues(calcJSON.inputFields)
  const { args, functionBody } = calcJSON.calculate
  const calculate = testCalculateFunction || Function(...args, functionBody)
  const calcResults = validateCalcFunction(
    calculate,
    inputValues,
    calcJSON.lookupData,
    utils
  )

  return {
    calculate,
    inputValues,
    calcConfig: { ...calcJSON, diagram },
    showResults: false,
    errorInputs: [],
    defaultValues: { ...inputValues },
    results: { ...calcResults },
  }
}
export const getCalculatorConfig = (url) =>
  testConfig
    ? Promise.resolve(testConfig)
    : fetch(url).then((fetchResponse) => fetchResponse.json())
