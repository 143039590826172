import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActions from '../../dux/actions/appActions'

class ResetPasswordSuccess extends React.Component {
  componentDidMount() {
    this.props.appActions.clearResetPasswordFlag()
  }

  render() {
    return (
      <div>
        <div className="public__phone-icon">
          <img
            className="public__logo"
            src={`${this.props.config.baseUrl}/BAM-logo-v2.svg`}
            alt="logo"
          />
        </div>

        <div className="forgot-success">
          <div className="forgot-success__text-large">Password Changed</div>
          <div className="forgot-success__text-small">
            {
              "You've successfully changed your password.  It's time to get back to work!"
            }
          </div>
        </div>
      </div>
    )
  }
}

ResetPasswordSuccess.propTypes = {
  appActions: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appActions, dispatch),
})

const mapStateToProps = (state) => ({
  config: state.config.appConfig,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordSuccess)
