import { Modal } from 'antd'
import update from 'immutability-helper'
import md5 from 'md5'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActions from '../../../dux/actions/appActions'
import * as assetActions from '../../../dux/actions/asset'
import * as builderActions from '../../../dux/actions/builders'
import * as folderActions from '../../../dux/actions/folder'
import * as modalActions from '../../../dux/actions/modal'
import * as settingsActions from '../../../dux/actions/settings'
import {
  BUILDERS_WITH_SLIDES,
  COMPARISON__BUILDER_TYPE,
  FEATURE__BUILDER_TYPE,
  FEATURE__MAX_DISPLAY_TEXT_LENGTH,
  HOTSPOT__MAX_DISPLAY_TEXT_LENGTH,
  NAVIGATOR__BUILDER_TYPE,
  NEWS__BUILDER_TYPE,
  PRODUCT__BUILDER_TYPE,
} from '../../../dux/constants/builders'
import {
  buildPath,
  downloadAsset,
  findLibraryFolder,
  getEndpointById,
} from '../../../dux/modules/utils'
import AssetBuilderView from './AssetBuilderView'
import {
  callbackForBuilderSection,
  getNavzoneParentSlide,
  getZonesForTarget,
  iconForBuilder,
  modalTitleForBuilderSection,
  prepComparisonDataForTable,
} from './utils'

class AssetBuilder extends React.Component {
  constructor(props) {
    super(props)
    const {
      appActions,
      allAssets,
      match,
      builderActions,
      endpoints,
      allFolders,
      settingsActions,
    } = this.props
    settingsActions.getSetting('builders')
    appActions.scrollToTop('main-content')
    if (allAssets[match.params.id]) {
      const folder = findLibraryFolder(match.params.id, allFolders, endpoints)
      const path = buildPath(folder, allFolders)
      builderActions.editBuilder(
        allAssets[match.params.id].asset_type,
        folder,
        allAssets[match.params.id],
        path,
        this.openSelectAssetModal,
        this.removeComparisonImage,
        this.isComparisonRowEditing,
        this.setEditComparisonRow
      )
    } else if (!this.props.builderData.isEdit)
      builderActions.setKeyForBuilder(this.props.builderType, {
        id: md5(`${Date.now()}`),
      })
  }

  componentWillUnmount() {
    this.props.builderActions.resetBuilderData(this.props.builderType)
  }

  saveFormRef = (ref) => (this.currentForm = ref)
  saveTagsRef = (ref) => (this.tags = ref)
  saveSlideDeckRef = (ref) => (this.slideDeck = ref)
  saveSimulatorRef = (ref) => (this.simulator = ref)

  // Cancel Button
  onCancel = () => {
    const { builderActions, builderType, builderData, history, appActions } =
      this.props
    builderActions.resetBuilderData(builderType)
    if (builderData.isEdit) appActions.setMenuItem('library')
    history.goBack()
  }

  // **** SAVE TO DATABASE **** //
  saveToDb = () => {
    const { builderType, builderData, builderActions, history } = this.props
    if (builderData.isEdit) {
      if (this.tags) builderData.tags = this.tags.getTags()
      builderActions.updateBuilder(builderType, builderData, history)
    } else {
      builderActions.saveDataForBuilder(builderType, builderData, history)
    }
  }

  // Figure out what the "next" button does based on current state
  nextAction = () => {
    const { builderActions, builderType, builderData, modalActions } =
      this.props
    if (builderData.activeScreen === 'meta') {
      if (builderData.isEdit) {
        return this.currentForm.validateFields((err, values) => {
          if (!err) {
            this.props.builderActions.setKeyForBuilder(
              this.props.builderType,
              values
            )
            this.saveTags(() => {
              if (
                builderType === NEWS__BUILDER_TYPE ||
                builderType === FEATURE__BUILDER_TYPE ||
                builderType === COMPARISON__BUILDER_TYPE
              ) {
                this.saveToDb()
              }

              if (
                builderType === PRODUCT__BUILDER_TYPE ||
                builderType === NAVIGATOR__BUILDER_TYPE
              ) {
                builderActions.setKeyForBuilder(builderType, {
                  activeScreen: `${builderType}EditSlide`,
                  activeSlide: builderData.activeSlide || builderData.slides[0],
                })
              }
            })
          }
        })
      }
      return this.currentForm.validateFields((err, values) => {
        if (!err) {
          this.props.builderActions.setKeyForBuilder(
            this.props.builderType,
            values
          )
          this.saveTags(() => {
            builderActions.setKeyForBuilder(builderType, {
              activeScreen: 'create',
            })
          })
        }
      })
    }
    if (builderData.activeScreen === 'create') {
      if (
        builderType === NEWS__BUILDER_TYPE ||
        builderType === FEATURE__BUILDER_TYPE
      ) {
        return this.saveToDb()
      }
      if (
        builderType === PRODUCT__BUILDER_TYPE ||
        builderType === NAVIGATOR__BUILDER_TYPE
      ) {
        // If no slides, add a default slide and got to addSlide form
        if (builderData.slides.length === 0)
          return builderActions.createSlideForBuilder(builderType, null)
        // If current slide has an image, just go to editSlide form
        if (builderData.activeSlide)
          return builderActions.setKeyForBuilder(builderType, {
            activeScreen: `${builderType}EditSlide`,
          })
        // Otherwise go to the first slide
        return builderActions.setKeyForBuilder(builderType, {
          activeScreen: `${builderType}EditSlide`,
          activeSlide: builderData.slides[0],
        })
      }
      if (builderType === COMPARISON__BUILDER_TYPE) {
        if (builderData.datafileKey) return this.saveToDb()
        return modalActions.openModal('UploadModal', {
          type: 'support',
          multiple: false,
          target: 'comparisons',
          callback: this.getComparisonData,
        })
      }
    }
    if (builderData.activeScreen === 'addSlide')
      return builderActions.replaceSlideForBuilder(
        builderType,
        builderData.activeSlide.id,
        builderData.pickerAsset
      )
    if (builderData.activeScreen === `${builderType}EditSlide`)
      return this.saveToDb()
  }

  prevAction = () => {
    const { builderActions, builderType, builderData } = this.props
    if (builderData.activeScreen === 'create')
      return builderActions.setKeyForBuilder(builderType, {
        activeScreen: 'meta',
        activeSlide: null,
      })

    if (builderData.activeScreen === 'addSlide') {
      if (builderData.isEdit) {
        this.handleDeleteSlide()
        return builderActions.setKeyForBuilder(builderType, {
          activeScreen: 'meta',
          activeSlide: null,
        })
      }
      if (
        builderType === NAVIGATOR__BUILDER_TYPE &&
        builderData.activeNavzone &&
        builderData.activeNavzone.targetType === 'unassigned'
      )
        this.removeTarget(
          'navzone',
          builderData.activeNavzone.id,
          getNavzoneParentSlide(
            builderData.activeNavzone.id,
            builderData.slides
          )
        )
      // Delete slide, set proper active slide
      this.handleDeleteSlide()
      // Go back, and deselect the picker Asset
      return builderActions.setKeyForBuilder(builderType, {
        activeScreen: 'create',
        pickerAsset: null,
        pickerNode: builderData.targetSaveFolder,
      })
    }
    if (builderData.activeScreen === 'viewRelatedAsset') {
      if (builderType === FEATURE__BUILDER_TYPE)
        return builderActions.setKeyForBuilder(builderType, {
          activeScreen: 'meta',
        })
      return builderActions.setKeyForBuilder(builderType, {
        activeScreen: `${builderType}EditSlide`,
      })
    }

    if (builderData.activeScreen === `${builderType}EditSlide`) {
      if (builderData.isEdit)
        return builderActions.setKeyForBuilder(builderType, {
          activeScreen: 'meta',
          activeSlide: null,
        })
      return builderActions.setKeyForBuilder(builderType, {
        activeScreen: 'create',
        pickerNode: builderData.pickerFolder,
        pickerAsset: null,
      })
    }
  }

  isNextDisabled = () => {
    const { builderType, builderData } = this.props

    if (builderType === NEWS__BUILDER_TYPE)
      if (builderData.activeScreen === 'meta')
        return (
          !builderData.title ||
          (builderData.contentType === 'pdf' && !builderData.pdfContent) ||
          (builderData.contentType === 'text' && !builderData.textContent)
        )

    if (builderType === FEATURE__BUILDER_TYPE)
      if (builderData.activeScreen === 'meta')
        return !builderData.title || !builderData.leadImage

    // Don't allow next/save if row is editing
    if (builderType === COMPARISON__BUILDER_TYPE)
      return builderData.editRow !== ''

    if (builderData.activeScreen === 'create')
      return !builderData.targetSaveFolder
  }

  getActionButtonLabel = () => {
    const { builderType, builderData } = this.props
    if (builderData.activeScreen === 'meta')
      if (
        builderType === NEWS__BUILDER_TYPE ||
        builderType === FEATURE__BUILDER_TYPE ||
        builderType === COMPARISON__BUILDER_TYPE
      )
        if (builderData.isEdit) return 'Save'
    if (builderData.activeScreen === 'create')
      if (
        builderType === NEWS__BUILDER_TYPE ||
        builderType === FEATURE__BUILDER_TYPE ||
        (builderType === COMPARISON__BUILDER_TYPE && builderData.datafileKey)
      )
        return 'Create'
    return 'Next'
  }

  // Saves the tags to redux store
  saveTags = (callback) => {
    this.tags.handleInputConfirm(null, () => {
      this.handleFormValuesChange({ tags: this.tags.getTags() })
      callback()
    })
  }

  addLeadImage = (asset) => {
    this.props.builderActions.setKeyForBuilder(this.props.builderType, {
      leadImage: asset,
    })
  }

  addPdfContent = (asset) => {
    this.props.builderActions.setKeyForBuilder(this.props.builderType, {
      pdfContent: asset,
    })
  }

  // Fires when a form value is changed
  handleFormValuesChange = (changedValue) => {
    this.props.builderActions.setKeyForBuilder(
      this.props.builderType,
      changedValue
    )
  }

  // Adds folder to the list of open folders for the picker to display.
  addSaveFolderKey = (folderId) => {
    const { openSaveFolderKeys } = this.props.builderData
    if (!openSaveFolderKeys.includes(folderId)) {
      this.props.builderActions.setKeyForBuilder(this.props.builderType, {
        openSaveFolderKeys: [...openSaveFolderKeys, folderId],
      })
    }
  }

  // Removes folder from the list of open folders for the picker to display.
  removeSaveFolderKey = (folderId) => {
    const { openSaveFolderKeys } = this.props.builderData
    this.props.builderActions.setKeyForBuilder(this.props.builderType, {
      openSaveFolderKeys: openSaveFolderKeys.filter((key) => key !== folderId),
    })
  }

  // For setting the active folder when picker is opened
  setPickerFolder = (folderId) => {
    let mutations = {
      pickerAsset: null,
      pickerNode: folderId,
      pickerFolder: folderId,
    }
    if (this.props.builderData.activeScreen === 'create')
      mutations.targetSaveFolder = folderId

    this.props.builderActions.setKeyForBuilder(
      this.props.builderType,
      mutations
    )
  }

  // Sets the picker details in redux when asset is clicked
  setPickerAsset = (assetId, parentId) => {
    this.props.builderActions.setKeyForBuilder(this.props.builderType, {
      pickerAsset: assetId,
      pickerNode: assetId,
      pickerFolder: parentId,
    })
  }

  // Toggle between showing asset titles/filenames in Picker
  handlePickerModeChange = (event) => {
    this.props.builderActions.setKeyForBuilder(this.props.builderType, {
      pickerDisplayMode: event.target.checked ? 'title' : 'filename',
    })
  }

  // Allows user to select a related asset in the builder (hotspot, navzone, etc
  // This should bring up a preview pane in the builder
  selectTarget = (targetType, targetObj) => {
    const { builderActions, builderType, builderData } = this.props
    switch (targetType) {
      case 'relatedAsset':
        builderActions.setKeyForBuilder(builderType, {
          activeScreen: 'viewRelatedAsset',
          activeRelatedAsset: targetObj,
        })
        break
      case 'hotspot':
        builderActions.setKeyForBuilder(builderType, {
          activeScreen: 'viewRelatedAsset',
          activeHotspot: targetObj,
        })
        break
      case 'navzone-asset':
        builderActions.setKeyForBuilder(builderType, {
          activeScreen: 'viewRelatedAsset',
          activeNavzone: targetObj,
        })
        break
      case 'navzone-slide':
        builderActions.setKeyForBuilder(builderType, {
          activeScreen: `${builderType}EditSlide`,
          activeSlide: builderData.slides.filter(
            (slide) => slide.id === targetObj.targetId
          )[0],
          activeNavzone: null,
        })
        break
      default:
        break
    }
  }

  // Removes related assets
  removeTarget = (targetType, targetId, parentId) => {
    const { builderData } = this.props
    switch (targetType) {
      case 'relatedAsset':
        this.props.builderActions.setKeyForBuilder(this.props.builderType, {
          relatedAssets: builderData.relatedAssets.filter(
            (assetObj) => assetObj.assetId !== targetId
          ),
        })
        break
      case 'hotspot':
        this.props.builderActions.deleteHotspot(
          this.props.builderType,
          targetId,
          parentId
        )
        break
      case 'navzone':
        this.props.builderActions.deleteNavzone(
          this.props.builderType,
          targetId,
          parentId
        )
        break
      default:
        break
    }
  }

  editTarget = (targetType, targetObj) => {
    const { allFolders, endpoints, builderType, builderActions } = this.props
    switch (targetType) {
      case 'relatedAsset':
        builderActions.setKeyForBuilder(builderType, {
          activeRelatedAsset: targetObj,
        })
        this.openSelectAssetModal('editRelatedAsset', null, {
          showTextOverride: true,
          selectedAsset: targetObj.assetId,
          selectedFolder: findLibraryFolder(
            targetObj.assetId,
            allFolders,
            endpoints
          ),
          selectedDisplayText: targetObj.displayText,
        })
        break
      case 'hotspot':
        builderActions.setKeyForBuilder(builderType, {
          activeHotspot: targetObj,
        })
        this.openSelectAssetModal('editHotspot', null, {
          showTextOverride: 1,
          selectedAsset: targetObj.assetId,
          selectedFolder: findLibraryFolder(
            targetObj.assetId,
            allFolders,
            endpoints
          ),
          selectedDisplayText: targetObj.displayText,
        })
        break
      case 'navzone-asset':
        builderActions.setKeyForBuilder(builderType, {
          activeNavzone: targetObj,
        })
        this.openNewZoneModal('editZone', {
          selectedType: 'addAssetTarget',
        })
        break
      case 'navzone-folder':
        builderActions.setKeyForBuilder(builderType, {
          activeNavzone: targetObj,
        })
        this.openNewZoneModal('editZone', {
          selectedType: 'addFolderTarget',
        })
        break
      case 'navzone-slide':
        builderActions.setKeyForBuilder(builderType, {
          activeNavzone: targetObj,
        })
        this.openNewZoneModal('editZone', {
          selectedType: 'slide',
        })
        break
      case 'navzone-unassigned':
        builderActions.setKeyForBuilder(builderType, {
          activeNavzone: targetObj,
        })
        this.openNewZoneModal('editZone')
        break
      default:
    }
  }

  // **** ADD/REMOVE RELATED ASSETS **** //
  addRelatedAsset = (assetId, folder, displayText) => {
    const { relatedAssets } = this.props.builderData
    let found = false
    relatedAssets.forEach((ra) => {
      if (ra.assetId === assetId) found = true
    })
    if (found) return

    const newRelatedAsset = {
      id: md5(`${assetId}${Date.now()}`),
      assetId,
      displayText,
    }
    this.props.builderActions.setKeyForBuilder(this.props.builderType, {
      relatedAssets: [...relatedAssets].concat(newRelatedAsset),
    })
  }

  // Callback for OpenSelectAssetModal
  editRelatedAssetCallback = (assetId, folder, displayText) => {
    const { builderData, builderType, builderActions } = this.props
    const newRelatedAssets = builderData.relatedAssets.map((ra) =>
      ra.assetId !== builderData.activeRelatedAsset.assetId
        ? ra
        : { ...ra, assetId, displayText }
    )
    builderActions.setKeyForBuilder(builderType, {
      activeRelatedAsset: { assetId, displayText },
      relatedAssets: newRelatedAssets,
    })
  }

  // Remove slide from array; set new active slide if applicable
  confirmDeleteSlide = () => {
    const { builderData } = this.props
    if (builderData.activeSlide && builderData.activeSlide.mainImage) {
      let content = 'This action cannot be undone.'
      if (this.props.builderType === NAVIGATOR__BUILDER_TYPE) {
        const references = getZonesForTarget(
          builderData.activeSlide.id,
          builderData.slides
        )
        content =
          `${content}  There are ${references.length} zones targeting this slide ` +
          `that will be removed by this action.`
      }
      return Modal.confirm({
        okText: 'Delete',
        cancelText: 'Cancel',
        title: 'Confirm slide delete',
        content,
        onCancel: () => Promise.resolve({}),
        onOk: () => {
          this.handleDeleteSlide()
          Promise.resolve({})
        },
      })
    }

    return this.handleDeleteSlide()
  }

  handleDeleteSlide = () => {
    const { builderType, builderData, builderActions } = this.props
    if (builderData.activeSlide) {
      let newActiveSlide
      const numSlides = builderData.slides.length
      // Set the active slide post-delete
      if (numSlides === 1) newActiveSlide = null
      else {
        const index = this.slideDeck
          .getDecoratedComponentInstance()
          .getIndexOfSlide(builderData.activeSlide.id)
        if (index === 0) newActiveSlide = builderData.slides[1]
        else newActiveSlide = builderData.slides[index - 1]
      }
      builderActions.deleteSlideFromBuilder(
        builderType,
        builderData.activeSlide.id,
        newActiveSlide
      )
      // Remove zones that target this slide
      if (builderType === NAVIGATOR__BUILDER_TYPE) {
        const targets = getZonesForTarget(
          builderData.activeSlide.id,
          builderData.slides
        )
        targets.forEach((target) => {
          this.removeTarget(
            'navzone',
            target,
            getNavzoneParentSlide(target, builderData.slides)
          )
        })
      }
    }
  }

  addHotspotCallback = (asset, folder, text) => {
    const { builderType, builderActions, builderData } = this.props
    if (builderData.currentScreen !== `${builderType}EditSlide`)
      builderActions.setScreenForBuilder(builderType, `${builderType}EditSlide`)

    const lastClick = this.simulator
      .getDecoratedComponentInstance()
      .getClickPosition()
    builderActions.addHotspotToSlide(builderType, builderData.activeSlide.id, {
      id: md5(`${asset}${lastClick}${Date.now()}`),
      assetId: asset,
      displayText: text,
      location: lastClick,
    })
  }

  // Callback for SelectAssetModal
  editHotspotCallback = (asset, folder, displayText) => {
    const { builderType, builderData, builderActions } = this.props
    const newHotspot = {
      ...builderData.activeHotspot,
      assetId: asset,
      displayText: displayText,
    }
    builderActions.replaceHotspot(
      builderType,
      builderData.activeSlide.id,
      newHotspot
    )
  }

  openNewZoneModal = (builderSection, options) => {
    this.props.modalActions.openModal('AddNavzoneModal', {
      builderData: this.props.builderData,
      title: modalTitleForBuilderSection(builderSection),
      okCallback: (type, id) =>
        callbackForBuilderSection(this, builderSection)(type, id),
      cancelCallback: () => {
        if (this.props.builderData.activeNavzone.targetType === 'unassigned')
          this.removeTarget(
            'navzone',
            this.props.builderData.activeNavzone.id,
            this.props.builderData.activeSlide.id
          )
        this.props.modalActions.closeModal()
      },
      ...options,
    })
  }

  addNavzone = (navzone) => {
    const { builderType, builderData, builderActions } = this.props
    navzone.id = md5(`${Date.now()}${navzone.topLeft}${navzone.bottomRight}`)
    builderActions.addNavzone(builderType, builderData.activeSlide.id, navzone)
  }

  addZoneCallback = (type, targetId) => {
    const { builderType, builderData, builderActions, allFolders, endpoints } =
      this.props
    switch (type) {
      case 'newSlide':
        return builderActions.createSlideDestinationForZone(
          builderType,
          builderData
        )
      case 'slide':
        builderActions.setTargetForZone(
          builderType,
          builderData.activeSlide.id,
          builderData.activeNavzone.id,
          'slide',
          targetId
        )
        return this.props.modalActions.closeModal()
      case 'addAssetTarget':
        return this.openSelectAssetModal(builderType, null, {
          treeRoot: getEndpointById('LIB', endpoints).rootFolders,
          saveCallback: (asset, folder, textOverride) =>
            callbackForBuilderSection(this, type)(asset, folder, textOverride),
          cancelCallback: () => {
            if (
              this.props.builderData.activeNavzone.targetType === 'unassigned'
            )
              this.removeTarget(
                'navzone',
                this.props.builderData.activeNavzone.id,
                this.props.builderData.activeSlide.id
              )
          },
          showTextOverride: false,
          modalTitle: (
            <span>
              <i className={`fa ${iconForBuilder(builderType)}`} />{' '}
              {`Builder - ${modalTitleForBuilderSection(type)}`}
            </span>
          ),
          selectedAsset:
            builderData.activeNavzone.targetType === 'asset' &&
            builderData.activeNavzone.targetId,
          selectedFolder:
            builderData.activeNavzone.targetType === 'asset' &&
            findLibraryFolder(
              builderData.activeNavzone.targetId,
              allFolders,
              endpoints
            ),
        })
      case 'addFolderTarget':
        return this.openSelectAssetModal(builderType, null, {
          treeRoot: getEndpointById('MST', endpoints).rootFolders,
          saveCallback: (asset, folder, textOverride) =>
            callbackForBuilderSection(this, type)(asset, folder, textOverride),
          cancelCallback: () => {
            if (
              this.props.builderData.activeNavzone.targetType === 'unassigned'
            )
              this.removeTarget(
                'navzone',
                this.props.builderData.activeNavzone.id,
                this.props.builderData.activeSlide.id
              )
          },
          showTextOverride: false,
          showAssets: false,
          modalTitle: (
            <span>
              <i className={`fa ${iconForBuilder(builderType)}`} />{' '}
              {`Builder - ${modalTitleForBuilderSection(type)}`}
            </span>
          ),
          selectedAsset:
            builderData.activeNavzone.targetType === 'folder'
              ? builderData.activeNavzone.targetId
              : getEndpointById('MST', endpoints).rootFolders[0],
          selectedFolder:
            builderData.activeNavzone.targetType === 'folder'
              ? builderData.activeNavzone.targetId
              : getEndpointById('MST', endpoints).rootFolders[0],
        })
      default:
        break
    }
  }

  addZoneTarget = (targetType, targetId) => {
    this.props.builderActions.setTargetForZone(
      this.props.builderType,
      this.props.builderData.activeSlide.id,
      this.props.builderData.activeNavzone.id,
      targetType,
      targetId
    )
  }
  addAssetTarget = (asset) => this.addZoneTarget('asset', asset)
  addFolderTarget = (asset, folder) => this.addZoneTarget('folder', folder)

  editAssetZoneCallback = (assetId) => {
    this.addAssetTarget(assetId)
  }
  editFolderZoneCallback = (asset, folder) => {
    this.addFolderTarget(asset, folder)
  }

  setComparisonImage = (asset) => {
    const { builderType, builderData, builderActions } = this.props
    let newTableData = update(builderData.comparisonData, {
      columns: {
        $apply: (x) =>
          x.map((column) =>
            column.key !== builderData.activeItem
              ? column
              : update(column, { image: { $set: asset } })
          ),
      },
    })
    let newComparisonObject = update(builderData.comparisonObject, {
      [builderData.activeItem]: {
        meta: {
          itemImage: {
            $set: asset,
          },
        },
      },
    })
    newTableData = prepComparisonDataForTable(
      newTableData.columns,
      newTableData.dataSource,
      this.props.allAssets,
      this.openSelectAssetModal,
      this.removeComparisonImage,
      this.isComparisonRowEditing,
      this.setEditComparisonRow
    )
    builderActions.setKeyForBuilder(builderType, {
      comparisonData: newTableData,
      comparisonObject: newComparisonObject,
    })
  }

  removeComparisonImage = (comparisonItem) => {
    const { builderType, builderData, builderActions } = this.props
    let newTableData = update(builderData.comparisonData, {
      columns: {
        $apply: (x) =>
          x.map((column) =>
            column.key !== comparisonItem
              ? column
              : update(column, { image: { $set: null } })
          ),
      },
    })
    let newComparisonObject = update(builderData.comparisonObject, {
      [comparisonItem]: {
        meta: {
          itemImage: {
            $set: null,
          },
        },
      },
    })
    newTableData = prepComparisonDataForTable(
      newTableData.columns,
      newTableData.dataSource,
      this.props.allAssets,
      this.openSelectAssetModal,
      this.removeComparisonImage,
      this.isComparisonRowEditing,
      this.setEditComparisonRow
    )
    builderActions.setKeyForBuilder(builderType, {
      comparisonData: newTableData,
      comparisonObject: newComparisonObject,
    })
  }
  /*
   * Given an id, style the element with matching id + targettype
   */
  componentMouseEnter = (id, targetType) => {
    const el = document.getElementById(`${targetType}-${id}`)
    if (el) {
      switch (targetType) {
        case 'hotspot':
          el.style.color = '#1890ff'
          break
        case 'listitem':
          el.style.backgroundColor = '#e8e8e8'
          break
        case 'navzone':
          el.style.opacity = '.7'
          break
        default:
          return
      }
    }
  }

  /*
   * Given an id, unstyle the element with matching id + targettype
   */
  componentMouseLeave = (id, targetType) => {
    const el = document.getElementById(`${targetType}-${id}`)
    if (el) {
      switch (targetType) {
        case 'hotspot':
          el.style.color = null
          break
        case 'listitem':
          el.style.backgroundColor = null
          break
        case 'navzone':
          el.style.opacity = null
          break
        default:
          return
      }
    }
  }

  onReplaceSlideClick = () => {
    this.props.builderActions.setKeyForBuilder(this.props.builderType, {
      activeScreen: 'addSlide',
    })
  }

  // Use the reference to slideDeck (wrapped in DropTarget) to get the index of active slide
  // Returns text like: "Slide 1 of 5"
  getSlideIndicatorText = () => {
    const { builderData } = this.props
    let text = ''
    if (this.slideDeck && builderData.activeSlide) {
      text = `Slide ${
        this.slideDeck
          .getDecoratedComponentInstance()
          .getIndexOfSlide(builderData.activeSlide.id) + 1
      } of ${builderData.slides.length}`
    }
    return text
  }

  addSlideCallback = (asset) => {
    const { builderType, builderActions } = this.props
    builderActions.createSlideForBuilder(builderType, asset)
  }

  replaceSlideCallback = (asset) => {
    const { builderType, builderActions, builderData } = this.props
    builderActions.replaceSlideForBuilder(
      builderType,
      builderData.activeSlide.id,
      asset
    )
  }

  // To open the select asset modal
  openSelectAssetModal = (builderSection, typeFilter, modalProps = {}) => {
    const { builderType, endpoints, builderActions, builderData } = this.props
    const builderIcon = `fa fa-${iconForBuilder(builderType)}`
    const modalTitle = modalTitleForBuilderSection(builderSection)
    const maxDisplayText =
      builderSection === 'addHotspot'
        ? HOTSPOT__MAX_DISPLAY_TEXT_LENGTH
        : builderSection === 'relatedAsset'
        ? FEATURE__MAX_DISPLAY_TEXT_LENGTH
        : null
    if (builderType === COMPARISON__BUILDER_TYPE) {
      builderActions.setKeyForBuilder(builderType, {
        activeItem: modalProps.itemName,
      })
    }
    if (builderData.isEdit) {
      typeFilter = [].concat(typeFilter, (id) => id !== builderData.id)
    }

    this.props.modalActions.openModal('SelectAssetModal', {
      treeRoot: getEndpointById('LIB', endpoints).rootFolders,
      filterType: typeFilter,
      saveCallback: (asset, folder, textOverride) =>
        callbackForBuilderSection(this, builderSection)(
          asset,
          folder,
          textOverride
        ),
      showTextOverride:
        builderSection === 'addHotspot' || builderSection === 'relatedAsset',
      maxDisplayText,
      modalTitle: (
        <span>
          <i className={builderIcon} /> {`Builder - ${modalTitle}`}
        </span>
      ),
      ...modalProps,
    })
  }

  // Scroll to top of window when advancing side panel screens
  scrollToTop = () => {
    this.props.appActions.scrollToTop('main-content')
  }

  showSimulator = () => {
    const { builderType, builderData } = this.props
    if (
      ~BUILDERS_WITH_SLIDES.indexOf(builderType) &&
      builderData.slides.length > 0 &&
      builderData.activeScreen !== 'meta' &&
      builderData.activeScreen !== 'create'
    )
      return true

    return false
  }
  showComparisonTable = () => {
    const { builderType, builderData } = this.props
    if (builderType === COMPARISON__BUILDER_TYPE && builderData.datafileKey)
      return true

    return false
  }

  // Filter image files based on mime-type or extension
  imageAssetFilter = (id) => {
    if (!this.props.allAssets[id]) return false
    return (
      this.props.allAssets[id].file_type.startsWith('image') ||
      ['jpeg', 'jpg', 'gif', 'png', 'svg'].indexOf(
        this.props.allAssets[id].extension
      ) > -1
    )
  }

  // Download comparison template
  downloadTemplate = () => {
    const item = {
      url: `${this.props.config.baseUrl}/active/ComparisonTemplate.tsv`,
      filename: 'Comparison Template.tsv',
    }
    // Download, but don't record event
    downloadAsset(item, false, false)
  }

  // This is the callback for the upload modal
  // Succeeded = array of file ids that succeeded
  // Failed = array of file ids that failed
  // Files = array of file attributes for successful files
  getComparisonData = (succeeded, failed, files) => {
    const { builderActions, assetActions, builderType } = this.props
    if (succeeded.length > 0) {
      builderActions.setKeyForBuilder(builderType, {
        datafileKey: files[0].key,
      })
      assetActions.parseComparisonData(
        files[0].key,
        this.openSelectAssetModal,
        this.removeComparisonImage,
        this.props.builderData,
        this.isComparisonRowEditing,
        this.setEditComparisonRow
      )
    }
  }

  setEditComparisonRow = (key) => {
    this.props.builderActions.setKeyForBuilder(this.props.builderType, {
      editRow: key,
    })
  }

  isComparisonRowEditing = (rowKey) => {
    return this.props.builderData.editRow === rowKey
  }

  tableRecordCancel = () => this.setEditComparisonRow('')

  tableRecordSave = (form, record) => {
    form.validateFields((err, values) => {
      if (!err) {
        this.props.builderActions.updateComparisonObject(
          this.props.builderData.comparisonObject,
          values,
          record,
          this.setComparisonImage,
          this.removeComparisonImage,
          this.isComparisonRowEditing,
          this.setEditComparisonRow
        )
        this.setEditComparisonRow('')
      }
    })
  }

  render() {
    return (
      <AssetBuilderView
        {...this.props}
        confirmDeleteSlide={this.confirmDeleteSlide}
        replaceSlide={this.onReplaceSlideClick}
        openSelectAssetModal={this.openSelectAssetModal}
        showFooter={BUILDERS_WITH_SLIDES.includes(this.props.builderType)}
        showSimulator={this.showSimulator()}
        showComparisonTable={this.showComparisonTable()}
        simulatorViews={this.props.simulatorViews}
        componentMouseEnter={this.componentMouseEnter}
        componentMouseLeave={this.componentMouseLeave}
        addSlideCallback={this.addSlideCallback}
        saveSlideDeckRef={this.saveSlideDeckRef}
        onCancel={this.onCancel}
        nextAction={this.nextAction}
        nextDisabled={this.isNextDisabled()}
        addSaveFolderKey={this.addSaveFolderKey}
        removeSaveFolderKey={this.removeSaveFolderKey}
        actionButtonLabel={this.getActionButtonLabel()}
        saveFormRef={this.saveFormRef}
        saveTagsRef={this.saveTagsRef}
        saveSimulatorRef={this.saveSimulatorRef}
        handleFormValuesChange={this.handleFormValuesChange}
        prevAction={this.prevAction}
        imageFilter={this.imageAssetFilter}
        setPickerFolder={this.setPickerFolder}
        setPickerAsset={this.setPickerAsset}
        handlePickerModeChange={this.handlePickerModeChange}
        getSlideIndicatorText={this.getSlideIndicatorText}
        addNavzone={this.addNavzone}
        openNewZoneModal={this.openNewZoneModal}
        selectTarget={this.selectTarget}
        editTarget={this.editTarget}
        removeTarget={this.removeTarget}
        config={this.props.config}
        downloadTemplate={this.downloadTemplate}
        getComparisonData={this.getComparisonData}
        isEditing={this.isComparisonRowEditing}
        setEditComparisonRow={this.setEditComparisonRow}
        tableRecordSave={this.tableRecordSave}
        tableRecordCancel={this.tableRecordCancel}
        builderSettings={this.props.builderSettings}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  builderData: state.builders[ownProps.builderType],
  simulatorViews: state.builders.simulatorViews,
  allAssets: state.assets.byId,
  allFolders: state.folders.byId,
  config: state.config.appConfig,
  endpoints: state.endpoints.endpoints,
  windowHeight: state.appState.windowHeight,
  builderSettings: state.settings.builders,
})

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appActions, dispatch),
  assetActions: bindActionCreators(assetActions, dispatch),
  builderActions: bindActionCreators(builderActions, dispatch),
  modalActions: bindActionCreators(modalActions, dispatch),
  folderActions: bindActionCreators(folderActions, dispatch),
  settingsActions: bindActionCreators(settingsActions, dispatch),
})

AssetBuilder.propTypes = {
  allAssets: PropTypes.object,
  allFolders: PropTypes.object,
  builderType: PropTypes.string,
  appActions: PropTypes.object,
  builderData: PropTypes.object,
  endpoints: PropTypes.arrayOf(PropTypes.object),
  modalActions: PropTypes.shape({
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
  }),
  builderActions: PropTypes.shape({
    reorderSlides: PropTypes.func,
    resetBuilderData: PropTypes.func,
    setKeyForBuilder: PropTypes.func,
    deleteHotspot: PropTypes.func,
    createSlideDestinationForZone: PropTypes.func,
    setTargetForZone: PropTypes.func,
    deleteNavzone: PropTypes.func,
    editBuilder: PropTypes.func,
  }),
  assetActions: PropTypes.shape({
    parseComparisonData: PropTypes.func,
  }),
  history: PropTypes.object,
  simulatorViews: PropTypes.object,
  config: PropTypes.object,
  windowHeight: PropTypes.number,
  builderSettings: PropTypes.object,
  settingsActions: PropTypes.shape({
    getSetting: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetBuilder)
