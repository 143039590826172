/* eslint-disable react/prop-types */
import { Button, Icon, Input } from 'antd'
import update from 'immutability-helper'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as modalActions from '../../dux/actions/modal'
import * as actions from '../../dux/actions/permissions'
import { folderHasContentRoleGroup, isAdmin } from '../../dux/modules/utils'
import FourOhFour from '../common/FourOhFour'
import LoadingModal from '../modals/LoadingModal'
import GroupsList from './GroupsList'
import GroupTableActions from './GroupTableActions'
import '../styles/Permissions.css'

class GroupsContainer extends React.Component {
  constructor(props) {
    super(props)

    this.filterInputs = {}
    this.state = {
      dropdownField: '',
      dropdownVisible: false,
      filtered: false,
      filters: [],
    }
  }

  componentDidMount() {
    this.props.actions.getAllPermissionsData()
  }

  applyFilters = (group) => {
    if (!this.state.filtered || this.state.filters.length === 0) {
      return null
    }

    const filters = this.state.filters.map((filter) => ({
      ...filter,
      regex: new RegExp(filter.value, 'gi'),
    }))
    const match = {}

    for (let i = 0, length = filters.length; i < length; i++) {
      const { field, regex } = filters[i]

      const fieldMatch = group[field].match(regex)

      if (!fieldMatch) {
        return null
      }

      match[`filtered${field}`] = (
        <span key={group[field]}>
          {group[field].split(regex).map((val, i) =>
            i > 0
              ? [
                  <span key={i} style={{ color: 'red' }}>
                    {fieldMatch[0]}
                  </span>,
                  val,
                ]
              : val
          )}
        </span>
      )
    }

    return match
  }

  prepareGroups = () => {
    const { filtered } = this.state
    const { groups } = this.props.permissions

    return Object.keys(groups)
      .map((id) => {
        const group = groups[id]
        let match

        if (!filtered || (match = this.applyFilters(group))) {
          return {
            ...group,
            ...match,
          }
        }

        return null
      })
      .filter((record) => !!record)
      .sort((a, b) => {
        if (!a.system && b.system) return -1
        if (!b.system && a.system) return 1
        return a.name.localeCompare(b.name)
      })
  }

  onAdd = () => {
    this.props.modalActions.openModal('AddGroupModal')
  }

  onFilterDropdownVisibleChange = (field) => (visible) => {
    this.setState(
      {
        dropdownField: field,
        dropdownVisible: visible,
      },
      () => this.filterInputs[field] && this.filterInputs[field].focus()
    )
  }

  onFilterInputChange = (field) => (e) => {
    const { filtered, filters } = this.state
    const idx = filters.findIndex((filter) => filter.field === field)
    const newFilter = {
      field,
      value: e.target.value,
    }
    let newFilters

    if (newFilter.value.length === 0 && idx >= 0) {
      newFilters = update(filters, {
        $splice: [[idx, 1]],
      })
    } else if (idx >= 0) {
      newFilters = update(filters, {
        $splice: [[idx, 1, newFilter]],
      })
    } else {
      newFilters = update(filters, {
        $push: [newFilter],
      })
    }

    this.setState({
      filtered: filtered && newFilters.length > 0,
      filters: newFilters,
    })
  }

  onFilterSearch = () => {
    const { filters } = this.state

    this.setState({
      dropdownVisible: false,
      filtered: filters.length > 0,
    })
  }

  renderFilterDropdown = (field, filters) => {
    const filter = filters.find((f) => f.field === field)

    return (
      <div className="permissions__filter-dropdown">
        <Input
          onChange={this.onFilterInputChange(field)}
          onPressEnter={this.onFilterSearch}
          placeholder={`Search ${field}`}
          ref={(el) => (this.filterInputs[field] = el)}
          value={filter && filter.value}
        />

        <Button type="primary" onClick={this.onFilterSearch}>
          Search
        </Button>
      </div>
    )
  }

  renderFilterIcon = (field, filters) => {
    const filter = filters.find((f) => f.field === field)

    return <Icon style={{ color: filter ? '#108ee9' : '#aaa' }} type="search" />
  }

  render() {
    const admin = isAdmin(this.props.email, this.props.permissions.groups)
    const { dropdownField, dropdownVisible, filters } = this.state
    const columns = [
      {
        title: 'Group Name',
        dataIndex: 'name',
        className: 'bam__table-column bam__table-column-maxwidth180',
        width: 180,
        filterDropdown: this.renderFilterDropdown('name', filters),
        filterIcon: this.renderFilterIcon('name', filters),
        filterDropdownVisible: dropdownVisible && dropdownField === 'name',
        onFilterDropdownVisibleChange:
          this.onFilterDropdownVisibleChange('name'),
        render: (key, record) => record.filteredname || record.name,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        width: 600,
        className: 'bam__table-column bam__table-column-maxwidth600',
      },
      {
        title: 'Members',
        dataIndex: 'members',
        className: 'bam__table-column bam__table-column-maxwidth100',
        width: 300,
        render: (text, record) => (
          <div className="permissions__group-list">
            {record.members
              .sort((a, b) => a.localeCompare(b))
              .map((member) => (
                <div key={member}>{member}</div>
              ))}
          </div>
        ),
      },
      {
        title: 'Actions',
        key: 'actions',
        className: 'bam__table-column bam__table-column-maxwidth120',
        width: 120,
        render: (text, record) => (
          <GroupTableActions
            isAdmin={admin}
            group={record}
            groups={this.props.permissions.groups}
            allFolders={this.props.allFolders}
            folderPrivs={Object.keys(this.props.allFolders).filter((fldr) =>
              folderHasContentRoleGroup(fldr, this.props.allFolders, record.id)
            )}
            scroll={{ x: 1000, y: this.props.windowHeight - 230 }}
          />
        ),
      },
    ]
    const data = this.prepareGroups()

    return !this.props.initializing && !admin ? (
      <FourOhFour />
    ) : this.props.loading ? (
      <LoadingModal loading={this.props.loading} />
    ) : (
      <GroupsList
        isAdmin={admin}
        onAdd={this.onAdd}
        columns={columns}
        groups={data}
        height={this.props.windowHeight}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  permissions: state.permissions,
  allFolders: state.folders.byId,
  users: state.users,
  email: state.user.email,
  loading: state.appState.asyncPending,
  initializing: state.appState.initializing,
  windowWidth: state.appState.windowWidth,
  windowHeight: state.appState.windowHeight,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  modalActions: bindActionCreators(modalActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(GroupsContainer)
