export const BADGES = {
  UGC: {
    tooltipPlacement: 'bottomRight',
    tooltip: 'user assets pending review',
    bgColor: '#334353',
  },
  PENDING_APPROVAL: {
    tooltipPlacement: 'bottomRight',
    tooltip: 'assets pending approval',
    bgColor: '#fa8c16',
  },
  PENDING_REMOVAL: {
    tooltipPlacement: 'bottomRight',
    tooltip: 'assets pending removal',
    bgColor: '#f5222d',
  },
}
