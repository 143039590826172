import { Button, Table } from 'antd'
import React from 'react'
import '../styles/Permissions.css'

const GroupsList = (props) => {
  return (
    <div>
      <Table
        className="permissions__table"
        columns={props.columns}
        dataSource={props.groups}
        bordered
        rowKey={(record) => record.id}
        size={props.tablesize}
        scroll={{ x: 1200, y: props.height - 250 }}
      />
      <Button
        disabled={!props.isAdmin}
        onClick={props.onAdd}
        icon="usergroup-add"
        style={{ margin: '0 10px' }}
      >
        Add
      </Button>
    </div>
  )
}

export default GroupsList
