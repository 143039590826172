import React from 'react'

const Import = (props) => (
  <svg
    data-name="Layer 1"
    viewBox="0 0 289.05 289.21"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d={
        'M0 162.74h36.18c18.13.05 65.93.05 65.93.05l-9.61 9.62-28.43 28.47c-6.16 6.12-7.8 14.12-' +
        '4.38 21.22a17.92 17.92 0 0 0 28.89 5.24c4.54-4.37 9-8.9 13.36-13.28 1.94-1.95 3.87-3.91 ' +
        '5.81-5.85l11.65-11.66 38.14-38.14a19.17 19.17 0 0 0 6.14-12c.51-5.79-1.53-11-6.06-15.53q' +
        '-18.06-18-36.09-36.08L108.71 82l-6-6c-4.56-4.6-9.28-9.36-14-14a17.91 17.91 0 0 0-28.9 5c' +
        '-3.43 7-1.94 15 3.89 20.94q8.4 8.48 16.86 16.91l8.4 8.39 13.2 13.24H0'
      }
      fill="none"
    />
    <path
      d={
        'M.19 126.83v-90c.23-1.69.44-3.38.71-5.06C3.72 13.7 17.81 3.75 29.72 1.08 31.77.62 33.87.' +
        '43 36 .11h217c1.65.18 3.31.29 5 .54a37.15 37.15 0 0 1 30.57 29.1c.42 2 .64 4.08 1 6.12v2' +
        '17.2c-.15 1.09-.31 2.19-.45 3.28a36.89 36.89 0 0 1-33.24 32.77l-4.47.43H36.43a9.68 9.68 ' +
        '0 0 0-1.15-.22c-9.89-.81-18.39-4.56-25.07-12a38.21 38.21 0 0 1-9.54-22c-.2-1.86-.33-3.71' +
        '-.48-5.57v-86.69c.51 0 1-.12 1.53-.12H102.1l.35.5a5.75 5.75 0 0 0-1.22.74q-18.49 18.5-37' +
        ' 37a19.05 19.05 0 0 0-6 12.11 18.23 18.23 0 0 0 10.55 17.84A17.87 17.87 0 0 0 89 227.35q' +
        '6.13-6.06 12.25-12.17l31.61-31.68q12.67-12.69 25.37-25.37c7.65-7.66 7.75-18.82.11-26.47q' +
        '-34.4-34.48-68.88-68.9c-4.19-4.19-9.21-6.39-15.16-5.68-7.12.85-12.11 4.82-14.77 11.42s-1' +
        '.34 12.66 3.06 18.07a79.92 79.92 0 0 0 5.55 5.8q16.53 16.56 33.09 33.08a7 7 0 0 0 1.21.7' +
        '9l-.3.41c-.38 0-.77.06-1.16.06l-49.67.08H1.62c-.48 0-.96.03-1.43.04zm194.09 92.53a10.2 1' +
        '0.2 0 1 0 10.34 9.88 10.22 10.22 0 0 0-10.34-9.88zm33.63 0a10.2 10.2 0 1 0 10.16 10.14 1' +
        '0.2 10.2 0 0 0-10.16-10.14z'
      }
    />
  </svg>
)

export default Import
