import { Icon, Tooltip } from 'antd'
import moment from 'moment'
import React from 'react'
import '../styles/Notifications.css'

const Inbox = (props) => {
  return (
    <div className="notifications__inbox">
      <div className="notifications__inbox-folders">
        <div className="notifications__inbox-header">Inbox</div>
        <div
          className={`notifications__inbox-item${
            props.selectedFolder === 'messages' ? '-selected' : ''
          }`}
          id="messages"
          onClick={props.onFolderSelect}
        >
          <span>
            <Icon type="inbox" /> Messages ({props.allMessages})
          </span>
          <span onClick={props.onRefreshInbox}>
            <Icon type="sync" spin={props.loading} />
          </span>
        </div>
        <div
          className={`notifications__inbox-item${
            props.selectedFolder === 'unread' ? '-selected' : ''
          }`}
          id="unread"
          onClick={props.onFolderSelect}
        >
          <span>
            <Icon type="mail" /> Unread ({props.unreadMessages})
          </span>
          <span>&nbsp;</span>
        </div>
        <div
          className={`notifications__inbox-item${
            props.selectedFolder === 'deleted' ? '-selected' : ''
          }`}
          id="deleted"
          onClick={props.onFolderSelect}
        >
          <span>
            <Icon type="delete" /> Deleted ({props.deletedMessages})
          </span>
          <span>&nbsp;</span>
        </div>
      </div>
      <div className="notifications__inbox-messages">
        {props.selectedMessages.length > 0 ? (
          props.selectedMessages.map((msg, index) => {
            return (
              <div
                key={msg.id}
                id={index}
                className={
                  props.activeMessage === index
                    ? 'notifications__message-preview-selected'
                    : 'notifications__message-preview'
                }
                onClick={props.onMessageSelect}
              >
                <div className="notifications__message-preview-subject">
                  {!msg.viewed ? (
                    <span className="notifications__message-preview-unread"></span>
                  ) : null}{' '}
                  {msg.subject}
                </div>
                <div className="notifications__message-preview-data">
                  <span className="notifications__message-preview-from">
                    {
                      <Tooltip title={msg.from}>
                        {msg.from.split('@')[0]}
                      </Tooltip>
                    }
                  </span>
                  <span className="notifications__message-preview-date">
                    {moment(msg.timestamp).format('MMM DD')}
                  </span>
                </div>
              </div>
            )
          })
        ) : (
          <div className="notifications__message-preview">
            <div className="notifications__message-preview-subject">
              No Messages
            </div>
            <div className="notifications__message-preview-data">
              <span className="notifications__message-preview-from"></span>
              <span className="notifications__message-preview-date"></span>
            </div>
          </div>
        )}
      </div>
      <div className="notifications__message-view">
        {props.selectedMessages[props.activeMessage] ? (
          <div>
            <div className="notifications__message-view-header">
              <div className="notifications__message-view-subject">
                <span>
                  {props.selectedMessages[props.activeMessage].subject}
                </span>
                <span>
                  <span
                    className="notifications__message-view-action"
                    id={props.selectedMessages[props.activeMessage].id}
                    onClick={props.onMarkUnread}
                  >
                    <Tooltip title="Mark unread">
                      <Icon type="mail" />
                    </Tooltip>
                  </span>
                  <span
                    className="notifications__message-view-action"
                    id={props.selectedMessages[props.activeMessage].id}
                    onClick={props.onMarkDeleted}
                  >
                    <Tooltip title="Delete">
                      <Icon type="delete" />
                    </Tooltip>
                  </span>
                </span>
              </div>
              <div className="notifications__message-view-data">
                <span className="notifications__message-view-from">
                  {props.selectedMessages[props.activeMessage].from}
                </span>
                <span className="notifications__message-view-date">
                  {moment(
                    props.selectedMessages[props.activeMessage].timestamp
                  ).format('MMM DD, YYYY h:mm a')}
                </span>
              </div>
            </div>
            <div className="notifications__message-view-body">
              <div
                dangerouslySetInnerHTML={{
                  __html: props.selectedMessages[props.activeMessage].htmlBody,
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Inbox
