import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as appActions from '../../dux/actions/appActions'
import * as userActions from '../../dux/actions/user'
import { requiredFieldsArePopulated } from '../../dux/modules/utils'
import LoginForm from './LoginForm'
//Class-based implementation necessary in order
//to have access to the router
class Login extends React.Component {
  // App will attempt to bootstrap email value from local storage
  constructor(props) {
    super(props)
    /*
     *  Attempt to log in to AWS Cognito with the supplied credentials.
     */
    this.handleSubmit = (event) => {
      event.preventDefault()

      this.form.validateFields((err, values) => {
        if (!err) {
          if (
            !requiredFieldsArePopulated(
              ['Email', 'Password'],
              values,
              this.form
            )
          )
            return
          this.props.userActions.loginUser({
            Username: values.Email.toLowerCase(),
            Password: values.Password,
          })
        }
      })
    }

    this.saveFormRef = (ref) => (this.form = ref)
  }

  componentDidMount() {
    //try to bootstrap login from local storage
    if (this.props.user.email && this.props.user.email !== '') {
      this.props.userActions.restoreSession(this.props.user.email)
    }
  }

  render() {
    // Redirect occurs after user registers, or attempts to login with
    // An unconfirmed Cognito account
    if (this.props.user.registrationPending) {
      return <Redirect to="/registration-pending" />
    }

    return (
      <LoginForm
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        saveFormRef={this.saveFormRef}
        email={this.props.user.email}
        asyncPending={this.props.appState.asyncPending}
        errorMsg={this.props.user.loginError}
        clearError={this.props.userActions.clearLoginError}
        config={this.props.config}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  appState: state.appState,
  config: state.config.appConfig,
})

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(userActions, dispatch),
  appActions: bindActionCreators(appActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
