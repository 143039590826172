import { Input, Tag, Tooltip } from 'antd'
import React from 'react'

class EditableTagGroup extends React.Component {
  state = {
    tags: this.props.defaultTags || [],
    inputValue: '',
  }

  // Set state if we get delayed defaultTags prop
  componentDidUpdate = (prevProps) => {
    if (this.props.defaultTags !== prevProps.defaultTags)
      this.setState(() => ({ tags: this.props.defaultTags }))
  }

  handleClose = (removedTag) => {
    const tags = this.state.tags.filter((tag) => tag !== removedTag)
    this.setState({ tags })
  }

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus())
  }

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value })
  }

  isDuplicate = (tags, input) => {
    let duplicate = false
    tags.forEach((tag) => {
      if (tag.toLowerCase() === input.toLowerCase()) duplicate = true
    })
    return duplicate
  }

  // accept callback to wait for setstate to complete
  handleInputConfirm = (e, cb) => {
    const state = this.state
    const inputValue = state.inputValue
    let tags = state.tags
    if (inputValue && !this.isDuplicate(tags, inputValue)) {
      tags = [...tags, inputValue]
    }
    this.setState(
      {
        tags,
        inputValue: '',
      },
      cb
    )
  }

  handleKeyPress = (e) => {
    const { confirmWithComma = true, confirmWithSpace = false } = this.props

    if (
      e.key === 'Enter' ||
      e.key === 'Tab' ||
      (confirmWithComma && e.key === ',') ||
      (confirmWithSpace && e.key === ' ')
    ) {
      e.preventDefault()
      this.handleInputConfirm(e)
    }
  }

  saveInputRef = (input) => (this.input = input)
  getTags = () => this.state.tags

  render() {
    const { inputProps = {} } = this.props
    const { tags = [], inputValue } = this.state

    return (
      <div>
        {tags
          .filter((tag) => !tag.startsWith('SYS.'))
          .map((tag) => {
            const isLongTag = tag.length > 20
            const tagElem = (
              <Tag
                key={tag}
                closable={true}
                style={{ marginBottom: '10px', cursor: 'auto' }}
                afterClose={() => this.handleClose(tag)}
              >
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
              </Tag>
            )
            return isLongTag ? (
              <Tooltip title={tag} key={tag}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            )
          })}
        <Input
          className={this.props.cssClass}
          onChange={this.handleInputChange}
          onKeyDown={this.handleKeyPress}
          ref={this.saveInputRef}
          type="text"
          value={inputValue}
          {...inputProps}
        />
      </div>
    )
  }
}

export default EditableTagGroup
