import { Button, Form, InputNumber, message, Modal } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { requiredFieldsArePopulated } from '../../dux/modules/utils'
import CopyTextComponent from '../BAM_UI/CopyTextComponent'
import LoadingModal from './LoadingModal'

class ShareAssetModal extends React.Component {
  constructor(props) {
    super(props)
    this.props.settingsActions.getSetting('general')
    this.props.assetActions.clearSecureLinks()
    this.state = {
      initiated: this.props.settings.general.minimumShare,
      fixedTime:
        this.props.settings.general.minimumShare ===
        this.props.settings.general.maximumShare,
    }
  }

  // if the general settings change, recalculate fixedTime
  componentDidUpdate = (prevProps) => {
    if (
      prevProps.settings.general.minimumShare !==
        this.props.settings.general.minimumShare ||
      prevProps.settings.general.maximumShare !==
        this.props.settings.general.maximumShare
    )
      this.setState({
        initiated: true,
        fixedTime:
          this.props.settings.general.minimumShare ===
          this.props.settings.general.maximumShare,
      })
  }

  getFooter = (assets) => {
    const { appState, modalActions } = this.props
    if (
      (appState.sharedAssetLinks && appState.sharedAssetLinks.links) ||
      assets.length === 0
    )
      return [
        <Button key="cancel" onClick={modalActions.closeModal}>
          Close
        </Button>,
      ]
    else
      return [
        <Button key="cancel" onClick={modalActions.closeModal}>
          Cancel
        </Button>,
        <Button key="submit" onClick={() => this.onSubmit(assets)}>
          Submit
        </Button>,
      ]
  }

  saveFormRef = (ref) => (this.form = ref)

  onSubmit = (assets) => {
    if (this.state.fixedTime)
      return this.props.assetActions.getSecureLinks(
        assets,
        this.props.modalProps.user,
        this.props.settings.general.minimumShare
      )

    this.form.validateFields((err, values) => {
      if (!err) {
        if (!requiredFieldsArePopulated(['Duration'], values, this.form)) return
        return this.props.assetActions.getSecureLinks(
          assets,
          this.props.modalProps.user,
          values.Duration
        )
      }
    })
  }

  shareTimeValidator = (rule, value, callback) => {
    const { general } = this.props.settings
    if (value > parseInt(general.maximumShare, 10))
      return callback(new Error(`Value cannot exceed ${general.maximumShare}`))

    if (value < parseInt(general.minimumShare, 10))
      return callback(
        new Error(`Value must be at least ${general.minimumShare}`)
      )

    return callback()
  }

  render() {
    let assets = this.props.modalProps.assets
    // convert ids to objects as necessary
    if (typeof assets[0] === 'string') {
      const assetsById = this.props.assets.byId
      assets = assets
        .map((assetId) => assetsById[assetId])
        .filter((asset) => asset.asset_type === 'asset')
    }
    const someUnsendable = assets.length < this.props.modalProps.assets.length

    let linksToShare =
      this.props.appState.sharedAssetLinks &&
      this.props.appState.sharedAssetLinks.links
    const shareDuration = linksToShare
      ? this.props.appState.sharedAssetLinks.duration
      : null
    const oneToShare = linksToShare && linksToShare.length === 1
    const single = oneToShare || assets.length === 1

    if (assets.length === 0) {
      return (
        <Modal
          title={this.props.modalProps.title}
          width={580}
          visible={this.props.modal.modalOpen}
          confirmLoading={this.props.appState.asyncPending}
          footer={this.getFooter(assets)}
          maskClosable={false}
          closable={false}
        >
          {' '}
          <h4 className="modal__warning">
            The assets you selected cannot be shared, because they are meant to
            be viewed within the BAM! mobile app only.
          </h4>
        </Modal>
      )
    }

    return (
      <Modal
        title={this.props.modalProps.title}
        width={'50%'}
        visible={this.props.modal.modalOpen}
        confirmLoading={this.props.appState.asyncPending}
        footer={this.getFooter(assets)}
        maskClosable={false}
        closable={false}
      >
        <h3 className="modal__message">
          When you share {single ? 'this asset' : 'these assets'}
          {someUnsendable ? '*' : ''}, BAM! will create{' '}
          {single ? 'a link to the asset' : 'links to the assets'} on the BAM
          Viewer website. Your {single ? 'link' : 'links'} will be valid for the
          number of days selected below.
        </h3>
        {someUnsendable && (
          <h4 className="modal__warning">
            *One or more of the assets you selected cannot be shared, because
            they are meant to be viewed within the BAM! mobile app only.
          </h4>
        )}
        {
          // Show the shared URL...if it's not populated, show the form to populate it
          linksToShare && linksToShare.length === assets.length ? (
            <div className="modal__text">
              <span>
                {single ? 'This link is' : 'These links are'} valid for{' '}
                {shareDuration} days:
              </span>
              <br />
              <br />
              <CopyTextComponent
                id="SharedURL"
                textToCopy={linksToShare.reduce(
                  (prev, curr, index) =>
                    `${prev}• ${
                      assets[index].title || assets[index].filename
                    }: ${curr.url}${index < assets.length - 1 ? '\n' : '\n\n'}`,
                  ''
                )}
                className="modal__text-link"
                showIcon
                onCopyClick={() => message.success('Copied')}
              />
            </div>
          ) : (
            <ShareForm
              {...this.props}
              initiated={this.state.initiated}
              fixedTime={this.state.fixedTime}
              ref={this.saveFormRef}
              validator={this.shareTimeValidator}
            />
          )
        }
      </Modal>
    )
  }
}

const ShareForm = Form.create()((props) => {
  const { form, fixedTime, settings, initiated, validator } = props

  return !initiated ? (
    <LoadingModal />
  ) : fixedTime ? (
    <div>
      Your administrators allow assets to be shared for{' '}
      {settings.general.minimumShare} days.
    </div>
  ) : (
    <Form>
      <Form.Item
        className="settings__form-item"
        label={
          <span className="builders__form-label">Link Duration (days)</span>
        }
      >
        <div>
          Your admins allow assets to be shared between{' '}
          {settings.general.minimumShare} and {settings.general.maximumShare}{' '}
          days.
        </div>
        {form.getFieldDecorator('Duration', {
          initialValue: settings.general.minimumShare,
          rules: [
            { type: 'number', message: 'Value must be numeric' },
            { validator },
          ],
        })(<InputNumber />)}
      </Form.Item>
    </Form>
  )
})

/*
 * modal is the redux state for modals
 * modalActions is an object containing the modal actions, namespaced by bindActionCreators
 */
ShareAssetModal.propTypes = {
  modal: PropTypes.object,
  modalActions: PropTypes.object,
}
export default ShareAssetModal
