import React, { Component } from 'react'
import * as permissionsActions from '../../../dux/actions/permissions'
import { connect } from 'react-redux'

const styles = {
  form: { display: 'flex', flexDirection: 'column', color: 'black' },
  label: { fontWeight: '1000px', fontSize: '30px' },
  button: { color: 'black', width: 200 },
  disabled: { color: 'red' },
}

// | first | last | email | password | invite | groups (names, semicolon-delimited) |
// need one of: Invite or Groups, all others are required
class BatchDeleteUsers extends Component {
  // Upload Users

  defaultText = `This utility deletes all users in the group "Delete".`

  componentDidMount() {
    if (this.props.error) this.setState({ output: this.props.error })
    else
      this.setState({
        output: `Users to delete:\n\n${JSON.stringify(
          this.props.usersToDelete.map((u) => u.email),
          null,
          2
        )}`,
      })
  }

  submitHandler = (e) => {
    e.preventDefault()
    this.props.deleteUsers(this.props.usersToDelete)
  }

  render() {
    const isValid = this.props.usersToDelete
    const output = (this.state && this.state.output) || this.defaultText

    return (
      <>
        <form style={styles.form} onSubmit={this.submitHandler}>
          <input
            type="file"
            onChange={this.handleFileChange}
            name="users"
            id="users"
            accept=".csv"
          />
          <br />
          {isValid && (
            <button type="submit" style={styles.button}>
              Start
            </button>
          )}
          {output && (
            <div>
              <pre>{output}</pre>
            </div>
          )}
        </form>
      </>
    )
  }
}

// What will happen when we call these sagas in a loop like this?

const mapDispatchToProps = (dispatch) => ({
  deleteUsers: (users) => {
    dispatch(permissionsActions.deleteUsers(users))
  },
})
const mapStateToProps = (state) => {
  const user = state.user
  const users = state.permissions.users
  const groups = state.permissions.groups

  const deleteGroup = Object.values(groups).find((obj) => obj.name === 'Delete')
  if (!deleteGroup) {
    return { error: 'No "Delete" group found' }
  }
  const usersToDelete = Object.values(users).filter((u) => {
    if (u.email === user.email) {
      return false
    }
    return deleteGroup.members.includes(u.email)
  })
  if (!usersToDelete || usersToDelete.length === 0) {
    return { error: 'No users found to delete' }
  }

  return { usersToDelete }
}
export default connect(mapStateToProps, mapDispatchToProps)(BatchDeleteUsers)
