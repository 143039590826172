import PropTypes from 'prop-types'
import React from 'react'
import { DragSource } from 'react-dnd'
import {
  DND_HOTSPOT,
  dragCollect,
  hotspotSource,
} from '../../../dux/modules/DragDrop'

class Hotspot extends React.Component {
  render() {
    const {
      hotspot,
      handleMouseEnter,
      handleMouseLeave,
      handleSelectHotspot,
      center,
      connectDragSource,
    } = this.props
    return connectDragSource(
      <div
        className={`simulator__hotspot${this.props.active ? '-active' : ''}`}
        onMouseEnter={() => handleMouseEnter(hotspot.id, 'listitem')}
        onMouseLeave={() => handleMouseLeave(hotspot.id, 'listitem')}
        onClick={(e) => handleSelectHotspot(e, hotspot)}
        style={{
          backgroundImage: `url('${this.props.config.baseUrl}/defaults/Hotspot${
            this.props.active ? '_active' : ''
          }.svg')`,
          top: center.y - hotspot.location.y * center.y,
          left: center.x + hotspot.location.x * center.x,
          opacity: this.props.isDragging ? 0 : 1,
        }}
      >
        <div
          className="simulator__hotspot-number"
          style={{
            opacity: this.props.isDragging ? 0 : 1,
          }}
        >
          {this.props.number}
        </div>
      </div>
    )
  }
}

Hotspot.propTypes = {
  hotspot: PropTypes.object,
  active: PropTypes.bool,
  parentId: PropTypes.string,
  center: PropTypes.object,
  builderType: PropTypes.string,
  handleMouseEnter: PropTypes.func,
  handleMouseLeave: PropTypes.func,
  handleSelectHotspot: PropTypes.func,
  connectDragSource: PropTypes.func,
  isDragging: PropTypes.bool,
}

export default DragSource(DND_HOTSPOT, hotspotSource, dragCollect)(Hotspot)
