export default {
  'promach.bam.zone': {
    registrationLinkText: 'Request one here',
    registrationLink: 'https://info.promachbuilt.com/dpg-prm-account',
    privacyPolicy: 'https://www.promachbuilt.com/privacy/',
    termsAndConditions: 'https://www.promachbuilt.com/terms-and-conditions/',
    favicon: '/promach.ico',
    tabTitle: 'BAM! ProMach',
  },
  'titan.bam.zone': {
    favicon: '/titan.ico',
    tabTitle: 'BAM! Titan',
  },
  'autocrane.bam.zone': {
    favicon: '/autocrane.ico',
    tabTitle: 'BAM! Auto Crane',
  },
  'hcea.bam.zone': {
    favicon: '/hcea.png',
    tabTitle: 'BAM! HCEA',
  },
  'develon.bam.zone': {
    favicon: '/develon-icon.png',
    tabTitle: 'BAM! DEVELON',
  },
  'hydrema.bam.zone': {
    favicon: '/hydrema-icon.png',
    tabTitle: 'BAM! Hydrema',
  },
  'viably.bam.zone': {
    favicon: '/viably-icon.png',
    tabTitle: 'BAM! Viably',
  },
  'mclanahan.bam.zone': {
    favicon: '/mclanahan-icon.png',
    tabTitle: 'BAM! McLanahan',
  },
  'salesiq.mclanahan.com': {
    favicon: '/mclanahan-icon.png',
    tabTitle: 'BAM! McLanahan',
    className: 'mclanahan',
  },
  'eagleironworks.bam.zone': {
    favicon: '/eagleironworks-icon.png',
    tabTitle: 'BAM! Eagle Iron Works',
  },
  'salespro.eagleironworks.com': {
    favicon: '/eagleironworks-icon.png',
    tabTitle: 'BAM! Eagle Iron Works',
    className: 'eagleironworks',
  },
  'anaconda.bam.zone': {
    favicon: '/anaconda-icon.png',
    tabTitle: 'BAM! Anaconda Equipment',
  },
  'salespro.anacondaequipment.com': {
    favicon: '/anaconda-icon.png',
    tabTitle: 'BAM! Anaconda Equipment',
    className: 'anaconda',
  },
  'diefenbach.bam.zone': {
    favicon: '/diefenbach-icon.png',
    tabTitle: 'BAM! Diefenbach',
  },
  'salespro.diefenbach.com': {
    favicon: '/diefenbach-icon.png',
    tabTitle: 'BAM! Diefenbach',
    className: 'diefenbach',
  },
  'rubblecrusher.bam.zone': {
    favicon: '/rubblecrusher-icon.png',
    tabTitle: 'BAM! RubbleCrusher',
  },
  'salespro.rubblecrusher.com': {
    favicon: '/rubblecrusher-icon.png',
    tabTitle: 'BAM! RubbleCrusher',
    className: 'rubblecrusher',
  },
  'tribute.pop-art.com': {
    favicon: '/tribute-icon.png',
    tabTitle: 'BAM! Tribute',
    className: 'rubblecrusher',
  },
  'paccarwinch.bam.zone': {
    favicon: '/paccarwinch-icon.png',
    tabTitle: 'BAM! PACCAR Winch',
    className: 'paccarwinch',
  },
  'avanttecno.bam.zone': {
    favicon: '/avanttecno-icon.png',
    tabTitle: 'BAM! Avant Tecno',
    className: 'avanttecno',
  },
  'ramseyindustries.bam.zone': {
    favicon: '/ramseyindustries-icon.png',
    tabTitle: 'BAM! Ramsey Industries',
    className: 'ramseyindustries',
  },
  'ecoverse.bam.zone': {
    favicon: '/ecoverse-icon.png',
    tabTitle: 'BAM! Ecoverse',
    className: 'ecoverse',
  },
}
