import { Button, Input } from 'antd'
import React from 'react'
import { PRODUCT__BUILDER_TYPE } from '../../../../dux/constants/builders'
import AssetListItem from '../components/AssetListItem'
import { getDisplayTextForZoneTarget, getIconForZoneTarget } from './utils'

const EditBuilderSlideForm = (props) => {
  const {
    prevAction,
    nextAction,
    activeSlide,
    allAssets,
    allFolders,
    onCancel,
    builderType,
    builderData,
    selectTarget,
    editTarget,
    removeTarget,
  } = props

  const renderProductEditSlide = () => (
    <div className="builders__sider-form">
      <div className="builders__sider-form-content">
        <div className="builders__sider-header-container">
          <div className="builders__sider-header-icon" onClick={prevAction}>
            <i className="fa fa-long-arrow-left" />
          </div>
          <div className="builders__sider-header-text ">Edit Slide</div>
        </div>
        <div className="builders__sider-form-label">Hotspots</div>
        <div className="builders__sider-form-text">
          Products may include up to 8 hotspots that can link to other assets
          within the app. This is an excellent way to showcase specific features
          or benefits of a product. Click anywhere on the image to place a
          hotspot and link it to an asset. Click a hotspot to see its details or
          click and drag to reposition it on the page.
        </div>
        {/* This is where the hotspots will be previewed */}
        {activeSlide && activeSlide.hotspots && (
          <ol className="builders__sider-list">
            {activeSlide.hotspots.map((hotspot, index) => (
              <li key={`${hotspot.assetId}${index}`}>
                <AssetListItem
                  id={hotspot.id}
                  asset={allAssets[hotspot.assetId]}
                  active={
                    builderData.activeHotspot &&
                    builderData.activeHotspot.id === hotspot.id
                  }
                  displayText={hotspot.displayText}
                  onClick={() => selectTarget('hotspot', hotspot)}
                  onClickRemove={() =>
                    removeTarget('hotspot', hotspot.id, activeSlide.id)
                  }
                  onClickEdit={() => editTarget('hotspot', hotspot)}
                  onMouseEnter={() =>
                    props.componentMouseEnter(hotspot.id, 'hotspot')
                  }
                  onMouseLeave={() =>
                    props.componentMouseLeave(hotspot.id, 'hotspot')
                  }
                  style={{ cursor: 'pointer' }}
                />
              </li>
            ))}
          </ol>
        )}
      </div>
      <div className="builders__sider-button-container">
        <span className="builders__button">
          <Button onClick={onCancel}>Cancel</Button>
        </span>
        <span className="builders__button">
          <Button type="primary" onClick={nextAction}>
            {builderData.isEdit ? 'Save' : 'Create'}
          </Button>
        </span>
      </div>
    </div>
  )

  const renderNavigatorEditSlide = () => (
    <div className="builders__sider-form">
      <div className="builders__sider-form-content">
        <div className="builders__sider-header-container">
          <div className="builders__sider-header-icon" onClick={prevAction}>
            <i className="fa fa-long-arrow-left" />
          </div>
          <div className="builders__sider-header-text ">Edit Slide</div>
        </div>
        <div className="builders__sider-form-label">Slide Label</div>
        <div className="builders__sider-form-text">
          A label will make it easier to identify this slide as a Nav Zone
          target.
        </div>
        <div className="builders__padded-div">
          <Input
            value={activeSlide.displayText}
            onChange={(e) =>
              props.editSlideLabel(
                props.builderType,
                props.activeSlide.id,
                e.target.value
              )
            }
          />
        </div>
        <div className="builders__sider-form-label">Nav Zones</div>
        <div className="builders__sider-form-text">
          Nav Zones are clickable areas that allow you to navigate the library.
          A Nav Zone can link to another slide or to a library folder or asset.
          Nav Zones may not overlap and must have a minimum size of 1cm
          <sup>2</sup>
        </div>
        {/* This is where the hotspots will be previewed */}
        {activeSlide &&
          activeSlide.navzones &&
          activeSlide.navzones.map((navzone) => {
            return (
              <AssetListItem
                key={navzone.id}
                id={navzone.id}
                asset={{
                  icon: getIconForZoneTarget(
                    navzone.targetType,
                    navzone.targetId,
                    allAssets
                  ),
                }}
                displayText={getDisplayTextForZoneTarget(
                  navzone.targetType,
                  navzone.targetId,
                  allAssets,
                  allFolders,
                  builderData.slides
                )}
                onClick={() =>
                  selectTarget(`navzone-${navzone.targetType}`, navzone)
                }
                onClickRemove={() =>
                  props.removeTarget('navzone', navzone.id, activeSlide.id)
                }
                onClickEdit={() =>
                  editTarget(`navzone-${navzone.targetType}`, navzone)
                }
                onMouseEnter={() =>
                  props.componentMouseEnter(navzone.id, 'navzone')
                }
                onMouseLeave={() =>
                  props.componentMouseLeave(navzone.id, 'navzone')
                }
                style={{ cursor: 'pointer' }}
              />
            )
          })}
      </div>
      <div className="builders__sider-button-container">
        <span className="builders__button">
          <Button onClick={onCancel}>Cancel</Button>
        </span>
        <span className="builders__button">
          <Button type="primary" onClick={nextAction}>
            {builderData.isEdit ? 'Save' : 'Create'}
          </Button>
        </span>
      </div>
    </div>
  )

  return builderType === PRODUCT__BUILDER_TYPE
    ? renderProductEditSlide()
    : renderNavigatorEditSlide()
}

export default EditBuilderSlideForm
