import { Button, DatePicker, Icon } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

const { RangePicker } = DatePicker

// ****************
// React Components
// ****************
class DateRangeSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }

    const { activeDateRange } = props
    const [dateStart, dateEnd] = activeDateRange
    this.momentDateStart = dateStart ? moment(dateStart) : moment()
    this.momentDateEnd = dateEnd ? moment(dateEnd) : moment()
    this.tempMomentDateStart = null
    this.tempMomentDateEnd = null
  }

  handleOnClickButton = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleSelectDate = () => {
    const { onSelect, durationParams, setActiveDateRange } = this.props

    if (this.tempMomentDateStart) {
      // If we have only selected a start date, lets assume the user
      // wanted to select the single day as the date range.
      if (!this.tempMomentDateEnd) {
        this.tempMomentDateEnd = this.tempMomentDateStart
      }

      // Save Values to Redux
      const tempDateStart = this.tempMomentDateStart.format('YYYY-MM-DD')
      const tempDateEnd = this.tempMomentDateEnd.format('YYYY-MM-DD')
      setActiveDateRange(tempDateStart, tempDateEnd)

      // Move the temp values over to the current values
      this.momentDateStart = this.tempMomentDateStart
      this.momentDateEnd = this.tempMomentDateEnd

      // Reset the temp values
      this.tempMomentDateStart = null
      this.tempMomentDateEnd = null
    }

    // Make Custom Date Range Button Active
    onSelect(durationParams.key)
    // Close the RangePicker
    this.setState({ isOpen: false })
  }

  handleOnCalendarChange = (dates) => {
    const [selectedDateStart, selectedDateEnd] = dates
    if (selectedDateStart) this.tempMomentDateStart = selectedDateStart
    if (selectedDateEnd) this.tempMomentDateEnd = selectedDateEnd
  }

  render() {
    const { isActive, durationParams } = this.props
    const { isOpen } = this.state

    return (
      <div>
        {/* Render Button */}
        {!isOpen && (
          <Button
            style={styles.durationButton}
            value={durationParams.key}
            shape="round"
            type="primary"
            ghost={!isActive}
            onClick={this.handleOnClickButton}
          >
            <Icon type="calendar" />
          </Button>
        )}
        {/* Render Calendar Picker */}
        {isOpen && (
          <RangePicker
            open={isOpen}
            allowClear={true}
            showTime={false}
            format={'MM-DD-YYYY'}
            separator="&#8212;"
            defaultValue={[this.momentDateStart, this.momentDateEnd]}
            onCalendarChange={this.handleOnCalendarChange}
            renderExtraFooter={() => (
              <DateRangeSelectorFooter
                onClose={this.handleOnClickButton}
                onOk={this.handleSelectDate}
              />
            )}
          />
        )}
      </div>
    )
  }
}

const DateRangeSelectorFooter = ({ onClose, onOk }) => {
  return (
    <div style={styles.rangePickerFooter}>
      <div style={styles.rangePickerFooterButton}>
        <Button onClick={onClose}>Cancel</Button>
      </div>
      <div style={styles.rangePickerFooterButton}>
        <Button onClick={onOk} type={'primary'}>
          Ok
        </Button>
      </div>
    </div>
  )
}

const DateSelectorButton = ({ durationParams, isActive, onSelect }) => {
  const buttonLabel = durationParams.label || 'N/A'

  return (
    <Button
      style={styles.durationButton}
      value={durationParams.key}
      shape="round"
      type="primary"
      ghost={!isActive}
      onClick={() => onSelect(durationParams.key)}
    >
      {buttonLabel}
    </Button>
  )
}

const DateRangeSelectorGroup = ({
  menuItems,
  activeDuration,
  setActiveDuration,
  activeDateRange,
  setActiveDateRange,
}) => {
  const isActive = (duration, activeDuration) => {
    return duration.key === activeDuration
  }

  return (
    <div style={styles.container}>
      {menuItems.map((duration) => {
        return duration.key === 'custom' ? (
          <DateRangeSelector
            key={duration.key}
            durationParams={duration}
            isActive={isActive(duration, activeDuration)}
            onSelect={setActiveDuration}
            activeDateRange={activeDateRange}
            setActiveDateRange={setActiveDateRange}
          />
        ) : (
          <DateSelectorButton
            key={duration.key}
            durationParams={duration}
            isActive={isActive(duration, activeDuration)}
            onSelect={setActiveDuration}
          />
        )
      })}
    </div>
  )
}

// ******
// Styles
// ******
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  durationButton: {
    marginLeft: '5px',
    marginRight: '5px',
  },
  rangePickerFooter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rangePickerFooterButton: {
    paddingLeft: '5px',
    paddingRight: '5px',
  },
}

// *********
// PropTypes
// *********
DateRangeSelector.propTypes = {
  durationParams: PropTypes.object,
  isActive: PropTypes.bool,
  onSelect: PropTypes.func,
  activeDateRange: PropTypes.arrayOf(PropTypes.string),
  setActiveDateRange: PropTypes.func,
}

DateRangeSelectorFooter.propTypes = {
  onClose: PropTypes.func,
  onOk: PropTypes.func,
}

DateSelectorButton.propTypes = {
  durationParams: PropTypes.object,
  isActive: PropTypes.bool,
  onSelect: PropTypes.func,
}

DateSelectorButton.defaultProps = {
  onSelect: () => {},
}

DateRangeSelectorGroup.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object),
  activeDuration: PropTypes.string,
  setActiveDuration: PropTypes.func,
  activeDateRange: PropTypes.arrayOf(PropTypes.string),
  setActiveDateRange: PropTypes.func,
}

export default DateRangeSelectorGroup
