import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import SessionTimeoutError from '../modules/SessionTimeoutError'
import * as apiActions from './api'
import * as appActions from './appActions'
import * as modalActions from './modal'
import * as userActions from './user'

export const GET_INVITATIONS = 'GET_INVITATIONS'
export const GET_INVITATIONS_SUCCESS = 'GET_INVITATIONS_SUCCESS'
export const ADD_INVITATION = 'ADD_INVITATION'
export const ADD_INVITATION_SUCCESS = 'ADD_INVITATION_SUCCESS'
export const MAKE_INVITATION_EDITABLE = 'MAKE_INVITATION_EDITABLE'
export const TOGGLE_INVITATION_VALIDITY = 'TOGGLE_INVITATION_VALIDITY'
export const EDIT_INVITATION = 'EDIT_INVITATION'
export const EDIT_INVITATION_SUCCESS = 'EDIT_INVITATION_SUCCESS'
export const DELETE_INVITATION = 'DELETE_INVITATION'
export const DELETE_INVITATION_SUCCESS = 'DELETE_INVITATION_SUCCESS'

// SetPendingFlag - allows action to be called as part of longer saga
export function getInvitations(id = 'all', setPendingFlag = true) {
  return {
    type: GET_INVITATIONS,
    id,
    setPendingFlag,
  }
}

export function* watchGetInvitations() {
  yield takeEvery(GET_INVITATIONS, getInvitationSaga)
}

export function* getInvitationSaga(action) {
  if (action.setPendingFlag)
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })

  try {
    const config = yield select((state) => state.config.appConfig)
    const invitations = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/invitations/${action.id}`,
      init: {
        method: 'GET',
      },
    })

    yield put({ type: GET_INVITATIONS_SUCCESS, invitations })
    if (action.setPendingFlag) yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            error.clientMessage ||
            'An error occurred while retrieving this invitation',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}

export function addInvitation(invitation) {
  return {
    type: ADD_INVITATION,
    invitation,
  }
}

export function* watchAddInvitation() {
  yield takeEvery(ADD_INVITATION, addInvitationSaga)
}

export function* addInvitationSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC })
  try {
    const config = yield select((state) => state.config.appConfig)
    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/invitations/add`,
      init: {
        method: 'POST',
        body: JSON.stringify(action.invitation),
      },
    })

    yield put({ type: ADD_INVITATION_SUCCESS, invitation: action.invitation })
    yield put({ type: appActions.COMPLETE_ASYNC })
    yield put({ type: modalActions.CLOSE_MODAL })
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            error.clientMessage ||
            'An error occurred while adding this invitation',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}

export function editInvitation(invite) {
  return {
    type: EDIT_INVITATION,
    invite,
  }
}

export function* watchEditInvitation() {
  yield takeEvery(EDIT_INVITATION, editInvitationSaga)
}

export function* editInvitationSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC })
  try {
    const config = yield select((state) => state.config.appConfig)
    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/invitations/update`,
      init: {
        method: 'POST',
        body: JSON.stringify(action.invite),
      },
    })
    yield put({ type: EDIT_INVITATION_SUCCESS, invite: action.invite })
    yield put({ type: modalActions.CLOSE_MODAL })
    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            error.clientMessage ||
            'An error occurred while saving this invitation',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}

export function deleteInvitation(id) {
  return {
    type: DELETE_INVITATION,
    id,
  }
}

export function* watchDeleteInvitation() {
  yield takeEvery(DELETE_INVITATION, deleteInvitationSaga)
}

export function* deleteInvitationSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
  try {
    const config = yield select((state) => state.config.appConfig)
    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/invitations/delete`,
      init: {
        method: 'POST',
        body: JSON.stringify(action.id),
      },
    })
    yield put({ type: DELETE_INVITATION_SUCCESS, id: action.id })
    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            error.clientMessage ||
            'An error occurred while deleting this invitation',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}

export default all([
  watchGetInvitations(),
  watchAddInvitation(),
  watchEditInvitation(),
  watchDeleteInvitation(),
])
