import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { TRANSCODING_STATUS_HIDDEN } from '../../dux/constants/assets'
import {
  COMPARISON__BUILDER_TYPE,
  NAVIGATOR__BUILDER_TYPE,
} from '../../dux/constants/builders'
import BuilderPreview from './previews/BuilderPreview'
import CalculatorPreview from './previews/CalculatorPreview'
import ImagePreview from './previews/ImagePreview'
import NewsPreview from './previews/NewsPreview'
import FeaturePreview from './previews/FeaturePreview'
import ProductPreview from './previews/ProductPreview'
import NavigatorPreview from './previews/NavigatorPreview'
import { FormPreview } from './previews/FormPreview/FormPreview'
/*
 * We have determined the file type during asset creation and assigned an icon;
 * Use the icon to determine preview format
 */
const AssetPreview = (props) => {
  /* temporary solution to develop calculator preview until we format calculators to have a unique icon*/
  if (props.asset.extension === 'calc') {
    return <CalculatorPreview {...props} />
  }
  if (props.asset.extension === 'form') {
    return <FormPreview asset={props.asset} />
  }
  //const isIE = /*@cc_on!@*/false || !!document.documentMode
  switch (props.asset.icon) {
    case 'fa-file-image-o':
      return (
        <Fragment>
          <ImagePreview {...props} />
        </Fragment>
      )

    case 'fa-file-pdf-o':
      return props.modalOpen ? (
        <div />
      ) : (
        <div className="assetdetail__preview">
          <object
            className="assetdetail__preview-pdf"
            key={props.asset.id}
            data={props.asset.url}
            type="application/pdf"
            width={'100%'}
            height={'100%'}
          >
            <param name="zoom" value="scale" />
            <param name="view" value="FitH" />
            <embed src={props.asset.url} type="application/pdf"></embed>
          </object>
        </div>
      )

    case 'fa-file-video-o':
      return (
        <div className="assetdetail__preview">
          <video
            key={`${props.asset.id}${props.asset.key}${
              props.asset.videoSrc ? props.asset.videoSrc.status : null
            }`}
            className="assetdetail__preview-video"
            controls
            controlsList="nodownload"
            onContextMenu={(e) => {
              e.preventDefault()
              return false
            }}
          >
            {props.asset.videoSrc &&
              props.asset.videoSrc.status !== TRANSCODING_STATUS_HIDDEN &&
              props.asset.videoSrc.sources.map((src) => {
                return (
                  <source
                    key={src}
                    src={`${props.baseUrl}/${src}`}
                    type="video/mp4"
                  />
                )
              })}
            <source
              key="original"
              src={`${props.asset.url}#t=1`}
              type={props.asset.file_type}
            />
          </video>
        </div>
      )

    case 'fa-newspaper-o':
      return <NewsPreview {...props} />
    case 'fa-bullseye':
      return <FeaturePreview {...props} />
    case 'fa-cubes':
      return <ProductPreview {...props} />
    case 'fa-sitemap':
      return <NavigatorPreview type={NAVIGATOR__BUILDER_TYPE} {...props} />
    case 'fa-columns':
      return <BuilderPreview type={COMPARISON__BUILDER_TYPE} {...props} />

    default:
      return (
        <div className="assetdetail__preview-icon">
          <i className={`fa ${props.asset.icon}`} />
        </div>
      )
  }
}

AssetPreview.propTypes = {
  asset: PropTypes.object,
  allAssets: PropTypes.object.isRequired,
  thumbnail: PropTypes.string,
  onPdfMenuClick: PropTypes.func,
  pdfMenuKey: PropTypes.string,
  showLightbox: PropTypes.bool,
  closeLightbox: PropTypes.func,
  openLightbox: PropTypes.func,
  modalActions: PropTypes.object,
  assetActions: PropTypes.object,
  appActions: PropTypes.object,
  originalAssetData: PropTypes.object,
  modalOpen: PropTypes.bool,
}

AssetPreview.defaultProps = {
  thumbnail: null,
  modalOpen: false,
}

export default AssetPreview
