import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

const Attribute = (props) => (
  <Fragment>
    <div className={props.cssLabelClass}>{props.label}</div>
    <div className={props.cssValueClass}>{props.value}</div>
  </Fragment>
)

Attribute.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cssLabelClass: PropTypes.string,
  cssValueClass: PropTypes.string,
}

export default Attribute
