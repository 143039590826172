//Source from: https://aclaes.com/responsive-background-images-with-srcset-and-sizes/
class ResponsiveBackgroundImage {
  constructor(element, getContainerHeight) {
    this.element = element
    this.getContainerHeight = getContainerHeight
    this.img = element.querySelector('img')
    this.src = ''

    this.img.addEventListener('load', () => {
      this.update()
    })

    if (this.img.complete) {
      this.update()
    }
  }

  update() {
    let src =
      typeof this.img.currentSrc !== 'undefined'
        ? this.img.currentSrc
        : this.img.src
    if (this.src !== src) {
      let bgSize = 'cover'
      this.src = src
      if (
        this.img.naturalWidth / this.img.naturalHeight / (16 / 9) < 0.75 ||
        this.img.naturalWidth / this.img.naturalHeight / (16 / 9) > 1.25
      ) {
        bgSize = 'contain'
        if (this.img.naturalHeight < this.getContainerHeight()) {
          bgSize = null
        }
      }
      this.element.style.backgroundImage = 'url("' + this.src + '")'
      this.element.style.backgroundPosition = 'center'
      this.element.style.backgroundRepeat = 'no-repeat'
      this.element.style.backgroundSize = bgSize
    }
  }
}

export default ResponsiveBackgroundImage
