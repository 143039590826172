import PropTypes from 'prop-types'
import React from 'react'
import '../styles/Library.css'

const Folder404 = () => <div className="folder404"></div>

Folder404.propTypes = {
  activeFolder: PropTypes.string,
}

export default Folder404
