// pull config object from bundled json file
const account = 'qa'
const configFromJSON = require(`./config/${account}.config.json`)

// overrides
const config = {
  ...configFromJSON,
  domain: 'localhost',
}

export default config
