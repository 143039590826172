import PropTypes from 'prop-types'
import React from 'react'
import { DropTarget } from 'react-dnd'
import {
  DND_NAVZONE,
  dropCollect,
  navzoneTarget,
} from '../../../dux/modules/DragDrop'
import Navzone from './Navzone'

class NavigatorSimulator extends React.Component {
  render() {
    const {
      imageWidth,
      imageHeight,
      slideImageUrl,
      activeSlide,
      center,
      builderType,
      startPosition,
      endPosition,
      componentMouseEnter,
      componentMouseLeave,
      connectDropTarget,
      zoneColor,
      activeZone,
      onDragHandleDown,
      dragHandleDown,
      startNavzone,
      endNavzone,
      resizeNavzone,
    } = this.props
    return connectDropTarget(
      <div
        id="navigator-view"
        className="simulator__view"
        style={{
          width: imageWidth,
          height: imageHeight,
          backgroundImage: `url(${slideImageUrl})`,
          cursor: `${activeSlide.mainImage ? 'crosshair' : 'not-allowed'}`,
        }}
        onMouseDown={startNavzone}
        onMouseMove={resizeNavzone}
        onMouseUp={endNavzone}
      >
        {activeSlide.navzones.map((zone) => {
          return (
            <Navzone
              key={zone.id}
              center={center}
              navzone={zone}
              activeZoneId={activeZone && activeZone.id}
              imageWidth={imageWidth}
              imageHeight={imageHeight}
              builderType={builderType}
              parentId={activeSlide.id}
              handleMouseEnter={componentMouseEnter}
              handleMouseLeave={componentMouseLeave}
              mouseIsDown={this.props.mouseIsDown}
              onDragHandleDown={onDragHandleDown}
              dragHandleDown={dragHandleDown}
            />
          )
        })}
        <div
          style={{
            position: 'absolute',
            pointerEvents: 'none',
            top: center.y - startPosition.y * center.y,
            left: center.x + startPosition.x * center.x,
            width: ((endPosition.x - startPosition.x) / 2) * imageWidth,
            height: ((startPosition.y - endPosition.y) / 2) * imageHeight,
            backgroundColor: zoneColor,
            opacity: 0.45,
            border: '2px solid white',
            zIndex: 20,
          }}
        ></div>
      </div>
    )
  }
}

NavigatorSimulator.propTypes = {
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  slideImageUrl: PropTypes.string,
  activeSlide: PropTypes.object,
  activeZone: PropTypes.object,
  navzone: PropTypes.object,
  center: PropTypes.object,
  builderType: PropTypes.string,
  builderActions: PropTypes.object,
  componentMouseEnter: PropTypes.func,
  componentMouseLeave: PropTypes.func,
  connectDropTarget: PropTypes.func,
  startPosition: PropTypes.object,
  endPosition: PropTypes.object,
  zoneColor: PropTypes.string,
  dragHandleDown: PropTypes.bool,
  startNavzone: PropTypes.func,
  endNavzone: PropTypes.func,
  resizeNavzone: PropTypes.func,
  onDragHandleDown: PropTypes.func,
}

export default DropTarget(
  DND_NAVZONE,
  navzoneTarget,
  dropCollect
)(NavigatorSimulator)
