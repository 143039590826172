/**
 * Given a form config, values map, and user info, builds text & HTML email body suitable
 * for sending form results.
 *
 * Copied from `mobile-sales-tool/src/dux/form/buildEmailBody.ts`.
 *
 * @param {Object} config
 * @param {Record<string, undefined | string | boolean | number>} valuesByKey
 * @param {{ name: string, email: string }} submittedBy
 * @returns {{ textBody: string, htmlBody: string }}
 */
export default function buildEmailBody(config, valuesByKey, submittedBy) {
  let textBody = ''
  // per best practices as seen at http://htmlemailboilerplate.com/
  let htmlBody = [
    '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">',
    '<html xmlns="http://www.w3.org/1999/xhtml">',
    '<head></head>',
    '<body>',
  ].join('\n')

  for (const section of config.elements) {
    const { sectionName, sectionData } = section
    if (!sectionData) continue
    const inputs = sectionData
      .map((input) => {
        // ignore non-input types like "label" and "image"
        if (
          input.type !== 'input' &&
          input.type !== 'textarea' &&
          input.type !== 'select' &&
          input.type !== 'checkbox'
        )
          return undefined
        // ignore inputs that have a value of undefined
        const key = `${sectionName}-${input.id}`
        if (!valuesByKey[key]) return undefined
        return {
          label: input.emailLabel || input.label,
          value: valuesByKey[key],
        }
      })
      .filter((input) => input !== undefined)
    if (inputs.length === 0) continue
    if (sectionName) {
      textBody += sectionName + ':\n'
      htmlBody += `<h3>${sectionName}</h3>\n`
    }
    htmlBody += '<ul>\n'
    for (const { label, value } of inputs) {
      textBody += `  ${label}: ${value}`
      htmlBody += `<li>${label}: ${value}</li>\n`
    }
    htmlBody += '</ul>\n'
  }

  textBody += `\n\nsubmitted by ${submittedBy.name} <${submittedBy.email}>\n`
  htmlBody += `\n<br /><p>submitted by ${submittedBy.name} &lt;${submittedBy.email}&gt;</p>\n`
  htmlBody += '</html>\n'

  return { textBody, htmlBody }
}
