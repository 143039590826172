import { Checkbox, Divider, Select, Tree } from 'antd'
import update from 'immutability-helper'
import { cloneDeep } from 'lodash'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { COLLAPSE_ALL, EXPAND_ALL } from '../../dux/constants/permissions'
import { buildPath, requiresTraverserRole } from '../../dux/modules/utils'
import {
  addEntityToFolder,
  addRoleToEntity,
  getAllParentNodes,
  getDefaultExpandedNodes,
  getEditableRootFolders,
  hasTraverserOnly,
  removeEntityFromFolder,
  removeRoleFromEntity,
} from './utils/permissionsUtils'

const { TreeNode } = Tree

class PermissionsTree extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedEndpoint: 'LIB',
      endpointList: this.props.endpoints
        .filter((endpoint) => endpoint.id !== 'HOME')
        .map((endpoint) => ({ key: endpoint.id, value: endpoint.name })),
      expandedTreeNodes: getDefaultExpandedNodes(
        this.props.endpoints,
        this.props.folders
      ),
      contentRoles:
        this.props.savedContentRoles ||
        Object.keys(this.props.folders).reduce((result, folder) => {
          result[folder] = cloneDeep(this.props.folders[folder].contentRoles)
          return result
        }, {}),
    }
    this.props.setFooter(this.getFooterComponents())
  }

  getContentRoles = () => this.state.contentRoles

  getExpandedStatus = (endpoint) =>
    this.state.expandedTreeNodes[endpoint].expandAllToggle

  getSelectedEndpoint = () => this.state.selectedEndpoint

  getFooterComponents = () => {
    return (
      <div>
        <span className="permissionstree__link" onClick={this.handleExpandAll}>
          {this.getExpandedStatus(this.state.selectedEndpoint)} All
        </span>
        <Divider type="vertical" className="permissionstree__divider" />
        <span className="permissionstree__padded">
          Display:{' '}
          <Select
            className="permissionstree__header-select"
            value={
              this.state.expandedTreeNodes[this.state.selectedEndpoint]
                .filterOption
            }
            onChange={this.handleFilterChange}
          >
            <Select.Option value="all">All Folders</Select.Option>
            <Select.Option value="filtered">
              {`This ${this.props.entityType}'s folders`}
            </Select.Option>
          </Select>
        </span>
      </div>
    )
  }

  handleEndpointChange = (value) => {
    this.setState(
      (prevState) => {
        if (prevState.selectedEndpoint !== value)
          return {
            selectedEndpoint: value,
          }
        return null
      },
      () => this.props.setFooter(this.getFooterComponents())
    )
  }

  handleExpand = (selectedNodes) => {
    this.setState((prevState) =>
      update(prevState, {
        expandedTreeNodes: {
          [prevState.selectedEndpoint]: {
            expandedNodes: {
              $set: selectedNodes,
            },
          },
        },
      })
    )
  }

  handleFilterChange = (option) => {
    this.setState(
      (prevState) =>
        update(prevState, {
          expandedTreeNodes: {
            [prevState.selectedEndpoint]: {
              filterOption: {
                $set: option,
              },
            },
          },
        }),
      () => this.props.setFooter(this.getFooterComponents())
    )
  }

  handleExpandAll = () => {
    this.setState(
      (prevState) => {
        const nodes =
          prevState.expandedTreeNodes[prevState.selectedEndpoint]
            .expandAllToggle === EXPAND_ALL
            ? getAllParentNodes(
                prevState.selectedEndpoint,
                this.props.endpoints,
                this.props.folders
              )
            : []
        return update(prevState, {
          expandedTreeNodes: {
            [prevState.selectedEndpoint]: {
              expandedNodes: {
                $set: nodes,
              },
              expandAllToggle: {
                $set:
                  prevState.expandedTreeNodes[prevState.selectedEndpoint]
                    .expandAllToggle === EXPAND_ALL
                    ? COLLAPSE_ALL
                    : EXPAND_ALL,
              },
            },
          },
        })
      },
      () => this.props.setFooter(this.getFooterComponents())
    )
  }

  handleContentRoleChange = (folder, clickedRole, checked) => {
    return this.setState((prevState, props) => {
      if (!checked) {
        // Remove entity, then check if traverser is needed after removal
        if (prevState.contentRoles[folder][props.entity].roleIds.length === 1) {
          prevState = removeEntityFromFolder(prevState, folder, props.entity)

          if (
            requiresTraverserRole(
              folder,
              props.entity,
              props.folders,
              null,
              prevState.contentRoles
            )
          ) {
            prevState = addEntityToFolder(
              prevState,
              folder,
              props.entity,
              props.entityType,
              ['traverser']
            )
          }
          // Filter out role, then check if traverser is still needed
        } else {
          prevState = removeRoleFromEntity(
            prevState,
            folder,
            props.entity,
            clickedRole
          )

          if (
            hasTraverserOnly(prevState.contentRoles, folder, props.entity) &&
            !requiresTraverserRole(
              folder,
              props.entity,
              props.folders,
              null,
              prevState.contentRoles
            )
          ) {
            prevState = removeEntityFromFolder(prevState, folder, props.entity)
          }
        }

        const foldersWithExtraTraverserRole = buildPath(
          folder,
          this.props.folders
        ).filter(
          (fldr) =>
            !requiresTraverserRole(
              fldr,
              props.entity,
              props.folders,
              fldr,
              prevState.contentRoles
            )
        )

        // Traverser role not needed...remove the entity from content roles
        foldersWithExtraTraverserRole.forEach((fldr) => {
          if (
            prevState.contentRoles[fldr][props.entity] &&
            prevState.contentRoles[fldr][props.entity].roleIds.filter(
              (role) => role !== 'traverser'
            ).length === 0
          ) {
            prevState = removeEntityFromFolder(prevState, fldr, props.entity)
          } else if (prevState.contentRoles[fldr][props.entity]) {
            prevState = removeRoleFromEntity(
              prevState,
              fldr,
              props.entity,
              'traverser'
            )
          }
        })
        return prevState
      }

      const parentsThatNeedTraverserRole = buildPath(folder, this.props.folders)
        .filter((fldr) => fldr !== folder)
        .filter(
          (fldr) =>
            !prevState.contentRoles[fldr] ||
            !prevState.contentRoles[fldr][props.entity] ||
            prevState.contentRoles[fldr][props.entity].roleIds.length === 0
        )

      parentsThatNeedTraverserRole.forEach((fldr) => {
        prevState = addEntityToFolder(
          prevState,
          fldr,
          props.entity,
          props.entityType,
          ['traverser']
        )
      })

      if (!prevState.contentRoles[folder][props.entity]) {
        return addEntityToFolder(
          prevState,
          folder,
          props.entity,
          props.entityType,
          [clickedRole]
        )
      }

      return addRoleToEntity(
        prevState,
        folder,
        props.entity,
        prevState.contentRoles[folder][props.entity].roleIds
          .filter((role) => role !== 'traverser')
          .concat([clickedRole])
      )
    })
  }

  nodeIsVisible = (node) => {
    const { expandedTreeNodes, selectedEndpoint, contentRoles } = this.state
    if (expandedTreeNodes[selectedEndpoint].filterOption === 'all') return true
    return (
      contentRoles[node][this.props.entity] &&
      contentRoles[node][this.props.entity].roleIds.length > 0
    )
  }

  renderTreeNodes = (data) => {
    const { folders } = this.props
    return data
      .sort((a, b) => folders[a].name.localeCompare(folders[b].name))
      .map((folder) => {
        if (this.props.folders[folder].childFolders.length > 0) {
          return (
            <TreeNode
              title={this.renderTitle(folder)}
              key={folder}
              dataRef={folder}
              className={
                this.nodeIsVisible(folder)
                  ? 'permissionstree__treenode'
                  : 'permissionstree_treenode-hidden'
              }
            >
              {this.renderTreeNodes(this.props.folders[folder].childFolders)}
            </TreeNode>
          )
        }
        return (
          <TreeNode
            title={this.renderTitle(folder)}
            key={folder}
            dataRef={folder}
            className={
              this.nodeIsVisible(folder)
                ? 'permissionstree__treenode'
                : 'permissionstree_treenode-hidden'
            }
          />
        )
      })
  }

  groupHasFolderContentRole = (folder, role) =>
    this.state.contentRoles[folder][this.props.entity] &&
    this.state.contentRoles[folder][this.props.entity].roleIds.indexOf(role) >
      -1

  renderTitle = (folder) => {
    return (
      <div className="permissionstree__row">
        <div className="permissionstree__cell1">
          {this.props.folders[folder].name}
        </div>
        <div className="permissionstree__cell2">
          {this.props.roles.map((role) => {
            return (
              <Checkbox
                key={role.id}
                checked={this.groupHasFolderContentRole(folder, role.id)}
                onChange={(e) =>
                  this.handleContentRoleChange(
                    folder,
                    role.id,
                    e.target.checked
                  )
                }
                className="permissionstree__cell-item"
                disabled={
                  role.id === 'traverser' ||
                  this.props.folders[folder].type === 'resources'
                }
              />
            )
          })}
        </div>
      </div>
    )
  }

  render() {
    const { selectedEndpoint, endpointList, expandedTreeNodes } = this.state
    return (
      <Fragment>
        <div className="permissionstree__header">
          <div className="permissionstree__header-left">
            <Select
              defaultValue={selectedEndpoint}
              onChange={this.handleEndpointChange}
              className="permissionstree__header-select"
            >
              {endpointList.map((endpoint) => (
                <Select.Option key={endpoint.key} value={endpoint.key}>
                  {endpoint.value}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="permissionstree__header-right">
            {this.props.roles.map((role) => (
              <div key={role.id} className="permissionstree__header-title">
                {role.displayName}
              </div>
            ))}
          </div>
        </div>
        <div className="permissionstree__tree-container">
          <Tree
            selectable={false}
            expandedKeys={expandedTreeNodes[selectedEndpoint].expandedNodes}
            blockNode
            onExpand={this.handleExpand}
          >
            {this.renderTreeNodes(
              getEditableRootFolders(
                this.props.endpoints.filter(
                  (endpoint) => endpoint.id === selectedEndpoint
                )[0].rootFolders,
                this.props.folders
              )
            )}
          </Tree>
        </div>
      </Fragment>
    )
  }
}

PermissionsTree.propTypes = {
  endpoints: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  folders: PropTypes.object.isRequired,
  entity: PropTypes.string.isRequired,
}

export default PermissionsTree
