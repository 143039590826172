import * as appActions from '../actions/appActions'

const INITIAL_STATE = {
  appConfig: {},
}

function configReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case appActions.SET_APP_CONFIG:
      return {
        ...state,
        appConfig: action.config,
      }

    default:
      return state
  }
}

export default configReducer
