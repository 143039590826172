import {
  ASSETS_RESOURCE_FOLDER,
  MST_ENDPOINT,
  NEWS_RESOURCE_FOLDER,
  RESOURCES_FOLDER,
  SEARCH_FOLDER,
  UGC_FOLDER,
} from '../constants/folder'
import { buildPath, validatePrivilege } from './utils'

const utils = {
  /*
   * Do not allow dragging of Root Folder or Recycle Bin or Search or UGC folder
   * User must have 'movefolder' privilege on the dragged folder
   */
  folderCanBeDragged: (props) => {
    return !(
      props.type === 'folder' &&
      (props.id === props.folders.recycleBin ||
        !props.folders.byId[props.id].parentId ||
        props.id === NEWS_RESOURCE_FOLDER ||
        props.id === ASSETS_RESOURCE_FOLDER ||
        props.id === SEARCH_FOLDER ||
        props.id === UGC_FOLDER ||
        props.folders.byId[props.id].virtual ||
        !validatePrivilege(
          'movefolder',
          props.privileges,
          props.email,
          props.groups,
          props.folders.activeFolder,
          props.folders.byId
        ))
    )
  },

  /* Returns true if the target folder is the Recycle Bin, or one of its children */
  targetIsRecycleBin: (props) => {
    return (
      buildPath(props.id, props.folders.byId).indexOf(
        props.folders.recycleBin
      ) > -1
    )
  },

  /* Assets may not be dropped in the same folder, UGC or Search folders */
  /* Assets may not be dropped in MST folders */
  targetIsValidForAssets: (props) => {
    return (
      props.folders.activeFolder !== props.id &&
      props.id !== UGC_FOLDER &&
      props.id !== SEARCH_FOLDER &&
      props.endpointId !== MST_ENDPOINT &&
      !props.folders.byId[props.id].virtual
    )
  },

  /* Folders may not be dropped in UGC or Search folders */
  targetIsValidForFolders: (props) => {
    return (
      props.id !== UGC_FOLDER &&
      props.id !== SEARCH_FOLDER &&
      props.id !== RESOURCES_FOLDER &&
      !props.folders.byId[props.id].virtual
    )
  },

  getNodeIndex: (allFolders, id) => {
    const folder = allFolders[id]
    const parent = folder.parentId
    if (!parent) return -1
    return allFolders[parent].childFolders.findIndex((child) => child === id)
  },

  haveSameParent: (allFolders, child1, child2) => {
    return allFolders[child1].parentId === allFolders[child2].parentId
  },

  /* Folder will close if hovering over top/bottom quarter, and it is not a root folder and it 
     is not the drag item's parent */
  shouldCloseOnHover: (props, item, mousePos, targetPos) => {
    return (
      (mousePos.y > targetPos.top + (targetPos.height * 3) / 4 ||
        mousePos.y < targetPos.bottom - (targetPos.height * 3) / 4) &&
      props.openFolderKeys.indexOf(props.id) > -1 &&
      props.folders.byId[props.id].parentId &&
      props.id !== props.folders.byId[item.id].parentId
    )
  },

  shouldOpenOnHover: (props, item, mousePos, targetPos) => {
    return (
      mousePos.y > targetPos.bottom - (targetPos.height * 3) / 4 &&
      mousePos.y < targetPos.bottom - (targetPos.height * 1) / 4 &&
      props.openFolderKeys.indexOf(props.id) < 0
    )
  },

  /*
   *  This function returns true if hovering over a Root folder, or a child of the Resources folder
   *  If true, the hover function won't consider hovering over the top 1/4 or bottom 1/4 of the
   *  folder to allow reordering (meaning you can only drop into the folder - if otherwise allowed)
   */
  folderDoesNotAllowReorder: (props) => {
    return (
      !props.folders.byId[props.id].parentId ||
      props.folders.byId[props.id].parentId === RESOURCES_FOLDER
    )
  },
}

export default utils
