import { Layout } from 'antd'
import { last } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  exportCalculations,
  exportEventsTable,
  refreshAnalyticsDataForView,
} from '../../../dux/actions/analytics'
import {
  setActiveAnalyticsDuration,
  setActiveAnalyticsPlatform,
  setActiveAnalyticsView,
  setAnalyticsCustomDateRange,
  toggleSidebar,
} from '../../../dux/actions/appActions'
import {
  SIDEBAR_BACKGROUND_COLOR,
  SIDEBAR_COLLAPSE_BREAKPOINT,
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_WIDTH,
} from '../../../dux/constants/analytics'
import {
  getDateMenuItems,
  getActiveAnalyticsDuration,
  getActiveAnalyticsPlatform,
  getActiveAnalyticsView,
  getAnalyticsCustomDateRange,
  getAllCalculators,
} from '../../../dux/reducers/analytics'
import AnalyticsHeader from './AnalyticsContent/AnalyticsHeader'
import AnalyticsPlotView from './AnalyticsContent/AnalyticsPlotView'
import AnalyticsSidebar from './AnalyticsSidebar'
import { platformMenuItems, sidebarMenuItems } from './menuConstants'
import './styles.css'

const { Content, Sider } = Layout

// ******
// Redux
// ******
const mapStateToProps = (state) => ({
  activeAnalyticsView: getActiveAnalyticsView(state),
  activeAnalyticsPlatform: getActiveAnalyticsPlatform(state),
  activeAnalyticsDuration: getActiveAnalyticsDuration(state),
  analyticsCustomDateRange: getAnalyticsCustomDateRange(state),
  analyticsConfig: state.settings.modules.analytics,
  allCalculators: getAllCalculators(state),
  dateRangeItems: getDateMenuItems(state),
})

const mapDispatchToProps = (dispatch) => ({
  exportTable: (tableData, view, dateRange) =>
    dispatch(exportEventsTable(tableData, view, dateRange)),
  exportCalculations: (exportData) => dispatch(exportCalculations(exportData)),
  toggleSidebar: (width) => dispatch(toggleSidebar(width)),
  setActiveAnalyticsView: (analyticsViewKey) =>
    dispatch(setActiveAnalyticsView(analyticsViewKey)),
  setActiveAnalyticsPlatform: (platform) =>
    dispatch(setActiveAnalyticsPlatform(platform)),
  setActiveAnalyticsDuration: (duration) =>
    dispatch(setActiveAnalyticsDuration(duration)),
  setAnalyticsCustomDateRange: (dateStart, dateEnd) =>
    dispatch(setAnalyticsCustomDateRange(dateStart, dateEnd)),
  refreshAnalyticsDataForView: (analyticsView) =>
    dispatch(refreshAnalyticsDataForView(analyticsView)),
})

// ****************
// React Components
// ****************
class Analytics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSidebarCollapsed: false,
    }
  }

  componentDidMount() {
    const {
      history,
      toggleSidebar,
      activeAnalyticsView,
      refreshAnalyticsDataForView,
    } = this.props

    history.replace(`/analytics/${activeAnalyticsView}`)
    window.addEventListener('popstate', this.handleBrowserNavigation)

    toggleSidebar(0)
    refreshAnalyticsDataForView(activeAnalyticsView)
  }

  componentDidUpdate() {
    const { history, activeAnalyticsView, refreshAnalyticsDataForView } =
      this.props

    const currentPath = last(history.location.pathname.split('/'))
    const URLReflectsActiveView = currentPath === activeAnalyticsView

    if (currentPath === 'analytics') {
      history.goBack()
    }

    // This is skipped when the user has navigated using the
    // browser back/forward arrows. That navigation action will
    // first change the url, and the handleBrowserNavigation function
    // will set the active analytics view.
    // In all other cases, the active analytics view will be changed first
    // and the url will be set here to reflect that change.
    if (!URLReflectsActiveView) {
      refreshAnalyticsDataForView(activeAnalyticsView)
      history.push(`/analytics/${activeAnalyticsView}`)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleBrowserNavigation)
    this.props.toggleSidebar(-1)
  }

  handleBrowserNavigation = () => {
    const { history, setActiveAnalyticsView } = this.props

    const analyticsRoute = last(history.location.pathname.split('/'))
    setActiveAnalyticsView(analyticsRoute)
  }

  handleSidebarCollapse = (collapsed) => {
    this.setState({ isSidebarCollapsed: collapsed })
  }

  render() {
    const {
      activeAnalyticsView,
      setActiveAnalyticsView,
      analyticsConfig,
      dateRangeItems,
    } = this.props
    const { isSidebarCollapsed } = this.state

    const availableViews = analyticsConfig.analyticsViews || [
      'AnalyticsOverview',
      'UserActivity',
      'AssetsDetail',
      'ShareDetail',
    ]
    const analyticsViews = sidebarMenuItems.filter((sidebarItem) =>
      availableViews.includes(sidebarItem.key)
    )
    const activeMenuItem = analyticsViews.find(
      (menuItem) => menuItem.key === activeAnalyticsView
    )
    const titleText = activeMenuItem && activeMenuItem.title

    const contentMarginLeft = isSidebarCollapsed
      ? SIDEBAR_COLLAPSED_WIDTH
      : SIDEBAR_WIDTH

    return (
      <Layout>
        {/* Sidebar Menu */}
        <Sider
          style={styles.siderContainer}
          collapsible
          trigger={null}
          breakpoint={SIDEBAR_COLLAPSE_BREAKPOINT}
          width={SIDEBAR_WIDTH}
          collapsedWidth={SIDEBAR_COLLAPSED_WIDTH}
          onCollapse={this.handleSidebarCollapse}
        >
          <AnalyticsSidebar
            sidebarMenuItems={analyticsViews}
            isSidebarCollapsed={isSidebarCollapsed}
            activeAnalyticsView={activeAnalyticsView}
            setActiveAnalyticsView={setActiveAnalyticsView}
          />
        </Sider>
        {/* Analytics Content Area */}
        <Content
          style={{ ...styles.contentContainer, marginLeft: contentMarginLeft }}
        >
          {/* Header */}
          <AnalyticsHeader
            title={titleText}
            platformMenuItems={platformMenuItems}
            dateRangeMenuItems={dateRangeItems}
            {...this.props}
          />
          {/* Plots */}
          <AnalyticsPlotView analyticsViews={analyticsViews} {...this.props} />
        </Content>
      </Layout>
    )
  }
}

// ******
// Styles
// ******
const styles = {
  siderContainer: {
    position: 'fixed',
    overflow: 'auto',
    height: '100vh',
    left: 0,
    backgroundColor: SIDEBAR_BACKGROUND_COLOR,
  },
  contentContainer: {
    overflow: 'auto',
    padding: '24px',
    minHeight: '100vh',
  },
}

// *********
// PropTypes
// *********
Analytics.propTypes = {
  toggleSidebar: PropTypes.func,
  setActiveAnalyticsView: PropTypes.func,
  activeAnalyticsView: PropTypes.string,
  setActiveAnalyticsPlatform: PropTypes.func,
  activeAnalyticsPlatform: PropTypes.string,
  setActiveAnalyticsDuration: PropTypes.func,
  activeAnalyticsDuration: PropTypes.string,
  analyticsCustomDateRange: PropTypes.arrayOf(PropTypes.string),
  setAnalyticsCustomDateRange: PropTypes.func,
  refreshAnalyticsDataForView: PropTypes.func,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Analytics))
