import { createSelector } from 'reselect'
import * as analyticsActions from '../actions/analytics'
import { generateDateRange } from '../../components/analytics/Analytics/helpers'
import {
  dateRangeMenuItems,
  platformMenuItems,
  sidebarMenuItems,
} from '../../components/analytics/Analytics/menuConstants'
import moment from 'moment'
import { get, cloneDeep } from 'lodash'

const initialState = {
  AnalyticsOverviewData: null,
  shareDataByShareId: null,
}

export default function analyticsReducer(state, action) {
  if (typeof state === 'undefined') return initialState
  switch (action.type) {
    case analyticsActions.SET_ANALYTICS_DATA: {
      return {
        ...state,
        ...action.analyticsReport,
      }
    }
    case analyticsActions.SET_ANALYTICS_SHARE_DATA: {
      return {
        ...state,
        shareDataByShareId: {
          ...state.shareDataByShareId,
          ...reduceShareData(action.shareDataList),
        },
      }
    }
    case analyticsActions.SET_ANALYTICS_LOADING_STATUS: {
      return {
        ...state,
        loadingAnalytics: action.loading,
      }
    }

    default:
      return state
  }
}

const reduceShareData = (shareDataList) => {
  if (!Array.isArray(shareDataList)) return null

  let shareDataByShareId = {}
  shareDataList.forEach((shareEvent) => {
    shareDataByShareId[shareEvent.ID] = {
      shareId: shareEvent.ID,
      expires: shareEvent.expires,
      firstView: shareEvent.firstView,
      totalViews: shareEvent.totalViews,
    }
  })

  return shareDataByShareId
}

const getDataOverview = (state) =>
  cloneDeep(state.analytics.AnalyticsOverviewData)
const getDataUserActivity = (state) =>
  cloneDeep(state.analytics.UserActivityData)
const getDataAssetsDetail = (state) =>
  cloneDeep(state.analytics.AssetsDetailData)
const getDataShareDetail = (state) => cloneDeep(state.analytics.ShareDetailData)
const getDataCalcViews = (state) => cloneDeep(state.analytics.CalcViewsData)
const getDataCalculations = (state) =>
  cloneDeep(state.analytics.CalculationsData)
const getAnalyticsCustomRange = (state) =>
  state.appState.analyticsCustomDateRange
const getAnalyticsDuration = (state) => state.appState.activeAnalyticsDuration
export const getLastAnalyticsDuration = (state) =>
  state.appState.lastAnalyticsDuration
export const getReportReceivedDate = (state) =>
  state?.analytics?.reportReceived || undefined
const getDefaultAnalyticsDuration = (state) =>
  get(state, 'settings.modules.analytics.analyticsDefaultTimespan', '30d')
const getAnalyticsPlatform = (state) => state.appState.activeAnalyticsPlatform
const getAnalyticsView = (state) => state.appState.activeAnalyticsView
const getAssetsById = (state) => state.assets.byId
const getDeletedUsers = (state) => state.permissions.deletedUsers
const getUsers = (state) => state.permissions.users
export const getDataForView = (state, view) => state.analytics[`${view}Data`]
export const getDateMenuItems = (state) => {
  const hardStart = moment(getStartDate(state))
  const dateMenuOptions = state.settings.modules.analytics.analyticsTimespans
  return dateRangeMenuItems.filter((item) => {
    if (!dateMenuOptions.includes(item.key)) return false
    if (['1d', 'all', 'custom'].includes(item.key)) return true
    return moment().subtract(item.duration, item.unit).isAfter(hardStart)
  })
}
const getPlatformMenuItems = () => platformMenuItems
const getSidebarMenuItems = () => sidebarMenuItems

export const getActiveAnalyticsDuration = createSelector(
  [getAnalyticsDuration, getDefaultAnalyticsDuration],
  (analyticsDuration, defaultanalyticsDuration) => {
    if (analyticsDuration) {
      return analyticsDuration
    }
    return defaultanalyticsDuration
  }
)

export const getActiveAnalyticsPlatform = createSelector(
  [getAnalyticsPlatform, getPlatformMenuItems],
  (analyticsPlatform, menuItems) => {
    if (analyticsPlatform) {
      return analyticsPlatform
    }

    return menuItems[0].key
  }
)

export const getActiveAnalyticsView = createSelector(
  [getAnalyticsView, getSidebarMenuItems],
  (analyticsView, menuItems) => {
    if (analyticsView) {
      return analyticsView
    }

    return menuItems[0].key
  }
)

export const getAnalyticsCustomDateRange = createSelector(
  [getAnalyticsCustomRange],
  (range) => {
    if (range) {
      return range
    }

    return [null, null]
  }
)
/* 
getAllCalculators
Flattens calculators config array into an object with all calculators 
(key: calculator route name, value: whole calculator object) 
*/
export const getAllCalculators = (state) =>
  state.settings.modules.calculators?.config &&
  state.settings.modules.calculators.config.reduce(
    (calculators, currentSection) => ({
      ...calculators,
      ...currentSection.sectionCalculators.reduce(
        (prev, { routeName, ...rest }) => ({
          ...prev,
          [routeName]: { ...rest, id: routeName },
        }),
        {}
      ),
    }),
    {}
  )

export const getStartDate = (state) =>
  state.settings.modules.analytics.analyticsStartDate || '2021-01-01'

export const getAnalyticsDateRange = createSelector(
  [getActiveAnalyticsDuration, getAnalyticsCustomDateRange, getStartDate],
  (duration, customRange, startDate) => {
    return generateDateRange(duration, customRange, startDate)
  }
)

export const getAllAssets = createSelector([getAssetsById], (assets) => assets)

export const getActiveData = createSelector(
  [
    getActiveAnalyticsView,
    getDataOverview,
    getDataUserActivity,
    getDataAssetsDetail,
    getDataShareDetail,
    getDataCalcViews,
    getDataCalculations,
  ],
  (
    activeView,
    dataOverview,
    dataUserActivity,
    dataAssets,
    dataShares,
    dataCalcs,
    dataCalculations
  ) => {
    switch (activeView) {
      case 'AnalyticsOverview':
        return dataOverview
      case 'UserActivity':
        return dataUserActivity
      case 'AssetsDetail':
        return dataAssets
      case 'ShareDetail':
        return dataShares
      case 'CalcViews':
        return dataCalcs
      case 'Calculations':
        return dataCalculations
      default:
        return {}
    }
  }
)

export const getAllUsers = createSelector(
  [getUsers, getDeletedUsers],
  (users, deletedUsers) => ({
    ...users,
    ...deletedUsers,
  })
)
