import { Button, Spin } from 'antd'
import * as PropTypes from 'prop-types'
import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as modalActions from '../../dux/actions/modal'
import * as settingsActions from '../../dux/actions/settings'
import * as webEndpointConstants from '../../dux/constants/webEndpoints'
import '../styles/Settings.css'

class SettingsImageUpload extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoaded: false,
      validationMessage: '',
      showResetButton: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedEndpointId, onImageChange, getFieldValue } = this.props
    const { validationMessage } = this.state

    // This block was shoved in here so that the error message clears
    // when the image update is cancelled.
    const current404Image = getFieldValue('image404sByEndpointId')
    if (!this.initial404Image) {
      this.initial404Image = current404Image
    }
    if (validationMessage.length && current404Image === this.initial404Image) {
      this.setState({ validationMessage: '' })
    }
    // End block that was shoved in.

    // Re-initialize the state of this component after every change to
    // selectedEndpointId.
    if (selectedEndpointId !== prevProps.selectedEndpointId) {
      this.setState({ validationMessage: '' })
      // This is here to reset the overideImage to null. The overideImage is
      // diplayed in place of the current image404sByEndpointId[endpointId] or default404Image
      onImageChange(null)
    }
  }

  onUpload = (succeeded, failed, files) => {
    const { fileCategory, modalActions, onImageChange, settingsActions } =
      this.props

    this.showSpinner(true)

    if (succeeded.length > 0) {
      // The returned object seems to be oddly shaped.
      const file = files.filter((f) => f.id === succeeded[0])[0]

      settingsActions.validateFile(file.url, fileCategory, (err, msg) => {
        // Set this image file key as the overideImage so it can take
        // precedent over the current image404sByEndpointId[endpointId]
        onImageChange && onImageChange(file.key)

        // Small red text at bottom of form
        this.setState({ validationMessage: err || msg })
      })
      modalActions.closeModal()
    }
  }

  onUploadClick = () => {
    this.props.modalActions.openModal('UploadModal', {
      type: 'support',
      imageOnly: true,
      multiple: false,
      callback: this.onUpload,
      target: this.props.uploadDestination,
    })
  }

  onResetClick = () => {
    const { onImageChange } = this.props
    onImageChange &&
      onImageChange(`defaults/${webEndpointConstants.DEFAULT_404_IMAGE}`)
  }

  renderImageSource = () => {
    const {
      selectedImage,
      thumbnailProps,
      appConfig: { baseUrl },
    } = this.props

    const thumbnailSuffix = `_thumb-${thumbnailProps.height}x${thumbnailProps.width}`

    return `${baseUrl}/${selectedImage}${thumbnailSuffix}`
  }

  showSpinner = (status) => {
    this.setState({ showSpinner: status })
  }

  handleOnHover = () => {
    this.setState({ showResetButton: true })
  }

  handleOnHoverOut = () => {
    this.setState({ showResetButton: false })
  }

  render() {
    const { title, subtitle, description, thumbnailProps } = this.props
    const { showSpinner, showResetButton, validationMessage } = this.state

    return (
      <div className="settings__form-container">
        {title && <div className="builders__form-label">{title}</div>}
        {subtitle && <div className="builders__padded-div">{subtitle}</div>}
        {description && (
          <div className="builders__padded-div">{description}</div>
        )}

        <div>
          <img
            src={this.renderImageSource()}
            className={'settings__uploaded-image'}
            onLoad={() => this.showSpinner(false)}
            onError={() => this.showSpinner(false)}
            alt={thumbnailProps.alt || 'uploadedImage'}
            onMouseEnter={this.handleOnHover}
            onMouseLeave={this.handleOnHoverOut}
          />
          <div
            className={'settings__upload-image-overlay'}
            style={{
              display: showSpinner ? 'block' : 'none',
              position: 'relative',
            }}
          >
            <Spin />
          </div>
          <div
            className={'settings__upload-reset-image-overlay'}
            style={{
              display: showResetButton ? 'block' : 'none',
              position: 'relative',
            }}
            onMouseEnter={this.handleOnHover}
          >
            <Button className="settings__button" onClick={this.onResetClick}>
              <span>
                <i className="fa fa-trash" />
              </span>
            </Button>
          </div>
        </div>

        <div className="builders__padded-div settings__action-remove">
          {validationMessage}
        </div>

        <Button className="settings__button" onClick={this.onUploadClick}>
          <span>
            <i className="fa fa-plus-circle" /> Image
          </span>
        </Button>
      </div>
    )
  }
}

SettingsImageUpload.propTypes = {
  appConfig: PropTypes.object,
  description: PropTypes.string,
  fileCategory: PropTypes.oneOf([
    'brandProfile',
    'logo',
    'splashScreen',
    'webendpoint404',
  ]).isRequired,
  modalActions: PropTypes.shape({
    openModal: PropTypes.func.isRequired,
  }),
  onChange: PropTypes.func,
  selectedImage: PropTypes.string,
  settingsActions: PropTypes.shape({
    validateFile: PropTypes.func.isRequired,
  }),
  subtitle: PropTypes.string,
  thumbnailProps: PropTypes.shape({
    alt: PropTypes.string,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  title: PropTypes.string,
  uploadDestination: PropTypes.string.isRequired,
  onImageChange: PropTypes.func,
  selectedEndpointId: PropTypes.string,
  getFieldValue: PropTypes.func,
}

const mapStateToProps = (state) => ({
  appConfig: state.config.appConfig,
})

const mapDispatchToProps = (dispatch) => ({
  modalActions: bindActionCreators(modalActions, dispatch),
  settingsActions: bindActionCreators(settingsActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsImageUpload)
