import { Card, Checkbox, Form, Input, Modal } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

const FormItem = Form.Item

class EditInvitationModal extends React.Component {
  state = {
    searchFilter: '',
  }

  onSearchInput = (val) => this.setState(() => ({ searchFilter: val }))

  saveFormRef = (ref) => (this.form = ref)

  onSave = () => {
    this.form.validateFields((err, values) => {
      if (!err) {
        const invite = {
          ...this.props.modalProps.record,
          valid: values.valid,
        }

        this.props.invitationActions.editInvitation(invite)
      }
    })
  }
  render() {
    return (
      <Modal
        title="Edit Invitation"
        okText="Save"
        onOk={this.onSave}
        cancelText="Cancel"
        onCancel={this.props.modalActions.closeModal}
        visible={this.props.modal.modalOpen}
        confirmLoading={this.props.appState.asyncPending}
        maskClosable={false}
      >
        <EditInvitationForm
          ref={this.saveFormRef}
          record={this.props.modalProps.record}
          users={this.props.permissions.users}
          onSearchInput={this.onSearchInput}
          searchFilter={this.state.searchFilter}
        />
      </Modal>
    )
  }
}

const EditInvitationForm = Form.create()((props) => {
  const { form } = props
  const { getFieldDecorator } = form
  const acceptedUsers = Object.keys(props.users).filter(
    (user) =>
      props.users[user].invitation === props.record.id &&
      props.users[user].email.includes(props.searchFilter)
  )

  return (
    <Form>
      <div className="modal__flex-start">
        <FormItem
          className="modal__form-item-flexgrow"
          label="Invitation Code"
          require={true}
        >
          {getFieldDecorator('id', {
            initialValue: props.record.id,
          })(<Input disabled />)}
        </FormItem>
        <FormItem label="Valid">
          {getFieldDecorator('valid', {
            valuePropName: 'checked',
            initialValue: props.record.valid,
          })(<Checkbox />)}
        </FormItem>
      </div>
      <div>
        <Card
          title="Accepted Users"
          extra={
            <Input.Search
              placeholder="email..."
              onChange={(e) => props.onSearchInput(e.target.value)}
              style={{ width: 200 }}
            />
          }
        >
          <div className="modal__scrollable-list">
            {acceptedUsers.map((user) => (
              <div key={props.users[user].id}>{props.users[user].email}</div>
            ))}
          </div>
        </Card>
      </div>
    </Form>
  )
})
/*
 *  Props are from the redux store
 *  "actions" are objects created by bindActionCreators, containing all relevant action functions
 *  modalProps is object passed from modal creator
 */
EditInvitationModal.propTypes = {
  modal: PropTypes.object,
  modalActions: PropTypes.object,
  modalProps: PropTypes.object,
  appState: PropTypes.shape({
    asyncPending: PropTypes.bool,
  }),
  onSubmit: PropTypes.func,
}

EditInvitationModal.defaultProps = {
  modalProps: {},
}

export default EditInvitationModal
