import { Button, Input } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

const Signout = (props) => {
  const { signoutEmail, signoutMessage, onSignOut, changeHandler } = props
  return (
    <div>
      <div>
        <div style={styles.title}>
          User to sign out (email):{' '}
          <Input
            value={signoutEmail}
            id="signoutEmail"
            onChange={changeHandler}
          />
        </div>
      </div>
      <div style={styles.message}>{signoutMessage}</div>
      <Button onClick={onSignOut} style={styles.button}>
        Sign out user
      </Button>
    </div>
  )
}

const styles = {
  title: {
    color: 'rgba(0,0,0,.8)',
    margin: 10,
    width: 200,
  },
  message: {
    color: 'rgba(0,0,0,.8)',
    margin: 10,
  },
  button: {
    margin: '0 10px',
  },
}

Signout.propTypes = {
  signoutEmail: PropTypes.string,
  signoutMessage: PropTypes.string,
  onSignOut: PropTypes.func,
  changeHandler: PropTypes.func,
}

export default Signout
