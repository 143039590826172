import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as endpointActions from '../../dux/actions/endpoint'
import * as actions from '../../dux/actions/settings'
import * as webEndpointActions from '../../dux/actions/webEndpoints'
import { requiredFieldsArePopulated } from '../../dux/modules/utils'
import SettingsForm from './SettingsForm'
import {
  convertFormValuesToSettings,
  dropUndefinedProps,
  getExtraFormButtons,
  getFormDescription,
  getFormItems,
  getFormTitle,
  performSaveAction,
  updateFieldsValue,
} from './SettingsUtils'

class SettingsContainer extends React.Component {
  constructor() {
    super()

    this.confirmRefs = {}
    this.state = {
      message: '',
    }
  }

  saveFormRef = (ref) => (this.form = ref && ref.props && ref.props.form)

  setConfirmRef = (key, ref) => {
    this.confirmRefs = {
      ...this.confirmRefs,
      [key]: ref,
    }
  }

  onFieldsChange = () => {
    if (this.state.message) {
      this.setState({ message: '', messageType: '' })
    }
  }

  componentDidUpdate = (prevProps) => {
    const { setting, settings } = this.props

    if (setting !== prevProps.setting) this.onFieldsChange()

    if (!isEqual(settings[setting], prevProps.settings[prevProps.setting])) {
      updateFieldsValue(this, setting, settings)
    }
  }

  handleSave = (value) => {
    const { setting, settings } = this.props
    value = dropUndefinedProps(value)

    if (!isEqual(value, settings[setting])) {
      performSaveAction(setting, value, this)
      this.setState({
        message: `${getFormTitle(setting)} saved`,
        messageType: 'SUCCESS',
      })
    } else {
      this.setState({
        message: `No changes to ${getFormTitle(setting)} settings.`,
        messageType: 'SUCCESS',
      })
    }
  }

  onSave = () => {
    if (Object.keys(this.confirmRefs)) {
      Promise.all(
        Object.values(this.confirmRefs).map(
          (cr) => new Promise((resolve) => cr.handleInputConfirm(null, resolve))
        )
      ).then(() => {
        const refValues = Object.entries(this.confirmRefs).reduce(
          (acc, [key, ref]) => ({ ...acc, [key]: ref.getTags() }),
          {}
        )

        this.form.validateFields((err, values) => {
          if (
            !err &&
            requiredFieldsArePopulated(
              this.getRequiredFields(),
              values,
              this.form
            )
          ) {
            const value = convertFormValuesToSettings(
              this.props.setting,
              values,
              this,
              refValues
            )
            this.handleSave(value)
          }
        })
      })
    } else {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (
            requiredFieldsArePopulated(
              this.getRequiredFields(),
              values,
              this.form
            )
          ) {
            const value = convertFormValuesToSettings(
              this.props.setting,
              values,
              this
            )
            this.handleSave(value)
          }
        }
      })
    }
  }

  onCancel = () => {
    this.form.resetFields()
    this.setState({
      message: `${getFormTitle(this.props.setting)} update canceled`,
      messageType: 'ERROR',
    })
  }

  getRequiredFields = () => {
    const { setting, settings } = this.props
    const items = getFormItems(setting, settings, this)

    return items.reduce((result, item) => {
      const requiredFields = item.fields.filter((field) => field.required)
      requiredFields.forEach((field) => result.push(field.id))

      return result
    }, [])
  }

  render() {
    const { setting, settings } = this.props

    return (
      <SettingsForm
        extraFormButtons={getExtraFormButtons(this, setting, settings)}
        formDescription={getFormDescription(setting)}
        formItems={getFormItems(setting, settings, this)}
        formTitle={getFormTitle(setting)}
        message={this.state.message}
        messageType={this.state.messageType}
        onCancel={this.onCancel}
        onFieldsChange={this.onFieldsChange}
        onSave={this.onSave}
        wrappedComponentRef={this.saveFormRef}
      />
    )
  }
}

SettingsContainer.propTypes = {
  setting: PropTypes.string.isRequired,
  settings: PropTypes.object,
}

const mapStateToProps = (state) => ({
  assets: state.assets,
  appConfig: state.config.appConfig,
  endpoints: state.endpoints.endpoints,
  folders: state.folders,
  settings: state.settings,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  endpointActions: bindActionCreators(endpointActions, dispatch),
  webEndpointActions: bindActionCreators(webEndpointActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer)
