import { Button } from 'antd'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import '../styles/BAM_UI.css'

const TwoButtonContainer = (props) => (
  <Fragment>
    <Button
      onClick={props.primaryButtonAction}
      type="primary"
      className={props.primaryButtonClass}
      disabled={props.primaryButtonDisabled}
    >
      {props.primaryButtonText}
    </Button>
    <Button
      onClick={props.secondaryButtonAction}
      className={props.secondaryButtonClass}
    >
      {props.secondaryButtonText}
    </Button>
  </Fragment>
)

TwoButtonContainer.propTypes = {
  primaryButtonText: PropTypes.string.isRequired,
  secondaryButtonText: PropTypes.string.isRequired,
  primaryButtonAction: PropTypes.func.isRequired,
  secondaryButtonAction: PropTypes.func.isRequired,
  primaryButtonClass: PropTypes.string,
  secondaryButtonClass: PropTypes.string,
  primaryButtonDisabled: PropTypes.bool,
}

TwoButtonContainer.defaultProps = {
  primaryButtonClass: 'BAM-UI__primary-button',
  secondaryButtonClass: 'BAM-UI__secondary-button',
  primaryButtonDisabled: false,
}

export default TwoButtonContainer
