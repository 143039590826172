import { Button, Table } from 'antd'
import React from 'react'
import '../styles/Permissions.css'

const InvitationList = (props) => {
  return (
    <div>
      <Table
        className="permissions__table"
        dataSource={props.dataSource}
        columns={props.columns}
        scroll={{ x: 965, y: props.height - 250 }}
        rowKey={(record) => record.id}
        bordered={true}
        size={props.tablesize}
      />
      <Button
        onClick={props.refresh}
        icon="reload"
        disabled={!props.isAdmin}
        style={{ margin: '0 10px' }}
      >
        Refresh
      </Button>
      <Button
        onClick={props.add}
        icon="idcard"
        disabled={!props.isAdmin}
        style={{ margin: '0 10px' }}
      >
        Add
      </Button>
    </div>
  )
}

export default InvitationList
