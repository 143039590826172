import { Button, Checkbox, Icon, Input, Select, Tooltip } from 'antd'
import React from 'react'
import * as assetConstants from '../../dux/constants/assets'
import * as builderConstants from '../../dux/constants/builders'
import * as endpointConstants from '../../dux/constants/endpoints'
import * as sfConstants from '../../dux/constants/salesforce'
import * as constants from '../../dux/constants/settings'
import * as webEndpointConstants from '../../dux/constants/webEndpoints'
import { containsPublishedAsset } from '../../dux/modules/utils'
import EditableTagGroup from '../common/EditableTagGroup'
import SettingsImageUpload from './SettingsImageUpload'
import SizeInput from './SizeInput'

export const getFormControl = (options) => {
  switch (options.controlType) {
    case 'checkbox':
      return (
        <Checkbox
          key={options.controlId}
          {...options.controlProps}
          disabled={options.controlDisabled(options.controlId)}
        >
          {options.controlChildren}
        </Checkbox>
      )

    case 'input':
      return (
        <Input
          key={options.controlId}
          {...options.controlProps}
          disabled={options.controlDisabled(options.controlId)}
        />
      )

    case 'textarea':
      return (
        <Input.TextArea
          key={options.controlId}
          {...options.controlProps}
          disabled={options.controlDisabled(options.controlId)}
        />
      )

    case 'cbGroup':
      return (
        <Checkbox.Group key={options.controlId} {...options.controlProps} />
      )

    case 'imageUpload':
      return (
        <SettingsImageUpload
          key={options.controlId}
          {...options.controlProps}
        />
      )

    case 'select':
      return (
        <Select
          key={options.controlId}
          {...options.controlProps}
          disabled={options.controlDisabled(options.controlId)}
        >
          {options.selectOptions.map((opt, i) => (
            <Select.Option key={i} value={opt.value}>
              {opt.label}
            </Select.Option>
          ))}
        </Select>
      )

    case 'size':
      return <SizeInput key={options.controlId} {...options.controlProps} />

    default:
      return null
  }
}

function uploadsFormItems(settings, formId, _this) {
  const formItems = [
    {
      id: '_enabled',
      label: 'Enable User Generated Content',
      description:
        'When UGC is enabled, members of the System: UGC Creators group will be able to add ' +
        'assets via the Mobile Sales Tool. Visit the Groups page to manage the members of ' +
        'System: UGC Creators',
      extra: undefined,
      fields: [
        {
          id: 'enabled',
          initialValue: settings[formId].enabled,
          valuePropName: 'checked',
          validationRules: [],
          control: {
            controlId: 'enabled',
            controlChildren: <span>Enabled</span>,
            controlType: 'checkbox',
            controlDisabled: () => false,
            controlProps: {},
          },
        },
      ],
    },
    {
      id: '_maxSize',
      label: 'Maximum Upload Size',
      description: null,
      extra: 'Maximum upload size is 5GB',
      fields: [
        {
          id: 'maxSize',
          initialValue: getMaxSizeFromBytes(settings[formId].maxSize),
          validationRules: [
            {
              validator: (rule, value, callback) =>
                maxSizeValidator(rule, value, callback, _this),
            },
          ],
          control: {
            controlId: 'maxSize',
            controlChildren: null,
            controlType: 'size',
            controlDisabled: () => false,
            controlProps: {},
          },
        },
      ],
    },
    {
      id: '_title',
      label: 'Title',
      description: null,
      extra: undefined,
      sectionTitle: 'Metadata',
      sectionDescription:
        'Metadata for user uploaded assets can be required or optional.  You may also enter ' +
        'default values for these fields.  Users may override the defaults.',
      fields: [
        {
          id: 'titleRequired',
          initialValue: settings[formId].metadata.title.required,
          valuePropName: 'checked',
          validationRules: [],
          control: {
            controlId: 'titleRequired',
            controlChildren: <span>Required</span>,
            controlType: 'checkbox',
            controlDisabled: () => false,
            controlProps: {
              className: 'builders__form-checkbox',
            },
          },
        },
        {
          id: 'title',
          initialValue: settings[formId].metadata.title.defaultValue,
          validationRules: [
            {
              max: assetConstants.ASSET_TITLE_MAX_LENGTH,
              message:
                `Asset titles are limited to ${assetConstants.ASSET_TITLE_MAX_LENGTH} ` +
                'characters',
            },
          ],
          control: {
            controlId: 'title',
            controlType: 'input',
            controlDisabled: () => false,
            controlProps: {
              placeholder: 'Default Value',
            },
          },
        },
      ],
    },
    {
      id: '_description',
      label: 'Description',
      description: null,
      extra: undefined,
      fields: [
        {
          id: 'descriptionRequired',
          initialValue: settings[formId].metadata.description.required,
          valuePropName: 'checked',
          validationRules: [],
          control: {
            controlId: 'descriptionRequired',
            controlChildren: <span>Required</span>,
            controlType: 'checkbox',
            controlDisabled: () => false,
            controlProps: {
              className: 'builders__form-checkbox',
            },
          },
        },
        {
          id: 'description',
          initialValue: settings[formId].metadata.description.defaultValue,
          validationRules: [
            {
              max: assetConstants.ASSET_DESCRIPTION_MAX_LENGTH,
              message:
                `Asset descriptions are limited to ` +
                `${assetConstants.ASSET_DESCRIPTION_MAX_LENGTH} characters`,
            },
          ],
          control: {
            controlId: 'description',
            controlType: 'input',
            controlDisabled: () => false,
            controlProps: {
              placeholder: 'Default Value',
            },
          },
        },
      ],
    },
    {
      id: '_tags',
      label: 'Tags',
      description: null,
      extra: undefined,
      fields: [
        {
          id: 'tagsRequired',
          initialValue: settings[formId].metadata.tags.required,
          valuePropName: 'checked',
          validationRules: [],
          control: {
            controlId: 'tagsRequired',
            controlChildren: <span>Required</span>,
            controlType: 'checkbox',
            controlDisabled: () => false,
            controlProps: {
              className: 'builders__form-checkbox',
            },
          },
        },
      ],
      supplementalControl: (
        <EditableTagGroup
          defaultTags={settings[formId].metadata.tags.defaultValue}
          ref={(el) => _this.setConfirmRef('tags', el)}
        />
      ),
    },
    {
      id: '_schedule',
      label: 'Email Summary Notification',
      description:
        'BAM! can notify your Content Admins when User Generated Content is waiting for review. ' +
        'The system will send a summary email in the morning on the days that are selected.',
      extra: undefined,
      fields: [
        {
          id: 'schedule',
          initialValue: settings[formId].schedule,
          valuePropName: 'value',
          validationRules: [],
          control: {
            controlId: 'schedule',
            controlChildren: <span>Required</span>,
            controlType: 'cbGroup',
            controlDisabled: () => false,
            controlProps: {
              className: 'builders__form-checkbox-override',
              options: [
                { label: 'Mon', value: 1 },
                { label: 'Tue', value: 2 },
                { label: 'Wed', value: 3 },
                { label: 'Thu', value: 4 },
                { label: 'Fri', value: 5 },
                { label: 'Sat', value: 6 },
                { label: 'Sun', value: 0 },
              ],
            },
          },
        },
      ],
    },
  ]

  if (_this.props.appConfig.uploadGroups) {
    formItems.push({
      id: '_groups',
      label: 'Upload Groups',
      description:
        'Use descriptive metadata to create virtual folder groups. Uploaded content will be ' +
        'grouped when the first line of the description matches one of these values.',
      fields: [],
      supplementalControl: (
        <EditableTagGroup
          confirmWithSpace={false}
          defaultTags={settings[formId].groups}
          ref={(el) => _this.setConfirmRef('groups', el)}
          inputProps={{
            suffix: (
              <Tooltip title="Fill in group keywords, then press Enter or Tab.">
                <Icon type="info-circle" />
              </Tooltip>
            ),
          }}
        />
      ),
    })
  }

  return formItems
}

export const getFormItems = (formId, settings, _this) => {
  switch (formId) {
    case 'profiles':
      return [
        {
          id: '_enabled',
          label: 'Enabled',
          description: null,
          extra: undefined,
          fields: [
            {
              id: 'enabled',
              initialValue: settings[formId].enabled,
              valuePropName: 'checked',
              validationRules: [],
              control: {
                controlId: 'enabled',
                controlChildren: <span>Customer Profiles Enabled</span>,
                controlType: 'checkbox',
                controlDisabled: (id) => inputIsDisabled(id, settings, _this),
                controlProps: {},
              },
            },
          ],
        },
        {
          id: '_defaultSubject',
          label: 'Default Email Subject',
          description: null,
          fields: [
            {
              id: 'defaultSubject',
              initialValue: settings[formId].defaultSubject,
              validationRules: [
                {
                  max: constants.CP_MAX_SUBJECT_CHARS,
                  message: `Subject is limited to ${constants.CP_MAX_SUBJECT_CHARS} characters`,
                },
              ],
              control: {
                controlId: 'defaultSubject',
                controlChildren: null,
                controlType: 'input',
                controlDisabled: (id) => inputIsDisabled(id, settings, _this),
                controlProps: {
                  placeholder: constants.CP_DEFAULT_SUBJECT,
                },
              },
            },
          ],
        },
        {
          id: '_defaultMessage',
          label: 'Default Email Message',
          description: null,
          fields: [
            {
              id: 'defaultMessage',
              initialValue: settings[formId].defaultMessage,
              validationRules: [
                {
                  max: constants.CP_DEFAULT_MESSAGE,
                  message: `Subject is limited to ${constants.CP_DEFAULT_MESSAGE} characters`,
                },
              ],
              control: {
                controlId: 'defaultMessage',
                controlChildren: null,
                controlType: 'textarea',
                controlDisabled: (id) => inputIsDisabled(id, settings, _this),
                controlProps: {
                  placeholder: constants.CP_DEFAULT_MESSAGE,
                  autosize: { minRows: 3, maxRows: 8 },
                },
              },
            },
          ],
        },
      ]
    case 'builders':
      return [
        {
          id: '_allowCustomDisclaimer',
          label: 'Allow Custom Disclaimers',
          description:
            'If this is checked, users can choose to enter custom disclaimer language when ' +
            'creating a comparison.',
          fields: [
            {
              id: 'allowCustomDisclaimer',
              initialValue: settings[formId].comparisons.allowCustomDisclaimer,
              valuePropName: 'checked',
              control: {
                controlId: 'allowCustomDisclaimer',
                controlChildren: <span>Allow Custom Disclaimer</span>,
                controlType: 'checkbox',
                controlDisabled: () => false,
                controlProps: {},
              },
            },
          ],
        },
        {
          id: '_globalDisclaimer',
          label: 'Comparison Disclaimer',
          description: null,
          fields: [
            {
              id: 'globalDisclaimer',
              initialValue: settings[formId].comparisons.globalDisclaimer,
              validationRules: [
                {
                  max: builderConstants.COMPARISON__MAX_DISCLAIMER_LENGTH,
                  message:
                    `Disclaimer is limited to ` +
                    `${builderConstants.COMPARISON__MAX_DISCLAIMER_LENGTH} characters`,
                },
              ],
              control: {
                controlId: 'globalDisclaimer',
                controlChildren: null,
                controlType: 'textarea',
                controlDisabled: () => false,
                controlProps: {
                  placeholder: 'Comparison Disclaimer',
                  autosize: { minRows: 3, maxRows: 8 },
                },
              },
            },
          ],
        },
      ]
    case 'uploads':
      return uploadsFormItems(settings, formId, _this)

    case 'integrations':
      return [
        {
          id: '_url',
          label: `Salesforce URL (example: https://${_this.props.appConfig.stage}.salesforce.com)`,
          description: null,
          extra: undefined,
          fields: [
            {
              id: 'url',
              initialValue: settings[formId].salesforce.url,
              valuePropName: 'value',
              validationRules: [
                {
                  required: true,
                  type: 'url',
                  message:
                    'Please enter a valid URL, including protocol (http:// or https://)',
                },
              ],
              control: {
                controlId: 'url',
                controlChildren: null,
                controlType: 'input',
                controlDisabled: () => !settings[formId].salesforce.enabled,
                controlProps: {
                  placeholder: 'SalesForce URL',
                },
              },
            },
          ],
        },
        {
          id: '_client',
          label: 'Client ID',
          description: null,
          extra: undefined,
          fields: [
            {
              id: 'client',
              initialValue: settings[formId].salesforce.client,
              valuePropName: 'value',
              validationRules: [
                {
                  required: true,
                  message: 'Client ID is required.',
                },
              ],
              control: {
                controlId: 'client',
                controlChildren: null,
                controlType: 'input',
                controlDisabled: () => !settings[formId].salesforce.enabled,
                controlProps: {
                  placeholder: 'SalesForce client ID',
                },
              },
            },
          ],
        },
        {
          id: '_subject',
          label: 'Default Email Subject',
          description: null,
          extra: undefined,
          fields: [
            {
              id: 'defaultSubject',
              initialValue: settings[formId].salesforce.defaultSubject,
              valuePropName: 'value',
              validationRules: [
                {
                  max: sfConstants.SF_MAX_SUBJECT_CHARS,
                  message: `Subject is limited to ${sfConstants.SF_MAX_SUBJECT_CHARS} characters`,
                },
              ],
              control: {
                controlId: 'defaultSubject',
                controlChildren: null,
                controlType: 'input',
                controlDisabled: () => !settings[formId].salesforce.enabled,
                controlProps: {
                  placeholder: sfConstants.SF_DEFAULT_SUBJECT,
                },
              },
            },
          ],
        },
        {
          id: '_message',
          label: 'Default Email Message Content',
          description: null,
          extra: undefined,
          fields: [
            {
              id: 'defaultMessage',
              initialValue: settings[formId].salesforce.defaultMessage,
              valuePropName: 'value',
              validationRules: [
                {
                  max: sfConstants.SF_MAX_MESSAGE_CHARS,
                  message: `Message is limited to ${sfConstants.SF_MAX_MESSAGE_CHARS} characters`,
                },
              ],
              control: {
                controlId: 'defaultMessage',
                controlChildren: null,
                controlType: 'textarea',
                controlDisabled: () => !settings[formId].salesforce.enabled,
                controlProps: {
                  placeholder: sfConstants.SF_DEFAULT_MESSAGE,
                  autosize: { minRows: 3, maxRows: 8 },
                },
              },
            },
          ],
        },
      ]

    case 'general':
      return [
        {
          id: '_sharingMin',
          sectionTitle: 'Shared Asset Expiration',
          sectionDescription:
            `Users can choose how many days a shared asset remains ` +
            `available on the viewer site, between ${constants.MIN_SHARE_DAYS} and ` +
            `${constants.MAX_SHARE_DAYS}.  If the minimum and maximum are the same, ` +
            `the asset will be shared for that number of days.`,
          label: 'Minimum Share (days)',
          extra: undefined,
          fields: [
            {
              id: 'Minimum Share',
              required: true,
              initialValue: settings[formId].minimumShare,
              valuePropName: 'value',
              validationRules: [
                {
                  validator: (rule, value, callback) =>
                    minShareValidator(rule, value, callback, _this),
                },
              ],
              control: {
                controlId: 'minimumShare',
                controlChildren: null,
                controlType: 'input',
                controlDisabled: () => false,
                controlProps: {
                  style: { maxWidth: 100 },
                },
              },
            },
          ],
        },
        {
          id: '_sharingMax',
          label: 'Maximum Share (days)',
          extra: undefined,
          fields: [
            {
              id: 'Maximum Share',
              required: true,
              initialValue: settings[formId].maximumShare,
              valuePropName: 'value',
              validationRules: [
                {
                  validator: (rule, value, callback) =>
                    maxShareValidator(rule, value, callback, _this),
                },
              ],
              control: {
                controlId: 'maximumShare',
                controlChildren: null,
                controlType: 'input',
                controlDisabled: () => false,
                controlProps: {
                  style: { maxWidth: 100 },
                },
              },
            },
          ],
        },
      ]

    case constants.SETTINGS_KEY_WEB_ENDPOINTS:
      return [
        {
          id: '_selection',
          label: 'Endpoint Selection',
          fields: [
            {
              id: 'selectedEndpointId',
              initialValue: settings[formId].selectedEndpointId,
              control: {
                controlId: 'selectedEndpoint',
                controlType: 'select',
                controlDisabled: () => false,
                controlProps: {
                  placeholder: 'Create New Endpoint',
                  onChange: (value) => {
                    _this.form.resetFields()
                    _this.props.webEndpointActions.setSelectedWebEndpointId(
                      value
                    )
                  },
                },
                selectOptions: getWebEndpointSelectOptions(_this),
              },
            },
          ],
        },
        {
          id: '_title',
          label: 'Endpoint Title',
          description:
            'An endpoint is for grouping all of your sites and projects that support a single ' +
            'concept. There can be an endpoint for every section of your marketing effort. ' +
            'Support your online presence with a Social Media endpoint. Add assets to your ' +
            'informational sites with a Product Websites endpoint. All folders and assets that ' +
            'live within the same endpoint will share the same settings. Name your endpoint ' +
            'something that will help you stay organized. This name will not affect your live ' +
            'sites and is for display and structure only.',
          fields: [
            {
              id: 'Endpoint Title',
              required: true,
              initialValue: settings[formId].endpointTitle,
              validationRules: [
                {
                  validator: (rule, value, callback) =>
                    maxNumCharsValidator(
                      rule,
                      value,
                      callback,
                      'Endpoint Title',
                      constants.MAX_NUM_CHARS_ENDPOINT_TITLE
                    ),
                },
                {
                  validator: (rule, value, callback) =>
                    requiredValidator(rule, value, callback, 'Endpoint Title'),
                },
              ],
              control: {
                controlId: 'endpointTitle',
                controlType: 'input',
                controlDisabled: () => false,
              },
            },
          ],
        },
        {
          id: '_folders',
          label: 'Root Folder',
          description:
            'Your root folder is a more specific way to structure your content and assets. ' +
            'Your Social Media endpoint, for example, can contain a root folder called Facebook ' +
            'or Instagram. This folder and the assets it contains will share the settings that ' +
            "you've configured in the endpoint. These folders will not affect your live sites " +
            'and are only used for organization.',
          fields: [
            {
              id: 'Root Folder',
              required: true,
              initialValue: settings[formId].rootFolder,
              validationRules: [
                {
                  validator: (rule, value, callback) =>
                    requiredValidator(rule, value, callback, 'Root Folder'),
                },
              ],
              control: {
                controlId: 'rootFolder',
                controlType: 'input',
                controlDisabled: () => false,
              },
            },
          ],
        },
        {
          id: '_notFoundImage',
          label: '404 Image',
          description:
            'Your 404 image will display on your sites if the asset on your site is no longer ' +
            'published. Customize your 404 image here with a JPG at 1080x1080 pixels, leaving ' +
            'ample room around the edges. Ideal size is 200kb or less.',
          fields: [
            {
              id: 'image404sByEndpointId',
              valuePropName: 'selectedImage',
              initialValue: getWebEndpoint404Image(
                settings[formId].selectedEndpointId,
                settings[formId].image404sByEndpointId
              ),
              control: {
                controlId: 'fourOhFourImage',
                controlType: 'imageUpload',
                controlProps: {
                  fileCategory: 'webendpoint404',
                  thumbnailProps: {
                    alt: '404',
                    height: 300,
                    width: 300,
                  },
                  getFieldValue: (key) => _this.form.getFieldValue(key),
                  uploadDestination: 'resources',
                  selectedEndpointId: settings[formId].selectedEndpointId,
                  onImageChange: (updatedImage) =>
                    _this.form.setFieldsValue({
                      image404sByEndpointId: getWebEndpoint404Image(
                        settings[formId].selectedEndpointId,
                        settings[formId].image404sByEndpointId,
                        updatedImage
                      ),
                    }),
                },
              },
            },
          ],
        },
      ]

    default:
      return []
  }
}

export const getExtraFormButtons = (_this, formId, settings) => {
  if (formId === constants.SETTINGS_KEY_WEB_ENDPOINTS) {
    const endpoint = _this.props.endpoints.find(
      (e) => e.id === settings[formId].selectedEndpointId
    )
    const { byId: allFolders } = _this.props.folders
    const { byId: allAssets, relationships } = _this.props.assets

    // If the endpoint contains published assets or
    // if the endpoint id is the default (new endpoint)
    // then we will not allow the user to delete it.
    const containsPublished =
      endpoint &&
      endpoint.rootFolders.some((folder) =>
        containsPublishedAsset(
          folder,
          allFolders,
          allAssets,
          relationships.assets
        )
      )
    const isNew =
      !settings[formId].selectedEndpointId ||
      settings[formId].selectedEndpointId === webEndpointConstants.DEFAULT_ID

    const isDisabled = containsPublished || isNew

    // This is only displayed when the delete button is disabled.
    const disabledTooltipText = isNew
      ? 'No endpoint selected'
      : containsPublished
      ? 'There are published assets in this endpoint'
      : 'Not allowed'

    const buttonComponent = (
      <Button
        disabled={isDisabled}
        onClick={() =>
          _this.props.endpointActions.deleteEndpoint(
            settings[formId].selectedEndpointId
          )
        }
        type="danger"
      >
        Delete
      </Button>
    )

    // We only show a tooltip when the button is disabled.
    return isDisabled ? (
      <Tooltip title={disabledTooltipText}>{buttonComponent}</Tooltip>
    ) : (
      buttonComponent
    )
  }
}

export const getWebEndpoint404Image = (
  endpointId,
  image404sByEndpointId,
  overrideImage
) => {
  const defaultImage = `defaults/${webEndpointConstants.DEFAULT_404_IMAGE}`

  // NOTE ABOUT IDENTICAL WEB ENDPOINT 404 IMAGES
  const uploadedImage = Object.values(image404sByEndpointId)[0]
  // END NOTE ABOUT IDENTICAL WEB ENDPOINT 404 IMAGES

  return overrideImage || uploadedImage || defaultImage
}

export const updateFieldsValue = (_this, formId, settings) => {
  const setting = settings[formId]

  switch (formId) {
    case constants.SETTINGS_KEY_WEB_ENDPOINTS:
      _this.form.setFieldsValue({
        endpointTitle: setting.endpointTitle,
        rootFolder: setting.rootFolder,
        selectedEndpointId: setting.selectedEndpointId,
      })

      // only update the 404 image if user has provided one
      if (setting.image404sByEndpointId[setting.selectedEndpointId]) {
        _this.form.setFieldsValue({
          imageUpload404: getWebEndpoint404Image(
            setting.selectedEndpointId,
            setting.image404sByEndpointId
          ),
        })
      }

      break

    default:
      break
  }
}

export const getFormTitle = (formId) => {
  switch (formId) {
    case 'profiles':
      return 'Customer Profile Settings'

    case 'builders':
      return 'Builder Settings'

    case 'uploads':
      return 'User Generated Content Configuration'

    case 'integrations':
      return 'Salesforce Configuration'

    case 'general':
      return 'DAM Settings'

    case constants.SETTINGS_KEY_WEB_ENDPOINTS:
      return 'Web Endpoint Settings'

    default:
      return 'Settings'
  }
}

export const getFormDescription = (formId) => {
  switch (formId) {
    case 'profiles':
      return (
        'Customer Profiles allow your users to save contact data and easily share assets with ' +
        'customers. If a Salesforce integration is enabled, you must disable it before using ' +
        'Customer Profiles. You can also customize the default values for email subject and ' +
        'messages that are sent to customers.'
      )

    case 'builders':
      return (
        "Configure your organization's Comparison disclaimer text. The disclaimer can contain " +
        `up to ${builderConstants.COMPARISON__MAX_DISCLAIMER_LENGTH} characters.`
      )

    case 'uploads':
      return (
        'This page allows you to configure User Generated Content settings for Mobile Sales Tool ' +
        'users. UGC allows your Mobile Sales Tool users to upload content from their device into ' +
        'the DAM. UGC must be approved by a DAM Content Admin before it can be used in the ' +
        'library or published. Add users to the System: UGC Creators group to give them ' +
        'permission to upload.'
      )

    case 'integrations':
      return (
        "Configure your organization's Salesforce instance to work with BAM! Mobile Sales Tool.  " +
        'MST users will not be able to access Salesforce contacts for sharing until the URL and ' +
        ' Client are populated.'
      )

    case 'general':
      return 'This page allows you to configure settings for DAM users.'

    case constants.SETTINGS_KEY_WEB_ENDPOINTS:
      return (
        "Configure your organization's public web endpoints. Please note, the folders placed or " +
        'published in the endpoint folders are for organization only. A published asset will ' +
        'generate a URL to embed on your website. Publishing assets to a Web Endpoint will not ' +
        'automatically push content to your website.'
      )

    default:
      return ''
  }
}

/**
 * Dispatch the redux save action for the given settings
 */
export const performSaveAction = (formId, value, _this) => {
  switch (formId) {
    case constants.SETTINGS_KEY_WEB_ENDPOINTS:
      _this.props.webEndpointActions.saveWebEndpoint(value)
      break

    default:
      _this.props.actions.saveSettings(formId, value)
      break
  }
}

export const inputIsDisabled = (id, settings, _this) => {
  if (!_this.form) return false

  switch (id) {
    case 'enabled':
      return settings.integrations.salesforce.enabled

    case 'defaultMessage':
    case 'defaultSubject':
      return !_this.form.getFieldValue('enabled')

    default:
      return false
  }
}

/**
 * Conversion for settings that have nested objects
 *
 * @param {String} formId current form id
 * @param {Object} values current form values
 * @param {SettingsContainer} _this settings form container
 * @param {Object} setValues object map of arrays of fields, such as tags
 */
export const convertFormValuesToSettings = (
  formId,
  values,
  _this,
  setValues
) => {
  switch (formId) {
    case 'builders':
      return {
        comparisons: {
          ...values,
        },
      }

    case 'integrations':
      return {
        salesforce: {
          ..._this.props.settings.integrations.salesforce,
          ...values,
        },
      }
    case 'uploads':
      return {
        enabled: values.enabled,
        groups: setValues.groups || _this.props.settings.uploads.groups,
        // Form values returns bad data for max size, so retrieve directly from form:
        maxSize: convertMaxSizeToBytes(_this.form.getFieldValue('maxSize')),
        metadata: {
          title: {
            required: values.titleRequired,
            defaultValue: values.title,
          },
          description: {
            required: values.descriptionRequired,
            defaultValue: values.description,
          },
          tags: {
            required: values.tagsRequired,
            defaultValue: setValues.tags,
          },
        },
        schedule: values.schedule,
      }
    case 'general':
      return {
        maximumShare: parseInt(values['Maximum Share'], 10),
        minimumShare: parseInt(values['Minimum Share'], 10),
      }
    case constants.SETTINGS_KEY_WEB_ENDPOINTS:
      return {
        endpointTitle: values['Endpoint Title'],
        rootFolder: values['Root Folder'],
        image404sByEndpointId: values.image404sByEndpointId,
      }
    default:
      return {
        ...values,
      }
  }
}

// Uses getFieldValue due to apparent defect in antd
// Validates the number value and total size
export const maxSizeValidator = (rule, value, callback, _this) => {
  const fieldValue = _this.form.getFieldValue('maxSize')
  if (fieldValue.number > 999) {
    return callback(new Error('Maximum value 999'))
  }
  if (fieldValue.number < 1) {
    return callback(new Error('Minimum value 1'))
  }
  if (fieldValue.units === 'GB' && fieldValue.number > 5) {
    return callback(new Error('Maximum size of uploads is 5GB'))
  }
  return callback()
}

export const minShareValidator = (rule, value, callback, _this) => {
  if (
    isNaN(value) ||
    Number.isNaN(value) ||
    parseInt(value, 10) < constants.MIN_SHARE_DAYS
  )
    return callback(
      new Error(
        `Minimum must be greater than or equal to ${constants.MIN_SHARE_DAYS}`
      )
    )

  if (!Number.isInteger(Number(value)))
    return callback(new Error('Minimum value must be a whole number'))

  if (parseInt(value, 10) > constants.MAX_SHARE_DAYS)
    return callback(
      new Error(`Minimum value must be less than ${constants.MAX_SHARE_DAYS}`)
    )

  const max = _this.form.getFieldValue('Maximum Share')
  if (parseInt(value, 10) > max) {
    return callback(new Error('Minimum value must be less than Maximum value'))
  }

  if (_this.form.getFieldError('Maximum Share'))
    _this.form.validateFields(['Maximum Share'])
  return callback()
}

export const maxShareValidator = (rule, value, callback, _this) => {
  if (
    isNaN(value) ||
    Number.isNaN(value) ||
    parseInt(value, 10) > constants.MAX_SHARE_DAYS
  )
    return callback(
      new Error(
        `Maximum must be less than or equal to ${constants.MAX_SHARE_DAYS}`
      )
    )

  if (parseInt(value, 10) < constants.MIN_SHARE_DAYS)
    return callback(
      new Error(
        `Maximum value must be greater than ${constants.MIN_SHARE_DAYS}`
      )
    )

  if (!Number.isInteger(Number(value)))
    return callback(new Error('Maximum value must be a whole number'))

  const min = _this.form.getFieldValue('Minimum Share')
  if (parseInt(value, 10) < min)
    return callback(
      new Error('Maximum value must be greater than Minimum value')
    )

  if (_this.form.getFieldError('Minimum Share'))
    _this.form.validateFields(['Minimum Share'])
  return callback()
}

export const maxNumCharsValidator = (
  rule,
  value,
  callback,
  fieldLabel = 'nofieldlabel',
  maxNumChars = 0
) => {
  const numChars = typeof value === 'string' && value.length

  if (Number(numChars) > maxNumChars) {
    return callback(
      new Error(`${fieldLabel} must be ${maxNumChars} characters or less`)
    )
  }

  return callback()
}

export const requiredValidator = (
  rule,
  value,
  callback,
  fieldLabel = 'noFieldLabel'
) => {
  if (!value) {
    return callback(new Error(`${fieldLabel} is required`))
  }

  return callback()
}

// Converts bytes to easier-to-read value object: number of megabytes or gigabytes
const getMaxSizeFromBytes = (bytes) => {
  const defaultSize = {
    number: 50,
    units: 'MB',
  }
  if (Number.isNaN(bytes)) return defaultSize

  let number =
    bytes / constants.MEGABYTES < 1024
      ? bytes / constants.MEGABYTES
      : bytes / constants.GIGABYTES
  let units = bytes / constants.MEGABYTES < 1024 ? 'MB' : 'GB'

  return {
    number,
    units,
  }
}

// Converts the easy-to-read-value object back to bytes
const convertMaxSizeToBytes = (maxSize) =>
  maxSize.number *
  (maxSize.units === 'GB' ? constants.GIGABYTES : constants.MEGABYTES)

export const dropUndefinedProps = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      dropUndefinedProps(obj[key])
    } else if (obj[key] === undefined) delete obj[key]
  })

  return obj
}

export const getWebEndpointSelectOptions = (_this) => {
  return _this.props.endpoints
    .filter(
      (endpoint) =>
        endpoint.deleted !== true &&
        endpoint.type === endpointConstants.WEB_ENDPOINT_TYPE
    )
    .map((endpoint) => ({
      label: endpoint.name,
      id: endpoint.id,
      value: endpoint.id,
    }))
    .concat({
      value: 'new',
      label: 'Create New Endpoint',
      id: webEndpointConstants.DEFAULT_ID,
    })
}
