import { Modal, Table } from 'antd'
import update from 'immutability-helper'
import PropTypes from 'prop-types'
import React from 'react'
import { buildPath, requiresTraverserRole } from '../../dux/modules/utils'
import * as utils from './FolderPermissionsUtils'

class FolderPermissionsModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentEntity: '',
      contentRoles: this.getInitialContentRoles(),
      originalRoles:
        this.props.folders.byId[this.props.folders.activeFolder].contentRoles,
      groupSelect: undefined,
      userSelect: undefined,
      sysDisplay: 'hide',
      selectedEntities: [],
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
    this.props.permissionsActions.getGroups('all', false)
    this.props.permissionsActions.getRoles('all')
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  /*
   *  Save permissions on enter, unless user is typing in a user or group to add to modal
   */
  handleKeyDown = (e) => {
    if (
      e.target.id !== 'groupselect' &&
      e.target.id !== 'userselect' &&
      e.keyCode === 13
    )
      this.save()
  }

  /*
   * For new folders, inherit permissions from parent folder excluding traverser roles.
   * For existing folders, start with existing content roles.
   */
  getInitialContentRoles = () => {
    const { modalProps, folders } = this.props
    if (modalProps.newFolder) {
      // filter out "traverser-only" from contentRoles (except SYS.traversers)
      const newFolderRoles = Object.keys(
        folders.byId[folders.activeFolder].contentRoles
      )
        .filter(
          (entity) =>
            !(
              folders.byId[folders.activeFolder].contentRoles[entity].roleIds
                .length === 1 &&
              folders.byId[folders.activeFolder].contentRoles[entity]
                .roleIds[0] === 'traverser' &&
              folders.byId[folders.activeFolder].contentRoles[entity]
                .entityId !== 'SYS.traversers'
            )
        )
        .reduce((result, entity) => {
          result[entity] = {
            ...folders.byId[folders.activeFolder].contentRoles[entity],
          }
          return result
        }, {})
      return newFolderRoles
    } else {
      return folders.byId[folders.activeFolder].contentRoles
    }
  }

  /*
   * Change Handler for checkbox
   */
  handleChange = (event, record, role) => {
    const id = record.key
    const { roles } = this.props.permissions
    this.setState(() => {
      let contentRoles = { ...this.state.contentRoles }
      contentRoles[id] = {
        ...contentRoles[id],
        roleIds: Object.keys(record)
          .filter(utils.validRoles(roles))
          .map((roleId) =>
            roleId === role
              ? event.target.checked
                ? roleId
                : null
              : record[roleId]
              ? roleId
              : null
          )
          .filter((role) => role !== null),
      }
      return { contentRoles }
    })
  }

  save = () => {
    // Filter out any users or groups that have no roles assigned
    const filteredRoles = Object.keys(this.state.contentRoles)
      .filter((key) => {
        return this.state.contentRoles[key].roleIds.length > 0
      })
      .reduce((result, entity) => {
        result[entity] = { ...this.state.contentRoles[entity] }
        return result
      }, {})
    //Add or save folder
    if (this.props.modalProps.newFolder) {
      //Make sure name is populated
      const name =
        this.props.folders.newFolderName.length > 0
          ? this.props.folders.newFolderName
          : 'New Folder'
      let path = buildPath(
        this.props.folders.activeFolder,
        this.props.folders.byId
      )
      this.props.folderActions.addFolder(
        name,
        this.props.folders.byId[this.props.folders.activeFolder],
        this.props.user.id,
        this.props.user.client,
        filteredRoles,
        path,
        this.props.folders.byId
      )
    } else {
      // Path represents parent folders that need to be updated.
      // Remove current folder from path, since we're explicitly calling updateFolder for it.
      let path = buildPath(
        this.props.modalProps.folder,
        this.props.folders.byId
      ).filter((folder) => folder !== this.props.modalProps.folder)

      const updatedFolder = Object.assign(
        {},
        this.props.folders.byId[this.props.modalProps.folder],
        {
          contentRoles: filteredRoles,
        }
      )
      // on update, removed entities with children may still need the traverse role...
      // so add them back before saving
      const removedEntities = Object.keys(this.state.originalRoles)
        .filter(
          (entity) =>
            Object.keys(filteredRoles).indexOf(entity) < 0 &&
            requiresTraverserRole(
              this.props.modalProps.folder,
              entity,
              this.props.folders.byId,
              this.props.modalProps.folder
            )
        )
        .reduce((result, entity) => {
          result[entity] = {
            ...this.state.originalRoles[entity],
            roleIds: ['traverser'],
          }
          return result
        }, {})

      let replacedEntities = filteredRoles
      Object.keys(removedEntities).forEach((entity) => {
        replacedEntities = update(replacedEntities, {
          [entity]: {
            $set: removedEntities[entity],
          },
        })
      })

      this.props.folderActions.updateFolder({
        folder: updatedFolder,
        path,
        allFolders: this.props.folders.byId,
        checkTraverseRole: true,
        contentRoles: replacedEntities,
      })
    }
  }

  cancel = () => {
    this.props.modalActions.closeModal()
    this.props.folderActions.setNewFolderName('')
  }

  /*
   *  Called when user/group checkbox changes.  Adds/removes entity to selectedEntities
   */
  onEntityClick = (role) => {
    this.setState((prevState) => {
      let selected = [...prevState.selectedEntities]
      const index = selected.indexOf(role)
      if (index > -1) selected.splice(index, 1)
      else selected.push(role)

      return {
        selectedEntities: selected,
        currentEntity: selected.length === 1 ? selected[0] : '',
      }
    })
  }

  /*
   * When header checkbox is clicked, select all users and non-system groups
   */
  selectAllEntities = (e) => {
    const checked = e.target.checked
    this.setState((prevState, props) => {
      if (checked) {
        return {
          selectedEntities: Object.keys(prevState.contentRoles).filter(
            (entity) =>
              !props.permissions.groups[entity] ||
              !props.permissions.groups[entity].system
          ),
          currentEntity: '',
        }
      }

      return {
        selectedEntities: [],
        currentEntity: '',
      }
    })
  }

  /*
   * Show or hide system groups in the modal
   */

  toggleSysGroups = () => {
    this.setState({
      sysDisplay: this.state.sysDisplay === 'hide' ? 'show' : 'hide',
    })
  }

  /*
   * Called when "remove selected" button is clicked.  Removes the selected entities from the
   * content roles.
   */
  removeEntities = () => {
    this.setState((prevState) => {
      let newContentRoles = { ...prevState.contentRoles }
      prevState.selectedEntities.forEach((role) => {
        newContentRoles = update(newContentRoles, {
          $unset: [role],
        })
      })
      return {
        contentRoles: newContentRoles,
        selectedEntities: [],
      }
    })
  }

  addGroup = (val) => {
    const newGroup = {
      entityType: 'group',
      entityId: val,
      roleIds: [],
    }
    this.setState({
      contentRoles: update(this.state.contentRoles, {
        [val]: {
          $set: newGroup,
        },
      }),
      currentEntity: val,
      groupSelect: undefined,
    })
  }

  addUser = (val) => {
    const newUser = {
      entityType: 'user',
      entityId: val,
      roleIds: [],
    }
    this.setState({
      contentRoles: update(this.state.contentRoles, {
        [val]: {
          $set: newUser,
        },
      }),
      currentEntity: val,
      userSelect: undefined,
    })
  }

  isRoleChecked = (role) => {
    let status = {
      checked: true,
      indeterminate: false,
    }

    // If no selected entities, no roles are checked
    if (this.state.selectedEntities.length === 0) {
      status.checked = false
      return status
    }

    // If any entities don't have this role, then the box is not checked
    // However, we'll count the entities...if SOME entities are checked, we'll set indeterminate
    // to true
    let count = 0
    this.state.selectedEntities.forEach((entity) => {
      if (this.state.contentRoles[entity].roleIds.indexOf(role) < 0) {
        status.checked = false
      } else {
        count = count + 1
      }
    })

    if (count > 0 && count !== this.state.selectedEntities.length) {
      status.indeterminate = true
    }
    return status
  }

  render() {
    const { folders, appState } = this.props
    const { newFolder } = this.props.modalProps
    const { groups, users, roles } = this.props.permissions
    const {
      contentRoles,
      sysDisplay,
      selectedEntities,
      groupSelect,
      userSelect,
    } = this.state
    return (
      <Modal
        title={utils.getModalTitle({
          groups,
          users,
          activeFolder: folders.activeFolder,
          allFolders: folders.byId,
          newFolder,
          newFolderName: folders.newFolderName,
          groupSelect,
          userSelect,
          contentRoles,
          addUser: this.addUser,
          addGroup: this.addGroup,
        })}
        visible={this.props.modal.modalOpen}
        confirmLoading={this.props.appState.asyncPending}
        maskClosable={false}
        closable={false}
        width={1025}
        bodyStyle={{ padding: '0 0 0 24px' }}
        footer={utils.getFooter(
          this.save,
          this.cancel,
          this.removeEntities,
          selectedEntities,
          appState.asyncPending
        )}
      >
        <Table
          className="modal__permissions-table"
          columns={utils.generateColumns(
            roles,
            this.state.sysDisplay,
            this.toggleSysGroups,
            this.selectAllEntities
          )}
          dataSource={utils.formatDataSource(
            contentRoles,
            groups,
            users,
            sysDisplay !== 'show',
            this.handleChange,
            selectedEntities,
            this.onEntityClick
          )}
          scroll={{ y: 450 }}
          pagination={false}
        />
      </Modal>
    )
  }
}

/*
 *  modal/modalActions and folder/folderActions are the redux state and actions
 */
FolderPermissionsModal.propTypes = {
  modal: PropTypes.object,
  modalProps: PropTypes.object,
  modalActions: PropTypes.object,
  appState: PropTypes.object,
  folders: PropTypes.object,
  folderActions: PropTypes.object,
  user: PropTypes.object,
  permissions: PropTypes.object,
  permissionsActions: PropTypes.object,
}

export default FolderPermissionsModal
