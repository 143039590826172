import { Button, Modal } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

const MessageModal = (props) => {
  let header
  if (props.modal.modalMessage.type === 'ERROR') {
    header = <h3 className="text-danger">An error occurred</h3>
  } else if (props.modal.modalMessage.type === 'SUCCESS') {
    header = <h3 className="text-success">Success!</h3>
  } else if (props.modal.modalMessage.type === 'WARNING') {
    header = <h3 className="text-warning">Alert</h3>
  } else {
    header = <h3 className="text-warning">FYI...</h3>
  }

  return (
    <Modal
      title={header}
      width={580}
      footer={[
        <Button key="cancel" onClick={props.modalActions.closeModal}>
          Close
        </Button>,
      ]}
      visible={props.modal.modalOpen}
      confirmLoading={props.appState.asyncPending}
      onCancel={props.modalActions.closeModal}
      maskClosable={false}
    >
      <h3 className="modal__message">{props.modal.modalMessage.msg}</h3>
    </Modal>
  )
}

/*
 * modal is the redux state for modals
 * modalActions is an object containing the modal actions, namespaced by bindActionCreators
 */
MessageModal.propTypes = {
  modal: PropTypes.object,
  modalActions: PropTypes.object,
}
export default MessageModal
