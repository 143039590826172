import { Checkbox, Table, Tooltip } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../dux/actions/permissions'
import { isAdmin } from '../../dux/modules/utils'
import FourOhFour from '../common/FourOhFour'
import LoadingModal from '../modals/LoadingModal'

class RolesContainer extends React.Component {
  componentWillMount() {
    this.props.actions.getPrivileges()
  }

  cellRender = (record, role) => {
    const exceptions = record.endpointExceptions.filter(
      (exception) => exception.role === role.id
    )

    return (
      <span>
        {exceptions.length > 0 ? (
          <Tooltip title={exceptions[0].description}>
            <Checkbox disabled={true} checked={record[role.id]} /> *{' '}
          </Tooltip>
        ) : (
          <Checkbox disabled={true} checked={record[role.id]} />
        )}
      </span>
    )
  }

  render() {
    const columns = [
      {
        title: 'Privilege',
        dataIndex: 'display',
        width: 130,
        className: 'bam__table-column bam__table-column-maxwidth130',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        width: 350,
        className: 'bam__table-column bam__table-column-maxwidth350',
      },
    ].concat(
      this.props.permissions.roles
        .sort((a, b) => a.sortOrder > b.sortOrder)
        .map((role) => ({
          title: role.displayName,
          dataIndex: role.id,
          width: 100,
          className: 'bam__table-column bam__table-column-maxwidth100',
          render: (text, record) => this.cellRender(record, role),
        }))
    )
    const admin = isAdmin(this.props.email, this.props.permissions.groups)
    return !this.props.initializing && !admin ? (
      <FourOhFour />
    ) : this.props.loading ? (
      <LoadingModal />
    ) : (
      <Table
        className="permissions__table"
        style={{ height: '100%' }}
        dataSource={Object.keys(this.props.permissions.privileges).map(
          (priv) => this.props.permissions.privileges[priv]
        )}
        columns={columns}
        rowKey={(record) => record.privilege}
        bordered={true}
        scroll={{ x: 1280, y: this.props.windowHeight - 230 }}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  permissions: state.permissions,
  initializing: state.appState.initializing,
  loading: state.appState.asyncPending,
  email: state.user.email,
  windowWidth: state.appState.windowWidth,
  windowHeight: state.appState.windowHeight,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(RolesContainer)
