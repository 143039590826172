import React, { Component } from 'react'
import { connect } from 'react-redux'
import { refreshAndLogState } from '../../../dux/actions/appActions'
import { getRefreshedState } from '../../../dux/reducers/appState'
const styles = {
  form: { display: 'flex', flexDirection: 'column', color: 'black' },
  label: { fontWeight: '1000px', fontSize: '30px' },
  button: { color: 'black', width: 200 },
  disabled: { color: 'red' },
}

// | first | last | email | password | invite | groups (names, semicolon-delimited) |
// need one of: Invite or Groups, all others are required
class BatchPublish extends Component {
  // Upload Folders

  constructor(props) {
    super(props)

    console.log(props.stateToLog)
  }

  submitHandler = (e) => {
    e.preventDefault()

    console.log('invoking refreshAndLogState')
    this.props.refreshAndLogState()
  }

  render() {
    const output =
      this.state && this.state.output
        ? JSON.stringify(this.state.output, null, 2)
        : null

    return (
      <>
        <form style={styles.form} onSubmit={this.submitHandler}>
          <button type="submit" style={styles.button}>
            Refresh
          </button>
          {output && (
            <div>
              <pre>{output}</pre>
            </div>
          )}
        </form>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  refreshAndLogState: () => {
    dispatch(refreshAndLogState())
  },
})

const mapStateToProps = (state) => ({
  stateToLog: getRefreshedState(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(BatchPublish)
