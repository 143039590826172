import PropTypes from 'prop-types'
import React from 'react'
import { DragSource } from 'react-dnd'
import {
  DND_NAVZONE,
  dragCollect,
  navzoneSource,
} from '../../../dux/modules/DragDrop'

class Navzone extends React.Component {
  render() {
    const {
      style,
      center,
      navzone,
      connectDragSource,
      imageWidth,
      imageHeight,
      handleMouseEnter,
      handleMouseLeave,
      isDragging,
      onDragHandleDown,
      dragHandleDown,
      activeZoneId,
      mouseIsDown,
    } = this.props
    const zoneStyle = {
      top: center.y - navzone.topLeft.y * center.y,
      left: center.x + navzone.topLeft.x * center.x,
      width: ((navzone.bottomRight.x - navzone.topLeft.x) / 2) * imageWidth,
      height: ((navzone.topLeft.y - navzone.bottomRight.y) / 2) * imageHeight,
      opacity: isDragging
        ? 0
        : dragHandleDown && navzone.id === activeZoneId
        ? 0.7
        : undefined,
      pointerEvents: dragHandleDown || mouseIsDown ? 'none' : undefined,
    }
    return connectDragSource(
      <div
        id={`navzone-${navzone.id}`}
        className="simulator__navigator-zone"
        style={{ ...zoneStyle, style }}
        onMouseEnter={() => handleMouseEnter(navzone.id, 'listitem')}
        onMouseLeave={() => handleMouseLeave(navzone.id, 'listitem')}
      >
        <DragHandle
          id={`draghandle-tl-${navzone.id}`}
          style={{
            top: -4,
            left: -4,
            pointerEvents: mouseIsDown ? 'none' : undefined,
          }}
          onMouseDown={(e) => onDragHandleDown(e, 'tl')}
        />
        <DragHandle
          id={`draghandle-tr-${navzone.id}`}
          style={{
            cursor: 'nesw-resize',
            top: -4,
            right: -4,
            pointerEvents: mouseIsDown ? 'none' : undefined,
          }}
          onMouseDown={(e) => onDragHandleDown(e, 'tr')}
        />
        <DragHandle
          id={`draghandle-br-${navzone.id}`}
          style={{
            bottom: -4,
            right: -4,
            pointerEvents: mouseIsDown ? 'none' : undefined,
          }}
          onMouseDown={(e) => onDragHandleDown(e, 'br')}
        />
        <DragHandle
          id={`draghandle-bl-${navzone.id}`}
          style={{
            cursor: 'nesw-resize',
            bottom: -4,
            left: -4,
            pointerEvents: mouseIsDown ? 'none' : undefined,
          }}
          onMouseDown={(e) => onDragHandleDown(e, 'bl')}
        />
      </div>
    )
  }
}

const DragHandle = (props) => (
  <div
    className="simulator__navigator-zone-draghandle"
    id={props.id}
    style={props.style}
    onMouseDown={props.onMouseDown}
  >
    &nbsp;
  </div>
)

Navzone.propTypes = {
  style: PropTypes.object,
  center: PropTypes.object,
  navzone: PropTypes.object,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  builderType: PropTypes.string,
  parentId: PropTypes.string,
  connectDragSource: PropTypes.func,
  isDragging: PropTypes.bool,
  handleMouseEnter: PropTypes.func,
  handleMouseLeave: PropTypes.func,
  onDragHandleDown: PropTypes.func,
  dragHandleDown: PropTypes.bool,
}

Navzone.defaultProps = {
  handleMouseEnter: () => {},
  handleMouseLeave: () => {},
}
export default DragSource(DND_NAVZONE, navzoneSource, dragCollect)(Navzone)
