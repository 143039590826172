import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import { S3RequestPresigner } from '@aws-sdk/s3-request-presigner'
import { createRequest } from '@aws-sdk/util-create-request'
import { formatUrl } from '@aws-sdk/util-format-url'
import { Icon, message, Modal, Tooltip } from 'antd'
import Amplify, { Analytics, Auth } from 'aws-amplify'
import clsx from 'clsx'
import { find, get, isEqual, reject, upperFirst } from 'lodash'
import React from 'react'
import * as assetActions from '../actions/asset'
import * as builderActions from '../actions/builders'
import * as endpointActions from '../actions/endpoint'
import AssetSelector from '../../components/common/AssetSelector'
import ReviewAssetForm from '../../components/library/ReviewAssetForm'
import * as analyticsConstants from '../constants/analytics'
import { DEFAULT_SIDEBAR_WIDTH } from '../constants/app'
import {
  PDF_IMAGE_FILE_TYPE,
  TRANSCODING_EXTENSION,
  UGC_REJECTED_STATUS,
} from '../constants/assets'
import {
  BUILDERS_WITH_SLIDES,
  COMPARISON__BUILDER_TYPE,
  FEATURE__BUILDER_TYPE,
  NAVIGATOR__BUILDER_TYPE,
  NEWS__BUILDER_TYPE,
  PRODUCT__BUILDER_TYPE,
} from '../constants/builders'
import * as endpointConstants from '../constants/endpoints'
import { WEB_ENDPOINT_TYPE } from '../constants/endpoints'
import * as folderConstants from '../constants/folder'
import { EXTENSIONS } from './extensions'

/* configures amplify */
/* if userId is passed, analytics endpoint is configured for the user */
export const configureAmplify = (config) => {
  // Amplify.Logger.LOG_LEVEL = 'DEBUG';
  Amplify.configure({
    Auth: {
      region: config.region,
      userPoolWebClientId: config.appClient,
      userPoolId: config.userPool,
      identityPoolId: config.identityPool,
      cookieStorage: {
        domain: config.mask || config.domain,
        path: '/',
        expires: 1,
        sameSite: 'lax',
        secure: false,
      },
    },
    Analytics: {
      disabled: false,
      autoSessionRecord: true,
      AWSPinpoint: {
        // OPTIONAL -  Amazon Pinpoint App Client ID
        appId: config.aws_exports.aws_mobile_analytics_app_id,
        // OPTIONAL -  Amazon service region
        region: config.aws_exports.aws_mobile_analytics_app_region,
        // Buffer settings used for reporting analytics events.
        // OPTIONAL - The buffer size for events in number of items.
        bufferSize: 1000,
        // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if
        // necessary.
        flushInterval: 5000, // 5s
        // OPTIONAL - The number of events to be deleted from the buffer when flushed.
        flushSize: 100,
        // OPTIONAL - The limit for failed recording retries.
        resendLimit: 5,
      },
    },
    aws_appsync_graphqlEndpoint: config.appSyncEndpoint,
    aws_appsync_region: config.region,
    aws_appsync_authenticationType: 'AWS_IAM',
  })
}

export const updateAnalyticsEndpoint = async (
  user = null,
  version = null,
  config = null
) => {
  try {
    if (!user) {
      return await Analytics.updateEndpoint({})
    }

    await Analytics.updateEndpoint({
      address: user.email,
      channelType: 'EMAIL',
      attributes: {
        BAMPlatform: ['DAM'],
        BAMVersion: [version],
        BAMClient: [config.stage],
      },
      userId: user.id,
      userAttributes: {
        userEmail: [user.email],
        userId: [user.id],
      },
    })
    return Analytics.autoTrack('pageView', {
      enable: true,
      type: 'SPA',
    })
  } catch (err) {
    console.error('Analytics endpoint error', err)
  }

  return Promise.resolve()
}

export const recordAnalyticsEvent = async (actionType, data = {}) => {
  const name = getEventName(actionType)
  const attributes = getEventAttributes(actionType, data)
  try {
    await Analytics.record({
      name,
      attributes,
      // Attribute values must be strings
    })
  } catch (err) {
    console.error('Analytics record error', err)
  }
}

export const getEventName = (actionType) => {
  switch (actionType) {
    case assetActions.GET_ASSET:
      return analyticsConstants.VIEW_ASSET_DETAIL

    case assetActions.CREATE_ASSET:
    case builderActions.CREATE_BUILDER:
      return analyticsConstants.CREATE_ASSET

    case analyticsConstants.DOWNLOAD_ASSET:
      return analyticsConstants.DOWNLOAD_ASSET

    case analyticsConstants.SHARE_ASSET:
      return analyticsConstants.SHARE_ASSET

    case assetActions.UPDATE_ASSET:
    case builderActions.UPDATE_BUILDER:
      return analyticsConstants.EDIT_ASSET

    case endpointActions.PUBLISH_ASSET:
      return analyticsConstants.PUBLISH_ASSET_REQUEST

    case analyticsConstants.PUBLISH_ASSET_APPROVE:
      return analyticsConstants.PUBLISH_ASSET_APPROVE

    case analyticsConstants.PUBLISH_ASSET_REJECT:
      return analyticsConstants.PUBLISH_ASSET_REJECT

    case endpointActions.UNPUBLISH_ASSET:
      return analyticsConstants.UNPUBLISH_ASSET_REQUEST

    case analyticsConstants.UNPUBLISH_ASSET_APPROVE:
      return analyticsConstants.UNPUBLISH_ASSET_APPROVE

    case analyticsConstants.UNPUBLISH_ASSET_REJECT:
      return analyticsConstants.UNPUBLISH_ASSET_REJECT
    case analyticsConstants.CALCULATE:
      return analyticsConstants.CALCULATE
    case analyticsConstants.LAUNCH_CALCULATOR:
      return analyticsConstants.LAUNCH_CALCULATOR
    default:
      return analyticsConstants.GENERIC_EVENT
  }
}

export const getEventAttributes = (actionType, data) => {
  switch (actionType) {
    case assetActions.GET_ASSET:
    case builderActions.UPDATE_BUILDER:
    case analyticsConstants.EDIT_ASSET:
    case analyticsConstants.DOWNLOAD_ASSET:
      return {
        assetId: data.asset.id,
        assetVersion: data.asset.version.toString(),
        file_type: data.asset.file_type,
      }

    case analyticsConstants.SHARE_ASSET:
      return {
        assetId: data.asset.id,
        assetVersion: data.asset.version.toString(),
        file_type: data.asset.file_type,
        share_url_id: data.url.ID,
      }

    case assetActions.CREATE_ASSET:
    case builderActions.CREATE_BUILDER:
    case endpointActions.PUBLISH_ASSET:
    case analyticsConstants.PUBLISH_ASSET_APPROVE:
    case analyticsConstants.PUBLISH_ASSET_REJECT:
    case endpointActions.UNPUBLISH_ASSET:
    case analyticsConstants.UNPUBLISH_ASSET_APPROVE:
    case analyticsConstants.UNPUBLISH_ASSET_REJECT:
      return {
        assetId: data.asset.id,
        assetVersion: data.asset.version.toString(),
        file_type: data.asset.file_type,
        assetSource: data.asset.tags.indexOf('SYS.ugc') > -1 ? 'UGC' : 'DAM',
      }
    case analyticsConstants.CALCULATE:
      return calculateAttributes(data)
    case analyticsConstants.LAUNCH_CALCULATOR:
      return {
        calculatorId: data.calculatorId,
        title: data.title,
      }
    default:
      return {
        generic: 'true',
      }
  }
}

/*
 * flattenCalculatorObject/CalculateAttributes - utilities for getting the data in the right shape
 * for the CALCULATE analytics event
 */

function flattenCalculatorObject(calcObject, firstKey) {
  const flattenedObject = {}
  const sectionKeys = Object.keys(calcObject[firstKey])
  let keyCount = 0
  for (let i = 0; i < sectionKeys.length; ++i) {
    const section = calcObject[firstKey][sectionKeys[i]]
    const ioKeys = Object.keys(section)
    for (let j = 0; j < ioKeys.length; ++j) {
      const io = section[ioKeys[j]]
      flattenedObject[
        `${firstKey}|${String(keyCount).padStart(2, '0')}|${ioKeys[j]}`
      ] = io
      ++keyCount
    }
  }

  return flattenedObject
}

function calculateAttributes(action) {
  const attributes = {
    calculatorId: action.calculatorId,
    title: action.title,
  }

  const flattenedInputs = flattenCalculatorObject(action, 'in')
  Object.keys(flattenedInputs).forEach((inputKey) => {
    attributes[inputKey.substring(0, 50)] = flattenedInputs[inputKey]
  })

  const flattenedOutputs = flattenCalculatorObject(action, 'out')
  Object.keys(flattenedOutputs).forEach((outputKey) => {
    attributes[outputKey.substring(0, 50)] = flattenedOutputs[outputKey]
  })
  return attributes
}

/*
 * buildPath - recursively walks the parentID chain to
 *  returns an array of folder names
 */
export function buildPath(folder, allFolders) {
  let path = []
  if (allFolders[folder]) {
    path.push(allFolders[folder].id)
    let current = folder
    while (allFolders[current] && allFolders[current].parentId) {
      current = allFolders[current].parentId

      // BAM-2502 may not exist due to being filtered out from privileges
      if (allFolders[current]) {
        path.push(allFolders[current].id)
      }
    }
  }

  return path
}

/*
 * Test if f1 is a child folder of f2
 * f1 = target folder
 * f2 = droppedItem folder
 */
export function isChild(f1, f2, allFolders) {
  if (allFolders[f2].childFolders.indexOf(f1) > -1) {
    return true
  } else {
    let result = false
    allFolders[f2].childFolders &&
      allFolders[f2].childFolders.forEach((folder) => {
        if (isChild(f1, folder, allFolders)) {
          result = true
        }
      })
    return result
  }
  //console.log(allFolders[f1].name,'not a child of ', allFolders[f2].name)
  //return false
}

export const getChildren = (folder, allFolders) => {
  let children = allFolders[folder].childFolders
  allFolders[folder].childFolders.forEach((child) => {
    children = children.concat(getChildren(child, allFolders))
  })
  return children
}

export const assetName = (asset, maxLength = 30) => {
  let name, fullname
  if (asset && asset.title && asset.title.length > 0) {
    name =
      asset.title.length > maxLength
        ? asset.title
            .substring(0, maxLength - 9)
            .concat('...')
            .concat(asset.title.substring(asset.title.length - 6))
        : asset.title
    fullname = asset.title
  } else {
    name =
      asset.filename && asset.filename.length > maxLength
        ? asset.filename
            .substring(0, maxLength - 9)
            .concat('...')
            .concat(asset.filename.substring(asset.filename.length - 6))
        : asset.filename
    fullname = asset.filename
  }

  if (name === '') name = 'Unitled'
  return <Tooltip title={fullname}>{name}</Tooltip>
}

export const shortenFolderName = (folderName = '') => {
  if (folderName.length < 24) return folderName

  const beginning = folderName.substring(0, 10).trim()
  const middle = '...'
  const end = folderName.substring(folderName.length - 10).trim()

  return beginning.concat(middle).concat(end)
}

/*
 *   Return the preview icon for the asset; for images it will be the URL, for other file types
 *   there are default icons in the S3 asset bucket
 */
export function getFileDetails(mime_type, url, extension) {
  let displayDetails = {
    preview: null,
    icon: 'fa-file',
    color: '#003a8c',
  }
  let foundMimeType = false
  const paths = [
    { mime: 'image', path: url, icon: 'fa-file-image-o', color: null },
    { mime: 'audio', path: null, icon: 'fa-file-audio-o', color: '#780650' },
    { mime: 'video', path: null, icon: 'fa-file-video-o', color: '#00474f' },
    {
      mime: 'application/pdf',
      path: null,
      icon: 'fa-file-pdf-o',
      color: '#820014',
    },
    {
      mime: 'application/msword',
      path: null,
      icon: 'fa-file-word-o',
      color: '#061178',
    },
    {
      mime: 'application/vnd.ms-word',
      path: null,
      icon: 'fa-file-word-o',
      color: '#061178',
    },
    {
      mime: 'application/vnd.oasis.opendocument.text',
      path: null,
      icon: 'fa-file-word-o',
      color: '#061178',
    },
    {
      mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml',
      path: null,
      icon: 'fa-file-word-o',
      color: '#061178',
    },
    {
      mime: 'application/vnd.ms-excel',
      path: null,
      icon: 'fa-file-excel-o',
      color: '#135200',
    },
    {
      mime: 'application/vnd.ms-excel',
      path: null,
      icon: 'fa-file-excel-o',
      color: '#135200',
    },
    {
      mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml',
      path: null,
      icon: 'fa-file-excel-o',
      color: '#135200',
    },
    {
      mime: 'application/vnd.openxmlformats-officedocument.presentationml',
      path: null,
      icon: 'fa-file-powerpoint-o',
      color: '#873800',
    },
    {
      mime: 'application/vnd.ms-powerpoint',
      path: null,
      icon: 'fa-file-powerpoint-o',
      color: '#873800',
    },
    {
      mime: 'application/vnd.openxmlformats-officedocument.presentationml',
      path: null,
      icon: 'fa-file-powerpoint-o',
      color: '#873800',
    },
    {
      mime: 'application/vnd.oasis.opendocument.presentation',
      path: null,
      icon: 'fa-file-powerpoint-o',
      color: '#873800',
    },
  ]

  // Try to get details by mime type
  paths.forEach((mt) => {
    if (!foundMimeType && mime_type.startsWith(mt.mime)) {
      displayDetails.preview = mt.path
      displayDetails.icon = mt.icon
      displayDetails.color = mt.color
      foundMimeType = true
    }
  })
  // Try to get details by file extension
  if (!foundMimeType) {
    if (EXTENSIONS[extension]) {
      displayDetails = EXTENSIONS[extension]
      // set preview path for images
      if (displayDetails.icon === 'fa-file-image-o')
        displayDetails.preview = url
    }
  }

  return displayDetails
}

export const getLabel = (asset) => {
  if (asset.asset_type !== 'asset') {
    return upperFirst(asset.asset_type)
  }
  if (asset.extension) {
    if (EXTENSIONS[asset.extension]) {
      return EXTENSIONS[asset.extension].label
    }
  }
  return 'File'
}

export const getFileExtension = (filename) => {
  const a = filename.split('.')
  if (a.length === 1 || (a[0] === '' && a.length === 2)) {
    return ''
  }
  return a.pop().toLowerCase()
}

export async function getS3Url(params, { region }) {
  const credentials = await Auth.currentCredentials()
  const client = new S3Client({
    credentialDefaultProvider: () => Auth.essentialCredentials(credentials),
    region,
  })
  const command = new PutObjectCommand(params)
  const request = await createRequest(client, command)
  const signer = new S3RequestPresigner({ ...client.config })
  const signedUrl = await signer.presign(request)

  return formatUrl(signedUrl)
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

// Set limits on how often a function can be called
export const throttle = (func, limit) => {
  let throttled
  return function () {
    const args = arguments
    const context = this
    if (!throttled) {
      func.apply(context, args)
      throttled = true
      setTimeout(() => (throttled = false), limit)
    }
  }
}

/*
 *   The right click action handler for the folder tree in the sidebar
 */
export function handleRightClick(e, data) {
  if (data.action === folderConstants.DELETE_FOLDER_ACTION) {
    // If the folder being clicked is not the active folder, set active folder
    if (data.name !== data.folders.activeFolder) {
      data.folderActions.activeFolder(data.name)
    }
    // Do not allow delete of folder if it contains referenced assets or referenced folders
    if (
      !folderContainsReferencedAssets(
        data.name,
        data.relationships.assets,
        data.folders.byId,
        data.endpoints.endpoints
      ) &&
      !folderContainsReferencedFolders(
        data.name,
        data.folders.byId,
        data.relationships.folders
      )
    )
      return data.modalActions.openModal('DeleteModal', {
        type: 'folder',
        folderDragData: { over: null, above: null, below: null },
      })

    data.modalActions.setMessage({
      type: 'ERROR',
      msg:
        'Invalid Action: cannot delete folders that contain assets or folders that are ' +
        'referenced by Builder Assets',
    })
    data.modalActions.openModal('MessageModal')
  }
  if (data.action === folderConstants.NEW_FOLDER_ACTION) {
    // If the folder being clicked is not the active folder, set active folder
    if (data.name !== data.folders.activeFolder) {
      data.folderActions.activeFolder(data.name)
    }
    // Do not allow add folder in recycle bin
    if (data.name === data.folders.recycleBin || data.name === '_search') {
      data.modalActions.setMessage({
        type: 'ERROR',
        msg: 'Invalid Target: cannot add folders to recycle bin or search folder',
      })
      data.modalActions.openModal('MessageModal')
      return
    }
    data.toggle()
    const ep = getEndpointOfNode(
      data.folders.byId[data.name],
      data.folders.byId,
      data.endpoints.endpoints
    )

    data.modalActions.openModal('AddFolderModal', {
      treeRoot: ep.rootFolders,
      onToggle: data.toggle,
      onNodeClick: data.onNodeClick,
      endpointType: ep.type,
    })
  }
  if (data.action === folderConstants.EMPTY_RECYCLE_BIN_ACTION) {
    Modal.confirm({
      title: 'Empty Recycle Bin',
      content: 'This action cannot be undone.',
      onOk: () => {
        data.folderActions.emptyRecycleBin(
          data.folders.byId[data.folders.recycleBin],
          data.folders.recycleBin
        )
      },
      onCancel: () => {
        Promise.resolve()
      },
    })
  }
  if (data.action === folderConstants.RENAME_FOLDER_ACTION) {
    data.appActions.setRenamingFolder(data.name)
  }

  if (data.action === folderConstants.SET_PERMISSIONS_ACTION) {
    // If the folder being clicked is not the active folder, set active folder
    if (data.name !== data.folders.activeFolder) {
      data.folderActions.activeFolder(data.name)
    }
    data.modalActions.openModal('FolderPermissionsModal', { folder: data.name })
  }
}

/*
 *  A function to enable downloading of assets.  It adds a link to the page, clicks it, then
 *  removes it
 *  Note: special handling for comparison data (we don't want to download the asset json, we
 *  want the comparison data sheet)
 *  Note Note: there's also related code in the downloads microservice
 */
export const downloadAsset = (
  item,
  useTranscodedVideo = false,
  recordEvent = true
) => {
  const link = document.createElement('a')
  const params = []
  let downloadName = item.filename

  link.href = item.url.replace(/active/, 'download')

  if (useTranscodedVideo) {
    downloadName = `${downloadName}.${TRANSCODING_EXTENSION}`
    params.push({ key: 'transcoded', value: true })

    link.href = item.url
      .substring(0, item.url.indexOf('active'))
      .concat(item.videoSrc.sources[0].replace(/videos/, 'download'))
  } else if (item.asset_type === COMPARISON__BUILDER_TYPE) {
    downloadName = downloadName.replace(/\.comparison$/, '.tsv')
    params.push({ key: 'comparison', value: true })

    link.href = item.url
      .substring(0, item.url.indexOf('active'))
      .concat(item.comparison.datafileKey.replace(/comparisons/, 'download'))
  }

  params.push({ key: 'name', value: downloadName })
  link.href += `?${params
    .reduce((acc, curr) => acc.concat(`${curr.key}=${curr.value}`), [])
    .join('&')}`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  if (recordEvent) {
    recordAnalyticsEvent(analyticsConstants.DOWNLOAD_ASSET, {
      asset: item,
    }).catch(console.error)
  }
}

/*
 *  Given a folder, gets it's root folder.
 *  The root folder may have special handling, and can be useful to look up the endpoint
 */
export function getRootOfNode(folder, allFolders) {
  let parent = folder.parentId

  // BAM-2502 may not exist due to being filtered out from privileges
  if (parent && allFolders[parent]) {
    return getRootOfNode(allFolders[parent], allFolders)
  } else {
    return folder.id
  }
}

/*
 *  Given a folder ID, return the endpoint that it belongs to
 */
export function getEndpointOfNode(folder, allFolders, endpoints) {
  const root = getRootOfNode(folder, allFolders)
  let ep = {}
  endpoints.forEach((endpoint) => {
    if (endpoint.rootFolders.indexOf(root) > -1) ep = endpoint
  })
  return ep
}

/*
 * Given an endpoint ID, return the endpoint object
 */
export function getEndpointById(id, endpoints) {
  let ep = null
  endpoints.forEach((endpoint) => {
    if (endpoint.id === id) {
      ep = endpoint
    }
  })
  return ep
}

export const getEndpointStatus = (
  folder,
  asset,
  folderAssetStatus,
  options = {}
) => {
  const {
    endpointType = 'mst',
    specialtyIcon = false,
    tooltipPlacement = 'top',
    tooltipTitle = null,
  } = options
  const status = folderAssetStatus[folder][asset]?.status
  let icon = null
  switch (status) {
    case 'PENDING_APPROVAL':
      icon = (
        <Tooltip
          title={tooltipTitle ? tooltipTitle : 'Pending Approval'}
          placement={tooltipPlacement}
        >
          <i className="fa fa-check-circle-o assettable__status-yellow" />
        </Tooltip>
      )
      break
    case 'PENDING_REMOVAL':
      icon = (
        <Tooltip
          title={tooltipTitle ? tooltipTitle : 'Pending Removal'}
          placement={tooltipPlacement}
        >
          <i className="fa fa-exclamation-circle assettable__status-red" />
        </Tooltip>
      )
      break
    case 'APPROVED':
      icon = (
        <Tooltip
          title={tooltipTitle ? tooltipTitle : 'Published'}
          placement={tooltipPlacement}
        >
          <i
            className={clsx('fa assettable__status-green', {
              'fa-check-circle': !specialtyIcon,
              'fa-mobile': specialtyIcon && endpointType !== WEB_ENDPOINT_TYPE,
              'fa-globe': specialtyIcon && endpointType === WEB_ENDPOINT_TYPE,
            })}
          />
        </Tooltip>
      )
      break
    default:
      icon = null
  }
  return icon
}
export const getEndpointStatusText = (folder, asset, folderAssetStatus) => {
  const status = folderAssetStatus[folder][asset].status
  let text
  switch (status) {
    case 'PENDING_APPROVAL':
    case 'PENDING_REMOVAL':
      text = 'Waiting For Approval'
      break
    case 'APPROVED':
      text = 'Published'
      break
    case 'UNPUBLISHED':
      text = 'Unpublished'
      break
    default:
      text = 'Original Asset'
  }
  return text
}
/*
 *   Return the number of assets in an endpoint with a PENDING_APPROVAL status
 */
export function getEndpointPendingCount(
  children,
  folderAssetStatus,
  allFolders,
  type = 'PENDING_APPROVAL'
) {
  let count = 0
  children.forEach((folder) => {
    count =
      count + getFolderPendingCount(folder, folderAssetStatus, type, allFolders)
    if (allFolders[folder].childFolders.length > 0) {
      count =
        count +
        getEndpointPendingCount(
          allFolders[folder].childFolders,
          folderAssetStatus,
          allFolders,
          type
        )
    }
  })
  return count
}

/*
 *   Return the number of assets in a folder with a PENDING_APPROVAL status
 */
export function getFolderPendingCount(
  folder,
  folderAssetStatus,
  type = 'PENDING_APPROVAL',
  allFolders
) {
  let count = 0
  if (allFolders[folder]) {
    allFolders[folder].assets.forEach((asset) => {
      const assetState = folderAssetStatus[folder][asset]
      if (assetState && assetState.status === type) {
        count = count + 1
      }
    })
  }
  return count
}

/*
 *   Return the number of assets in a folder with a PENDING_APPROVAL status
 */
export function getRolledUpFolderPendingCount(
  folder,
  allFolders,
  folderAssetStatus,
  type = 'PENDING_APPROVAL'
) {
  let count = getFolderPendingCount(folder, folderAssetStatus, type, allFolders)
  allFolders[folder].childFolders.forEach((child) => {
    count =
      count +
      getRolledUpFolderPendingCount(child, allFolders, folderAssetStatus, type)
  })
  return count
}

/*
 *  Helper to get the correct count for badges in the sidebar
 */
export const getEndpointBadgeCount = (
  badgeType,
  rootFolders,
  folderAssetStatus,
  allFolders
) => {
  switch (badgeType) {
    case 'UGC':
      if (!allFolders[folderConstants.UGC_FOLDER]) return 0

      return allFolders[folderConstants.UGC_FOLDER].assets.length || 0
    case 'PENDING_APPROVAL':
    case 'PENDING_REMOVAL':
      return getEndpointPendingCount(
        rootFolders,
        folderAssetStatus,
        allFolders,
        badgeType
      )
    default:
      return 0
  }
}

/*
 *   SYS. tags are applied by the system when an asset meets certain criteria;
 *   For now, the criteria is missing metadata (tags, title, description)
 *   When an asset is created or edited this function adds or removes the SYS. tags
 *   based on the asset's metadata conditions.
 */
export function validateSysTags(asset) {
  const tagCount = asset.tags.filter((tag) => !tag.startsWith('SYS.')).length
  const hasDescription = asset.description && asset.description.length > 0
  const hasTitle = asset.title && asset.title.length > 0

  if (tagCount === 0) {
    if (asset.tags.indexOf('SYS.missingtags') < 0) {
      asset.tags.push('SYS.missingtags')
    }
  } else {
    if (asset.tags.indexOf('SYS.missingtags') > -1) {
      asset.tags.splice(asset.tags.indexOf('SYS.missingtags'), 1)
    }
  }

  if (!hasDescription) {
    if (asset.tags.indexOf('SYS.missingdescription') < 0) {
      asset.tags.push('SYS.missingdescription')
    }
  } else {
    if (asset.tags.indexOf('SYS.missingdescription') > -1) {
      asset.tags.splice(asset.tags.indexOf('SYS.missingdescription'), 1)
    }
  }

  if (!hasTitle) {
    if (asset.tags.indexOf('SYS.missingtitle') < 0) {
      asset.tags.push('SYS.missingtitle')
    }
  } else {
    if (asset.tags.indexOf('SYS.missingtitle') > -1) {
      asset.tags.splice(asset.tags.indexOf('SYS.missingtitle'), 1)
    }
  }

  return asset
}

/*
 * Checks if user has the ability to create content (assets, news, comparison, navigator, product)
 * Looks for SYS level groups first; then checks folder tree for user-specific groups and roles
 */
export const isContentAdmin = (user, groups) => {
  const userGroups = getGroupsByUser(user, groups)
  return userGroups.some((grp) => grp === 'SYS.contentadmins')
}

/*
 *  Given a privilege name and the set of all privileges, return the role IDs that have this
 *  privilege
 */
export function getRolesWithPrivilege(requestedPrivilege, privileges) {
  return Object.keys(privileges[requestedPrivilege] || []).filter(
    (key) => privileges[requestedPrivilege][key] === true
  )
}

/*
 *  Return an array of groups containing this user in members
 */
export const getGroupsByUser = (user, groups) => {
  return Object.keys(groups).filter(
    (grp) => groups[grp].members.indexOf(user) > -1
  )
}

export const validatePrivilege = (
  requestedPrivilege,
  privileges = [],
  user,
  groups,
  folderToCheck,
  allFolders
) => {
  if (folderToCheck === '_search' && requestedPrivilege === 'viewfolder')
    return true

  // if user is a content admin or admin, allow all actions
  const userGroups = getGroupsByUser(user, groups)
  if (
    userGroups.includes('SYS.admins') ||
    userGroups.includes('SYS.contentadmins')
  ) {
    return true
  }

  // Check whether active folder exists in this user's state
  // Convert single folder to array
  if (!Array.isArray(folderToCheck)) {
    if (!(folderToCheck && allFolders[folderToCheck])) {
      return false
    }
    folderToCheck = [folderToCheck]
  }

  let privilegeFound = false

  folderToCheck.forEach((folder) => {
    if (!privilegeFound) {
      const contentRoles = allFolders[folder].contentRoles
      //console.log(`this folder's contentRoles:`, contentRoles)
      const validRoles = getRolesWithPrivilege(requestedPrivilege, privileges)
      //console.log(`roles with ${requestedPrivilege}`, validRoles)
      if (
        contentRoles[user] &&
        validRoles.some((r) => contentRoles[user].roleIds.indexOf(r) > -1)
      ) {
        privilegeFound = true
      } else {
        //console.log('userGroups', userGroups)

        // Filter the content roles by groups that contain the valid roles (we already checked user)
        // Check if user is a member of any of those groups
        if (
          Object.keys(contentRoles)
            .filter((cr) => contentRoles[cr].entityType === 'group')
            .filter((cr) => userGroups.indexOf(cr) > -1)
            .filter((cr) =>
              contentRoles[cr].roleIds.some((r) => validRoles.indexOf(r) > -1)
            ).length > 0
        ) {
          privilegeFound = true
        }
      }
    }
  })

  return privilegeFound
}

export const isAdmin = (user, groups) => {
  return groups['SYS.admins'] && groups['SYS.admins'].members.indexOf(user) > -1
}

export const folderHasContentRoleGroup = (folder, allFolders, group) => {
  return (
    allFolders[folder].contentRoles[group] &&
    allFolders[folder].contentRoles[group].roleIds.length > 0
  )
}

/**
 * @param {Object} groups all groups used to generate privileges
 * @param {"mst"|"web"} endpointType type of the endpoint containing the folder
 */
export const generateDefaultContentRoles = (groups, endpointType) => {
  const contentRoles = {
    'SYS.approvers': {
      entityType: 'group',
      entityId: groups['SYS.approvers'].id,
      roleIds: ['approver'],
    },
    'SYS.contentadmins': {
      entityType: 'group',
      entityId: groups['SYS.contentadmins'].id,
      roleIds: ['contentadmin'],
    },
    'SYS.creators': {
      entityType: 'group',
      entityId: groups['SYS.creators'].id,
      roleIds: ['creator'],
    },
    'SYS.editors': {
      entityType: 'group',
      entityId: groups['SYS.editors'].id,
      roleIds: ['editor'],
    },
    'SYS.publishers': {
      entityType: 'group',
      entityId: groups['SYS.publishers'].id,
      roleIds: ['publisher'],
    },
    'SYS.traversers': {
      entityType: 'group',
      entityId: groups['SYS.traversers'].id,
      roleIds: ['traverser'],
    },
    'SYS.viewers': {
      entityType: 'group',
      entityId: groups['SYS.viewers'].id,
      roleIds: ['viewer'],
    },
  }

  switch (endpointType) {
    case endpointConstants.MST_ENDPOINT_TYPE:
      contentRoles['SYS.mobileusers'] = {
        entityType: 'group',
        entityId: groups['SYS.mobileusers'].id,
        roleIds: ['mobileuser'],
      }

      break

    case endpointConstants.WEB_ENDPOINT_TYPE:
      contentRoles['SYS.webcontentusers'] = {
        entityType: 'group',
        entityId: groups['SYS.webcontentusers'].id,
        roleIds: ['webcontentuser'],
      }

      break

    default:
      break
  }

  return contentRoles
}

export const getBulkAction = (config) => {
  let func = null
  switch (config.action) {
    case 'selectall':
      func = () => {
        if (config.selectedFilter !== 'ALL') {
          if (
            config.activeFolder &&
            config.selectedAssets.length !==
              config.allFolders[config.activeFolder].assets.filter((asset) => {
                return (
                  config.publishedStatus[config.activeFolder] &&
                  config.publishedStatus[config.activeFolder][asset] &&
                  config.publishedStatus[config.activeFolder][asset].status ===
                    config.selectedFilter
                )
              }).length
          ) {
            config.appActions.selectAssets(
              config.allFolders[config.activeFolder].assets.filter((asset) => {
                return (
                  config.publishedStatus[config.activeFolder] &&
                  config.publishedStatus[config.activeFolder][asset] &&
                  config.publishedStatus[config.activeFolder][asset].status ===
                    config.selectedFilter
                )
              })
            )
          } else {
            config.appActions.clearSelectedAssets()
          }
        } else {
          if (
            config.activeFolder &&
            config.selectedAssets.length !==
              config.allFolders[config.activeFolder].assets.length
          ) {
            config.appActions.selectAssets(
              config.allFolders[config.activeFolder].assets
            )
          } else {
            config.appActions.clearSelectedAssets()
          }
        }
      }
      break
    case 'edit':
      func = () => {
        if (
          config.selectedAssets.reduce((acc, asset) => {
            acc = acc.concat(config.allAssets[asset].publishedTo)
            return acc
          }, []).length > 0
        )
          return Modal.confirm({
            width: 550,
            title: 'Published Asset - Continue?',
            onOk: () => {
              if (config.selectedAssets.length > 1)
                config.modalActions.openModal('BulkEditModal', {
                  assets: config.selectedAssets.map(
                    (asset) => config.allAssets[asset]
                  ),
                  new: false,
                })
              else
                config.modalActions.openModal('EditAssetModal', {
                  asset: config.selectedAssets.map(
                    (asset) => config.allAssets[asset]
                  )[0],
                  new: false,
                })

              config.appActions.clearSelectedAssets()
              Promise.resolve('')
            },
            okText: 'Continue',
            content: (
              <div>
                <div>Edit will automatically display on published assets.</div>
                <AssetSelector
                  assets={config.selectedAssets}
                  allAssets={config.allAssets}
                  allFolders={config.allFolders}
                  updateSelected={(assets) => (config.selectedAssets = assets)}
                />
              </div>
            ),
          })
        else {
          if (config.selectedAssets.length > 1)
            return config.modalActions.openModal('BulkEditModal', {
              assets: config.selectedAssets.map(
                (asset) => config.allAssets[asset]
              ),
              new: false,
            })
          else
            return config.modalActions.openModal('EditAssetModal', {
              asset: config.selectedAssets.map(
                (asset) => config.allAssets[asset]
              )[0],
              new: false,
            })
        }
      }
      break
    case 'delete':
      func = () => {
        if (
          config.activeFolder === folderConstants.UGC_FOLDER ||
          (config.activeFolder &&
            config.allFolders[config.activeFolder].parentId ===
              folderConstants.UGC_FOLDER)
        ) {
          return Promise.all([
            config.assetActions.reviewUgcAssets(
              config.selectedAssets,
              UGC_REJECTED_STATUS,
              config.recycleBin,
              config.history
            ),
            config.appActions.clearSelectedAssets(),
          ])
        }

        if (
          config.selectedAssets.reduce((acc, asset) => {
            acc = acc.concat(config.allAssets[asset].publishedTo)
            return acc
          }, []).length > 0
        ) {
          return Modal.confirm({
            width: 550,
            title: 'Published Asset - Continue?',
            onOk: () => {
              config.assetActions.dropAsset(
                config.recycleBin,
                config.selectedAssets,
                config.activeFolder
              )
              config.appActions.clearSelectedAssets()
              return Promise.resolve('')
            },
            okText: 'Continue',
            content: (
              <div>
                <div>Edit will automatically display on published assets.</div>
                <AssetSelector
                  assets={config.selectedAssets}
                  allAssets={config.allAssets}
                  allFolders={config.allFolders}
                  updateSelected={(assets) => (config.selectedAssets = assets)}
                />
              </div>
            ),
          })
        } else {
          return Promise.all([
            config.assetActions.dropAsset(
              config.recycleBin,
              config.selectedAssets,
              config.activeFolder
            ),
            config.appActions.clearSelectedAssets(),
          ])
        }
      }

      break
    case 'download':
      func = () => config.assetActions.bulkDownload(config.selectedAssets)
      break
    case 'share':
      func = () =>
        config.modalActions.openModal('ShareAssetModal', {
          assets: config.selectedAssets,
          user: config.user,
          title:
            config.selectedAssets.length > 1 ? 'Share Assets' : 'Share Asset',
        })
      break
    case 'move':
      func = () =>
        config.modalActions.openModal('ReviewUGCModal', {
          assets: config.selectedAssets,
          treeRoot: getEndpointById(config.endpoint, config.endpoints)
            .rootFolders,
        })
      break
    case 'publish':
      func = () =>
        config.modalActions.openModal('PublishAssetModal', {
          assets: config.selectedAssets,
          parent: config.activeFolder,
        })
      break
    case 'review':
      func = () =>
        config.saveFormRef(
          Modal.info({
            title: (
              <span>
                Review Assets for Publishing &nbsp;
                <Tooltip
                  title={
                    'Publishing an asset requires approval. A published asset will be visible ' +
                    'to consumers of this endpoint'
                  }
                >
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            ),
            okText: 'Cancel',
            onOk: () => Promise.resolve({}),
            okType: 'default',
            maskClosable: false,
            content: (
              <ReviewAssetForm
                assets={config.selectedAssets}
                reviewFunc={config.endpointActions.reviewAsset}
                statusType={'PENDING_APPROVAL'}
                activeFolder={config.activeFolder}
                allAssets={config.allFolders}
                closeForm={config.closeReviewForm}
              />
            ),
          })
        )

      break
    case 'unpublish':
      func = () => {
        const approveFunc = config.unpublishAndApprove
          ? () =>
              config.endpointActions.unpublishAndApprove(
                config.selectedAssets,
                config.activeFolder
              )
          : null
        config.endpointActions.unpublish(
          config.selectedAssets,
          config.activeFolder,
          () =>
            config.endpointActions.unpublishAsset(
              config.selectedAssets,
              config.activeFolder
            ),
          approveFunc
        )
      }
      break

    case 'unpublish_review':
      func = () =>
        config.saveFormRef(
          Modal.info({
            title: (
              <span>
                Review Assets for Removal &nbsp;
                <Tooltip
                  title={
                    'Removing a published asset requires approval.  Once an asset is removed, it' +
                    ' is no longer visible to consumers of this endpoint'
                  }
                >
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            ),
            okText: 'Cancel',
            onOk: () => Promise.resolve({}),
            okType: 'default',
            maskClosable: false,
            content: (
              <ReviewAssetForm
                assets={config.selectedAssets}
                reviewFunc={config.endpointActions.reviewUnpublishAsset}
                statusType={'PENDING_REMOVAL'}
                activeFolder={config.activeFolder}
                allAssets={config.allAssets}
                closeForm={config.closeReviewForm}
              />
            ),
          })
        )
      break

    default:
      func = null
  }
  return func
}

export const containsPublishedAsset = (
  folder,
  allFolders,
  allAssets,
  relationships
) => {
  if (!allFolders[folder] || !allFolders[folder].assets) {
    return false
  }

  let hasPublishedAsset = false

  allFolders[folder].assets.forEach((asset) => {
    if (assetIsPublished(asset, relationships, allAssets)) {
      hasPublishedAsset = true
    }
  })
  if (hasPublishedAsset) {
    return true
  }
  allFolders[folder].childFolders.forEach((folder) => {
    hasPublishedAsset = containsPublishedAsset(
      folder,
      allFolders,
      allAssets,
      relationships
    )
  })
  return hasPublishedAsset
}

export const getWidestPanel = () => {
  let widest = DEFAULT_SIDEBAR_WIDTH
  const list = Array.from(
    document.getElementsByClassName('ant-collapse-content-box')
  )
  if (list) {
    list.forEach((div) => {
      if (div.offsetWidth > widest) {
        widest = div.offsetWidth
      }
    })
  }
  return widest
}

export const parseBuilderAssetData = (
  builderType,
  builderData,
  allAssets,
  user,
  builderSettings
) => {
  let assetMetaData = {}
  let assetData = {}

  switch (builderType) {
    case 'news':
      assetMetaData = {
        id: builderData.id,
        asset_type: NEWS__BUILDER_TYPE,
        owner: user.id,
        title: builderData.title,
        description: builderData.description,
        tags: builderData.tags,
        icon: 'fa-newspaper-o',
        color: '#22075e',
        preview: null,
      }
      assetData = {
        copyright: builderData.copyright,
        image: builderData.leadImage,
        content:
          builderData.contentType === 'text' ? builderData.textContent : null,
        pdfContent:
          builderData.contentType === 'pdf' ? builderData.pdfContent : null,
      }
      return { assetMetaData, assetData }
    case 'product':
      assetMetaData = {
        id: builderData.id,
        asset_type: PRODUCT__BUILDER_TYPE,
        owner: user.id,
        title: builderData.title,
        description: builderData.description,
        tags: builderData.tags,
        icon: 'fa-cubes',
        color: '#22075e',
        preview: null,
      }
      assetData = {
        image: builderData.leadImage,
        slides: builderData.slides,
      }
      return { assetMetaData, assetData }
    case 'feature':
      assetMetaData = {
        id: builderData.id,
        asset_type: FEATURE__BUILDER_TYPE,
        owner: user.id,
        title: builderData.title,
        description: builderData.description,
        tags: builderData.tags,
        icon: 'fa-bullseye',
        color: '#22075e',
        preview: null,
      }
      assetData = {
        image: builderData.leadImage,
        relatedAssets: builderData.relatedAssets,
      }
      return { assetMetaData, assetData }
    case 'navigator':
      assetMetaData = {
        id: builderData.id,
        asset_type: NAVIGATOR__BUILDER_TYPE,
        owner: user.id,
        title: builderData.title,
        description: builderData.description,
        tags: builderData.tags,
        icon: 'fa-sitemap',
        color: '#22075e',
        preview: null,
      }
      assetData = {
        image: builderData.leadImage,
        slides: builderData.slides,
      }
      return { assetMetaData, assetData }
    case 'comparison':
      assetMetaData = {
        id: builderData.id,
        asset_type: COMPARISON__BUILDER_TYPE,
        owner: user.id,
        title: builderData.title,
        description: builderData.description,
        tags: builderData.tags,
        icon: 'fa-columns',
        color: '#22075e',
        preview: null,
      }
      assetData = {
        image: builderData.leadImage,
        datafileKey: builderData.datafileKey,
        comparisonObject: builderData.comparisonObject,
        prevDatafileKey: builderData.prevDatafileKey || null,
        primaryClassification: builderData.primaryClassification,
        useDefaultDisclaimer: builderSettings.comparisons.allowCustomDisclaimer
          ? builderData.useDefaultDisclaimer
          : true,
        disclaimerText:
          builderSettings.comparisons.allowCustomDisclaimer ||
          builderData.useDefaultDisclaimer
            ? builderData.disclaimerText
            : builderSettings.globalDisclaimer,
      }
      return { assetMetaData, assetData }
    default:
      return null
  }
}

/*
 *  Returns true if arr1 has same items as arr2 (shallow compare)
 */
export const compareFlatArrays = (arr1, arr2) => {
  if (arr1 === arr2) return true
  if (arr1 == null || arr2 == null) return false
  if (arr1.length !== arr2.length) return false
  const [arrayOne, arrayTwo] = [arr1, arr2].map((arr) => arr.slice().sort())
  for (let i = 0; i < arrayOne.length; i++) {
    if (arrayOne[i] !== arrayTwo[i]) return false
  }

  return true
}

/*
 *  Function returns an object with two arrays: added and removed
 *  These arrays contain object Ids whose referencedBy property was impacted
 *  by a builder edit action.
 *  Each builder asset has different references...
 */
export const getChangedReferences = (
  builderType,
  newAsset,
  oldAsset,
  allAssets
) => {
  let result = {
    assetRefUpdates: {
      added: [],
      removed: [],
    },
    folderRefUpdates: {
      added: [],
      removed: [],
    },
  }
  const newRefs = getBuilderRefs(builderType, newAsset, allAssets)
  const oldRefs = getBuilderRefs(builderType, oldAsset, allAssets)

  result.assetRefUpdates.added = newRefs.relatedAssets.filter(
    (ref) => oldRefs.relatedAssets.indexOf(ref) < 0
  )
  result.assetRefUpdates.removed = oldRefs.relatedAssets.filter(
    (ref) => newRefs.relatedAssets.indexOf(ref) < 0
  )
  result.folderRefUpdates.added = newRefs.relatedFolders.filter(
    (ref) => oldRefs.relatedFolders.indexOf(ref) < 0
  )
  result.folderRefUpdates.removed = oldRefs.relatedFolders.filter(
    (ref) => newRefs.relatedFolders.indexOf(ref) < 0
  )
  return result
}

/*
 * Returns an array of asset IDs referenced by a given builder
 * The array only contains direct references - to get a full list, you'll need
 * to check for indirect references
 */
export const getBuilderRefs = (builderType, asset) => {
  let assetRefs = new Set()
  let folderRefs = new Set()
  switch (builderType) {
    case NEWS__BUILDER_TYPE:
      if (asset[builderType].image) assetRefs.add(asset[builderType].image)
      if (asset[builderType].pdfContent)
        assetRefs.add(asset[builderType].pdfContent)
      break

    case FEATURE__BUILDER_TYPE:
      if (asset[builderType].image) assetRefs.add(asset[builderType].image)

      asset[builderType].relatedAssets &&
        asset[builderType].relatedAssets.forEach((rel) => {
          rel.assetId && assetRefs.add(rel.assetId)
        })
      break

    case PRODUCT__BUILDER_TYPE:
      if (asset[builderType].image) assetRefs.add(asset[builderType].image)
      asset[builderType].slides.forEach((slide) => {
        if (slide.mainImage) assetRefs.add(slide.mainImage)

        slide.hotspots.forEach((hotspot) => {
          assetRefs.add(hotspot.assetId)
        })
      })
      break

    case NAVIGATOR__BUILDER_TYPE:
      if (asset[builderType].image) assetRefs.add(asset[builderType].image)
      asset[builderType].slides.forEach((slide) => {
        if (slide.mainImage) assetRefs.add(slide.mainImage)
        slide.navzones.forEach((zone) => {
          if (zone.targetType === 'asset') assetRefs.add(zone.targetId)
          if (zone.targetType === 'folder') folderRefs.add(zone.targetId)
        })
      })
      break

    case COMPARISON__BUILDER_TYPE:
      if (asset[builderType].image) assetRefs.add(asset[builderType].image)
      asset[builderType].comparisonObject &&
        Object.keys(asset[builderType].comparisonObject).forEach((item) => {
          if (
            asset[builderType].comparisonObject[item].meta &&
            (asset[builderType].comparisonObject[item].meta.itemImage ||
              asset[builderType].comparisonObject[item].meta.image)
          )
            assetRefs.add(
              asset[builderType].comparisonObject[item].meta.itemImage ||
                asset[builderType].comparisonObject[item].meta.image
            )
        })
      break

    default:
      break
  }
  return {
    relatedAssets: Array.from(assetRefs),
    relatedFolders: Array.from(folderRefs),
  }
}
/*
 *  Checks for metadata changes and returns an object with new data values for merging
 *  Metadata includes: title, description, tags for all builders
 *  Metadata includes preview for news
 */
export const getChangedMetadata = (
  builderType,
  newAsset,
  oldAsset,
  allAssets,
  userId
) => {
  let updates = {
    updated: Date.now(),
    updated_by: userId,
  }
  if (newAsset.version !== oldAsset.version) updates.version = newAsset.version

  if (newAsset.title !== oldAsset.title) updates.title = newAsset.title

  if (newAsset.description !== oldAsset.description)
    updates.description = newAsset.description

  if (!compareFlatArrays(newAsset.tags, oldAsset.tags))
    updates.tags = newAsset.tags

  if (newAsset[builderType].image !== oldAsset[builderType].image)
    updates.preview = allAssets[newAsset[builderType].image].url

  return updates
}

/*
 *  Checks for changes to the body of a builder asset
 *  Each builder has a different body structure.
 *  If body updates are detected, the builder should be resaved to S3
 */
export const getChangedBuilderData = (builderType, newAsset, oldAsset) => {
  let updates = {
    [builderType]: {},
  }

  if (builderType === NEWS__BUILDER_TYPE) {
    if (newAsset[builderType].content !== oldAsset[builderType].content)
      updates[builderType].content = newAsset[builderType].content
    if (newAsset[builderType].image !== oldAsset[builderType].image)
      updates[builderType].image = newAsset[builderType].image
    if (newAsset[builderType].copyright !== oldAsset[builderType].copyright)
      updates[builderType].copyright = newAsset[builderType].copyright
    if (newAsset[builderType].pdfContent !== oldAsset[builderType].pdfContent)
      updates[builderType].pdfContent = newAsset[builderType].pdfContent
  }

  if (builderType === FEATURE__BUILDER_TYPE) {
    if (newAsset[builderType].image !== oldAsset[builderType].image)
      updates[builderType].image = newAsset[builderType].image
    if (
      JSON.stringify(newAsset[builderType].relatedAssets) !==
      JSON.stringify(oldAsset[builderType].relatedAssets)
    )
      updates[builderType].relatedAssets = newAsset[builderType].relatedAssets
  }

  if (
    builderType === PRODUCT__BUILDER_TYPE ||
    builderType === NAVIGATOR__BUILDER_TYPE
  ) {
    if (newAsset[builderType].image !== oldAsset[builderType].image)
      updates[builderType].image = newAsset[builderType].image
    //Todo - deep equal compare slides
    updates[builderType].slides = newAsset[builderType].slides
  }

  if (builderType === COMPARISON__BUILDER_TYPE) {
    if (newAsset[builderType].image !== oldAsset[builderType].image)
      updates[builderType].image = newAsset[builderType].image

    if (newAsset[builderType].datafileKey !== oldAsset[builderType].datafileKey)
      updates[builderType].datafileKey = newAsset[builderType].datafileKey

    if (
      newAsset[builderType].prevDatafileKey !==
      oldAsset[builderType].prevDatafileKey
    )
      updates[builderType].prevDatafileKey =
        newAsset[builderType].prevDatafileKey

    if (
      newAsset[builderType].primaryClassification !==
      oldAsset[builderType].primaryClassification
    )
      updates[builderType].primaryClassification =
        newAsset[builderType].primaryClassification

    if (
      newAsset[builderType].useDefaultDisclaimer !==
      oldAsset[builderType].useDefaultDisclaimer
    )
      updates[builderType].useDefaultDisclaimer =
        newAsset[builderType].useDefaultDisclaimer

    if (
      newAsset[builderType].disclaimerText !==
      oldAsset[builderType].disclaimerText
    )
      updates[builderType].disclaimerText = newAsset[builderType].disclaimerText

    if (
      !isEqual(
        newAsset[builderType].comparisonObject,
        oldAsset[builderType].comparisonObject || {}
      )
    )
      updates[builderType].comparisonObject =
        newAsset[builderType].comparisonObject
  }

  return updates
}

/*
 *  Builds an array of event objects to save with the action
 *  These events show:
 *   who: the user that made the change
 *   what: the attributes that were changed
 *   where: the asset id that was changed
 *   when: The timestamp of the change
 *   how: the action the caused the change
 */
export const generateBuilderEvents = (
  builderId,
  builderType,
  refUpdates,
  metadataUpdates,
  bodyUpdates,
  userId,
  originalData
) => {
  let events = []
  let timestamp = Date.now()
  const builderEvent = {
    event_type: 'UPDATE_ASSET',
    target_id: builderId,
    timestamp,
    user: userId,
    attributes: [],
  }
  Object.keys(metadataUpdates).forEach((key) => {
    timestamp = timestamp + 1
    builderEvent.attributes.push({
      name: key,
      new_value: metadataUpdates[key],
      old_value: originalData[key],
    })
  })

  Object.keys(bodyUpdates[builderType]).forEach((key) => {
    builderEvent.attributes.push({
      name: key,
      new_value: bodyUpdates[builderType][key],
      old_value: originalData[builderType][key],
    })
  })
  events.push(builderEvent)

  refUpdates.assetRefUpdates.added.forEach((ref) => {
    timestamp = timestamp + 1
    events.push({
      event_type: 'UPDATE_ASSET',
      target_id: ref,
      timestamp,
      user: userId,
      attributes: [
        {
          name: 'referencedBy',
          new_value: ref,
          old_value: '',
        },
      ],
    })
  })
  refUpdates.assetRefUpdates.removed.forEach((ref) => {
    timestamp = timestamp + 1
    events.push({
      event_type: 'UPDATE_ASSET',
      target_id: ref,
      timestamp,
      user: userId,
      attributes: [
        {
          name: 'referencedBy',
          new_value: ref,
          old_value: '',
        },
      ],
    })
  })
  refUpdates.folderRefUpdates.added.forEach((ref) => {
    timestamp = timestamp + 1
    events.push({
      event_type: 'UPDATE_ASSET',
      target_id: ref,
      timestamp,
      user: userId,
      attributes: [
        {
          name: 'referencedBy',
          new_value: ref,
          old_value: '',
        },
      ],
    })
  })
  refUpdates.folderRefUpdates.removed.forEach((ref) => {
    timestamp = timestamp + 1
    events.push({
      event_type: 'UPDATE_ASSET',
      target_id: ref,
      timestamp,
      user: userId,
      attributes: [
        {
          name: 'referencedBy',
          new_value: ref,
          old_value: '',
        },
      ],
    })
  })
  return events
}
/*
 *  Find first folder that contains a given asset
 */
export const findLibraryFolder = (asset, allFolders, endpoints) => {
  let firstFolder = null
  Object.keys(allFolders).forEach((folder) => {
    if (
      allFolders[folder].assets.indexOf(asset) > -1 &&
      getEndpointOfNode(allFolders[folder], allFolders, endpoints).id === 'LIB'
    )
      firstFolder = folder
  })
  return firstFolder
}

export const scrollElement = (
  itemRef,
  element,
  start,
  change,
  duration = 20,
  direction = 'horizontal'
) => {
  const increment = 20
  const animateScroll = (elapsedTime) => {
    elapsedTime += increment
    if (direction === 'vertical')
      element.scrollTop = easeInOut(elapsedTime, start, change, duration)
    else element.scrollLeft = easeInOut(elapsedTime, start, change, duration)

    if (elapsedTime < duration) {
      setTimeout(function () {
        animateScroll(elapsedTime)
      }, increment)
    } else {
      itemRef.scrolling = false
    }
  }

  animateScroll(0)
}

function easeInOut(currentTime, start, change, duration) {
  currentTime /= duration / 2
  if (currentTime < 1) {
    return (change / 2) * currentTime * currentTime + start
  }
  currentTime -= 1
  return (-change / 2) * (currentTime * (currentTime - 2) - 1) + start
}

export const assetIsPublished = (
  assetId,
  relationships,
  allAssets,
  visited = []
) => {
  let isPublished = false
  if (allAssets[assetId] && allAssets[assetId].publishedTo.length > 0) {
    return true
  }
  visited.push(assetId)
  relationships.referencedBy[assetId] &&
    relationships.referencedBy[assetId].forEach((ref) => {
      if (!isPublished && visited.indexOf(ref) < 0)
        isPublished = assetIsPublished(ref, relationships, allAssets, visited)
    })
  return isPublished
}

export const assetIsReferenced = (assetId, relationships) => {
  let references = false
  if (
    !references &&
    relationships.referencedBy[assetId] &&
    relationships.referencedBy[assetId].length > 0
  )
    references = true
  return references
}

// This function will check if a folder has any assets that are referenced by other assets in
// a different folder
// It is used for validating whether a folder can be deleted by drag-n-drop
export const folderContainsReferencedAssets = (
  folder,
  relationships,
  allFolders,
  endpoints
) => {
  let references = false
  let parent
  allFolders[folder].assets.forEach((asset) => {
    parent = findLibraryFolder(asset, allFolders, endpoints)
    if (!references && relationships.referencedBy[asset]) {
      relationships.referencedBy[asset].forEach((ref) => {
        if (
          !references &&
          findLibraryFolder(ref, allFolders, endpoints) !== parent
        ) {
          references = true
        }
      })
    }
  })
  allFolders[folder].childFolders.forEach((child) => {
    if (!references)
      references = folderContainsReferencedAssets(
        child,
        relationships,
        allFolders,
        endpoints
      )
  })

  return references
}

// This function will check if a folder or any of its children is referenced by any builder
// assets (i.e. Navigators)
// It is used for validating whether a folder can be deleted
export const folderContainsReferencedFolders = (
  folder,
  allFolders,
  relationships
) => {
  if (
    relationships.referencedBy[folder] &&
    relationships.referencedBy[folder].length > 0
  )
    return true

  let hasRefs = false
  allFolders[folder].childFolders.forEach((child) => {
    if (!hasRefs)
      hasRefs = folderContainsReferencedFolders(
        child,
        allFolders,
        relationships
      )
  })

  return hasRefs
}

export const copySpanToClipboard = (spanId) => {
  var copyText = document.getElementById(spanId)
  var textArea = document.createElement('textarea')
  textArea.value = copyText.textContent
  textArea.setAttribute('readonly', '')
  textArea.style.position = 'absolute'
  textArea.style.left = '-9999px'
  document.body.appendChild(textArea)
  textArea.select()
  document.execCommand('Copy')
  textArea.remove()
}

/*
 *  This function determines whether a folder needs the traverser role for a given entity.
 *  Ignore folder allows you to ignore a given child folder - useful for validating the parent
 *  tree during drag and drop, when the folder move is not yet completed.
 *  Alternate content roles are used by bulk permissions updates where changes are not yet saved
 *  to redux state
 */
export const requiresTraverserRole = (
  folder,
  entity,
  allFolders,
  ignoreFolder = null,
  alternateContentRoles = null
) => {
  let required = false
  if (entity === 'SYS.traversers') return true

  if (
    allFolders[folder].childFolders &&
    allFolders[folder].childFolders.length === 0
  )
    return false

  allFolders[folder].childFolders
    .filter((child) => child !== ignoreFolder)
    .forEach((child) => {
      // Check child for a non-traverser roles
      if (!required) {
        if (alternateContentRoles)
          required =
            alternateContentRoles[child][entity] &&
            alternateContentRoles[child][entity].roleIds.filter(
              (role) => role !== 'traverser'
            ).length > 0
        else
          required =
            allFolders[child].contentRoles[entity] &&
            allFolders[child].contentRoles[entity].roleIds.filter(
              (role) => role !== 'traverser'
            ).length > 0
      }
      // Check child subfolders for non-traverser role
      if (!required)
        required = requiresTraverserRole(
          child,
          entity,
          allFolders,
          ignoreFolder,
          alternateContentRoles
        )
    })
  return required
}

export const assetIsUsedAsImage = (asset, allAssets, relationships) => {
  if (!asset) return false

  let isImage = false
  relationships.referencedBy[asset] &&
    relationships.referencedBy[asset].forEach((builder) => {
      if (
        !isImage &&
        allAssets[builder] &&
        allAssets[builder][allAssets[builder].asset_type].image === asset
      )
        isImage = true

      if (
        !isImage &&
        allAssets[builder] &&
        allAssets[builder].asset_type === COMPARISON__BUILDER_TYPE
      )
        isImage = true

      if (
        !isImage &&
        allAssets[builder] &&
        BUILDERS_WITH_SLIDES.indexOf(
          allAssets[builder][allAssets[builder].asset_type]
        ) > -1
      ) {
        allAssets[builder][allAssets[builder].asset_type].slides.forEach(
          (slide) => {
            if (slide.mainImage === asset) {
              isImage = true
            }
          }
        )
      }
    })

  return isImage
}

export const getUserById = (id, allUsers) => {
  return Object.values(allUsers).find((u) => u.id === id)
}

export const requiredFieldsArePopulated = (requiredFields, values, form) => {
  let populated = true
  requiredFields.forEach((field) => {
    if (typeof values[field] === 'string') values[field] = values[field].trim()

    if (!values[field]) {
      populated = false
      form.setFields({
        [field]: { errors: [new Error(`${field} is required`)] },
      })
    }
  })
  return populated
}

export const getOriginalAssetPreview = (asset) => {
  switch (asset.icon) {
    case 'fa-file-pdf-o':
      return `${asset.key}.${PDF_IMAGE_FILE_TYPE}`
    case 'fa-file-video-o':
      return `${asset.key}-thumbnail.0000000.jpg`
    default:
      return null
  }
}

/*
 * Description
 * TODO
 * @param    ailTargets      {[string]}          -   List of asset ids that we want to search for
 * @param    eilTargets      {[string]}          -   List of endpoints that we want to search
 *                                                   through
 * @param    adictAll        {assetsById}        -   All assets in state, by Id
 * @param    fdictAll        {foldersById}       -   All folders in state, by Id
 * @param    eolAll          {[endpointObj]}     -   All endpoints in state, collection
 * @param    fasdictAll      {folderAssetStatus} -   All Folder Asset Statuses in
 *                                                   endpoints.folderAssetStatus
 * @param    validAssetStatus {[sting]}          -   Asset status that the assets must be in to
 *                                                   be included in output.
 *
 * Example Input
 * {
 *    ailTargets: [a1, a2, a3],
 *    eilTargets: [e1, e2],
 *    adictAll:   {...a1: {a1StateData}, a2: {a2StateData}, a3: {a3StateData}, ...},
 *    fdictAll:   {...f1: {f1StateData}, f2: {f2StateData} ...},
 *    eolAll:     [{e1StateData}, {e2StateData}, ...}]
 *    fasdictAll: {...f1: {f1AssetStatusData}, f2: {f2AssetStatusData}, ...}
 *
 * Example Output
 * [
 *	{
 *		folderId: f1
 *		assetIds: [a1, a2, a3]
 *	},
 *	{
 *		folderId: f2
 *		assetIds: [a1, a3]
 *	},
 *   ...
 * ]
 */
export const createFolderToAssetsCollection = ({
  validAssetStatus,
  fasdictAll,
  ailTargets,
  eilTargets,
  adictAll,
  fdictAll,
  eolAll,
}) => {
  let parentToAssetsCollection = []

  for (let ai of ailTargets) {
    const filPublishedTo = adictAll[ai].publishedTo

    for (let fi of filPublishedTo) {
      // Skip if this folder is not a child of an endpoint that is in eilTargets
      const foPublishedTo = fdictAll[fi]
      const eoForFolder = getEndpointOfNode(foPublishedTo, fdictAll, eolAll)
      if (!eilTargets.includes(eoForFolder.id)) continue

      // Skip if this asset status is not one of the valid ones.
      const targetFolderAssetStatus = get(
        fasdictAll,
        `[${fi}].[${ai}].status`,
        null
      )
      if (!validAssetStatus.includes(targetFolderAssetStatus)) continue

      // Add this parent: asset combination to the parentToAssetsCollection
      const targetParent = { parent: fi }
      const targetParentObject = find(parentToAssetsCollection, targetParent)

      if (!targetParentObject) {
        parentToAssetsCollection = [
          ...parentToAssetsCollection,
          {
            ...targetParent,
            assets: [ai],
          },
        ]
      } else {
        parentToAssetsCollection = [
          ...reject(parentToAssetsCollection, targetParent),
          {
            ...targetParent,
            assets: [...targetParentObject.assets, ai],
          },
        ]
      }
    }
  }

  return parentToAssetsCollection
}

/*
 * Description
 * TODO
 * @param    type       {string}     -   Specifies which type of message it is, to determine
 *                                       the message text.
 * @param    success    {boolean}    -   Show a success message. Displays an error message if
 *                                       not truthy.
 * @param    params     {object}     -   All of the rest of the options that the message needs
 *                                       for display.
 *                                       ( assets, allAssets, time )
 *
 * Example Input
 * {
 *    type: "UNPUBLISH_WEBENDPOINT_ASSET",
 *    success: true,
 *    params: {
 *        assets: [ <assetIdList> ],
 *        allAssets: { <allAssetsById> },
 *        time: 4
 *    }
 * }
 *
 * time should be specified in units of seconds
 *
 */
export const displayToast = ({ type, success, params }) => {
  const {
    assets,
    allAssets,
    time = 3, // Seconds
  } = params

  if (!type) return

  switch (type) {
    case 'PUBLISH_WEBENDPOINT_ASSET':
      if (success) {
        return assets.length > 1
          ? message.success(
              `${assets.length} assets have been publsihed to web endpoints`,
              time
            )
          : message.success(
              <span>
                {assetName(allAssets[assets[0]])} has been published to web
                endpoints
              </span>,
              time
            )
      } else {
        return assets.length > 1
          ? message.error(
              `${assets.length} assets could not be published to web endpoints`,
              time
            )
          : message.error(
              'This asset could not be published to all web endpoints',
              time
            )
      }
    case 'UNPUBLISH_WEBENDPOINT_ASSET':
      if (success) {
        return assets.length > 1
          ? message.success(
              `${assets.length} assets have been removed from all web endpoint folders`,
              time
            )
          : message.success(
              <span>
                {assetName(allAssets[assets[0]])} has been removed from all web
                endpoint folders
              </span>,
              time
            )
      } else {
        return assets.length > 1
          ? message.error(
              `${assets.length} assets could not be unpublished from all web endpoint folders`,
              time
            )
          : message.error(
              'This asset could not be unpublished from all web endpoint folders',
              time
            )
      }
    default:
      console.warn('Unhandled displayToast type', type)
      return
  }
}

/**
 * Converts a React element to its simple text content
 */
export function reactNodeToText(node) {
  if (typeof node === 'object') {
    if (React.isValidElement(node)) {
      return reactNodeToText(node.props.children)
    }

    return ''
  }

  return node
}
