import { Checkbox, Form, Input } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { getAllFolders } from '../../dux/reducers/folder'

const mapState = (state) => ({
  allFolders: getAllFolders(state),
})

class PublishAssetNotificationForm extends React.PureComponent {
  applySubstitutions = (value = '%F has been published to %T') => {
    const { allFolders, selectedAssets, selectedFolder } = this.props
    const { filename, title } = selectedAssets[0]
    const { name } = allFolders[selectedFolder] || {}

    return value.replace(/%F/g, title || filename).replace(/%T/g, name || '%T')
  }

  render() {
    const {
      form: { getFieldDecorator, getFieldValue, isFieldTouched },
      selectedAssets,
    } = this.props
    const enabled = isFieldTouched('enabled') && getFieldValue('enabled')
    const messageTemplate = selectedAssets.length > 1

    return (
      <Form layout="vertical">
        <Form.Item style={{ margin: '10px 0 0 0' }}>
          {getFieldDecorator('enabled', {
            initialValue: false,
            valuePropName: 'checked',
          })(
            <Checkbox>
              Notify mobile device users with the following message
            </Checkbox>
          )}
        </Form.Item>

        {enabled && (
          <Form.Item
            help={
              messageTemplate && (
                <div>
                  <p>
                    For example:
                    <br />
                    {this.applySubstitutions(getFieldValue('message'))}
                  </p>
                  <span>Message template variables:</span>
                  <ul>
                    <li>%F - asset filename</li>
                    <li>%T - name of destination folder</li>
                  </ul>
                </div>
              )
            }
            style={{ marginBottom: 0 }}
          >
            {getFieldDecorator('message', {
              initialValue: messageTemplate
                ? '%F has been published to %T'
                : this.applySubstitutions(),
              validateTrigger: 'onChange',
              rules: [{ required: true, message: 'A message is required.' }],
            })(<Input />)}
          </Form.Item>
        )}
      </Form>
    )
  }
}

export default connect(mapState)(Form.create()(PublishAssetNotificationForm))
