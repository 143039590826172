import update from 'immutability-helper'
import React, { Fragment } from 'react'

export const builderPreviewText = {
  news: {
    title: 'News',
    subtitle:
      'The News Builder creates a News asset that allows users to read press releases and ' +
      'announcements.',
    description: 'A News asset may be uploaded to a mobile endpoint.',
    caption:
      'In a future release, this pane will display a preview of your builder as it is being ' +
      'constructed.',
  },
  feature: {
    title: 'Feature',
    subtitle:
      'The Feature Builder creates a Feature asset, combining a single image asset with a ' +
      'description and related assets.',
    description:
      'The Feature is used within a Product or Visual Navigation asset to provide detailed ' +
      'information about specific aspects of the corresponding product. A Feature asset may be ' +
      'uploaded to a mobile endpoint, and may only be used within a Navigator or a Product Asset.',
    caption:
      'In a future release, this pane will display a preview of your builder as it is being ' +
      'constructed.',
  },
  product: {
    title: 'Product',
    subtitle:
      'The Product Builder creates a Product asset, combining a series of image assets with ' +
      'Feature assets to produce a comprehensive and informative product walkaround.',
    description: 'A Product asset may be uploaded to a mobile Endpoint',
    caption:
      'In a future release, this pane will display a preview of your builder as it is being ' +
      'constructed.',
  },
  navigator: {
    title: 'Navigator',
    subtitle:
      'The Navigator Builder creates an intuitive way to explore Product assets and Feature ' +
      'assets.',
    description: 'A Navigator asset may be uploaded to a mobile Endpoint',
    caption:
      'In a future release, this pane will display a preview of your builder as it is being ' +
      'constructed.',
  },
  comparison: {
    title: 'Comparison',
    subtitle:
      'The Comparison Builder creates a Comparison asset that allows users to compare ' +
      'specifications of multiple products.',
    description: 'A Comparison asset may be uploaded to a mobile Endpoint',
    caption: 'Caption is overridden for comparisons',
  },
}

export const builderHeaderData = {
  news: {
    linkTo: '/builders/home',
    linkText: 'Create',
    crumbText: 'News',
    crumbIcon: 'fa fa-newspaper-o',
  },
  feature: {
    linkTo: '/builders/home',
    linkText: 'Create',
    crumbText: 'Feature',
    crumbIcon: 'fa fa-bullseye',
  },
  product: {
    linkTo: '/builders/home',
    linkText: 'Create',
    crumbText: 'Product',
    crumbIcon: 'fa fa-cubes',
  },
  navigator: {
    linkTo: '/builders/home',
    linkText: 'Create',
    crumbText: 'Navigator',
    crumbIcon: 'fa fa-sitemap',
  },
  comparison: {
    linkTo: '/builders/home',
    linkText: 'Create',
    crumbText: 'Comparison',
    crumbIcon: 'fa fa-columns',
  },
}

export const iconForBuilder = (builderType) => {
  switch (builderType) {
    case 'feature':
      return 'fa-bullseye'
    case 'news':
      return 'fa-newspaper-o'
    case 'product':
      return 'fa-cubes'
    case 'navigator':
      return 'fa-sitemap'
    case 'comparison':
      return 'fa-columns'
    default:
      return 'fa-file-o'
  }
}

export const callbackForBuilderSection = (_this, builderSection) => {
  switch (builderSection) {
    case 'leadImage':
      return _this.addLeadImage
    case 'pdfContent':
      return _this.addPdfContent
    case 'relatedAsset':
      return _this.addRelatedAsset
    case 'editRelatedAsset':
      return _this.editRelatedAssetCallback
    case 'addSlideAsset':
      return _this.addSlideCallback
    case 'replaceSlideAsset':
      return _this.replaceSlideCallback
    case 'addHotspot':
      return _this.addHotspotCallback
    case 'editHotspot':
      return _this.editHotspotCallback
    case 'addZone':
    case 'editZone':
      return _this.addZoneCallback
    case 'addAssetTarget':
      return _this.addAssetTarget
    case 'addFolderTarget':
      return _this.addFolderTarget
    case 'setComparisonImage':
      return _this.setComparisonImage
    default:
      return _this.handleFormValuesChange
  }
}

export const modalTitleForBuilderSection = (builderSection) => {
  switch (builderSection) {
    case 'leadImage':
      return 'Lead Image'
    case 'pdfContent':
      return 'News PDF'
    case 'relatedAsset':
      return 'Related Asset'
    case 'editRelatedAsset':
      return 'Related Asset'
    case 'addSlideAsset':
      return 'Add Slide Asset'
    case 'replaceSlideAsset':
      return 'Replace Slide Asset'
    case 'addHotspot':
      return 'Add Hotspot'
    case 'editHotspot':
      return 'Edit Hotspot'
    case 'addZone':
      return 'Add Nav Zone'
    case 'addAssetTarget':
      return 'Select Asset Target'
    case 'addFolderTarget':
      return 'Select Folder Target'
    case 'editZone':
      return 'Edit Nav Zone'
    case 'setComparisonImage':
      return 'Select Comparison Item Image'
    default:
      return 'Default Select'
  }
}

export const getNavzoneParentSlide = (navzoneId, slides) => {
  let parentId = null
  slides.forEach((slide) => {
    slide.navzones.forEach((zone) => {
      if (zone.id === navzoneId) parentId = slide.id
    })
  })
  return parentId
}

export const getZonesForTarget = (targetId, slides) => {
  let targets = []
  slides.forEach((slide) => {
    slide.navzones.forEach((zone) => {
      if (zone.targetId === targetId) targets.push(zone.id)
    })
  })
  return targets
}

export const prepComparisonDataForTable = (
  columns,
  dataSource,
  allAssets,
  action,
  deleteAction,
  isComparisonRowEditing
) => {
  columns.forEach((column) => {
    if (column.key === 'attribute') {
      column.title = (
        <div className="comparison__table-col-hdr-txt">{column.title}</div>
      )
      column.width = 200
    } else if (column.key === 'category') {
      column.title = (
        <div className="comparison__table-col-hdr-txt">{column.title}</div>
      )
      column.width = 200
      column.onCell = (record) => ({
        record,
        dataIndex: column.dataIndex,
        editing: isComparisonRowEditing(record.attribute),
        disabled: record.category === 'meta',
      })
    } else if (
      column.image &&
      allAssets[column.image] &&
      allAssets[column.image].preview
    ) {
      column.onCell = (record) => ({
        record,
        dataIndex: column.dataIndex,
        editing: isComparisonRowEditing(record.attribute),
      })
      column.width = 200
      column.title = (
        <Fragment>
          <div className="comparison__items-group">
            <div className="comparison__item">
              <div
                style={{
                  boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.15)',
                  width: 165,
                  height: 123,
                  border: '1px solid #d8d8d8',
                  backgroundImage: `url(${
                    allAssets[column.image].preview
                  }_thumb-165x)`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                }}
              >
                <div className="comparison__item-action">
                  <div className="comparison__item-action-icon">
                    <i
                      className="fa fa-trash comparison__item-icon"
                      onClick={() => deleteAction(column.dataIndex)}
                    />
                    <i
                      className="fa fa-edit comparison__item-icon"
                      onClick={() =>
                        action('setComparisonImage', 'image', {
                          itemName: column.dataIndex,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="comparison__table-col-hdr-txt">{column.key}</div>
        </Fragment>
      )
    } else if (column.key !== 'attribute') {
      column.onCell = (record) => ({
        record,
        dataIndex: column.dataIndex,
        editing: isComparisonRowEditing(record.attribute),
      })
      column.width = 200
      column.title = (
        <Fragment>
          <div className="comparison__items-group">
            <div className="comparison__item-blank">
              <span className="comparison__item-blank-text">
                There is nothing here. Yet.
              </span>
              <div
                className="comparison__item-action-blank"
                onClick={() =>
                  action('setComparisonImage', 'image', {
                    itemName: column.dataIndex,
                  })
                }
              >
                <i className="fa fa-plus-circle" />
                &nbsp; Asset
              </div>
            </div>
          </div>
          <div className="comparison__table-col-hdr-txt">{column.key}</div>
        </Fragment>
      )
    }
  })
  // Sort by category, then attribute (meta category always first)
  dataSource.sort((a, b) => {
    if (a.category !== b.category) {
      if (a.category === 'meta') {
        return -1
      }
      if (b.category === 'meta') {
        return 1
      }
      return (a.category || '').localeCompare(b.category)
    }

    return a.attribute.localeCompare(b.attribute)
  })

  return {
    columns,
    dataSource,
  }
}

export const convertTableToTSV = (tableData) => {
  let TSVData = []
  let itemName = ['itemName', 'meta']
  let itemImage = ['itemImage', 'meta']
  tableData.columns.forEach((column) => {
    if (column.dataIndex !== 'attribute' && column.dataIndex !== 'category') {
      itemName.push(column.key)
      itemImage.push(column.image || null)
    }
  })
  TSVData.push(itemName.join('\t'), itemImage.join('\t'))
  tableData.dataSource.forEach((row) => {
    let dataRow = []
    dataRow.push(row.attribute, row.category || null)
    itemName.forEach((item, index) => {
      if (index > 1) {
        dataRow.push(row[item])
      }
    })
    TSVData.push(dataRow.join('\t'))
  })
  return TSVData.join('\r')
}

export const convertComparisonObjectToTable = (comparisonObject) => {
  let classifications = []
  let dataSource = []
  let dataSourceKey = 2 // For consistency with lambda parsing
  let columns = [
    {
      title: 'Attribute',
      dataIndex: 'attribute',
      key: 'attribute',
      editable: true,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      editable: true,
    },
  ]
  const keys = Object.keys(comparisonObject).sort((a, b) => a.localeCompare(b))
  keys.forEach((item, index) => {
    columns.push({
      editable: true,
      title: item,
      dataIndex: item,
      key: item,
      image:
        comparisonObject[item].meta.itemImage ||
        comparisonObject[item].meta.image,
    })
    // Initialize dataSource
    if (index === 0) {
      dataSource.push({
        key: dataSourceKey,
        attribute: 'classification',
        category: 'meta',
      })
      Object.keys(comparisonObject[item]).forEach((category) => {
        if (category !== 'meta') {
          Object.keys(comparisonObject[item][category]).forEach((attribute) => {
            dataSourceKey = dataSourceKey + 1
            dataSource.push({
              key: dataSourceKey,
              attribute: attribute,
              category: category,
              editable: true,
            })
          })
        }
      })
    }
  })
  // add rows to data source
  dataSource.forEach((row) => {
    keys.forEach((item) => {
      if (
        row.attribute.toLowerCase() === 'classification' &&
        comparisonObject[item][row.category][row.attribute] !== '' &&
        classifications.indexOf(
          comparisonObject[item][row.category][row.attribute]
        ) < 0
      ) {
        classifications.push(
          comparisonObject[item][row.category][row.attribute]
        )
      }
      row[item] = comparisonObject[item][row.category][row.attribute]
    })
  })

  // Sort by category, then attribute (meta category always first)
  dataSource.sort((a, b) => {
    if (a.category !== b.category) {
      if (a.category === 'meta') {
        return -1
      }
      if (b.category === 'meta') {
        return 1
      }
      return (a.category || '').localeCompare(b.category)
    }

    return a.attribute.localeCompare(b.attribute)
  })
  return {
    columns,
    dataSource,
    classifications,
  }
}

export const applyUpdatesToComparisonObject = (
  original,
  newValues,
  oldValues
) => {
  let newObject = { ...original }
  const items = Object.keys(original)
  items.forEach((item) => {
    if (
      oldValues.category !== newValues.category &&
      oldValues.category !== 'meta'
    ) {
      newObject = update(newObject, {
        [item]: {
          // Copy new category from original object (if it exists)
          [newValues.category]: {
            $set: Object.assign({}, original[item][newValues.category], {
              [newValues[oldValues.attribute]]: newValues[item],
            }),
          },
          // delete the previous attribute
          [oldValues.category]: {
            $unset: [oldValues.attribute],
          },
        },
      })
      //there are no attributes under the old category, delete it
      if (Object.keys(newObject[item][oldValues.category]).length === 0) {
        newObject = update(newObject, {
          [item]: {
            $unset: [oldValues.category],
          },
        })
      }
    }
    // If the attribute name changed, update the attribute (make sure category still exists)
    if (
      oldValues.category !== 'meta' &&
      original[item][newValues.category] &&
      !original[item][newValues.category][newValues[oldValues.attribute]]
    ) {
      newObject = update(newObject, {
        [item]: {
          [newValues.category]: {
            $unset: [oldValues.attribute],
          },
        },
      })
    }
    // Set the item value
    if (
      oldValues.category !== 'meta' ||
      (oldValues.category === 'meta' &&
        oldValues.attribute === newValues[oldValues.attribute])
    ) {
      newObject = update(newObject, {
        [item]: {
          [newValues.category]: {
            [newValues[oldValues.attribute]]: {
              $set: newValues[item],
            },
          },
        },
      })
    }
  })
  return newObject
}
