import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActions from '../../dux/actions/appActions'
import * as assetActions from '../../dux/actions/asset.js'
import * as folderActions from '../../dux/actions/folder.js'
import * as homeActions from '../../dux/actions/homeActions'
import * as modalActions from '../../dux/actions/modal'
import { UGC_FOLDER } from '../../dux/constants/folder'
import {
  getEndpointById,
  getFolderPendingCount,
  getRolledUpFolderPendingCount,
  getRootOfNode,
  getWidestPanel,
  isChild,
  validatePrivilege,
} from '../../dux/modules/utils'
import SidebarContent from './SidebarContent'

import '../styles/Sidebar.css'

class Sidebar extends React.Component {
  componentDidMount = () => {
    window.setTimeout(this.setSidebarWidth, 50)
  }

  onCollapseChange = (keys) => {
    this.props.appActions.setOpenEndpointKeys(keys)
    window.setTimeout(this.setSidebarWidth, 50)
  }

  setSidebarWidth = () => {
    this.props.appActions.setMaxSidebarWidth(getWidestPanel())
  }

  /*
   * Only change active folder and scroll to top if
   * folder is changed.
   */
  onNodeClick = (id, endpoint, preserveStatusFilter = false) => {
    if (this.props.folders.activeFolder !== id) {
      this.props.folderActions.activeFolder(id)
    }

    if (!preserveStatusFilter && this.props.selectedFilter !== 'ALL')
      this.props.appActions.applyStatusFilter('ALL')

    if (endpoint && this.props.activeEndpoint !== endpoint)
      this.props.appActions.setActiveEndpoint(endpoint)

    if (this.props.menuItem !== 'library' && this.props.menuItem !== 'builders')
      this.props.appActions.setMenuItem('library')
  }

  /*
   * Expand the panel if it's not expanded; Launch AddFolderModal passing
   *  appropriate type of folder to create
   */
  onPanelHeaderIconClick = (e) => {
    e.stopPropagation()
    const key = e.target.id
    const { activeFolder, byId } = this.props.folders

    if (this.props.openEndpointKeys.indexOf(key) < 0) {
      this.props.appActions.addOpenEndpointKey(key)
    }

    if (key === 'HOME')
      this.props.modalActions.openModal('ConfigUserEndpointModal')
    else {
      const endpoint = getEndpointById(key, this.props.endpoints.endpoints)

      const treeRoot = endpoint.rootFolders
      if (treeRoot.indexOf(getRootOfNode(byId[activeFolder], byId)) < 0) {
        this.props.folderActions.activeFolder(treeRoot[0])
      }

      this.props.modalActions.openModal('AddFolderModal', {
        treeRoot,
        onToggle: this.toggleFolder,
        onNodeClick: this.onNodeClick,
        endpointType: endpoint.type,
      })
    }
  }

  handleRenameFolder = (e, id) => {
    if (e.keyCode === 13 || e.which === 13 || e.nativeEvent.type === 'blur') {
      this.props.folderActions.renameFolder(e.target.value, id)
      this.props.appActions.setRenamingFolder()
    }
  }

  toggleFolder = (id) => {
    if (this.props.openFolderKeys.indexOf(id) < 0) {
      this.props.appActions.addOpenFolderKey(id)
    } else {
      this.props.appActions.removeOpenFolderKey(id)
    }
    window.setTimeout(this.setSidebarWidth, 50)
  }

  nodeIsOpen = (id) => this.props.openFolderKeys.indexOf(id) > -1

  nodeIsActive = (id) => this.props.folders.activeFolder === id

  /*
   * A node is draggable if it is in the library, and not a root node
   */
  nodeIsDraggable = () => {
    return true
  }

  nodeIsDisabled = (id) => {
    if (this.props.menuItem === 'builders') {
      if (
        id === '_search' ||
        id === this.props.folders.recycleBin ||
        isChild(id, this.props.folders.recycleBin, this.props.folders.byId)
      ) {
        return true
      }
      return !validatePrivilege(
        'createnews',
        this.props.permissions.privileges,
        this.props.user.email,
        this.props.permissions.groups,
        id,
        this.props.folders.byId
      )
    }
    return false
  }

  nodeLink = (id) => `/library/${id}`

  setActiveFolder = (id) => {
    this.props.folderActions.activeFolder(id)
  }

  // Returns the number of pending items in the given folder; if the folder is open, only folder
  // contents are tested, If the folder is closed, contents of all subfolders are tested too.
  getBadgeCount = (id, type, rolledUp = true) => {
    const {
      endpoints: { folderAssetStatus },
      folders: { byId: allFolders },
      openFolderKeys,
    } = this.props

    switch (type) {
      case 'UGC':
        return id === UGC_FOLDER || allFolders[id].parentId === UGC_FOLDER
          ? allFolders[id].assets.length
          : 0
      case 'PENDING_APPROVAL':
      case 'PENDING_REMOVAL':
        if (!rolledUp || openFolderKeys.indexOf(id) > -1) {
          return getFolderPendingCount(id, folderAssetStatus, type, allFolders)
        }

        return getRolledUpFolderPendingCount(
          id,
          allFolders,
          folderAssetStatus,
          type
        )

      default:
        return 0
    }
  }

  onBadgeClick = (e, id, badge) => {
    if (badge !== 'UGC') {
      e.preserveStatusFilter = false
      if (this.nodeIsOpen(id) && this.nodeIsActive(id)) e.stopPropagation()

      if (this.nodeIsOpen(id)) e.preserveStatusFilter = true

      const folderPendingItems = this.getBadgeCount(id, badge, false)
      if (folderPendingItems) {
        e.preserveStatusFilter = true
        if (this.props.selectedFilter !== badge)
          this.props.appActions.applyStatusFilter(badge)
      }
    }
  }

  // Returns true if the endpoint allows publishing.  Used by the nodes when deciding whether
  // to display a badge of pending items
  allowPublishing = (endpointId) => {
    const endpoint = getEndpointById(endpointId, this.props.endpoints.endpoints)
    return endpoint.allowPublishing
  }

  render() {
    return (
      <SidebarContent
        activeKey={this.props.activeEndpoint}
        activeFolder={this.props.folders.activeFolder}
        onCollapseChange={this.onCollapseChange}
        onPanelHeaderIconClick={this.onPanelHeaderIconClick}
        folders={this.props.folders}
        handleRenameFolder={this.handleRenameFolder}
        toggleFolder={this.toggleFolder}
        onNodeClick={this.onNodeClick}
        nodeLink={this.nodeLink}
        nodeIsOpen={this.nodeIsOpen}
        nodeIsActive={this.nodeIsActive}
        nodeIsDraggable={this.nodeIsDraggable}
        nodeIsDisabled={this.nodeIsDisabled}
        endpoints={this.props.endpoints}
        openFolderKeys={this.props.openFolderKeys}
        removeOpenFolderKey={this.props.appActions.removeOpenFolderKey}
        openEndpointKeys={this.props.openEndpointKeys}
        renamingFolder={this.props.renamingFolder}
        folderActions={this.props.folderActions}
        modalActions={this.props.modalActions}
        appActions={this.props.appActions}
        assetActions={this.props.assetActions}
        email={this.props.user.email}
        homeActions={this.props.homeActions}
        groups={this.props.permissions.groups}
        privileges={this.props.permissions.privileges}
        history={this.props.history}
        menuItem={this.props.menuItem}
        setActiveFolder={this.setActiveFolder}
        getBadgeCount={this.getBadgeCount}
        onBadgeClick={this.onBadgeClick}
        allowPublishing={this.allowPublishing}
        relationships={this.props.relationships}
        activeView={this.props.activeView}
        allAssets={this.props.assets.byId}
        selectedAssets={this.props.selectedAssets}
        folderDragData={this.props.folderDragData}
      />
    )
  }
}

Sidebar.propTypes = {
  user: PropTypes.object,
  folders: PropTypes.object,
  activeFolder: PropTypes.string,
  moveFunction: PropTypes.func,
  onGetFolderClick: PropTypes.func,
  onActiveFolderClick: PropTypes.func,
  relationships: PropTypes.object,
  activeEndpoint: PropTypes.string,
  menuItem: PropTypes.string,
  activeView: PropTypes.string,
  openFolderKeys: PropTypes.arrayOf(PropTypes.string),
  openEndpointKeys: PropTypes.arrayOf(PropTypes.string),
  renamingFolder: PropTypes.string,
  ContextMenuTrigger: PropTypes.string,
  folderDragData: PropTypes.object,
}

const mapStateToProps = (state) => ({
  folders: state.folders,
  assets: state.assets,
  user: state.user,
  selectedAssets: state.appState.selectedAssets,
  activeEndpoint: state.appState.activeEndpoint,
  openFolderKeys: state.appState.openFolderKeys,
  openEndpointKeys: state.appState.openEndpointKeys,
  renamingFolder: state.appState.renamingFolder,
  selectedFilter: state.appState.selectedFilter,
  menuItem: state.appState.menuItem,
  activeView: state.appState.activeView,
  endpoints: state.endpoints,
  permissions: state.permissions,
  relationships: state.assets.relationships,
  folderDragData: state.appState.folderDragData,
})

const mapDispatchToProps = (dispatch) => ({
  folderActions: bindActionCreators(folderActions, dispatch),
  modalActions: bindActionCreators(modalActions, dispatch),
  assetActions: bindActionCreators(assetActions, dispatch),
  appActions: bindActionCreators(appActions, dispatch),
  homeActions: bindActionCreators(homeActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
