import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getAllAssets,
  getActiveData,
  getAllUsers,
  getAnalyticsDateRange,
} from '../../../../../dux/reducers/analytics'
import AnalyticsOverview from './Views/AnalyticsOverview'
import AssetsDetail from './Views/AssetsDetail'
import ShareDetail from './Views/ShareDetail'
import UserActivity from './Views/UserActivity'
import CalcViews from './Views/CalcViews'
import Calculations from './Views/Calculations'

// ******
// Redux
// ******
const mapStateToProps = (state) => ({
  loadingAnalytics: state.analytics.loadingAnalytics,
  allData: getActiveData(state),
  allUsers: getAllUsers(state),
  allAssets: getAllAssets(state),
  dateRange: getAnalyticsDateRange(state),
})

// ****************
// React Components
// ****************
class AnalyticsPlotView extends Component {
  render() {
    const {
      activeAnalyticsView,
      dateRange,
      // eslint-disable-next-line no-unused-vars
      match,
      // eslint-disable-next-line no-unused-vars
      location,
      loadingAnalytics,
      ...others
    } = this.props
    if (loadingAnalytics) {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <i
            className={`fa fa-5x fa-spinner fa-spin`}
            style={{ color: '#1890ff' }}
          />
        </div>
      )
    }

    switch (activeAnalyticsView) {
      case 'AnalyticsOverview':
        return <AnalyticsOverview dateRange={dateRange} {...others} />
      case 'UserActivity':
        return <UserActivity dateRange={dateRange} {...others} />
      case 'AssetsDetail':
        return <AssetsDetail dateRange={dateRange} {...others} />
      case 'ShareDetail':
        return <ShareDetail dateRange={dateRange} {...others} />
      case 'CalcViews':
        return <CalcViews dateRange={dateRange} {...others} />
      case 'Calculations':
        return <Calculations dateRange={dateRange} {...others} />
      default:
        return <div />
    }
  }
}

// *********
// PropTypes
// *********
AnalyticsPlotView.propTypes = {
  allData: PropTypes.object,
  allUsers: PropTypes.object,
  activeAnalyticsView: PropTypes.string,
  activeAnalyticsPlatform: PropTypes.string,
  activeAnalyticsDuration: PropTypes.string,
  analyticsCustomDateRange: PropTypes.arrayOf(PropTypes.string),
  setActiveAnalyticsView: PropTypes.func,
}

export default connect(mapStateToProps)(AnalyticsPlotView)
