import { Icon } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { copySpanToClipboard } from '../../dux/modules/utils'

const CopyTextComponent = (props) => (
  <span className={props.className}>
    <span id={props.id}>{props.textToCopy}</span>
    &nbsp;
    {props.showIcon ? (
      <span
        className="span__clickable"
        onClick={() => {
          copySpanToClipboard(`${props.id}`)
          props.onCopyClick()
        }}
      >
        <Icon type="copy" className="bam__invitation-link-icon" />
        Copy
      </span>
    ) : (
      <span className="span__clickable-success">
        <Icon type="check-circle" className="bam__invitation-link-success" />
        Copied
      </span>
    )}
  </span>
)

CopyTextComponent.propTypes = {
  id: PropTypes.string.isRequired,
  textToCopy: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
  onCopyClick: PropTypes.func,
  className: PropTypes.string,
}

export default CopyTextComponent
