export default {
  previewSizing: { width: 64, height: 64, borderRadius: 4, marginLeft: 12 },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    overflowX: 'hidden',

    height: '100%',
    width: '100%',
  },
  mainContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '65%',
    height: '100%',
  },
  mainViewer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  paginationContainer: {
    height: 2,
    display: 'flex',
  },
  prevNext: {
    position: 'absolute',
    bottom: 10,
    width: 12,
    height: 12,
    backgroundColor: '#fff5',
    borderRadius: '50%',
    color: 'black',
    display: 'flex',
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: 12,
  },
  productPageIndicator: {
    height: 2,
    flex: 1,
  },
}
