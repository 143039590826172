import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActions from '../../dux/actions/appActions'
import '../styles/FourOhFour.css'

const FourOhFour = () => (
  <div className="fourohfourpage">
    <div className="fourohfourpage__text-error">404 error!</div>
    <div className="fourohfourpage__text-description">
      That means we couldn&apos;t find what you&apos;re looking for.
    </div>
    <div className="fourohfourpage__text-help">
      You can try{' '}
      <span
        className="fourohfourpage__text-link"
        onClick={() => window.location.reload(true)}
      >
        reloading the page
      </span>
      , searching for what you want, or navigate to one of the areas in the menu
      above.
    </div>
  </div>
)

FourOhFour.defaultProps = {
  appActions,
}

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appActions, dispatch),
})

export default connect(null, mapDispatchToProps)(FourOhFour)
