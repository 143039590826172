import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as userActions from '../../dux/actions/user'
import { requiredFieldsArePopulated } from '../../dux/modules/utils'
import RegisterForm from './RegisterForm'
import '../styles/Register.css'

class Register extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = (e) => {
      e.preventDefault()

      this.form.validateFields((err, values) => {
        if (!err) {
          if (
            !requiredFieldsArePopulated(
              ['Name', 'Email', 'Invitation', 'Password', 'Confirm Password'],
              values,
              this.form
            )
          )
            return

          if (values.Password !== values['Confirm Password']) {
            this.form.setFields({
              'Confirm Password': {
                errors: [new Error('Passwords do not match')],
              },
            })
          } else {
            let accountDetails = {
              name: values.Name,
              email: values.Email.toLowerCase(),
              password: values.Password,
              invitation: values.Invitation,
            }
            this.props.userActions.registerUser(accountDetails)
          }
        }
      })
    }

    this.saveFormRef = (ref) => (this.form = ref)
  }

  render() {
    if (this.props.user.registrationPending) {
      return <Redirect to="/registration-pending" />
    }

    return (
      <RegisterForm
        handleSubmit={this.handleSubmit}
        saveFormRef={this.saveFormRef}
        asyncPending={this.props.appState.asyncPending}
        errorMsg={this.props.appState.registrationErrorMsg}
        config={this.props.config}
        invitation={this.props.match.params.invitation}
      />
    )
  }
}

Register.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  invitationCode: PropTypes.string,
}

const mapStateToProps = (state) => ({
  user: state.user,
  appState: state.appState,
  config: state.config.appConfig,
})

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(userActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Register)
