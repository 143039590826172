import update from 'immutability-helper'
import * as appActions from '../actions/appActions'
import * as assetActions from '../actions/asset'
import * as permissionsActions from '../actions/permissions'
import * as userActions from '../actions/user.js'
import config from '../../config'

const defaultAvatar = `${config.baseUrl}/defaults/defaultProfile.png`

let email = localStorage.getItem('email')
if (email && email !== 'undefined') email = JSON.parse(email)
else email = ''

let rp = localStorage.getItem('registrationPending')
if (rp && rp !== 'undefined') rp = JSON.parse(rp)
else rp = false

const INITIAL_STATE = {
  token: '',
  id: '1',
  client: '6dso3vbs5nse30isja14nt0mrl',
  name: '',
  assets: [],
  userAssets: {},
  email,
  profileUrl: 'https://avatars3.githubusercontent.com/u/19525200?s=400&v=4',
  profilePreview: null,
  invitation: '589925ba0123c32f738a1e4i',
  status: 'active',
  level: 'User',
  createdDt: 'February 22, 2016',
  preferences: {
    homeViewMode: 'grid',
    mobile: true,
  },
  socialMedia: {},
  storageUsed: 34,
  loggedIn: false,
  loginError: '',
  registrationPending: rp,
  resendConfirmError: false,
  resendConfirmErrorMsg: '',
  admin: {
    createUserError: null,
  },
}
export default function userReducer(state = INITIAL_STATE, action) {
  let result
  switch (action.type) {
    /*
     * LOGIN_USER_SUCCESS - Login succeeded, update user data, clear any errors
     *
     */
    case userActions.LOGIN_USER_SUCCESS: {
      localStorage.setItem('email', JSON.stringify(action.user))
      return Object.assign({}, state, {
        loggedIn: true,
        loginError: '',
      })
    }

    /*
     * LOGIN_USER_FAILURE - Login failed, notify user
     *
     */
    case userActions.LOGIN_USER_FAILURE: {
      return Object.assign({}, state, {
        loginError: action.message,
      })
    }

    case userActions.CLEAR_LOGIN_ERROR: {
      return Object.assign({}, state, {
        loginError: '',
      })
    }

    case userActions.SET_LOGIN_ERROR: {
      return {
        ...state,
        loginError: action.message,
      }
    }
    /*
     *
     */
    case userActions.SET_TOKEN: {
      return {
        ...state,
        token: action.token,
      }
    }
    /*
     *  UPDATE_USER_PREFS
     *  params: Object containing preferences updates
     *    { preferences:
     *       { homeViewMode: 'grid'
     *       }
     *    }
     *  uses immutability-helper update to allow for
     *  additional preference changes in future
     */
    case userActions.UPDATE_USER_PREFS: {
      return update(state, {
        preferences: { $merge: action.payload.preferences },
      })
    }

    /*
     * UPDATE_USER_PROFILE_SUCCESS - update succeeded, replace user object with object passed
     *  by the action
     */
    case userActions.UPDATE_USER_PROFILE_SUCCESS: {
      return update(state, {
        $merge: action.updates,
      })
    }

    /*
     * REGISTER_USER_SUCCESS - set appstate to reflect registration call completed
     *   set local storage item to preserve state
     */
    case userActions.REGISTER_USER_SUCCESS: {
      localStorage.setItem('registrationPending', JSON.stringify(true))
      localStorage.setItem('email', JSON.stringify(action.user.email))

      return Object.assign({}, state, {
        registrationPending: true,
        id: action.user.id,
        email: action.user.email,
        name: action.user.name,
        client: action.user.client,
        title: action.user.title,
        invitation: action.user.invitation,
      })
    }

    /*
     * SET_REGISTRATION_PENDING - if user attempts to login with ID that is not confirmed,
     * set this flag.  Normally this would be set in local storage, but user may log in from
     * another browser
     */
    case userActions.SET_REGISTRATION_PENDING: {
      localStorage.setItem('registrationPending', JSON.stringify(true))
      localStorage.setItem('email', JSON.stringify(action.user))
      return Object.assign({}, state, {
        registrationPending: true,
        email: action.user,
      })
    }

    /*
     * CLEAR_LOCAL_STORAGE - this action clears local storage.  It is called when user wants to
     * log in with a different account
     */
    case appActions.CLEAR_LOCAL_STORAGE: {
      let result = state
      Object.keys(action.items).forEach((key) => {
        localStorage.removeItem(key)
        result = update(result, {
          [key]: { $set: action.items[key] },
        })
      })
      return result
    }

    /*
     *
     */
    case userActions.RESEND_CONFIRM_FAILURE: {
      return Object.assign({}, state, {
        resendConfirmError: true,
        resendConfirmErrorMsg: action.error.message,
      })
    }

    /*
     *
     */
    case userActions.RESEND_CONFIRM_SUCCESS: {
      return Object.assign({}, state, {
        loginError: 'Confirmation email resent',
      })
    }

    /*
     * LOGOUT_SUCCESS: set redux flag to loggedOut - redirects app to login page.
     *  Cognito will handle clearing out cached tokens
     */
    case userActions.LOGOUT_SUCCESS: {
      result = state
      if (action.force) {
        localStorage.removeItem('activeFolder')
        localStorage.removeItem('email')
      }

      return {
        ...state,
        loggedIn: false,
      }
    }

    /*
     *  LOGOUT_FAILURE: not sure what to do here!
     */
    case userActions.LOGOUT_FAILURE: {
      return Object.assign({}, state, { loggedIn: false })
    }

    /*
     * GET_USER_DATA_SUCCESS - on login, set redux user data
     */
    case userActions.GET_USER_DATA_SUCCESS: {
      return {
        ...state,
        id: action.user.id,
        name: action.user.name,
        level: action.user.level,
        email: action.user.email,
        title: action.user.title,
        client: action.user.client,
        assets: action.user.assets,
        createdDt: action.user.createdDt,
        profileUrl: action.user.profileUrl,
        invitation: action.user.invitation,
        preferences: action.user.preferences,
        socialMedia: action.user.socialMedia,
        homeEndpoint: action.user.homeEndpoint,
        deleted: action.user.deleted,
      }
    }

    case userActions.GET_USER_DATA_FAILURE: {
      result = Object.assign({}, state, {
        updating: false,
      })
      return result
    }

    case assetActions.CREATE_USERASSET_SUCCESS: {
      return update(state, {
        profilePreview: {
          $set: action.preview || defaultAvatar,
        },
      })
    }

    case userActions.FORGOT_PASSWORD_REQUEST: {
      return {
        ...state,
        loginError: '',
      }
    }

    case userActions.FORGOT_PASSWORD_FAILURE: {
      return {
        ...state,
        loginError: action.error.message,
      }
    }

    case permissionsActions.EDIT_USER_SUCCESS: {
      if (action.user.email === state.email) {
        return {
          ...state,
          name: action.newName,
        }
      }
      return state
    }

    case userActions.SET_PROFILE_PREVIEW: {
      return {
        ...state,
        profilePreview: action.url || defaultAvatar,
      }
    }
    /*-------------------------------------------------*/

    case userActions.UPDATE_USER_FAILURE: {
      result = Object.assign({}, state, {
        updating: false,
      })
      return result
    }

    case userActions.ADMIN_CREATE_USER_SUCCESS:
      return {
        ...state,
        admin: {
          ...state.admin,
          createUserError: null,
        },
      }

    case userActions.ADMIN_CREATE_USER_ERROR:
      return {
        ...state,
        admin: {
          ...state.admin,
          createUserError: action.error,
        },
      }

    default:
      //no recognition of this action type
      return state
  }
}
