import { Button, Modal } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { assetIsUsedAsImage } from '../../dux/modules/utils'
import FileUploader from '../library/FileUploader'

const IMAGE_WARNING =
  'This image is used as a preview image for one or more builders.  You may only replace it with ' +
  'another image.'
const DEFAULT_TITLE = 'Upload Assets'

const UploadModal = (props) => {
  const { modalActions, modalProps, appState, modal } = props

  return (
    <Modal
      title={modalProps.title || DEFAULT_TITLE}
      visible={modal.modalOpen}
      confirmLoading={appState.asyncPending}
      maskClosable={false}
      closable={false}
      footer={[
        <Button key="cancel" onClick={modalActions.closeModal}>
          Cancel
        </Button>,
        <Button
          key="ok"
          disabled={!appState.uploadContinue}
          onClick={modalProps.callback}
        >
          CONTINUE
        </Button>,
      ]}
    >
      {assetIsUsedAsImage(
        props.appState.editModeAsset,
        props.assets.byId,
        props.assets.relationships.assets
      )
        ? IMAGE_WARNING
        : null}
      <br />

      <FileUploader
        target={'active'}
        imageOnly={
          props.modalProps.imageOnly ||
          assetIsUsedAsImage(
            props.appState.editModeAsset,
            props.assets.byId,
            props.assets.relationships.assets
          )
        }
        {...props}
      />
    </Modal>
  )
}

UploadModal.propTypes = {
  modal: PropTypes.object,
  modalActions: PropTypes.object,
  modalProps: PropTypes.object,
}

UploadModal.defaultProps = {
  modalProps: {
    multiple: true,
    type: 'asset',
    target: 'active',
  },
}
export default UploadModal
