import update from 'immutability-helper'
import * as actions from '../actions/permissions'
import { ADMIN_CREATE_USER_SUCCESS } from '../actions/user'

const INITIAL_STATE = {
  users: {},
  groups: {},
  roles: [],
  privileges: {},
  deletedUsers: {},
}

export default function permissionsReducer(state = INITIAL_STATE, action) {
  let newState, grps
  switch (action.type) {
    case actions.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.users,
      }

    case actions.GET_DELETED_USERS_SUCCESS:
      return {
        ...state,
        deletedUsers: action.users,
      }

    case actions.EDIT_USER_SUCCESS:
      return update(state, {
        users: {
          [action.user.email]: {
            name: {
              $set: action.newName,
            },
          },
        },
      })

    // Delete user from any existing group membership
    // Remove the user from redux store
    case actions.DELETE_USER_SUCCESS:
      newState = state
      grps = Object.keys(newState.groups).filter(
        (grp) => newState.groups[grp].members.indexOf(action.user.email) > -1
      )
      grps.forEach((grp) => {
        newState = update(newState, {
          groups: {
            [newState.groups[grp].id]: {
              members: {
                $splice: [
                  [newState.groups[grp].members.indexOf(action.user.email), 1],
                ],
              },
            },
          },
        })
      })
      return update(newState, {
        users: {
          $unset: [action.user.email],
        },
      })

    case ADMIN_CREATE_USER_SUCCESS:
      newState = update(state, {
        users: {
          [action.newUser.email]: {
            $set: action.newUser,
          },
        },
      })
      action.groups.forEach((group) => {
        newState = update(newState, {
          groups: {
            [group]: {
              members: {
                $push: [action.newUser.email],
              },
            },
          },
        })
      })
      return newState

    case actions.GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.groups,
      }

    case actions.ADD_GROUP_SUCCESS:
      return update(state, {
        groups: {
          [action.group.id]: {
            $set: action.group,
          },
        },
      })

    case actions.UPDATE_GROUP_SUCCESS:
      return update(state, {
        groups: {
          [action.group.id]: {
            $set: action.group,
          },
        },
      })

    case actions.DELETE_GROUP_SUCCESS:
      return update(state, {
        groups: {
          $unset: [action.groupId],
        },
      })

    case actions.GET_ROLES_SUCCESS: {
      return {
        ...state,
        roles: action.roles,
      }
    }

    case actions.GET_PRIVILEGES_SUCCESS: {
      return {
        ...state,
        privileges: action.privileges,
      }
    }
    default:
      return state
  }
}
