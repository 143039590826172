import { Button, Icon, Table, Typography, Checkbox } from 'antd'
import dayjs from 'dayjs'
import { startCase } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { reactNodeToText } from '../../../../../../../dux/modules/utils'
import { colors } from '../../../../styleConstants'

const dateFormat = 'MM/DD/YYYY'
const columnOrder = [
  'title',
  'filename',
  'file_type',
  'name',
  'user',
  'email',
  'shares',
  'views',
  'shareDate',
  'expires',
  'firstView',
  'totalViews',
  'calculations',
]

const columnFormatters = {
  shareDate: (value) => dayjs(value).format(dateFormat),
  expires: (value) => (value ? dayjs.unix(value).format(dateFormat) : '—'),
  firstView: (value) => (value ? dayjs.unix(value).format(dateFormat) : '—'),
}
const columnSorters = {
  filename: (a, b) => {
    if (a.filename && b.filename) {
      return a.filename.localeCompare(b.filename)
    } else if (a.filename) {
      return 1
    } else if (b.filename) {
      return -1
    }

    return a.file_type.localeCompare(b.file_type)
  },
  file_type: (a, b) => a.file_type.localeCompare(b.file_type),
  shares: (a, b) => a.shares - b.shares,
  title: (a, b) =>
    reactNodeToText(a.title).localeCompare(reactNodeToText(b.title)),
  views: (a, b) => a.views - b.views,
}

const formatKey = (keyName) => {
  return startCase(keyName)
}

// ****************
// React Components
// ****************
class AnalyticsAssetTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 1,
    }
  }

  setPage = (page) => this.setState({ page })

  /*
   * Generates the table columns collection from the first element of the
   * table data collection. Keys of that first element will be the table
   * header columns.
   */
  tableColumns = (tableData, currentPage = 1) => {
    if (!Array.isArray(tableData) || !tableData.length) return null

    const keys = Object.keys(tableData[0])
    let tableColumns = []

    for (let keyName of keys) {
      if (
        [
          'assetId',
          'key',
          'shareId',
          'sharesList',
          'userId',
          'viewsList',
        ].includes(keyName)
      )
        continue

      const columnStyle = {
        whiteSpace: 'break-spaces',
        wordBreak: 'break-word',
      }

      /* 
      Table data may contain a react component instead of a string,
      see formatDataForCalculatorsTable helper function
      */
      tableColumns.push({
        dataIndex: keyName,
        render: (text) => (
          <Typography.Text
            ellipsis={true}
            title={typeof text === 'string' ? text : undefined}
            style={columnStyle}
          >
            {columnFormatters[keyName] ? columnFormatters[keyName](text) : text}
          </Typography.Text>
        ),
        sorter: columnSorters[keyName],
        title: formatKey(keyName),
      })
    }

    return [
      {
        key: 'index',
        render: (text, record, index) => (currentPage - 1) * 100 + index + 1,
        title: '#',
      },
    ].concat(
      tableColumns.sort(({ dataIndex: a }, { dataIndex: b }) => {
        // order applies to different table views - assets, assets by user, shares, shares by user

        const aIdx = columnOrder.indexOf(a)
        const bIdx = columnOrder.indexOf(b)

        if (aIdx === bIdx) {
          return a.localeCompare(b)
        }

        return aIdx - bIdx
      })
    )
  }

  render() {
    const {
      tableData,
      setShowInactive,
      onExportClick,
      onRowClick,
      selectedDate,
      selectedAsset,
      titleTxt,
      customExportButtonTitle,
      customExport,
    } = this.props

    return (
      <div style={styles.container}>
        <div style={styles.headerContainer}>
          <span style={styles.headerText}>{titleTxt}</span>
          {selectedDate || selectedAsset || !setShowInactive ? (
            <span />
          ) : (
            <Checkbox onChange={setShowInactive}>Show Inactive Assets</Checkbox>
          )}
        </div>
        <Table
          bordered={false}
          columns={this.tableColumns(tableData, this.state.page)}
          dataSource={tableData}
          footer={() => {
            if (tableData.length === 0) return null
            return (
              <div style={styles.footerButtons}>
                <Button
                  style={styles.footerButton}
                  onClick={onExportClick}
                  type="ghost"
                >
                  <Icon type="download-o" />
                  Events Report
                </Button>
                {customExportButtonTitle && (
                  <Button
                    style={styles.footerButton}
                    onClick={customExport}
                    type="ghost"
                  >
                    <Icon type="download-o" />
                    Calculations Report
                  </Button>
                )}
              </div>
            )
          }}
          onRow={(record) => ({
            onClick: () => onRowClick(record),
          })}
          pagination={{
            hideOnSinglePage: true,
            onChange: this.setPage,
            pageSize: 100,
            pageSizeOptions: ['50', '100', '500', '1000'],
            showQuickJumper: true,
            showSizeChanger: true,
          }}
        />
      </div>
    )
  }
}

// ******
// Styles
// ******
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    marginTop: '20px',
    padding: '20px',
    borderRadius: '7px',
    boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.07)',
    backgroundColor: colors.white00,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '10px 10px 17px 5px',
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  headerRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: '17px',
  },
  headerTitle: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    letterSpacing: '.94px',
    fontWeight: 'bold',
    color: colors.grey01,
    cursor: 'pointer',
  },
  headerText: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    letterSpacing: '.94px',
    fontWeight: 'bold',
    color: colors.grey01,
  },
  headerSubtitle: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '.94px',
    color: colors.grey01,
    cursor: 'pointer',
  },
  thumbnailContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100px',
    height: '100px',
  },
  thumbnailImage: {
    maxWidth: '100%',
    height: '100%',
    objectFit: 'cover',
    overflow: 'hidden',
    borderRadius: '4px',
    boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.17)',
  },
  footerButtons: {
    alignItems: 'center',
    display: 'flex',
  },
  footerButton: {
    marginRight: 12,
  },
}

// *********
// PropTypes
// *********
AnalyticsAssetTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object),
  selectedDate: PropTypes.string,
  isExpandable: PropTypes.bool,
  onClearSelection: PropTypes.func,
}

export default AnalyticsAssetTable
