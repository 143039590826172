import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { ContextMenuTrigger } from 'react-contextmenu'
import { handleRightClick } from '../../dux/modules/utils'
import AssetNode from './AssetNode'
import DraggableNode from './DraggableNode'
import Node from './Node'
import '../styles/Sidebar.css'

const NODE_FOLDER_MENU = 'NODE_FOLDER_MENU'

class TreeNode extends React.Component {
  render() {
    return this.props.isModal ? (
      <Fragment>
        <div
          onClick={() => {
            if (!this.props.nodeIsDisabled(this.props.node.id)) {
              if (this.props.activeFolder !== this.props.node.id)
                this.props.setActiveFolder(this.props.node.id)
            }
          }}
          className="treenode-link"
        >
          {this.props.nodeIsDraggable(this.props.node.id) ? (
            <DraggableNode
              id={this.props.node.id}
              type="folder"
              open={this.props.openFolderKeys.indexOf(this.props.node.id) > -1}
              renaming={this.props.renamingFolder === this.props.node.id}
              node={this.props.node}
              toggle={() =>
                !this.props.nodeIsDisabled(this.props.node.id) &&
                this.props.onToggle(this.props.node.id)
              }
              handleRename={this.props.handleRenameFolder}
              disabled={this.props.nodeIsDisabled(this.props.node.id)}
              {...this.props}
            >
              <Node
                id={this.props.node.id}
                type="folder"
                open={
                  this.props.openFolderKeys.indexOf(this.props.node.id) > -1
                }
                renaming={this.props.renamingFolder === this.props.node.id}
                node={this.props.node}
                toggle={this.props.onToggle}
                handleRename={this.props.handleRenameFolder}
                disabled={this.props.nodeIsDisabled(this.props.node.id)}
                {...this.props}
              />
            </DraggableNode>
          ) : (
            <Node
              id={this.props.node.id}
              type="folder"
              open={this.props.openFolderKeys.indexOf(this.props.node.id) > -1}
              renaming={this.props.renamingFolder === this.props.node.id}
              node={this.props.node}
              toggle={this.props.onToggle}
              handleRename={this.props.handleRenameFolder}
              disabled={this.props.nodeIsDisabled(this.props.node.id)}
              {...this.props}
            />
          )}
        </div>

        {/* Display child folders in tree if they are not deleted*/}
        {this.props.openFolderKeys.indexOf(this.props.node.id) > -1 &&
          this.props.isFolder &&
          this.props.node.childFolders &&
          this.props.node.childFolders.length > 0 && (
            <ul
              className={`childlist${
                this.props.openFolderKeys.indexOf(this.props.node.id) > -1
                  ? ''
                  : '-hidden'
              }`}
            >
              {this.props.node.childFolders &&
                this.props.node.childFolders.map((folderid, index) => {
                  const newFolder = this.props.folders.byId[folderid]
                  const isDeleted = newFolder.deleted
                  const isInRecycleAndHidden =
                    this.props.node.type === 'recycle' &&
                    newFolder.type === 'webfolder'
                  if (!isDeleted && !isInRecycleAndHidden) {
                    return (
                      <li key={index} className="childfolder">
                        <TreeNode
                          {...this.props}
                          key={folderid}
                          node={newFolder}
                          isRoot={false}
                          renaming={this.props.renamingFolder === folderid}
                          open={
                            this.props.openFolderKeys.indexOf(folderid) > -1
                          }
                          toggle={() => this.props.onToggle(folderid)}
                          isModal={this.props.isModal}
                        />
                      </li>
                    )
                  }
                  return null
                })}
            </ul>
          )}
        {/* Display assets in tree */}
        {this.props.showAssets &&
          this.props.nodeIsOpen(this.props.node.id) &&
          this.props.node.assets
            .filter((asset) =>
              this.props.assetFilter(asset, this.props.node.id)
            )
            .map((asset) => {
              return (
                <ul className="childlist" key={asset}>
                  <li className="childfolder">
                    <AssetNode
                      {...this.props}
                      asset={this.props.allAssets[asset]}
                      parentFolder={this.props.node.id}
                      displayMode={this.props.displayMode}
                    />
                  </li>
                </ul>
              )
            })}
      </Fragment>
    ) : (
      <Fragment>
        <div
          onClick={() => {
            if (!this.props.nodeIsDisabled(this.props.node.id)) {
              if (
                this.props.menuItem !== 'builders' &&
                this.props.menuItem !== 'settings'
              ) {
                this.props.history.push(this.props.nodeLink(this.props.node.id))
                if (this.props.menuItem !== 'library')
                  this.props.appActions.setMenuItem('library')
              }
              if (this.props.activeFolder !== this.props.node.id)
                this.props.setActiveFolder(this.props.node.id)
            }
          }}
          className="treenode-link"
        >
          <ContextMenuTrigger
            id={NODE_FOLDER_MENU}
            name={this.props.node.id}
            onItemClick={handleRightClick}
            collect={collect}
            holdToDisplay={-1}
            folders={this.props.folders}
            folderActions={this.props.folderActions}
            modalActions={this.props.modalActions}
            appActions={this.props.appActions}
            toggle={this.props.onToggle}
            onNodeClick={this.props.onNodeClick}
            disable={this.props.rightClickDisabled}
            endpoints={this.props.endpoints}
          >
            {this.props.nodeIsDraggable(this.props.node.id) ? (
              <DraggableNode
                id={this.props.node.id}
                type="folder"
                open={
                  this.props.openFolderKeys.indexOf(this.props.node.id) > -1
                }
                renaming={this.props.renamingFolder === this.props.node.id}
                node={this.props.node}
                toggle={() =>
                  !this.props.nodeIsDisabled(this.props.node.id) &&
                  this.props.onToggle(this.props.node.id)
                }
                handleRename={this.props.handleRenameFolder}
                disabled={this.props.nodeIsDisabled(this.props.node.id)}
                {...this.props}
              >
                <Node
                  id={this.props.node.id}
                  type="folder"
                  open={
                    this.props.openFolderKeys.indexOf(this.props.node.id) > -1
                  }
                  renaming={this.props.renamingFolder === this.props.node.id}
                  node={this.props.node}
                  toggle={this.props.onToggle}
                  handleRename={this.props.handleRenameFolder}
                  disabled={this.props.nodeIsDisabled(this.props.node.id)}
                  {...this.props}
                />
              </DraggableNode>
            ) : (
              <Node
                id={this.props.node.id}
                type="folder"
                open={
                  this.props.openFolderKeys.indexOf(this.props.node.id) > -1
                }
                renaming={this.props.renamingFolder === this.props.node.id}
                node={this.props.node}
                toggle={this.props.onToggle}
                handleRename={this.props.handleRenameFolder}
                disabled={this.props.nodeIsDisabled(this.props.node.id)}
                {...this.props}
              />
            )}
          </ContextMenuTrigger>
        </div>
        {/* Display child folders in tree if they are not deleted*/}
        {this.props.openFolderKeys.indexOf(this.props.node.id) > -1 &&
          this.props.isFolder &&
          this.props.node.childFolders &&
          this.props.node.childFolders.length > 0 && (
            <ul
              className={`childlist${
                this.props.openFolderKeys.indexOf(this.props.node.id) > -1
                  ? ''
                  : '-hidden'
              }`}
              id={`${this.props.node.id}-childContainer`}
            >
              {this.props.node.childFolders &&
                this.props.node.childFolders.map((folderid, index) => {
                  const newFolder = this.props.folders.byId[folderid]
                  const isDeleted = newFolder.deleted
                  const isInRecycleAndHidden =
                    this.props.node.type === 'recycle' &&
                    newFolder.type === 'webfolder'
                  if (!isDeleted && !isInRecycleAndHidden) {
                    return (
                      <li key={index} className="childfolder">
                        <TreeNode
                          {...this.props}
                          key={folderid}
                          node={newFolder}
                          isRoot={false}
                          isModal={this.props.isModal}
                          renaming={this.props.renamingFolder === folderid}
                          open={
                            this.props.openFolderKeys.indexOf(folderid) > -1
                          }
                          toggle={() => this.props.onToggle(folderid)}
                        />
                      </li>
                    )
                  }
                  return null
                })}
            </ul>
          )}
        {/* Display assets in tree */}
        {this.props.showAssets &&
          this.props.nodeIsOpen(this.props.node.id) &&
          this.props.node.assets
            .filter((asset) =>
              this.props.assetFilter(asset, this.props.node.id)
            )
            .map((asset) => {
              return (
                <ul className="childlist" key={asset}>
                  <li className="childfolder">
                    <AssetNode
                      {...this.props}
                      asset={this.props.allAssets[asset]}
                      parentFolder={this.props.node.id}
                      displayMode={this.props.displayMode}
                    />
                  </li>
                </ul>
              )
            })}
      </Fragment>
    )
  }
}

const collect = (props) => {
  return props
}

TreeNode.defaultProps = {
  rightClickDisabled: false,
  isModal: true,
}

TreeNode.propTypes = {
  rightClickDisabled: PropTypes.bool,
  onAddFolderClick: PropTypes.func,
  onDeleteFolderClick: PropTypes.func,
  onActiveFolderClick: PropTypes.func,
  folders: PropTypes.object,
  node: PropTypes.object.isRequired,
  isFolder: PropTypes.bool,
  isRoot: PropTypes.bool,
  activeFolder: PropTypes.string,
  renameFolder: PropTypes.func,
  editClick: PropTypes.func,
  isViewOnly: PropTypes.bool,
  parent: PropTypes.string,
}

export default TreeNode
