import { API, Auth, graphqlOperation } from 'aws-amplify'
import {
  DEFAULT_MAINTENANCE_COMPLETE_MESSAGE,
  DEFAULT_MAINTENANCE_MESSAGE,
} from '../constants/app'
import mutations from './graphql/mutations'
import queries from './graphql/queries'
import subscriptions from './graphql/subscriptions'

export default class BamAppSyncClient {
  // Config is the AWS config used to initialize the AppSync client
  // subscriptionAction is the redux action to handle responses from the maintenance subscription
  // timeoutAction is the redux action to prompt user to re-enter password
  constructor(config, subscriptionAction, timeoutAction) {
    this.timeoutAction = timeoutAction
    this.subscribeToMaintenanceMutations(subscriptionAction).then((sub) => {
      this.subscription = sub
    })
  }

  // Helper to retrieve credentials for the user
  getCredentials = () => {
    try {
      return Auth.currentCredentials()
    } catch (error) {
      return false
    }
  }

  // Helper to refresh credentials.  If the refresh token is valid,
  // it will just work.  Otherwise it will launch the SessionTimeoutModal
  // and pass a callback to resubscribe.
  refreshCredentials = (subscriptionAction) => {
    return new Promise((resolve, reject) => {
      const creds = this.getCredentials()
      if (creds) {
        creds.refresh((err) => {
          if (err) {
            this.timeoutAction(null, null, null, () => {
              this.subscribeToMaintenanceMutations(subscriptionAction).then(
                (sub) => {
                  this.subscription = sub
                }
              )
            })
            return reject()
          }
          return resolve()
        })
      } else {
        this.timeoutAction(null, null, null, () => {
          this.subscribeToMaintenanceMutations(subscriptionAction).then(
            (sub) => {
              this.subscription = sub
            }
          )
        })
      }
    })
  }

  // This can be called to query the environment's current maintenance mode
  queryMaintenance = async () => {
    const { data } = await API.graphql(
      graphqlOperation(queries.GetMaintenanceSnapshot)
    )

    return data.getMaintenanceSnapshot
  }

  // This can be called to set maintenance mode to "on"
  turnOnMaintenance = async () => {
    return API.graphql(
      graphqlOperation(mutations.UpdateMaintenanceMode, {
        input: {
          state: 1,
          message: DEFAULT_MAINTENANCE_MESSAGE,
        },
      })
    )
  }

  // This can be called to set maintenance mode to "off"
  turnOffMaintenance = async () => {
    return API.graphql(
      graphqlOperation(mutations.UpdateMaintenanceMode, {
        input: {
          state: 0,
          message: DEFAULT_MAINTENANCE_COMPLETE_MESSAGE,
        },
      })
    )
  }

  // This function returns a promise with the subscription observable.
  // By saving the reference, we can later unsubscribe
  subscribeToMaintenanceMutations = async (callback) => {
    return API.graphql(
      graphqlOperation(subscriptions.onUpdateMaintenanceMode)
    ).subscribe({
      next: ({ value: { data } }) => {
        callback(data.onUpdateMaintenanceMode)
      },
      error: (error) => {
        this.handleSubscriptionError(error, callback)
      },
    })
  }

  // The subscription will throw an error when it can't reconnect,
  // as in the case of no network, or expired token
  // 1. Unsubcribe from graphql subscription
  // 2. Try to refresh credentials and resubscribe
  handleSubscriptionError = async (error, callback) => {
    if (this.subscription) this.subscription.unsubscribe()
    // If it's a token error we can try to refresh token, or possibly throw a sessiontimeouterror
    try {
      await this.refreshCredentials(callback)
      this.subscription = await this.subscribeToMaintenanceMutations(callback)
    } catch (error) {
      // Don't do anything with this error.
      // It means there was a timeout, and we will resubscribe in a callback
      // After user has entered password
    }
  }
}
