export const styles = {
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fill: {
    width: '100%',
    height: '100%',
  },
  headerImage: {
    marginTop: 50,
    backgroundSize: 'contain',
    backgroundPosition: 'left',
    backgroundRepeat: 'no-repeat',
    minHeight: 100,
    maxHeight: 400,
    height: '35%',
    width: '80%',
    marginLeft: 50,
  },
  titleArea: {
    width: '90%',
    minHeight: 125,
    marginLeft: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'flex-end',
    maxWidth: 600,
  },
  titleText: {
    color: 'white',
    margin: 0,
    display: 'flex',
    justifySelf: 'flex-start',
  },
  articleContent: {
    margin: 0,
    marginLeft: 50,
    maxWidth: 600,
    color: 'white',
    whiteSpace: 'pre-wrap',
  },
}
