import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActions from '../../dux/actions/appActions'
import * as assetActions from '../../dux/actions/asset'
import * as folderActions from '../../dux/actions/folder'
import * as modalActions from '../../dux/actions/modal'
import { buildPath, findLibraryFolder } from '../../dux/modules/utils'
import AssetDetail404 from './AssetDetail404'
import AssetPreview from './AssetPreview'

import '../styles/Asset.css'

class AssetDetail extends React.PureComponent {
  state = {
    pdfMenuKey: 'content',
    showLightbox: false,
  }

  onPdfMenuClick = (e) => this.setState({ pdfMenuKey: e.key })

  closeLightbox = () => this.setState({ showLightbox: false })

  openLightbox = () => this.setState({ showLightbox: true })

  isUploadModalOpen = () =>
    this.props.modalOpen && this.props.modalComponent === 'UploadModal'
  /*
   * Helper function to determine if user can edit the asset.  The search folder doesn't have
   * appropriate permissions, so if it is the search folder, we need to look up the library folder
   */

  componentDidMount() {
    this.props.appActions.scrollToTop('main-content')
    // If user has access to asset, retrieve latest version
    if (this.props.allAssets[this.props.match.params.id]) {
      this.props.assetActions.getAsset(this.props.match.params.id, true)
    }

    // If folder not set (e.g. direct link) find library folder
    if (!this.props.activeFolder) {
      const af = findLibraryFolder(
        this.props.match.params.id,
        this.props.allFolders,
        this.props.endpoints.endpoints
      )
      this.props.folderActions.activeFolder(af, false)
      this.props.appActions.setOpenFolderKeys(
        buildPath(af, this.props.allFolders)
      )
    }

    // Make sure only this asset is selected
    this.props.appActions.clearSelectedAssets()
    this.props.appActions.selectAsset(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.appActions.clearSelectedAssets()
  }

  render() {
    const { id } = this.props.match.params
    const {
      allAssets,
      modalActions,
      assetActions,
      appActions,
      originalAssetData,
      endpoints,
      allFolders,
      relationships,
      baseUrl,
    } = this.props
    const asset = allAssets[id]
    if (!asset) return <AssetDetail404 />
    const clsMain =
      asset.extension === 'form'
        ? 'assetdetail__main-tall'
        : 'assetdetail__main'
    return (
      <div className={clsMain}>
        <div className="assetdetail__preview-container">
          <AssetPreview
            asset={allAssets[id]}
            allAssets={allAssets}
            allFolders={allFolders}
            onPdfMenuClick={this.onPdfMenuClick}
            pdfMenuKey={this.state.pdfMenuKey}
            showLightbox={this.state.showLightbox}
            closeLightbox={this.closeLightbox}
            openLightbox={this.openLightbox}
            modalActions={modalActions}
            assetActions={assetActions}
            appActions={appActions}
            originalAssetData={originalAssetData}
            relationships={relationships}
            endpoints={endpoints}
            modalOpen={this.isUploadModalOpen()}
            baseUrl={`${baseUrl}`}
          />
        </div>
      </div>
    )
  }
}

AssetDetail.propTypes = {
  appActions: PropTypes.object,
  assetActions: PropTypes.object,
  modalActions: PropTypes.object,
  folderActions: PropTypes.object,
  allAssets: PropTypes.object,
  activeFolder: PropTypes.string,
  match: PropTypes.object,
  allFolders: PropTypes.object,
  endpoints: PropTypes.object,
  originalAssetData: PropTypes.object,
  relationships: PropTypes.object,
  privileges: PropTypes.object,
  groups: PropTypes.object,
  email: PropTypes.string,
  modalOpen: PropTypes.bool,
  modalComponent: PropTypes.string,
  baseUrl: PropTypes.string,
}

const mapStateToProps = (state) => ({
  allAssets: state.assets.byId,
  originalAssetData: state.assets.originalAssetData,
  activeFolder: state.folders.activeFolder,
  allFolders: state.folders.byId,
  endpoints: state.endpoints,
  relationships: state.assets.relationships,
  privileges: state.permissions.privileges,
  groups: state.permissions.groups,
  email: state.user.email,
  modalOpen: state.modal.modalOpen,
  modalComponent: state.modal.modalComponent,
  baseUrl: state.config.appConfig.baseUrl,
})

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appActions, dispatch),
  assetActions: bindActionCreators(assetActions, dispatch),
  folderActions: bindActionCreators(folderActions, dispatch),
  modalActions: bindActionCreators(modalActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssetDetail)
