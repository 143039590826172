import React, { Component } from 'react'
import * as endpointActions from '../../../dux/actions/endpoint'
import { connect } from 'react-redux'

const styles = {
  form: { display: 'flex', flexDirection: 'column', color: 'black' },
  label: { fontWeight: '1000px', fontSize: '30px' },
  button: { color: 'black', width: 200 },
  disabled: { color: 'red' },
}
class BatchPublish extends Component {
  defaultText = `This utility takes a json array of objects with these props:
    assetId 
    targetId
  `

  constructor(props) {
    super(props)
    this.handleFileChange = ({ target: { files } }) => {
      const reader = new FileReader()
      reader.onload = ({ target: { result } }) => {
        const data = JSON.parse(result)
        const validData = this.validate(data)
        if (validData) {
          this.setState({ data: validData })
        }
      }
      reader.readAsText(files[0])
    }
  }

  validate(json) {
    if (!Array.isArray(json)) {
      console.error('data is not an array')
      throw 'Data is invalid: needs to be a JSON array of objects with assetId, targetId'
    }

    json.forEach((obj) => {
      if (!obj.assetId) throw 'One or more objects is missing assetId'
      if (!obj.targetId) throw 'One or more objects is missing targetId'
    })

    if (!json) return null

    try {
      const publishes = json.filter(
        (p) =>
          this.props.allAssets[p.assetId] &&
          this.props.allAssets[p.assetId].publishedTo.length === 0
      )
      console.log({ publishes })
      return publishes
    } catch (err) {
      console.log({ err })
    }

    return null
  }

  submitHandler = (e) => {
    e.preventDefault()

    console.log('invoking batchPublish', this.state.data)
    this.props.batchPublish(this.state.data)
  }

  render() {
    const isValid = this.state && this.state.data
    const output =
      (this.state && this.state.output) || !isValid ? this.defaultText : ''

    return (
      <>
        <form style={styles.form} onSubmit={this.submitHandler}>
          <input
            type="file"
            onChange={this.handleFileChange}
            name="upload"
            id="upload"
            accept=".json"
          />
          <br />
          {isValid && (
            <button type="submit" style={styles.button}>
              Start
            </button>
          )}
          {output && (
            <div>
              <pre>{output}</pre>
            </div>
          )}
        </form>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  batchPublish: (publishActions) => {
    dispatch(endpointActions.batchPublish(publishActions))
  },
})

const mapStateToProps = (state) => ({
  allAssets: state.assets.byId,
})

export default connect(mapStateToProps, mapDispatchToProps)(BatchPublish)
