import React from 'react'

const Replace = (props) => (
  <svg
    data-name="Layer 1"
    viewBox="0 0 233.84 233.86"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d={
        'M168,208.76c0,2.56-.92,3.22-4,3.23H26.59c-4.32,0-4.76-.43-4.76-4.7q0-68.37,0-136.73c0-4.' +
        '22.52-4.73,4.77-4.74h5.81V43.9c-3,0-6.08,0-9.12,0C9.37,44,0,53.26,0,67.15q0,71.73,0,143.' +
        '48c0,14,9.24,23.21,23.18,23.22q35.87,0,71.74,0,36.12,0,72.24,0c13.37,0,22.63-9.29,22.7-2' +
        '2.66,0-3.23,0-6.46,0-9.69H168.05C168.05,203.92,168.05,206.34,168,208.76Z'
      }
    />
    <path
      d={
        'M213.61,0H64.11A20.29,20.29,0,0,0,43.88,20.23V169.72A20.29,20.29,0,0,0,64.11,190h149.5a2' +
        '0.29,20.29,0,0,0,20.23-20.23V20.23A20.29,20.29,0,0,0,213.61,0Zm-29,102.48H146.36v38.28h-' +
        '15V102.48H93.08v-15h38.28V49.2h15V87.47h38.28Z'
      }
    />
  </svg>
)

export default Replace
