import { Button, Col } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

const BuildingBlock = (props) => {
  const isDisabled = props.block.enableBuilder === false || !props.block.enabled
  const disabledClassSuffix = !isDisabled ? '' : '-disabled'
  return (
    <Col
      key={props.block.type}
      xs={12}
      md={8}
      lg={6}
      xxl={4}
      style={props.style}
    >
      <div
        className={`builders__block-box${disabledClassSuffix}`}
        onClick={() => {
          if (!isDisabled) {
            props.history.push(props.block.route)
          }
        }}
      >
        <div>
          <span>
            <i
              className={`fa fa-${props.block.icon} builders__block-box-icon`}
            />
          </span>
        </div>
        <div>{props.block.title}</div>
      </div>
      <div className={`builders__block-text${disabledClassSuffix}`}>
        {props.block.description}
      </div>
      <div className={'builders__block-action'}>
        <Button
          disabled={isDisabled}
          onClick={() => props.history.push(props.block.route)}
        >
          <span>
            <i className="fa fa-plus-circle" /> {props.block.title}
          </span>
        </Button>
      </div>
    </Col>
  )
}

BuildingBlock.defaultProps = {
  style: {},
}

BuildingBlock.propTypes = {
  style: PropTypes.object,
  block: PropTypes.shape({
    type: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    route: PropTypes.string,
    enabled: PropTypes.bool,
  }),
}
export default BuildingBlock
