import * as modalActions from '../actions/modal'

const INITIAL_STATE = {
  modalComponent: '',
  modalOpen: false,
  modalMessage: {},
  modalValid: false,
  modalProps: {},
}

function modal(state = INITIAL_STATE, action) {
  switch (action.type) {
    case modalActions.OPEN_MODAL:
      return Object.assign({}, state, {
        modalComponent: action.modal,
        modalOpen: true,
        modalValid: true,
        modalProps: action.props,
      })

    case modalActions.CLOSE_MODAL:
      return Object.assign({}, state, {
        modalOpen: false,
        modalComponent: '',
        modalProps: {},
      })

    case modalActions.SET_MESSAGE:
      return Object.assign({}, state, { modalMessage: action.msg })

    default:
      return state
  }
}

export default modal
