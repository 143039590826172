import { Button, Form } from 'antd'
import React from 'react'
import { FEATURE__BUILDER_TYPE } from '../../../../dux/constants/builders'
import AssetPreview from '../../../library/AssetPreview'

class ViewRelatedAssetForm extends React.PureComponent {
  state = {
    pdfMenuKey: 'content',
  }

  onPdfMenuClick = (e) => this.setState({ pdfMenuKey: e.key })

  render() {
    const { builderType, prevAction, relatedAsset, allAssets, onCancel } =
      this.props

    return (
      <div className="builders__sider-form">
        <div className="builders__sider-form-content">
          <div className="builders__sider-header-container">
            <div className="builders__sider-header-icon" onClick={prevAction}>
              <i className="fa fa-long-arrow-left" />
            </div>
            <div className="builders__sider-header-text ">
              {builderType === FEATURE__BUILDER_TYPE
                ? 'Related Asset Details'
                : 'Hotspot Details'}
            </div>
          </div>
          <Form.Item
            label={
              <span className="builders__sider-form-label">Asset Title</span>
            }
          >
            {allAssets[relatedAsset.assetId].title}
          </Form.Item>
          <Form.Item
            label={
              <span className="builders__sider-form-label">
                Asset Description
              </span>
            }
          >
            {allAssets[relatedAsset.assetId].description}
          </Form.Item>
          <Form.Item
            label={
              <span className="builders__sider-form-label">Display Text</span>
            }
          >
            {relatedAsset.displayText}
          </Form.Item>
          <Form.Item
            label={<span className="builders__sider-form-label">Preview</span>}
          >
            {allAssets[relatedAsset.assetId] && (
              <div className="builders__asset_preview_container">
                <AssetPreview
                  asset={allAssets[relatedAsset.assetId]}
                  allAssets={allAssets}
                  thumbnail={'350x'}
                  onPdfMenuClick={this.onPdfMenuClick}
                  pdfMenuKey={this.state.pdfMenuKey}
                  previewStyle="light"
                />
              </div>
            )}
          </Form.Item>
        </div>
        <div className="builders__sider-button-container">
          <span className="builders__button">
            <Button onClick={onCancel}>Cancel</Button>
          </span>
          <span className="builders__button">
            <Button type="primary" disabled>
              Create
            </Button>
          </span>
        </div>
      </div>
    )
  }
}

export default ViewRelatedAssetForm
