import { Modal } from 'antd'
import React from 'react'
import BulkEditForm from './BulkEditForm'
import '../styles/Modal.css'

class BulkEditModal extends React.Component {
  save = () => {
    this.form.validateFields((err) => {
      if (!err) {
        this.tags.handleInputConfirm(null, () => {
          const metadata = {
            title: this.form.getFieldValue('title') || '',
            description: this.form.getFieldValue('description') || '',
            tags: this.tags.getTags(),
          }

          this.props.assetActions.bulkEdit(
            this.props.modalProps.assets,
            metadata,
            this.props.folders.activeFolder,
            this.props.modalProps.new
          )
        })
      }
    })
  }

  saveFormRef = (ref) => (this.form = ref && ref.props && ref.props.form)
  saveTagsRef = (tags) => (this.tags = tags)

  render() {
    return (
      <Modal
        title="Edit Asset Metadata (multiple)"
        okText="SAVE"
        onOk={this.save}
        cancelText="Cancel"
        onCancel={this.props.modalActions.closeModal}
        visible={this.props.modal.modalOpen}
        confirmLoading={this.props.appState.asyncPending}
        maskClosable={false}
      >
        <div style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
          {' '}
          There are{' '}
          <span className="modal__bulkedit-highlight">
            {this.props.modalProps.assets.length}
          </span>{' '}
          files in this batch. These edits will be applied to all selected
          files.
        </div>

        <BulkEditForm
          wrappedComponentRef={this.saveFormRef}
          assets={this.props.modalProps.assets}
          saveTagsRef={this.saveTagsRef}
          single={false}
        />
      </Modal>
    )
  }
}

export default BulkEditModal
