import { Button, Form, Icon, Input } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/App-Public.css'

const RegisterForm = (props) => {
  return (
    <div>
      <div className="public__phone-icon">
        <img
          className="public__logo"
          src={`${props.config.baseUrl}/BAM-logo-v2.svg`}
          alt="logo"
        />
      </div>

      <div className="bam-login__form-container">
        <div className="bam-login__form-text-top">
          Already have an account? <Link to="/login">Sign in</Link>
        </div>
        <div className="bam-login__form-title">Create an Account</div>
        <div>
          <WrappedBAMRegisterForm ref={props.saveFormRef} {...props} />
        </div>
      </div>
    </div>
  )
}

export default RegisterForm

const FormItem = Form.Item
const BAMRegisterForm = (props) => {
  const { getFieldDecorator } = props.form
  const clearError = (e) => {
    if (props.form.getFieldError(e.target.id))
      props.form.setFields({ [e.target.id]: { errors: '' } })
  }
  return (
    <Form
      onSubmit={props.handleSubmit}
      className="login-form"
      layout="vertical"
      hideRequiredMark
    >
      <FormItem label={<span className="bam-form__item">Name</span>}>
        {getFieldDecorator('Name', {
          onChange: clearError,
        })(<Input type="text" />)}
      </FormItem>
      <FormItem
        label={<span className="bam-form__item">Email address</span>}
        hasFeedback
        validateStatus={props.form.getFieldError('Email') ? 'error' : null}
      >
        {getFieldDecorator('Email', {
          initialValue: props.email,
          onChange: clearError,
          validateTrigger: 'onBlur',
          rules: [
            {
              type: 'email',
              message: 'The input is not a valid email address',
            },
          ],
        })(
          <Input
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email"
          />
        )}
      </FormItem>
      <FormItem label={<span className="bam-form__item">Invitation Code</span>}>
        {getFieldDecorator('Invitation', {
          initialValue: props.invitation,
          onChange: clearError,
        })(<Input type="text" />)}
      </FormItem>
      <FormItem
        label={<span className="bam-form__item">Password</span>}
        hasFeedback
        validateStatus={props.form.getFieldError('Password') ? 'error' : null}
      >
        {getFieldDecorator('Password', {
          initialValue: '',
          validateTrigger: 'onBlur',
          onChange: clearError,
          rules: [
            {
              type: 'string',
              min: 8,
              message: 'Password must be at least 8 characters',
            },
          ],
        })(
          <Input
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
          />
        )}
      </FormItem>
      <FormItem
        label={<span className="bam-form__item">Confirm password</span>}
        hasFeedback
        validateStatus={
          props.form.getFieldError('Confirm Password') ? 'error' : null
        }
      >
        {getFieldDecorator('Confirm Password', {
          initialValue: '',
          onChange: clearError,
          validateTrigger: 'onBlur',
          rules: [
            {
              type: 'string',
              min: 8,
              message: 'Password must be at least 8 characters.',
            },
            {
              validator(rule, value, callback) {
                if (value !== props.form.getFieldValue('Password'))
                  return callback(new Error('Passwords must match'))
                else return callback()
              },
            },
          ],
        })(
          <Input
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Re-enter password"
          />
        )}
      </FormItem>
      <FormItem className="bam-form__item">
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={props.asyncPending}
        >
          Create an account
        </Button>
      </FormItem>
      <div className="bam-login__form-error">{props.errorMsg}</div>
      <Link
        to={{
          pathname: '/privacy',
          state: {
            cssClass: 'light',
          },
        }}
      >
        Privacy policy
      </Link>
    </Form>
  )
}

const WrappedBAMRegisterForm = Form.create()(BAMRegisterForm)
