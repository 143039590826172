import { NAVIGATOR__MINIMUM_ZONE_SIZE } from '../../../dux/constants/builders'

export const getCenter = (width, height) => {
  return {
    x: Math.floor(width / 2),
    y: Math.floor(height / 2),
  }
}

// Helper to set thumbnail size for smaller screens
export const getThumbnailWidth = (width) => {
  if (width < 500) return 250
  if (width < 750) return 500
  if (width < 1000) return 750
  if (width < 1250) return 1000
  return width
}

// Returns true if click occurs over existing nav zone
export const checkClickLocation = (zones, xPos, yPos) => {
  let over = false
  zones.forEach((zone) => {
    if (
      xPos > zone.topLeft.x &&
      xPos < zone.bottomRight.x &&
      yPos > zone.topLeft.y &&
      yPos < zone.bottomRight.y
    )
      over = true
  })
  return over
}

// If any of these conditions are true, the boxes do not overlap
// Left edge of newbox is right of right edge of zone
// Right edge of newbox is left of left edge of zone
// Top of newbox is below bottom of zone
// Bottom of newbox is above top of zone
// Option exclude ID for skipping self
export const isOverlapping = (zones, newBox, excludeId = null) => {
  let overlap = false
  zones.forEach((zone) => {
    if (zone.id !== excludeId) {
      if (
        !(
          newBox.topLeft.x > zone.bottomRight.x ||
          newBox.bottomRight.x < zone.topLeft.x ||
          newBox.topLeft.y < zone.bottomRight.y ||
          newBox.bottomRight.y > zone.topLeft.y
        )
      ) {
        overlap = true
      }
    }
  })
  return overlap
}

export const isOutsideImage = (newBox) => {
  let outside = false
  if (
    newBox.topLeft.x > 1 ||
    newBox.topLeft.x < -1 ||
    newBox.bottomRight.x > 1 ||
    newBox.bottomRight.x < -1 ||
    newBox.topLeft.y > 1 ||
    newBox.topLeft.y < -1 ||
    newBox.bottomRight.y > 1 ||
    newBox.bottomRight.y < -1
  )
    outside = true
  return outside
}

export const zoneSizeExceedsMinimum = (state, imageWidth, imageHeight) => {
  // Create a new zone if it meets the minimum size criteria
  return (
    ((state.bottomRight.x - state.topLeft.x) / 2) * imageWidth >
      NAVIGATOR__MINIMUM_ZONE_SIZE &&
    ((state.topLeft.y - state.bottomRight.y) / 2) * imageHeight >
      NAVIGATOR__MINIMUM_ZONE_SIZE
  )
}

const IPHONEX_PADDING_TOP = 0.07178
const IPHONEX_PADDING_SIDE = 0.1082
const IPHONE_PADDING_TOP = 0.09208
const IPHONE_PADDING_SIDE = 0.21081
const ANDROID_PADDING_TOP = 0.04429
const ANDROID_PADDING_SIDE = 0.08863
const TABLET_PADDING_TOP = 0.08407
const TABLET_PADDING_SIDE = 0.06374
export const getTemplateDimensions = (type, template, width, height) => {
  switch (template) {
    case 'iPhoneX': {
      if (type === 'interior') {
        return {
          width: width - width * IPHONEX_PADDING_SIDE,
          height: height - height * IPHONEX_PADDING_TOP,
        }
      } else {
        return {
          width: width + width * IPHONEX_PADDING_SIDE,
          height: height + height * IPHONEX_PADDING_TOP,
        }
      }
    }
    case 'iPhone': {
      if (type === 'interior') {
        return {
          width: width - width * IPHONE_PADDING_SIDE,
          height: height - height * IPHONE_PADDING_TOP,
        }
      } else {
        return {
          width: width + width * IPHONE_PADDING_SIDE,
          height: height + height * IPHONE_PADDING_TOP,
        }
      }
    }
    case 'iPad': {
      if (type === 'interior') {
        return {
          width: width - width * TABLET_PADDING_SIDE,
          height: height - height * TABLET_PADDING_TOP,
        }
      } else {
        return {
          width: width + width * TABLET_PADDING_SIDE,
          height: height + height * TABLET_PADDING_TOP,
        }
      }
    }
    case 'android': {
      if (type === 'interior') {
        return {
          width: width - width * ANDROID_PADDING_SIDE,
          height: height - height * ANDROID_PADDING_TOP,
        }
      } else {
        return {
          width: width + width * ANDROID_PADDING_SIDE,
          height: height + height * ANDROID_PADDING_TOP,
        }
      }
    }
    default:
      return {
        width,
        height,
      }
  }
}
