import update from 'immutability-helper'
import * as searchActions from '../actions/search'

export const ASCENDING = 1
export const DESCENDING = -1

const INITIAL_STATE = {
  searchQuery: '',
  searchResults: [],
  lastSearch: '',
  nextPage: 0,
  sortKey: {
    value: '_score',
    displayValue: 'Relevance',
    type: 'number',
    defaultValue: 0,
  },
  searchResultsById: {},
  sortDirection: DESCENDING,
  sortFields: {
    relevance: {
      value: '_score',
      displayValue: 'Relevance',
      type: 'number',
      defaultValue: 0,
    },
    description: {
      value: 'description',
      displayValue: 'Description',
      type: 'text',
      defaultValue: '',
    },
    filename: {
      value: 'filename',
      displayValue: 'Filename',
      type: 'text',
      defaultValue: '',
    },
    title: {
      value: 'title',
      displayValue: 'Title',
      type: 'text',
      defaultValue: '',
    },
    size: {
      value: 'size',
      displayValue: 'Size',
      type: 'number',
      defaultValue: 0,
    },
    updated: {
      value: 'updated',
      displayValue: 'Date Updated',
      type: 'number',
      defaultValue: 0,
    },
  },
}

function search(state = INITIAL_STATE, action) {
  let newState
  switch (action.type) {
    case searchActions.SET_QUERY:
      return {
        ...state,
        searchQuery: action.query,
      }

    case searchActions.SET_SORT_KEY:
      return {
        ...state,
        sortKey: action.key,
      }

    case searchActions.TOGGLE_SORT_DIR:
      return {
        ...state,
        sortDirection:
          state.sortDirection === ASCENDING ? DESCENDING : ASCENDING,
      }

    case searchActions.PROCESS_SEARCH_RESULTS:
      newState = state
      action.results.forEach((assetId) => {
        newState = update(newState, {
          searchResultsById: {
            [assetId]: {
              $set: Object.assign({}, action.assetsById[assetId]),
            },
          },
        })
      })

      return {
        ...newState,
        searchResults: action.results,
        lastSearch: state.searchQuery,
        nextPage: state.nextPage + 1,
      }

    case searchActions.CLEAR_SEARCH_RESULTS: {
      return {
        ...state,
        searchResults: [],
        nextPage: 0,
      }
    }

    case searchActions.SET_LAST_SEARCH: {
      return {
        ...state,
        lastSearch: action.search,
      }
    }
    default:
      return state
  }
}

export default search
