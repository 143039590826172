import { Button, Input, Steps } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

const StressTest = (props) => (
  <div>
    <input
      id="fileInput"
      multiple={true}
      type="file"
      style={{ display: 'none' }}
      onChange={props.handleFileChange}
    />
    <Steps direction="vertical" current={props.step}>
      <Steps.Step
        title={
          <span>
            <Button onClick={props.openFilePicker}>Files</Button>{' '}
            <Button onClick={props.handleRandom}>Random</Button>
          </span>
        }
        description="Choose a set of files or use random images"
      />
      <Steps.Step
        title={
          <span>
            <Input type="number" onChange={props.handleIterationChange} />
            <Button onClick={props.handleIterations}>Set Iterations</Button>
          </span>
        }
        description="select # of iterations"
      />
      <Steps.Step
        title={
          <Button onClick={props.submitStressTest}>
            <span style={{ display: 'flex', alignContent: 'center' }}>
              <i className="material-icons">code</i>
              Stress Test
            </span>
          </Button>
        }
        description="run stress test"
      />
      <Steps.Step title="Final step" description="check console log results" />
    </Steps>
  </div>
)

StressTest.propTypes = {
  openFilePicker: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  handleIterationChange: PropTypes.func.isRequired,
  handleIterations: PropTypes.func.isRequired,
  submitStressTest: PropTypes.func.isRequired,
}

export default StressTest
