import PropTypes from 'prop-types'
import React from 'react'

const FormDivider = (props) => {
  const color = props.color || '#000000'
  const opacity = props.opacity || 1
  return (
    <div
      className="builder__sider-form-divider"
      style={{ backgroundColor: color, opacity: opacity }}
    />
  )
}

FormDivider.propTypes = {
  color: PropTypes.string,
  opacity: PropTypes.number,
}

export default FormDivider
