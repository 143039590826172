import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as appActions from '../../dux/actions/appActions'
import BuilderHome from './BuilderHome'
import AssetBuilder from './main/AssetBuilder'
import '../styles/Builders.css'

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appActions, dispatch),
})

class BuilderRouter extends React.Component {
  componentDidMount() {
    this.props.appActions.toggleSidebar(0)
  }

  componentWillUnmount() {
    this.props.appActions.toggleSidebar(-1) //Negative results in DEFAULT_SIDEBAR_WIDTH
  }

  render() {
    return (
      <div className="builders__container">
        <Route path="/builders/home" component={BuilderHome} />
        <Route
          path="/builders/comparison/:id?"
          render={(props) => (
            <AssetBuilder {...props} builderType={'comparison'} />
          )}
        />
        <Route
          path="/builders/feature/:id?"
          render={(props) => (
            <AssetBuilder {...props} builderType={'feature'} />
          )}
        />
        <Route
          path="/builders/navigator/:id?"
          render={(props) => (
            <AssetBuilder {...props} builderType={'navigator'} />
          )}
        />
        <Route
          path="/builders/news/:id?"
          render={(props) => <AssetBuilder {...props} builderType={'news'} />}
        />
        <Route
          path="/builders/product/:id?"
          render={(props) => (
            <AssetBuilder {...props} builderType={'product'} />
          )}
        />
      </div>
    )
  }
}

BuilderRouter.propTypes = {
  appActions: PropTypes.object,
}

export default connect(null, mapDispatchToProps)(BuilderRouter)
