import React from 'react'

const SvgSfCloud = (props) => (
  <svg viewBox="0 0 1148.962 804.211" {...props}>
    <g data-name="Layer 2">
      <path
        d={
          'M901.257 108.933a245.068 245.068 0 0 0-100.187 21.294C765.828 67.444 699.668 25.16 62' +
          '3.814 25.16c-57.059 0-108.646 23.946-145.695 62.547A222.9 222.9 0 0 0 300.678 0C177.4' +
          '41 0 77.525 99.781 77.525 222.849a221.867 221.867 0 0 0 18.355 88.636A194.028 194.028' +
          ' 0 0 0 0 479.335c0 107.009 85.85 193.753 191.761 193.753a190.643 190.643 0 0 0 39.413' +
          '-4.1c29.1 78.944 104.914 135.226 193.871 135.226 85.411 0 158.715-51.892 190.157-125.' +
          '888a181.061 181.061 0 0 0 237.253-74.5 247.377 247.377 0 0 0 48.8 4.863c136.8 0 247.7' +
          '05-111.871 247.705-249.884.002-138-110.907-249.872-247.703-249.872z'
        }
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export default SvgSfCloud
