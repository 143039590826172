import { Icon, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

const WarningChip = ({ message }) => {
  return (
    <Tooltip title={message} placement="right">
      <Icon type="exclamation-circle" style={styles.warningIcon} />
    </Tooltip>
  )
}

const styles = {
  warningIcon: {
    fontSize: '16px',
    margin: '0px 10px',
    color: '#fa8c16',
  },
}

WarningChip.propTypes = {
  message: PropTypes.string,
}

export default WarningChip
