import Title from 'antd/lib/typography/Title'
import React from 'react'

function LoadingError() {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Title
        level={2}
        className="assetdetail__calc-error-text"
        style={{ color: 'white', padding: '0 80px' }}
      >
        {`Something went wrong loading this calculator's preview.`}
      </Title>
      <Title
        level={4}
        className="assetdetail__calc-error-text"
        style={{ color: 'lightgray', padding: '0 80px' }}
      >
        {`If this problem persists, use the help tab in the top right to make sure we are aware of it.`}
      </Title>
    </div>
  )
}

export default LoadingError
