import { Button, Divider, Tag, Tooltip } from 'antd'
import filesize from 'filesize'
import { get } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import {
  TRANSCODING_STATUS_COMPLETE,
  TRANSCODING_STATUS_HIDDEN,
} from '../../../dux/constants/assets'
import { getLabel, getUserById } from '../../../dux/modules/utils'
import Attribute from '../../BAM_UI/Attribute'
import EditableAttribute from '../../BAM_UI/EditableAttribute'
import UserChip from '../../BAM_UI/UserChip'
import WarningChip from '../../BAM_UI/WarningChip.js'
import EditableTagGroup from '../../common/EditableTagGroup'
import UploadAction from '../UploadAction'

const validImageColorSpaces = ['srgb', 'rgb']

const ReplaceAsset = (props) => {
  return (
    <>
      <div className="assetdetail__panel-content-title">Replace Asset</div>
      <div className="assetdetail__panel-content-value">
        <UploadAction freePosition {...props} />
      </div>
    </>
  )
}
class AssetDetailPanel extends React.PureComponent {
  getTranscodingInfo = (asset) => {
    let info = <span>Unable to find transcoded video</span>
    if (asset.file_type.startsWith('video') && asset.videoSrc) {
      info = (
        <div>
          <span>{asset.videoSrc.status}: </span>
          <span>{asset.videoSrc.message}</span>
        </div>
      )
    }
    return info
  }

  getTranscodingLabel = (asset) => {
    let label = <span>Transcoding</span>
    let button = null
    if (asset.videoSrc.status === TRANSCODING_STATUS_COMPLETE)
      button = (
        <span>
          <Button
            className="assetdetail__button-link"
            onClick={this.props.hideTranscodedVideo}
          >
            Hide
          </Button>
        </span>
      )
    if (asset.videoSrc.status === TRANSCODING_STATUS_HIDDEN)
      button = (
        <span>
          <Button
            className="assetdetail__button-link"
            onClick={this.props.showTranscodedVideo}
          >
            Show
          </Button>
        </span>
      )
    return (
      <span>
        {label} {button}
      </span>
    )
  }

  getWarningMessage = (asset) => {
    const colorSpace = get(asset, 'imageAttributes.colorSpace', null)
    if (!colorSpace) {
      return null
    }

    if (!validImageColorSpaces.includes(colorSpace)) {
      return (
        `The color space for this asset is set to ${colorSpace}. It may not display ` +
        `correctly in the mobile sales tool.`
      )
    } else {
      return null
    }
  }

  render() {
    const {
      asset,
      isEditing,
      editState,
      editChangeHandler,
      saveTagsRef,
      uploadActionProps,
      shouldShowReplaceAction,
    } = this.props
    const assetWarning = this.getWarningMessage(asset)
    return (
      <div className="assetdetail__panel-content">
        {isEditing ? (
          <Fragment>
            {shouldShowReplaceAction ? (
              <ReplaceAsset {...uploadActionProps} />
            ) : null}
            <Divider className="assetdetail__panel-content-divider" />
            <EditableAttribute
              type="text"
              id="filename"
              label="File Name"
              cssLabelClass="assetdetail__panel-content-title"
              value={editState.filename}
              cssValueClass="assetdetail__panel-content-value-editable"
              changeHandler={editChangeHandler}
            />
            <EditableAttribute
              type="textarea"
              id="description"
              label="Description"
              cssLabelClass="assetdetail__panel-content-title"
              value={editState.description}
              cssValueClass="assetdetail__panel-content-value-editable"
              changeHandler={editChangeHandler}
            />
            <Attribute
              label="Tags"
              cssLabelClass="assetdetail__panel-content-title"
              value={
                <EditableTagGroup
                  defaultTags={asset.tags}
                  cssClass="assetdetail__panel-content-value-editable"
                  ref={saveTagsRef}
                />
              }
            />
            <Divider className="assetdetail__panel-content-divider" />
          </Fragment>
        ) : (
          <Fragment>
            <Attribute
              label="File Name"
              cssLabelClass="assetdetail__panel-content-title"
              value={asset.filename}
              cssValueClass="assetdetail__panel-content-value"
            />
            <Attribute
              label="Description"
              cssLabelClass="assetdetail__panel-content-title"
              value={asset.description}
              cssValueClass="assetdetail__panel-content-value"
            />
            <Attribute
              label="Tags"
              cssLabelClass="assetdetail__panel-content-title"
              value={
                asset.tags && asset.tags.length > 0 ? (
                  asset.tags
                    .filter((tag) => !tag.startsWith('SYS.'))
                    .map((tag) => {
                      const isLongTag = tag.length > 20
                      const tagElem = (
                        <Tag
                          key={tag}
                          closable={false}
                          style={{ marginBottom: '10px', cursor: 'auto' }}
                        >
                          {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </Tag>
                      )
                      return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                          {tagElem}
                        </Tooltip>
                      ) : (
                        tagElem
                      )
                    })
                ) : (
                  <Tag style={{ visibility: 'hidden' }}>X</Tag>
                )
              }
              cssValueClass="assetdetail__panel-content-value"
            />
          </Fragment>
        )}
        <Attribute
          label="Asset Type"
          cssLabelClass={`assetdetail__panel-content-title${
            isEditing ? '-uneditable' : ''
          }`}
          value={
            <Fragment>
              <span>
                <i className={`fa ${asset.icon}`} />
                &nbsp;
                {getLabel(asset)}
              </span>
              {!!assetWarning && <WarningChip message={assetWarning} />}
            </Fragment>
          }
          cssValueClass={`assetdetail__panel-content-value${
            isEditing ? '-uneditable' : ''
          }`}
        />
        <Attribute
          label="Created Date"
          cssLabelClass={`assetdetail__panel-content-title${
            isEditing ? '-uneditable' : ''
          }`}
          value={
            <Fragment>
              <div>
                {moment(asset.created).format('MMM D, YYYY [at] h:mm a')}
              </div>
              <UserChip user={getUserById(asset.owner, this.props.allUsers)} />
            </Fragment>
          }
          cssValueClass={`assetdetail__panel-content-value${
            isEditing ? '-uneditable' : ''
          }`}
        />
        <Attribute
          label="Last Modified"
          cssLabelClass={`assetdetail__panel-content-title${
            isEditing ? '-uneditable' : ''
          }`}
          value={
            <Fragment>
              <div>
                {moment(asset.updated).format('MMM D, YYYY [at] h:mm a')}
              </div>
              <UserChip
                user={getUserById(asset.updated_by, this.props.allUsers)}
              />
            </Fragment>
          }
          cssValueClass={`assetdetail__panel-content-value${
            isEditing ? '-uneditable' : ''
          }`}
        />
        <Attribute
          label="Size"
          cssLabelClass={`assetdetail__panel-content-title${
            isEditing ? '-uneditable' : ''
          }`}
          value={asset.size && filesize(asset.size)}
          cssValueClass={`assetdetail__panel-content-value${
            isEditing ? '-uneditable' : ''
          }`}
        />
        {asset.file_type.startsWith('video') && asset.videoSrc && (
          <Attribute
            label={this.getTranscodingLabel(asset)}
            cssLabelClass={`assetdetail__panel-content-title${
              isEditing ? '-uneditable' : ''
            }`}
            value={this.getTranscodingInfo(asset)}
            cssValueClass={`assetdetail__panel-content-value${
              isEditing ? '-uneditable' : ''
            }`}
          />
        )}
      </div>
    )
  }
}

AssetDetailPanel.propTypes = {
  asset: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  editChangeHandler: PropTypes.func.isRequired,
  saveTagsRef: PropTypes.func,
  editState: PropTypes.object,
  showTranscodedVideo: PropTypes.func,
  hideTranscodedVideo: PropTypes.func,
  allUsers: PropTypes.object,
}

AssetDetailPanel.defaultProps = {
  isEditing: false,
}

export default AssetDetailPanel
