import { Checkbox } from 'antd'
import React from 'react'
import { assetName } from '../../dux/modules/utils'

class AssetSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      assets: this.props.assets.map((asset) => {
        let a = { ...this.props.allAssets[asset] }
        a.checked = true
        return a
      }),
    }
  }

  checkAsset = (index) => {
    let newAssets = [...this.state.assets]
    newAssets[index].checked = !newAssets[index].checked
    this.setState({ assets: newAssets }, () =>
      this.props.updateSelected(
        this.state.assets.filter((assets) => assets.checked).map((a) => a.id)
      )
    )
  }

  render() {
    return (
      <div className="publish__list">
        {this.state.assets.map((asset, index) => {
          return (
            <div key={asset.id} className="publish__list-item">
              <span style={{ width: '50%' }}>
                <Checkbox
                  checked={asset.checked}
                  onChange={() => this.checkAsset(index)}
                />{' '}
                {assetName(asset)}{' '}
              </span>
              <span style={{ width: '50%' }}>
                {asset.publishedTo.map((folder) => (
                  <div key={folder}>
                    Published to: {this.props.allFolders[folder].name}
                  </div>
                ))}
              </span>
            </div>
          )
        })}
      </div>
    )
  }
}

export default AssetSelector
