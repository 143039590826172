import {
  all,
  call,
  put,
  select,
  spawn,
  take,
  takeEvery,
} from 'redux-saga/effects'
import { SEARCH_FOLDER } from '../constants/folder'
import { getRefreshedState } from '../reducers/appState'
import * as apiActions from './api'
import * as assetActions from './asset'
import * as folderActions from './folder'
import * as modalActions from './modal'
import * as userActions from './user'

export const CLEAR_LOCAL_STORAGE = 'CLEAR_LOCAL_STORAGE'
export const SHOW_PROFILE_UPLOADER = 'SHOW_PROFILE_UPLOADER'
export const SELECT_ASSET = 'SELECT_ASSET'
export const SELECT_ASSETS = 'SELECT_ASSETS'
export const CLEAR_SELECTED_ASSETS = 'CLEAR_SELECTED_ASSETS'
export const INITIATE_ASYNC = 'INITIATE_ASYNC'
export const COMPLETE_ASYNC = 'COMPLETE_ASYNC'
export const SET_WINDOW_SIZE = 'SET_WINDOW_SIZE'
export const SET_CARDWIDTH = 'SET_CARDWIDTH'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const SET_TOKEN_EXPIRATION = 'SET_TOKEN_EXPIRATION'
export const INITIALIZE = 'INITIALIZE'
export const INITIALIZATION_COMPLETE = 'INITIALIZATION_COMPLETE'
export const SET_MENU_ITEM = 'SET_MENU_ITEM'
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION'
export const APPLY_LIBRARY_SORT = 'APPLY_LIBRARY_SORT'
export const APPLY_LIBRARY_SORT_SUCCESS = 'APPLY_LIBRARY_SORT_SUCCESS'
export const TOGGLE_LIB_SORT_DIR = 'TOGGLE_LIB_SORT_DIR'
export const SCROLL_TO_TOP = 'SCROLL_TO_TOP'
export const RENAMING_FOLDER = 'RENAMING_FOLDER'
export const ADD_OPEN_FOLDERKEY = 'ADD_OPEN_FOLDERKEY'
export const REMOVE_OPEN_FOLDERKEY = 'REMOVE_OPEN_FOLDERKEY'
export const SET_OPEN_FOLDER_KEYS = 'SET_OPEN_FOLDER_KEYS'
export const ADD_OPEN_ENDPOINTKEY = 'ADD_OPEN_ENDPOINTKEY'
export const REMOVE_OPEN_ENDPOINTKEY = 'REMOVE_OPEN_ENDPOINTKEY'
export const SET_OPEN_ENDPOINTKEYS = 'SET_OPEN_ENDPOINTKEYS'
export const SET_STARTUP_ROUTE = 'SET_STARTUP_ROUTE'
export const SET_ACTIVE_ENDPOINT = 'SET_ACTIVE_ENDPOINT'
export const SET_ACTIVE_VIEW = 'SET_ACTIVE_VIEW'
export const SET_ACTIVE_ASSET = 'SET_ACTIVE_ASSET'
export const REFRESH_STATE = 'REFRESH_STATE'
export const ENABLE_UPLOAD_CONTINUE = 'ENABLE_UPLOAD_CONTINUE'
export const SET_SUCCESSFUL_UPLOADS = 'SET_SUCCESSFUL_UPLOADS'
export const UPLOAD_COMPLETE = 'UPLOAD_COMPLETE'
export const SET_BACK_BUTTON_FLAG = 'SET_BACK_BUTTON_FLAG'
export const SET_APP_CONFIG = 'SET_APP_CONFIG'
export const CLEAR_RESET_PASSWORD_COMPLETE = 'CLEAR_RESET_PASSWORD_COMPLETE'
export const APPLY_STATUS_FILTER = 'APPLY_STATUS_FILTER'
export const CLEAR_STATUS_FILTER = 'CLEAR_STATUS_FILTER'
export const SET_MAX_SIDEBAR_WIDTH = 'SET_MAX_SIDEBAR_WIDTH'
export const SET_MAINTENANCE_MODE = 'SET_MAINTENANCE_MODE'
export const SET_EDITMODE_ASSET = 'SET_EDITMODE_ASSET'
export const ADD_PENDING_ACTION = 'ADD_PENDING_ACTION'
export const REMOVE_PENDING_ACTION = 'REMOVE_PENDING_ACTION'
export const SET_BAM_VERSION = 'SET_BAM_VERSION'
export const VERSION_MISMATCH = 'VERSION_MISMATCH'
export const SET_VERSION_MISMATCH = 'SET_VERSION_MISMATCH'
export const SET_COUNTDOWN_TIMER = 'SET_COUNTDOWN_TIMER'
export const DECREMENT_COUNTDOWN_TIMER = 'DECREMENT_COUNTDOWN_TIMER'
export const BEGIN_FOLDER_DRAG = 'BEGIN_FOLDER_DRAG'
export const END_FOLDER_DRAG = 'END_FOLDER_DRAG'
export const SET_FOLDER_DRAG_DATA = 'SET_FOLDER_DRAG_DATA'
export const SET_FOLDER_DRAG_CLEANUP = 'SET_FOLDER_DRAG_CLEANUP'
export const SET_CLIPBOARD_CONTENT = 'SET_CLIPBOARD_CONTENT'
export const SET_ACTIVE_ANALYTICS_VIEW = 'SET_ACTIVE_ANALYTICS_VIEW'
export const SET_ACTIVE_ANALYTICS_PLATFORM = 'SET_ACTIVE_ANALYTICS_PLATFORM'
export const SET_ACTIVE_ANALYTICS_DURATION = 'SET_ACTIVE_ANALYTICS_DURATION'
export const SET_ANALYTICS_CUSTOM_DATE_RANGE = 'SET_ANALYTICS_CUSTOM_DATE_RANGE'
export const SET_BULK_DOWNLOAD_LINK = 'app/SET_BULK_DOWNLOAD_LINK'
export const REFRESH_AND_LOG_STATE = 'REFRESH_AND_LOG_STATE'

export function clearLocalStorage(items) {
  return {
    type: CLEAR_LOCAL_STORAGE,
    items,
  }
}

/*
 * showProfileUploader - sets a flag to display the uploader component within
 *  the EditProfileModal
 * @param show - boolean
 */
export function showProfileUploader(show) {
  return {
    type: SHOW_PROFILE_UPLOADER,
    show,
  }
}

/*
 *  selectAsset: dispatch SELECT_ASSET action
 *  param: id - string ID of the asset
 *    will add/remove the requested action to the array of selected assets
 */
export function selectAsset(id) {
  return {
    type: SELECT_ASSET,
    payload: id,
  }
}

export const selectAssets = (ids) => ({
  type: SELECT_ASSETS,
  ids,
})
/*
 *  clearSelectedAssets: dispatch CLEAR_SELECTED_ASSETS action
 *  param: id - string ID of the asset
 *   will remove all assets from the array of selected assets
 */
export function clearSelectedAssets() {
  return {
    type: CLEAR_SELECTED_ASSETS,
  }
}

/*
 * setWindowSize: this is a JS function to check the window dimensions.  This
 * action stores the size in redux - it is used to set sidebar width
 */
export function setWindowSize(size) {
  return {
    type: SET_WINDOW_SIZE,
    size,
  }
}

/*
 * setCardWidth: this is a JS function to set the width of the asset cards.
 *  This is used to determine the CSS for the asset in the card (cover vs contain)
 */
export function setCardWidth(cardWidth) {
  return {
    type: SET_CARDWIDTH,
    cardWidth,
  }
}

/*
 * toggleSidebar: this function collapses the sidebar - it is not very useful when
 * this sidebar is showing the folder tree, but might be useful in other views.
 */
export function toggleSidebar(width) {
  return {
    type: TOGGLE_SIDEBAR,
    width,
  }
}

export function setTokenExpiration(exp) {
  return {
    type: SET_TOKEN_EXPIRATION,
    time: exp,
  }
}

export function setMenuItem(item) {
  return {
    type: SET_MENU_ITEM,
    item,
  }
}

export function setScroll(pos, showMore) {
  return {
    type: SET_SCROLL_POSITION,
    pos,
    showMore,
  }
}

export const initialize = (options) => ({
  type: INITIALIZE,
  user: options.user.length > 0 ? options.user : undefined,
  path: options.path,
  config: options.config,
})

export function* watchInitialize() {
  yield takeEvery(INITIALIZE, initializeSaga)
}

export function* initializeSaga(action) {
  try {
    yield put({ type: SET_APP_CONFIG, config: action.config })
    if (action.path && !action.path.startsWith('/login')) {
      yield put({ type: SET_STARTUP_ROUTE, path: action.path })
    }
    const version = yield call(apiActions.getBAMVersion, action.config)
    yield put({ type: SET_BAM_VERSION, version })

    if (action.user) {
      yield call(userActions.restoreSessionSaga, {
        type: userActions.RESTORE_SESSION,
        user: action.user,
      })
    }

    yield put({
      type: INITIALIZATION_COMPLETE,
    })
  } catch (error) {
    yield put({ type: userActions.LOGOUT_SUCCESS })
    yield put({ type: COMPLETE_ASYNC })
  }
}

export function applyLibrarySort(newSort) {
  return {
    type: APPLY_LIBRARY_SORT,
    newSort,
  }
}

export function* watchLibrarySort() {
  yield takeEvery(APPLY_LIBRARY_SORT, applyLibrarySortSaga)
}

export function* applyLibrarySortSaga(action) {
  const { activeFolder, byId: allFolders } = yield select(
    (state) => state.folders
  )

  if (activeFolder !== SEARCH_FOLDER && !allFolders[activeFolder]) {
    yield put({ type: folderActions.SET_ACTIVE_FOLDER, id: null })
  } else {
    const assets = yield select((state) => state.assets.byId)
    const appState = yield select((state) => state.appState)
    // Default sort key for when action is initiated outside the search control
    // (like login or folder change)
    if (!action.newSort) {
      action.newSort = appState.sortKey.value
    }

    yield put({
      assets,
      newSort: action.newSort,
      sortDir: action.sortDir || appState.sortDirection,
      sortKey: appState.sortFields[action.newSort],
      type: APPLY_LIBRARY_SORT_SUCCESS,
    })
  }
}

export function toggleLibrarySortDir() {
  return {
    type: TOGGLE_LIB_SORT_DIR,
  }
}

export function setRenamingFolder(id = null) {
  return {
    type: RENAMING_FOLDER,
    id,
  }
}

/*
 *  When a sidebar folder is opened, this action will add it to the
 *  redux state.  Attribute can be used to show an open folder icon.
 */
export function addOpenFolderKey(id) {
  return {
    type: ADD_OPEN_FOLDERKEY,
    id,
  }
}
/*
 *  When a sidebar folder is closed, this action will remove it from the
 *  redux state.  Attribute can be used to show a closed folder icon.
 */
export function removeOpenFolderKey(id) {
  return {
    type: REMOVE_OPEN_FOLDERKEY,
    id,
  }
}

/*
 *  Override the open folder keys in the sidebar
 */
export const setOpenFolderKeys = (keyArray) => ({
  type: SET_OPEN_FOLDER_KEYS,
  keyArray,
})

export const addOpenEndpointKey = (id) => ({
  type: ADD_OPEN_ENDPOINTKEY,
  id,
})

export const removeOpenEndpointKey = (id) => ({
  type: REMOVE_OPEN_ENDPOINTKEY,
  id,
})

export const setOpenEndpointKeys = (ids) => ({
  type: SET_OPEN_ENDPOINTKEYS,
  ids,
})
export const setActiveEndpoint = (id) => ({
  type: SET_ACTIVE_ENDPOINT,
  id,
})

export const setStartupRoute = (path) => ({
  type: SET_STARTUP_ROUTE,
  path,
})

export const setActiveView = (view, endpoint) => ({
  type: SET_ACTIVE_VIEW,
  view,
  endpoint,
})

export const setActiveAsset = (id) => ({
  type: SET_ACTIVE_ASSET,
  id,
})

export const scrollToTop = (element) => ({
  type: SCROLL_TO_TOP,
  element,
})

export const refreshAndLogState = () => ({
  type: REFRESH_AND_LOG_STATE,
})

export function* watchRefreshAndLogState() {
  yield takeEvery(REFRESH_AND_LOG_STATE, refreshAndLogStateSaga)
}

export function* refreshAndLogStateSaga() {
  const state = yield call(refreshStateSaga)
  const { folderState, assetState, endpointState, maintenance } = state
  console.log({ folderState, assetState, endpointState, maintenance })
}

// This function assumes user has already PUT an INITIATE_ASYNC action
export function* refreshStateSaga() {
  const config = yield select((state) => state.config.appConfig)
  const user = yield select((state) => state.user)
  const data = yield call(apiActions.secureFetchSaga, {
    url: `${config.baseUrl}/api/state/zipstate/current`,
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'gzip',
    },
    init: {
      method: 'POST',
      body: JSON.stringify(user.email),
    },
  })

  yield put({
    type: REFRESH_STATE,
    folderState: data.folderState,
    assetState: data.assetState,
    endpointState: data.endpointState,
    maintenance: data.maintenance,
  })
  yield take(folderActions.SET_VIRTUAL_FOLDERS)
  yield spawn(applyLibrarySortSaga, { type: APPLY_LIBRARY_SORT })

  return yield select(getRefreshedState)
}

export const enableUploadContinue = (enabled) => ({
  type: ENABLE_UPLOAD_CONTINUE,
  enabled,
})

export const setSuccessfulUploads = (uploads, assetType) => ({
  type: SET_SUCCESSFUL_UPLOADS,
  uploads,
  assetType,
})

export const uploadCompleteHandler = () => ({
  type: UPLOAD_COMPLETE,
})

export function* watchUploadComplete() {
  yield takeEvery(UPLOAD_COMPLETE, uploadCompleteSaga)
}

export function* uploadCompleteSaga() {
  const uploadState = yield select((state) => ({
    successfulUploads: state.appState.successfulUploads,
    assetType: state.appState.assetType,
  }))

  if (uploadState.successfulUploads) {
    if (uploadState.assetType === 'userAsset') {
      const userKey = yield select((state) => state.user.email)
      yield call(assetActions.createUserAssetSaga, {
        asset: uploadState.successfulUploads[0],
        userKey,
      })
    } else {
      if (uploadState.successfulUploads.length === 1) {
        yield put({
          type: modalActions.OPEN_MODAL,
          modal: 'EditAssetModal',
          props: { asset: uploadState.successfulUploads[0], new: true },
        })
      } else {
        yield put({
          type: modalActions.OPEN_MODAL,
          modal: 'BulkEditModal',
          props: { assets: uploadState.successfulUploads, new: true },
        })
      }
    }
    // TODO: clear upload state...
  }
}

export const setBackButtonFlag = (flag = true) => ({
  type: SET_BACK_BUTTON_FLAG,
  flag,
})

export const clearResetPasswordFlag = () => ({
  type: CLEAR_RESET_PASSWORD_COMPLETE,
})

export const applyStatusFilter = (filter) => ({
  type: APPLY_STATUS_FILTER,
  filter,
})

export const clearStatusFilter = () => ({
  type: CLEAR_STATUS_FILTER,
})

export const setMaxSidebarWidth = (width) => ({
  type: SET_MAX_SIDEBAR_WIDTH,
  width,
})

export const setMaintenanceMode = (maintenance) => ({
  type: SET_MAINTENANCE_MODE,
  maintenance,
})

export const setEditModeAsset = (assetId) => ({
  type: SET_EDITMODE_ASSET,
  assetId,
})

export const addPendingAction = (action) => ({
  type: ADD_PENDING_ACTION,
  action,
})

export const removePendingAction = (action) => ({
  type: REMOVE_PENDING_ACTION,
  action,
})

export const beginFolderDrag = (id) => ({
  type: BEGIN_FOLDER_DRAG,
  id,
})

export function* watchBeginFolderDrag() {
  yield takeEvery(BEGIN_FOLDER_DRAG, beginFolderDragSaga)
}

export function* beginFolderDragSaga(action) {
  const appState = yield select((state) => state.appState)
  const activeFolder = yield select((state) => state.folders.activeFolder)

  if (action.id !== activeFolder)
    yield put({
      type: folderActions.ACTIVE_FOLDER,
      id: action.id,
      scroll: false,
    })

  const cleanup = {}
  if (appState.openFolderKeys.indexOf(action.id) > -1) {
    yield put({ type: REMOVE_OPEN_FOLDERKEY, id: action.id })
    cleanup.reopen = true
  }

  if (appState.sidebarWidth !== appState.maxSidebarWidth) {
    cleanup.toggleSidebar = appState.sidebarWidth
    yield put({ type: TOGGLE_SIDEBAR, width: appState.maxSidebarWidth })
  }

  yield put({ type: SET_FOLDER_DRAG_CLEANUP, cleanup })
}

export const endFolderDrag = (id) => ({
  type: END_FOLDER_DRAG,
  id,
})

export function* watchEndFolderDrag() {
  yield takeEvery(END_FOLDER_DRAG, endFolderDragSaga)
}

export function* endFolderDragSaga(action) {
  const cleanup = yield select((state) => state.appState.folderDragCleanup)

  if (cleanup.reopen) yield put({ type: ADD_OPEN_FOLDERKEY, id: action.id })

  if (cleanup.toggleSidebar)
    yield put({ type: TOGGLE_SIDEBAR, width: cleanup.toggleSidebar })

  yield put({ type: SET_FOLDER_DRAG_CLEANUP, cleanup: {} })
}

export const setFolderDragData = (over, above, below) => ({
  type: SET_FOLDER_DRAG_DATA,
  over,
  above,
  below,
})

export function setClipboardContent(text) {
  return {
    type: SET_CLIPBOARD_CONTENT,
    text,
  }
}

export function setActiveAnalyticsView(analyticsViewKey) {
  return {
    type: SET_ACTIVE_ANALYTICS_VIEW,
    analyticsViewKey,
  }
}

export function setActiveAnalyticsPlatform(platform) {
  return {
    type: SET_ACTIVE_ANALYTICS_PLATFORM,
    platform,
  }
}

export function setActiveAnalyticsDuration(duration) {
  return {
    type: SET_ACTIVE_ANALYTICS_DURATION,
    duration,
  }
}

export function setAnalyticsCustomDateRange(dateStart, dateEnd) {
  return {
    type: SET_ANALYTICS_CUSTOM_DATE_RANGE,
    dateStart,
    dateEnd,
  }
}

export const setBulkDownloadLink = (link) => ({
  type: SET_BULK_DOWNLOAD_LINK,
  link,
})

export default all([
  watchInitialize(),
  watchLibrarySort(),
  watchUploadComplete(),
  watchBeginFolderDrag(),
  watchEndFolderDrag(),
  watchRefreshAndLogState(),
])
