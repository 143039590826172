import update from 'immutability-helper'
import * as actions from '../actions/settings'
import * as settingsConstants from '../constants/settings'
import * as webEndpointConstants from '../constants/webEndpoints'

const INITIAL_STATE = {
  settingsMenu: [
    {
      id: 'mst',
      name: 'MST Settings',
      items: [
        {
          id: 'branding',
          icon: 'certificate',
          route: '/settings/mst/branding',
          displayName: 'Branding',
        },
        {
          id: 'featured',
          icon: 'rocket',
          route: '/settings/mst/featured',
          displayName: 'Featured Assets',
        },
        {
          id: 'menu',
          icon: 'list',
          route: '/settings/mst/menu',
          displayName: 'Menu',
        },
        {
          id: 'resources',
          icon: 'info-circle',
          route: '/settings/mst/resources',
          displayName: 'Resources',
        },
        {
          id: 'uploads',
          icon: 'cloud-upload',
          route: '/settings/mst/uploads',
          displayName: 'User Generated Content',
        },
      ],
    },
    {
      id: 'dam',
      name: 'DAM Settings',
      items: [
        {
          id: 'general',
          icon: 'sliders',
          route: '/settings/dam/general',
          displayName: 'General',
        },
        {
          id: 'builders',
          icon: 'puzzle-piece',
          route: '/settings/dam/builders',
          displayName: 'Builders',
        },
        {
          id: 'profiles',
          icon: 'address-card-o',
          route: '/settings/dam/profiles',
          displayName: 'Customer Profiles',
        },
      ],
    },
    {
      id: settingsConstants.SETTINGS_KEY_WEB_ENDPOINTS,
      name: 'Web Endpoints',
      items: [
        {
          id: 'general',
          icon: 'gear',
          route: '/settings/webendpoints/general',
          displayName: 'General',
        },
      ],
    },
    {
      id: 'permissions',
      name: 'Permissions',
      items: [
        {
          id: 'invitations',
          icon: 'id-card',
          route: '/settings/permissions/invitations',
          displayName: 'Invitations',
        },
        {
          id: 'users',
          icon: 'user',
          route: '/settings/permissions/users',
          displayName: 'Users',
        },
        {
          id: 'groups',
          icon: 'users',
          route: '/settings/permissions/groups',
          displayName: 'Groups',
        },
        {
          id: 'roles',
          icon: 'key',
          route: '/settings/permissions/roles',
          displayName: 'Privileges',
        },
      ],
    },
    {
      id: 'integrations',
      name: 'Integration Settings',
      items: [
        {
          id: 'salesforce',
          icon: 'salesforce',
          route: '/settings/integrations/salesforce',
          displayName: 'Salesforce',
        },
      ],
    },
  ],
  settingsOpenKeys: ['mst'],
  settingsActiveKey: 'mst',
  settingsActiveItem: 'branding',
  newBrandingLogo: null,
  newLogoValidationMsg: null,
  newBrandingSplashScreen: null,
  newSplashScreenValidationMsg: null,
  brandingMessage: null,
  brandingMessageType: null,
  branding: {
    logo: null,
    splashScreen: null,
  },
  featured: {
    carousel: [],
  },
  menu: {
    modules: {
      dashboard: 'Dashboard',
      library: 'Library',
      profile: 'My Profile',
      customerProfiles: 'Customer Profiles',
      news: 'News',
      navigation: 'Visual Navigation',
      comparison: 'Comparison',
      resources: 'Resources',
      calculators: 'Calculators',
      upload: 'Upload',
    },
    custom: [],
  },
  resources: {
    custom: [],
    social: {
      facebook: '',
      twitter: '',
      instagram: '',
      youTube: '',
      linkedIn: '',
    },
  },
  modules: {
    news: {
      enabled: true,
    },
    navigation: {
      enabled: false,
    },
    comparison: {
      enabled: false,
    },
    calculators: {
      enabled: false,
    },
  },
  uploads: {
    enabled: false,
    groups: [],
    maxSize: 52428800,
    metadata: {
      description: {
        defaultValue: null,
        required: false,
      },
      tags: {
        defaultValue: null,
        required: false,
      },
      title: {
        defaultValue: null,
        required: false,
      },
    },
  },
  integrations: {
    salesforce: {
      displayValue: 'Salesforce',
      enabled: false,
      client: 'test',
      defaultMessage: null,
      defaultSubject: 'tests',
    },
  },
  builders: {
    comparisons: {
      allowCustomDisclaimer: false,
      globalDisclaimer: '',
    },
  },
  profiles: {},
  general: {},
  [settingsConstants.SETTINGS_KEY_WEB_ENDPOINTS]: {
    endpointTitle: '',
    image404sByEndpointId: {},
    rootFolder: '',
    selectedEndpointId: webEndpointConstants.DEFAULT_ID,
  },
}

export default function settingsReducer(state = INITIAL_STATE, action) {
  let index = -1
  switch (action.type) {
    case actions.SET_SETTINGS_OPEN_KEYS:
      return {
        ...state,
        settingsOpenKeys: action.keys,
      }

    case actions.SET_SETTINGS_ACTIVE_KEY:
      return {
        ...state,
        settingsActiveKey: action.key,
      }

    case actions.SET_SETTINGS_ACTIVE_ITEM:
      return {
        ...state,
        settingsActiveItem: action.item,
      }

    case actions.SET_BRANDING_MESSAGE:
      return {
        ...state,
        brandingMessage: action.msg,
        brandingMessageType: action.msgType,
      }

    case actions.UPDATE_SETTINGS:
      return {
        ...state,
        [action.result.setting]: action.result.value,
      }

    case actions.ADD_ASSET_TO_CAROUSEL:
      return update(state, {
        featured: {
          carousel: {
            $push: [
              {
                asset: action.asset,
                folder: action.folder,
                displayText: action.displayText,
              },
            ],
          },
        },
      })

    case actions.REMOVE_ASSET_FROM_CAROUSEL:
      index = state.featured.carousel.findIndex(
        (obj) =>
          obj.asset === action.asset.asset && obj.folder === action.asset.folder
      )
      return update(state, {
        featured: {
          carousel: {
            $splice: [[index, 1]],
          },
        },
      })

    case actions.REPLACE_CAROUSEL_ASSET:
      index = state.featured.carousel.findIndex(
        (obj) =>
          obj.asset === action.originalAsset.asset &&
          obj.folder === action.originalAsset.folder
      )
      return update(state, {
        featured: {
          carousel: {
            $splice: [
              [
                index,
                1,
                {
                  asset: action.asset,
                  folder: action.folder,
                  displayText: action.displayText,
                },
              ],
            ],
          },
        },
      })

    case actions.RESET_CAROUSEL:
      return {
        ...state,
        featured: {
          carousel: action.carousel,
        },
      }

    case actions.SAVE_CUSTOM_MENU_RESOURCE:
      return update(state, {
        menu: {
          custom: {
            $apply: (x) =>
              x.find((resource) => resource.id === action.resource.id)
                ? x.map((resource) =>
                    resource.id !== action.resource.id
                      ? resource
                      : action.resource
                  )
                : x.concat(action.resource),
          },
        },
      })

    case actions.REMOVE_CUSTOM_MENU_RESOURCE:
      return update(state, {
        menu: {
          custom: {
            $apply: (x) =>
              x.filter((resource) => resource.id !== action.resource.id),
          },
        },
      })

    case actions.RESET_MENU_SETTINGS:
      return update(state, {
        menu: {
          custom: {
            $set: action.originalResources,
          },
        },
      })

    case actions.SET_CUSTOM_RESOURCE:
      return update(state, {
        resources: {
          custom: {
            $apply: (x) =>
              x.find((el) => el.id === action.resource.id)
                ? x.map((el) =>
                    el.id === action.resource.id ? action.resource : el
                  )
                : x.concat(action.resource),
          },
        },
      })

    case actions.REMOVE_CUSTOM_RESOURCE:
      return update(state, {
        resources: {
          custom: {
            $apply: (x) =>
              x.filter((resource) => resource.id !== action.resourceId),
          },
        },
      })

    case actions.CANCEL_RESOURCES_UPDATE:
      return update(state, {
        resources: {
          custom: {
            $set: action.originalResources,
          },
          social: {
            $set: state.resources.social,
          },
        },
      })

    case actions.SAVE_SETTINGS_SUCCESS:
      return update(state, {
        [action.setting]: {
          $set: action.value,
        },
      })

    default:
      return state
  }
}
