import { Modal } from 'antd'
import React from 'react'

const DeleteModal = (props) => {
  let message
  if (props.modal.modalProps.type === 'asset') {
    message = `You have selected ${props.appState.selectedAssets.length} asset${
      props.appState.selectedAssets.length > 1 ? 's' : ''
    } to delete`
  } else {
    message = 'This action will delete the folder and all of its contents'
  }

  return (
    <Modal
      title={`Delete ${
        props.modal.modalProps.type === 'asset' ? 'Asset' : 'Folder'
      }`}
      okText="CONTINUE"
      onOk={() => {
        if (props.modal.modalProps.type === 'folder') {
          props.folderActions.dropFolder(
            props.folders.recycleBin,
            props.folders.activeFolder,
            props.folders.byId[props.folders.activeFolder].parentId,
            props.modalProps.folderDragData
          )
        } else {
          // Drop the asset into the RecycleBin folder
          props.assetActions.dropAsset(
            props.folders.recycleBin,
            props.appState.selectedAssets,
            props.folders.activeFolder,
            props.user.token
          )
        }
      }}
      cancelText="Cancel"
      onCancel={props.modalActions.closeModal}
      visible={props.modal.modalOpen}
      confirmLoading={props.appState.asyncPending}
      maskClosable={false}
    >
      <div className="modal__form-password">
        <h4>{message}</h4>
      </div>
    </Modal>
  )
}

export default DeleteModal
