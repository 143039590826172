import React from 'react'
import '../styles/Library.css'

const AssetDetail404 = () => (
  <div className="folder404">
    <div className="folder404__text-large">Hmm, there’s nothing here...</div>
    <div className="folder404__text-small">
      ...or you may not have permission to view this item. You can search for
      what you’re looking for, or navigate to another folder in the navigation
      panel.
    </div>
  </div>
)

export default AssetDetail404
