import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActions from '../../dux/actions/appActions'
import * as assetActions from '../../dux/actions/asset'
import * as builderActions from '../../dux/actions/builders'
import * as endpointActions from '../../dux/actions/endpoint'
import * as folderActions from '../../dux/actions/folder'
import * as modalActions from '../../dux/actions/modal'
import * as searchActions from '../../dux/actions/search'
import { UGC_FOLDER } from '../../dux/constants/folder'
import {
  assetIsPublished,
  buildPath,
  debounce,
  getBulkAction,
  getEndpointById,
  getEndpointOfNode,
  validatePrivilege,
} from '../../dux/modules/utils'
import AutoScrollFooter from './AutoScrollFooter'
import { ENDPOINT_BULK_ACTIONS, LIBRARY_BULK_ACTIONS } from './LibraryConstants'
import LibraryHeader from './LibraryHeader'
import SearchFooter from './SearchFooter'
import TileView from './TileView'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.reviewForm = null
  }
  onScroll = (e) => {
    let showMore = false
    const top = e.target.scrollTop
    const autoscrollDetector = document.getElementById('autoscroll')
    if (autoscrollDetector) {
      if (top + window.innerHeight >= autoscrollDetector.offsetTop) {
        showMore = true
      }
    }
    this.props.appActions.setScroll(top, showMore)
  }

  componentWillMount() {
    window.addEventListener('resize', debounce(this.getCardSize, 150))

    if (this.props.appState.startupRoute) {
      if (this.props.match.params.folder) {
        const valid = this.props.folders.byId[this.props.match.params.folder]
        this.props.folderActions.activeFolder(
          this.props.match.params.folder,
          !!valid
        )
        if (valid) {
          const path = buildPath(
            this.props.match.params.folder,
            this.props.folders.byId
          ).concat(this.props.match.params.folder)
          path.forEach((node) => {
            this.props.appActions.addOpenFolderKey(node)
          })
          const node = getEndpointOfNode(
            this.props.folders.byId[this.props.match.params.folder],
            this.props.folders.byId,
            this.props.endpoints.endpoints
          ).id
          this.props.appActions.setActiveEndpoint(node)
          this.props.appActions.setOpenEndpointKeys([node])
        }
      } else {
        if (!this.props.folders.activeFolder) {
          const af = localStorage.getItem('activeFolder')
          if (af && af !== 'undefined' && this.props.folders.byId[af]) {
            this.props.folderActions.activeFolder(af)
            this.props.history.push(`/library/${af}`)
            const path = buildPath(af, this.props.folders.byId).concat(af)
            path.forEach((node) => {
              this.props.appActions.addOpenFolderKey(node)
            })
            const node = getEndpointOfNode(
              this.props.folders.byId[af],
              this.props.folders.byId,
              this.props.endpoints.endpoints
            ).id
            this.props.appActions.setActiveEndpoint(node)
            this.props.appActions.setOpenEndpointKeys([node])
          }
        }
      }
      this.props.appActions.setStartupRoute('')
    }
  }

  componentDidMount() {
    const el = document.getElementById('asset_container')
    el.addEventListener('scroll', debounce(this.onScroll, 500))
    if (this.props.appState.backButtonFlag) {
      el.scrollTop = this.props.appState.scrollPosition || 0
      this.props.appActions.setBackButtonFlag(false)
    }
  }

  componentDidUpdate() {
    if (!this.props.appState.cardSizeDetected) this.getCardSize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', debounce(this.getCardSize, 150))
    document
      .getElementById('asset_container')
      .removeEventListener('scroll', debounce(this.onScroll, 500))
  }

  // Get the current folder's assets.  If the folder exists in a publishing endpoint,
  // then filter by the current requested filter
  getAssets = () => {
    const { activeFolder, byId } = this.props.folders
    const { folderAssetStatus } = this.props.endpoints
    let assets =
      activeFolder && byId[activeFolder] ? byId[activeFolder].assets : []

    if (activeFolder && byId[activeFolder]) {
      const endpoint = getEndpointOfNode(
        byId[activeFolder],
        byId,
        this.props.endpoints.endpoints
      )

      if (
        endpoint.allowPublishing &&
        this.props.appState.selectedFilter !== 'ALL'
      ) {
        assets = assets.filter((asset) => {
          return (
            activeFolder &&
            folderAssetStatus[activeFolder] &&
            folderAssetStatus[activeFolder][asset] &&
            folderAssetStatus[activeFolder][asset].status ===
              this.props.appState.selectedFilter
          )
        })
      }
    }

    return assets
  }
  // Set window dimensions in Redux
  getCardSize = () => {
    const el = document.getElementsByClassName('assetcard')[0]
    if (el) {
      const width = el.getBoundingClientRect().width
      this.props.appActions.setCardWidth(width)
    }
  }

  onEditClick = () => {
    const assets = this.props.appState.selectedAssets.map(
      (asset) => this.props.assets.byId[asset]
    )
    this.props.modalActions.openModal('BulkEditModal', { new: false, assets })
  }

  saveFormRef = (ref) => {
    this.reviewForm = ref
  }

  closeReviewForm = () => {
    this.reviewForm.destroy()
  }

  publishedAssetSelected = () => {
    let published = false
    this.props.appState.selectedAssets.forEach((asset) => {
      if (
        this.props.assets.byId[asset] &&
        assetIsPublished(
          asset,
          this.props.relationships,
          this.props.assets.byId
        )
      )
        published = true
    })
    return published
  }

  referencedAssetSelected = () => {
    const { appState, relationships } = this.props
    let referenced = false
    appState.selectedAssets.forEach((asset) => {
      if (
        !referenced &&
        relationships.referencedBy[asset] &&
        relationships.referencedBy[asset].length > 0
      )
        referenced = true
    })
    return referenced
  }

  showStatusFilter = (endpointId) => {
    return getEndpointById(endpointId, this.props.endpoints.endpoints)
      .allowPublishing
  }

  bulkActionFilter = (action) => {
    const {
      folders,
      endpoints: { endpoints, folderAssetStatus },
      appState: { selectedAssets },
    } = this.props

    const activeFolderId = folders.activeFolder
    const activeFolder = folders.byId[activeFolderId]

    const isUGCAction = (action) =>
      ['move', 'delete', 'selectall'].includes(action)
    const isUGCFolder =
      activeFolderId === UGC_FOLDER ||
      (activeFolder && activeFolder.parentId === UGC_FOLDER)
    // UGC Folder only has move/delete actions
    if (isUGCFolder && !isUGCAction(action.action)) return false

    // move action not allowed outside of UGC
    if (!isUGCFolder && action.action === 'move') return false

    const endpoint = activeFolder
      ? getEndpointOfNode(activeFolder, folders.byId, endpoints).id
      : 'LIB'
    // if an asset is published to an endpoint other than LIB,
    // check if it makes sense to show that action based on
    // the current status of the assets selected (currently only checking the first?)
    // ex. If an asset is pending approval, it would make sense to show the 'review' action
    if (action.action === 'selectall') return true

    if (!selectedAssets || selectedAssets.length === 0) return false

    if (endpoint === 'LIB') return true

    //if the action is to be performed, every asset must match the statusType
    const assetsStatusForFolder = folderAssetStatus[activeFolderId]
    if (
      selectedAssets.every(
        (assetId) => assetsStatusForFolder[assetId].status === action.statusType
      )
    )
      return true

    return false
  }

  render() {
    const { activeFolder, byId, recycleBin } = this.props.folders
    const show404 =
      !activeFolder ||
      !byId[activeFolder] ||
      byId[activeFolder].assets.length === 0 ||
      byId[activeFolder].type === 'hidden'
    const endpoint = byId[activeFolder]
      ? getEndpointOfNode(
          byId[activeFolder],
          byId,
          this.props.endpoints.endpoints
        ).id
      : 'LIB'
    // Disable upload button in virtual folders, recycle bin, and non-library endpoints
    // Also disable if user does not have 'createasset' privilege
    const disabled =
      !byId[activeFolder] ||
      byId[activeFolder].type === 'virtual' ||
      byId[activeFolder].type === 'ugc' ||
      buildPath(activeFolder, byId).indexOf(recycleBin) > -1 ||
      endpoint !== 'LIB' ||
      !validatePrivilege(
        'createasset',
        this.props.permissions.privileges,
        this.props.user.email,
        this.props.permissions.groups,
        activeFolder,
        byId
      )
    const controls = (
      <LibraryHeader
        appState={this.props.appState}
        onSortChange={this.props.appActions.applyLibrarySort}
        onToggleSortDir={this.props.appActions.toggleLibrarySortDir}
        selectedAssets={this.props.appState.selectedAssets}
        allAssets={this.props.assets.byId}
        activeFolder={activeFolder}
        allFolders={byId}
        recycleBin={recycleBin}
        endpoint={endpoint}
        endpoints={this.props.endpoints.endpoints}
        endpointActions={this.props.endpointActions}
        publishedStatus={this.props.endpoints.folderAssetStatus}
        bulkActions={
          endpoint === 'LIB' ? LIBRARY_BULK_ACTIONS : ENDPOINT_BULK_ACTIONS
        }
        addButtonDisabled={disabled}
        privileges={this.props.permissions.privileges}
        groups={this.props.permissions.groups}
        email={this.props.user.email}
        user={this.props.user}
        modalActions={this.props.modalActions}
        appActions={this.props.appActions}
        assetActions={this.props.assetActions}
        reviewFormRef={this.reviewForm}
        saveFormRef={this.saveFormRef}
        closeReviewForm={this.closeReviewForm}
        handler={getBulkAction}
        publishedAssetSelected={this.publishedAssetSelected()}
        referencedAssetSelected={this.referencedAssetSelected()}
        showStatusFilter={this.showStatusFilter}
        selectedFilter={this.props.appState.selectedFilter}
        bulkActionFilter={this.bulkActionFilter}
        history={this.props.history}
        search={this.props.search}
      />
    )
    const footer =
      activeFolder === '_search' ? (
        <SearchFooter
          search={this.props.search}
          actions={this.props.searchActions}
          folder={byId['_search']}
          max={this.props.appState.maxAssetsToDisplay}
        />
      ) : (
        <AutoScrollFooter
          length={
            activeFolder && byId[activeFolder] ? this.getAssets().length : 0
          }
          max={this.props.appState.maxAssetsToDisplay}
        />
      )
    return (
      <TileView
        show404={show404}
        modalActions={this.props.modalActions}
        assets={this.getAssets()}
        activeFolder={activeFolder}
        allFolders={byId}
        max={this.props.appState.maxAssetsToDisplay}
        allAssets={this.props.assets.byId}
        cardWidth={this.props.appState.cardWidth}
        appState={this.props.appState}
        appActions={this.props.appActions}
        assetActions={this.props.assetActions}
        controls={controls}
        footer={footer}
        selectedAssets={this.props.appState.selectedAssets}
        onSelectAsset={(id) => this.props.appActions.selectAsset(id)}
        recycleBin={recycleBin}
        folderAssetStatus={this.props.endpoints.folderAssetStatus}
        endpoints={this.props.endpoints}
        endpointActions={this.props.endpointActions}
        groups={this.props.permissions.groups}
        privileges={this.props.permissions.privileges}
        userEmail={this.props.user.email}
        userId={this.props.user.id}
        config={this.props.config}
      />
    )
  }
}

Home.propTypes = {
  assets: PropTypes.object,
  user: PropTypes.object,
  folders: PropTypes.object,
  search: PropTypes.object,
  endpointActions: PropTypes.object,
  searchActions: PropTypes.object,
  folderActions: PropTypes.object,
  assetActions: PropTypes.object,
  modalActions: PropTypes.object,
  userActions: PropTypes.object,
  appActions: PropTypes.object,
  appState: PropTypes.object,
  match: PropTypes.object,
  endpoints: PropTypes.object,
  permissions: PropTypes.object,
  history: PropTypes.object,
  relationships: PropTypes.object,
  config: PropTypes.object,
}

const mapStateToProps = (state) => ({
  relationships: state.assets.relationships.assets,
  permissions: state.permissions,
  config: state.config.appConfig,
  endpoints: state.endpoints,
  appState: state.appState,
  folders: state.folders,
  search: state.search,
  assets: state.assets,
  user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appActions, dispatch),
  folderActions: bindActionCreators(folderActions, dispatch),
  assetActions: bindActionCreators(assetActions, dispatch),
  modalActions: bindActionCreators(modalActions, dispatch),
  searchActions: bindActionCreators(searchActions, dispatch),
  builderActions: bindActionCreators(builderActions, dispatch),
  endpointActions: bindActionCreators(endpointActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
