import { Icon, Tooltip } from 'antd'
import React from 'react'
import { validatePrivilege } from '../../dux/modules/utils'

const ActionIcon = (props) => {
  // Disabled prop may be passed; otherwise check privileges
  let disabled =
    props.disabled ||
    !validatePrivilege(
      props.action.privilege,
      props.privileges,
      props.email,
      props.groups,
      props.folder,
      props.allFolders
    )

  const actionCss = disabled
    ? `library-card__action-${props.useText ? 'text' : 'icon'}-disabled`
    : `library-card__action-${props.useText ? 'text' : 'icon'}`

  if (props.useText)
    return (
      <span
        className={actionCss}
        onClick={
          disabled
            ? null
            : (e) => {
                e.stopPropagation()
                props.handler()
              }
        }
      >
        {props.action.text}
      </span>
    )
  else
    return (
      <Tooltip
        title={props.tooltip || props.action.tooltipText}
        key={props.action.action}
      >
        {typeof props.action.icon === 'string' ? (
          <i
            className={`${actionCss} ${
              props.useAltIcon ? props.action.altIcon : props.action.icon
            } fa-fw`}
            onClick={
              disabled
                ? null
                : (e) => {
                    e.stopPropagation()
                    props.handler()
                  }
            }
          />
        ) : (
          <Icon
            component={props.action.icon.component}
            className={props.action.icon.className}
            onClick={
              disabled
                ? null
                : (e) => {
                    e.stopPropagation()
                    props.handler()
                  }
            }
          />
        )}
      </Tooltip>
    )
}

ActionIcon.defaultProps = {
  disabled: false,
  toolip: null,
  useAltIcon: false,
  useText: false,
}

export default ActionIcon
