import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import helpers from './utils/helpers'

export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const SET_MESSAGE = 'SET_MESSAGE'
export const OPEN_MODAL_REQUEST = 'OPEN_MODAL_REQUEST'

/*
 * Public facing action creator for the open modal saga
 */
export function openModal(modal, props = {}) {
  return {
    type: OPEN_MODAL_REQUEST,
    modal,
    props,
  }
}

/*
 * watcher function to initiate the modal saga
 */
export function* watchOpenModal() {
  yield takeEvery(OPEN_MODAL_REQUEST, openModalSaga)
}

/*
 * openModalSaga - validates the open modal request, and dispatches the action
 * or opens an error modal on invalid request
 */
export function* openModalSaga(action) {
  const state = yield select()

  let result = yield call(helpers.validate, action.modal, state)

  if (result.valid) {
    yield put({ type: OPEN_MODAL, modal: action.modal, props: action.props })
  } else {
    yield put({
      type: SET_MESSAGE,
      msg: {
        type: 'ERROR',
        msg: result.msg,
      },
    })
    yield put({ type: OPEN_MODAL, modal: 'MessageModal' })
  }
}

export function closeModal() {
  return {
    type: CLOSE_MODAL,
  }
}

export function setMessage(msg) {
  return {
    type: SET_MESSAGE,
    msg,
  }
}

export default all([watchOpenModal()])
