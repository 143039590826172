import {
  isInRecycleBin,
  isRoot,
  isVirtual,
  isWebRootFolder,
} from '../../components/sidebar/sidebarUtils.js'

export const FOLDER__ICON = 'fa fa-folder-o'
export const UGC_FOLDER = '_ugc'
export const RESOURCES_FOLDER = '_resources'
export const NEWS_RESOURCE_FOLDER = '_resources.news'
export const ASSETS_RESOURCE_FOLDER = '_resources.assets'
export const SEARCH_FOLDER = '_search'

export const LIBRARY_ENDPOINT = 'LIB'
export const MST_ENDPOINT = 'MST'

export const ROOT_FOLDER_TYPE = 'root'
export const RECYCLE_BIN_FOLDER_TYPE = 'recycle'
export const UGC_FOLDER_TYPE = 'ugc'
export const RESOURCES_FOLDER_TYPE = 'resources'
export const DEFAULT_FOLDER_TYPE = 'folder'
export const INVALID_FOLDER_TYPE = 'invalid'
export const VIRTUAL_FOLDER_TYPE = 'virtual'
export const WEB_FOLDER_TYPE = 'webfolder'

export const NEW_FOLDER_ACTION = 'New Folder'
export const SET_PERMISSIONS_ACTION = 'Set Permissions'
export const EMPTY_RECYCLE_BIN_ACTION = 'Empty Recycle Bin'
export const RENAME_FOLDER_ACTION = 'Rename Folder'
export const DELETE_FOLDER_ACTION = 'Delete Folder'

export const MAX_SHARE_LENGTH = 50

/*
 * Describes the actions available for each folder.
 * Exceptions are functions that take 3 parameters and return a boolean, where TRUE indicates
 * that the action is NOT allowed for that folder type.  The exceptions are treated as OR
 * conditions, so if any exception function returns false, the action is not allowed.
 * The parameters are folderId, allFolders and folderConstants as defined in this module
 */
export const FOLDER_TYPE_ACTIONS = {
  [ROOT_FOLDER_TYPE]: {
    actions: [
      {
        type: NEW_FOLDER_ACTION,
        exceptions: [],
      },
      {
        type: SET_PERMISSIONS_ACTION,
        exceptions: [],
      },
      {
        type: RENAME_FOLDER_ACTION,
        exceptions: [],
      },
    ],
  },
  [RECYCLE_BIN_FOLDER_TYPE]: {
    actions: [
      {
        type: EMPTY_RECYCLE_BIN_ACTION,
        exceptions: [],
      },
    ],
  },
  [UGC_FOLDER_TYPE]: {
    actions: [
      {
        type: RENAME_FOLDER_ACTION,
        exceptions: [isVirtual],
      },
    ],
  },
  [RESOURCES_FOLDER_TYPE]: {
    actions: [
      {
        type: NEW_FOLDER_ACTION,
        exceptions: [isRoot],
      },
      {
        type: SET_PERMISSIONS_ACTION,
        exceptions: [isRoot],
      },
    ],
  },
  [WEB_FOLDER_TYPE]: {
    actions: [
      {
        type: NEW_FOLDER_ACTION,
        exceptions: [isInRecycleBin],
      },
      {
        type: RENAME_FOLDER_ACTION,
        exceptions: [isInRecycleBin],
      },
      {
        type: DELETE_FOLDER_ACTION,
        exceptions: [isInRecycleBin, isWebRootFolder],
      },
    ],
  },
  [DEFAULT_FOLDER_TYPE]: {
    actions: [
      {
        type: NEW_FOLDER_ACTION,
        exceptions: [isInRecycleBin],
      },
      {
        type: SET_PERMISSIONS_ACTION,
        exceptions: [isInRecycleBin],
      },
      {
        type: RENAME_FOLDER_ACTION,
        exceptions: [isInRecycleBin],
      },
      {
        type: DELETE_FOLDER_ACTION,
        exceptions: [isInRecycleBin],
      },
    ],
  },
  [VIRTUAL_FOLDER_TYPE]: {
    actions: [],
  },
  [INVALID_FOLDER_TYPE]: {
    actions: [],
  },
}
export const FOLDER_ACTIONS = {
  [NEW_FOLDER_ACTION]: { privilege: 'addfolder' },
  [SET_PERMISSIONS_ACTION]: { privilege: 'setfolderprivs' },
  [EMPTY_RECYCLE_BIN_ACTION]: { privilege: 'emptyrecyclebin' },
  [RENAME_FOLDER_ACTION]: { privilege: 'renamefolder' },
  [DELETE_FOLDER_ACTION]: { privilege: 'deletefolder' },
}
