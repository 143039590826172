import PropTypes from 'prop-types'
import React from 'react'
import AddSlideForm from './forms/AddSlideForm'
import EditBuilderSlideForm from './forms/EditBuilderSlideForm'
import MetadataForm from './forms/MetadataForm'
import SaveLocationForm from './forms/SaveLocationForm'
import ViewRelatedAssetForm from './forms/ViewRelatedAssetForm'

class BuilderSider extends React.Component {
  handleFormValuesChange = (val) => this.props.handleFormValuesChange(val)

  render() {
    const {
      builderType,
      builderData,
      allAssets,
      nextAction,
      nextDisabled,
      onCancel,
      saveFormRef,
      saveTagsRef,
      handleFormValuesChange,
      prevAction,
      actionButtonLabel,
      addSaveFolderKey,
      removeSaveFolderKey,
      selectTarget,
      setPickerFolder,
      setPickerAsset,
      componentMouseEnter,
      componentMouseLeave,
      removeTarget,
      openSelectAssetModal,
      editTarget,
      imageFilter,
      allFolders,
      editSlideLabel,
      downloadTemplate,
      modalActions,
      getComparisonData,
      handlePickerModeChange,
      builderSettings,
    } = this.props
    switch (builderData.activeScreen) {
      case 'meta':
        return (
          <MetadataForm
            saveFormRef={saveFormRef}
            saveTagsRef={saveTagsRef}
            builderData={builderData}
            allAssets={allAssets}
            builderType={builderType}
            openSelectAssetModal={openSelectAssetModal}
            selectTarget={selectTarget}
            removeTarget={removeTarget}
            editTarget={editTarget}
            onCancel={onCancel}
            actionButtonLabel={actionButtonLabel}
            nextDisabled={nextDisabled}
            nextAction={nextAction}
            onValuesChange={handleFormValuesChange}
            downloadTemplate={downloadTemplate}
            modalActions={modalActions}
            getComparisonData={getComparisonData}
            builderSettings={builderSettings}
          />
        )
      case 'create':
        return (
          <SaveLocationForm
            nextAction={nextAction}
            prevAction={prevAction}
            nextDisabled={nextDisabled}
            actionButtonLabel={actionButtonLabel}
            setSaveFolder={setPickerFolder}
            targetSaveFolder={builderData.targetSaveFolder}
            pickerNode={builderData.pickerNode}
            addSaveFolderKey={addSaveFolderKey}
            removeSaveFolderKey={removeSaveFolderKey}
            openSaveFolderKeys={builderData.openSaveFolderKeys}
            onCancel={onCancel}
          />
        )
      case 'addSlide':
        return (
          <AddSlideForm
            builderData={builderData}
            prevAction={prevAction}
            nextAction={nextAction}
            addSaveFolderKey={addSaveFolderKey}
            removeSaveFolderKey={removeSaveFolderKey}
            setPickerFolder={setPickerFolder}
            setPickerAsset={setPickerAsset}
            handlePickerModeChange={handlePickerModeChange}
            builderType={builderType}
            imageFilter={imageFilter}
            allAssets={allAssets}
            onCancel={onCancel}
          />
        )
      case 'productEditSlide':
        return (
          <EditBuilderSlideForm
            builderType={builderType}
            activeSlide={builderData.activeSlide}
            selectTarget={selectTarget}
            removeTarget={removeTarget}
            editTarget={editTarget}
            builderData={builderData}
            prevAction={prevAction}
            nextAction={nextAction}
            allAssets={allAssets}
            onCancel={onCancel}
            componentMouseEnter={componentMouseEnter}
            componentMouseLeave={componentMouseLeave}
          />
        )
      case 'navigatorEditSlide':
        return (
          <EditBuilderSlideForm
            builderType={builderType}
            activeSlide={builderData.activeSlide}
            builderData={builderData}
            prevAction={prevAction}
            nextAction={nextAction}
            allAssets={allAssets}
            allFolders={allFolders}
            onCancel={onCancel}
            selectTarget={selectTarget}
            removeTarget={removeTarget}
            editTarget={editTarget}
            editSlideLabel={editSlideLabel}
            componentMouseEnter={componentMouseEnter}
            componentMouseLeave={componentMouseLeave}
          />
        )
      case 'viewRelatedAsset':
        return (
          <ViewRelatedAssetForm
            builderType={builderType}
            prevAction={prevAction}
            relatedAsset={
              builderData.activeRelatedAsset ||
              builderData.activeHotspot || {
                assetId: builderData.activeNavzone.targetId,
              }
            }
            allAssets={allAssets}
            onCancel={onCancel}
          />
        )

      default:
        return <div />
    }
  }
}

BuilderSider.propTypes = {
  saveFormRef: PropTypes.func,
  saveTagsRef: PropTypes.func,
  builderType: PropTypes.string,
  builderData: PropTypes.object,
  onCancel: PropTypes.func,
  nextDisabled: PropTypes.bool,
  nextAction: PropTypes.func,
  prevAction: PropTypes.func,
  actionButtonLabel: PropTypes.string,
  addSaveFolderKey: PropTypes.func,
  removeSaveFolderKey: PropTypes.func,
  handleFormValuesChange: PropTypes.func,
  setPickerAsset: PropTypes.func,
  setPickerFolder: PropTypes.func,
  handlePickerModeChange: PropTypes.func,
  selectHotspot: PropTypes.func,
  imageFilter: PropTypes.func,
  removeHotspot: PropTypes.func,
  editHotspot: PropTypes.func,
  componentMouseEnter: PropTypes.func,
  componentMouseLeave: PropTypes.func,
  openSelectAssetModal: PropTypes.func,
  removeTarget: PropTypes.func,
  selectTarget: PropTypes.func,
  editTarget: PropTypes.func,
  allAssets: PropTypes.object,
  allFolders: PropTypes.object,
  editSlideLabel: PropTypes.func,
  downloadTemplate: PropTypes.func,
  getComparisonData: PropTypes.func,
  addSlideCallback: PropTypes.func,
  endpoints: PropTypes.arrayOf(PropTypes.object),
  modalActions: PropTypes.object,
  appActions: PropTypes.object,
  setCurrentScreen: PropTypes.func,
  builderSettings: PropTypes.object,
}

export default BuilderSider
