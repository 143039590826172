/**
 * @typedef {Object} ElementDimensionsForResize
 * @property {number} width - Original width of the element
 * @property {number} height - Original height of the element
 * @property {number} containerWidth - Width of the element after resizing
 * @property {number} containerHeight - Height of the element after resizing
 *
 * @typedef {Object} ImageDimensions
 * @property {number} width - Width of the image
 * @property {number} height - Height of the image
 */

import { HOTSPOT__SIZE } from '../../../../dux/constants/builders'

/**
 * Returns the with and height of the image after resizing, keeping the aspect ratio the same
 *
 * @param {ElementDimensionsForResize} elementDimensions
 * @returns {ImageDimensions} - The width and height of the image after resizing
 */
export function calculateImageDimensions(elementDimensions) {
  const { width, height, containerWidth, containerHeight } = elementDimensions
  const originalAspectRatio = width / height
  const containerAspectRatio = containerWidth / containerHeight

  if (containerAspectRatio > originalAspectRatio) {
    const resizedHeight = containerHeight
    const resizedWidth = resizedHeight * originalAspectRatio
    return { width: resizedWidth, height: resizedHeight }
  }
  if (containerAspectRatio < originalAspectRatio) {
    const resizedWidth = containerWidth
    const resizedHeight = resizedWidth / originalAspectRatio
    return { width: resizedWidth, height: resizedHeight }
  }
  return { width: containerWidth, height: containerHeight }
}

export function calculateHotspotPosition(hotspotLocation, imageDimensions) {
  const { mainViewerHeight, mainViewerWidth, imageHeight, imageWidth } =
    imageDimensions
  const { x, y } = hotspotLocation
  // Transform coordinate system [-1, 1] to [0, 1]
  const xTransformed = (x + 1) / 2
  const yTransformed = (y + 1) / 2

  // Calculate the distance the hotspot is from the bottom and left of the image in pixels
  let bottom = yTransformed * imageHeight
  let left = xTransformed * imageWidth

  // Adjust the hotspot position if there is a difference between
  // the image size and the main viewer size that would skew the hotspot position

  if (imageWidth === mainViewerWidth) {
    const imageHeightDifference = mainViewerHeight - imageHeight
    bottom += imageHeightDifference / 2
  }

  if (imageHeight === mainViewerHeight) {
    const imageWidthDifference = mainViewerWidth - imageWidth
    left += imageWidthDifference / 2
  }

  // Subtract the radius of the hotspot div to center it
  bottom -= HOTSPOT__SIZE / 2
  left -= HOTSPOT__SIZE / 2

  return { bottom, left }
}
