import { Button, Form, Input, Tooltip } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import * as constants from '../../dux/constants/menu'

const MSTMenuSettingsView = (props) => (
  <div className="settings__container" id="menuSettings">
    <div className="settings__form-container">
      <div className="builders__form-label">Menu Configuration</div>
      <MenuSettingsForm {...props} ref={props.saveFormRef} />

      <div className="builders__button-container">
        <span className="builders__button">
          <Button onClick={props.onCancel}>Cancel</Button>
        </span>
        <span className="builders__button">
          <Button type="primary" onClick={props.onSave} loading={props.loading}>
            Save
          </Button>
        </span>
        <div className="builders__fixed-div">
          {props.message && (
            <div>
              {props.messageType === 'SUCCESS' ? (
                <i className="fa fa-check-circle assettable__status-green" />
              ) : (
                <i className="fa fa-exclamation-circle assettable__status-red" />
              )}
              &nbsp; {props.message}
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
)

const MenuSettingsForm = Form.create({
  onFieldsChange: (props) => props.onFieldsChange(),
})((props) => {
  const { form } = props
  const { getFieldDecorator } = form

  return (
    <Form layout="vertical">
      <div className="builders__padded-div">
        Here you can rename the menu items in the mobile app. Choose names that
        make sense for your team.
      </div>
      <Form.Item
        className="settings__form-item"
        label={<span className="builders__form-label">Dashboard</span>}
      >
        {getFieldDecorator('dashboard', {
          initialValue: props.menu.modules.dashboard,
          rules: [
            {
              max: constants.MENU_ITEM_MAX_CHARS,
              message: `Labels are limited to ${constants.MENU_ITEM_MAX_CHARS} characters`,
            },
          ],
        })(<Input placeholder={constants.DEFAULT_DASHBOARD_LABEL} />)}
      </Form.Item>
      <Form.Item
        className="settings__form-item"
        label={<span className="builders__form-label">Library</span>}
      >
        {getFieldDecorator('library', {
          initialValue: props.menu.modules.library,
          rules: [
            {
              max: constants.MENU_ITEM_MAX_CHARS,
              message: `Labels are limited to ${constants.MENU_ITEM_MAX_CHARS} characters`,
            },
          ],
        })(<Input placeholder={constants.DEFAULT_LIBRARY_LABEL} />)}
      </Form.Item>
      <Form.Item
        className="settings__form-item"
        label={<span className="builders__form-label">News</span>}
      >
        {getFieldDecorator('news', {
          initialValue: props.menu.modules.news,
          rules: [
            {
              max: constants.MENU_ITEM_MAX_CHARS,
              message: `Labels are limited to ${constants.MENU_ITEM_MAX_CHARS} characters`,
            },
          ],
        })(
          <Input
            disabled={!props.modules.news.enabled}
            placeholder={constants.DEFAULT_NEWS_LABEL}
          />
        )}
      </Form.Item>
      <Form.Item
        className="settings__form-item"
        label={<span className="builders__form-label">Calculators</span>}
      >
        {getFieldDecorator('calculators', {
          initialValue: props.menu.modules.calculators,
          rules: [
            {
              max: constants.MENU_ITEM_MAX_CHARS,
              message: `Labels are limited to ${constants.MENU_ITEM_MAX_CHARS} characters`,
            },
          ],
        })(
          <Input
            disabled={!props.modules.calculators.enabled}
            placeholder={constants.DEFAULT_CALCULATORS_LABEL}
          />
        )}
      </Form.Item>
      <Form.Item
        className="settings__form-item"
        label={<span className="builders__form-label">Resources</span>}
      >
        {getFieldDecorator('resources', {
          initialValue: props.menu.modules.resources,
          rules: [
            {
              max: constants.MENU_ITEM_MAX_CHARS,
              message: `Labels are limited to ${constants.MENU_ITEM_MAX_CHARS} characters`,
            },
          ],
        })(<Input placeholder={constants.DEFAULT_RESOURCES_LABEL} />)}
      </Form.Item>
      <Form.Item
        className="settings__form-item"
        label={<span className="builders__form-label">Upload</span>}
      >
        {getFieldDecorator('ugc', {
          initialValue: props.menu.modules.uploads,
          rules: [
            {
              max: constants.MENU_ITEM_MAX_CHARS,
              message: `Labels are limited to ${constants.MENU_ITEM_MAX_CHARS} characters`,
            },
          ],
        })(
          <Input
            disabled={!props.uploads.enabled}
            placeholder={constants.DEFAULT_UGC_LABEL}
          />
        )}
      </Form.Item>
      <Form.Item
        className="settings__form-item"
        label={<span className="builders__form-label">My Profile</span>}
      >
        {getFieldDecorator('profile', {
          initialValue: props.menu.modules.profile,
          rules: [
            {
              max: constants.MENU_ITEM_MAX_CHARS,
              message: `Labels are limited to ${constants.MENU_ITEM_MAX_CHARS} characters`,
            },
          ],
        })(<Input placeholder={constants.DEFAULT_PROFILE_LABEL} />)}
      </Form.Item>
      <Form.Item
        className="settings__form-item"
        label={<span className="builders__form-label">Customer Profiles</span>}
      >
        {getFieldDecorator('customerProfiles', {
          initialValue: props.menu.modules.customerProfiles,
          rules: [
            {
              max: constants.MENU_ITEM_MAX_CHARS,
              message: `Labels are limited to ${constants.MENU_ITEM_MAX_CHARS} characters`,
            },
          ],
        })(
          <Input
            disabled={!props.customerProfiles.enabled}
            placeholder={constants.DEFAULT_CUSTOMER_PROFILE_LABEL}
          />
        )}
      </Form.Item>
      <div className="builders__form-label">Custom Menu Items</div>
      <div className="builders__padded-div">
        Click the add asset button to find an asset in your library you’d like
        to include in your main menu. You may also choose a website URL to give
        your team easy access to commonly used websites.
      </div>
      <Button
        className="settings__button-inline"
        onClick={() => props.openSelectAssetModal()}
        disabled={props.menu.custom.length >= constants.MENU_MAX_CUSTOM_ASSETS}
      >
        <span>
          <i className="fa fa-plus-circle" /> Asset
        </span>
      </Button>
      <Button
        className="settings__button-inline"
        onClick={() => props.openCustomResourceModal('url')}
        disabled={props.menu.custom.length >= constants.MENU_MAX_CUSTOM_URLS}
      >
        <span>
          <i className="fa fa-plus-circle" /> URL
        </span>
      </Button>
      {props.menu.custom.map((resource) => {
        switch (resource.resourceType) {
          case 'asset':
            return (
              <div
                key={resource.id}
                className="settings__custom-resource-div"
                onClick={props.showAssetDetail}
              >
                <span className="settings__custom-resource-div-text">
                  <i className="fa fa-desktop settings__custom-resource-div-icon" />
                  &nbsp;
                  <Link to={`/library/asset/${resource.resourceValue}`}>
                    {resource.displayText}
                  </Link>
                </span>
                <span className="settings__custom-resource-div-action">
                  <div className="settings__custom-resource-div-action">
                    <i
                      className="fa fa-edit settings__custom-resource-div-action-icon"
                      onClick={() => props.openSelectAssetModal(resource)}
                    />
                    <i
                      className="fa fa-trash settings__custom-resource-div-action-icon"
                      onClick={() => props.removeCustomMenuResource(resource)}
                    />
                  </div>
                </span>
              </div>
            )
          case 'url':
            return (
              <div key={resource.id} className="settings__custom-resource-div">
                <span className="settings__custom-resource-div-text">
                  <i className="fa fa-desktop settings__custom-resource-div-icon" />
                  &nbsp;
                  <Tooltip title={resource.resourceValue} placement="right">
                    <a
                      href={resource.resourceValue}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {resource.displayText}
                    </a>
                  </Tooltip>
                </span>
                <span className="settings__custom-resource-div-action">
                  <div className="settings__custom-resource-div-action">
                    <i
                      className="fa fa-edit settings__custom-resource-div-action-icon"
                      onClick={() =>
                        props.openCustomResourceModal('url', resource)
                      }
                    />
                    <i
                      className="fa fa-trash settings__custom-resource-div-action-icon"
                      onClick={() => props.removeCustomMenuResource(resource)}
                    />
                  </div>
                </span>
              </div>
            )
          default:
            return null
        }
      })}
    </Form>
  )
})

export default MSTMenuSettingsView
