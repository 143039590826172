export const LOGO_WIDTH = 700
export const LOGO_HEIGHT = 360
export const SPLASH_WIDTH = 750
export const SPLASH_HEIGHT = 1334
export const DEFAULT_LOGO_FILE = 'defaultLogo.jpg'
export const DEFAULT_SPLASH_SCREEN_FILE = 'defaultSplashScreen.jpg'
export const BRAND_PROFILE_WIDTH = 360
export const BRAND_PROFILE_HEIGHT = 360
export const WEB_ENDPOINT_404_WIDTH = 1080
export const WEB_ENDPOINT_404_HEIGHT = 1080
