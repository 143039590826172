import PropTypes from 'prop-types'
import React from 'react'

const AssetNode = (props) => {
  let cssModifier = ''
  if (props.nodeIsActive(props.asset.id, props.parentFolder))
    cssModifier = '-active'
  else if (props.nodeIsDisabled(props.asset.id, props.parentFolder))
    cssModifier = '-disabled'
  else if (props.activeKey !== props.endpointId) cssModifier = '-inactive'

  return (
    <div
      className={`node${cssModifier}`}
      onClick={() => {
        if (!props.nodeIsDisabled(props.asset.id, props.parentFolder))
          props.onAssetClick(props.asset.id, props.parentFolder)
      }}
    >
      <span className={`treenode-toggle${cssModifier}`}>
        {props.asset.icon ? (
          <i className={`fa ${props.asset.icon}`} />
        ) : (
          <i className="fa fa-fw fa-file" />
        )}
      </span>
      <span className={`treenode-text${cssModifier}`}>
        {props.displayMode === 'filename'
          ? props.asset.filename
          : props.asset.title || props.asset.filename}
      </span>
    </div>
  )
}

AssetNode.propTypes = {
  nodeIsActive: PropTypes.func,
  nodeIsDisabled: PropTypes.func,
  activeKey: PropTypes.string,
  endpointId: PropTypes.string,
  onAssetClick: PropTypes.func,
  asset: PropTypes.object,
  parentFolder: PropTypes.string,
  displayMode: PropTypes.string,
}

export default AssetNode
