import { Icon } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

const AutoScrollFooter = (props) => {
  if (props.length > props.max) {
    return (
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          color: '#505050',
          fontSize: '24px',
        }}
        id="autoscroll"
      >
        <Icon type="loading" />
      </div>
    )
  }

  return null
}

AutoScrollFooter.propTypes = {
  length: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
}

export default AutoScrollFooter
