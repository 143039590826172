import PropTypes from 'prop-types'
import React from 'react'
import { DropTarget } from 'react-dnd'
import {
  NAVIGATOR__BACKGROUND,
  NAVIGATOR__BUILDER_TYPE,
  PRODUCT__BUILDER_TYPE,
  PRODUCT__MAX_HOTSPOTS,
} from '../../../dux/constants/builders'
import {
  DND_HOTSPOT,
  dropCollect,
  hotspotTarget,
} from '../../../dux/modules/DragDrop'
import { debounce, throttle } from '../../../dux/modules/utils'
import NavigatorSimulator from './NavigatorSimulator'
import ProductSimulator from './ProductSimulator'
import {
  checkClickLocation,
  getCenter,
  getTemplateDimensions,
  getThumbnailWidth,
  isOutsideImage,
  isOverlapping,
  zoneSizeExceedsMinimum,
} from './utils'

class BuilderSimulator extends React.Component {
  state = {
    mouseIsDown: false,
    zoneColor: NAVIGATOR__BACKGROUND,
    templateWidth: null,
    templateHeight: null,
    imageWidth: null,
    imageHeight: null,
    center: {
      x: null,
      y: null,
    },
    thumbnailWidth: 250,
    limiter: null,
    topLeft: {
      x: 1,
      y: -1,
    },
    bottomRight: {
      x: 1,
      y: -1,
    },
  }

  // Set up event listeners
  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', debounce(this.updateDimensions, 150))
  }

  // Tear down event listeners
  componentWillUnmount() {
    window.removeEventListener('resize', debounce(this.updateDimensions, 150))
  }

  // Recalculate frame size if background image changes
  componentDidUpdate(prevProps) {
    const oldSize = prevProps.activeSlide.size
    const newSize = this.props.activeSlide.size
    if (
      prevProps.ratio !== this.props.ratio ||
      oldSize.width !== newSize.width ||
      oldSize.height !== newSize.height
    )
      this.updateDimensions(true)
  }

  // Event handler when window is resized; also called when background changes
  updateDimensions = (force = false) => {
    const el = document.getElementById('sim-container')
    const { frameWidth, frameHeight } = this.state
    if (el) {
      const dimensions = el.getBoundingClientRect()
      const assetDimensions = this.props.activeSlide.size
      if (
        force ||
        dimensions.width !== frameWidth ||
        dimensions.height !== frameHeight
      ) {
        let templateWidth, templateHeight, imageWidth, imageHeight, limiter
        const windowRatio = dimensions.width / dimensions.height
        const assetRatio = assetDimensions.width / assetDimensions.height
        //Container Width is the limiting factor
        if (assetRatio > windowRatio) {
          // Template Height also limits image
          if (assetRatio > this.props.ratio.exterior) {
            console.log('case1')
            imageWidth = dimensions.width
            imageHeight = imageWidth / assetRatio
            const interiorDimensions = getTemplateDimensions(
              'interior',
              this.props.builderData.simulatorView,
              imageHeight * this.props.ratio.exterior,
              imageHeight
            )
            const exteriorDimensions = getTemplateDimensions(
              'exterior',
              this.props.builderData.simulatorView,
              interiorDimensions.height * this.props.ratio.interior,
              interiorDimensions.height
            )
            imageHeight = interiorDimensions.height
            imageWidth = imageHeight * assetRatio
            templateHeight = exteriorDimensions.height
            templateWidth = exteriorDimensions.width
            // Image full width
          } else {
            console.log('case2')
            templateWidth = dimensions.width
            templateHeight = templateWidth / this.props.ratio.exterior
            const templateInterior = getTemplateDimensions(
              'interior',
              this.props.builderData.simulatorView,
              templateWidth,
              templateHeight
            )
            imageWidth = templateInterior.width
            imageHeight = imageWidth / assetRatio
          }
          // Container Height is the limiting factor
        } else {
          // Template height also limits image height
          if (assetRatio < this.props.ratio.exterior) {
            imageHeight = dimensions.height
            imageWidth = imageHeight * assetRatio
            templateWidth = imageWidth
            templateHeight = templateWidth / this.props.ratio.exterior
            const templateInterior = getTemplateDimensions(
              'interior',
              this.props.builderData.simulatorView,
              imageWidth,
              imageWidth / this.props.ratio.interior
            )
            imageWidth = templateInterior.width
            imageHeight = imageWidth / assetRatio
            // Image is full height
          } else {
            console.log('case4')
            templateHeight = dimensions.height
            templateWidth = templateHeight * this.props.ratio.exterior
            const templateInterior = getTemplateDimensions(
              'interior',
              this.props.builderData.simulatorView,
              templateWidth,
              templateHeight
            )
            imageHeight = templateInterior.height
            imageWidth = imageHeight * assetRatio
          }
        }

        this.setState({
          templateWidth,
          templateHeight,
          imageWidth,
          imageHeight,
          center: getCenter(imageWidth, imageHeight),
          thumbnailWidth: getThumbnailWidth(imageWidth),
          limiter,
        })
      }
    }
  }

  saveClickPosition = (e) => {
    if (!this.props.builderData.activeSlide.mainImage) return
    const { center } = this.state
    const xPctFromCenter = (e.nativeEvent.offsetX - center.x) / center.x
    const yPctFromCenter = (center.y - e.nativeEvent.offsetY) / center.y
    this.setState(
      {
        startPosition: {
          x: xPctFromCenter,
          y: yPctFromCenter,
        },
      },
      () => {
        if (this.props.activeSlide.hotspots.length >= PRODUCT__MAX_HOTSPOTS)
          return
        this.props.openSelectAssetModal('addHotspot')
      }
    )
  }

  getClickPosition = () => this.state.startPosition

  // If click is over a valid target, save the start position, top left corner and
  // bottom right corner (these are the same on initial click)
  startNavzone = (e) => {
    const { builderData } = this.props
    if (
      e.target !== document.getElementById('navigator-view') ||
      !builderData.activeSlide.mainImage
    )
      return
    const { center } = this.state
    const xPctFromCenter = (e.nativeEvent.offsetX - center.x) / center.x
    const yPctFromCenter = (center.y - e.nativeEvent.offsetY) / center.y
    this.setState((prevState) => {
      const overZone = checkClickLocation(
        builderData.activeSlide.navzones,
        xPctFromCenter,
        yPctFromCenter
      )
      if (overZone) return prevState
      return {
        mouseIsDown: true,
        startPosition: {
          x: xPctFromCenter,
          y: yPctFromCenter,
        },
        topLeft: {
          x: xPctFromCenter,
          y: yPctFromCenter,
        },
        bottomRight: {
          x: xPctFromCenter,
          y: yPctFromCenter,
        },
      }
    })
  }

  // When resizing, we need to check the coordinates relative to the start
  // position to determine what values to user for TopLeft/BottomRight
  resizeNavzone = (e) => {
    if (!this.props.builderData.activeSlide.mainImage) return

    if (this.state.mouseIsDown || this.state.dragHandleDown) {
      const { center, startPosition } = this.state
      const xPctFromCenter = (e.nativeEvent.offsetX - center.x) / center.x
      const yPctFromCenter = (center.y - e.nativeEvent.offsetY) / center.y
      this.setState((prevState) => {
        let mutations = {}

        if (this.state.mouseIsDown) {
          if (xPctFromCenter < startPosition.x) {
            mutations.topLeft = {
              x: xPctFromCenter,
            }
            mutations.bottomRight = {
              x: startPosition.x,
            }
            if (yPctFromCenter > startPosition.y) {
              mutations.topLeft.y = yPctFromCenter
              mutations.bottomRight.y = startPosition.y
            } else {
              mutations.topLeft.y = startPosition.y
              mutations.bottomRight.y = yPctFromCenter
            }
          } else {
            mutations.topLeft = {
              x: startPosition.x,
            }
            mutations.bottomRight = {
              x: xPctFromCenter,
            }
            if (yPctFromCenter > startPosition.y) {
              mutations.topLeft.y = yPctFromCenter
              mutations.bottomRight.y = startPosition.y
            } else {
              mutations.topLeft.y = startPosition.y
              mutations.bottomRight.y = yPctFromCenter
            }
          }
          mutations.zoneColor = isOverlapping(
            this.props.builderData.activeSlide.navzones,
            mutations
          )
            ? 'red'
            : NAVIGATOR__BACKGROUND
          if (isOutsideImage(mutations)) {
            return {
              topLeft: {
                x: 1,
                y: -1,
              },
              bottomRight: {
                x: 1,
                y: -1,
              },
              mouseIsDown: false,
              dragHandleDown: false,
              dragHandle: '',
              originalPosition: {},
            }
          }

          return mutations
        } else {
          mutations = {}
          switch (this.state.handle) {
            case 'tl':
              mutations.topLeft = {
                x: xPctFromCenter,
                y: yPctFromCenter,
              }
              mutations.bottomRight = prevState.originalPosition.bottomRight
              break
            case 'tr':
              mutations.topLeft = {
                x: prevState.originalPosition.topLeft.x,
                y: yPctFromCenter,
              }
              mutations.bottomRight = {
                x: xPctFromCenter,
                y: prevState.originalPosition.bottomRight.y,
              }
              break
            case 'br':
              mutations.topLeft = prevState.originalPosition.topLeft
              mutations.bottomRight = {
                x: xPctFromCenter,
                y: yPctFromCenter,
              }
              break
            case 'bl':
              mutations.topLeft = {
                x: xPctFromCenter,
                y: prevState.originalPosition.topLeft.y,
              }
              mutations.bottomRight = {
                x: prevState.originalPosition.bottomRight.x,
                y: yPctFromCenter,
              }
              break
            default:
          }
          if (isOutsideImage(mutations)) {
            return {
              topLeft: {
                x: 1,
                y: -1,
              },
              bottomRight: {
                x: 1,
                y: -1,
              },
              mouseIsDown: false,
              dragHandleDown: false,
              dragHandle: '',
              originalPosition: {},
            }
          }
          mutations.zoneColor = isOverlapping(
            this.props.builderData.activeSlide.navzones,
            mutations,
            this.props.builderData.activeNavzone.id
          )
            ? 'red'
            : NAVIGATOR__BACKGROUND
          this.props.builderActions.updateNavzone(
            this.props.builderType,
            this.props.builderData.activeSlide.id,
            {
              ...this.props.builderData.activeNavzone,
              ...mutations,
            }
          )
          return mutations
        }
      })
    }
  }

  // If release point is not valid, do not draw box...reset state
  endNavzone = (e) => {
    const target = e.target
    this.setState((prevState) => {
      const { imageWidth, imageHeight, dragHandleDown } = prevState
      const { builderData, addNavzone, openNewZoneModal } = this.props
      const activeZoneId = dragHandleDown ? builderData.activeNavzone.id : null
      if (!builderData.activeSlide.mainImage) return
      // Reset state if release point is over a zone, or if we haven't stated recording a box
      if (
        target === document.getElementById('navigator-view') ||
        prevState.mouseIsDown ||
        prevState.dragHandleDown
      ) {
        if (
          !isOverlapping(
            builderData.activeSlide.navzones,
            prevState,
            activeZoneId
          ) &&
          zoneSizeExceedsMinimum(prevState, imageWidth, imageHeight)
        ) {
          if (prevState.mouseIsDown) {
            addNavzone({
              topLeft: prevState.topLeft,
              bottomRight: prevState.bottomRight,
              targetId: null,
              targetType: 'unassigned',
            })
            openNewZoneModal('addZone')
          }
        } else {
          // Need to reset zone size to original
          if (prevState.dragHandleDown) {
            this.props.builderActions.updateNavzone(
              this.props.builderType,
              this.props.builderData.activeSlide.id,
              {
                ...this.props.builderData.activeNavzone,
                topLeft: prevState.originalPosition.topLeft,
                bottomRight: prevState.originalPosition.bottomRight,
              }
            )
          }
        }
      }
      return {
        topLeft: {
          x: 1,
          y: -1,
        },
        bottomRight: {
          x: 1,
          y: -1,
        },
        mouseIsDown: false,
        dragHandleDown: false,
        dragHandle: '',
        originalPosition: {},
      }
    })
  }

  onDragHandleDown = (e, handle) => {
    const navZoneId = e.target.id.split('-')[2]
    const activeNavzone = this.props.builderData.activeSlide.navzones.filter(
      (zone) => zone.id === navZoneId
    )[0]
    this.setState({
      dragHandleDown: true,
      handle,
      originalPosition: {
        topLeft: activeNavzone.topLeft,
        bottomRight: activeNavzone.bottomRight,
      },
    })
    this.props.builderActions.setKeyForBuilder(this.props.builderType, {
      activeNavzone,
    })
  }

  handleSelectHotspot = (e, hotspot) => {
    e.stopPropagation()
    this.props.selectTarget('hotspot', hotspot)
  }

  render() {
    const {
      builderData,
      builderType,
      activeSlide,
      allAssets,
      builderActions,
      componentMouseEnter,
      componentMouseLeave,
      config,
      openSelectAssetModal,
      simulatorViews,
    } = this.props
    const {
      templateWidth,
      templateHeight,
      imageWidth,
      imageHeight,
      thumbnailWidth,
      center,
      topLeft,
      bottomRight,
      zoneColor,
    } = this.state
    const thumbSuffix =
      thumbnailWidth !== imageWidth ? `_thumb-${thumbnailWidth}x` : ''
    const slideImageUrl =
      activeSlide &&
      activeSlide.mainImage &&
      `${allAssets[activeSlide.mainImage].url}${thumbSuffix}`
    return (
      <div
        className={`simulator__container simulator__${builderData.simulatorView}`}
        id="sim-container"
      >
        {!activeSlide.mainImage && (
          <h1
            className="simulator__container-h1"
            style={{ maxWidth: templateWidth }}
          >
            Add a background image to the slide
          </h1>
        )}
        <div
          className="simulator__template"
          style={{
            width: templateWidth,
            height: templateHeight,
            zIndex: 1,
            backgroundSize: '100%',
            backgroundImage: `url(${
              simulatorViews[builderData.simulatorView].background
            })`,
            backgroundRepeat: 'no-repeat',
            padding: '2.22% 3.5%',
          }}
        ></div>
        {builderType === PRODUCT__BUILDER_TYPE && (
          <ProductSimulator
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            center={center}
            slideImageUrl={slideImageUrl}
            activeSlide={activeSlide}
            activeHotspot={
              builderData.activeHotspot && builderData.activeHotspot.id
            }
            builderType={builderType}
            builderActions={builderActions}
            saveClickPosition={this.saveClickPosition}
            componentMouseEnter={componentMouseEnter}
            componentMouseLeave={componentMouseLeave}
            openSelectAssetModal={openSelectAssetModal}
            handleSelectHotspot={this.handleSelectHotspot}
            config={config}
          />
        )}
        {builderType === NAVIGATOR__BUILDER_TYPE && (
          <NavigatorSimulator
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            activeSlide={activeSlide}
            activeZone={builderData.activeZone}
            center={center}
            slideImageUrl={slideImageUrl}
            zoneColor={zoneColor}
            startPosition={topLeft}
            endPosition={bottomRight}
            builderType={builderType}
            builderActions={builderActions}
            componentMouseEnter={componentMouseEnter}
            componentMouseLeave={componentMouseLeave}
            mouseIsDown={this.state.mouseIsDown}
            dragHandleDown={this.state.dragHandleDown}
            onDragHandleDown={this.onDragHandleDown}
            startNavzone={this.startNavzone}
            resizeNavzone={throttle(this.resizeNavzone, 50)}
            endNavzone={this.endNavzone}
          />
        )}
      </div>
    )
  }
}

BuilderSimulator.propTypes = {
  builderData: PropTypes.object,
  builderType: PropTypes.string,
  activeSlide: PropTypes.object,
  allAssets: PropTypes.object,
  ratio: PropTypes.object,
  builderActions: PropTypes.object,
  modalActions: PropTypes.object,
  endpoints: PropTypes.arrayOf(PropTypes.object),
  selectTarget: PropTypes.func,
  openSelectAssetModal: PropTypes.func,
  componentMouseEnter: PropTypes.func,
  componentMouseLeave: PropTypes.func,
  connectDropTarget: PropTypes.func,
  addNavzone: PropTypes.func,
  openNewZoneModal: PropTypes.func,
  config: PropTypes.object,
}

BuilderSimulator.defaultProps = {
  activeSlide: {
    mainImage: '',
    size: {
      width: 800,
      height: 450,
    },
  },
}
export default DropTarget(
  DND_HOTSPOT,
  hotspotTarget,
  dropCollect
)(BuilderSimulator)
