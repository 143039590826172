import { Popover, Tooltip } from 'antd'
import React, { Fragment } from 'react'

const ReplacePreviewAction = (props) => (
  <div>
    <Tooltip title="Manage Thumbnail">
      <Popover content={<ThumbnailOptions {...props} />} placement="bottom">
        <i
          className="fa fa-picture-o"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            return false
          }}
        />
      </Popover>
    </Tooltip>
  </div>
)

const ThumbnailOptions = (props) => {
  return (
    <Fragment>
      <span
        className="assetdetail__preview-action-link"
        onClick={(e) => props.handlePreviewClick(e, props.asset, 'custom')}
      >
        Custom
      </span>
      <span
        className="assetdetail__preview-action-link"
        onClick={(e) => props.handlePreviewClick(e, props.asset, 'original')}
      >
        Original
      </span>
      <span
        className="assetdetail__preview-action-link"
        onClick={(e) => props.handlePreviewClick(e, props.asset, 'icon')}
      >
        Icon
      </span>
    </Fragment>
  )
}

export default ReplacePreviewAction
