import { get } from 'lodash'
import { buildPath } from '../../modules/utils'
import {
  APPROVED,
  PENDING_APPROVAL,
  PENDING_REMOVAL,
  REJECTED,
  UNPUBLISHED,
} from '../endpoint'

const helpers = {
  /*  This function knows the conditions for a valid modal.  If the modal
   *   can be displayed, then it will return a result object with valid=true.  If
   *   it cannot be displayed, the result will be returned with valid=false and
   *   a message describing the error.
   *
   *   The action creator will create either the requested modal or a message modal
   *   in case of invalid state.
   */
  validate: (modal, state) => {
    let result = {
      valid: true,
      msg: '',
    }
    let path
    const { activeFolder, byId, recycleBin } = state.folders

    switch (modal) {
      // DownloadModal requires at least 1 selected asset.  Selected folders
      // cannot be downloaded for MVP
      case 'ShareModal':
        if (state.appState.selectedAssets.length === 0) {
          result.valid = false
          result.msg = 'No items selected for sharing!'
        }
        if (Object.keys(state.user.socialMedia).length === 0) {
          result.valid = false
          result.msg +=
            'No Social Media accounts linked. Please edit your profile'
        }
        break

      case 'UploadModal':
        path = buildPath(state.folders.activeFolder, state.folders.byId)
        if (
          state.folders.activeFolder === state.folders.recycleBin ||
          path.indexOf(state.folders.recycleBin) > -1
        ) {
          result.valid = false
          result.msg = 'Invalid Target: Upload to Recycle Bin not allowed'
        }
        break

      case 'AddFolderModal':
        path = buildPath(activeFolder, byId)
        if (activeFolder === recycleBin || path.indexOf(recycleBin) > -1) {
          result.valid = false
          result.msg = 'Invalid Target: Add Folder to Recycle Bin not allowed'
        }
        if (byId[activeFolder].type === 'ugc') {
          result.valid = false
          result.msg = `Invalid Target: Add Folder to ${byId[activeFolder].name} folder not allowed`
        }
        break
      default:
        result.valid = true
        result.msg = ''
    }

    return result
  },
  // Confirm that original asset to be replaced still exists in state...
  validateReplaceAsset: (action, data) => {
    return data.assetState.byId[action.originalAsset.id]
  },

  validatePublishRequest: (
    action,
    data,
    validStates = [UNPUBLISHED, REJECTED, undefined]
  ) => {
    // If folder doesn't exist or folder is in recycleBin, return false
    if (
      !data.folderState.byId[action.parent] ||
      buildPath(action.parent, data.folderState.byId).includes(
        data.folderState.recycleBin
      )
    ) {
      return false
    }
    return validateRequest(action, data, validStates, false)
  },

  validateUnpublishRequest: (action, data, validStates = [APPROVED]) => {
    return validateRequest(action, data, validStates)
  },

  validateReviewPublishRequest: (
    action,
    data,
    validStates = [PENDING_APPROVAL]
  ) => {
    return validateRequest(action, data, validStates)
  },

  validateReviewUnpublishRequest: (
    action,
    data,
    validStates = [PENDING_REMOVAL]
  ) => {
    return validateRequest(action, data, validStates)
  },

  validateUnpublishWebEndpointRequest: (
    action,
    data,
    validStates = [APPROVED, PENDING_APPROVAL, PENDING_REMOVAL]
  ) => {
    return validateRequest(action, data, validStates)
  },

  validateBulkEditRequest: (action, data) => {
    if (action.create) {
      return data.folderState.byId[action.parentId]
    }
    let valid = true
    for (let asset of action.assets) {
      if (!data.assetState.byId[asset.id]) {
        valid = false
      }
    }
    return valid
  },

  validateCreateProductRequest: (action, data) => {
    if (!data.folderState.byId[action.builderData.targetSaveFolder])
      return false
  },
}

/*
 *  This is a common function that the helpers use to determine is a publish/unpublish
 *  action is valid for a {parentFolder: [assets]} type object. It iterates through all
 *  of the assets in the action and makes sure that the folderAssetStatus state contains
 *  assets that are in a valid state for the helper function that is requesting.
 *  If no validStates array is passed to the function, it will always return false.
 *
 *  {object}    action        The action created from an endpoint action creator.
 *  {object}    data          The state data as returned from refreshStateSaga
 *  {array}     validStates   The list of valid states for the assets in the action.
 *                            Pass undefined as a member of this array if you want to allow
 *                            an undefined folderAssetStatus
 *  {boolean}   requireParentFolderInStatus
 *                            Whether or not the action.parent needs to be present in the
 *                            folderAssetStatus for the action to be valid.
 */
const validateRequest = (
  action,
  data,
  validStates = [],
  requireParentFolderInStatus = true
) => {
  if (!Array.isArray(validStates)) return false

  const { folderAssetStatus } = data.endpointState
  const parentFolderStatus = get(folderAssetStatus, `${action.parent}`, null)

  const noAssetStatusIsValid = requireParentFolderInStatus
    ? parentFolderStatus && validStates.includes(undefined)
    : validStates.includes(undefined)

  return action.assets.every((asset) => {
    const assetStatus = get(parentFolderStatus, `${asset}.status`, null)
    return validStates.includes(assetStatus) || noAssetStatusIsValid
  })
}

export default helpers
