import PropTypes from 'prop-types'
import React from 'react'

const AssetListItem = (props) => {
  const { asset, displayText, onClickRemove, onClickEdit, style } = props
  return (
    <div
      id={`listitem-${props.id}`}
      className={`settings__custom-resource-div${
        props.active ? '-active' : ''
      }`}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onClick={() => {
        props.onClick()
        props.onMouseLeave()
      }}
      style={style}
    >
      <span className="settings__custom-resource-div-text">
        <i className={`fa ${asset.icon} settings__custom-resource-div-icon`} />
        &nbsp;
        {displayText || asset.filename}
      </span>
      <span className="settings__custom-resource-div-action">
        <div className="settings__custom-resource-div-action">
          <i
            className="fa fa-edit settings__custom-resource-div-action-icon"
            onClick={(e) => {
              e.stopPropagation()
              onClickEdit()
            }}
          />
          <i
            className="fa fa-trash settings__custom-resource-div-action-icon"
            onClick={(e) => {
              e.stopPropagation()
              props.onMouseLeave()
              onClickRemove(props.id)
            }}
          />
        </div>
      </span>
    </div>
  )
}

AssetListItem.propTypes = {
  id: PropTypes.string,
  asset: PropTypes.object,
  displayText: PropTypes.string,
  onClickRemove: PropTypes.func,
  onClickEdit: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  style: PropTypes.object,
}

AssetListItem.defaultProps = {
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onClick: () => {},
}

export default AssetListItem
