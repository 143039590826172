import { Badge, Button, Collapse, Icon, Layout, Tooltip } from 'antd'
import React from 'react'
import { connectMenu } from 'react-contextmenu'
import { DropTarget } from 'react-dnd'
import { Link } from 'react-router-dom'
import { BADGES } from '../../dux/constants/sidebar'
import {
  DND_FOLDER_LIB,
  DND_FOLDER_MST,
  dropCollect,
  folderContainerTarget,
} from '../../dux/modules/DragDrop'
import {
  getEndpointBadgeCount,
  validatePrivilege,
} from '../../dux/modules/utils'
import FolderMenu from './FolderMenu'
import TreeNode from './TreeNode'

import '../styles/Sidebar.css'

const NODE_FOLDER_MENU = 'NODE_FOLDER_MENU'

class SidebarContent extends React.Component {
  saveContainerRef = (ref) => {
    this.props.connectDropTarget(ref)
    this.container = ref
  }

  render() {
    const { Content } = Layout
    const Panel = Collapse.Panel

    return (
      <div className="bam-sidebar" ref={this.saveContainerRef}>
        <Content className="bam-sidebar__content" id="sidebar-container">
          <Collapse
            activeKey={this.props.openEndpointKeys}
            onChange={this.props.onCollapseChange}
            style={{
              background: '#344353',
              border: 'none',
              fontSize: '20px',
              fontWeight: 500,
              padding: 0,
            }}
          >
            {this.props.endpoints.endpoints
              .filter((endpoint) => !endpoint.deleted)
              .map((endpoint) => {
                return (
                  <Panel
                    header={
                      <div className="bam-sidebar__collapse-panel-header">
                        <span>
                          <i
                            className={`bam-sidebar__collapse-pointer fa fa-caret-${
                              this.props.openEndpointKeys.indexOf(endpoint.id) >
                              -1
                                ? 'down'
                                : 'right'
                            }`}
                          />
                          <span style={{ padding: '12px 8px 12px 0' }}>
                            {endpoint.name}
                          </span>
                        </span>
                        <span>
                          {
                            //Show badge only if endpoint panel is closed
                            this.props.openEndpointKeys.indexOf(endpoint.id) <
                              0 && (
                              <span>
                                {(endpoint.badges || []).map((badge) => {
                                  const badgeCount = getEndpointBadgeCount(
                                    badge,
                                    endpoint.rootFolders,
                                    this.props.endpoints.folderAssetStatus,
                                    this.props.folders.byId
                                  )
                                  return (
                                    <Tooltip
                                      key={badge}
                                      placement={BADGES[badge].placement}
                                      title={`${badgeCount} ${BADGES[badge].tooltip}`}
                                    >
                                      <Badge
                                        count={badgeCount}
                                        title={null}
                                        style={{
                                          boxShadow: 'none',
                                          minWidth: 26,
                                          backgroundColor:
                                            BADGES[badge].bgColor,
                                          cursor: 'pointer',
                                        }}
                                        offset={[0, -4]}
                                      />
                                    </Tooltip>
                                  )
                                })}
                              </span>
                            )
                          }
                          {endpoint.actionIcon ? (
                            <Button
                              ghost
                              icon={endpoint.actionIcon}
                              id={endpoint.id}
                              onClick={this.props.onPanelHeaderIconClick}
                              style={{
                                border: 'none',
                                color:
                                  this.props.activeKey === endpoint.id
                                    ? '#ffffff'
                                    : '#b5b5b5',
                                fontSize: '16px',
                                cursor: 'pointer',
                                paddingRight: '15px',
                              }}
                              disabled={
                                endpoint.privilege
                                  ? !validatePrivilege(
                                      endpoint.privilege,
                                      this.props.privileges,
                                      this.props.email,
                                      this.props.groups,
                                      this.props.folders.activeFolder,
                                      this.props.folders.byId
                                    )
                                  : false
                              }
                            />
                          ) : null}
                        </span>
                      </div>
                    }
                    key={endpoint.id}
                    className={`bam-sidebar__collapse${
                      this.props.activeKey === endpoint.id ? '-active' : ''
                    }`}
                    showArrow={false}
                  >
                    {endpoint.rootFolders
                      .filter(
                        (folder) =>
                          this.props.folders.byId[folder].type !== 'hidden'
                      )
                      .map((root) => {
                        return (
                          <TreeNode
                            key={root}
                            {...this.props}
                            node={this.props.folders.byId[root]}
                            nodeLink={this.props.nodeLink}
                            isFolder
                            nodeIsActive={this.props.nodeIsActive}
                            nodeIsOpen={this.props.nodeIsOpen}
                            nodeIsDraggable={this.props.nodeIsDraggable}
                            nodeIsDisabled={this.props.nodeIsDisabled}
                            isRoot={false}
                            handleRenameFolder={this.props.handleRenameFolder}
                            onToggle={this.props.toggleFolder}
                            onNodeClick={this.props.onNodeClick}
                            endpointId={endpoint.id}
                            activeKey={this.props.activeKey}
                            setActiveFolder={this.props.setActiveFolder}
                            isModal={false}
                            badges={endpoint.badges}
                            onBeginDrag={this.props.appActions.beginFolderDrag}
                            onEndDrag={this.props.appActions.endFolderDrag}
                            folderDragData={this.props.folderDragData}
                            setFolderDragData={
                              this.props.appActions.setFolderDragData
                            }
                            endpoint={endpoint.id}
                          />
                        )
                      })}
                    {endpoint.views
                      .filter(
                        (view) => !!this.props.endpoints.allViews[view].route
                      )
                      .map((view) => {
                        const baseCss =
                          this.props.activeKey === endpoint.id
                            ? ''
                            : '-inactive'
                        return this.props.endpoints.allViews[view].route ? (
                          <Link
                            to={this.props.endpoints.allViews[view].route}
                            key={
                              this.props.endpoints.allViews[view].description
                            }
                            onClick={() =>
                              this.props.appActions.setActiveView(
                                view,
                                endpoint.id
                              )
                            }
                          >
                            <div
                              className={`endpoint__link${
                                view === this.props.activeView
                                  ? '-active'
                                  : baseCss
                              }`}
                              onClick={
                                this.props.homeActions[
                                  this.props.endpoints.allViews[view].action
                                ]
                              }
                            >
                              <Icon
                                type={this.props.endpoints.allViews[view].icon}
                              />
                              {this.props.endpoints.allViews[view].description}
                            </div>
                          </Link>
                        ) : (
                          <div
                            key={
                              this.props.endpoints.allViews[view].description
                            }
                            className={`endpoint__link${
                              view === this.props.activeView
                                ? '-active'
                                : baseCss
                            }`}
                            onClick={() => {
                              this.props.appActions.setActiveView(
                                view,
                                endpoint.id
                              )
                              this.props.homeActions[
                                this.props.endpoints.allViews[view].action
                              ] &&
                                this.props.homeActions[
                                  this.props.endpoints.allViews[view].action
                                ]()
                            }}
                          >
                            <Icon
                              type={this.props.endpoints.allViews[view].icon}
                            />
                            {this.props.endpoints.allViews[view].description}
                          </div>
                        )
                      })}
                  </Panel>
                )
              })}
          </Collapse>
          <NodeConnectedFolderMenu
            endpoints={this.props.endpoints.endpoints}
            allFolders={this.props.folders.byId}
            activeFolder={this.props.folders.activeFolder}
            bin={this.props.folders.recycleBin}
            groups={this.props.groups}
            privileges={this.props.privileges}
            email={this.props.email}
            relationships={this.props.relationships}
          />
        </Content>
      </div>
    )
  }
}
const NodeConnectedFolderMenu = connectMenu(NODE_FOLDER_MENU)(FolderMenu)

export default DropTarget(
  [DND_FOLDER_LIB, DND_FOLDER_MST],
  folderContainerTarget,
  dropCollect
)(SidebarContent)
