import { Input, Menu } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import './styles/BamHeader.css'

class BamHeader extends React.PureComponent {
  render() {
    const brandName = this.props.mask ? this.props.mask.split('.')[0] : 'BAM!'
    return (
      <div className="bam-menu">
        <div
          className="bam-header__logo"
          style={{ width: this.props.logoWidth }}
        >
          <img
            className="logo logo--BAM"
            src={
              this.props.mask ? `/${brandName}-logo.png` : '/BAM-logo-v2.svg'
            }
            alt={`${brandName} logo`}
          />
        </div>
        <Menu
          className="bam-menu__center-section"
          mode="horizontal"
          selectedKeys={this.props.selectedKeys}
          onClick={this.props.handleClick}
        >
          <Menu.Item key="library" className="bam-menu__item">
            <NavLink
              to={`/library/${this.props.activeFolder}`}
              activeClassName=""
            >
              <span className="button__text">
                <i className={'fa fa-database bam-menu__icon'} /> Assets
              </span>
            </NavLink>
          </Menu.Item>
          {this.props.modules.builders &&
          !this.props.modules.builders.disabled &&
          this.props.isContentAdmin ? (
            <Menu.Item key="builders" className="bam-menu__item">
              <NavLink to="/builders/home" activeClassName="bam-menu__active">
                <span className="button__text">
                  <i className={'fa fa-puzzle-piece bam-menu__icon'} /> Builders
                </span>
              </NavLink>
            </Menu.Item>
          ) : null}
          {this.props.isAdmin ? (
            <Menu.Item key="settings" className="bam-menu__item">
              <NavLink
                to={this.props.settingsLink}
                activeClassName="bam-menu__active"
              >
                <span className="button__text">
                  <i className={'fa fa-unlock-alt bam-menu__icon'} /> Settings
                </span>
              </NavLink>
            </Menu.Item>
          ) : null}
          {this.props.modules.analytics &&
          !this.props.modules.analytics.disabled &&
          this.props.isContentAdmin ? (
            <Menu.Item key="analytics" className="bam-menu__item">
              <NavLink to="/analytics" activeClassName="bam-menu__active">
                <span className="button__text">
                  <i className={'fa fa-bar-chart bam-menu__icon'} /> Analytics
                </span>
              </NavLink>
            </Menu.Item>
          ) : null}
          <Menu.Item key="profile" className="bam-menu__item">
            <NavLink to="/profile" activeClassName="bam-menu__active">
              <span className="button__text">
                <i className={'fa fa-user bam-menu__icon'} /> Profile
              </span>
            </NavLink>
          </Menu.Item>
        </Menu>
        {!this.props.isDisabled() && (
          <div className="bam-header__search">
            <Input
              placeholder="Asset Search"
              value={this.props.searchQuery}
              onChange={this.props.onChangeSearch}
              onKeyPress={(e) => {
                if (e.key === 'Enter') this.props.onSubmitSearch()
              }}
              prefix={
                <i
                  className={'fa fa-search bam-header__search-icon'}
                  style={{ cursor: 'pointer' }}
                  onClick={this.props.onSubmitSearch}
                />
              }
              disabled={this.props.isDisabled()}
            />
          </div>
        )}
      </div>
    )
  }
}

BamHeader.propTypes = {
  isAdmin: PropTypes.bool,
  isContentAdmin: PropTypes.bool,
  isDisabled: PropTypes.func,
  settingsLink: PropTypes.string,
  handleClick: PropTypes.func,
  onChangeSearch: PropTypes.func,
  onSubmitSearch: PropTypes.func,
  selectedKeys: PropTypes.array,
  activeFolder: PropTypes.string,
  superuser: PropTypes.bool,
  searchQuery: PropTypes.string,
  logoWidth: PropTypes.number,
}

export default BamHeader
