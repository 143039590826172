import {
  isOutsideImage,
  isOverlapping,
} from '../../components/builders/simulator/utils'
import { UGC_ACCEPTED_STATUS, UGC_REJECTED_STATUS } from '../constants/assets'
import { SEARCH_FOLDER, UGC_FOLDER } from '../constants/folder'
import utils from './DragDropUtils'
import {
  assetIsReferenced,
  buildPath,
  folderContainsReferencedAssets,
  folderContainsReferencedFolders,
  getEndpointOfNode,
  isChild,
  scrollElement,
  throttle,
  validatePrivilege,
} from './utils'

export const DND_ASSET = 'DND_ASSET'
export const DND_FOLDER_LIB = 'DND_FOLDER_LIB'
export const DND_FOLDER_MST = 'DND_FOLDER_MST'
export const DND_SLIDE = 'DND_SLIDE'
export const DND_HOTSPOT = 'DND_HOTSPOT'
export const DND_NAVZONE = 'DND_NAVZONE'

export const assetSource = {
  beginDrag(props) {
    if (props.type === 'folder') {
      // Make sure dragged folder is active!
      if (props.id !== props.folders.activeFolder)
        props.folderActions.activeFolder(props.id)
    }
    if (props.type === 'asset') {
      // Make sure dragged asset is active!
      if (props.selectedAssets.indexOf(props.id) === -1) {
        props.appActions.selectAsset(props.id)
      }
    }
    return {
      id: props.id,
      type: props.type,
    }
  },

  /*
   * Do not allow dragging assets from search folder
   * Do not allow dragging assets from any endpoint besides the library
   * Do not allow dragging assets unless user has the move asset privilege
   */
  canDrag(props) {
    if (
      props.type === 'asset' &&
      (props.activeFolder === SEARCH_FOLDER ||
        getEndpointOfNode(
          props.allFolders[props.activeFolder],
          props.allFolders,
          props.endpoints.endpoints
        ).id !== 'LIB' ||
        !validatePrivilege(
          'moveasset',
          props.privileges,
          props.userEmail,
          props.groups,
          props.activeFolder,
          props.allFolders
        ))
    ) {
      return false
    }
    return true
  },
}

/******************
 * Folder Drag/Drop
 ******************/
export const folderSource = {
  beginDrag(props) {
    props.onBeginDrag(props.id)

    return {
      id: props.id,
      type: props.type,
    }
  },

  endDrag(props) {
    props.onEndDrag(props.id)
  },

  canDrag(props) {
    return utils.folderCanBeDragged(props)
  },
}

export const folderTarget = {
  drop(props, monitor) {
    const droppedItem = monitor.getItem()
    // If we're dropping in the recycle bin...open the delete modal
    // Otherwise, call dropFolder to move the folder
    if (droppedItem.type === 'folder') {
      if (utils.targetIsRecycleBin(props)) {
        props.modalActions.openModal('DeleteModal', {
          type: 'folder',
          folderDragData: props.folderDragData,
        })
      } else {
        const destinationFolder = props.folderDragData.over
          ? props.folderDragData.over
          : props.folderDragData.above
          ? props.folders.byId[props.folderDragData.above].parentId
          : props.folderDragData.below
          ? props.folders.byId[props.folderDragData.below].parentId
          : null
        if (destinationFolder)
          props.folderActions.dropFolder(
            destinationFolder,
            droppedItem.id,
            props.folders.byId[droppedItem.id].parentId,
            props.folderDragData
          )
      }
    } else {
      // Dropping an asset - move ALL selected assets
      if (props.folders.activeFolder === UGC_FOLDER) {
        if (utils.targetIsRecycleBin(props))
          props.assetActions.reviewUgcAssets(
            props.selectedAssets,
            UGC_REJECTED_STATUS,
            props.id
          )
        else
          props.assetActions.reviewUgcAssets(
            props.selectedAssets,
            UGC_ACCEPTED_STATUS,
            props.id
          )
      } else {
        props.assetActions.dropAsset(
          props.id,
          props.selectedAssets,
          props.folders.activeFolder
        )
      }
    }
  },

  // This function tells us whether the object being dragged is allowed to be dropped a given
  // folder
  // Since folders are both a dragItem and dropTarget,
  // only allow drop if we are over a different folder
  // and that folder is not the current parent
  // and that folder is not the search folder
  // and that folder is not a child of the dragged folder
  // and check permissions for move/delete folder
  //
  // Assets -> Only allow drop asset if destination folder is different, and user has move asset
  // privilege there
  canDrop(props, monitor) {
    if (monitor.isOver({ shallow: true })) {
      const dropCandidate = monitor.getItem()
      if (dropCandidate.type === 'asset') {
        // Can't drop published assets or referenced assets in recycleBin
        if (utils.targetIsRecycleBin(props)) {
          if (
            assetIsReferenced(dropCandidate.id, props.relationships.assets) ||
            props.allAssets[dropCandidate.id].publishedTo.length > 0
          ) {
            return false
          }
        }
        // Can't drop in same folder
        // Can't drop in UGC or Search folder
        if (
          utils.targetIsValidForAssets(props) &&
          validatePrivilege(
            'moveasset',
            props.privileges,
            props.email,
            props.groups,
            props.id,
            props.folders.byId
          )
        ) {
          return true
        }
        return false
      }

      // Can't drop a folder on itself
      if (dropCandidate.id === props.id) {
        return false
      }

      // Can't delete a folder if it has referenced assets or folders
      if (dropCandidate.type === 'folder' && utils.targetIsRecycleBin(props)) {
        const allowed = validatePrivilege(
          'deletefolder',
          props.privileges,
          props.email,
          props.groups,
          dropCandidate.id,
          props.folders.byId
        )
        const noReferences = !folderContainsReferencedAssets(
          dropCandidate.id,
          props.relationships.assets,
          props.folders.byId,
          props.endpoints.endpoints
        )
        const noReferencedFolders = !folderContainsReferencedFolders(
          dropCandidate.id,
          props.folders.byId,
          props.relationships.folders
        )
        return (
          allowed &&
          noReferences &&
          noReferencedFolders &&
          props.folders.byId[dropCandidate.id].parentId !== props.id &&
          dropCandidate.id !== props.id &&
          buildPath(dropCandidate.id, props.folders.byId).indexOf(
            props.folders.recycleBin
          ) < 0
        )
      }

      /* Can't drop in parent folder, but can reorder to above/below parent */
      if (utils.targetIsValidForFolders(props)) {
        if (props.folders.byId[dropCandidate.id].parentId === props.id) {
          if (
            props.folderDragData &&
            props.folderDragData.over === null &&
            (props.folderDragData.above ===
              props.folders.byId[dropCandidate.id].parentId ||
              props.folderDragData.below ===
                props.folders.byId[dropCandidate.id].parentId)
          )
            return true
          return false
        } else {
          return (
            !isChild(props.id, dropCandidate.id, props.folders.byId) &&
            validatePrivilege(
              'movefolder',
              props.privileges,
              props.email,
              props.groups,
              props.id,
              props.folders.byId
            ) &&
            validatePrivilege(
              'movefolder',
              props.privileges,
              props.email,
              props.groups,
              dropCandidate.id,
              props.folders.byId
            )
          )
        }
      }
      return false
    }
    return false
  },

  hover: throttle((props, monitor, component) => {
    // Determine rectangle on screen
    const item = monitor.getItem()

    if (item.id === props.id) {
      if (
        props.folderDragData.over !== null ||
        props.folderDragData.above !== null ||
        props.folderDragData.below !== null
      )
        props.setFolderDragData(null, null, null)
      return
    }

    // If hover last 250ms, expand or collapse the folder
    if (!item.timeout && props.node.childFolders.length > 0) {
      item.timeout = setTimeout(() => {
        // Get mouse position and drop target position
        const mousePos = monitor.getClientOffset()
        const hoverTargetPosition = component
          .getDecoratedComponentInstance()
          .node.getBoundingClientRect()

        if (monitor.isOver()) {
          if (
            utils.shouldCloseOnHover(props, item, mousePos, hoverTargetPosition)
          )
            props.appActions.removeOpenFolderKey(props.id)
          else if (
            utils.shouldOpenOnHover(props, item, mousePos, hoverTargetPosition)
          )
            props.appActions.addOpenFolderKey(props.id)
        }

        item.timeout = null
      }, 250)
    }

    // Get mouse position and drop target position
    const mousePos = monitor.getClientOffset()
    const hoverTargetPosition = component
      .getDecoratedComponentInstance()
      .node.getBoundingClientRect()

    // When dragging assets or hovering root folders, we only care about "over"
    if (
      item.type === 'asset' ||
      (item.type === 'folder' && utils.folderDoesNotAllowReorder(props))
    ) {
      if (
        mousePos.y > hoverTargetPosition.top &&
        mousePos.y < hoverTargetPosition.bottom
      )
        if (
          props.folderDragData.over !== props.id ||
          props.folderDragData.above !== null ||
          props.folderDragData.below !== null
        )
          props.setFolderDragData(props.id, null, null)

      return
    }
    const dragIndex = utils.getNodeIndex(props.folders.byId, item.id)
    const hoverIndex = utils.getNodeIndex(props.folders.byId, props.id)
    // Dragged item is below hovered item (bottom 1/4)
    if (
      mousePos.y >
      hoverTargetPosition.top + (hoverTargetPosition.height * 3) / 4
    ) {
      if (
        dragIndex - hoverIndex !== 1 &&
        props.id !== props.folders.byId[item.id].parentId
      )
        if (
          props.folderDragData.over !== null ||
          props.folderDragData.above !== null ||
          props.folderDragData.below !== props.id
        )
          props.setFolderDragData(null, null, props.id)
      // Dragged item is above hovered item (top 1/4)
    } else if (
      mousePos.y <
      hoverTargetPosition.bottom - (hoverTargetPosition.height * 3) / 4
    ) {
      if (hoverIndex - dragIndex !== 1)
        if (
          props.folderDragData.over !== null ||
          props.folderDragData.above !== props.id ||
          props.folderDragData.below !== null
        )
          props.setFolderDragData(null, props.id, null)
      // Dragged item is over hovered item (middle 1/2)
    } else if (
      mousePos.y >
        hoverTargetPosition.bottom - (hoverTargetPosition.height * 3) / 4 &&
      mousePos.y <
        hoverTargetPosition.bottom - (hoverTargetPosition.height * 1) / 4
    ) {
      if (
        props.folderDragData.over !== props.id ||
        props.folderDragData.above !== null ||
        props.folderDragData.below !== null
      )
        props.setFolderDragData(props.id, null, null)
    } else {
      if (
        props.folderDragData.over !== null ||
        props.folderDragData.above !== null ||
        props.folderDragData.below !== null
      )
        props.setFolderDragData(null, null, null)
    }
  }, 50),
}

export const folderContainerTarget = {
  hover: throttle((props, monitor, component) => {
    const hoverTargetPosition =
      component.container && component.container.getBoundingClientRect()
    if (hoverTargetPosition) {
      const container = document.getElementById('sidebar-container')

      const mousePos = monitor.getClientOffset()
      if (mousePos.y < hoverTargetPosition.top + 42) {
        if (container.scrollTop > 0 && !container.scrolling) {
          scrollElement(
            container,
            container,
            container.scrollTop,
            -42,
            150,
            'vertical'
          )
        }
      }
      if (
        !container.scrolling &&
        mousePos.y > hoverTargetPosition.bottom - 42 &&
        container.scrollHeight > container.clientHeight &&
        container.scrollHeight - container.scrollTop > container.clientHeight
      ) {
        console.log(
          'scrollable down',
          container.clientHeight,
          container.scrollHeight,
          container.scrollTop
        )
        scrollElement(
          container,
          container,
          container.scrollTop,
          42,
          150,
          'vertical'
        )
      }
    }
  }),
}

export const slideTarget = {
  hover(props, monitor, component) {
    // Determine rectangle on screen
    const item = monitor.getItem()
    if (item.index === props.index) {
      return
    }

    const mousePos = monitor.getClientOffset()
    const hoverTarget = component
      .getDecoratedComponentInstance()
      .slideRef.getBoundingClientRect()
    if (
      (props.index > item.index &&
        mousePos.x > hoverTarget.left + hoverTarget.width / 2) ||
      (props.index < item.index &&
        mousePos.x < hoverTarget.right - hoverTarget.width / 2)
    ) {
      props.reorderSlides(
        props.builderType,
        item.index,
        props.index,
        item.slide
      )
      monitor.getItem().index = props.index
    }
  },
}

export const slideContainerTarget = {
  hover(props, monitor) {
    // Determine rectangle on screen
    const item = monitor.getItem()
    const container = document.getElementById('slide-container')
    const itemEl = document.getElementById(item.id)

    if (itemEl && container && !item.scrolling) {
      const itemPos = monitor.getSourceClientOffset()
      const containerRect = container.getBoundingClientRect()
      const itemRect = itemEl.getBoundingClientRect()
      if (itemPos.x < containerRect.x) {
        item.scrolling = true
        scrollElement(item, container, container.scrollLeft, -92, 500)
      }
      if (itemPos.x + itemRect.width > containerRect.right) {
        item.scrolling = true
        scrollElement(item, container, container.scrollLeft, 92, 500)
      }
    }
  },
}

export const slideSource = {
  beginDrag(props) {
    return {
      id: props.slide.id,
      slide: props.slide,
      index: props.index,
      builderType: props.builderType,
    }
  },
  canDrag() {
    return true
  },
  isDragging(props, monitor) {
    return monitor.getItem().id === props.slide.id
  },
}

export const uploadTarget = {
  drop(props, monitor) {
    const newFile = monitor.getItem().files[0]
    if (newFile.type !== '') {
      props.uploadDroppedFile(newFile)
      //props.assetActions.dropAsset(props.id, props.folders.activeFolder)
    } else {
      props.modalActions.setMessage({ type: 'ERROR', msg: 'Unknown File Type' })
      props.modalActions.openModal('MessageModal')
    }
  },

  canDrop() {
    return true
  },
}

export const hotspotTarget = {
  drop(props, monitor) {
    const item = monitor.getItem()
    const itemX = item.center.x + item.center.x * item.initialLocation.x
    const itemY = item.center.y - item.center.y * item.initialLocation.y
    const finalX = monitor.getClientOffset().x
    const finalY = monitor.getClientOffset().y
    const origX = monitor.getInitialClientOffset().x
    const origY = monitor.getInitialClientOffset().y
    const moveX = finalX - origX
    const moveY = origY - finalY
    const newX = itemX + moveX
    const newY = itemY - moveY
    const xPct = (newX - item.center.x) / item.center.x
    const yPct = (item.center.y - newY) / item.center.y
    props.builderActions.updateHotspot(
      item.builderType,
      item.slideId,
      item.id,
      {
        x: xPct,
        y: yPct,
      }
    )
  },
}

export const hotspotSource = {
  beginDrag(props) {
    return {
      id: props.hotspot.id,
      slideId: props.parentId,
      builderType: props.builderType,
      initialLocation: props.hotspot.location,
      center: props.center,
    }
  },
  canDrag() {
    return true
  },
  isDragging(props, monitor) {
    return monitor.getItem().id === props.hotspot.id
  },
}

export const navzoneSource = {
  beginDrag(props) {
    return {
      id: props.navzone.id,
      navzone: props.navzone,
      slideId: props.parentId,
      builderType: props.builderType,
      center: props.center,
    }
  },
  isDragging(props, monitor) {
    return monitor.getItem().id === props.navzone.id
  },
  canDrag(props) {
    return !props.dragHandleDown
  },
}

export const navzoneTarget = {
  drop(props, monitor) {
    const { builderType, builderActions, activeSlide } = props
    const item = monitor.getItem()
    const finalX = monitor.getClientOffset().x
    const finalY = monitor.getClientOffset().y
    const origX = monitor.getInitialClientOffset().x
    const origY = monitor.getInitialClientOffset().y
    const moveXPct = ((finalX - origX) / props.imageWidth) * 2
    const moveYPct = ((finalY - origY) / props.imageHeight) * 2
    const newLocation = {
      topLeft: {
        x: item.navzone.topLeft.x + moveXPct,
        y: item.navzone.topLeft.y - moveYPct,
      },
      bottomRight: {
        x: item.navzone.bottomRight.x + moveXPct,
        y: item.navzone.bottomRight.y - moveYPct,
      },
    }
    if (
      !isOutsideImage(newLocation) &&
      !isOverlapping(activeSlide.navzones, newLocation, item.navzone.id)
    ) {
      const newNavzone = {
        ...item.navzone,
        topLeft: newLocation.topLeft,
        bottomRight: newLocation.bottomRight,
      }
      builderActions.updateNavzone(builderType, activeSlide.id, newNavzone)
    }
  },
}

export function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }
}

export function dropCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }
}
