export const PDF_IMAGE_FILE_TYPE = 'png'
export const HTML5_VIDEO_POSTER = 'VideoPoster.png'
// VIDEO TRANSCODING STATUS
export const TRANSCODING_STATUS_PENDING = 'PENDING'
export const TRANSCODING_STATUS_ERROR = 'ERROR'
export const TRANSCODING_STATUS_COMPLETE = 'COMPLETE'
export const TRANSCODING_STATUS_HIDDEN = 'HIDDEN'
export const TRANSCODING_STATUS_UNSUPPORTED = 'UNSUPPORTED'
export const TRANSCODING_EXTENSION = 'mp4'
// METADATA
export const ASSET_TITLE_MAX_LENGTH = 100
export const ASSET_DESCRIPTION_MAX_LENGTH = 500
// UGC
export const UGC_ACCEPTED_STATUS = 'ACCEPTED'
export const UGC_REJECTED_STATUS = 'REJECTED'
