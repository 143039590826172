import React from 'react'
import { getEndpointOfNode, getEndpointStatus } from '../../dux/modules/utils'
import {
  MST_ENDPOINT_TYPE,
  WEB_ENDPOINT_TYPE,
} from '../../dux/constants/endpoints'

const StatusIcon = ({
  activeFolder = null,
  allFolders,
  endpoints,
  item = {},
}) => {
  let status = null

  if (
    activeFolder &&
    getEndpointOfNode(allFolders[activeFolder], allFolders, endpoints.endpoints)
      .allowPublishing
  ) {
    status = getEndpointStatus(
      activeFolder,
      item.id,
      endpoints.folderAssetStatus
    )
  } else if (item.publishedTo && item.publishedTo.length) {
    // if any endpoint is MST/not web-endpoint, use that
    // if all are web-endpoint, use web-endpoint
    // it is possible to be published to a folder we no longer have access to, so can't
    // assume we can read allFolder[folder]
    const folder =
      item.publishedTo.find((folderId) => {
        if (allFolders && allFolders[folderId]) {
          const rootEndpoint = getEndpointOfNode(
            allFolders[folderId],
            allFolders,
            endpoints.endpoints
          )
          return rootEndpoint.type !== WEB_ENDPOINT_TYPE
        }

        return false
      }) || item.publishedTo[0]
    let endpointType = MST_ENDPOINT_TYPE

    if (allFolders && allFolders[folder]) {
      endpointType = getEndpointOfNode(
        allFolders[folder],
        allFolders,
        endpoints.endpoints
      ).type

      status = getEndpointStatus(folder, item.id, endpoints.folderAssetStatus, {
        specialtyIcon: true,
        endpointType,
      })
    }
  }

  return status ? (
    <span className="assetcard__status-icon">{status}</span>
  ) : null
}

export default StatusIcon
