/* eslint-disable react/prop-types */
import { Button, Checkbox, Icon, Input } from 'antd'
import update from 'immutability-helper'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as modalActions from '../../dux/actions/modal'
import * as actions from '../../dux/actions/permissions'
import { isAdmin } from '../../dux/modules/utils'
import FourOhFour from '../common/FourOhFour'
import UsersList from './UsersList'
import UserTableActions from './UserTableActions'

class UsersContainer extends React.Component {
  constructor(props) {
    super(props)

    this.filterInputs = {}
    this.state = {
      dropdownField: '',
      dropdownVisible: false,
      filtered: false,
      filters: [],
    }
  }

  componentDidMount() {
    this.props.actions.getAllPermissionsData()
  }

  applyUserFilters = (user, groups) => {
    if (!this.state.filtered || this.state.filters.length === 0) {
      return null
    }

    const filters = this.state.filters.map((filter) => ({
      ...filter,
      regex: new RegExp(filter.value, 'gi'),
    }))
    const match = {}

    for (let i = 0, length = filters.length; i < length; i++) {
      const { field, regex } = filters[i]

      if (field === 'group') {
        if (
          !groups.some((grp) =>
            regex.test(this.props.permissions.groups[grp].name)
          )
        ) {
          return null
        }
      } else {
        const fieldMatch = user[field].match(regex)

        if (!fieldMatch) {
          return null
        }

        match[`filtered${field}`] = (
          <span key={user[field]}>
            {user[field].split(regex).map((val, i) =>
              i > 0
                ? [
                    <span key={i} style={{ color: 'red' }}>
                      {fieldMatch[0]}
                    </span>,
                    val,
                  ]
                : val
            )}
          </span>
        )
      }
    }

    return match
  }

  prepareUsers = () => {
    const { filtered } = this.state
    const { users } = this.props.permissions

    return Object.keys(users)
      .map((id) => {
        const user = users[id]
        const groups = Object.keys(this.props.permissions.groups).filter(
          (grp) =>
            this.props.permissions.groups[grp].members.includes(user.email)
        )
        let match

        if (!filtered || (match = this.applyUserFilters(user, groups))) {
          return {
            ...user,
            groups,
            ...match,
          }
        }

        return null
      })
      .filter((record) => !!record)
      .sort((a, b) => a.email.localeCompare(b.email))
  }

  onFilterDropdownVisibleChange = (field) => (visible) => {
    this.setState(
      {
        dropdownField: field,
        dropdownVisible: visible,
      },
      () => this.filterInputs[field] && this.filterInputs[field].focus()
    )
  }

  onFilterInputChange = (field) => (e) => {
    const { filtered, filters } = this.state
    const idx = filters.findIndex((filter) => filter.field === field)
    const newFilter = {
      field,
      value: e.target.value,
    }
    let newFilters

    if (newFilter.value.length === 0 && idx >= 0) {
      newFilters = update(filters, {
        $splice: [[idx, 1]],
      })
    } else if (idx >= 0) {
      newFilters = update(filters, {
        $splice: [[idx, 1, newFilter]],
      })
    } else {
      newFilters = update(filters, {
        $push: [newFilter],
      })
    }

    this.setState({
      filtered: filtered && newFilters.length > 0,
      filters: newFilters,
    })
  }

  onFilterSearch = () => {
    const { filters } = this.state

    this.setState({
      dropdownVisible: false,
      filtered: filters.length > 0,
    })
  }

  removeFilterFields = (user) => {
    return Object.entries(user).reduce((acc, [key, val]) => {
      if (key.startsWith('filtered')) {
        return acc
      }

      return {
        ...acc,
        [key]: val,
      }
    }, {})
  }

  renderFilterDropdown = (field, filters) => {
    const filter = filters.find((f) => f.field === field)

    return (
      <div className="permissions__filter-dropdown">
        <Input
          onChange={this.onFilterInputChange(field)}
          onPressEnter={this.onFilterSearch}
          placeholder={`Search ${field}`}
          ref={(el) => (this.filterInputs[field] = el)}
          value={filter && filter.value}
        />

        <Button type="primary" onClick={this.onFilterSearch}>
          Search
        </Button>
      </div>
    )
  }

  renderFilterIcon = (field, filters) => {
    const filter = filters.find((f) => f.field === field)

    return <Icon style={{ color: filter ? '#108ee9' : '#aaa' }} type="search" />
  }

  render() {
    const admin = isAdmin(this.props.email, this.props.permissions.groups)
    const { dropdownField, dropdownVisible, filters } = this.state
    const columns = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 300,
        filterDropdown: this.renderFilterDropdown('email', filters),
        filterIcon: this.renderFilterIcon('email', filters),
        filterDropdownVisible: dropdownVisible && dropdownField === 'email',
        onFilterDropdownVisibleChange:
          this.onFilterDropdownVisibleChange('email'),
        render: (key, record) => record.filteredemail || record.email,
        className: 'bam__table-column bam__table-column-maxwidth300',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        width: 250,
        filterDropdown: this.renderFilterDropdown('name', filters),
        filterIcon: this.renderFilterIcon('name', filters),
        filterDropdownVisible: dropdownVisible && dropdownField === 'name',
        onFilterDropdownVisibleChange:
          this.onFilterDropdownVisibleChange('name'),
        render: (key, record) => record.filteredname || record.name,
        className: 'bam__table-column bam__table-column-maxwidth250',
      },
      {
        title: 'Last Login',
        dataIndex: 'lastLogin',
        width: 100,
        render: (record) => (
          <span>{moment(record || Date.now()).format('MM/DD/YYYY')}</span>
        ),
        className: 'bam__table-column bam__table-column-maxwidth100',
      },
      {
        title: 'Admin',
        dataIndex: 'admin',
        width: 70,
        render: (key, record) => (
          <Checkbox
            disabled
            checked={isAdmin(record.email, this.props.permissions.groups)}
          />
        ),
        className: 'bam__table-column bam__table-column-maxwidth70',
      },
      {
        title: 'Groups',
        dataIndex: 'groups',
        width: 170,
        filterDropdown: this.renderFilterDropdown('group', filters),
        filterIcon: this.renderFilterIcon('group', filters),
        filterDropdownVisible: dropdownVisible && dropdownField === 'group',
        onFilterDropdownVisibleChange:
          this.onFilterDropdownVisibleChange('group'),
        render: (record) => (
          <div className="permissions__group-list">
            {record
              .sort((a, b) =>
                this.props.permissions.groups[a].name.localeCompare(
                  this.props.permissions.groups[b].name
                )
              )
              .map((r) => (
                <div key={r}>{this.props.permissions.groups[r].name}</div>
              ))}
          </div>
        ),
        className: 'bam__table-column bam__table-column-maxwidth170',
      },
      {
        title: 'Actions',
        width: 120,
        render: (record) => (
          <UserTableActions
            record={this.removeFilterFields(record)}
            isAdmin={admin}
            loggedInUser={this.props.loggedInUser}
          />
        ),
        className: 'bam__table-column bam__table-column-maxwidth120',
      },
    ]

    const data = this.prepareUsers()

    return !this.props.appState.initializing && !admin ? (
      <FourOhFour />
    ) : (
      <UsersList
        dataSource={data}
        columns={columns}
        tablesize={this.props.appState.windowWidth < 1600 ? 'middle' : null}
        rowKey={(record) => record.id}
        height={this.props.appState.windowHeight}
        modalActions={this.props.modalActions}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  permissions: state.permissions,
  appState: state.appState,
  email: state.user.email,
  loggedInUser: state.user.email,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  modalActions: bindActionCreators(modalActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer)
