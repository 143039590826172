import { Collapse, Icon, Layout } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import SalesForceIcon from './SalesForceIcon'
import '../styles/Sidebar.css'

const SettingsSidebarContent = (props) => {
  const { Content } = Layout
  const Panel = Collapse.Panel

  return (
    <div className="bam-sidebar">
      <Content className="bam-sidebar__content">
        <Collapse
          activeKey={props.openKeys}
          onChange={props.onCollapseChange}
          style={{
            background: '#344353',
            border: 'none',
            fontSize: '20px',
            fontWeight: 500,
            padding: 0,
          }}
        >
          {props.settingsMenu.map((panel) => {
            return (
              <Panel
                header={
                  <div className="bam-sidebar__collapse-panel-header">
                    <span>
                      <i
                        className={`bam-sidebar__collapse-pointer fa fa-caret-${
                          props.openKeys.indexOf(panel.id) > -1
                            ? 'down'
                            : 'right'
                        }`}
                      />
                      <span style={{ padding: '12px 8px 12px 0' }}>
                        {panel.name}
                      </span>
                    </span>
                  </div>
                }
                key={panel.id}
                className={`bam-sidebar__collapse${
                  props.activeKey === panel.id ? '-active' : ''
                }`}
                showArrow={false}
              >
                {panel.items.map((item) => {
                  return (
                    <Link
                      to={item.route}
                      key={item.id}
                      onClick={() => props.onItemClick(item.id, panel.id)}
                    >
                      <div
                        className={`endpoint__link${
                          item.id === props.activeItem ? '-active' : ''
                        }`}
                      >
                        <span>
                          {item.icon === 'salesforce' ? (
                            <span>
                              <Icon component={SalesForceIcon} /> &nbsp;
                            </span>
                          ) : (
                            <span>
                              <i className={`fa fa-${item.icon}`} /> &nbsp;
                            </span>
                          )}
                          <span>{item.displayName}</span>
                        </span>
                      </div>
                    </Link>
                  )
                })}
              </Panel>
            )
          })}
        </Collapse>
      </Content>
    </div>
  )
}

SettingsSidebarContent.propTypes = {
  openKeys: PropTypes.arrayOf(PropTypes.string),
  onCollapseChange: PropTypes.func,
  settingsMenu: PropTypes.arrayOf(PropTypes.object),
  activeKey: PropTypes.string,
  onItemClick: PropTypes.func,
  activeItem: PropTypes.string,
}

export default SettingsSidebarContent
