/**
 * CalculatorInput Component
 * The custom input component with simple validation for the
 * calculator inputs.
 *
 */

import PropTypes from 'prop-types'
import { Input } from 'antd'
import React, { Component } from 'react'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'

export class CalculatorInput extends Component {
  constructor(props) {
    super(props)
    this.state = { text: props.defaultValue }
  }

  formatNumberToDecimal = ({ target: { value } }) => {
    const { decimalPlaces } = this.props
    const numVal = Number(value)
    const validNumber = !isNaN(numVal)
    if (validNumber) {
      const numberWithDecimals = numVal.toFixed(decimalPlaces || 0)
      this.setState({ text: String(numberWithDecimals) })
    }
  }
  handleTextChange = ({ target: { value } }) => {
    const {
      required,
      minimum,
      maximum,
      id,
      dataType,
      clearError,
      setInputState,
      setError,
    } = this.props
    this.setState({ text: value }, () =>
      isValidText(value, { required, minimum, maximum })
        ? (clearError(id), setInputState(id, value, dataType))
        : setError(id)
    )
  }
  render() {
    const { required, minimum, maximum, label, units } = this.props
    const { text } = this.state
    const numVal = Number(text)
    const errorMessage =
      required !== false && text === ''
        ? 'Required'
        : isNaN(numVal) || containsChars(text)
        ? 'Invalid Number'
        : numVal > maximum
        ? 'Maximum value is ' + maximum.toLocaleString()
        : numVal < minimum
        ? 'Minimum value is ' + minimum.toLocaleString()
        : undefined

    return (
      <div>
        <div>
          <Title className="assetdetail__calc-text-secondary" level={4}>
            {label}
          </Title>
          <Input
            type="text"
            value={text}
            onChange={this.handleTextChange}
            onBlur={this.formatNumberToDecimal}
            addonAfter={units}
          />
        </div>
        {errorMessage && <Text type="danger">{errorMessage}</Text>}
      </div>
    )
  }
}

const containsChars = (string) => /\D^./g.test(string)
const isValidText = (val, { required, minimum, maximum }) => {
  const parsedVal = Number(val)
  return !(
    (required !== false && val === '') ||
    isNaN(parsedVal) ||
    containsChars(val) ||
    parsedVal > maximum ||
    parsedVal < minimum
  )
}
CalculatorInput.propTypes = {
  id: PropTypes.string,
  units: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChangeText: PropTypes.func,
  defaultValue: PropTypes.string,
  maximum: PropTypes.number,
  minimum: PropTypes.number,
  required: PropTypes.bool,
  setError: PropTypes.func,
  clearError: PropTypes.func,
  setInputState: PropTypes.func,
  dataType: PropTypes.string,
  decimalPlaces: PropTypes.number,
}

export default CalculatorInput
