import { Row } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as appActions from '../../dux/actions/appActions'
import * as settingsActions from '../../dux/actions/settings'
import LoadingModal from '../modals/LoadingModal'
import BuildingBlock from './BuildingBlock'
import '../styles/Builders.css'

class BuilderHome extends React.Component {
  componentDidMount() {
    this.props.settingsActions.refreshSettings()
    this.props.appActions.scrollToTop('main-content')
  }

  render() {
    const { builders } = this.props

    return (
      <div>
        <div className="builders__header">
          <h1 className="builders__header-text">Build a Custom Asset</h1>
        </div>
        <div className="builders__home-grid">
          <Row
            type="flex"
            gutter={{ xs: 16, sm: 16, md: 16, lg: 20, xl: 20, xxl: 24 }}
          >
            {builders.map((builder) => {
              return (
                <BuildingBlock
                  key={`${builder.key}`}
                  block={builder}
                  history={this.props.history}
                />
              )
            })}
          </Row>
        </div>
        <LoadingModal loading={this.props.pending} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  builders: state.settings.modules.builders,
  pending: state.appState.asyncPending,
})

const mapDispatchToProps = (dispatch) => ({
  settingsActions: bindActionCreators(settingsActions, dispatch),
  appActions: bindActionCreators(appActions, dispatch),
})

BuilderHome.propTypes = {
  builders: PropTypes.object,
  pending: PropTypes.bool,
  history: PropTypes.object,
  settingsActions: PropTypes.shape({
    refreshSettings: PropTypes.func,
  }),
  appActions: PropTypes.shape({
    scrollToTop: PropTypes.func,
  }),
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BuilderHome)
)
