import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as builderActions from '../../../../dux/actions/builders'
import {
  getEndpointById,
  isChild,
  validatePrivilege,
} from '../../../../dux/modules/utils'
import PickerView from './PickerView'

class Picker extends React.Component {
  onNodeClick = (id) => {
    if (!this.nodeIsActive(id)) {
      this.props.setActiveFolder(id)
    }
  }

  nodeIsActive = (id, parentFolder = null) => {
    if (parentFolder)
      return (
        this.props.activeFolder === parentFolder && this.props.activeNode === id
      )
    else return this.props.activeNode === id
  }
  onToggleFolder = (id) => {
    this.nodeIsOpen(id)
      ? this.props.removeOpenFolderKey(id)
      : this.props.addOpenFolderKey(id)
  }

  nodeIsOpen = (id) => this.props.openFolderKeys.indexOf(id) > -1

  removeOpenFolderKey = (id) => {
    this.props.removeOpenFolderKey(id)
  }

  addOpenFolderKey = (id) => {
    this.props.addOpenFolderKey(id)
  }

  /*
   * Disable folders that don't have selected privileger, or folder in the recycle bin/search area
   */
  nodeIsDisabled = (id) => {
    if (
      id === '_search' ||
      id === this.props.folders.recycleBin ||
      isChild(id, this.props.folders.recycleBin, this.props.folders.byId)
    ) {
      return true
    }
    if (this.props.folders.byId[id]) {
      return !validatePrivilege(
        this.props.privilegedRole,
        this.props.permissions.privileges,
        this.props.user.email,
        this.props.permissions.groups,
        id,
        this.props.folders.byId
      )
    }
    // Assets
    return false
  }

  setActiveFolder = (id) => {
    this.props.setActiveFolder(id)
  }

  setActiveAsset = (id, parent) => {
    this.props.setActiveAsset(id, parent)
  }

  nodeLink = (id) => `/library/${id}`

  getFilter = () => {
    if (this.props.filter) return this.props.filter
    return (id) => this.props.allAssets[id]
  }
  render() {
    const {
      folders,
      endpoints,
      appState,
      activeFolder,
      openFolderKeys,
      showAssets,
      allAssets,
      style,
      displayMode,
    } = this.props
    const treeRoot = getEndpointById('LIB', endpoints.endpoints).rootFolders

    return (
      <PickerView
        appState={appState}
        folders={folders}
        treeRoot={treeRoot}
        allAssets={allAssets}
        showAssets={showAssets}
        activeFolder={activeFolder}
        setActiveFolder={this.setActiveFolder}
        setActiveAsset={this.setActiveAsset}
        openFolderKeys={openFolderKeys}
        removeOpenFolderKey={this.removeOpenFolderKey}
        toggleFolder={this.onToggleFolder}
        nodeLink={this.nodeLink}
        nodeIsOpen={this.nodeIsOpen}
        nodeIsActive={this.nodeIsActive}
        nodeIsDisabled={this.nodeIsDisabled}
        onNodeClick={this.onNodeClick}
        onAssetClick={this.setActiveAsset}
        assetFilter={this.getFilter()}
        displayMode={displayMode}
        style={style}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  allAssets: state.assets.byId,
  folders: state.folders,
  endpoints: state.endpoints,
  appState: state.appState,
  permissions: state.permissions,
  user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
  builderActions: bindActionCreators(builderActions, dispatch),
})

Picker.propTypes = {
  style: PropTypes.object,
  selectedPickerNode: PropTypes.string,
  builderType: PropTypes.string,
  allAssets: PropTypes.object,
  showAssets: PropTypes.bool,
  setActiveFolder: PropTypes.func,
  activeFolder: PropTypes.string,
  addOpenFolderKey: PropTypes.func,
  removeOpenFolderKey: PropTypes.func,
  openFolderKeys: PropTypes.arrayOf(PropTypes.string),
  privilegedRole: PropTypes.string,
  folders: PropTypes.object,
  endpoints: PropTypes.object,
  appState: PropTypes.object,
  permissions: PropTypes.object,
  user: PropTypes.object,
  displayMode: PropTypes.string,
}

Picker.defaultProps = {
  displayMode: 'filename',
}

export default connect(mapStateToProps, mapDispatchToProps)(Picker)
