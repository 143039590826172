import { Button, Divider, Input } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

const Reports = (props) => (
  <div>
    <div>
      <div style={{ color: 'rgba(0,0,0,.8)', margin: 10, width: 200 }}>
        Start Date (MM/DD/YYYY):{' '}
        <Input
          value={props.startDate}
          id="startDate"
          onChange={props.changeHandler}
        />
      </div>
      <div style={{ color: 'rgba(0,0,0,.8)', margin: 10, width: 200 }}>
        End Date (MM/DD/YYYY):{' '}
        <Input
          value={props.endDate}
          id="endDate"
          onChange={props.changeHandler}
        />
      </div>
    </div>
    <div style={{ color: 'rgba(0,0,0,.8)', margin: 10 }}>
      {props.reportMessage}
    </div>
    <Button onClick={props.onNewAssetReport} style={{ margin: '0 10px' }}>
      Run New Asset Report
    </Button>
    <Divider />
    <Button onClick={props.onUserAuditReport} style={{ margin: '0 10px' }}>
      Run User Audit Report
    </Button>
  </div>
)

Reports.propTypes = {
  onUserAuditReport: PropTypes.func.isRequired,
  onNewAssetReport: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  reportMessage: PropTypes.string.isRequired,
}

export default Reports
