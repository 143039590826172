/**
 * CalculatorPicker Component
 * A custom picker component for selecting values within the calculators
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import Title from 'antd/lib/typography/Title'

const CalculatorPicker = ({
  id,
  units = '',
  value,
  label,
  options,
  onChange,
  dataType,
}) => {
  const unitsLabel = units ? ` (${units})` : ''
  const changeHandler = (value) => onChange(id, value, dataType)
  return (
    <div>
      <Title level={4} className="assetdetail__calc-text-secondary">
        {label} {unitsLabel}
      </Title>
      <Select
        defaultValue={value}
        onChange={changeHandler}
        style={{ width: '80%' }}
      >
        {options.map(({ value }) => (
          <Select.Option value={value} key={value}>
            {value}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

CalculatorPicker.propTypes = {
  id: PropTypes.string,
  units: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  onChangeText: PropTypes.func,
}

CalculatorPicker.defaultProps = {
  id: undefined,
  units: '',
  value: undefined,
  label: '',
  options: [],
  onChangeText: () => {},
}

export default CalculatorPicker
