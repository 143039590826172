import update from 'immutability-helper'
import { isEqual } from 'lodash'
import { EXPAND_ALL } from '../../../dux/constants/permissions'
import { buildPath } from '../../../dux/modules/utils'

const validFolderTypes = ['folder', 'root', 'resources', 'webfolder']

export const getEditableRootFolders = (rootFolders, allFolders) =>
  rootFolders.filter(
    (folder) => validFolderTypes.indexOf(allFolders[folder].type) > -1
  )

export const getDefaultExpandedNodes = (endpoints, allFolders) => {
  const defaultNodes = endpoints.reduce((result, node) => {
    result[node.id] = {
      expandedNodes: getEditableRootFolders(node.rootFolders, allFolders),
      expandAllToggle: EXPAND_ALL,
      filterOption: 'all',
    }
    return result
  }, {})
  return defaultNodes
}

export const getAllParentNodes = (endpointId, endpoints, allFolders) => {
  let nodes = []
  const ep = endpoints.filter((endpoint) => endpoint.id === endpointId)[0]
  const roots = getEditableRootFolders(ep.rootFolders, allFolders)
  // Only include folders that have children, and are subfolders of the given root
  Object.keys(allFolders).forEach((folder) => {
    if (
      allFolders[folder].childFolders.length > 0 &&
      buildPath(folder, allFolders).some(
        (element) => roots.indexOf(element) > -1
      )
    )
      nodes.push(folder)
  })
  return nodes
}

export const getChangedContentRoles = (contentRoles = {}, allFolders) => {
  let changedRoles = {}
  Object.keys(allFolders).forEach((folder) => {
    if (!isEqual(allFolders[folder].contentRoles, contentRoles[folder]))
      changedRoles[folder] = contentRoles[folder]
  })
  return changedRoles
}

export const hasTraverserOnly = (contentRoles, folder, entity) => {
  const roles = contentRoles[folder][entity].roleIds
  return roles.length === 1 && roles[0] === 'traverser'
}

export const removeEntityFromFolder = (state, folder, entity) => {
  return update(state, {
    contentRoles: {
      [folder]: {
        $unset: [entity],
      },
    },
  })
}

export const removeRoleFromEntity = (state, folder, entity, roleToRemove) => {
  return update(state, {
    contentRoles: {
      [folder]: {
        [entity]: {
          roleIds: {
            $apply: (x) => x.filter((role) => role !== roleToRemove),
          },
        },
      },
    },
  })
}

export const addEntityToFolder = (
  state,
  folder,
  entity,
  entityType,
  rolesToAdd
) => {
  return update(state, {
    contentRoles: {
      [folder]: {
        [entity]: {
          $set: {
            entityType: entityType,
            entityId: entity,
            roleIds: rolesToAdd,
          },
        },
      },
    },
  })
}

export const addRoleToEntity = (state, folder, entity, roleArray) => {
  return update(state, {
    contentRoles: {
      [folder]: {
        [entity]: {
          roleIds: {
            $push: roleArray,
          },
        },
      },
    },
  })
}
