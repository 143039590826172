import { Button } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

const Reconcile = (props) => (
  <div>
    <div style={{ color: 'rgba(0,0,0,.8)', margin: 10 }}>
      Click to create an action which will rebuild the asset relationships.
      After the action is created, refresh your screen to force BAM! rebuild the
      relationships.
    </div>
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <Button
        onClick={props.onClick}
        loading={props.asyncPending}
        style={{ margin: '0 10px' }}
      >
        Reconcile Relationships
      </Button>
    </div>
    {props.reconcileActionCreated && (
      <div style={{ color: 'rgba(0,0,0,.8)', margin: 10 }}>
        Done! Refresh your screen to have BAM! process this action
      </div>
    )}
  </div>
)

Reconcile.propTypes = {
  onclick: PropTypes.func.isRequired,
  asyncPending: PropTypes.bool.isRequired,
  reconcileActionCreated: PropTypes.bool.isRequired,
}

export default Reconcile
