import { Icon, Menu } from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { SIDEBAR_BACKGROUND_COLOR } from '../../../../dux/constants/analytics'

// ****************
// React Components
// ****************
/* Individual Sidebar menu items. One per Analytics page
 *  @param   {string}    icon
 *  @param   {string}    title
 *  @param   {bool}    isLargeIcon
 */
const SidebarMenuItem = ({ icon, title, isLargeIcon }) => {
  const iconStyles = isLargeIcon
    ? { ...styles.menuItemIcon, fontSize: '22px' }
    : styles.menuItemIcon

  return (
    <div>
      <Icon type={icon} style={iconStyles} />
      <span style={styles.menuItemText}>{title}</span>
    </div>
  )
}

/*
 * Analytics Sidebar Menu component
 */
class AnalyticsSidebar extends Component {
  renderMenuItem = (menuItem, activeAnalyticsView) => {
    const { key, title, icon } = menuItem
    const { setActiveAnalyticsView, isSidebarCollapsed } = this.props

    const menuItemBackgroundColor =
      key === activeAnalyticsView ? 'white' : SIDEBAR_BACKGROUND_COLOR

    return (
      <Menu.Item
        key={key}
        style={{ ...styles.menuItem, backgroundColor: menuItemBackgroundColor }}
        onClick={() => setActiveAnalyticsView(key)}
      >
        <SidebarMenuItem
          icon={icon}
          title={title}
          isLargeIcon={isSidebarCollapsed}
        />
      </Menu.Item>
    )
  }

  render() {
    const { sidebarMenuItems, activeAnalyticsView } = this.props

    return (
      <Menu
        mode="inline"
        style={styles.menu}
        selectedKeys={[activeAnalyticsView]}
      >
        {sidebarMenuItems.map((menuItem) =>
          this.renderMenuItem(menuItem, activeAnalyticsView)
        )}
      </Menu>
    )
  }
}

// ******
// Styles
// ******
const styles = {
  menu: {
    backgroundColor: SIDEBAR_BACKGROUND_COLOR,
    color: 'white',
  },
  menuItem: {
    padding: '0px',
    margin: '0px',
  },
  menuItemIcon: {
    fontSize: '16px',
  },
  menuItemText: {
    fontSize: '15px',
    fontWeight: '600',
    fontFamily: 'Roboto',
  },
}

// *********
// PropTypes
// *********
SidebarMenuItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  isLargeIcon: PropTypes.bool,
}

AnalyticsSidebar.propTypes = {
  sidebarMenuItems: PropTypes.arrayOf(PropTypes.object),
  activeAnalyticsView: PropTypes.string,
  setActiveAnalyticsView: PropTypes.func,
  isSidebarCollapsed: PropTypes.bool,
}
// ******
// Export
// ******
export default AnalyticsSidebar
