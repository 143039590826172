import { isChild } from '../../dux/modules/utils'

/*
 * Exception function.  Validates whether a folder is a top-level folder by checking for
 * existence of parent folder
 */
export const isRoot = (folderId, allFolders) => {
  let isRoot = true
  if (allFolders[folderId] && allFolders[folderId].parentId) isRoot = false
  return isRoot
}

/*
 * Exception function.  Validates whether a folder is a child folder of the recycle bin
 * (can be a grandchild, etc)
 */
export const isInRecycleBin = (folderId, allFolders, constants) => {
  const binKeys = Object.keys(allFolders).filter(
    (folder) => allFolders[folder].type === constants.RECYCLE_BIN_FOLDER_TYPE
  )
  // User can't see recycle bin!
  if (binKeys.length === 0) return false

  return isChild(folderId, binKeys[0], allFolders)
}

/*
 * Exception function.  Validates whether a folder is a webfolder root folder
 */
export const isWebRootFolder = (
  folderId,
  allFolders,
  constants,
  folderType
) => {
  const folder = allFolders[folderId]
  return folderType === 'webfolder' && !folder.parentId
}

export const isVirtual = (folderId, allFolders) => {
  return allFolders[folderId] && allFolders[folderId].virtual
}
