import { Modal } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { UGC_ACCEPTED_STATUS } from '../../dux/constants/assets'
import { validatePrivilege } from '../../dux/modules/utils'
import TreeNode from '../sidebar/TreeNode'

import '../styles/Modal.css'

class ReviewUGCModal extends React.Component {
  state = {
    activeFolder: null,
    openFolderKeys: this.props.appState.openFolderKeys,
  }
  save = () => {
    this.props.assetActions.reviewUgcAssets(
      this.props.modalProps.assets,
      UGC_ACCEPTED_STATUS,
      this.state.activeFolder,
      this.props.history
    )
  }

  cancel = () => {
    this.props.modalActions.closeModal()
  }

  nodeIsActive = (id) => this.state.activeFolder === id

  nodeIsOpen = (id) => this.state.openFolderKeys.indexOf(id) > -1

  nodeIsDraggable = () => {
    return false
  }

  onNodeClick = (id) => {
    if (this.state.activeFolder !== id) {
      this.setState({ activeFolder: id })
    }
  }

  toggleFolder = (id) => {
    if (this.state.openFolderKeys.indexOf(id) < 0) {
      this.setState({ openFolderKeys: this.state.openFolderKeys.concat([id]) })
    } else {
      this.setState({
        openFolderKeys: this.state.openFolderKeys.filter((key) => key !== id),
      })
    }
  }

  removeOpenFolderKey = (id) => {
    this.setState((prevState) => {
      return {
        openFolderKeys: prevState.openFolderKeys.filter((key) => key !== id),
      }
    })
  }

  nodeLink = () => this.props.location.pathname

  nodeIsDisabled = (id) =>
    !validatePrivilege(
      'moveasset',
      this.props.permissions.privileges,
      this.props.user.email,
      this.props.permissions.groups,
      id,
      this.props.folders.byId
    )

  setActiveFolder = (id) => {
    this.setState({ activeFolder: id })
  }

  // Not needed for ReviewUGCModal
  allowPublishing = () => false

  // Not needed for ReviewUGCModal
  getBadgeCount = () => 0

  render() {
    return (
      <Modal
        title="Review UGC Assets"
        okText="Approve"
        onOk={this.save}
        okButtonProps={{ disabled: !this.state.activeFolder }}
        cancelText="Cancel"
        onCancel={this.cancel}
        visible={this.props.modal.modalOpen}
        confirmLoading={this.props.appState.asyncPending}
        maskClosable={false}
      >
        <div className="modal__text">Select a destination:</div>
        <div className="modal-tree-container">
          {this.props.modalProps.treeRoot
            .filter((folder) => {
              return (
                this.props.folders.byId[folder].type !== 'recycle' &&
                this.props.folders.byId[folder].type !== 'ugc' &&
                this.props.folders.byId[folder].id !== '_search'
              )
            })
            .map((folderid) => {
              return (
                <TreeNode
                  key={folderid}
                  {...this.props}
                  activeFolder={this.state.activeFolder}
                  openFolderKeys={this.state.openFolderKeys}
                  removeOpenFolderKey={this.removeOpenFolderKey}
                  node={this.props.folders.byId[folderid]}
                  nodeLink={this.nodeLink}
                  isFolder
                  nodeIsActive={this.nodeIsActive}
                  nodeIsOpen={this.nodeIsOpen}
                  isRoot={
                    this.props.folders.byId[folderid].parentId ? true : false
                  }
                  nodeIsDraggable={this.nodeIsDraggable}
                  rightClickDisabled={true}
                  onToggle={this.toggleFolder}
                  onNodeClick={this.onNodeClick}
                  nodeIsDisabled={this.nodeIsDisabled}
                  setActiveFolder={this.setActiveFolder}
                  allowPublishing={this.allowPublishing}
                  badges={[]}
                  getBadgeCount={this.getBadgeCount}
                  style={{
                    treenodeText: {
                      color: 'rgba(0,0,0,.65)',
                    },
                    treenodeIcon: {
                      color: 'rgba(0,0,0,.65)',
                    },
                  }}
                />
              )
            })}
        </div>
      </Modal>
    )
  }
}

/*
 *  modal/modalActions and folder/folderActions are the redux state and actions
 */
ReviewUGCModal.propTypes = {
  modal: PropTypes.object,
  modalProps: PropTypes.object,
  modalActions: PropTypes.object,
  appState: PropTypes.object,
  folders: PropTypes.object,
  folderActions: PropTypes.object,
  user: PropTypes.object,
}

export default ReviewUGCModal
