import { Form } from 'antd'
import * as PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import TwoButtonContainer from '../BAM_UI/TwoButtonContainer'
import { getFormControl } from './SettingsUtils'

class WrappedForm extends React.PureComponent {
  render() {
    const { form } = this.props
    const { getFieldDecorator } = form

    return (
      <div className="settings__container" id="salesforcesettings">
        <div className="settings__form-container">
          <Form layout="vertical">
            <div className="builders__form-label">{this.props.formTitle}</div>
            <div className="builders__padded-div">
              {this.props.formDescription}
            </div>
            {this.props.formItems.map((item) => (
              <Fragment key={item.id}>
                {item.sectionTitle && (
                  <div className="builders__form-label">
                    {item.sectionTitle}
                  </div>
                )}
                {item.sectionDescription && (
                  <div className="builders__padded-div">
                    {item.sectionDescription}
                  </div>
                )}
                <Form.Item
                  className="settings__form-item"
                  label={
                    <span className="builders__form-label">{item.label}</span>
                  }
                  extra={item.extra}
                >
                  {item.description && (
                    <div className="builders__padded-div">
                      {item.description}
                    </div>
                  )}
                  {item.fields.map((field) =>
                    getFieldDecorator(`${field.id}`, {
                      initialValue: field.initialValue,
                      valuePropName: field.valuePropName || 'value',
                      rules: field.validationRules,
                    })(getFormControl(field.control))
                  )}
                  {item.supplementalControl && item.supplementalControl}
                </Form.Item>
              </Fragment>
            ))}
          </Form>
          <div className="builders__button-container">
            <TwoButtonContainer
              primaryButtonText="Save"
              primaryButtonAction={this.props.onSave}
              secondaryButtonText="Cancel"
              secondaryButtonAction={this.props.onCancel}
            />
            {this.props.extraFormButtons}
          </div>
          <div className="builders__fixed-div">
            {this.props.message && (
              <div>
                {this.props.messageType === 'SUCCESS' ? (
                  <i className="fa fa-check-circle assettable__status-green" />
                ) : (
                  <i className="fa fa-exclamation-circle assettable__status-red" />
                )}
                &nbsp; {this.props.message}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
WrappedForm.propTypes = {
  extraFormButtons: PropTypes.object,
  form: PropTypes.object.isRequired,
  formDescription: PropTypes.string,
  formItems: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      extra: PropTypes.any,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          control: PropTypes.object.isRequired,
          id: PropTypes.string.isRequired,
          initialValue: PropTypes.any,
          validationRules: PropTypes.array,
          valuePropName: PropTypes.string,
        })
      ).isRequired,
      label: PropTypes.string.isRequired,
      sectionDescription: PropTypes.string,
      sectionTitle: PropTypes.string,
      supplementalControl: PropTypes.object,
    })
  ).isRequired,
  formTitle: PropTypes.string,
  message: PropTypes.string,
  messageType: PropTypes.oneOf(['', 'ERROR', 'SUCCESS']),
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}
const SettingsForm = Form.create({
  onFieldsChange: (props) => props.onFieldsChange(),
})(WrappedForm)

export default SettingsForm
