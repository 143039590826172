import PropTypes from 'prop-types'
import React from 'react'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import * as actions from '../dux/actions/appActions'
import * as folderActions from '../dux/actions/folder'
import * as userActions from '../dux/actions/user'
import { DEFAULT_SIDEBAR_WIDTH } from '../dux/constants/app'
import BamAppSyncClient from '../dux/modules/BamAppSyncClient'
import { debounce } from '../dux/modules/utils'
import MaintenanceView from './common/MaintenanceView'
import PrivateApp from './PrivateApp'
import PublicApp from './PublicApp'
import SplashScreen from './SplashScreen'

import './styles/AntOverrides.css'
import './styles/CoBrand.css'
class App extends React.Component {
  componentDidMount() {
    if (this.props.initializing) {
      let loc = this.props.location.pathname.split('/')[1]
      if (!loc || loc === 'login') loc = 'library'
      this.props.actions.setMenuItem(loc)
      const deeplink = `${this.props.location.pathname}${this.props.location.hash}`
      //try to bootstrap login from local storage
      this.props.actions.initialize({
        user: this.props.email,
        path: deeplink,
        config: this.props.config,
      })
    } else if (this.props.loggedIn) {
      this.configureAppSyncClient()
    }
    this.updateDimensions()
    window.addEventListener('resize', debounce(this.updateDimensions, 150))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', debounce(this.updateDimensions, 150))
  }

  // If the component switches from not logged in -> logged in,
  // then we initialize the appsync client
  componentDidUpdate(prevProps) {
    if (!prevProps.loggedIn && this.props.loggedIn) {
      this.configureAppSyncClient()
    }
  }
  // Set window dimensions in Redux
  updateDimensions = () => {
    const size = {
      width: window.innerWidth,
      height: window.innerHeight,
    }
    this.props.actions.setWindowSize(size)
  }

  // Helper to allow the app sync client to get current token
  getToken = () => this.props.token

  // This creates the BamAppSyncClient.  It is called either in componentDidMount or
  // componentDidUpdate then queries the current maintenance state
  configureAppSyncClient = async () => {
    const { config, actions } = this.props
    this.client = new BamAppSyncClient(
      config,
      actions.setMaintenanceMode,
      userActions.sessionTimeout
    )
    const maintenanceData = await this.client.queryMaintenance()
    actions.setMaintenanceMode(maintenanceData)
  }

  render() {
    if (this.props.initializing) {
      return <SplashScreen config={this.props.config} />
    } else {
      return this.props.loggedIn ? (
        <Switch>
          <Route
            path="/maintenance"
            component={() => (
              <MaintenanceView maintenance={this.props.maintenance} />
            )}
          />
          <Route
            path="*"
            render={() => (
              <PrivateApp
                superuser={this.props.level === 'Superuser'}
                startupRoute={this.props.startupRoute}
                allFolders={this.props.allFolders}
                folderActions={this.props.folderActions}
                actions={this.props.actions}
                pending={this.props.pending}
                width={this.props.sidebarWidth}
                sidebarCollapsed={this.props.sidebarCollapsed}
                onSidebarToggle={this.props.actions.toggleSidebar}
                onLoad={this.props.actions.setMenuItem}
                groups={this.props.groups}
                email={this.props.email}
                token={this.props.token}
                config={this.props.config}
                maintenance={this.props.maintenance}
                client={this.client}
                versionMismatch={this.props.versionMismatch}
                modules={this.props.modules}
              />
            )}
          />
        </Switch>
      ) : (
        <PublicApp />
      )
    }
  }
}

App.propTypes = {
  email: PropTypes.string,
  loggedIn: PropTypes.bool,
  level: PropTypes.string,
  startupRoute: PropTypes.string,
  allFolders: PropTypes.object,
  initializing: PropTypes.bool,
  pending: PropTypes.bool,
  windowWidth: PropTypes.number,
  sidebarWidth: PropTypes.number,
  sidebarCollapsed: PropTypes.bool,
  groups: PropTypes.object,
  actions: PropTypes.object,
  folderActions: PropTypes.object,
  userActions: PropTypes.object,
  location: PropTypes.object,
  config: PropTypes.object,
  width: PropTypes.number,
  token: PropTypes.string,
  maintenance: PropTypes.object,
  versionMismatch: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  loggedIn: state.user.loggedIn,
  email: state.user.email,
  token: state.user.token,
  maintenance: state.appState.maintenance,
  level: state.user.level,
  startupRoute: state.appState.startupRoute,
  allFolders: state.folders.byId,
  initializing: state.appState.initializing,
  pending: state.appState.asyncPending && state.appState.showSpinner,
  windowWidth: state.appState.windowWidth,
  sidebarCollapsed: state.appState.sidebarCollapsed,
  versionMismatch: state.appState.versionMismatch,
  groups: state.permissions.groups,
  sidebarWidth:
    state.appState.sidebarWidth >= 0
      ? state.appState.sidebarWidth
      : DEFAULT_SIDEBAR_WIDTH,
  modules: state.settings.modules,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  folderActions: bindActionCreators(folderActions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
})

export default withRouter(
  DragDropContext(HTML5Backend)(
    connect(mapStateToProps, mapDispatchToProps)(App)
  )
)
