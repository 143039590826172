export const MENU_ITEM_MAX_CHARS = 22
export const MENU_MAX_CUSTOM_ASSETS = 3
export const MENU_MAX_CUSTOM_URLS = 3
export const DEFAULT_DASHBOARD_LABEL = 'Dashboard'
export const DEFAULT_LIBRARY_LABEL = 'Library'
export const DEFAULT_PROFILE_LABEL = 'My Profile'
export const DEFAULT_CUSTOMER_PROFILE_LABEL = 'Customer Profiles'
export const DEFAULT_COMPARISON_LABEL = 'Comparison'
export const DEFAULT_RESOURCES_LABEL = 'Resources'
export const DEFAULT_CALCULATORS_LABEL = 'Calculators'
export const DEFAULT_NEWS_LABEL = 'News'
export const DEFAULT_NAVIGATION_LABEL = 'Visual Navigation'
export const DEFAULT_UGC_LABEL = 'Upload'
