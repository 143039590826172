import { Button, Checkbox, Modal } from 'antd'
import update from 'immutability-helper'
import React from 'react'
import { BUILDER_TYPES, NEWS__BUILDER_TYPE } from '../../dux/constants/builders'
import { NEWS_RESOURCE_FOLDER } from '../../dux/constants/folder'
import {
  assetName,
  buildPath,
  getEndpointOfNode,
  validatePrivilege,
} from '../../dux/modules/utils'
import TreeNode from '../sidebar/TreeNode'
import '../styles/Modal.css'
import PublishAssetNotificationForm from './PublishAssetNotificationForm'

class PublishAssetModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeFolder: null,
      openFolderKeys: this.props.appState.openFolderKeys,
      errors: [],
      assets: this.props.modalProps.assets.map((asset) => {
        let a = { ...this.props.assets.byId[asset] }
        a.checked = true
        return a
      }),
      publishAndApprove: false,
      publish: false,
    }
  }

  changeCheckbox = (index) => {
    let newAsset = this.state.assets[index]
    newAsset.checked = !newAsset.checked
    this.setState({
      assets: update(this.state.assets, { [index]: { $set: newAsset } }),
    })
  }

  allowPublish = (folderId) => {
    return validatePrivilege(
      'publishasset',
      this.props.permissions.privileges,
      this.props.user.email,
      this.props.permissions.groups,
      folderId,
      this.props.folders.byId
    )
  }

  allowPublishAndApprove = (folderId) => {
    const approve = validatePrivilege(
      'approveasset',
      this.props.permissions.privileges,
      this.props.user.email,
      this.props.permissions.groups,
      folderId,
      this.props.folders.byId
    )

    return this.allowPublish(folderId) && approve
  }

  validate = () => {
    let errors = []
    let alreadyExists = 0
    // Generate error msg if asset is already published to (or Pending in) the selected folder
    this.state.assets.forEach((asset) => {
      if (
        asset.checked &&
        this.props.endpoints.folderAssetStatus[this.state.activeFolder] &&
        this.props.endpoints.folderAssetStatus[this.state.activeFolder][
          asset.id
        ] &&
        this.props.endpoints.folderAssetStatus[this.state.activeFolder][
          asset.id
        ].status !== 'REJECTED' &&
        this.props.endpoints.folderAssetStatus[this.state.activeFolder][
          asset.id
        ].status !== 'UNPUBLISHED'
      )
        alreadyExists++
    })
    if (alreadyExists)
      errors.push(
        'Some assets cannot be published to this folder.  Review the list above.'
      )

    // Generate error msg if endpoint folder not selected (or no folder selected)
    if (!this.state.activeFolder)
      errors.push(
        'You must select a folder within an endpoint that allows publishing to complete this ' +
          'request'
      )
    else {
      const ep = getEndpointOfNode(
        this.props.folders.byId[this.state.activeFolder],
        this.props.folders.byId,
        this.props.endpoints.endpoints
      )
      if (!ep.allowPublishing)
        errors.push(
          'You must select a folder within an endpoint that allows publishing to complete this ' +
            'request'
        )
    }

    if (this.state.assets.filter((a) => a.checked).length === 0)
      errors.push('No assets selected')

    if (errors.length) {
      this.setState({ errors })
      return false
    }

    return true
  }

  save = () => {
    if (this.validate()) {
      this.notificationForm.validateFields((err) => {
        if (!err) {
          this.props.endpointActions.publishAsset(
            this.state.assets.filter((a) => a.checked).map((a) => a.id),
            this.state.activeFolder,
            {
              pushNotification: this.notificationForm.getFieldValue('enabled'),
              pushNotificationMessage:
                this.notificationForm.getFieldValue('message'),
            }
          )
        }
      })
    }
  }

  saveAndApprove = () => {
    if (this.validate()) {
      this.notificationForm.validateFields((err) => {
        if (!err) {
          this.props.endpointActions.publishAndApproveAsset(
            this.state.assets.filter((a) => a.checked).map((a) => a.id),
            this.state.activeFolder,
            {
              pushNotification: this.notificationForm.getFieldValue('enabled'),
              pushNotificationMessage:
                this.notificationForm.getFieldValue('message'),
            }
          )
        }
      })
    }
  }

  nodeIsActive = (id) => this.state.activeFolder === id

  nodeIsDraggable = () => {
    return false
  }
  nodeIsOpen = (id) => this.state.openFolderKeys.indexOf(id) > -1

  onNodeClick = (id) => {
    this.setActiveFolder(id)
  }

  toggleFolder = (id) => {
    if (this.state.openFolderKeys.indexOf(id) < 0) {
      this.setState((prevState) => ({
        openFolderKeys: prevState.openFolderKeys.concat([id]),
      }))
    } else {
      this.setState((prevState) => ({
        openFolderKeys: prevState.openFolderKeys.filter((key) => key !== id),
      }))
    }
  }

  removeOpenFolderKey = (id) => {
    this.setState((prevState) => ({
      openFolderKeys: prevState.openFolderKeys.filter((key) => key !== id),
    }))
  }

  nodeLink = () => this.props.location.pathname

  nodeIsDisabled = (id) => {
    const { user, folders, endpoints, permissions } = this.props
    const { assets } = this.state

    // NEW BUILDER type assets are the only type that we allow publishing to the
    // NEWS RESOURCE FOLDER.
    if (
      buildPath(id, folders.byId).indexOf(NEWS_RESOURCE_FOLDER) > -1 &&
      assets.some((asset) => asset.asset_type !== NEWS__BUILDER_TYPE)
    ) {
      return true
    }

    // BUILDER ASSETS cannot be published to web endpoints.
    if (
      getEndpointOfNode(folders.byId[id], folders.byId, endpoints.endpoints)
        .type === 'web' &&
      assets.some((asset) => BUILDER_TYPES.includes(asset.asset_type))
    ) {
      return true
    }

    return !validatePrivilege(
      'publishasset',
      permissions.privileges,
      user.email,
      permissions.groups,
      id,
      folders.byId
    )
  }

  saveNotificationFormRef = (ref) =>
    (this.notificationForm = ref && ref.props && ref.props.form)

  setActiveFolder = (id) => {
    if (id !== this.state.activeFolder)
      this.setState({
        activeFolder: id,
        publishAndApprove: this.allowPublishAndApprove(id),
        publish: this.allowPublish(id),
        errors: [],
      })
  }

  // Not needed for publish asset modal
  allowPublishing = () => false

  // Not needed for PublishAssetModal
  getBadgeCount = () => 0

  render() {
    return (
      <Modal
        title="Publish Asset"
        okText="PUBLISH"
        onOk={this.save}
        cancelText="Cancel"
        footer={[
          <Button key="cancel" onClick={this.props.modalActions.closeModal}>
            Cancel
          </Button>,
          <Button
            key="publish"
            type="primary"
            onClick={this.save}
            disabled={!this.state.publish}
            loading={this.props.appState.asyncPending}
          >
            PUBLISH
          </Button>,
          <Button
            key="approve"
            type="primary"
            onClick={this.saveAndApprove}
            disabled={!this.state.publishAndApprove}
            loading={this.props.appState.asyncPending}
          >
            PUBLISH and APPROVE
          </Button>,
        ]}
        onCancel={this.props.modalActions.closeModal}
        visible={this.props.modal.modalOpen}
        confirmLoading={this.props.appState.asyncPending}
        maskClosable={false}
      >
        <div>
          <div style={{ padding: '5px 0' }}>
            {this.state.assets.filter((a) => a.checked).length} Assets selected
            for publishing:
          </div>
          <div className="publish__list">
            {this.state.assets.map((asset, index) => {
              return (
                <div key={asset.id} className="publish__list-item">
                  <span>
                    <Checkbox
                      checked={asset.checked}
                      onChange={() => this.changeCheckbox(index)}
                    />{' '}
                    {assetName(asset)}: &nbsp;
                  </span>
                  {this.props.endpoints.folderAssetStatus[
                    this.state.activeFolder
                  ] &&
                  this.props.endpoints.folderAssetStatus[
                    this.state.activeFolder
                  ][asset.id] &&
                  this.props.endpoints.folderAssetStatus[
                    this.state.activeFolder
                  ][asset.id].status !== 'REJECTED' &&
                  this.props.endpoints.folderAssetStatus[
                    this.state.activeFolder
                  ][asset.id].status !== 'UNPUBLISHED' ? (
                    <span className="publish__error">
                      Already exists in folder
                    </span>
                  ) : this.state.activeFolder ? (
                    <span className="publish__valid">Ready for publishing</span>
                  ) : (
                    ''
                  )}
                </div>
              )
            })}
          </div>
        </div>
        <div className="modal__text">Select a destination:</div>
        <div className="modal-tree-container">
          {this.props.endpoints.endpoints
            .filter((endpoint) => endpoint.id !== 'LIB')
            .map((endpoint) => {
              return endpoint.rootFolders.map((root) => {
                return (
                  <TreeNode
                    key={root}
                    {...this.props}
                    activeFolder={this.state.activeFolder}
                    openFolderKeys={this.state.openFolderKeys}
                    removeOpenFolderKey={this.removeOpenFolderKey}
                    node={this.props.folders.byId[root]}
                    nodeLink={this.nodeLink}
                    isFolder
                    isModal={true}
                    nodeIsActive={this.nodeIsActive}
                    nodeIsOpen={this.nodeIsOpen}
                    nodeIsDraggable={this.nodeIsDraggable}
                    rightClickDisabled={true}
                    onToggle={this.toggleFolder}
                    onNodeClick={this.onNodeClick}
                    nodeIsDisabled={this.nodeIsDisabled}
                    endpointId={endpoint.id}
                    setActiveFolder={this.setActiveFolder}
                    allowPublishing={this.allowPublishing}
                    badges={[]}
                    getBadgeCount={this.getBadgeCount}
                  />
                )
              })
            })}
        </div>
        <div>
          {this.state.errors.map((error, index) => (
            <div key={index} className="library__publish-error">
              {error}
            </div>
          ))}
        </div>
        <div style={{ paddingTop: 10 }}>
          <PublishAssetNotificationForm
            selectedAssets={this.state.assets}
            selectedFolder={this.state.activeFolder}
            wrappedComponentRef={this.saveNotificationFormRef}
          />
        </div>
      </Modal>
    )
  }
}

export default PublishAssetModal
