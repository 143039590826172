import update from 'immutability-helper'
import * as appActions from '../actions/appActions'
import * as assetActions from '../actions/asset'
import * as actions from '../actions/endpoint'

const INITIAL_STATE = {
  allViews: {
    inbox: {
      action: '',
      description: 'Notifications',
      icon: 'inbox',
      id: 'inbox',
      optional: false,
      route: '/library/notifications',
    },
    queue: {
      id: 'queue',
      icon: 'share-alt',
      optional: true,
      description: 'Share Queue',
      action: '',
      route: '/share',
    },
    profile: {
      id: 'profile',
      icon: 'user',
      optional: true,
      description: 'Profile',
      action: '',
      route: '/profile',
    },
    superuser: {
      id: 'superuser',
      icon: 'tool',
      optional: true,
      description: 'Utilities',
      action: '',
      route: '/superuser',
    },
  },
  endpoints: [
    {
      icon: 'home',
      id: 'HOME',
      name: 'My Work',
      description: 'This is the users personal endpoint',
      views: ['inbox'],
    },
    {
      id: 'MST',
      name: 'Mobile Sales Tool',
      description:
        'Assets in this endpoint are available to the Mobile Sales Tool Platform',
      rootFolder: 'f669379071c82f9ba1b178e63cf05535',
      client: '',
    },
  ],
  folderAssetStatus: {},
}

const endpointReducer = (state = INITIAL_STATE, action) => {
  let newState
  switch (action.type) {
    case actions.ADD_ENDPOINT_SUCCESS:
      return update(state, {
        endpoints: {
          $push: [action.endpoint],
        },
      })

    /*
     *  This reducer is not identical to its cloud reducer equivalent in
     *  bam-api-services. The cloud reducer preserves the deleted endpoint
     *  by toggling a deleted flag to false, while this reducer simply filters
     *  out the deleted endpoint. The resultant state from this reducer will
     *  match the state returned from the DAM, since the DAM state api call
     *  filters out any endpoints with the deleted=true flag.
     */
    case actions.DELETE_ENDPOINT_SUCCESS:
      return update(state, {
        endpoints: (endpoints) =>
          endpoints.filter((endpoint) => endpoint.id !== action.endpointId),
      })
    /*
     *  Load the endpoint state from cloud; if user home endpoint is provided
     *  in action, replace default home endpoint
     */
    case actions.LOAD_ENDPOINT_STATE:
      newState = action.payload
      // override "allViews"
      newState.allViews = INITIAL_STATE.allViews
      // override some props of the home endpoint
      newState.endpoints[0].actionIcon = INITIAL_STATE.endpoints[0].actionIcon
      newState.endpoints[0].views = INITIAL_STATE.endpoints[0].views
      // add a superuser navigation link if appropriate
      if (action.userLevel === 'Superuser')
        newState.endpoints[0].views.push('superuser')

      return newState

    case actions.PUBLISH_ASSET_SUCCESS:
      newState = state
      action.assets.forEach((id) => {
        newState = Object.assign({}, newState, {
          folderAssetStatus: Object.assign({}, newState.folderAssetStatus, {
            [action.parent]: Object.assign(
              {},
              newState.folderAssetStatus[action.parent],
              {
                [id]: {
                  status: actions.PENDING_APPROVAL,
                  requestedBy: action.user,
                  requestedDate: action.timestamp,
                },
              }
            ),
          }),
        })
      })
      return newState

    case actions.RENAME_ENDPOINT_SUCCESS:
      return update(state, {
        endpoints: (endpoints) =>
          endpoints.map((endpoint) => {
            if (endpoint.id === action.id) {
              endpoint.name = action.newName
            }

            return endpoint
          }),
      })

    case actions.REVIEW_ASSET_SUCCESS: {
      newState = state
      action.assets.forEach((asset) => {
        newState = Object.assign({}, newState, {
          folderAssetStatus: Object.assign({}, newState.folderAssetStatus, {
            [action.parent]: Object.assign(
              {},
              newState.folderAssetStatus[action.parent],
              {
                [asset]: Object.assign(
                  {},
                  newState.folderAssetStatus[action.parent][asset],
                  {
                    status: action.statusType,
                    publishReviewedBy: action.user,
                    publishReviewedDate: action.timestamp,
                  }
                ),
              }
            ),
          }),
        })
      })
      return newState
    }

    case actions.PUBLISH_AND_APPROVE_ASSET_SUCCESS: {
      newState = state
      action.assets.forEach((asset) => {
        newState = Object.assign({}, newState, {
          folderAssetStatus: Object.assign({}, newState.folderAssetStatus, {
            [action.parent]: Object.assign(
              {},
              newState.folderAssetStatus[action.parent],
              {
                [asset]: {
                  status: action.statusType,
                  publishReviewedBy: action.user,
                  publishReviewedDate: action.timestamp,
                },
              }
            ),
          }),
        })
      })
      return newState
    }

    case actions.UNPUBLISH_ASSET_SUCCESS: {
      newState = state
      action.assets.forEach((id) => {
        newState = Object.assign({}, newState, {
          folderAssetStatus: Object.assign({}, newState.folderAssetStatus, {
            [action.parent]: Object.assign(
              {},
              newState.folderAssetStatus[action.parent],
              {
                [id]: Object.assign(
                  {},
                  newState.folderAssetStatus[action.parent][id],
                  {
                    status: action.statusType,
                    unpublishRequestedBy: action.user,
                    unpublishRequestedDate: action.timestamp,
                  }
                ),
              }
            ),
          }),
        })
      })
      return newState
    }

    case actions.REVIEW_UNPUBLISH_ASSET_SUCCESS: {
      newState = state
      action.assets.forEach((asset) => {
        newState = Object.assign({}, newState, {
          folderAssetStatus: Object.assign({}, newState.folderAssetStatus, {
            [action.parent]: Object.assign(
              {},
              newState.folderAssetStatus[action.parent],
              {
                [asset]: Object.assign(
                  {},
                  newState.folderAssetStatus[action.parent][asset],
                  {
                    status: action.statusType,
                    unpublishReviewedBy: action.user,
                    unpublishReviewedDate: action.timestamp,
                  }
                ),
              }
            ),
          }),
        })
      })
      return newState
    }

    case actions.UNPUBLISH_AND_APPROVE_ASSET_SUCCESS: {
      newState = state
      action.assets.forEach((asset) => {
        newState = Object.assign({}, newState, {
          folderAssetStatus: Object.assign({}, newState.folderAssetStatus, {
            [action.parent]: Object.assign(
              {},
              newState.folderAssetStatus[action.parent],
              {
                [asset]: Object.assign(
                  {},
                  newState.folderAssetStatus[action.parent][asset],
                  {
                    status: action.statusType,
                    unpublishReviewedBy: action.user,
                    unpublishReviewedDate: action.timestamp,
                  }
                ),
              }
            ),
          }),
        })
      })
      return newState
    }

    case appActions.REFRESH_STATE: {
      return {
        ...state,
        folderAssetStatus: action.endpointState.folderAssetStatus,
      }
    }

    case assetActions.GET_ASSET_SUCCESS: {
      return {
        ...state,
        folderAssetStatus: action.folderAssetStatus,
      }
    }

    default:
      return state
  }
}

export default endpointReducer
