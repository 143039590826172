import { Tooltip } from 'antd'
import React from 'react'
import CopyTextComponent from '../BAM_UI/CopyTextComponent'

class UserChip extends React.Component {
  state = {
    copied: false,
  }

  copy = () => this.setState({ copied: true })

  clearCopy = (visible) => {
    if (!visible) this.setState({ copied: false })
  }

  render() {
    return this.props.user ? (
      <Tooltip
        placement="right"
        overlayClassName="assetdetail__userchip-tooltip"
        onVisibleChange={this.clearCopy}
        title={
          <CopyTextComponent
            id={this.props.user.id}
            textToCopy={this.props.user.email}
            showIcon={!this.state.copied}
            onCopyClick={this.copy}
          />
        }
      >
        <div className="assetdetail__userchip">
          {this.props.user.name || this.props.users.email}
        </div>
      </Tooltip>
    ) : null
  }
}

export default UserChip
