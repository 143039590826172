import { combineReducers } from 'redux'
import analyticsReducer from './analytics'
import appStateReducer from './appState'
import assetReducer from './asset'
import buildersReducer from './builders'
import configReducer from './config'
import endpointReducer from './endpoints'
import folderReducer from './folder'
import invitationReducer from './invitation'
import modalReducer from './modal'
import notificationReducer from './notifications'
import permissionsReducer from './permissions'
import searchReducer from './search'
import settingsReducer from './settings'
import userReducer from './user'

const indexReducer = combineReducers({
  analytics: analyticsReducer,
  appState: appStateReducer,
  assets: assetReducer,
  builders: buildersReducer,
  config: configReducer,
  endpoints: endpointReducer,
  folders: folderReducer,
  invitations: invitationReducer,
  modal: modalReducer,
  notifications: notificationReducer,
  permissions: permissionsReducer,
  search: searchReducer,
  settings: settingsReducer,
  user: userReducer,
})

export default indexReducer
