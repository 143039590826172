import { Button, Select } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import ReactCrop, { makeAspectCrop } from 'react-image-crop'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as assetActions from '../../dux/actions/asset'
import 'react-image-crop/dist/ReactCrop.css'
import '../styles/AssetCrop.css'

const Option = Select.Option

class AssetCrop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      crop: {},
      aspectRatio: 0,
      pixelCrop: {},
    }
    this.onCropChange = (crop) => {
      this.setState({ crop })
    }

    this.onComplete = (crop, pixelCrop) => {
      this.setState({ pixelCrop })
    }

    this.onImageLoaded = (image) => {
      const pixelCrop = this.props.assets.byId[this.props.match.params.id].crop
        ? this.props.assets.byId[this.props.match.params.id].crop
        : {
            x: 0,
            y: 0,
            width: Math.round(image.naturalWidth * (50 / 100)),
            height: Math.round(image.naturalHeight * (50 / 100)),
          }
      const crop = {
        x: Math.round((pixelCrop.x / image.naturalWidth) * 100),
        y: Math.round((pixelCrop.y / image.naturalHeight) * 100),
        width: Math.round((pixelCrop.width / image.naturalWidth) * 100),
        height: Math.round((pixelCrop.height / image.naturalHeight) * 100),
      }
      this.setState({
        crop,
        image,
        pixelCrop,
      })
    }

    this.handleChange = (value) => {
      if (parseFloat(value) === 0) {
        this.setState({
          aspectRatio: value,
          crop: {
            x: this.state.crop.x,
            y: this.state.crop.y,
            width: this.state.crop.width,
            height: this.state.crop.height,
          },
        })
      } else {
        this.setState({
          aspectRatio: value,
          crop: makeAspectCrop(
            {
              x: this.state.crop.x,
              y: this.state.crop.y,
              aspect: parseFloat(value),
              width: this.state.crop.width,
            },
            this.state.image.naturalWidth / this.state.image.naturalHeight
          ),
        })
      }
    }

    this.onSave = () => {
      this.props.assetActions.cropAsset(
        this.state.pixelCrop,
        this.props.assets.byId[this.props.match.params.id]
      )
      this.props.history.goBack()
    }
  }

  render() {
    return (
      <div className="cropcontainer">
        <div className="croptools">
          <span>
            <div className="croptools__label">
              <strong>Aspect Ratio:&nbsp;</strong>
            </div>
            <div className="croptools__select">
              <Select
                placeholder="select"
                onChange={this.handleChange}
                defaultValue={this.state.aspectRatio}
                style={{ width: 100 }}
              >
                <Option value={0}>Custom</Option>
                <Option value={19.5 / 9}>19.5:9</Option>
                <Option value={18 / 9}>18:9</Option>
                <Option value={16 / 9}>16:9</Option>
                <Option value={4 / 3}>4:3</Option>
                <Option value={1 / 1}>1:1</Option>
              </Select>
            </div>
          </span>
          <span>
            <Button className="croptools__button-save" onClick={this.onSave}>
              Save
            </Button>
            <Button onClick={() => this.props.history.goBack()}>Cancel</Button>
          </span>
        </div>
        <ReactCrop
          {...this.state}
          onChange={this.onCropChange}
          onComplete={this.onComplete}
          onImageLoaded={this.onImageLoaded}
          src={`${this.props.config.baseUrl}/${
            this.props.assets.byId[this.props.match.params.id].key
          }`}
          style={{ margin: '0 15px' }}
          keepSelection={true}
        />
      </div>
    )
  }
}

AssetCrop.propTypes = {
  routeParams: PropTypes.object,
  assets: PropTypes.object,
}

const mapStateToProps = (state) => ({
  assets: state.assets,
  config: state.config.appConfig,
})
const mapDispatchToProps = (dispatch) => ({
  assetActions: bindActionCreators(assetActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssetCrop)
