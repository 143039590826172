import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import * as permissionsActions from '../../../dux/actions/permissions'
import * as invitationActions from '../../../dux/actions/invitation'
import * as userActions from '../../../dux/actions/user'
import { connect } from 'react-redux'
import * as d3 from 'd3-dsv'

const styles = {
  form: { display: 'flex', flexDirection: 'column', color: 'black' },
  label: { fontWeight: '1000px', fontSize: '30px' },
  button: { color: 'black', width: 200 },
  disabled: { color: 'red' },
}

// | first | last | email | password | invite | groups (names, semicolon-delimited) |
// need one of: Invite or Groups, all others are required
class BatchUsers extends Component {
  // Upload Users

  defaultText = `This utility takes a csv list of users, with columns:
    first (required)
    last (required)
    email (required)
    password (required)
    invite (optional, will ignore groups if used)
    groups (semicolon-delimited list of group names)`

  constructor(props) {
    super(props)
    this.handleFileChange = ({ target: { files } }) => {
      const reader = new FileReader()
      reader.onload = ({ target: { result } }) => {
        const json = d3.csvParse(result)
        const validData = this.validate(json)
        if (validData) {
          this.setState({ data: validData })
        } else {
          console.log('no valid data')
          this.setState({ data: null })
        }
      }
      reader.readAsText(files[0])
    }
  }

  validate(json) {
    console.log({ json })
    // all we need is an array of strings
    try {
      if (!Array.isArray(json)) {
        console.error('data is not an array')
        throw 'data is not an array'
      }

      // json.forEach((row) => {
      //   console.error
      // })

      json.forEach((obj) => {
        if (!obj.name && (!obj.first || !obj.last)) {
          throw 'One or more objects is missing name and first or last'
        }
        if (!obj.email) throw 'One or more objects is missing email'
        //if (!obj.password) throw 'One or more objects is missing password'
        if (obj.invite) return
        else if (!obj.groups || obj.groups.trim().length === 0)
          throw 'One or more objects is missing groups'
      })
    } catch (error) {
      console.log(error)
      this.setState({
        output: `${error}\n\n${this.defaultText}`,
      })
      return null
    }

    const emails = Object.values(this.props.users).map((u) => u.email)
    const filtered = json.filter((u) => !emails.includes(u.email))

    console.log({ emails, filtered })

    return filtered
  }

  submitHandler = (e) => {
    e.preventDefault()
    this.props.userActions.importUsers(this.state.data)
  }

  render() {
    const isValid = this.state && this.state.data
    const output =
      (this.state && this.state.output) || !isValid ? this.defaultText : ''

    return (
      <>
        <form style={styles.form} onSubmit={this.submitHandler}>
          <input
            type="file"
            onChange={this.handleFileChange}
            name="users"
            id="users"
            accept=".csv"
          />
          <br />
          {isValid && (
            <button type="submit" style={styles.button}>
              Start
            </button>
          )}
          {output && (
            <div>
              <pre>{output}</pre>
            </div>
          )}
        </form>
      </>
    )
  }
}

// What will happen when we call these sagas in a loop like this?

const mapDispatchToProps = (dispatch) => ({
  permissionsActions: bindActionCreators(permissionsActions, dispatch),
  invitationActions: bindActionCreators(invitationActions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
})
const mapStateToProps = (state) => ({
  user: state.user,
  users: state.permissions.users,
})
export default connect(mapStateToProps, mapDispatchToProps)(BatchUsers)
