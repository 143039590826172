import { get, orderBy, sortBy, union } from 'lodash'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { getUserById } from '../../../dux/modules/utils'
import { dateRangeMenuItems } from './menuConstants'
import { assetName } from '../../../dux/modules/utils'

/*
 * Returns the sum of an array of integers.
 * @param    numArray    <[int]>      An array of integers to sum
 */
const sumElements = (numArray) => {
  return numArray.reduce((total, element) => total + parseInt(element, 10), 0)
}

/*
 * Checks if a date is inside a given date range.
 * @param    date        <{moment}>              A moment date to test
 * @param    dateRange   <[{moment}, {moment}]>  An array describing the start and end
 *                                               date of the date range. (moment objects)
 */
const dateIsInRange = (date, dateRange) => {
  if (date.isSame(dateRange)) {
    return true
  }
  return date.isBetween(dateRange[0], dateRange[1], null, '[]')
}

/*
 * A helper function used to convert an "activeDurationKey" and
 * "customDateRange" combination into a valid array of two moment
 * objects. The activeDurationKey will determine the key in the
 * dateRangeMenuItems collection to look up so the function can compute
 * a date range.
 * If the active duration key is set to custom, then the function will use
 * the customDateRange for the return value.
 *
 * @param    activeDurationKey   <string>            The key of the selected activeDuration to
 *                                                   lookup the correct dateRangeMenuItem.
 * @param    customDateRange     <[string, string]>  The date strings to use for the date range
 *                                                   calculation.
 */
export const generateDateRange = (
  activeDurationKey,
  customDateRange,
  startDate = '2019-10-01'
) => {
  const activeDurationObj = dateRangeMenuItems.find(
    (menuItem) => menuItem.key === activeDurationKey
  )
  const activeDuration = activeDurationObj && activeDurationObj.duration
  const activeDurationUnit = activeDurationObj && activeDurationObj.unit

  const hardStartDate = moment(startDate)
  const customStartDate = moment(customDateRange[0])
  const durationStartDate = moment().subtract(
    activeDuration,
    activeDurationUnit
  )

  const momentStart =
    activeDurationKey === 'custom' && customStartDate.isAfter(hardStartDate)
      ? customStartDate
      : activeDurationKey !== 'all' && durationStartDate.isAfter(hardStartDate)
      ? durationStartDate
      : hardStartDate

  const momentEnd =
    activeDurationKey === 'custom' ? moment(customDateRange[1]) : moment()

  return [momentStart.startOf('day'), momentEnd.endOf('day')]
}

/*
 * Massages the analytics api data (which comes from the redux store) so that
 * it can be displayed in the BAM Spark Charts.
 * @param    dataObj     <object>            The chart data. As formatted in the redux store.
 * @param    chartType   <string>            The type of spark chart where the data is to be used.
 * @param    bamPlatform <string>            Is "dam" or "mst"
 * @param    dateRange   <[string, string]>  The date range to filter the data for.
 */
export const formatDataForSparkChart = ({
  dataObj,
  chartType,
  bamPlatform,
  dateRange,
}) => {
  const dataByDate = get(dataObj, 'data', null)

  if (!dataByDate) return null

  let numList = []
  //let prevDateMoment = null
  for (let date in dataByDate) {
    const dateMoment = moment(date)
    // Date in Date Range Filter
    if (!dateIsInRange(dateMoment, dateRange)) continue

    // Concat
    switch (chartType) {
      case 'users':
        bamPlatform !== 'all'
          ? numList.push(get(dataByDate[date], `${bamPlatform}ActiveUsers`, 0))
          : numList.push(
              get(dataByDate[date], 'damActiveUsers', 0) +
                get(dataByDate[date], 'mstActiveUsers', 0)
            )
        break
      case 'assetsViewed':
        bamPlatform !== 'all'
          ? numList.push(get(dataByDate[date], `${bamPlatform}AssetsViewed`, 0))
          : numList.push(
              get(dataByDate[date], 'damAssetsViewed', 0) +
                get(dataByDate[date], 'mstAssetsViewed', 0)
            )
        break
      case 'assetsShared':
        bamPlatform !== 'all'
          ? numList.push(get(dataByDate[date], `${bamPlatform}AssetsShared`, 0))
          : numList.push(
              get(dataByDate[date], 'damAssetsShared', 0) +
                get(dataByDate[date], 'mstAssetsShared', 0)
            )
        break
      case 'calcsViewed':
        bamPlatform !== 'all'
          ? numList.push(get(dataByDate[date], `${bamPlatform}CalcsViewed`, 0))
          : numList.push(
              get(dataByDate[date], 'damCalcsViewed', 0) +
                get(dataByDate[date], 'mstCalcsViewed', 0)
            )
        break
      case 'calculations':
        bamPlatform !== 'all'
          ? numList.push(get(dataByDate[date], `${bamPlatform}Calculations`, 0))
          : numList.push(
              get(dataByDate[date], 'damCalculations', 0) +
                get(dataByDate[date], 'mstCalculations', 0)
            )
        break
      case 'totalEvents':
        bamPlatform !== 'all'
          ? numList.push(get(dataByDate[date], `${bamPlatform}TotalEvents`, 0))
          : numList.push(
              get(dataByDate[date], 'damTotalEvents', 0) +
                get(dataByDate[date], 'mstTotalEvents', 0)
            )
        break
      default:
        console.warn('Unrecognized chartType', chartType)
        break
    }
  }

  return {
    total: sumElements(numList),
    dataList: numList,
  }
}

/*
 * Massages the analytics api data (which comes from the redux store) so that
 * it can be displayed in the BAM Temporal Line Graph.
 * @param    dataObj     <object>            The graph data. As formatted in the redux store.
 * @param    chartType   <string>            The type of temporal line graph where the data is
 *                                              to be used.
 * @param    bamPlatform <string>            Is "dam" or "mst"
 * @param    dateRange   <[string, string]>  The date range to filter the data for.
 * @param    selectedUserFilter <string>     User ID to restrict dataset to
 */
export const formatDataForLineGraph = ({
  dataObj,
  chartType,
  bamPlatform,
  dateRange,
  selectedUserFilter,
}) => {
  if (chartType === 'users') {
    const dataByDate = get(dataObj, 'data', null)

    if (!dataByDate) {
      return {
        dataList: [],
        max: undefined,
      }
    }

    let numUsersCollection = []
    let maxNumUsers = 0
    for (let date in dataByDate) {
      const dateMoment = moment(date)
      // Date in Date Range Filter
      if (!dateIsInRange(dateMoment, dateRange)) continue

      let activeUsers = get(dataByDate[date], `${bamPlatform}ActiveUsers`, [])
      if (bamPlatform === 'all') {
        activeUsers = union(
          get(dataByDate[date], 'damActiveUsers', []),
          get(dataByDate[date], 'mstActiveUsers', [])
        )
      }

      if (selectedUserFilter) {
        activeUsers = activeUsers.filter((id) => id === selectedUserFilter)
      }

      const numUsers = activeUsers.length

      numUsersCollection.push({
        x: dateMoment.valueOf(),
        y: numUsers,
      })
      maxNumUsers = Math.max(numUsers, maxNumUsers)
    }

    return {
      dataList: numUsersCollection,
      max: maxNumUsers,
    }
  }
}

/**
 * Enumerate the set of dates between start and end (not inclusive)
 *
 * @param {moment.Moment} start the start date
 * @param {moment.Moment} end the end date
 * @returns {moment.Moment[]} an array of dates between the given start and end dates
 */
// inspired by https://stackoverflow.com/a/23796069
const enumerateDatesBetween = (start, end) => {
  const startDate = moment(start).startOf('day')
  const endDate = moment(end).startOf('day')
  const enumeratedDates = []

  while (startDate.add(1, 'day').diff(endDate) < 0) {
    enumeratedDates.push(startDate.clone())
  }

  return enumeratedDates
}

/**
 * @typedef {Object} AnalyticsPlatformData
 * @property {Object} dam - dated analytics event data object for DAM
 * @property {Object} mst - dated analytics event data object for MST
 *
 * @param {AnalyticsPlatformData} analyticsData
 */
function aggregatePlatformEvents(analyticsData) {
  if (!analyticsData) {
    throw new Error('analyticsData is required')
  }
  const { dam, mst } = analyticsData
  const aggregatedData = { ...(dam || {}) }
  if (mst) {
    Object.keys(mst).forEach((date) => {
      if (!aggregatedData[date]) {
        aggregatedData[date] = mst[date]
      } else {
        aggregatedData[date] = aggregatedData[date].concat(mst[date])
      }
    })
  }
  return aggregatedData
}

/*
 * Massages the analytics api data (which comes from the redux store) so that
 * it can be displayed in the BAM Bar Graph.
 * @param    dataObj       <object>            The graph data. As formatted in the redux store.
 * @param    bamPlatform   <string>            Is "dam" or "mst"
 * @param    dateRange     <[string, string]>  The date range to filter the data for.
 * @param    selectedAsset <string>            The selected asset id
 */
export const formatDataForBarGraph = ({
  dataObj,
  bamPlatform,
  dateRange,
  selectedAsset,
  selectedCalculator,
}) => {
  const dataByPlatform = get(dataObj, 'data', null)
  let dataByDate = get(dataByPlatform, `${bamPlatform}`, {})

  if (bamPlatform === 'all') {
    dataByDate = aggregatePlatformEvents(dataByPlatform)
  }

  if (Object.keys(dataByDate).length === 0) return null

  let numViewsCollection = []

  for (let date in dataByDate) {
    if (!dataByDate.hasOwnProperty(date)) continue

    const dateMoment = moment(date)

    if (
      !dateIsInRange(dateMoment, dateRange) ||
      dataByDate[date].length === 0
    ) {
      continue
    }

    numViewsCollection.push({
      x: dateMoment.format('YYYY-MM-DD'),
      y: dataByDate[date].filter(
        (event) =>
          (event.assetId || event.calcId) &&
          ((!selectedAsset && !selectedCalculator) ||
            (selectedAsset && selectedAsset === event.assetId) ||
            (selectedCalculator && selectedCalculator === event.calcId))
      ).length,
    })
  }
  // sort collection so that last event date is accurate
  numViewsCollection = sortBy(numViewsCollection, 'x')

  if (numViewsCollection.length > 0) {
    // transform the collection by filling in all dates between those w/data with zero-value
    // entries
    numViewsCollection = numViewsCollection.reduce((acc, item, idx, array) => {
      let prevDate
      const nextDate = moment(item.x).startOf('day')

      if (idx === 0) {
        // start from one day before the date range, to include the initial range in enumeration
        prevDate = moment(dateRange[0]).subtract(1, 'day').startOf('day')
      } else {
        prevDate = moment(array[idx - 1].x).startOf('day')
      }

      return acc.concat(
        enumerateDatesBetween(prevDate, nextDate).map((item) => ({
          x: item.format('YYYY-MM-DD'),
          y: 0,
        })),
        item
      )
    }, [])

    const lastEventDate = moment(
      numViewsCollection[numViewsCollection.length - 1].x
    ).startOf('day')
    const endDate = moment(dateRange[1]).add(1, 'day').startOf('day')

    numViewsCollection.push(
      ...enumerateDatesBetween(lastEventDate, endDate).map((item) => ({
        x: item.format('YYYY-MM-DD'),
        y: 0,
      }))
    )
  }

  return sortBy(numViewsCollection, 'x')
}

/*
 * Massages the analytics api data (which comes from the redux store) so that
 * it can be displayed in the BAM Analytics User Table.
 * @param    activeDate   <string>            The active date to select the data for.
 * @param    allUsers     <object>            The dictionary to use for user data to display.
 * @param    bamPlatform  <string>            Is "dam" or "mst"
 * @param    dataObj      <object>            The chart data. As formatted in the redux store.
 * @param    dateRange    <[string, string]>  The date range to filter the data for
 * @param    selectedUser <object>            The selected user filter
 */
export const formatDataForUserTable = ({
  allUsers,
  activeDate,
  bamPlatform,
  dataObj,
  dateRange,
  selectedUser,
  showInactive,
}) => {
  const data = get(dataObj, 'data', {})
  const collectedUsers = []

  if (!activeDate) {
    const userEvents = {}

    for (let date in data) {
      if (!data.hasOwnProperty(date)) continue

      const dateMoment = moment(date)

      if (!dateIsInRange(dateMoment, dateRange)) continue

      const dateData = data[dateMoment.format('YYYY-MM-DD')]
      let usersWithEvents = get(dateData, `${bamPlatform}ActiveUsers`, [])
      if (bamPlatform === 'all') {
        usersWithEvents = union(
          get(dateData, 'damActiveUsers', []),
          get(dateData, 'mstActiveUsers', [])
        )
      }
      usersWithEvents = usersWithEvents.filter(
        (userId) => !selectedUser || selectedUser.id === userId
      )
      usersWithEvents.forEach(
        (userId) => (userEvents[userId] = 1 + (userEvents[userId] || 0))
      )
    }

    Object.values(allUsers).forEach((user) => {
      if (!selectedUser || selectedUser.id === user.id) {
        if (showInactive || userEvents[user.id]) {
          collectedUsers.push({
            ...formatUserForDisplay(user),
            daysActive: userEvents[user.id] || 0,
            key: user.id,
          })
        }
      }
    })

    return orderBy(
      collectedUsers,
      ['daysActive', 'name', 'email'],
      ['desc', 'asc', 'asc']
    )
  } else {
    const dateStr = moment(activeDate).format('YYYY-MM-DD')
    const usersForDate =
      bamPlatform === 'all'
        ? union(
            get(data[dateStr], 'damActiveUsers', []),
            get(data[dateStr], 'mstActiveUsers', [])
          )
        : get(data[dateStr], `${bamPlatform}ActiveUsers`, [])

    usersForDate.forEach((userId) => {
      if (selectedUser && selectedUser.id !== userId) {
        return
      }

      const userData = getUserById(userId, allUsers)
      collectedUsers.push({
        key: userId,
        name: get(userData, 'name', 'unknown'),
        email: get(userData, 'email', 'unknown'),
      })
    })

    return sortBy(collectedUsers, ['name', 'email'])
  }
}

function formatUserForDisplay(user) {
  const style = {}

  if (!user || user.deleted) {
    style.color = 'lightgray'
    style.textDecoration = 'line-through'
  }

  return {
    email: <span style={style}>{get(user, 'email', 'unknown')}</span>,
    user: <span style={style}>{get(user, 'name', 'unknown')}</span>,
    userId: get(user, 'id', ''),
  }
}

export const gatherUserDataForExport = ({
  // gather all events as an array of table objects
  // primary data:
  dataObj,
  eventType, // "asset views" or "asset shares" or "user days active"
  // context data:
  allUsers, // Map of all users
  // filters:
  bamPlatform, // Is "dam" or "mst"
  selectedUser,
  dateRange, // The date range to filter the data for.
}) => {
  // gather events for specified platform(s)
  const eventsByDate = {}
  Object.keys(dataObj.data).forEach((date) => {
    const dateData = dataObj.data[date]
    const activeUsers =
      bamPlatform === 'dam'
        ? dateData['damActiveUsers']
        : bamPlatform === 'mst'
        ? dateData['mstActiveUsers']
        : union(dateData['damActiveUsers'], dateData['mstActiveUsers'])
    if (activeUsers && activeUsers.length > 0) eventsByDate[date] = activeUsers
  })

  // collect a set of all assets transformed to data rows
  const events = []
  for (let date in eventsByDate) {
    if (!eventsByDate.hasOwnProperty(date)) continue

    if (!dateIsInRange(moment(date), dateRange)) continue

    for (let userId of eventsByDate[date]) {
      // if a user is selected, skip over the other user events
      if (selectedUser && userId !== selectedUser.id) {
        continue
      }

      // assemble asset & user details and date
      const user = Object.values(allUsers).find((user) => user.id === userId)
      const event = {
        key: `${userId}-${date}`,
        name: user ? user.name : 'unknown',
        email: user ? user.email : 'unknown',
        date: date,
      }

      events.push(event)
    }
  }

  return {
    view: eventType,
    dateRange,
    bamPlatform,
    selectedUser,
    events,
  }
}

export const gatherAssetDataForExport = ({
  // gather all events as an array of table objects
  // primary data:
  dataObj,
  eventType, // "asset views" or "asset shares" or "user days active"
  // context data:
  allAssets, // Map of all assets in the system
  allUsers, // Map of all users
  // filters:
  bamPlatform, // Is "dam" or "mst"
  dateRange, // The date range to filter the data for.
  selectedAsset, // The selected asset id
}) => {
  // filter events by platform, dateRange, and selected asset

  // gather events for specified platform(s)
  let eventsByDate = dataObj.data[bamPlatform]
  if (bamPlatform === 'all') {
    eventsByDate = aggregatePlatformEvents(dataObj.data)
  }
  // collect a set of all assets transformed to data rows
  const events = []
  let selectedAssetTitle = undefined
  for (let date in eventsByDate) {
    if (!eventsByDate.hasOwnProperty(date)) continue

    if (!dateIsInRange(moment(date), dateRange)) continue

    for (let eventObj of eventsByDate[date]) {
      const { assetId, shareId, eventTime, userId } = eventObj
      if (!assetId || (selectedAsset && assetId !== selectedAsset)) continue

      // assemble asset & user details and date
      const asset = allAssets[assetId]
      const user = Object.values(allUsers).find((user) => user.id === userId)
      const event = {
        key: `${assetId}-${userId}-${eventTime}`,
        assetId,
        filename: asset ? asset.filename : 'unknown',
        title: asset ? asset.title || asset.filename : 'unknown',
        file_type: asset ? asset.file_type : 'unknown',
        name: user ? user.name : 'unknown',
        email: user ? user.email : 'unknown',
        shareId: shareId,
        date: moment(eventTime).format('YYYY-MM-DD'),
      }

      if (selectedAsset) selectedAssetTitle = asset.title || asset.filename

      events.push(event)
    }
  }

  return {
    view: eventType,
    dateRange,
    selectedAsset: selectedAssetTitle,
    bamPlatform,
    events,
  }
}

export const gatherCalculatorDataForExport = ({
  // gather all events as an array of table objects
  // primary data:
  dataObj,
  eventType,
  // context data:
  allCalculators,
  allUsers, // Map of all users
  // filters:
  bamPlatform, // Is "dam" or "mst"
  dateRange, // The date range to filter the data for.
  selectedCalculator,
}) => {
  // filter events by platform, dateRange, and selected asset
  console.log({
    dataObj,
    eventType,
    allCalculators,
    allUsers,
    bamPlatform,
    dateRange,
    selectedCalculator,
  })

  // gather events for specified platform(s)
  let eventsByDate = dataObj.data[bamPlatform]
  if (bamPlatform === 'all') {
    eventsByDate = aggregatePlatformEvents(dataObj.data)
  }

  // collect a set of all assets transformed to data rows
  const events = []
  let selectedCalculatorTitle = undefined
  for (let date in eventsByDate) {
    if (!eventsByDate.hasOwnProperty(date)) continue

    if (!dateIsInRange(moment(date), dateRange)) continue

    for (let eventObj of eventsByDate[date]) {
      const { calcId, eventTime, userId } = eventObj
      if (!calcId || (selectedCalculator && calcId !== selectedCalculator))
        continue

      // assemble asset & user details and date
      const calcData = allCalculators[calcId] || {
        id: calcId,
        title: calcId,
      }
      const user = Object.values(allUsers).find((user) => user.id === userId)
      const event = {
        key: `${calcId}-${userId}-${eventTime}`,
        calcId,
        title: calcData ? calcData.title || calcData.id : 'unknown',
        name: user ? user.name : 'unknown',
        email: user ? user.email : 'unknown',
        date: moment(eventTime).format('YYYY-MM-DD'),
      }

      if (selectedCalculator)
        selectedCalculatorTitle = calcData
          ? calcData.title || calcId
          : 'unknown'

      events.push(event)
    }
  }

  return {
    view: eventType,
    dateRange,
    selectedCalculator: selectedCalculatorTitle,
    bamPlatform,
    events,
  }
}

export const gatherCalculationsForExport = ({
  // gather all events as an array of table objects
  // primary data:
  dataObj,
  eventType,
  // context data:
  allCalculators,
  allUsers, // Map of all users
  // filters:
  bamPlatform, // Is "dam" or "mst"
  dateRange, // The date range to filter the data for.
  selectedUser,
  calculator,
}) => {
  // filter events by platform, dateRange, and selected asset

  // gather events for specified platform(s)
  let eventsByDate = dataObj.data[bamPlatform]
  if (bamPlatform === 'all') {
    eventsByDate = aggregatePlatformEvents(dataObj.data)
  }

  // collect a set of all assets transformed to data rows
  const events = []
  let calculatorTitle = undefined
  for (let date in eventsByDate) {
    if (!eventsByDate.hasOwnProperty(date)) continue

    if (!dateIsInRange(moment(date), dateRange)) continue

    for (let eventObj of eventsByDate[date]) {
      const { calcId, eventTime, userId } = eventObj
      if (!calcId || (calculator && calcId !== calculator)) continue

      // assemble asset & user details and date
      const calcData = allCalculators[calcId] || {
        id: calcId,
        title: calcId,
      }
      const user = Object.values(allUsers).find((user) => user.id === userId)
      const event = {
        date: moment(eventTime).format('YYYY-MM-DD'),
        user: user ? user.name : 'unknown',
        email: user ? user.email : 'unknown',
        ...eventObj,
      }

      if (calculator)
        calculatorTitle = calcData ? calcData.title || calcId : 'unknown'

      events.push(event)
    }
  }

  return {
    view: eventType,
    dateRange,
    calculatorTitle,
    bamPlatform,
    selectedUser,
    events,
  }
}

/*
 * Massages the analytics api data (which comes from the redux store) so that
 * it can be displayed in the BAM Analytics Asset Table or exported report
 */
export const formatDataForAssetTable = ({
  dataObj, //  The chart data. As formatted in the redux store.
  eventType, // "views" or "shares"
  bamPlatform, // Is "dam" or "mst"
  dateRange, // The date range to filter the data for.
  selectedAsset, // The selected asset id
  selectedAssetShareData,
  selectedDate, // The selected date to filter the detail view.
  showInactive, // option to include data with no associated events
  allAssets, // Map of all assets in the system
  allUsers,
}) => {
  const showAllAssets = !selectedAsset && !selectedDate && showInactive
  const dataByPlatform = get(dataObj, 'data', null)

  // collect data by date
  let dataByDate = get(dataByPlatform, `${bamPlatform}`, null)
  if (bamPlatform === 'all') {
    dataByDate = aggregatePlatformEvents(dataByPlatform)
  }

  // collect a set of all assets transformed to data rows
  const assetToTableObj = (asset) => ({
    assetId: asset.id,
    filename: asset.filename,
    file_type: asset.file_type,
    title: (
      <Link to={`/library/asset/${asset.id}`}>{assetName(asset, 2000)}</Link>
    ),
  })

  // to show all assets, initialize the collection with all assets in the system
  const collectedEvents = showAllAssets
    ? Object.entries(allAssets)
        .filter(([assetId]) => !!assetId)
        .reduce((acc, [assetId, asset]) => {
          acc[assetId] = assetToTableObj(asset)
          return acc
        }, {})
    : {}

  for (let date in dataByDate) {
    if (!dataByDate.hasOwnProperty(date)) continue

    const dateMoment = moment(date)

    if (!dateIsInRange(dateMoment, dateRange)) continue
    if (!showAllAssets && selectedDate && !dateMoment.isSame(selectedDate))
      continue

    for (let eventObj of dataByDate[date]) {
      const { assetId, shareId, eventTime, userId } = eventObj

      if (!assetId || (selectedAsset && assetId !== selectedAsset)) continue

      const eventSignature = {
        eventId: shareId || 1,
        eventTime,
        userId,
      }
      const previousEvents = get(
        collectedEvents[assetId],
        `${eventType}List`,
        []
      )

      // add the event details for this asset
      // initialize with empty values in case the asset doesn't exist in `allAssets` (e.g. it's
      // deleted)
      collectedEvents[assetId] = {
        filename: '',
        file_type: '',
        title: (
          <span style={{ color: 'lightgray', textDecoration: 'line-through' }}>
            deleted
          </span>
        ),
        ...collectedEvents[assetId],
        [`${eventType}List`]: previousEvents.concat(eventSignature),
      }

      // if collectedEvents is not pre-initialized, need to populate the asset properties
      if (!showAllAssets && allAssets[assetId]) {
        collectedEvents[assetId] = {
          ...collectedEvents[assetId],
          ...assetToTableObj(allAssets[assetId]),
        }
      }
    }
  }

  let keyedEvents = []

  if (selectedAsset) {
    const eventList = get(
      collectedEvents[selectedAsset],
      `${eventType}List`,
      []
    )

    if (eventType === 'views') {
      // for showing asset views by user, convert the events list into a user list
      keyedEvents = Object.values(
        eventList.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.userId]: {
              ...curr,
              ...acc[curr.userId],
              events: get(acc[curr.userId], 'events', []).concat(curr),
            },
          }),
          {}
        )
      ).map((user) => ({
        ...formatUserForDisplay(getUserById(user.userId, allUsers)),
        assetId: selectedAsset,
        key: `${eventType}ByUser_${user.userId}`,
        [eventType]: user.events.length,
      }))
    } else if (eventType === 'shares') {
      // for asset shares by user, convert the events list into a merged user-shares list
      keyedEvents = eventList.map((share) => ({
        ...(selectedAssetShareData.find(
          (data) => data.shareId === share.eventId
        ) || {}),
        email: formatUserForDisplay(getUserById(share.userId, allUsers)).email,
        key: `${eventType}ByUser_${share.userId}_${share.eventId}`,
        shareDate: share.eventTime,
      }))
    }
  } else {
    for (let [assetId, asset] of Object.entries(collectedEvents)) {
      keyedEvents.push({
        ...asset,
        key: `allAssets_${assetId}`,
        [eventType]: get(asset, `${eventType}List`, []).length,
      })
    }
  }

  return sortBy(keyedEvents, [eventType]).reverse()
}

/*
 * Massages the analytics api data (by way of the redux store) so that
 * it can be displayed in the BAM Analytics Calculators Table or exported report
 */
export const formatDataForCalculatorsTable = ({
  dataObj, //  The chart data. As formatted in the redux store.
  eventType, // "views" or "shares"
  bamPlatform, // Is "dam" or "mst"
  dateRange, // The date range to filter the data for.
  selectedCalculator, // The selected calculator id
  selectedDate, // The selected date to filter the detail view.
  showInactive, // option to include data with no associated events
  allCalculators, // Map of all calculators in the system
  allUsers,
}) => {
  const showAllCalculators =
    !selectedCalculator && !selectedDate && showInactive
  const dataByPlatform = get(dataObj, 'data', null)

  // collect data by date
  let dataByDate = get(dataByPlatform, `${bamPlatform}`, null)
  if (bamPlatform === 'all') {
    dataByDate = aggregatePlatformEvents(dataByPlatform)
  }

  // collect a set of all calcs transformed to data rows
  const calcToTableObj = (calcId, calcData) => ({
    title: calcData.title || calcId,
    calculatorId: calcId,
  })

  // to show all calcs, initialize the collection with all assets in the system
  const collectedEvents = { ...allCalculators }

  for (let date in dataByDate) {
    if (!dataByDate.hasOwnProperty(date)) continue

    const dateMoment = moment(date)

    if (!dateIsInRange(dateMoment, dateRange)) continue
    if (!showAllCalculators && selectedDate && !dateMoment.isSame(selectedDate))
      continue

    for (let eventObj of dataByDate[date]) {
      const { calcId, eventTime, userId } = eventObj

      if (
        !calcId ||
        !calcId.length ||
        (selectedCalculator && calcId !== selectedCalculator)
      )
        continue

      const eventSignature = {
        eventId: eventTime,
        eventTime,
        userId,
      }
      const previousEvents = get(
        collectedEvents[calcId],
        `${eventType}List`,
        []
      )

      // add the event details for this calculator
      // initialize with empty values in case the asset doesn't exist in `alCalculators`
      // (e.g. it's deleted)
      collectedEvents[calcId] = {
        title: calcId,
        ...collectedEvents[calcId],
        [`${eventType}List`]: previousEvents.concat(eventSignature),
      }

      // if collectedEvents is not pre-initialized, need to populate the calc properties
      if (!showAllCalculators && allCalculators[calcId]) {
        collectedEvents[calcId] = {
          ...collectedEvents[calcId],
          ...calcToTableObj(calcId, allCalculators[calcId]),
        }
      }
    }
  }

  let keyedEvents = []

  if (selectedCalculator) {
    const eventList = get(
      collectedEvents[selectedCalculator],
      `${eventType}List`,
      []
    )

    const title = collectedEvents[selectedCalculator].title

    // for showing asset views by user, convert the events list into a user list
    keyedEvents = Object.values(
      eventList.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.userId]: {
            ...curr,
            ...acc[curr.userId],
            events: get(acc[curr.userId], 'events', []).concat(curr),
          },
        }),
        {}
      )
    ).map((user) => {
      return {
        ...formatUserForDisplay(getUserById(user.userId, allUsers)),
        title,
        key: `${eventType}ByUser_${user.userId}`,
        [eventType]: user.events.length,
      }
    })
  } else {
    for (let [calcId, calcData] of Object.entries(collectedEvents)) {
      const calcEvents = get(calcData, `${eventType}List`, [])
      const eventCount = calcEvents.length
      if (eventCount) {
        keyedEvents.push({
          title: `${
            typeof calcData.title === 'string' ? calcData.title : calcId
          }`,
          key: `${calcId}`,
          [eventType]: eventCount,
        })
      }
    }
  }

  return sortBy(keyedEvents, [eventType]).reverse()
}
