export const GLOBAL_ACTIONS = [
  {
    action: 'edit',
    text: 'EDIT',
    icon: 'fa fa-pencil',
    tooltipText: 'Edit Asset',
    privilege: 'editmetadata',
  },
  {
    action: 'editbuilder',
    text: 'EDIT',
    icon: 'fa fa-pencil',
    tooltipText: 'Edit Asset',
    privilege: 'editbuilder',
  },
  {
    action: 'download',
    text: 'DOWNLOAD',
    icon: 'fa fa-download',
    tooltipText: 'Download',
    privilege: 'downloadasset',
  },
  {
    action: 'publish',
    text: 'PUBLISH',
    icon: 'fa fa-cloud-upload',
    tooltipText: 'Publish',
    privilege: 'publishasset',
  },
  {
    action: 'copyURL',
    text: 'PUBLIC URL',
    icon: 'fa fa-link',
    tooltipText: 'Public URL',
    privilege: 'downloadasset',
  },
  {
    action: 'crop',
    text: 'CROP',
    icon: 'fa fa-crop',
    tooltipText: 'Crop',
    privilege: 'cropasset',
  },
  {
    action: 'share',
    text: 'SHARE',
    icon: 'fa fa-share-alt',
    tooltipText: 'Share Asset',
    privilege: 'downloadasset',
  },
  {
    action: 'delete',
    text: 'DELETE',
    icon: 'fa fa-trash',
    tooltipText: 'Delete',
    privilege: 'deleteasset',
  },
  {
    action: 'move',
    text: 'MOVE TO LIBRARY',
    icon: 'fa fa-save',
    tooltipText: 'Move to Library',
    privilege: 'reviewugc',
  },
]
export const FOLDER_ACTIONS = [
  {
    action: 'clone',
    icon: 'fa fa-clone',
    tooltipText: 'Clone',
    privilege: 'cloneasset',
  },
  {
    action: 'delete',
    icon: 'fa fa-trash',
    tooltipText: 'Delete',
    privilege: 'deleteasset',
  },
  {
    action: 'review',
    icon: 'fa fa-eye',
    tooltipText: 'Review',
    privilege: 'approveasset',
  },
  {
    action: 'unpublish_review',
    icon: 'fa fa-eye',
    tooltipText: 'Unpublish Review',
    privilege: 'approveasset',
  },
  {
    action: 'unpublish',
    icon: 'fa fa-cloud-download',
    tooltipText: 'Unpublish',
    privilege: 'unpublishasset',
  },
]

export const STATUS_COLORS = {
  NEW: '#1890ff',
  REJECTED: '#f5222d',
  PENDING_APPROVAL: '#faad14',
  APPROVED: '#52c41a',
  PENDING_REMOVAL: '#AD14FA',
}
