export const EXTENSIONS = {
  png: {
    color: null,
    icon: 'fa-file-image-o',
    label: 'Image',
  },
  jpeg: {
    color: null,
    icon: 'fa-file-image-o',
    label: 'Image',
  },
  jpg: {
    color: null,
    icon: 'fa-file-image-o',
    label: 'Image',
  },
  gif: {
    color: null,
    icon: 'fa-file-image-o',
    label: 'Image',
  },
  svg: {
    color: null,
    icon: 'fa-file-image-o',
    label: 'Image',
  },
  heic: {
    color: null,
    icon: 'fa-file-image-o',
    label: 'Image',
  },
  xls: {
    color: '#135200',
    icon: 'fa-file-excel-o',
    label: 'Excel Document',
  },
  xlsx: {
    color: '#135200',
    icon: 'fa-file-excel-o',
    label: 'Excel Document',
  },
  doc: {
    color: '#061178',
    icon: 'fa-file-excel-o',
    label: 'Word Document',
  },
  docx: {
    color: '#061178',
    icon: 'fa-file-excel-o',
    label: 'Word Document',
  },
  ppt: {
    color: '#873800',
    icon: 'fa-file-powerpoint-o',
    label: 'Powerpoint Presentation',
  },
  pptx: {
    color: '#873800',
    icon: 'fa-file-powerpoint-o',
    label: 'Powerpoint Presentation',
  },
  pdf: {
    color: '#820014',
    icon: 'fa-file-pdf-o',
    label: 'Adobe PDF',
  },
  mp3: {
    color: '#780650',
    icon: 'fa-file-audio-o',
    label: 'Audio File',
  },
  m4a: {
    color: '#780650',
    icon: 'fa-file-audio-o',
    label: 'Audio File',
  },
  wav: {
    color: '#780650',
    icon: 'fa-file-audio-o',
    label: 'Audio File',
  },
  aac: {
    color: '#780650',
    icon: 'fa-file-audio-o',
    label: 'Audio File',
  },
  oga: {
    color: '#780650',
    icon: 'fa-file-audio-o',
    label: 'Audio File',
  },
  webm: {
    color: '#00474f',
    icon: 'fa-file-video-o',
    label: 'Video',
  },
  mpg: {
    color: '#00474f',
    icon: 'fa-file-video-o',
    label: 'Video',
  },
  mpeg: {
    color: '#00474f',
    icon: 'fa-file-video-o',
    label: 'Video',
  },
  ogg: {
    color: '#00474f',
    icon: 'fa-file-video-o',
    label: 'Video',
  },
  mp4: {
    color: '#00474f',
    icon: 'fa-file-video-o',
    label: 'Video',
  },
  mov: {
    color: '#00474f',
    icon: 'fa-file-video-o',
    label: 'Video',
  },
  calc: {
    color: '#00008b',
    icon: 'fa-calculator',
    label: 'Calculator',
  },
  360: {
    color: '#00008b',
    icon: 'fa-circle-o-notch',
    label: '360º',
  },
}
