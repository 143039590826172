import { Button } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActions from '../../dux/actions/appActions'
import * as modalActions from '../../dux/actions/modal'
import * as userActions from '../../dux/actions/user'
import '../styles/UserProfile.css'

class UserProfile extends React.Component {
  render() {
    return (
      <div className="profile">
        <div className="profile__header">
          <span className="profile__title">
            <i className="fa fa-user" /> &nbsp; User Profile
          </span>
          <span>
            <Button
              className="profile__actions"
              onClick={() =>
                this.props.modalActions.openModal('EditProfileModal', {
                  target: 'active',
                  type: 'userAsset',
                  multiple: false,
                  callback: this.props.appActions.uploadCompleteHandler,
                })
              }
            >
              Edit
            </Button>
            <Button
              className="profile__actions"
              type="primary"
              onClick={() =>
                this.props.userActions.logout(this.props.user.email)
              }
            >
              Sign Out
            </Button>
          </span>
        </div>
        <div className="profile__body">
          <div
            className="profile__avatar"
            style={{
              backgroundImage: `url(${this.props.user.profileUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          ></div>
          <div className="profile__attributes-name">Name</div>
          <div className="profile__attributes-value">
            {this.props.user.name}
          </div>
          <div className="profile__attributes-name">Email Address</div>
          <div className="profile__attributes-value">
            {this.props.user.email}
          </div>
        </div>
      </div>
    )
  }
}

UserProfile.propTypes = {
  showModal: PropTypes.bool,
  user: PropTypes.object,
  onGetFolderClick: PropTypes.func,
  onGetAssetClick: PropTypes.func,
  onGetUserDataClick: PropTypes.func,
  assets: PropTypes.object,
}

const mapStateToProps = (state) => ({
  user: state.user,
  appState: state.appState,
})

const mapDispatchToProps = (dispatch) => ({
  modalActions: bindActionCreators(modalActions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
  appActions: bindActionCreators(appActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
