import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import SessionTimeoutError from '../modules/SessionTimeoutError'
import { getRolesWithPrivilege } from '../modules/utils'
import * as apiActions from './api'
import * as appActions from './appActions'
import * as endpointActions from './endpoint'
import * as modalActions from './modal'
import * as userActions from './user'

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE'
export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS'
/*
 * Public facing action creator to retrieve user messages
 * @param 'email' - string user email address
 */
export const getNotifications = (email, showSpinner = true) => ({
  type: GET_NOTIFICATIONS,
  email,
  showSpinner,
})

export function* watchGetNotifications() {
  yield takeEvery(GET_NOTIFICATIONS, getNotificationsSaga)
}

/*
 * Saga to retrieve user email messages
 * @param {action} - the triggering redux action
 *   Call the notifications api to retrieve messages for a specific user
 */
export function* getNotificationsSaga(action) {
  yield put({
    type: appActions.INITIATE_ASYNC,
    showSpinner: action.showSpinner,
  })
  try {
    const config = yield select((state) => state.config.appConfig)
    const messages = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/notifications/messages`,
      init: {
        method: 'POST',
        body: JSON.stringify(action.email),
      },
    })
    yield put({ type: GET_NOTIFICATIONS_SUCCESS, messages })
    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            error.clientMessage ||
            `Failed to get messages for user ${action.email}`,
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}
/*
 * Saga to send review emails/notifications to approvers when an asset is published
 * First, find all users that have the approveasset or rejectasset privilege on the folder
 * Then request an email blast to all those users
 *
 * If no reviewers are found - ??? - MVP implementation shows a message to the requester.
 */
export function* sendPublishNotificationSaga(action) {
  const config = yield select((state) => state.config.appConfig)
  const folders = yield select((state) => state.folders.byId)
  const permissions = yield select((state) => state.permissions)
  const assets = yield select((state) => state.assets.byId)

  // Get unique publish roles
  const reviewerRoles = Array.from(
    new Set(
      getRolesWithPrivilege('approveasset', permissions.privileges).concat(
        getRolesWithPrivilege('rejectasset', permissions.privileges)
      )
    )
  )
  let reviewers = new Set()
  // These groups/users have the publishasset privilege
  let reviewerEntities = Object.keys(
    folders[action.parent].contentRoles
  ).filter((cr) =>
    folders[action.parent].contentRoles[cr].roleIds.some(
      (role) => reviewerRoles.indexOf(role) > -1
    )
  )
  // Now, get the user ids
  reviewerEntities.forEach((entity) => {
    if (folders[action.parent].contentRoles[entity].entityType === 'user')
      reviewers.add(folders[action.parent].contentRoles[entity].entityId)
    else {
      permissions.groups[entity].members.forEach((mem) => reviewers.add(mem))
    }
  })

  try {
    const user = Object.keys(permissions.users)
      .filter((u) => permissions.users[u].id === action.user)
      .map((u) => permissions.users[u])[0]

    if (reviewers.size > 0) {
      const templateVars = {
        assets: action.assets.map((assetId) => ({
          assetLink: `${config.baseUrl}/library/asset/${assetId}`,
          assetTitle: assets[assetId].title || assets[assetId].filename,
        })),
        folder: {
          link: `${config.baseUrl}/library/${folders[action.parent].id}`,
          name: folders[action.parent].name,
        },
        user: { name: user.name },
      }
      let templateName

      if (action.type === endpointActions.PUBLISH_ASSET_SUCCESS) {
        templateName = 'AssetRequestPublishNotification'
      } else {
        templateName = 'AssetRequestUnpublishNotification'
      }

      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/notifications/send`,
        init: {
          method: 'POST',
          body: JSON.stringify({
            from_address: 'notifications@bam.zone',
            notify: true,
            templateName,
            templateVars,
            to_addresses: Array.from(reviewers),
          }),
        },
      })
    } else {
      const admins = yield select(
        (state) => state.permissions.groups['SYS.admins'].members
      )
      if (admins.length === 0) admins.push('support@bam.zone')
      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/notifications/send`,
        init: {
          method: 'POST',
          body: JSON.stringify({
            to_addresses: admins,
            from_address: 'notifications@bam.zone',
            subject: `${user.name} needs someone to approve a publication request`,
            textBody:
              `Hi, ${user.name} needs someone to approve a publication request, however ` +
              `there's not an approver ``currently assigned to ${
                folders[action.parent].name
              }. Visit ${
                config.baseUrl
              }/permissions/groups to edit global approvers.`,
            htmlBody:
              `
                    <html>
                        <head></head>
                        <body>
                            <p class="message__greeting">Hi,</p>
                            <p class="message__body">${user.name} needs someone to approve a ` +
              `publication request, however there's not an approver currently assigned to ${
                folders[action.parent].name
              }.  Click <a href="${
                config.baseUrl
              }/permissions/groups">here</a> to edit global approvers.</p>
                            <p>Thank you,</p>
                            <p>BAM!</p>
                        </body>
                    </html>
                    `,
            notify: true,
          }),
        },
      })

      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            "Your request was submitted, however there's no one to review it. Your BAM! " +
            'admin has been notified. ',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  } catch (error) {
    console.log(error)
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            error.clientMessage ||
            'Email failed to send.  Please notify approvers',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}
/*
 *  sendReviewNotificationSaga: When an asset that is PENDING_APPROVAL is reviewed, send a 
    notification to the requester with the results of the review.
 */
export function* sendReviewNotificationSaga(action) {
  try {
    const config = yield select((state) => state.config.appConfig)
    yield put({ type: appActions.INITIATE_ASYNC })
    const folderAssetStatus = yield select(
      (state) => state.endpoints.folderAssetStatus
    )
    const users = yield select((state) => state.permissions.users)
    const assets = yield select((state) => state.assets.byId)
    const folders = yield select((state) => state.folders.byId)

    const type =
      action.type === endpointActions.REVIEW_ASSET_SUCCESS
        ? 'requestedBy'
        : 'unpublishRequestedBy'

    let requests = action.assets
      .map((asset) => {
        const user = Object.keys(users)
          .filter(
            (u) => users[u].id === folderAssetStatus[action.parent][asset][type]
          )
          .map((u) => users[u])[0]
        return { user, asset }
      })
      .reduce((result, obj) => {
        result[obj.user.email] = {
          name: obj.user.name,
          assets: result[obj.user.email]
            ? result[obj.user.email].assets.concat([obj.asset])
            : [obj.asset],
        }
        return result
      }, {})
    const reviewer = Object.keys(users)
      .filter((user) => users[user].id === action.user)
      .map((u) => users[u])[0]

    for (let request of Object.keys(requests)) {
      let subject = 'Your asset has been reviewed'
      let textBody =
        `Your publishing request was reviewed by ${reviewer.name}. The new asset ` +
        `status is: ${action.statusType}`
      let htmlBody = '<html><head></head><body></body></html>'
      if (action.type === endpointActions.REVIEW_ASSET_SUCCESS) {
        if (action.statusType === endpointActions.APPROVED) {
          subject =
            requests[request].assets.length > 1
              ? 'Your assets have been published!'
              : `${
                  assets[requests[request].assets[0]].title ||
                  assets[requests[request].assets[0]].filename
                } has been published!`
          htmlBody = `
                        <html>
                            <head></head>
                            <body>
                                <p class="message__greeting">Hi ${
                                  requests[request].name
                                },</p>
                                <p class="message__body">${
                                  reviewer.name
                                } has approved the following asset${
            requests[request].assets.length > 1 ? 's' : ''
          } to ${folders[action.parent].name}.
                                 <ul class="message__list">
                                    ${requests[request].assets
                                      .map((asset) => {
                                        return `<li class="message__listitem"><a href="${
                                          config.baseUrl
                                        }/library/asset/${asset}">${
                                          assets[asset].title ||
                                          assets[asset].filename
                                        }</a></li>`
                                      })
                                      .join('')}
                                </ul>
                                <p>${reviewer.name} commented: ${
            action.comments || ''
          }
                                <p>Thank you,</p>
                                <p>BAM!</p>
                            </body>
                        </html>
                    `
        } else {
          subject =
            requests[request].assets.length > 1
              ? `Your request to publish ${
                  requests[request].assets.length
                } assets to ${folders[action.parent].name} was not approved`
              : `Your request to publish ${
                  assets[requests[request].assets[0]].title ||
                  assets[requests[request].assets[0]].filename
                } to ${folders[action.parent].name} was not approved`
          htmlBody = `
                        <html>
                            <head></head>
                            <body>
                                <p class="message__greeting">Hi ${
                                  requests[request].name
                                },</p>
                                <p class="message__body">${
                                  reviewer.name
                                } did not approve the following asset${
            requests[request].assets.length > 1 ? 's' : ''
          } to ${folders[action.parent].name}.
                                 <ul class="message__list">
                                    ${requests[request].assets
                                      .map((asset) => {
                                        return `<li class="message__listitem"><a href="${
                                          config.baseUrl
                                        }/library/asset/${asset}">${
                                          assets[asset].title ||
                                          assets[asset].filename
                                        }</a></li>`
                                      })
                                      .join('')}
                                </ul>
                                <p>${reviewer.name} commented: ${
            action.comments || ''
          }
                                <p>Thank you,</p>
                                <p>BAM!</p>
                            </body>
                        </html>
                    `
        }
      } else {
        if (action.statusType === endpointActions.UNPUBLISHED) {
          subject =
            requests[request].assets.length > 1
              ? `${
                  requests[request].assets.length
                } assets have been removed from ${folders[action.parent].name}`
              : `${
                  assets[requests[request].assets[0]].title ||
                  assets[requests[request].assets[0]].filename
                } has been removed from ${folders[action.parent].name}`
          htmlBody = `
                       <html>
                           <head></head>
                           <body>
                               <p class="message__greeting">Hi ${
                                 requests[request].name
                               },</p>
                               <p class="message__body">${
                                 reviewer.name
                               } has removed the following asset${
            requests[request].assets.length > 1 ? 's' : ''
          } from ${
            folders[action.parent].name
          }, so they are no longer published.
                                <ul class="message__list">
                                   ${requests[request].assets
                                     .map((asset) => {
                                       return `<li class="message__listitem"><a href="${
                                         config.baseUrl
                                       }/library/asset/${asset}">${
                                         assets[asset].title ||
                                         assets[asset].filename
                                       }</a></li>`
                                     })
                                     .join('')}
                               </ul>
                               <p>${reviewer.name} commented: ${
            action.comments || ''
          }
                               <p>Thank you,</p>
                               <p>BAM!</p>
                           </body>
                       </html>
                   `
        } else {
          subject =
            requests[request].assets.length > 1
              ? `Your request to remove ${
                  requests[request].assets.length
                } assets from ${folders[action.parent].name} was not approved`
              : `Your request to remove ${
                  assets[requests[request].assets[0]].title ||
                  assets[requests[request].assets[0]].filename
                } from ${folders[action.parent].name} was not approved`
          htmlBody = `
                        <html>
                            <head></head>
                            <body>
                                <p class="message__greeting">Hi ${
                                  requests[request].name
                                },</p>
                                <p class="message__body">${
                                  reviewer.name
                                } did not want to remove the following asset${
            requests[request].assets.length > 1 ? 's' : ''
          } from ${folders[action.parent].name}.
                                 <ul class="message__list">
                                    ${requests[request].assets
                                      .map((asset) => {
                                        return `<li class="message__listitem"><a href="${
                                          config.baseUrl
                                        }/library/asset/${asset}">${
                                          assets[asset].title ||
                                          assets[asset].filename
                                        }</a></li>`
                                      })
                                      .join('')}
                                </ul>
                                <p>${reviewer.name} commented: ${
            action.comments || ''
          }
                                <p>Thank you,</p>
                                <p>BAM!</p>
                            </body>
                        </html>
                    `
        }
      }

      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/notifications/send`,
        init: {
          method: 'POST',
          body: JSON.stringify({
            to_addresses: [request],
            from_address: 'notifications@bam.zone',
            subject,
            textBody,
            htmlBody,
            notify: true,
          }),
        },
      })
    }
    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    console.log(error)
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: error.clientMessage || 'Review email failed to send.',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}

export const setMessageRead = (email, id) => ({
  type: UPDATE_MESSAGE,
  email,
  update: { viewed: true },
  id,
})

export const setMessageUnread = (email, id) => ({
  type: UPDATE_MESSAGE,
  email,
  update: { viewed: false },
  id,
})

export const setMessageDeleted = (email, id) => ({
  type: UPDATE_MESSAGE,
  email,
  update: { deleted: true },
  id,
})

export function* watchMessageUpdates() {
  yield takeEvery([UPDATE_MESSAGE], updateMessageSaga)
}

export function* updateMessageSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC })
  try {
    const config = yield select((state) => state.config.appConfig)
    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/notifications/update`,
      init: {
        method: 'POST',
        body: JSON.stringify(action),
      },
    })
    yield put({
      type: UPDATE_MESSAGE_SUCCESS,
      id: action.id,
      update: action.update,
    })
    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: error.clientMessage || 'Failed to update message',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}

export default all([watchGetNotifications(), watchMessageUpdates()])
