import md5 from 'md5'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActions from '../../dux/actions/appActions'
import * as modalActions from '../../dux/actions/modal'
import * as actions from '../../dux/actions/settings'
import * as constants from '../../dux/constants/menu'
import { findLibraryFolder, getEndpointById } from '../../dux/modules/utils'
import MSTMenuSettingsView from './MSTMenuSettingsView'

class MSTMenuSettings extends React.Component {
  state = {
    message: '',
    messageType: '',
    originalAssets: null,
    originalURLs: null,
  }

  componentDidMount() {
    this.props.appActions.scrollToTop('main-content')
  }

  saveFormRef = (ref) => (this.form = ref)
  saveResourceFormRef = (ref) => (this.resourceForm = ref)

  onCancel = () => {
    this.form.resetFields()
    if (this.state.originalResources)
      this.props.actions.resetMenuSettings(this.state.originalResources)

    this.setState({
      message: 'Menu Settings update canceled',
      messageType: 'ERROR',
    })
  }

  onFieldsChange = () => {
    if (this.state.message) {
      this.setState({ message: '', messageType: '' })
    }
  }

  onSave = () => {
    this.form.validateFields((err, values) => {
      if (!err) {
        const menu = {
          modules: {
            dashboard: values.dashboard || constants.DEFAULT_DASHBOARD_LABEL,
            library: values.library || constants.DEFAULT_LIBRARY_LABEL,
            profile: values.profile || constants.DEFAULT_PROFILE_LABEL,
            customerProfiles:
              values.customerProfiles ||
              constants.DEFAULT_CUSTOMER_PROFILE_LABEL,
            news: values.news || constants.DEFAULT_NEWS_LABEL,
            navigation: values.navigation || constants.DEFAULT_NAVIGATION_LABEL,
            resources: values.resources || constants.DEFAULT_RESOURCES_LABEL,
            comparison: values.comparison || constants.DEFAULT_COMPARISON_LABEL,
            calculators:
              values.calculators || constants.DEFAULT_CALCULATORS_LABEL,
            uploads: values.ugc || constants.DEFAULT_UGC_LABEL,
          },
          custom: this.props.menu.custom,
        }

        this.props.actions.saveSettings('menu', menu)

        this.setState({
          message: 'Menu Settings Updated',
          messageType: 'SUCCESS',
          originalResources: this.props.menu.custom.map((resource) => {
            return { ...resource }
          }),
        })
      }
    })
  }

  removeCustomMenuResource = (resource) => {
    if (!this.state.originalResources) {
      this.setState({
        originalResources: this.props.menu.custom.map((resource) => {
          return { ...resource }
        }),
      })
    }
    this.props.actions.removeCustomMenuResource(resource)
  }

  openSelectAssetModal = (resource) => {
    let modalProps = {
      treeRoot: getEndpointById('MST', this.props.endpoints).rootFolders,
      filterType: 'published',
      saveCallback: (asset, folder, displayText) => {
        this.saveCustomResource({
          id: md5(Date.now()),
          resourceType: 'asset',
          resourceValue: asset,
          displayText,
        })
      },
      modalTitle: 'Assets - Published to Mobile Sales Tool',
      showTextOverride: true,
      textOverrideDescription:
        'By default the asset title will be displayed in the menu.  However, you may customize ' +
        'the menu item text below if necessary.',
    }
    if (resource) {
      modalProps.selectedAsset = resource.resourceValue
      modalProps.selectedFolder = findLibraryFolder(
        resource.resourceValue,
        this.props.allFolders,
        this.props.endpoints
      )
      modalProps.selectedDisplayText = resource.displayText
      modalProps.showTextOverride = true
      modalProps.textOverrideDescription =
        'By default the asset title will be displayed in the menu.  However, you may customize ' +
        'the menu item text below if necessary.'
      modalProps.saveCallback = (asset, folder, displayText) =>
        this.saveCustomResource({
          id: resource.id,
          resourceType: 'asset',
          resourceValue: asset,
          displayText,
        })
    }

    this.setState({
      message: '',
      messageType: '',
    })

    this.props.modalActions.openModal('SelectAssetModal', modalProps)
  }

  saveCustomResource = (resource) => {
    if (this.resourceForm) {
      this.resourceForm.validateFields((err, values) => {
        if (!err) {
          if (!this.state.originalResources) {
            this.setState({
              originalResources: this.props.menu.custom.map((resource) => {
                return { ...resource }
              }),
            })
          }
          this.props.actions.saveCustomMenuResource({
            ...resource,
            resourceValue: values.value,
            displayText: values.displayText,
          })
          this.props.modalActions.closeModal()
        } else {
          console.log(err)
        }
      })
    } else {
      if (!this.state.originalResources) {
        this.setState({
          originalResources: this.props.menu.custom.map((resource) => {
            return { ...resource }
          }),
        })
      }
      this.props.actions.saveCustomMenuResource({
        ...resource,
        resourceValue: resource.resourceValue,
        displayText:
          resource.displayText ||
          this.props.allAssets[resource.resourceValue].filename,
      })
      this.props.modalActions.closeModal()
    }
  }

  openCustomResourceModal = (type, resource = {}) => {
    this.setState({
      message: '',
      messageType: '',
    })

    this.props.modalActions.openModal('CustomResourceModal', {
      modalTitle: 'Custom Menu Resource',
      saveFormRef: this.saveResourceFormRef,
      saveCustomResource: this.saveCustomResource,
      resource: {
        resourceType: resource.resourceType || type,
        resourceValue: resource.resourceValue || '',
        id: resource.id || md5(Date.now()),
        displayText: resource.displayText || '',
      },
    })
  }

  showAssetDetail = () => {
    this.props.appActions.setBackButtonFlag(true)
    this.props.appActions.setMenuItem('library')
  }

  render() {
    return (
      <MSTMenuSettingsView
        allAssets={this.props.allAssets}
        customerProfiles={this.props.customerProfiles}
        menu={this.props.menu}
        message={this.state.message}
        messageType={this.state.messageType}
        modules={this.props.modules}
        onCancel={this.onCancel}
        onFieldsChange={this.onFieldsChange}
        onSave={this.onSave}
        openCustomResourceModal={this.openCustomResourceModal}
        openSelectAssetModal={this.openSelectAssetModal}
        saveFormRef={this.saveFormRef}
        showAssetDetail={this.showAssetDetail}
        removeCustomMenuResource={this.removeCustomMenuResource}
        uploads={this.props.uploads}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  menu: state.settings.menu,
  uploads: state.settings.uploads,
  customerProfiles: state.settings.profiles,
  allAssets: state.assets.byId,
  allFolders: state.folders.byId,
  modules: state.settings.modules,
  endpoints: state.endpoints.endpoints,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  appActions: bindActionCreators(appActions, dispatch),
  modalActions: bindActionCreators(modalActions, dispatch),
})

MSTMenuSettings.propTypes = {
  actions: PropTypes.shape({
    saveMenu: PropTypes.func,
    resetMenuSettings: PropTypes.func,
    saveCustomAsset: PropTypes.func,
    saveCustomUrl: PropTypes.func,
    removeCustomMenuAsset: PropTypes.func,
    removeCustomMenuURL: PropTypes.func,
  }),
  appActions: PropTypes.shape({
    scrollToTop: PropTypes.func,
    setBackButtonFlag: PropTypes.func,
    setMenuItem: PropTypes.func,
  }),
  modalActions: PropTypes.shape({
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
  }),
  uploads: PropTypes.object,
  menu: PropTypes.object,
  modules: PropTypes.object,
  allAssets: PropTypes.object,
  allFolders: PropTypes.object,
  endpoints: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(MSTMenuSettings)
