import PropTypes from 'prop-types'
import React from 'react'

const BuilderPreview = (props) => {
  return props.asset[props.type].image ? (
    <div
      className="assetdetail__preview-image"
      alt="asset preview"
      style={{
        backgroundImage: `url(${
          props.allAssets[props.asset[props.type].image].preview
        })`,
        height: props.allAssets[props.asset[props.type].image].dimensions
          ? `${
              props.allAssets[props.asset[props.type].image].dimensions.height
            }px`
          : '100%',
        width: props.allAssets[props.asset[props.type].image].dimensions
          ? `${
              props.allAssets[props.asset[props.type].image].dimensions.width
            }px`
          : '100%',
      }}
    />
  ) : (
    <div className="assetdetail__preview-icon">
      <i className={`fa ${props.asset.icon}`} />
    </div>
  )
}

BuilderPreview.propTypes = {
  asset: PropTypes.object,
  type: PropTypes.string,
}

export default BuilderPreview
