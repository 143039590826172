import { Form, Input, Modal, Select } from 'antd'
import { customAlphabet } from 'nanoid'
import PropTypes from 'prop-types'
import React from 'react'

const FormItem = Form.Item
const nanoid = customAlphabet(
  '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
  7
)

class AddInvitationModal extends React.Component {
  state = {
    inviteCode: nanoid(),
  }

  saveFormRef = (ref) => (this.form = ref)

  onAdd = () => {
    this.form.validateFields((err, values) => {
      if (!err) {
        const invite = {
          name: values.inviteName,
          id: values.id,
          groups: values.groups,
          valid: true,
          uses: parseInt(values.uses, 10),
          registrations: 0,
          client: this.props.user.client,
          deleted: false,
          created: Date.now(),
        }

        this.props.modalProps.onSubmit(invite)
      }
    })
  }
  render() {
    return (
      <Modal
        title="Add Invitation Code"
        okText="SAVE"
        onOk={this.onAdd}
        cancelText="Cancel"
        onCancel={this.props.modalActions.closeModal}
        visible={this.props.modal.modalOpen}
        confirmLoading={this.props.appState.asyncPending}
        maskClosable={false}
      >
        <AddInvitationForm
          ref={this.saveFormRef}
          groups={this.props.modalProps.groups}
          code={this.state.inviteCode}
        />
      </Modal>
    )
  }
}

const AddInvitationForm = Form.create()((props) => {
  const { form } = props
  const { getFieldDecorator } = form
  const { Option } = Select

  return (
    <Form layout="vertical">
      <FormItem label="Invitation Name" require={true}>
        {getFieldDecorator('inviteName', {
          initialValue: '',
          rules: [
            {
              required: true,
              message: 'Invitation Name is required',
            },
          ],
        })(<Input />)}
      </FormItem>
      <FormItem
        label="Group"
        help={
          'Users that register with this invitation will be automatically added to selected groups.'
        }
      >
        {getFieldDecorator('groups', {
          initialValue: undefined,
          rules: [
            {
              required: true,
              message: 'At leaset one group is required',
            },
          ],
        })(
          <Select
            mode="multiple"
            defaultActiveFirstOption={false}
            placeholder="Add one or more groups"
            filterOption
            optionFilterProp="name"
          >
            {props.groups
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((group) => (
                <Option key={group.id} value={group.id} name={group.name}>
                  {group.name}
                </Option>
              ))}
          </Select>
        )}
      </FormItem>
      <FormItem
        label="Available Registrations"
        extra="How many users may register with this code before it becomes invalid?"
      >
        {getFieldDecorator('uses', {
          validateTrigger: 'onBlur',
          initialValue: 1,
          rules: [
            {
              validator(rule, value, callback) {
                if (
                  value <= 0 ||
                  value > 100 ||
                  !Number.isInteger(parseFloat(value))
                )
                  return callback(
                    new Error('# of uses must be an integer between 1 and 100')
                  )
                else return callback()
              },
            },
          ],
        })(<Input type="number" />)}
      </FormItem>
      <FormItem label="Invitation Code">
        {getFieldDecorator('id', {
          initialValue: props.code,
        })(<Input disabled />)}
      </FormItem>
    </Form>
  )
})
/*
 *  Props are from the redux store
 *  "actions" are objects created by bindActionCreators, containing all relevant action functions
 *  modalProps is object passed from modal creator
 */
AddInvitationModal.propTypes = {
  modal: PropTypes.object,
  modalActions: PropTypes.object,
  modalProps: PropTypes.object,
  appState: PropTypes.shape({
    asyncPending: PropTypes.bool,
  }),
  onSubmit: PropTypes.func,
}

AddInvitationModal.defaultProps = {
  modalProps: {},
}

export default AddInvitationModal
