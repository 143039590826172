import { Form, Input, Modal, Radio, Select, Typography } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

const FormItem = Form.Item
const Option = Select.Option

class AddUserModal extends React.Component {
  saveFormRef = (ref) => (this.form = ref)

  onAdd = () => {
    this.form.validateFields((err, values) => {
      if (!err) {
        this.props.userActions.adminCreateUser(
          values.username,
          values.email.toLowerCase(),
          values.confirmType,
          values.password,
          values.invitation
        )
      }
    })
  }

  render() {
    const { adminCreateError } = this.props

    return (
      <Modal
        title="Add User"
        okText="SAVE"
        onOk={this.onAdd}
        cancelText="Cancel"
        onCancel={this.props.modalActions.closeModal}
        visible={this.props.modal.modalOpen}
        confirmLoading={this.props.appState.asyncPending}
        maskClosable={false}
      >
        {adminCreateError && (
          <Typography.Text type="danger">{adminCreateError}</Typography.Text>
        )}

        <AddUserForm
          ref={this.saveFormRef}
          users={this.props.permissions.users}
          invitations={this.props.invitations.invitations.filter(
            (i) => i.valid
          )}
        />
      </Modal>
    )
  }
}

const AddUserForm = Form.create()((props) => {
  const { form } = props
  const { getFieldDecorator } = form

  const clearError = (e) => {
    if (props.form.getFieldError(e.target.id))
      props.form.setFields({ [e.target.id]: { errors: '' } })
  }

  return (
    <Form layout="vertical">
      <FormItem
        className="settings__form-item"
        label="User Email"
        require={true}
      >
        {getFieldDecorator('email', {
          initialValue: '',
          validateTrigger: 'onBlur',
          onChange: clearError,
          rules: [
            {
              type: 'email',
              message: 'The input is not a valid email address',
            },
            {
              required: true,
              message: 'Please enter your email',
            },
            {
              validator(rule, value, callback) {
                if (props.users[value])
                  return callback(new Error('This user already exists'))
                else return callback()
              },
            },
          ],
        })(<Input />)}
      </FormItem>
      <FormItem
        className="settings__form-item"
        label="Full Name"
        require={true}
      >
        {getFieldDecorator('username', {
          initialValue: '',
          validateTrigger: 'onBlur',
          onChange: clearError,
          rules: [
            {
              required: true,
              message: 'Name is required',
            },
          ],
        })(<Input />)}
      </FormItem>
      <FormItem
        className="settings__form-item"
        label="Temporary Password"
        require={true}
      >
        {getFieldDecorator('password', {
          initialValue: '',
          onChange: clearError,
          validateTrigger: 'onBlur',
          rules: [
            {
              required: true,
              message: 'Temporary password is required',
            },
            {
              validator(rule, value, callback) {
                if (value && value.length < 8)
                  return callback(
                    new Error('Password must be at least 8 characters')
                  )
                else return callback()
              },
            },
          ],
        })(<Input />)}
      </FormItem>
      <FormItem
        className="settings__form-item"
        label="Invitation Code"
        require={true}
      >
        {getFieldDecorator('invitation', {
          initialValue: '',
          onChange: () => clearError({ target: 'invitation' }),
          validateTrigger: 'onBlur',
          rules: [
            {
              required: true,
              message: 'Invitation Code is required',
            },
          ],
        })(
          <Select
            key={props.form.getFieldValue('invitation')}
            defaultActiveFirstOption={false}
            placeholder="Add an invitation"
            filterOption
            optionFilterProp="name"
          >
            {props.invitations
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((invite) => (
                <Option
                  key={invite.id}
                  value={invite.id}
                  name={invite.name}
                >{`${invite.name} (${invite.id})`}</Option>
              ))}
          </Select>
        )}
      </FormItem>
      <FormItem label="Send Email">
        {getFieldDecorator('confirmType', {
          initialValue: 'auto',
        })(
          <Radio.Group>
            <Radio value="email" disabled>
              Send Verification Email
            </Radio>
            <Radio value="auto">Automatically Verify User</Radio>
          </Radio.Group>
        )}
      </FormItem>
    </Form>
  )
})

const mapState = ({ user }) => ({
  adminCreateError: user.admin.createUserError,
})

/*
 *  Props are from the redux store
 *  "actions" are objects created by bindActionCreators, containing all relevant action functions
 *  modalProps is object passed from modal creator
 */
AddUserModal.propTypes = {
  modal: PropTypes.object,
  modalActions: PropTypes.object,
  modalProps: PropTypes.object,
  appState: PropTypes.shape({
    asyncPending: PropTypes.bool,
  }),
  onSubmit: PropTypes.func,
}

AddUserModal.defaultProps = {
  modalProps: {},
}

export default connect(mapState)(AddUserModal)
