const transformCoordinatesToPercentages = ({ x, y }) => {
  const percentageX = (x + 1) / 2
  const percentageY = (y + 1) / 2
  return { x: percentageX, y: percentageY }
}

export const calculateNavzoneDimensions = (navZone, imageSize) => {
  const { bottomRight, topLeft } = navZone
  const { x: bottomRightX, y: bottomRightY } =
    transformCoordinatesToPercentages(bottomRight)
  const { x: topLeftX, y: topLeftY } =
    transformCoordinatesToPercentages(topLeft)
  const { width: imageWidth, height: imageHeight } = imageSize

  const width = (bottomRightX - topLeftX) * imageWidth
  const height = (topLeftY - bottomRightY) * imageHeight

  return { width, height }
}

export const calculateNavzonePosition = (navZone, imageSize, containerSize) => {
  const { topLeft, bottomRight } = navZone
  const { width: imageWidth, height: imageHeight } = imageSize
  const { x } = transformCoordinatesToPercentages(topLeft)
  const { y } = transformCoordinatesToPercentages(bottomRight)

  let left = x * imageWidth
  let bottom = y * imageHeight

  const originalAspectRatio = imageSize.width / imageSize.height
  const containerAspectRatio = containerSize.width / containerSize.height
  if (containerAspectRatio > originalAspectRatio) {
    // image will fill the height of the container, offset x position
    left += (containerSize.width - imageWidth) / 2
  }
  if (containerAspectRatio < originalAspectRatio) {
    // image will fill the width of the container
    bottom += (containerSize.height - imageHeight) / 2
  }

  return { left, bottom }
}
export const calculateImageSize = (originalSize, containerSize) => {
  const originalAspectRatio = originalSize.width / originalSize.height
  const containerAspectRatio = containerSize.width / containerSize.height
  if (containerAspectRatio > originalAspectRatio) {
    // image will fill the height of the container,
    const width = containerSize.height * originalAspectRatio
    const height = containerSize.height
    return { width, height }
  }
  if (containerAspectRatio < originalAspectRatio) {
    // image will fill the width of the container
    const width = containerSize.width
    const height = containerSize.width / originalAspectRatio
    return { width, height }
  }
  if (containerAspectRatio === originalAspectRatio) {
    // image will fill the container , do nothing
    return { width: containerSize.width, height: containerSize.height }
  }
}
