import md5 from 'md5'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActions from '../../dux/actions/appActions'
import * as modalActions from '../../dux/actions/modal'
import * as actions from '../../dux/actions/settings'
import MSTResourceSettingsView from './MSTResourceSettingsView'

class MSTResourceSettings extends React.Component {
  state = {
    message: '',
    messageType: '',
  }

  componentDidMount() {
    this.props.appActions.scrollToTop('main-content')
  }

  saveFormRef = (ref) => (this.form = ref)

  saveResourceFormRef = (ref) => (this.resourceForm = ref)

  onFieldsChange = () => {
    if (this.state.message) {
      this.setState({ message: '', messageType: '' })
    }
  }

  onSaveForm = () => {
    this.form.validateFields((err, values) => {
      if (!err) {
        const resources = {
          custom: this.props.resources.custom,
          termsOfService: values.terms,
          privacyPolicy: values.privacy,
          social: {
            facebook: values.facebook,
            twitter: values.twitter,
            instagram: values.instagram,
            youtube: values.youtube,
            linkedin: values.linkedin,
          },
        }
        this.props.actions.saveSettings('resources', resources)
        this.setState({
          message: 'Resource settings updated',
          messageType: 'SUCCESS',
          originalURLs: resources.custom.urls,
          originalPhoneNumbers: resources.custom.phoneNumbers,
        })
      }
    })
  }

  onCancel = () => {
    this.form.resetFields()
    this.setState({
      message: 'Resource Settings update canceled',
      messageType: 'ERROR',
    })
    if (this.state.originalCustomResources)
      this.props.actions.cancelResourcesUpdate(
        this.state.originalCustomResources
      )
  }

  openCustomResourceModal = (type, resource = {}) => {
    if (this.state.message) {
      this.setState({ message: '', messageType: '' })
    }
    this.props.modalActions.openModal('CustomResourceModal', {
      modalTitle: 'Custom Resource',
      saveFormRef: this.saveResourceFormRef,
      saveCustomResource: this.saveCustomResource,
      resource: {
        resourceType: resource.resourceType || type,
        resourceValue: resource.resourceValue || '',
        id: resource.id || md5(Date.now()),
        displayText: resource.displayText || '',
      },
    })
  }

  saveCustomResource = (resource) => {
    this.resourceForm.validateFields((err, values) => {
      if (!err) {
        if (!this.state.originalCustomResources)
          this.setState({
            originalCustomResources: this.props.resources.custom.map(
              (resource) => {
                return { ...resource }
              }
            ),
          })

        this.props.actions.saveCustomResource({
          ...resource,
          resourceValue: values.value,
          displayText: values.displayText,
        })
        this.props.modalActions.closeModal()
      }
    })
  }

  removeCustomResource = (resource) => {
    this.setState((prevState) => {
      let updates = {}
      if (prevState.message) {
        updates.message = ''
        updates.messageType = ''
      }
      if (!prevState.originalCustomResources) {
        updates.originalCustomResources = this.props.resources.custom.map(
          (resource) => {
            return { ...resource }
          }
        )
      }

      return updates
    })
    this.props.actions.removeCustomResource(resource.id)
  }

  render() {
    return (
      <MSTResourceSettingsView
        resources={this.props.resources}
        onSave={this.onSaveForm}
        onCancel={this.onCancel}
        onFieldsChange={this.onFieldsChange}
        saveFormRef={this.saveFormRef}
        saveURLFormRef={this.saveUrlFormRef}
        savePhoneFormRef={this.savePhoneFormRef}
        message={this.state.message}
        messageType={this.state.messageType}
        openCustomResourceModal={this.openCustomResourceModal}
        removeCustomResource={this.removeCustomResource}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  resources: state.settings.resources,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  appActions: bindActionCreators(appActions, dispatch),
  modalActions: bindActionCreators(modalActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(MSTResourceSettings)
