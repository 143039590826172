import { Button, Modal } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as invitationActions from '../../dux/actions/invitation'
import * as modalActions from '../../dux/actions/modal'

const InvTableActions = (props) => (
  <span>
    <Button
      disabled={
        props.record.registrations >= props.record.uses || !props.isAdmin
      }
      onClick={() =>
        props.modalActions.openModal('EditInvitationModal', {
          record: props.record,
        })
      }
      icon="edit"
      style={{ margin: '0 5px' }}
    />
    <Button
      disabled={!props.isAdmin}
      icon="delete"
      style={{ margin: '0 5px' }}
      onClick={() => {
        return props.isAdmin
          ? Modal.confirm({
              title: 'Confirm delete',
              onOk: () => {
                props.actions.deleteInvitation(props.record.id)
                Promise.resolve('')
              },
              content: 'This action cannot be undone',
            })
          : null
      }}
    />
  </span>
)

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(invitationActions, dispatch),
  modalActions: bindActionCreators(modalActions, dispatch),
})

export default connect(null, mapDispatchToProps)(InvTableActions)
