import { Breadcrumb, Dropdown, Menu, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import {
  assetIsPublished,
  buildPath,
  getEndpointOfNode,
  getEndpointStatus,
  shortenFolderName,
} from '../../../dux/modules/utils'
import ActionIcon from '../ActionIcon'
import { FOLDER_ACTIONS } from '../CardConstants'

class AssetLocationPanel extends React.PureComponent {
  render() {
    const { folderList, allFolders, endpoints, asset, navigateToFolder } =
      this.props
    return folderList.map((folder) => {
      const endpoint = getEndpointOfNode(
        folder,
        allFolders,
        endpoints.endpoints
      )
      return (
        <div key={folder.id} className="assetdetail__panel-content">
          <div className="assetdetail__panel-border"></div>
          <div className="assetdetail__panel-content-title">
            <span
              className="assetdetail__panel-content-link"
              onClick={() => navigateToFolder(folder.id)}
            >
              {endpoint.name}
            </span>
          </div>
          <div className="assetdetail__panel-content-value">
            <span className="assettable__status">
              {endpoint.allowPublishing ? (
                getEndpointStatus(
                  folder.id,
                  asset.id,
                  endpoints.folderAssetStatus
                )
              ) : (
                <Tooltip title="Original Asset">
                  <i className="fa fa-star assettable__status-blue" />
                </Tooltip>
              )}
            </span>
            <span
              className="assetdetail__panel-content-status assetdetail__panel-content-link"
              onClick={() => navigateToFolder(folder.id)}
            >
              {
                <Tooltip
                  title={
                    <Breadcrumb separator=">">
                      {buildPath(folder.id, allFolders)
                        .reverse()
                        .map((crumb) => (
                          <Breadcrumb.Item
                            key={crumb}
                            className="assetdetail__breadcrumb"
                          >{` ${allFolders[crumb].name} `}</Breadcrumb.Item>
                        ))}
                    </Breadcrumb>
                  }
                >
                  {shortenFolderName(folder.name)}
                </Tooltip>
              }
            </span>
            <span className="assetdetail__panel-menu-trigger">
              <Dropdown
                overlay={<AssetLocationMenu {...this.props} folder={folder} />}
                trigger={['click']}
              >
                <i className="fa fa-ellipsis-h assetdetail__panel-content-link" />
              </Dropdown>
            </span>
          </div>
        </div>
      )
    })
  }
}

const AssetLocationMenu = (props) => (
  <Menu className="assetdetail__panel-content-menu">
    {FOLDER_ACTIONS.filter((action) =>
      props.actionFilter(action, props.asset, props.folder.id)
    ).map((action) => {
      let disabled = !!props.actionDisabled
      let tooltip = ''
      if (
        action.action === 'delete' &&
        assetIsPublished(
          props.asset.id,
          props.relationships.assets,
          props.allAssets
        )
      ) {
        disabled = true
        tooltip = 'Delete is disabled for published assets'
      }
      if (
        action.action === 'delete' &&
        props.relationships.assets.referencedBy[props.asset.id] &&
        props.relationships.assets.referencedBy[props.asset.id].length > 0
      ) {
        disabled = true
        tooltip =
          'Delete is disabled for assets that are referenced within other assets'
      }
      if (
        action.action === 'unpublish' &&
        props.carousel.filter(
          (fa) => fa.asset === props.asset.id && fa.folder === props.folder.id
        ).length > 0
      ) {
        disabled = true
        tooltip = 'Unpublish is disabled for Featured Assets'
      }
      if (
        action.action === 'unpublish' &&
        props.customAssets.filter(
          (ca) =>
            ca.resourceType === 'asset' && ca.resourceValue === props.asset.id
        ).length > 0
      ) {
        disabled = true
        tooltip = 'Unpublish is disabled for Custom Menu Assets'
      }

      return (
        <Menu.Item
          className="assetdetail__panel-content-menu-item"
          key={action.action}
        >
          <ActionIcon
            action={action}
            folder={props.folder.id}
            allFolders={props.allFolders}
            email={props.email}
            privileges={props.privileges}
            groups={props.groups}
            handler={props.handler(action.action, props.folder.id)}
            disabled={disabled}
            tooltip={tooltip}
          />{' '}
          &nbsp; {action.tooltipText}
        </Menu.Item>
      )
    })}
  </Menu>
)

AssetLocationPanel.propTypes = {
  folderList: PropTypes.arrayOf(PropTypes.object),
  allFolders: PropTypes.object,
  endpoints: PropTypes.object,
  asset: PropTypes.object,
}

export default AssetLocationPanel
