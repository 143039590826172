import { Input, Modal } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import NewPassword from '../public/NewPassword'
import '../styles/Modal.css'

class ChangePasswordModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      oldPassword: '',
      password: '',
    }

    this.handlePasswordValidChange = (change) => {
      const password = change ? change.password : ''
      this.setState({ password })
    }

    this.handleChange = (event) => {
      this.setState({ [event.target.id]: event.target.value })
    }
  }

  render() {
    return (
      <Modal
        title="Change Password"
        okText="SAVE"
        onOk={() =>
          this.props.userActions.changePassword(
            this.props.user.email,
            this.state.oldPassword,
            this.state.password
          )
        }
        cancelText="Cancel"
        onCancel={this.props.modalActions.closeModal}
        visible={this.props.modal.modalOpen}
        confirmLoading={this.props.appState.asyncPending}
        maskClosable={false}
      >
        <div className="modal__form-password">
          <Input
            className="modal__input"
            type="password"
            value={this.state.oldPassword}
            onChange={this.handleChange}
            placeholder="Current password"
            autoFocus
            id="oldPassword"
          />
          <div className="input__spacer" />
          <NewPassword
            cssClass="textinput-dark"
            onValidityChange={this.handlePasswordValidChange}
          />
        </div>
      </Modal>
    )
  }
}

/*
 *  "Actions" props are objects created by redux bindActionCreators, containing all
 *  the actions for that slice of state.
 */
ChangePasswordModal.propTypes = {
  modal: PropTypes.object,
  modalActions: PropTypes.object,
  user: PropTypes.object,
  userActions: PropTypes.object,
  assets: PropTypes.object,
}

export default ChangePasswordModal
