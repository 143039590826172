import { Button, Form, Icon, Input } from 'antd'
import React from 'react'
import '../styles/App-Public.css'

const ResetPasswordForm = (props) => {
  return (
    <div>
      <div className="public__phone-icon">
        <img
          className="public__logo"
          src={`${props.config.baseUrl}/BAM-logo-v2.svg`}
          alt="logo"
        />
      </div>

      <div className="bam-login__form-container">
        <div className="bam-login__form-title">Reset Password</div>
        <div>
          <WrappedBAMResetPasswordForm ref={props.saveFormRef} {...props} />
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordForm

const FormItem = Form.Item
const BAMResetPasswordForm = (props) => {
  const { getFieldDecorator } = props.form
  const clearError = (e) => {
    if (props.form.getFieldError(e.target.id))
      props.form.setFields({ [e.target.id]: { errors: '' } })
  }
  return (
    <Form
      onSubmit={props.handleSubmit}
      className="login-form"
      layout="vertical"
      hideRequiredMark
    >
      <FormItem label={<span className="bam-form__item">Email address</span>}>
        {getFieldDecorator('email', {
          initialValue: props.email,
          validateTrigger: 'onBlur',
          onChange: clearError,
          rules: [
            {
              type: 'email',
              message: 'The input is not a valid email address',
            },
            {
              required: true,
              message: 'Please enter your email',
            },
          ],
        })(
          <Input
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email"
          />
        )}
      </FormItem>
      <FormItem
        label={<span className="bam-form__item">New Password</span>}
        hasFeedback
        validateStatus={props.form.getFieldError('password') ? 'error' : null}
      >
        {getFieldDecorator('password', {
          validateTrigger: 'onBlur',
          onChange: clearError,
          rules: [
            {
              required: true,
              message: 'Please enter your new password',
            },
            {
              type: 'string',
              min: 8,
              message: 'Password must be at least 8 characters',
            },
          ],
        })(
          <Input
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
          />
        )}
      </FormItem>
      <FormItem
        label={<span className="bam-form__item">Confirm Password</span>}
        hasFeedback
        validateStatus={
          props.form.getFieldError('confirmpassword') ? 'error' : null
        }
        className="bam-form__item"
      >
        {getFieldDecorator('confirmpassword', {
          validateTrigger: 'onBlur',
          onChange: clearError,
          rules: [
            {
              required: true,
              message: 'Re-enter password',
            },
            {
              type: 'string',
              min: 8,
              message: 'Password must be at least 8 characters',
            },
            {
              validator(rule, value, callback) {
                if (value !== props.form.getFieldValue('password'))
                  return callback(new Error('Passwords must match'))
                else return callback()
              },
            },
          ],
        })(
          <Input
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
          />
        )}
      </FormItem>
      <FormItem className="bam-form__item">
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={props.asyncPending}
        >
          Reset password
        </Button>
      </FormItem>
      <div className="bam-login__form-error">{props.errorMsg}</div>
    </Form>
  )
}
const WrappedBAMResetPasswordForm = Form.create()(BAMResetPasswordForm)
