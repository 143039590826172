import { Button, Form, Icon, Input } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/App-Public.css'

const ForgotPasswordForm = (props) => {
  return (
    <div>
      <div className="public__phone-icon">
        <img
          className="public__logo"
          src={`${props.config.baseUrl}/BAM-logo-v2.svg`}
          alt="logo"
        />
      </div>
      <div className="bam-login__form-container">
        <div className="bam-login__form-title">Forgot Password</div>
        <div>
          <WrappedBAMForgotPasswordForm ref={props.saveFormRef} {...props} />
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordForm

const FormItem = Form.Item
const BAMForgotPasswordForm = (props) => {
  const { getFieldDecorator } = props.form
  const clearError = (e) => {
    if (props.form.getFieldError(e.target.id))
      props.form.setFields({ [e.target.id]: { errors: '' } })
  }
  return (
    <Form
      onSubmit={props.handleSubmit}
      className="login-form"
      layout="vertical"
      hideRequiredMark
    >
      <FormItem
        label={
          <span className="bam-form__item bam-login__form-pw-label">
            <span className="bam-login__form-pw-label-token">
              Email Address
            </span>
            <span className="bam-login__form-pw-label-token">
              <Link to="/login" onClick={props.clearError}>
                Return to sign in
              </Link>
            </span>
          </span>
        }
      >
        {getFieldDecorator('Email', {
          initialValue: props.email,
          validateTrigger: 'onBlur',
          onChange: clearError,
          rules: [
            {
              type: 'email',
              message: 'The input is not a valid email address',
            },
          ],
        })(
          <Input
            className="public-form__input"
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email"
          />
        )}
      </FormItem>
      <FormItem className="bam-form__item">
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={props.asyncPending}
        >
          Send email
        </Button>
      </FormItem>
      <div className="bam-login__form-error">{props.errorMsg}</div>
    </Form>
  )
}
const WrappedBAMForgotPasswordForm = Form.create()(BAMForgotPasswordForm)
