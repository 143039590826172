import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActions from '../../dux/actions/appActions'
import * as invitationActions from '../../dux/actions/invitation'
import * as actions from '../../dux/actions/settings'
import { DEFAULT_SIDEBAR_WIDTH } from '../../dux/constants/app'
import SettingsSidebarContent from './SettingsSidebarContent'

class SettingsSidebar extends React.Component {
  componentDidMount() {
    this.props.appActions.toggleSidebar(DEFAULT_SIDEBAR_WIDTH)
    this.props.actions.refreshSettings()
    this.props.invitationActions.getInvitations()
    this.props.appActions.setMenuItem('settings')
    if (this.props.appState.startupRoute) {
      const path = this.props.appState.startupRoute.split('/')
      if (path[1] === 'settings') {
        this.props.actions.setSettingsOpenKeys([path[2]])
        this.props.actions.setSettingsActiveKey(path[2])
        this.props.actions.setSettingsActiveItem(path[3])
        this.props.appActions.setStartupRoute('')
      }
    }
  }

  onCollapseChange = (keys) => {
    this.props.actions.setSettingsOpenKeys(keys)
  }

  onItemClick = (item, key) => {
    if (key !== this.props.settings.settingsActiveKey)
      this.props.actions.setSettingsActiveKey(key)

    if (item !== this.props.settings.settingsActiveItem)
      this.props.actions.setSettingsActiveItem(item)
  }

  render() {
    return (
      <SettingsSidebarContent
        settingsMenu={this.props.settings.settingsMenu}
        openKeys={this.props.settings.settingsOpenKeys}
        activeKey={this.props.settings.settingsActiveKey}
        activeItem={this.props.settings.settingsActiveItem}
        onItemClick={this.onItemClick}
        onCollapseChange={this.onCollapseChange}
        actions={this.props.actions}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  settings: state.settings,
  appState: state.appState,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  appActions: bindActionCreators(appActions, dispatch),
  invitationActions: bindActionCreators(invitationActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsSidebar)
