import { Form, Modal } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import BulkEditForm from './BulkEditForm'
import '../styles/Tag.css'

const FormItem = Form.Item

class EditAssetModal extends React.Component {
  constructor(props) {
    super(props)

    this.saveTagsRef = (ref) => (this.tagsRef = ref)
    this.saveFormRef = (ref) => (this.form = ref)
    // For NEW assets, call the upload saga
    // For Existing assets, call the save edits saga
    this.saveEdits = () => {
      const { asset } = this.props.modalProps
      const datestring = moment().format('YYYYMMDD')
      // Save partial tag input, continue in callback from setstate
      this.tagsRef.handleInputConfirm(null, () => {
        this.form.validateFields((err, values) => {
          if (!err) {
            let updates = {
              updated: Date.now(),
              filename: values.filename,
              description:
                values.description &&
                values.description
                  .replace('%F', values.filename)
                  .replace('%D', datestring)
                  .replace('%I', '1'),
              title:
                values.title &&
                values.title
                  .replace('%F', values.filename)
                  .replace('%D', datestring)
                  .replace('%I', '1'),
              tags: this.tagsRef.getTags(),
            }

            const editedAsset = Object.assign({}, asset, updates)
            editedAsset.tags = this.tagsRef.getTags()
            this.props.modalProps.new
              ? this.props.assetActions.createAsset(
                  editedAsset,
                  this.props.folders.activeFolder
                )
              : this.props.assetActions.saveAssetEdits(asset, updates)
          }
        })
      })
    }

    this.close = () => {
      if (this.props.modalProps.new) {
        // Delete that orphaned asset!
      } else {
        this.props.modalActions.closeModal()
      }
    }
  }

  render() {
    return (
      <Modal
        title="Edit Asset Metadata"
        okText="SAVE"
        onOk={this.saveEdits}
        cancelText="Cancel"
        onCancel={this.props.modalActions.closeModal}
        visible={this.props.modal.modalOpen}
        confirmLoading={this.props.appState.asyncPending}
        maskClosable={false}
      >
        <div className="modal__img-centered">
          {this.props.modalProps.asset.preview ? (
            <img
              src={`${this.props.modalProps.asset.preview}_thumb-200x`}
              alt={this.props.modalProps.asset.filename}
            />
          ) : (
            <i
              className={`fa fa-5x ${
                this.props.modalProps.asset.icon
                  ? this.props.modalProps.asset.icon
                  : 'fa-file-0'
              }`}
            />
          )}
        </div>
        <BulkEditForm
          ref={this.saveFormRef}
          assets={[this.props.modalProps.asset]}
          saveTagsRef={this.saveTagsRef}
          single={true}
        />
        <FormItem label="Last Modified">
          {moment(this.props.modalProps.asset.updated).format(
            'MMM D, YYYY hh:mm a'
          )}
        </FormItem>
      </Modal>
    )
  }
}

/*
 *  Props are from the redux store
 *  "actions" are objects created by bindActionCreators, containing all relevant action functions
 *  modalProps is object passed from modal creator
 */
EditAssetModal.propTypes = {
  modal: PropTypes.object,
  modalActions: PropTypes.object,
  folders: PropTypes.object,
  folderActions: PropTypes.object,
  assets: PropTypes.object,
  assetActions: PropTypes.object,
  modalProps: PropTypes.object,
}

EditAssetModal.defaultProps = {
  modalProps: {
    new: false,
  },
}

export default EditAssetModal
