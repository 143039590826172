import { find, get } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Hint,
  LineMarkSeries,
  makeWidthFlexible,
  MarkSeries,
  XAxis,
  XYPlot,
  YAxis,
} from 'react-vis'
import 'react-vis/dist/style.css'
import { colors } from '../../../../styleConstants'

const plotHeight = 400 //px

const unixToDateString = (ts) => {
  return moment(ts).format('MMM D')
}

const yTickFormat = (value) => {
  return parseInt(value, 10)
}

const Tooltip = ({ containerStyles, xyObj }) => {
  const yValue = get(xyObj, 'y', null)
  const subtitle = moment(get(xyObj, 'x', null)).format('MMM D, YYYY')
  const titleUnits = yValue === 1 ? 'user' : 'users'

  return (
    <div style={{ ...styles.tooltipContainer, ...containerStyles }}>
      <div>
        <span
          style={{ ...styles.tooltipText, ...styles.bold }}
        >{`${yValue} ${titleUnits}`}</span>
      </div>
      <div>
        <span style={styles.tooltipText}>{subtitle}</span>
      </div>
    </div>
  )
}

// ****************
// React Components
// ****************
class AnalyticsTemporalLineGraph extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tooltipValue: null,
    }
  }

  setTooltipValue = (tooltipValue, { index }) => {
    const { graphData } = this.props
    if (graphData.length === 1 && (index === 0 || index > graphData.length))
      return

    this.setState({ tooltipValue })
  }

  forgetTooltipValue = () => {
    this.setState({ tooltipValue: null })
  }

  handleSelectPoint = () => {
    const { tooltipValue } = this.state
    const { onSelectPoint } = this.props

    onSelectPoint(tooltipValue && tooltipValue.x)
  }

  renderPlot = () => {
    const {
      graphData,
      selectedPoint: selectedDate,
      width,
      xTitle,
      yTitle,
      yMax,
    } = this.props
    const { tooltipValue } = this.state

    const selectedPoint = find(graphData, { x: selectedDate })

    const xLower = get(graphData[0], 'x', null)
    const xUpper = get(graphData[graphData.length - 1], 'x', null)
    const xTickValues = [xLower, xUpper]
    const yTickValues = [0, yMax]

    const xAxisLower = moment(xLower).subtract(1, 'day').valueOf()
    const xAxisUpper = moment(xUpper).add(1, 'day').valueOf()
    const paddedData =
      graphData.length === 1
        ? [{ x: xAxisLower, y: 0 }, ...graphData, { x: xAxisUpper, y: 0 }]
        : graphData

    return (
      <XYPlot
        height={plotHeight}
        width={width}
        margin={{ left: 70, right: 40, top: 20, bottom: 40 }}
        onClick={this.handleSelectPoint}
        onMouseLeave={this.forgetTooltipValue}
      >
        <XAxis
          title={xTitle}
          tickFormat={unixToDateString}
          tickValues={xTickValues}
          style={{ text: styles.axisLabelText }}
        />
        <YAxis
          title={yTitle}
          tickValues={yTickValues}
          tickFormat={yTickFormat}
          style={{ text: styles.axisLabelText }}
        />
        <LineMarkSeries
          data={paddedData}
          lineStyle={styles.lineGraph}
          markStyle={styles.markGraph}
          onNearestX={this.setTooltipValue}
        />

        {selectedPoint &&
          (() => [
            <MarkSeries
              data={[selectedPoint]}
              key="selectedMark"
              style={{
                ...styles.markGraph,
                fill: colors.blue00,
                pointerEvents: 'none',
              }}
            />,
            <Hint
              align={{ horizontal: 'auto', vertical: 'top' }}
              key="selectedMarkTip"
              value={selectedPoint}
            >
              <Tooltip
                containerStyles={styles.selectedTooltipContainer}
                xyObj={selectedPoint}
              />
            </Hint>,
          ])()}

        {tooltipValue ? (
          <Hint
            value={tooltipValue}
            align={{ horizontal: 'auto', vertical: 'top' }}
          >
            <Tooltip xyObj={tooltipValue} />
          </Hint>
        ) : null}
      </XYPlot>
    )
  }

  render() {
    const { graphData, graphTitle } = this.props
    const hasData = graphData && graphData.length > 0

    return (
      <div style={styles.container}>
        <div style={styles.headerContainer}>
          <span style={styles.titleText}>{graphTitle}</span>

          {!hasData && (
            <span style={styles.titleText}>
              No data available for the selected timeframe
            </span>
          )}
        </div>

        {hasData && this.renderPlot()}
      </div>
    )
  }
}

// ******
// Styles
// ******
const styles = {
  container: {
    borderRadius: '7px',
    backgroundColor: colors.white00,
    cursor: 'pointer',
    boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.07)',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '22px 40px',
  },
  titleText: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    letterSpacing: '.94px',
    fontWeight: 'bold',
    color: colors.grey01,
  },
  lineGraph: {
    opacity: 1,
    stroke: colors.blue02,
  },
  markGraph: {
    fill: colors.blue01,
    opacity: 1,
    stroke: colors.blue02,
  },
  selectedTooltipContainer: {
    opacity: 1,
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '3px',
    padding: '5px 5px',
    backgroundColor: colors.blue00,
  },
  tooltipText: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '1.21',
    color: colors.white00,
  },
  bold: {
    fontWeight: 'bold',
  },
  axisLabelText: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '.94px',
    fontWeight: 'bold',
    fill: colors.grey00,
  },
}

// *********
// PropTypes
// *********
Tooltip.propTypes = {
  xyObj: PropTypes.object,
}

AnalyticsTemporalLineGraph.propTypes = {
  width: PropTypes.number,
  dateRange: PropTypes.arrayOf(PropTypes.object),
  graphData: PropTypes.arrayOf(PropTypes.object),
  onSelectPoint: PropTypes.func,
  xTitle: PropTypes.string,
  yTitle: PropTypes.string,
  yMax: PropTypes.number,
  graphTitle: PropTypes.string,
}

export default makeWidthFlexible(AnalyticsTemporalLineGraph)
